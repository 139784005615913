import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { getCashCoupon, deleteCashCoupon } from 'api';
import { discountTypes } from 'utils/constants';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import DrawerHeader from 'components/DrawerHeader';
import GeneralInfoBox, { GeneralInfoBoxContainer } from 'components/GeneralInfoBox';
import Loader from 'components/Loaders/Dots';
import OptionMenuPopover from 'components/OptionMenuPopover';
import UnknownId from 'components/Drawer/UnknownId';
import useDynId from 'customHooks/useDynId';

import { ReactComponent as DeleteIcon } from 'assets/16px_trash.svg';
import { ReactComponent as Pencil } from 'assets/16px_edit.svg';

import styles from './CashCouponDetails.module.scss';

const CashCouponDetails = ({ cashCouponId, onDeleted }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state } = globalState;
  const basketId = useDynId('basket');
  const cancelId = useDynId('cancel');
  const continueId = useDynId('continue');
  const editId = useDynId('edit');
  const {
    user,
    cashCoupons: {
      details: { cashCoupon = {}, offerCampaigns = {} },
    },
  } = state;
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);

  useEffect(() => {
    const loadOffer = async () => {
      setIsLoading(true);
      await getCashCoupon(cashCouponId);
      setIsLoading(false);
    };

    loadOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generosity =
    cashCoupon.discountUnit === 'CURRENCY'
      ? t('commun_price', {
          value: cashCoupon.discountValue,
          currency: user.currency.code,
          maximumFractionDigits: 2,
        })
      : `${cashCoupon.discountValue} %`;

  const treshold = t('commun_price', {
    value: cashCoupon.discountBasketThreshold,
    currency: user.currency.code,
  });

  const type = t(`_dyn_commun_${discountTypes.find((t) => t.denomination === cashCoupon.discountType)?.label}`);

  const generalInfos = [
    { label: t('commun_generosity'), value: generosity },
    { label: t('cash_coupon_creation_basket_threshold'), value: treshold },
    { label: t('commun_discount_type'), value: type },
    {
      label: t('commun_validation_dates'),
      value: t('commun_from_to_dates', {
        valueFrom: cashCoupon.validityStartDate,
        valueTo: cashCoupon.validityEndDate,
      }),
    },
    { label: t('cash_coupon_max_number_use'), value: cashCoupon.discountMaximumUses },
  ];

  const handleDeleteButton = () => {
    setDialogDeleteOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogDeleteOpen(false);
  };

  const handleDeleteOffer = async () => {
    setIsDeleteLoading(true);
    const deleted = await deleteCashCoupon(cashCouponId);
    setIsDeleteLoading(false);

    if (deleted) {
      onDeleted();
    }
  };

  const goToCashCouponEditionPage = () => {
    history.push(`/offers/add-cash-coupon/${cashCouponId}`);
  };

  if (isLoading) {
    return (
      <div className={styles['cashcoupon-details']}>
        <DrawerHeader title={t('cash_coupon_details_summary')} />
        <div className={styles['header']}>
          <div className={styles['title-placeholder']}></div>
          <div className={styles['subtitle-placeholder']}></div>
        </div>
        <div className={styles['loading']}>
          <p className={styles['loading-message']}>{t('offer_details_loading_message')}</p>
          <Loader />
        </div>
      </div>
    );
  }

  if (!cashCoupon.title) {
    return <UnknownId />;
  }

  const buttons = [
    <ButtonsCustom
      id={editId}
      key="offer-br-edit-button"
      classType="link_black"
      method={goToCashCouponEditionPage}
      startIconCustom={<Pencil />}
      text={t('commun_offer_option_menu_edit')}
    />,

    <ButtonsCustom
      id={basketId}
      disabled={offerCampaigns.length > 0}
      classType="link_black"
      key="offer-br-delete-button"
      method={handleDeleteButton}
      startIconCustom={<DeleteIcon />}
      text={t('commun_offer_option_menu_delete')}
      tooltip={offerCampaigns.length > 0 ? t('offer_details_delete_tooltip') : ''}
    />,
  ];

  return (
    <div className={styles['cashcoupon-details']}>
      <DrawerHeader
        title={t('cash_coupon_details_summary')}
        buttons={[<OptionMenuPopover key="offer-br-option-menu">{buttons}</OptionMenuPopover>]}
      />

      <div className={styles['header']}>
        <div className={styles['title']}>{cashCoupon?.title}</div>
        <div className={styles['subtitle']}>ID : {cashCoupon?.id}</div>
      </div>

      <GeneralInfoBoxContainer>
        {generalInfos.map((el, index) => (
          <GeneralInfoBox key={`generalInfoItem-${index}`} label={el.label}>
            <span>{el.value}</span>
          </GeneralInfoBox>
        ))}
      </GeneralInfoBoxContainer>

      <ContentDialog
        centerText
        isLoading={isDeleteLoading}
        isOpen={dialogDeleteOpen}
        handleClose={handleCloseDialog}
        title={t('offer_details_delete_confirmation_message')}
        maxWidth="xs"
      >
        <ButtonsCustom id={cancelId} classType="canceled" text={t('commun_cancel')} method={handleCloseDialog} />
        <ButtonsCustom
          id={continueId}
          classType="action_primary_big"
          text={t('commun_button_yes_continue')}
          method={handleDeleteOffer}
          loading={isDeleteLoading}
        />
      </ContentDialog>
    </div>
  );
};

CashCouponDetails.propTypes = {
  cashCouponId: PropTypes.string,
  onDeleted: PropTypes.func,
};

export default memo(CashCouponDetails);

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import DotsLoader from 'components/Loaders/Dots';
import LearnMore from './LearnMore';
import SendFeedbackEmail from 'components/SendFeedbackEmail';

import styles from './Recommendation.module.scss';

const EMAIL_TEMPLATE_ID = 5053755;

const Recommendation = ({ currency, isLoading, value }) => {
  const { t } = useTranslation();

  return (
    <div className={clsx(styles['root'], isLoading && styles['loader'], !isLoading && styles['content'])}>
      {isLoading ? (
        <>
          <p className={styles['title']}>{t('offers_creation_block_budget_reco_loading_title')}</p>
          <DotsLoader />
        </>
      ) : (
        <>
          <p className={styles['title']}>{t('offers_creation_block_budget_reco_title')}</p>
          <p className={styles['value']}>{value ? t('commun_price', { value, currency }) : '-'}</p>
          <p className={styles['description']}>{t('offers_creation_block_budget_reco_desc')}</p>
          <div>
            <LearnMore />
            <SendFeedbackEmail
              emailObject={t('commun_feedback_email_object_estimatedBudget')}
              emailTemplateId={EMAIL_TEMPLATE_ID}
            />
          </div>
        </>
      )}
    </div>
  );
};

Recommendation.propTypes = {
  currency: PropTypes.string,
  isLoading: PropTypes.bool,
  value: PropTypes.number,
};

export default memo(Recommendation);

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserSuppliers, postSupplierEmailNotification } from 'api';

import ContentDialog from 'components/ContentDialog';
import DotsLoader from 'components/Loaders/Dots';
import FormManager from 'components/FormManager';

import styles from './EmailsNotificationDialog.module.scss';

import { supplierBudgetType } from 'types/supplierBudget';
import { userSupplierType } from 'types/userSupplier';

type Props = {
  onDialogClose: () => void;
  selectedBudget?: supplierBudgetType;
};

const EmailsNotificationDialog = ({ onDialogClose, selectedBudget }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [fetchingEmailNotificationInfo, setFetchingEmailNotificationInfo] = useState(false);
  const [sendingEmailLoading, setSendingEmailLoading] = useState(false);
  const [userSupplierList, setUserSupplierList] = useState<userSupplierType[]>([]);
  const [selectedEmailsList, setSelectedEmailsList] = useState<string[]>([]);

  useEffect(() => {
    const fetchUserSuppliersInfo = async () => {
      setFetchingEmailNotificationInfo(true);
      const list: userSupplierType[] = await getUserSuppliers(selectedBudget?.supplier?.id);

      if (list) {
        setUserSupplierList(list);
        setSelectedEmailsList(list.map((user) => user.id.toString()));
      }
      setFetchingEmailNotificationInfo(false);
    };
    if (selectedBudget?.id) {
      fetchUserSuppliersInfo();
    }
  }, [selectedBudget]);

  const handleSendingEmail = async ({
    email_notification_content,
    email_notification_object,
    email_notification_userPlatformIds
  }: {
    email_notification_content: string;
    email_notification_object: string;
    email_notification_userPlatformIds: string[];
  }) => {
    setSendingEmailLoading(true);
    await postSupplierEmailNotification({
      content: email_notification_content,
      supplierId: selectedBudget?.supplier?.id,
      title: email_notification_object,
      userPlatformIds:
        email_notification_userPlatformIds.length !== userSupplierList.length
          ? email_notification_userPlatformIds.map((id) => parseInt(id))
          : undefined
    });
    setSendingEmailLoading(false);
    onDialogClose();
  };

  return (
    <ContentDialog
      isLoading={sendingEmailLoading}
      isOpen={!!selectedBudget?.id}
      handleClose={onDialogClose}
      title={`${t('supplier_details_email_relaunch_short')} ${selectedBudget?.supplier?.name}`}
      subTitle={
        selectedBudget?.supplier?.emailType &&
        selectedBudget?.supplier?.emailSendingDate &&
        t(`supplier_details_email_relaunch_date_type_${selectedBudget.supplier.emailType}`, {
          date: selectedBudget.supplier.emailSendingDate,
          datetime: selectedBudget.supplier.emailSendingDate
        })
      }
      maxWidth="sm"
    >
      <div className={styles['root']}>
        {fetchingEmailNotificationInfo && <DotsLoader />}
        <FormManager
          onSubmit={handleSendingEmail}
          data={{
            fieldsets: [
              {
                id: 'email-notification-form',
                fields: [
                  {
                    label:
                      fetchingEmailNotificationInfo || userSupplierList.length > 0
                        ? t('retailers_creation_notifications_recipients')
                        : t('retailers_creation_notifications_recipients_empty'),
                    type: 'CheckboxList',
                    id: 'email_notification_userPlatformIds',
                    defaultValue: selectedEmailsList,
                    fieldProps: {
                      displayOnePerRow: true,
                      list: userSupplierList.map((user) => ({ label: user.email, value: user.id.toString() }))
                    },
                    onFieldChange: (values: string[]) => {
                      setSelectedEmailsList(values);
                    },
                    validations: [
                      {
                        func: (val: string[]) => !!val.length,
                        message: t('commun_field_required')
                      }
                    ]
                  },
                  {
                    label: t('commun_email_object_title'),
                    defaultValue: t('commun_email_object_placeholder'),
                    type: 'TextField',
                    id: 'email_notification_object',
                    validations: [
                      {
                        func: (value: string) => !!value,
                        message: t('commun_field_required')
                      }
                    ]
                  },
                  {
                    label: t('commun_email_content_title'),
                    defaultValue: t('commun_email_content_placeholder'),
                    type: 'TextField',
                    id: 'email_notification_content',
                    fieldProps: {
                      multiline: true
                    },
                    validations: [
                      {
                        func: (value: string) => !!value,
                        message: t('commun_field_required')
                      }
                    ]
                  }
                ]
              }
            ],
            alignFormButtons: 'center',
            submit: {
              disabled: !userSupplierList.length,
              id: 'send_suppliers_receivers_email_notification_button',
              label: t('supplier_details_email_relaunch_short'),
              loading: sendingEmailLoading
            }
          }}
        />
      </div>
    </ContentDialog>
  );
};

export default EmailsNotificationDialog;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { withFractionDigits } from 'utils/global';
import { getMonitoredOffersToDisable } from 'api';

import ButtonsCustom from 'components/ButtonsCustom';
import OfferDisableDialog from 'components/OfferDisableDialog';
import TableCustom from 'components/TableCustom';

import { ReactComponent as DisableIcon } from 'assets/30px_echouer.svg';
import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';
import EmptyImage from 'assets/illu_petit_robot.png';
import styles from './PilotageRetailer.module.scss';

import { offerType } from 'types/offer';
import { stateOffersType } from 'types/storeState';

type Props = {
  count: number | null;
  filters: { supplierId?: string[]; userManagerId?: string[] };
  onUpdated: () => void;
};

const tableSize = 30;

const MonitoredOffersToDisable = ({ count, filters, onUpdated }: Props): JSX.Element => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state, dispatch } = globalState as any;
  const {
    user,
    monitoringRetailer: { offersToDisable }
  } = state;
  const currency = user.currency.code;

  // use the store for not refetching data when mounting after changing tab index
  const { list, orderBy, page, sortBy, total } = offersToDisable as stateOffersType;

  const [isLoading, setIsLoading] = useState(true);
  const [selectedOffer, setSelectedOffer] = useState<offerType | undefined>();
  const [sorting, setSorting] = useState({ orderBy, sortBy });
  const [pageIndex, setPageIndex] = useState(page);

  // disable offer dialog states
  const [dialogDisableOpen, setDialogDisableOpen] = useState(false);

  const fetchData = async ({ orderBy, page, sortBy }: { orderBy?: 'asc' | 'desc'; page?: number; sortBy?: string }) => {
    setIsLoading(true);
    await getMonitoredOffersToDisable({
      retailerId: user.id,
      sortBy,
      orderBy,
      page,
      size: tableSize,
      filters
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (!list?.length && count && count !== total) {
      fetchData({});
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, filters, list]);

  useEffect(() => {
    dispatch({
      type: 'MONITORING_RETAILER_OFFERS_TO_DISABLE_LIST',
      payload: { orderBy: sorting.orderBy, page: pageIndex, sortBy: sorting.sortBy }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, sorting.orderBy, sorting.sortBy]);

  const columns = useMemo(
    () => [
      { id: 1, field: 'img', type: 'component' },
      {
        id: 2,
        field: 'infos',
        headerName: t('commun_offer'),
        type: 'component'
      },
      {
        id: 3,
        field: 'supplier',
        headerName: t('commun_supplier'),
        type: 'text',
        sortable: true,
        sortPath: 'offerHead.budgetSupplierName'
      },
      {
        id: 4,
        field: 'budgetSpent',
        headerName: t('commun_budget_spent'),
        type: 'component',
        align: 'right',
        sortable: true,
        sortPath: 'offerHead.budgetSpent'
      },
      {
        id: 5,
        field: 'budgetTarget',
        headerName: t('commun_budget_target'),
        type: 'number',
        align: 'right',
        sortable: true,
        sortPath: 'offerHead.budgetTarget'
      },
      {
        id: 6,
        field: 'percentSpent',
        headerName: t('pilotage_monitored_offers_to_disable_budgetSpent_percentage_label'),
        type: 'number',
        align: 'right',
        sortable: true,
        sortPath: 'offerHeadPercentageBudgetRemaining'
      },
      {
        id: 7,
        field: 'action',
        type: 'component'
      }
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      list.map((offer) => {
        const picture =
          offer.offerHead.imageMediumFileDescriptor?.url ||
          offer.offerHead.imageRetailerFileDescriptor?.url ||
          offer.offerHead.fileDescriptor?.url;

        const isOverBurning = offer.offerHead.budgetSpent >= offer.offerHead.budgetTarget;
        const percentSpent = offer.offerHead.percentageBudgetRemaining;

        const tooltipOrderCode = ['OFF002', 'OFF001', 'CAMP001', 'CAMP002']; // order matters
        const tooltipCode = tooltipOrderCode
          .map((code) => offer.disableStatus.infos.find((el) => el.code === code))
          .filter((el) => !!el)[0]?.code;

        return {
          id: [{ value: offer.id }],
          img: (
            <div className={styles['offer-img']}>
              <img src={picture} alt={`offer ${offer.id}`} />
            </div>
          ),
          infos: (
            <div className={styles['infos']}>
              <div>{offer.offerHead.title}</div>

              <span>{offer.offerHead.descriptionTag}</span>
              {offer.offerHead.descriptionTag && offer.offerHead.budget?.type && ' - '}
              {offer.offerHead.budget?.type && <span>{t(`commun_budget_type_${offer.offerHead.budget?.type}`)}</span>}
            </div>
          ),
          supplier: [{ value: offer.offerHead.budget?.supplier.name }],
          budgetSpent: (
            <div className={clsx(styles['budgetSpent'], isOverBurning && styles['overBurn'])}>
              {isOverBurning && <WarningIcon />}
              {t('commun_price', {
                value: offer.offerHead.budgetSpent,
                currency,
                maximumFractionDigits: withFractionDigits(offer.offerHead.budgetSpent)
              })}
            </div>
          ),
          budgetTarget: [
            {
              value: t('commun_price', {
                value: offer.offerHead.budgetTarget,
                currency,
                maximumFractionDigits: withFractionDigits(offer.offerHead.budgetTarget)
              })
            }
          ],
          percentSpent: [
            {
              value: t('commun_percentage_number', {
                value: percentSpent,
                maximumFractionDigits: withFractionDigits(percentSpent)
              })
            }
          ],
          action: (
            <div className={clsx('visibility-hidden', styles['disable-button'])}>
              <ButtonsCustom
                classType="action_secondary"
                disabled={!offer.disableStatus.disable}
                id={`disable_button_${offer.id}`}
                method={(event) => {
                  event.preventDefault();
                  setSelectedOffer(offer);
                  setDialogDisableOpen(true);
                }}
                startIconCustom={<DisableIcon />}
                size="thin"
                text={t('commun_offer_option_menu_disable')}
                tooltip={
                  !offer.disableStatus || offer.disableStatus?.disable
                    ? ''
                    : t(`offer_details_disable_status_code_${tooltipCode}`)
                }
              />
            </div>
          )
        };
      }),
    [currency, list, t]
  );

  const handleNextPage = () => {
    const newPage = page + 1;
    setPageIndex(newPage);
    fetchData({ orderBy: sorting.orderBy, page: newPage, sortBy: sorting.sortBy });
  };

  const handlePrevPage = () => {
    const newPage = page - 1;
    setPageIndex((page) => page - 1);
    fetchData({ orderBy: sorting.orderBy, page: newPage, sortBy: sorting.sortBy });
  };

  const handleSorting = (newSortBy: string) => {
    setPageIndex(0);
    const newSorting = {
      orderBy: (newSortBy === sortBy && orderBy === 'desc' ? 'asc' : 'desc') as 'asc' | 'desc',
      sortBy: newSortBy
    };
    setSorting(newSorting);
    fetchData({ orderBy: newSorting.orderBy, page: 0, sortBy: newSorting.sortBy });
  };

  const handleCloseDisableDialog = () => {
    setSelectedOffer(undefined);
    setDialogDisableOpen(false);
  };

  const displayEmptyResult = () => {
    if (count === 0 && !Object.keys(filters)?.length) {
      return (
        <div className={styles['empty-results-container']}>
          <img src={EmptyImage} alt="no offers to disable" />
          <div className={styles['title']}>{t('commun_no_offers_to_disable')}</div>
          <div className={styles['desc']}>{t('commun_no_offers_to_disable_desc')}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className={styles['monitoredOffersToDisable-container']}>
        <h4>{t('pilotage_monitored_offers_to_disable_title')}</h4>
        <TableCustom
          borderOnHover
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          total={total}
          handleSort={handleSorting}
          page={pageIndex}
          size={tableSize}
          prev={handlePrevPage}
          next={handleNextPage}
          order={sorting.orderBy}
          sort={sorting.sortBy}
          type="big"
          isUsingFilters={!!Object.keys(filters)?.length}
          customMessageNode={displayEmptyResult()}
          clickedRawUniqueKeyValue={selectedOffer?.id.toString()}
        />
      </div>
      <OfferDisableDialog
        dialogDisableOpen={dialogDisableOpen}
        disableStatus={selectedOffer?.disableStatus}
        notifyRetailersEnabled={user.notifyRetailersEnabled}
        offerHeadId={selectedOffer?.offerHead.id}
        onCloseDialog={handleCloseDisableDialog}
        updateData={async () => {
          await fetchData({ orderBy: sorting.orderBy, page: 0, sortBy: sorting.sortBy });
          onUpdated();
        }}
      />
    </>
  );
};

export default MonitoredOffersToDisable;

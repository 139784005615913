import React from 'react';

import DashboardSupplier from 'pages/DashboardSupplier';
import TopNav from '../TopNav';

import styles from '../Dashboard.module.scss';

const Dashboard = () => {
  return (
    <div className={styles['root']}>
      <TopNav index={1} />
      <DashboardSupplier />
    </div>
  );
};

export default Dashboard;

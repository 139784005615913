import React, { useEffect, useContext } from 'react';
import { store } from 'store';

import 'swagger-ui/dist/swagger-ui.css';
import './Apidocs.scss';

const ApiDocs = () => {
  const globalState = useContext(store);
  const {
    state: { user },
  } = globalState;

  useEffect(() => {
    // dynamic import
    const launchSwagger = async () => {
      await import('swagger-ui').then((module) => {
        module
          .default({
            oauth2RedirectUrl: `${window.REACT_APP_API_URL.replace(
              'v4',
              'v2',
            )}/api-docs/swagger-ui/oauth2-redirect.html`,
            dom_id: '#swaggerContainer',
            url:
              user?.userType === 'superAdmin'
                ? `${window.REACT_APP_API_URL.replace('v4', 'v2')}/api-docs`
                : `${window.REACT_APP_API_URL.replace('v4', 'v2')}/api-docs/service-accounts`,
            requestInterceptor: (req) => {
              if (req.loadSpec) {
                req.headers.Authorization = `Bearer ${user?.token}`;
              }
              return req;
            },
          })
          .initOAuth({
            clientId: 'app',
            realm: 'coupilo',
          });
      });
    };

    launchSwagger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="apidocs">
      <div id="swaggerContainer" />
    </section>
  );
};

export default ApiDocs;

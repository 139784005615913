import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { deleteOffer } from 'api';
import moment from 'moment-timezone';
import { offerStatuses } from 'utils/constants';
import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import Tags from 'components/Tags';

import noImageIcon from 'assets/no_image.svg';
import { ReactComponent as DeleteIcon } from 'assets/16px_trash.svg';
import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';
import styles from './CardsList.module.scss';

const CardsList = ({ handleOfferSelection, list, resetAllData }) => {
  const { t } = useTranslation();
  const [offerIdToDelete, setOfferIdToDelete] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  const handleDeleteOffer = async () => {
    setIsDeleteLoading(true);
    let success = await deleteOffer({
      offerId: offerIdToDelete,
      offerHeadId: list.find((offer) => offer.id === offerIdToDelete)?.offerHead?.id,
    });
    setIsDeleteLoading(false);

    if (success) {
      resetAllData();
      setOfferIdToDelete();
    }
  };

  return (
    <>
      {list.map((offer) => {
        const startDate = moment(offer.offerHead?.validityStartDate);
        const endDate = moment(offer.offerHead?.validityEndDate);
        const diff = Math.floor((endDate.diff(startDate, 'days') + 1) / 7);
        const isToValidatedStatus = offer.status === offerStatuses.TO_VALIDATE;
        const isOfferExpired = moment().isAfter(offer.offerHead?.validityEndDate, 'day') && isToValidatedStatus;
        const generosity =
          offer.discountUnit === 'CURRENCY'
            ? t('commun_price', {
                value: offer.discountValue,
                currency: offer.retailer.currency.code,
                maximumFractionDigits: 2,
              })
            : `${offer.discountValue} %`;
        return (
          <div
            key={offer.id}
            className={styles['root']}
            onClick={() => {
              handleOfferSelection(offer.offerHead.id);
            }}
            role="button"
          >
            <div className={styles['top-container']}>
              <div className={styles['offerId']}>{`#${offer.offerHead.id}`}</div>
              <img
                className={styles['offer-details-image']}
                src={
                  offer?.offerHead?.imageMediumFileDescriptor?.url
                    ? offer.offerHead?.imageMediumFileDescriptor.url
                    : offer?.offerHead?.fileDescriptor?.url
                    ? offer.offerHead?.fileDescriptor.url
                    : noImageIcon
                }
                onError={(e) => (e.target.src = noImageIcon)}
                alt={offer.offerHead?.title}
              />
              <span className={styles['delete-button-container']}>
                <ButtonsCustom
                  classType="action"
                  method={(event) => {
                    event.stopPropagation();
                    setOfferIdToDelete(offer.id);
                  }}
                  disabled={!!offer.campaignCount}
                  startIconCustom={<DeleteIcon />}
                  tooltip={offer.campaignCount ? t('offer_details_delete_tooltip') : ''}
                />
              </span>
            </div>
            <div className={styles['bottom-container']}>
              <div className={styles['meta']}>
                <Tags status={offer.privateLabel ? 'retailer_brand' : 'national_brand'} />
                <span
                  className={`${styles['item']} ${
                    offer.targetingStrategy?.title.length < 20 && styles['keep-visible']
                  }`}
                >
                  {offer.targetingStrategy?.title}
                </span>
              </div>
              {/* <div className={styles['offer-details-block-meta']}>
                <span className={styles['offer-details-block-meta-item']}>
                  {offer.offerHead.budgetTarget === -1
                    ? t('commun_unlimited')
                    : t('commun_price', {
                        value: offer.offerHead.budgetTarget || 0,
                        currency: offer.retailer.currency.code,
                      })}
                </span>
              </div> */}
              <div className={styles['discount']}>{generosity}</div>
              <p className={styles['title']}>{offer.offerHead?.title}</p>

              <div className={styles['validity']}>{t('offer_validity_weeks', { count: diff })}</div>
              <div className={styles['dates']}>
                {isOfferExpired && <WarningIcon className={styles['warning-icon']} />}
                {t('commun_from_to_dates', {
                  valueFrom: offer.offerHead?.validityStartDate,
                  valueTo: offer.offerHead?.validityEndDate,
                })}
              </div>
            </div>
          </div>
        );
      })}
      <ContentDialog
        centerText
        isLoading={isDeleteLoading}
        isOpen={!!offerIdToDelete}
        handleClose={setOfferIdToDelete}
        title={t('offer_details_delete_confirmation_message')}
        maxWidth="xs"
      >
        <div className={styles['confirm-dialog']}>
          <ButtonsCustom
            classType="canceled"
            disabled={isDeleteLoading}
            text={t('commun_cancel')}
            method={() => setOfferIdToDelete()}
          />
          <ButtonsCustom
            classType="action_primary_big"
            loading={isDeleteLoading}
            text={t('commun_button_yes_continue')}
            method={handleDeleteOffer}
          />
        </div>
      </ContentDialog>
    </>
  );
};

CardsList.propTypes = {
  handleOfferSelection: PropTypes.func,
  list: PropTypes.array,
  resetAllData: PropTypes.func,
};

export default CardsList;

import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import DOMPurify from 'dompurify';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { store } from 'store';
import { getAllSuppliers, getAllUserManager, getSuppliersAndBudgets, getSuppliersExportFile } from 'api';
import { supplierBudgetTypes } from 'utils/constants';
import useDynId from 'customHooks/useDynId';

import ButtonsCustom from 'components/ButtonsCustom';
import Drawer from 'components/Drawer';
import ExportFileButton from 'components/ExportFileButton';
import FiltersContainer, { getInitialFilters } from 'components/Filters';
import ScrollToTop from 'components/ScrollToTop';
import SupplierDetails from 'pages/SupplierDetails';
import SupplierNoteDialog from 'components/SupplierNoteDialog';
import TableCustom from 'components/TableCustom';
import Tooltip from 'components/Tooltip';
import UserIconList from 'components/UserIconList';

import { ReactComponent as NewIcon } from 'assets/24px_new.svg';
import { ReactComponent as NotesIcon } from 'assets/notes.svg';
import { ReactComponent as Pencil } from 'assets/16px_edit.svg';

import styles from './Suppliers.module.scss';

const Suppliers = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const supplierId = !isNaN(id) ? parseInt(id, 10) : null;
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { queryFilters, suppliersList, suppliers, user, userManagers } = state;

  const [isLoading, setIsLoading] = useState(true);
  const [openEditPanel, setOpenEditPanel] = useState(false);
  const [openNotesDialogSupplierData, setOpenNotesDialogSupplierData] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({ type: 'id', sort: 'desc' });
  const oppositeSort = order.sort === 'asc' ? 'desc' : 'asc';
  const tableSize = 30;
  const today = moment();
  const suppliersFilter = localStorage.getItem('suppliersFilter') || '';

  // Filters
  const initFilters = useMemo(
    () =>
      getInitialFilters({
        history,
        queryFilters: { [history.location.pathname]: suppliersFilter, ...queryFilters },
        suppliers,
        userManagers
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [filters, setFilters] = useState(initFilters);
  const filtersListToDisplay = ['retailerUser', 'budgetSpent', 'currentYearBudget', 'nextYearBudget', 'userManager'];

  const handleUpdateFilters = useCallback((newFilters) => {
    setPage(0); // reset the page when changing the filters
    setFilters({ ...newFilters });
  }, []);

  const refreshSuppliersList = useCallback(async () => {
    setIsLoading(true);
    await getSuppliersAndBudgets({
      retailer: user.id,
      page,
      size: tableSize,
      orderBy: order.sort,
      sortBy: order.type,
      filters
    });
    setIsLoading(false);
    localStorage.setItem('suppliersFilter', window.location.search);
  }, [user, page, order, filters]);

  useEffect(() => {
    getAllSuppliers();
    getAllUserManager();

    return () => {
      dispatch({ type: 'SUPPLIERS_RESET' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshSuppliersList();
  }, [refreshSuppliersList]);

  const columns = [
    { id: 1, field: 'new', type: 'component' },
    { id: 2, field: 'id', headerName: 'ID', type: 'text', sortable: true },
    {
      id: 3,
      field: 'name',
      headerName: t('suppliers_list_name'),
      type: 'text',
      sortable: true
    },
    { id: 4, field: 'budgetTypes', headerName: t('suppliers_list_budget_type'), type: 'text' },
    {
      id: 5,
      field: 'currentYearBudgetSpent',
      headerName: t('commun_budget_spent'),
      type: 'number',
      sortable: true
    },
    {
      id: 6,
      field: 'currentYearBudgetTotal',
      headerName: t('suppliers_list_current_year_budget', { year: new Date().getFullYear() }),
      type: 'number',
      sortable: true
    },
    {
      id: 7,
      field: 'nextYearBudgetTotal',
      headerName: t('suppliers_list_next_year_budget', { year: new Date().getFullYear() + 1 }),
      type: 'number',
      sortable: true
    },
    { id: 8, field: 'userManager', headerName: t('supplier_userManager'), type: 'text' },
    {
      id: 9,
      field: 'updatedAt',
      headerName: t('suppliers_list_update_date'),
      type: 'text',
      sortable: true
    },
    { id: 10, headerName: '', field: 'userListIcon', type: 'component' },
    { id: 11, headerName: '', field: 'actions', type: 'component' }
  ];

  const rows = suppliersList.list.map((element) => ({
    new: today.diff(element.createdAt, 'days') <= 7 ? <NewIcon className={styles['new-tag']} /> : null,
    id: [{ value: element.id, color: 'disabled' }],
    name: [{ value: element.name }],
    budgetTypes: [{ value: element.budgetTypes.map((type) => t(`commun_budget_type_${type}`)).join(',') }],
    currentYearBudgetSpent: [
      {
        value:
          element.currentYearBudgetSpent !== null
            ? t('commun_price', { value: element.currentYearBudgetSpent, currency: user.currency.code })
            : element.currentYearBudgetTotal !== null
            ? t('commun_price', { value: 0, currency: user.currency.code })
            : '-',
        customTooltip:
          element.budgetTypes.length > 1 ? (
            <div>
              <div>{`${t('commun_budget_type_DIGITAL_long')} : ${t('commun_price', {
                value: element.budgets.find((el) => el.type === supplierBudgetTypes.DIGITAL)?.budgetN?.spent || 0,
                currency: user.currency.code
              })}`}</div>
              <div>{`${t('commun_budget_type_PAPER_long')} : ${t('commun_price', {
                value: element.budgets.find((el) => el.type === supplierBudgetTypes.PAPER)?.budgetN?.spent || 0,
                currency: user.currency.code
              })}`}</div>
            </div>
          ) : undefined
      }
    ],
    currentYearBudgetTotal: [
      {
        value: t('commun_price', { value: element.currentYearBudgetTotal ?? '-', currency: user.currency.code }),
        customTooltip:
          element.budgetTypes.length > 1 ? (
            <div>
              <div>{`${t('commun_budget_type_DIGITAL_long')} : ${t('commun_price', {
                value: element.budgets.find((el) => el.type === supplierBudgetTypes.DIGITAL)?.budgetN?.total || '-',
                currency: user.currency.code
              })}`}</div>
              <div>{`${t('commun_budget_type_PAPER_long')} : ${t('commun_price', {
                value: element.budgets.find((el) => el.type === supplierBudgetTypes.PAPER)?.budgetN?.total || '-',
                currency: user.currency.code
              })}`}</div>
            </div>
          ) : undefined
      }
    ],
    nextYearBudgetTotal: [
      {
        value: t('commun_price', { value: element.nextYearBudgetTotal ?? '-', currency: user.currency.code }),
        customTooltip:
          element.budgetTypes.length > 1 ? (
            <div>
              <div>{`${t('commun_budget_type_DIGITAL_long')} : ${t('commun_price', {
                value: element.budgets.find((el) => el.type === supplierBudgetTypes.DIGITAL)?.budgetN1?.total || '-',
                currency: user.currency.code
              })}`}</div>
              <div>{`${t('commun_budget_type_PAPER_long')} : ${t('commun_price', {
                value: element.budgets.find((el) => el.type === supplierBudgetTypes.PAPER)?.budgetN1?.total || '-',
                currency: user.currency.code
              })}`}</div>
            </div>
          ) : undefined
      }
    ],
    userManager: [
      {
        value: element.userManager?.id
          ? element.userManager.firstName || element.userManager.lastName
            ? `${element.userManager.firstName || ''} ${element.userManager.lastName || ''}`
            : element.userManager.email
          : ''
      }
    ],
    updatedAt: [{ value: element.updatedAt ? t('commun_date', { value: element.updatedAt }) : '-', color: 'disabled' }],
    userListIcon: <UserIconList userCount={element.users?.length} />,
    actions: (
      <div className="d-flex">
        {/* create / edit supplier note*/}
        <Tooltip
          placement="top"
          title={
            element.note ? (
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(element.note) }} />
            ) : (
              t('supplier_add_note_tooltip_placeholder')
            )
          }
        >
          <div>
            <ButtonsCustom
              classType="action"
              method={(ev) => {
                setOpenNotesDialogSupplierData(element);
              }}
              startIconCustom={<NotesIcon />}
            />
          </div>
        </Tooltip>
        {/* edit supplier */}
        <ButtonsCustom
          classType="action"
          id={`${user.userType}_${'/suppliers'}_editSupplierButton-${element.id}`}
          method={(ev) => {
            openUpdateSupplier(ev, element.id);
          }}
          startIconCustom={<Pencil />}
        />
      </div>
    )
  }));

  const handleTableRowClick = ({ id }) => {
    history.push({ pathname: `/suppliers/${id[0].value}`, search: history.location.search });
  };

  const onCloseFinished = () => {
    dispatch({ type: 'SUPPLIERS_DETAILS_RESET' });
    setOpenEditPanel(false);
  };

  const handlePanelClose = async () => {
    history.push({ pathname: '/suppliers', search: history.location.search });
  };

  const handleDownloadFile = (extension) => {
    return getSuppliersExportFile({ extension, filters, orderBy: order.sort, sortBy: order.type });
  };

  const openUpdateSupplier = (ev, id) => {
    ev.stopPropagation();

    setOpenEditPanel(true);
    history.push({ pathname: `/suppliers/${id}`, search: history.location.search });
  };

  return (
    <>
      <section className={styles['root']}>
        <div className={styles['top-wrapper']}>
          <div className={styles['header']}>
            <div className={styles['header-title']}>{t('suppliers_list_title')}</div>
            <div className={styles['header-desc']}>{t('suppliers_list_desc')}</div>
          </div>
        </div>
        <div className={styles['top-wrapper']}>
          <p className={styles['count-result']} id={useDynId('Xsupplier')}>
            {t('commun_supplier_count', { count: suppliersList.total })}
          </p>
          <div className={styles['right-buttons']}>
            <ExportFileButton
              customCsvButtonKeyLabel={'commun_file_export_csv_for_supplier'}
              disabled={!suppliersList.total || isLoading}
              handleApiCall={handleDownloadFile}
            />
            <Link to={'suppliers/add-supplier'} role="link">
              <ButtonsCustom
                classType="action_primary_big"
                id={useDynId('createSupplierButton')}
                text={t('commun_button_create_a_supplier')}
              />
            </Link>
          </div>
        </div>

        <FiltersContainer
          dispatch={dispatch}
          filters={filters}
          filtersListToDisplay={filtersListToDisplay}
          suppliersList={suppliers}
          updateFilters={handleUpdateFilters}
          user={user}
          userManagersList={userManagers}
        />
        <TableCustom
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          total={suppliersList.total}
          handleSort={(type) => type && setOrder({ type: type, sort: type === order.type ? oppositeSort : 'desc' })}
          page={page}
          size={tableSize}
          prev={() => setPage(page - 1)}
          next={() => setPage(page + 1)}
          order={order.sort}
          sort={order.type}
          onRowClick={handleTableRowClick}
          type="firstTag"
          isUsingFilters={!!Object.keys(filters)?.length}
          clickedRawUniqueKeyValue={supplierId?.toString()}
        />

        <Drawer isOpen={!!supplierId} onClose={handlePanelClose} onCloseFinished={onCloseFinished} small>
          <SupplierDetails
            supplierId={supplierId}
            onClose={handlePanelClose}
            openEditPanel={openEditPanel}
            onUpdated={refreshSuppliersList}
          />
        </Drawer>

        <ScrollToTop />
      </section>
      <SupplierNoteDialog
        isOpen={!!openNotesDialogSupplierData}
        onClose={() => {
          setOpenNotesDialogSupplierData(null);
        }}
        onUpdated={refreshSuppliersList}
        supplier={openNotesDialogSupplierData}
      />
    </>
  );
};

export default Suppliers;

import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import useDynId from 'customHooks/useDynId';

import ButtonsCustom from 'components/ButtonsCustom';
import Divider from 'components/Divider';
import Filters from 'components/Filters/Filters.js';
import Loader from 'components/Loaders/Dots';
import Tooltip from 'components/Tooltip';

import { ReactComponent as InfoIcon } from 'assets/30px_info_round.svg';
import { ReactComponent as SuccessIcon } from 'assets/30px_check.svg';

import styles from './FiltersContainer.module.scss';

const FiltersContainer = forwardRef(
  (
    {
      campaignProduct,
      disabled,
      filters,
      isAddingOffers,
      isAddingOffersSuccess,
      isLoading,
      onAddingOffers,
      onChangeFilters,
      strategyData,
      suppliersData
    },
    filtersRef
  ) => {
    const { t } = useTranslation();

    const step2ValidAddOfferId = useDynId('step2ValidAddOffer');
    const filtersListToDisplay = [
      ['id', 'title', 'descriptionTag', 'targetingMarketingStrategy', 'retailerUser'],
      ['offerType', 'privateLabel', 'supplierBudgetType']
    ];

    return (
      <div className={styles['root']}>
        <div className={styles['section-title']}>{t('campaign_product_step2_filters_title')}</div>
        <Filters
          disabled={disabled || isAddingOffers}
          filters={filters}
          filtersListToDisplay={filtersListToDisplay}
          ref={filtersRef}
          suppliersList={suppliersData}
          targetingStrategiesList={strategyData}
          updateFilters={(newFilters) => {
            if (JSON.stringify(filters) !== JSON.stringify(newFilters)) {
              onChangeFilters(newFilters);
            }
          }}
        />
        <Divider />
        <div className={styles['results-frame']}>
          <div className={styles['container']}>
            <div className={styles['count']}>
              {campaignProduct.offersPreviewCount > 0 ? (
                <div>
                  <div className={styles['d-flex']}>
                    <Trans
                      i18nKey="campaign_product_step2_offers_compatible_count"
                      values={{ count: campaignProduct.offersPreviewCount }}
                    />
                    <div className={styles['info-icon']}>
                      <Tooltip title={t('campaign_cash_coupon_step2_compatible_offers_tooltip')}>
                        <InfoIcon />
                      </Tooltip>
                    </div>
                  </div>
                  {!!campaignProduct.offersPreviewAlreadyAddedCount && (
                    <p className={styles['sub-text']}>
                      {t('campaign_product_step2_offers_already_added_count', {
                        count: campaignProduct.offersPreviewAlreadyAddedCount
                      })}
                    </p>
                  )}
                </div>
              ) : (
                <p>
                  <Trans i18nKey="campaign_product_step2_offers_compatible_none" />
                </p>
              )}
            </div>
            <div>
              {isAddingOffersSuccess && (
                <div className={styles['success-icon']}>
                  <SuccessIcon />
                </div>
              )}
              {!disabled && !isAddingOffersSuccess && isLoading && (
                <div className={styles['loading-wrapper']}>
                  <span>{t('commun_search_in_progress')}</span>
                  <Loader />
                </div>
              )}
              {isAddingOffers && !isAddingOffersSuccess && (
                <div className={styles['loading-wrapper']}>
                  <span>{t('commun_adding_offers', { count: campaignProduct.offersPreviewCount })}</span>
                  <Loader />
                </div>
              )}
              {!isLoading && !isAddingOffers && !isAddingOffersSuccess && (
                <ButtonsCustom
                  classType="action_primary_small"
                  text={t('commun_add_offers', { count: campaignProduct.offersPreviewCount })}
                  method={onAddingOffers}
                  disabled={
                    disabled ||
                    campaignProduct.offersPreviewCount === 0 ||
                    campaignProduct.offersPreviewCount === campaignProduct.offersPreviewAlreadyAddedCount
                  }
                  id={step2ValidAddOfferId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

FiltersContainer.propTypes = {
  campaignProduct: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  filters: PropTypes.object,
  isAddingOffers: PropTypes.bool,
  isAddingOffersSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  onAddingOffers: PropTypes.func,
  onChangeFilters: PropTypes.func,
  strategyData: PropTypes.array.isRequired,
  suppliersData: PropTypes.array.isRequired
};

export default memo(FiltersContainer);

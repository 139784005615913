import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as UserIconGreen } from 'assets/user_green.svg';
import { ReactComponent as UserIconYellow } from 'assets/user_yellow.svg';
import { ReactComponent as UserIconRed } from 'assets/user_red.svg';

import styles from './UserIconList.module.scss';

const UserIconList = ({ userCount }) => {
  return (
    <div className={styles['users']}>
      {userCount > 0 && (
        <div className={styles['user']}>
          <UserIconYellow />
        </div>
      )}

      {userCount > 2 && (
        <div className={styles['user']}>
          <UserIconRed />
        </div>
      )}

      {userCount > 1 && (
        <div className={styles['user']}>
          <UserIconGreen />
        </div>
      )}

      {userCount > 3 && (
        <div className={styles['user']}>
          <div className={styles['user-count']}>+{userCount - 3}</div>
        </div>
      )}
    </div>
  );
};

UserIconList.propTypes = {
  userCount: PropTypes.number,
};

export default UserIconList;

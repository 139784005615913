import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { store } from 'store';

import { exitModalTypes } from 'utils/constants';

import AddOfferForm from './AddOfferForm';
import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import FormLayoutWrapper from 'components/LayoutWrappers/Form';

const AddOffer = () => {
  const { t } = useTranslation();
  const history = useHistory();

  // for edit mode
  const { offerId: id } = useParams();
  const offerId = !isNaN(id) && parseInt(id, 10);
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const [dialogOpened, setDialogOpen] = useState(false);
  const [isBudgetStep, setIsBudgetStep] = useState(false);
  const [offerCreation, setOfferCreation] = useState({ step1: { id: null } });

  const { step1 } = offerCreation;

  useEffect(() => {
    // check if form is not empty
    if (step1.id) {
      dispatch({
        type: 'EXIT_MODAL_TYPE_UPDATE',
        payload: offerId ? exitModalTypes.OFFER_EDITION : exitModalTypes.OFFER_CREATION
      });
    }

    return () => {
      dispatch({
        type: 'EXIT_MODAL_TYPE_UPDATE',
        payload: null
      });
    };
  }, [dispatch, offerId, step1.id]);

  const goBackUrl = () => {
    history.push({
      pathname: `/offers/offersProductsSupplier/${offerId ? offerId : ''}`,
      search: state.queryFilters['/offers/offersProductsSupplier']
    });
  };

  const handleCloseForm = () => {
    if (step1.id) {
      setDialogOpen(true);
    } else {
      goBackUrl();
    }
  };

  const handleDialogConfim = () => {
    goBackUrl();
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleBudgetStepChange = (newBudgetStep) => {
    setIsBudgetStep(newBudgetStep);
  };

  const handleOfferCreationChange = (offerCreation) => {
    setOfferCreation(offerCreation);
  };

  const handleOfferSaved = (offerHeadId) => {
    history.push({
      pathname: `/offers/offersProductsSupplier/${offerHeadId}`,
      search: state.queryFilters['/offers/offersProductsSupplier']
    });
  };

  return (
    <FormLayoutWrapper
      headerTitle={
        isBudgetStep
          ? t('commun_validate_budget')
          : t(offerId ? 'commun_edit_offer_header_title' : 'commun_create_offer_header_title')
      }
      headerSubTitle={isBudgetStep ? '' : t('commun_create_offer_header_subtitle')}
      onClose={isBudgetStep ? undefined : handleCloseForm}
    >
      <AddOfferForm
        onBudgetStepChange={handleBudgetStepChange}
        onOfferCreationChange={handleOfferCreationChange}
        onOfferSaved={handleOfferSaved}
      />
      <ContentDialog
        centerText
        isOpen={dialogOpened}
        handleClose={handleCloseDialog}
        maxWidth="xs"
        title={
          offerId ? (
            t('offers_edition_leave_popup_confirmation_message')
          ) : (
            <>
              {t('offers_creation_confirmation_message')}
              <br />
              <br />
              {t('offers_creation_confirmation_message_not_saved')}
            </>
          )
        }
      >
        <ButtonsCustom classType="canceled" text={t('commun_button_cancel')} method={handleCloseDialog} />
        <ButtonsCustom classType="action_primary_big" text={t('commun_button_continue')} method={handleDialogConfim} />
      </ContentDialog>
    </FormLayoutWrapper>
  );
};

export default AddOffer;

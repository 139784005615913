import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import DotsLoader from 'components/Loaders/Dots';
import TabSwitch from 'components/TabSwitch';

import { ReactComponent as ResetIcon } from 'assets/resetIcon.svg';
import { ReactComponent as SelectedIcon } from 'assets/30px_check.svg';
import styles from './DashboardSupplier.module.scss';

import { supplierBudgetType } from 'types/supplierBudget';

type Props = {
  allSupplierBudgetList: supplierBudgetType[];
  isLoading: boolean;
  onSupplierBudgetIdsChange: (list: number[]) => void;
  onYearChange: (value: number) => void;
  selectedSupplierBudgetIds: number[];
  selectedYear: number;
};

const thisYear = new Date().getFullYear();

const Header = ({
  allSupplierBudgetList,
  isLoading,
  onSupplierBudgetIdsChange,
  onYearChange,
  selectedSupplierBudgetIds,
  selectedYear
}: Props): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    if (allSupplierBudgetList.length === 1) {
      onSupplierBudgetIdsChange([allSupplierBudgetList[0].id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSupplierBudgetList]);

  const yearTabs = [
    {
      label: t('supplier_budget_N', { year: thisYear - 2 }),
      isActive: selectedYear === thisYear - 2,
      onClick: () => {
        onYearChange(thisYear - 2);
      }
    },
    {
      label: t('supplier_budget_N', { year: thisYear - 1 }),
      isActive: selectedYear === thisYear - 1,
      onClick: () => {
        onYearChange(thisYear - 1);
      }
    },
    {
      label: t('supplier_budget_N', { year: thisYear }),
      isActive: selectedYear === thisYear,
      onClick: () => {
        onYearChange(thisYear);
      }
    }
  ];

  const handleResetFilters = () => {
    onSupplierBudgetIdsChange([]);
  };

  return (
    <div className={styles['header-container']}>
      <div className={styles['title-line']}>
        <p className={styles['title']}>{t('dashboard_global_perf_title')}</p>
        <TabSwitch isLoading={isLoading} tabs={yearTabs} />
      </div>
      {isLoading && (
        <div className={styles['empty-filters-container']}>
          <DotsLoader />
        </div>
      )}
      {!isLoading && !!allSupplierBudgetList.length && (
        <div className={styles['filters-container']}>
          {allSupplierBudgetList
            .reduce((acc: supplierBudgetType[], value: supplierBudgetType): supplierBudgetType[] => {
              const foundSameSupplierId = allSupplierBudgetList.filter((sb) => sb.supplier.id === value.supplier.id);

              let combinedSupplierRetailerNames = `${value.supplier.name} ${value.retailer.name}`;
              combinedSupplierRetailerNames =
                foundSameSupplierId.length > 1
                  ? `${combinedSupplierRetailerNames} - ${t('commun_budget_type_' + value.type)}`
                  : combinedSupplierRetailerNames;

              const newValue = {
                ...value,
                customName: combinedSupplierRetailerNames
              };
              acc.push(newValue);
              return acc;
            }, [])
            .map((sb: supplierBudgetType) => {
              const isSelected = selectedSupplierBudgetIds.includes(sb.id);
              return (
                <button
                  className={clsx(styles['filter-item'], isSelected && styles['selected'])}
                  disabled={allSupplierBudgetList.length === 1}
                  key={`${sb.id}-${sb.type}`}
                  onClick={() => {
                    let newList = [...selectedSupplierBudgetIds];
                    if (isSelected) {
                      newList = newList.filter((i) => i !== sb.id);
                    } else {
                      newList.push(sb.id);
                    }
                    onSupplierBudgetIdsChange(newList);
                  }}
                >
                  {isSelected && <SelectedIcon />}
                  {sb.customName}
                </button>
              );
            })}
          <button
            className={styles['reset-button']}
            disabled={
              !selectedSupplierBudgetIds.length || allSupplierBudgetList.length === selectedSupplierBudgetIds.length
            }
            id="supplier-budget-reset-filters-button"
            onClick={handleResetFilters}
          >
            <ResetIcon />
          </button>
        </div>
      )}
    </div>
  );
};

export default Header;

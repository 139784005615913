import React, { Fragment, memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { downloadErrorImportFile } from 'api';

import { offerImportStatuses as importedFileStatuses } from 'utils/constants';
import Accordion from 'components/Accordion';
import ButtonsCustom from 'components/ButtonsCustom';
import Loader from 'components/Loaders/Spinner_red';
import Tooltip from 'components/Tooltip';

import { ReactComponent as DownloadIcon } from 'assets/30px_download.svg';
import { ReactComponent as CloseIcon } from 'assets/cross_round_red.svg';
import { ReactComponent as FailedIcon } from 'assets/failed_picto.svg';
import { ReactComponent as FailSquareIcon } from 'assets/fail_rounded_square.svg';
import { ReactComponent as SuccessIcon } from 'assets/success_picto.svg';
import { ReactComponent as SuccessSquareIcon } from 'assets/success_rounded_square.svg';
import { ReactComponent as RequestedPicto } from 'assets/requested_picto.svg';
import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';

import styles from './ImportedFileFrame.module.scss';

const errorTypes = {
  CELL_FORMAT: 'CELL_FORMAT',
  COLUMN_FORMAT: 'COLUMN_FORMAT',
  FILE_FORMAT: 'FILE_FORMAT',
  FUNCTIONAL: 'FUNCTIONAL',
  TECHNICAL: 'TECHNICAL'
};

const ImportedFileFrame = ({ data, id, linkTo, simpleDisplay }) => {
  const { t } = useTranslation();
  const [areDetailsVisible, setAreDetailsVisible] = useState(false);
  const [errors, setErrors] = useState(null);
  const [loadingErrorFile, setLoadingErrorFile] = useState(false);
  const isSuccess = ![importedFileStatuses.FAIL, importedFileStatuses.FAIL_TO_PROCESS].includes(data.status);

  const downloadErrorFile = async () => {
    if (!loadingErrorFile) {
      setLoadingErrorFile(true);
      await downloadErrorImportFile(data.segmentId);
      setLoadingErrorFile(false);
    }
  };

  const toogleDetails = () => {
    // sort errors the first time user toggles details
    if (!isSuccess && !errors && data?.errors.length) {
      const errList = { FILE_FORMAT: [], CELL_FORMAT: [], COLUMN_FORMAT: [], TECHNICAL: [], FUNCTIONAL: [] };
      data.errors.forEach((err) => {
        errList[err.type].push(err);
      });
      if (!errList.CELL_FORMAT.length) {
        delete errList.CELL_FORMAT;
      }
      if (!errList.COLUMN_FORMAT.length) {
        delete errList.COLUMN_FORMAT;
      }
      setErrors(errList);
    }
    setAreDetailsVisible((state) => !state);
  };

  const displayDetailsError = () => {
    if (!errors) {
      return (
        <div className={styles['details-fail-block']}>
          <div className={styles['details-sub-accordion']}>
            <div className={styles['error-block']}>
              <ul>
                <li>{t('commun_error_technical')}</li>
              </ul>
            </div>
          </div>
        </div>
      );
    }

    const subSummary = (key) => {
      return (
        <div className={styles['sub-summary']}>
          <div>
            {errors[key].length ? <FailSquareIcon /> : <SuccessSquareIcon />}
            <span>{t(`use_case_import_file_error_type_${key}`, { count: errors[key].length })}</span>
          </div>
          {!!errors[key].length && (
            <div className={styles['error-count']}>{t('commun_error_count', { count: errors[key].length })}</div>
          )}
        </div>
      );
    };

    return (
      <div className={styles['details-fail-block']}>
        {Object.keys(errors).map((key) => {
          const hasError = !!errors[key].length;
          const lists = errors[key].reduce((acc, _el) => {
            if (!acc[_el.sheetName]) {
              acc[_el.sheetName] = [];
            }
            acc[_el.sheetName].push(_el);
            return acc;
          }, {});
          if (!hasError) {
            return (
              <div className={styles['details-sub-accordion']} key={key}>
                {subSummary(key)}
              </div>
            );
          }
          return (
            <Accordion
              classname={styles['details-sub-accordion']}
              hideExpandIcon={!hasError}
              key={key}
              id={key}
              summary={subSummary(key)}
            >
              <div className={styles['divider']}></div>
              <div className={styles['error-block']}>
                {Object.keys(lists).map((listKey) => {
                  if ([errorTypes.FUNCTIONAL, errorTypes.CELL_FORMAT, errorTypes.COLUMN_FORMAT].includes(key)) {
                    return (
                      <Fragment key={listKey}>
                        {listKey !== 'undefined' && (
                          <div>{t('use_case_import_file_error_group_head', { text: listKey })}</div>
                        )}
                        <ul>
                          {lists[listKey].map((el, index) => (
                            <li key={index}>
                              <div className={styles['li-with-link']}>
                                {el.line
                                  ? t('use_case_import_file_error_line', {
                                      line: el.line,
                                      field: el.fieldName,
                                      msg: el.message
                                    })
                                  : el.message}
                                {data.hasErrorFileUrl && index === lists[listKey].length - 1 && (
                                  <ButtonsCustom
                                    classType="link_primary_small"
                                    method={downloadErrorFile}
                                    startIconCustom={loadingErrorFile ? <Loader /> : <DownloadIcon />}
                                    text={t('segmentManager_button_download_import_error_file')}
                                  />
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </Fragment>
                    );
                  }
                  return (
                    <Fragment key={listKey}>
                      <ul>
                        {lists[listKey].map((el, index) => (
                          <li key={index}>{el.message}</li>
                        ))}
                      </ul>
                    </Fragment>
                  );
                })}
              </div>
            </Accordion>
          );
        })}
      </div>
    );
  };

  const displayDetailsSuccess = () => {
    const {
      numberCampaignsBR,
      numberCampaignsProduct,
      numberOffersBR,
      numberOffersProduct,
      numberProducts,
      numberSegments,
      numberSuppliers,
      numberTargetingStrategies,
      retailerCode
    } = data.infos;
    const dataToDisplay = [
      ['retailerCode', retailerCode],
      ['numberCampaignsBR', numberCampaignsBR],
      ['numberCampaignsProduct', numberCampaignsProduct],
      ['numberOffersBR', numberOffersBR],
      ['numberOffersProduct', numberOffersProduct],
      ['numberProducts', numberProducts],
      ['numberSegments', numberSegments],
      ['numberTargetingStrategies', numberTargetingStrategies],
      ['numberSuppliers', numberSuppliers]
    ];
    return (
      <div className={styles['details-success-block']}>
        {dataToDisplay.map((el) => (
          <p key={el[0]}>
            <span>{t(`commun_${el[0]}`)}</span>
            <span>{el[1] ?? '-'}</span>
          </p>
        ))}
      </div>
    );
  };

  const displayCustomExpansionIcon = () => {
    if (areDetailsVisible) {
      return (
        <div className={styles['closeIcon-button']}>
          <CloseIcon />
        </div>
      );
    } else {
      return <ButtonsCustom classType="link_primary" method={toogleDetails} text={t('commun_show_details')} />;
    }
  };

  const displaySummary = () => (
    <div className={styles['summary-block']}>
      <div>
        {data.status === importedFileStatuses.SUCCESS ? (
          <SuccessIcon />
        ) : data.status === importedFileStatuses.IN_PROCESS || data.status === importedFileStatuses.REQUESTED ? (
          <RequestedPicto />
        ) : (
          <FailedIcon />
        )}
        <div className={styles['info-block']}>
          <Tooltip title={data.filename}>
            <p>{data.filename}</p>
          </Tooltip>
          {data.requestDate && data.author && (
            <p className={styles['desc']}>
              {t('imported_on', {
                value: data.requestDate,
                text: data.author,
                datetime: data.requestDate
              })}
            </p>
          )}
          {data.requestDate && !data.author && (
            <p className={styles['desc']}>
              {t('imported_at', {
                value: data.requestDate,
                datetime: data.requestDate
              })}
            </p>
          )}
          {data.status === importedFileStatuses.SUCCESS && data.failedImageCount ? (
            <div className={styles['missing-image-count']}>
              {
                <>
                  <WarningIcon className={styles['warning-icon']} />
                  {t('_dyn_offer_import_list_failed_image', { count: data.failedImageCount })}
                </>
              }
            </div>
          ) : data.status === importedFileStatuses.SUCCESS && data.offerCount ? (
            <div className={styles['total-image-count']}>
              {t('_dyn_offer_import_list_added', { count: data.offerCount })}
            </div>
          ) : null}
        </div>
      </div>
      {!linkTo &&
        (data.status === importedFileStatuses.IN_PROCESS || data.status === importedFileStatuses.REQUESTED) && (
          <div className={styles['request-status-label']}>{t('import_requested')}</div>
        )}
      {linkTo && (
        <Link to={linkTo}>
          <ButtonsCustom classType="link_primary" text={t('commun_show_details')} />
        </Link>
      )}
    </div>
  );

  return (
    <div
      className={`${styles['root']} ${
        data.status !== importedFileStatuses.SUCCESS ? styles['status-' + data.status] : ''
      }`}
    >
      {simpleDisplay ||
      data.status === importedFileStatuses.IN_PROCESS ||
      data.status === importedFileStatuses.REQUESTED ? (
        displaySummary()
      ) : (
        <Accordion
          customIcon={displayCustomExpansionIcon()}
          expanded={areDetailsVisible}
          handleExpandedChange={toogleDetails}
          id={id}
          preventClickOnSummary
          summary={displaySummary()}
          TransitionProps={{
            timeout: {
              enter: 500,
              exit: 150
            }
          }}
        >
          {isSuccess ? displayDetailsSuccess() : displayDetailsError()}
        </Accordion>
      )}
    </div>
  );
};

ImportedFileFrame.propTypes = {
  data: PropTypes.object.isRequired,
  id: PropTypes.string,
  linkTo: PropTypes.string,
  simpleDisplay: PropTypes.bool
};

export default memo(ImportedFileFrame);

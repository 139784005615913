import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import SingleDatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DatePickerCalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as CalendarArrowLeftIcon } from 'assets/calendar_arrow_left.svg';
import { ReactComponent as CalendarArrowRightIcon } from 'assets/calendar_arrow_right.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';

const userLocale = navigator.language.split('-')[0];

const DatePicker = ({ date, disabled, id, onDateChange, placeholder }, props) => {
  const { t } = useTranslation();

  const monthsList = [
    t('commun_month_full_01'),
    t('commun_month_full_02'),
    t('commun_month_full_03'),
    t('commun_month_full_04'),
    t('commun_month_full_05'),
    t('commun_month_full_06'),
    t('commun_month_full_07'),
    t('commun_month_full_08'),
    t('commun_month_full_09'),
    t('commun_month_full_10'),
    t('commun_month_full_11'),
    t('commun_month_full_12')
  ];

  const yearsList = useMemo(() => {
    const currYear = moment().year();
    const pastYear = currYear - 9;
    const futurYear = currYear + 20;
    const arr = [];
    let counter = 0;
    do {
      arr.push(pastYear + counter);
      counter += 1;
    } while (counter + pastYear < futurYear);
    return arr;
  }, []);

  const CustomHeader = ({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }) => {
    return (
      <div className="custom-header">
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          <CalendarArrowLeftIcon />
        </button>

        <select
          value={monthsList[date.getMonth()]}
          onChange={({ target: { value } }) => changeMonth(monthsList.indexOf(value))}
        >
          {monthsList.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
          {yearsList.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>

        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <CalendarArrowRightIcon />
        </button>
      </div>
    );
  };

  const handleOnChange = (d) => {
    onDateChange(d ? moment(d) : null);
  };

  return (
    <div className="custom-date-picker-container">
      <SingleDatePicker
        autoComplete="off"
        autoFocus={false}
        dateFormat={userLocale === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
        disabled={disabled}
        disabledKeyboardNavigation
        icon={<DatePickerCalendarIcon />}
        id={id}
        isClearable
        onChange={handleOnChange}
        placeholderText={placeholder}
        popperClassName="custom-popper"
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [10, -5]
            }
          }
        ]}
        renderCustomHeader={CustomHeader}
        selected={date?.toDate()}
        showIcon
        showPopperArrow={false}
        {...props}
      />
    </div>
  );
};

DatePicker.propTypes = {
  date: PropTypes.instanceOf(moment),
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

export default DatePicker;

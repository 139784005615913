/* eslint-disable react/display-name */
import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { marketingStrategiesCodes, segmentedMarketingStrategyCodes } from 'utils/constants';

import AutocompleteSelect from 'components/AutocompleteCustom/AutocompleteSelect';
import FormManager from 'components/FormManager';

import 'components/FormManager/FormManager.scss';
import styles from './AddOfferStep2.module.scss';

export const oldSuffix = '_old';

const targeting_code_to_i18n_Mapping = {
  UNTARGETABLE: 11,
  SMART: 12,
  GENERIC: 13,
  INNOVATION: 14,
  LOYAL: 15,
  LOST: 16,
  CONVERT: 18,
  LOOK_ALIKE: 19
};

const TargetingStrategiesBlock = ({
  currentRetailer,
  currentSegmentId,
  currentStrategyId,
  disabled,
  errors,
  isDuplicating,
  isEditMode,
  segments,
  targetingStrategies,
  updateOfferCreation
}) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state } = globalState;
  const currentOffer = state.offers.details.offer;
  const [listData, setListData] = useState([]);

  // for look_alike, lost, loyal targetingStrategy codes
  // need to add "_old" suffix to the targetingStrategy id of old existing offers that are using old targetingStrategies
  // or the radio buttons will select 2 options when the old strategy added to the offer is one of existing and reused new targetingStrategy
  // the difference for distinguishing the use of old or new targetingStrategy is in the presence of offerHead.externalSegment.id for new segmented targetingStrategies
  // like this, it will match the id transformed in mapStrategiesToListData
  // '_old' will be removed from the id before submitting the form in AddOfferStepValidation component
  const strategyId = currentStrategyId
    ? isEditMode &&
      currentStrategyId === currentOffer.targetingStrategy?.id?.toString() &&
      !currentOffer.offerHead?.externalSegment?.id &&
      segmentedMarketingStrategyCodes.includes(currentOffer.targetingStrategy.targetMarketing.code)
      ? currentStrategyId.replace(oldSuffix, '') + oldSuffix // prevents infinite loop
      : currentStrategyId
    : '';

  // smart & generic
  const renderRadioButtonLabel = (targetMarketing) => (
    <div>
      <div>
        {t(`commun_marketingStrategy_${targetMarketing.code}_title`)}
        {targetMarketing.code === marketingStrategiesCodes.SMART && (
          <span className={styles['ai-tag']}>{t('commun_AI')}</span>
        )}
      </div>
      <small>
        <i>{t(`offers_creation_tips_${targeting_code_to_i18n_Mapping[targetMarketing.code]}_2`)}</i>
      </small>
    </div>
  );

  // fid, reac, rec
  const renderSegmentedRadioButtonLabel = (id, targetMarketing) => (
    <div>
      <div>{t(`commun_marketingStrategy_${targetMarketing.code}_title`)}</div>
      {/* display segment selection only when this strategy is selected */}
      {currentStrategyId === id && (
        <div className={styles['segment-block']}>
          <AutocompleteSelect
            disabled={disabled || !segments.length}
            disablePortal
            id="select-segment"
            labelAttribute="name"
            listData={segments}
            onSelection={(option) => {
              updateOfferCreation({
                step2: {
                  externalSegment: { id: option?.id }
                }
              });
            }}
            placeholderText={t('campaign_cash_coupon_step2_form_segment_place_holder')}
            useCustomSegmentOption
            value={segments.find((seg) => seg.id === currentSegmentId)}
          />
          {!segments.length && <div className={styles['error-msg']}>{t('commun_no_compatible_segment')}</div>}
        </div>
      )}
    </div>
  );

  // old fid, old reac, old rec
  const renderOldRadioButtonLabel = (code, targetMarketing) => (
    <div>
      <div>{t(`offers_creation_tips_${targeting_code_to_i18n_Mapping[code]}_1`)}</div>
      <small>
        {targetMarketing.title} - <i>{t(`offers_creation_tips_${targeting_code_to_i18n_Mapping[code]}_2`)}</i>
      </small>
    </div>
  );

  const mapStrategiesToListData = useCallback(() => {
    if (targetingStrategies.length) {
      // always displayed
      const smartStrategy = targetingStrategies.find((s) => s.targetMarketing.code === marketingStrategiesCodes.SMART);
      const genericStrategy = targetingStrategies.find(
        (s) => s.targetMarketing.code === marketingStrategiesCodes.GENERIC
      );
      let radioButtonGroupValues = [smartStrategy, genericStrategy];

      // displayed only when retailer has offerSegmentEnabled property
      if (currentRetailer?.offerSegmentEnabled) {
        const recStrategy = targetingStrategies.find((s) => s.targetMarketing.code === marketingStrategiesCodes.REC);
        const fidStrategy = targetingStrategies.find((s) => s.targetMarketing.code === marketingStrategiesCodes.FID);
        const reacStrategy = targetingStrategies.find((s) => s.targetMarketing.code === marketingStrategiesCodes.REAC);
        radioButtonGroupValues = [...radioButtonGroupValues, recStrategy, fidStrategy, reacStrategy];
      }

      // displayed only when editing an offer with old targetingStrategy
      // currentOffer is filled only when editing an offer
      if (
        isEditMode &&
        currentOffer.targetingStrategy &&
        ![marketingStrategiesCodes.SMART, marketingStrategiesCodes.GENERIC].includes(
          currentOffer.targetingStrategy?.targetMarketing?.code
        ) &&
        !currentOffer.offerHead?.externalSegment?.id
      ) {
        const oldStrategy = { ...currentOffer.targetingStrategy };
        oldStrategy.id = oldStrategy.id.toString().replace(oldSuffix, '') + oldSuffix; // prevents adding _old suffix many times
        radioButtonGroupValues = [...radioButtonGroupValues, oldStrategy];
      }

      setListData(
        radioButtonGroupValues.map(({ code, id, targetMarketing }) => {
          const isSegmentedMarketingStrategyCode = segmentedMarketingStrategyCodes.includes(targetMarketing.code);
          const isOldTargetingStrategy = id.toString().includes(oldSuffix);
          return {
            disabled: false,
            label: isSegmentedMarketingStrategyCode
              ? isOldTargetingStrategy
                ? renderOldRadioButtonLabel(code, targetMarketing)
                : renderSegmentedRadioButtonLabel(id.toString(), targetMarketing)
              : renderRadioButtonLabel(targetMarketing),
            value: id.toString()
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOffer, currentRetailer, currentSegmentId, currentStrategyId, segments, targetingStrategies]);

  useEffect(() => mapStrategiesToListData(), [targetingStrategies, mapStrategiesToListData]);

  const handleChange = (value) => {
    updateOfferCreation({
      step2: {
        externalSegment: {
          id:
            (!isEditMode && !isDuplicating) ||
            (currentOffer.targetingStrategy && value !== currentOffer.targetingStrategy?.id?.toString())
              ? null
              : currentSegmentId
        },
        targetingStrategy: { id: value }
      }
    });
  };

  const displayErrorMsg = () => {
    if (errors.targetError) {
      return t('offers_creation_target_marketing_error');
    }
    if (errors.segmentError) {
      return t('offers_creation_target_marketing_segment_error');
    }
    return '';
  };

  return (
    <FormManager
      data={{
        title: t('offers_creation_block_marketingStrategy_title'),
        fieldsets: [
          {
            id: 'add-offer-targetingStrategy-step',
            fields: [
              {
                display: isEditMode && (!strategyId || !currentOffer?.id || !targetingStrategies.length),
                id: 'select-strategy-loading',
                type: 'CustomContent',
                component: <div>{t('commun_loading')}</div>
              },
              {
                type: 'RadioGroup',
                // default to empty value to get radio group as controlled because passing undefined will set it as uncontrolled
                defaultValue: strategyId || '',
                fieldProps: { listData, row: false },
                onFieldChange: (value) => {
                  if (value) {
                    handleChange(value);
                  }
                },
                id: 'select-strategy',
                disabled,
                error: errors.targetError || errors.segmentError,
                errorMsg: displayErrorMsg()
              }
            ]
          }
        ]
      }}
    />
  );
};

TargetingStrategiesBlock.propTypes = {
  currentRetailer: PropTypes.object,
  currentSegmentId: PropTypes.number,
  currentStrategyId: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  isDuplicating: PropTypes.bool,
  isEditMode: PropTypes.bool,
  segments: PropTypes.array,
  targetingStrategies: PropTypes.array,
  updateOfferCreation: PropTypes.func
};

export default memo(TargetingStrategiesBlock);

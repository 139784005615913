import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { offerTypes } from 'utils/constants';
import useDynId from 'customHooks/useDynId';
import ButtonsCustom from 'components/ButtonsCustom';
import TableCustom from 'components/TableCustom';

import { ReactComponent as CrossIcon } from 'assets/22px_croix.svg';
import styles from './OfferList.module.scss';

const OfferList = ({
  campaignProduct,
  isAddingOffers,
  isLoadingCleaningList,
  onClearList,
  onDeleteOne,
  page,
  tableSize,
  updatePage
}) => {
  const { t } = useTranslation();

  const totalOffers = campaignProduct.offersCount;
  const columns = [
    { id: 1, headerName: '', field: 'id' },
    { id: 2, headerName: '', field: 'title', hasTooltip: true },
    { id: 3, headerName: '', field: 'marketingStrategy' },
    { id: 4, headerName: '', field: 'delete', type: 'component', align: 'right' }
  ];
  const offersParsed = campaignProduct.offers.map((i) => ({
    id: [{ value: i.offerHead.id, color: 'ean', textStyle: 'tag' }],
    title: [
      { value: i.offerHead?.title, color: 'default' },
      { value: i.offerHead?.descriptionTag, color: 'proposal' }
    ],
    marketingStrategy: [
      { value: t(`commun_marketingStrategy_${i.targetingStrategy.targetMarketing.code}_title`), color: 'disabled' },
      {
        value: i.type === offerTypes.SUPPLIER_PRODUCT_OFFER ? i.supplier?.name : i.retailer?.name,
        color: 'disabled'
      }
    ],
    delete: (
      <div
        className={styles['cross-icon']}
        onClick={async () => {
          await onDeleteOne(i.offerCampaignId);
        }}
        role="button"
      >
        <CrossIcon />
      </div>
    )
  }));

  const handleNextPage = () => {
    updatePage(1);
  };

  const handlePreviousPage = () => {
    updatePage(-1);
  };

  const step2ClearOfferListId = useDynId('step2ClearOfferList');
  return (
    <div className={styles['root']}>
      <div className={styles['section-title']}>{t('campaign_product_step2_offers_list_title')}</div>
      {!isAddingOffers && !isLoadingCleaningList && !offersParsed.length ? (
        <span className={styles['no-result']}>{t('campaign_product_step2_no_offer')}</span>
      ) : (
        <div className={styles['offer-list']}>
          <TableCustom
            columns={columns}
            rows={offersParsed}
            total={totalOffers}
            page={page}
            size={tableSize}
            prev={handlePreviousPage}
            next={handleNextPage}
            type="medium"
            showHeader={false}
            isLoading={isAddingOffers || isLoadingCleaningList}
          />
          <div className={styles['specific-table-bottom']}>
            <span className={styles['count']}>{totalOffers}</span>
            {t('commun_product_offer', { count: totalOffers })}
            <span className={styles['separator']}>|</span>
            <ButtonsCustom
              classType="link_primary_small"
              text={t('commun_button_clear_list')}
              method={onClearList}
              disabled={isAddingOffers || isLoadingCleaningList}
              id={step2ClearOfferListId}
            />
          </div>
        </div>
      )}
    </div>
  );
};

OfferList.propTypes = {
  campaignProduct: PropTypes.object.isRequired,
  isAddingOffers: PropTypes.bool.isRequired,
  isLoadingCleaningList: PropTypes.bool.isRequired,
  onClearList: PropTypes.func.isRequired,
  onDeleteOne: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  tableSize: PropTypes.number.isRequired,
  updatePage: PropTypes.func.isRequired
};

export default memo(OfferList);

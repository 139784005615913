import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Popover } from '@mui/material';
import ButtonsCustom from 'components/ButtonsCustom';

import { ReactComponent as CloseIcon } from 'assets/22px_croix.svg';
import { ReactComponent as CalendarIcon } from 'assets/24px_calendrier.svg';
import styles from './DateYearPicker.module.scss';

const DateYearPicker = ({ loading, onValidation, value, yearList = [] }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  const handleClick = (event) => {
    if (!loading) {
      setState(value);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (val) => {
    setState(val);
  };

  const handleValidation = () => {
    onValidation?.(state);
    setAnchorEl(null);
  };

  return (
    <div className={styles['root']}>
      <div
        className={`${styles['display-container']} ${loading ? styles['disabled'] : ''}`}
        onClick={handleClick}
        role="button"
      >
        {loading || !value ? '-' : value}
        <CalendarIcon className={styles['calendar-icon']} />
      </div>

      <Popover
        id={'date-year-picker'}
        classes={{ root: styles['popper'], paper: styles['paper'] }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disablePortal
        anchorOrigin={{
          vertical: 55,
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={styles['paper-container']}>
          {/* {popperContent} */}
          <div className={styles['popper-content']}>
            {yearList.map((el) => (
              <div
                className={`${styles['item']} ${el === state ? styles['selected'] : ''}`}
                key={el}
                onClick={() => {
                  handleSelection(el);
                }}
                role="button"
              >
                <div>{el}</div>
              </div>
            ))}
          </div>

          <div className={styles['popper-validation']}>
            <ButtonsCustom
              classType="action_secondary"
              id="date-year-picker-popover-button-apply"
              text={t('commun_apply')}
              method={handleValidation}
            />
          </div>
          <span className={styles['popper-arrow']}></span>
          <span className={styles['popper-close-icon']}>
            <CloseIcon onClick={handleClose} />
          </span>
        </div>
      </Popover>
    </div>
  );
};

DateYearPicker.propTypes = {
  loading: PropTypes.bool,
  onValidation: PropTypes.func,
  value: PropTypes.number,
  yearList: PropTypes.arrayOf(PropTypes.number),
};

export default DateYearPicker;

/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './FormStepTitle.module.scss';

const FormStepTitle = ({ isOptional, marginTop, smallSubtitle, step, subtitle, title }) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles['root']} ${marginTop ? styles['marginTop-' + marginTop] : ''}`}>
      <div>
        {step && <span className={styles['step-number']}>{step}.</span>}
        {title}
        {isOptional && <i>{t('commun_optional_with_parentheses')}</i>}
      </div>
      {subtitle && <div className={styles['step-desc']}>{subtitle}</div>}
      {smallSubtitle && <div className={styles['step-desc-small']}>{smallSubtitle}</div>}
    </div>
  );
};

FormStepTitle.propTypes = {
  marginTop: PropTypes.oneOf(['small', 'medium', 'big']),
  smallSubtitle: PropTypes.string,
  step: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default FormStepTitle;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { formatFloat, withFractionDigits } from 'utils/global';

import styles from './SummaryText.module.scss';

const SummaryText = ({ currency, discountUnits, step3 }) => {
  const { t } = useTranslation();
  const { discountType, generosity, quantity } = step3;

  const basketThresholdValue = t('offers_creation_count_generosity_count', { count: quantity?.value || 0 });
  const value = parseFloat(formatFloat(generosity.value));
  const maximumFractionDigits = withFractionDigits(value);

  const generosityValue =
    generosity.unit === 1
      ? `${t('commun_number', {
          value,
          maximumFractionDigits,
        })} ${discountUnits.find((i) => i.id === generosity.unit)?.label}`
      : t('commun_price', {
          currency,
          maximumFractionDigits,
          value,
        });

  return (
    <div className={styles['root']}>
      <span>{t('offers_creation_promotion_block_summary')}</span>{' '}
      <Trans
        i18nKey={`offers_creation_summary_type_${discountType.id === 1 ? 'loyalty' : 'immediate'}`}
        values={{ basketThresholdValue, generosityValue }}
      />
    </div>
  );
};

SummaryText.propTypes = { currency: PropTypes.string, discountUnits: PropTypes.array, step3: PropTypes.object };

export default SummaryText;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withFractionDigits } from 'utils/global';
import styles from './RoiFrame.module.scss';

const Roi = ({ roi }) => {
  const { t } = useTranslation();
  return (
    <div className={styles['roi']}>
      <span>{t('campaign_details_kpi_cashcoupon_segment_returnOnInvestment')}</span>
      <span>
        {t('commun_number', {
          value: roi ?? '-',
          maximumFractionDigits: withFractionDigits(roi),
        })}
      </span>
    </div>
  );
};

Roi.propTypes = { roi: PropTypes.number };

export default memo(Roi);

import React from 'react';
import PropTypes from 'prop-types';

import AutocompleteTag from 'components/AutocompleteCustom/AutocompleteTag';

const Autocomplete = (props) => {
  const {
    className,
    data: { id, defaultValue, disabled, placeholder, fieldProps },
    onChange,
  } = props;

  const handleChange = (item) => {
    if (onChange) onChange(id, item);
  };

  return (
    <AutocompleteTag
      className={className}
      disabled={disabled}
      id={id}
      onSelection={handleChange}
      placeholderText={placeholder}
      value={defaultValue}
      {...fieldProps}
    />
  );
};

Autocomplete.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.array,
  onChange: PropTypes.func,
};

export default Autocomplete;

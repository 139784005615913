import React, { useState, useEffect, useContext, useRef } from 'react';
import { store } from 'store';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { exitModalTypes } from 'utils/constants';
import moment from 'moment-timezone';
import {
  deleteCampaign,
  getAllSuppliers,
  getCampaign,
  getOffersCompatibleCount,
  getTargetingStrategies,
  postProductCampaign,
  postProductCampaignClone,
  updateOriginalProductCampaignEdition,
  updateProductCampaign,
  updateProductCampaignCreation
} from 'api';
import { useTranslation } from 'react-i18next';
import useDynId from 'customHooks/useDynId';
import { initialState } from 'store/initialState';

import ButtonsCustom from 'components/ButtonsCustom';
import CampaignKeypoints from 'components/AddCampaign/ProductSteps/ProductStep4/CampaignKeypoints';
import ContentDialog from 'components/ContentDialog';
import DistributionChannels from 'components/AddOfferSteps/DistributionChannels';
import FooterButtonsContainer from 'components/LayoutWrappers/Form/FooterButtonsContainer';
import FormLayoutWrapper from 'components/LayoutWrappers/Form';
import RetailerSegmentSelection from 'components/AddCampaign/ProductSteps/RetailerSegmentSelection';

import ProductStep1 from 'components/AddCampaign/ProductSteps/ProductStep1';
import ProductStep2 from 'components/AddCampaign/ProductSteps/ProductStep2';
import ProductStep3 from 'components/AddCampaign/ProductSteps/ProductStep3';
import ProductStep4 from 'components/AddCampaign/ProductSteps/ProductStep4';

const initState = initialState.campaignCreation.product;

const ProductSteps = ({ personalized = true }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const {
    campaignCreation: { product: campaignState },
    campaigns: { details: originalCampaign },
    targetingStrategies
  } = state;
  const isEditMode = !!id;

  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const step3Ref = useRef(null);

  const [displayValidationStep, setDisplayValidationStep] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openLeaveDialog, setOpenLeaveDialog] = useState(false);
  const [openConfirmationSavedDialog, setOpenConfirmationSavedDialog] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [tooltipSaveErrorMsg, setTooltipSaveErrorMsg] = useState(null);

  const [initProcessDone, setInitProcessDone] = useState(false);
  const [prevDates, setPrevDates] = useState({
    start: campaignState.validityStartDate,
    end: campaignState.validityEndDate
  });

  useEffect(() => {
    const getCampaignData = async () => {
      setIsLoading(true);
      const data = await getCampaign(id);

      if (data) {
        // use the data to create a new draft 'clone' campaign
        const payload = {
          ...campaignState,
          campaignType: 'PRODUCT',
          distributionChannels: data.distributionChannels,
          endDate: moment(data.validityEndDate),
          id: null,
          parameters: data.paramsOfferCampaign ? { ...data.paramsOfferCampaign } : campaignState.parameters,
          retailer: { id: data.retailer.id },
          retailerSegment: { ...data.retailerSegment },
          startDate: moment(data.validityStartDate),
          targetingKpisOfferCampaign: { ...data.targetingKpisOfferCampaign },
          targetingStatus: data.targetingStatus,
          targetingUrl: data.targetingUrl,
          title: data.title
        };
        dispatch({ type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE', payload });
      } else {
        history.push({ pathname: '/campaigns', search: history.location.search });
      }

      setIsLoading(false);
    };

    if (id) {
      getCampaignData();
    }
    if (!targetingStrategies.length) {
      getTargetingStrategies();
    }

    getAllSuppliers();

    return () => {
      dispatch({ type: 'CAMPAIGN_CREATION_RESET' });
      dispatch({ type: 'CAMPAIGNS_RESET' });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const campaignDraftCreation = async () => {
      setIsLoading(true);
      let hasError = false;

      if (!step1Ref.current.step1CheckHasError()) {
        if (!campaignState.id) {
          const createdCampaign = await postProductCampaign({
            campaign: { ...campaignState, personalized },
            userId: state.user.id
          });
          if (!!createdCampaign) {
            setPrevDates({
              start: moment(createdCampaign.validityStartDate),
              end: moment(createdCampaign.validityEndDate)
            });
          }
        }
      } else {
        hasError = true;
      }

      if (!hasError) {
        setInitProcessDone(true);
      }
      setIsLoading(false);
    };

    const cloneCampaignForEdition = async () => {
      setIsLoading(true);
      const success = await postProductCampaignClone(originalCampaign);
      if (success) {
        setInitProcessDone(true);

        setPrevDates({
          start: moment(originalCampaign.validityStartDate),
          end: moment(originalCampaign.validityEndDate)
        });
      }
      setIsLoading(false);
    };

    if (!initProcessDone && !isLoading && campaignState.title && campaignState.startDate && campaignState.endDate) {
      if (!id) {
        campaignDraftCreation();
      } else if (id && originalCampaign.id) {
        cloneCampaignForEdition();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignState.title,
    campaignState.startDate,
    campaignState.endDate,
    initProcessDone,
    isLoading,
    originalCampaign.id
  ]);

  useEffect(() => {
    // update leave modal for AppNavigation clicks
    if (campaignState.title || campaignState.startDate || campaignState.endDate) {
      dispatch({
        type: 'EXIT_MODAL_TYPE_UPDATE',
        payload: id ? exitModalTypes.CAMPAIGN_EDITION : exitModalTypes.CAMPAIGN_PRODUCT
      });
    }

    return () => {
      dispatch({
        type: 'EXIT_MODAL_TYPE_UPDATE',
        payload: null
      });
    };
  }, [dispatch, campaignState.title, campaignState.startDate, campaignState.endDate, id]);

  useEffect(() => {
    const updateCampaign = async () => {
      setIsLoading(true);

      // save previous dates
      setPrevDates({ start: campaignState.startDate, end: campaignState.endDate });
      // update with new Dates
      const updatedCampaign = await updateProductCampaign(campaignState);
      await getOffersCompatibleCount({
        campaignProduct: updatedCampaign,
        filters: { id: [], title: '' }
      });
      // reset filters
      step2Ref.current.filtersRef.current.resetAllFilters();
      // delete added offers
      dispatch({ type: 'CAMPAIGN_CREATION_PRODUCT_OFFERS_RESET' });

      setIsLoading(false);
    };

    if (
      initProcessDone &&
      !isLoading &&
      campaignState.startDate &&
      campaignState.endDate &&
      prevDates.start &&
      prevDates.end &&
      (!moment(prevDates.start).isSame(campaignState.startDate, 'day') ||
        !moment(prevDates.end).isSame(campaignState.endDate, 'day'))
    ) {
      updateCampaign();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignState.startDate, campaignState.endDate]);

  useEffect(() => {
    document
      .getElementById('form-layout-wrapper-header-container')
      ?.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'instant' });
  }, [displayValidationStep]);

  const handleCancelCampaign = () => {
    if (JSON.stringify(campaignState) !== JSON.stringify(initState)) {
      setOpenLeaveDialog(true);
    } else {
      handleLeaveDialogConfim();
    }
  };

  const handleValidation = async () => {
    const step1HasError = step1Ref.current.step1CheckHasError();
    const step2HasError = step2Ref.current.step2CheckHasError();
    const step3HasError = personalized && step3Ref.current.step3CheckHasError();

    if (!step1HasError && !step2HasError && !step3HasError) {
      if (personalized) {
        setIsLoading(true);
        const success = await updateProductCampaign(campaignState);
        setIsLoading(false);
        if (success) {
          setDisplayValidationStep(true);
        }
      } else {
        handleSave();
      }
    }
  };

  const handleSave = async () => {
    setSaveIsLoading(true);
    const response = isEditMode
      ? await updateOriginalProductCampaignEdition({
          campaign: campaignState,
          originalCampaign
        })
      : await updateProductCampaignCreation(campaignState);
    setSaveIsLoading(false);

    if (response === true) {
      // display info popup only if in creation mode
      if (isEditMode) {
        deleteCampaign({ campaign: campaignState });
        handleConfirmationSavedDialogClose();
      } else {
        setOpenConfirmationSavedDialog(true);
      }
    } else {
      setTooltipSaveErrorMsg(response.msg);
    }
  };

  const handleConfirmationSavedDialogClose = () => {
    history.push(`/campaigns/${originalCampaign?.id || campaignState.id}/offers`);
  };

  const handleLeaveDialogConfim = async () => {
    setIsLoading(true);
    let noApiError = true;
    // delete productCampaign if exists in Draft status
    if (campaignState.id) {
      noApiError = await deleteCampaign({ campaign: campaignState });
    }

    if (noApiError) {
      history.push(id ? `/campaigns/${originalCampaign.id}` : '/campaigns/add-campaign');
    } else {
      setIsLoading(false);
    }
  };

  const handleLeaveDialogCancel = () => {
    setOpenLeaveDialog(false);
  };

  const handleStepBack = () => {
    setDisplayValidationStep(false);
  };

  return (
    <>
      <FormLayoutWrapper
        headerTitle={
          displayValidationStep
            ? t('campaign_visualize_your_campaign_label')
            : id
            ? personalized
              ? t('campaign_product_edition_timeline_title')
              : t('campaign_product_edition_timeline_title_non_personalized')
            : personalized
            ? t('campaign_product_creation_timeline_title')
            : t('campaign_product_creation_timeline_title_non_personalized')
        }
        headerSubTitle={
          displayValidationStep ? t('campaign_product_step4_desc') : t('campaign_product_creation_timeline_subtitle')
        }
        onClose={handleCancelCampaign}
      >
        <div className={displayValidationStep ? 'd-hidden' : ''}>
          <ProductStep1
            campaignProduct={campaignState}
            disabled={isLoading}
            dispatch={dispatch}
            isEditMode={!!id}
            ref={step1Ref}
          />
          {!personalized && <DistributionChannels disabled={!initProcessDone} />}
          <ProductStep2
            campaignProduct={campaignState}
            disabled={!initProcessDone}
            isLoading={isLoading}
            isEditMode={!!id}
            strategyData={targetingStrategies}
            suppliersData={state.suppliers}
            ref={step2Ref}
          />

          {personalized && (
            <ProductStep3
              campaignProduct={campaignState}
              disabled={!initProcessDone}
              dispatch={dispatch}
              isEditMode={!!id}
              isLoading={isLoading}
              settingProductLevelLabelThree={state.user?.settingProductLevelLabelThree || ''}
              ref={step3Ref}
            />
          )}

          <RetailerSegmentSelection disabled={!initProcessDone} />

          <CampaignKeypoints targetingKpisOfferCampaign={campaignState?.targetingKpisOfferCampaign} />

          <FooterButtonsContainer
            confirmLabel={t(
              personalized ? 'campaign_creation_footer_button_visualization' : 'commun_button_save_campaign'
            )}
            disabled={!initProcessDone}
            isLoading={isLoading || saveIsLoading}
            onConfirm={handleValidation}
          />
        </div>

        {displayValidationStep && (
          <ProductStep4
            campaignProduct={campaignState}
            handlePreviousStep={handleStepBack}
            handleSave={handleSave}
            isLoading={saveIsLoading}
            settingProductLevelLabelThree={state.user?.settingProductLevelLabelThree || ''}
            tooltipSaveErrorMsg={tooltipSaveErrorMsg}
          />
        )}
      </FormLayoutWrapper>

      {/* Save confirm popup */}
      <ContentDialog
        centerText
        isOpen={openConfirmationSavedDialog}
        handleClose={handleConfirmationSavedDialogClose}
        title={t('campaign_modal_validation_title')}
        desc={t(personalized ? 'campaign_modal_validation_desc' : 'campaign_modal_validation_desc_non_personalized')}
        maxWidth="xs"
      >
        <div className="confirm-dialog">
          <ButtonsCustom
            classType="action_primary_big"
            text={t('commun_button_got_it')}
            method={handleConfirmationSavedDialogClose}
            id={useDynId('step4Confirm')}
          />
        </div>
      </ContentDialog>

      {/* leave popup */}
      <ContentDialog
        centerText
        isLoading={isLoading}
        isOpen={openLeaveDialog}
        handleClose={handleLeaveDialogCancel}
        title={id ? t('campaign_edition_leave_modal_message') : t('campaign_creation_leave_modal_message')}
        maxWidth="xs"
      >
        <div className="confirm-dialog">
          <ButtonsCustom
            id={useDynId('step1CancelModal')}
            classType="canceled"
            disabled={isLoading}
            text={t('commun_button_cancel')}
            method={handleLeaveDialogCancel}
          />
          <ButtonsCustom
            id={useDynId('step1ConfirmModal')}
            classType="action_primary_big"
            loading={isLoading}
            text={t('commun_button_continue')}
            method={handleLeaveDialogConfim}
          />
        </div>
      </ContentDialog>
    </>
  );
};

export default ProductSteps;

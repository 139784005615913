import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { scrollToElementById } from 'utils/global';

import FormManager from 'components/FormManager';

const ProductsRepository = forwardRef(({ onChangeState, retailerState }, ref) => {
  const { t } = useTranslation();

  const nameRegex = /^[\s\w\u00C0-\u00FF-&()]*$/;

  const [errorMsgSettingProductLevelLabelOne, setErrorMsgSettingProductLevelLabelOne] = useState(null);
  const [errorMsgSettingProductLevelLabelTwo, setErrorMsgSettingProductLevelLabelTwo] = useState(null);
  const [errorMsgSettingProductLevelLabelThree, setErrorMsgSettingProductLevelLabelThree] = useState(null);
  const [errorMsgSettingProductLevelLabelFour, setErrorMsgSettingProductLevelLabelFour] = useState(null);

  useImperativeHandle(ref, () => ({
    checkHasError() {
      return handleCheckError();
    },
  }));

  const handleCheckError = () => {
    let errorFieldIdList = [];

    if (retailerState.settingProductLevelLabelOne && !nameRegex.test(retailerState.settingProductLevelLabelOne)) {
      setErrorMsgSettingProductLevelLabelOne(t('retailers_creation_label_one_error_format'));
      errorFieldIdList.push('field-retailer-creation-form-products-title');
    } else if (retailerState.settingProductLevelLabelOne > 255) {
      setErrorMsgSettingProductLevelLabelOne(t('retailers_creation_label_error_length'));
      errorFieldIdList.push('field-retailer-creation-form-products-title');
    } else {
      setErrorMsgSettingProductLevelLabelOne();
    }

    if (!retailerState.settingProductLevelLabelTwo) {
      setErrorMsgSettingProductLevelLabelTwo(t('retailers_creation_label_two_error_required'));
      errorFieldIdList.push('field-settingProductLevelLabelOne');
    } else if (!nameRegex.test(retailerState.settingProductLevelLabelTwo)) {
      setErrorMsgSettingProductLevelLabelTwo(t('retailers_creation_label_two_error_format'));
      errorFieldIdList.push('field-settingProductLevelLabelOne');
    } else if (retailerState.settingProductLevelLabelTwo > 255) {
      setErrorMsgSettingProductLevelLabelTwo(t('retailers_creation_label_error_length'));
      errorFieldIdList.push('field-settingProductLevelLabelOne');
    } else {
      setErrorMsgSettingProductLevelLabelTwo();
    }

    if (!retailerState.settingProductLevelLabelThree) {
      setErrorMsgSettingProductLevelLabelThree(t('retailers_creation_label_three_error_required'));
      errorFieldIdList.push('field-settingProductLevelLabelOne');
    } else if (!nameRegex.test(retailerState.settingProductLevelLabelThree)) {
      setErrorMsgSettingProductLevelLabelThree(t('retailers_creation_label_three_error_format'));
      errorFieldIdList.push('field-settingProductLevelLabelOne');
    } else if (retailerState.settingProductLevelLabelThree > 255) {
      setErrorMsgSettingProductLevelLabelThree(t('retailers_creation_label_error_length'));
      errorFieldIdList.push('field-settingProductLevelLabelOne');
    } else {
      setErrorMsgSettingProductLevelLabelThree();
    }

    if (!retailerState.settingProductLevelLabelFour) {
      setErrorMsgSettingProductLevelLabelFour(t('retailers_creation_label_four_error_required'));
      errorFieldIdList.push('field-settingProductLevelLabelOne');
    } else if (!nameRegex.test(retailerState.settingProductLevelLabelFour)) {
      setErrorMsgSettingProductLevelLabelFour(t('retailers_creation_label_four_error_format'));
      errorFieldIdList.push('field-settingProductLevelLabelOne');
    } else if (retailerState.settingProductLevelLabelFour > 255) {
      setErrorMsgSettingProductLevelLabelFour(t('retailers_creation_label_error_length'));
      errorFieldIdList.push('field-settingProductLevelLabelOne');
    } else {
      setErrorMsgSettingProductLevelLabelFour();
    }

    if (errorFieldIdList.length) {
      setTimeout(() => {
        scrollToElementById(errorFieldIdList[0]);
      }, 150);
    }

    return !!errorFieldIdList.length;
  };

  return (
    <FormManager
      data={{
        title: t('retailers_creation_products_ref'),
        subTitle: t('retailers_creation_products_ref_desc'),
        fieldsets: [
          {
            id: 'creation-form-products',
            fields: [
              {
                outerContainerClass: ['without-margin-top'],
                label: <Trans i18nKey="retailers_creation_label_one" />,
                placeholder: t('retailers_creation_label_one_name'),
                defaultValue: retailerState?.settingProductLevelLabelOne || '',
                type: 'TextField',
                id: 'settingProductLevelLabelOne',
                error: errorMsgSettingProductLevelLabelOne,
                onFieldChange: (value) => {
                  setErrorMsgSettingProductLevelLabelOne();
                  onChangeState({ settingProductLevelLabelOne: value });
                },
              },
              {
                label: t('retailers_creation_label_two'),
                placeholder: t('retailers_creation_label_two_name'),
                defaultValue: retailerState?.settingProductLevelLabelTwo || '',
                type: 'TextField',
                id: 'settingProductLevelLabelTwo',
                error: errorMsgSettingProductLevelLabelTwo,
                onFieldChange: (value) => {
                  setErrorMsgSettingProductLevelLabelTwo();
                  onChangeState({ settingProductLevelLabelTwo: value });
                },
              },
              {
                label: t('retailers_creation_label_three'),
                placeholder: t('retailers_creation_label_three_name'),
                defaultValue: retailerState?.settingProductLevelLabelThree || '',
                type: 'TextField',
                id: 'settingProductLevelLabelThree',
                error: errorMsgSettingProductLevelLabelThree,
                onFieldChange: (value) => {
                  setErrorMsgSettingProductLevelLabelThree();
                  onChangeState({ settingProductLevelLabelThree: value });
                },
              },
              {
                label: t('retailers_creation_label_four'),
                placeholder: t('retailers_creation_label_four_name'),
                defaultValue: retailerState?.settingProductLevelLabelFour || '',
                type: 'TextField',
                id: 'settingProductLevelLabelFour',
                error: errorMsgSettingProductLevelLabelFour,
                onFieldChange: (value) => {
                  setErrorMsgSettingProductLevelLabelFour();
                  onChangeState({ settingProductLevelLabelFour: value });
                },
              },
            ],
          },
        ],
      }}
    />
  );
});

ProductsRepository.propTypes = {
  onChangeState: PropTypes.func,
  retailerState: PropTypes.object,
};

export default ProductsRepository;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Trans } from 'react-i18next';

import styles from './TabsInModal.module.scss';

const Tabs = ({ className, labels, onChange, position = 'center', selectedIndex }) => {
  const align = `align-${position}`;

  return (
    <div className={clsx(styles['root'], className)}>
      <div className={`${styles['container']} ${styles[align]}`}>
        {labels.map((label, index) => {
          return (
            <div
              className={`${styles['tab']} ${selectedIndex === index ? styles['selected'] : ''}`}
              key={index.toString()}
              onClick={() => {
                onChange(index);
              }}
              role="button"
            >
              {typeof label === 'string' ? <Trans i18nKey={label} /> : label}
              {selectedIndex === index && <div className={styles['indicator']} />}
            </div>
          );
        })}
      </div>
      <div className={styles['grey-line']}></div>
    </div>
  );
};

Tabs.propTypes = {
  className: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  onChange: PropTypes.func.isRequired,
  position: PropTypes.oneOf(['center', 'left', 'right']),
  selectedIndex: PropTypes.number
};

export default memo(Tabs);

import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import { getSupplier, getSupplierPreviousBudgets, getUserSuppliers, relaunchUserValidationEmail } from 'api';
import { store } from 'store';
import useDynId from 'customHooks/useDynId';

import AddSupplierForm from 'components/AddSupplierForm';
import BudgetsProgressBar from 'components/BudgetsProgressBar';
import BudgetsProgressLegend from 'components/BudgetsProgressBar/Legend';
import ButtonsCustom from 'components/ButtonsCustom';
import DrawerHeader from 'components/DrawerHeader';
import EmailsList from 'components/EmailsList';
import Loader from 'components/Loaders/Dots';
import ProductRefListDetails from 'components/ProductRefListDetails';

import { ReactComponent as PencilIcon } from 'assets/16px_edit.svg';

import styles from './SupplierDetails.module.scss';
import GoBackLink from 'components/GoBackLink';

const SupplierDetails = ({ supplierId, openEditPanel = false, onUpdated }) => {
  const { t } = useTranslation();

  const {
    state: {
      user,
      suppliersList: {
        details: { supplier, externalSuppliers = [], productsCount }
      }
    }
  } = useContext(store);
  const showProductListId = useDynId('showProductListId');
  const [loading, setLoading] = useState(true);
  const [openProductList, setOpenProductList] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(openEditPanel);
  const [previousBudgetsList, setPreviousBudgetsList] = useState([]);
  const [userSupplierList, setUserSupplierList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getSupplier({ retailerId: user.id, supplierId });
      const userSupplierListData = await getUserSuppliers(supplierId);
      if (userSupplierListData) {
        setUserSupplierList(userSupplierListData);
      }

      const previousBudgets = await getSupplierPreviousBudgets({ retailerId: user.id, supplierId });
      if (previousBudgets?.length) {
        setPreviousBudgetsList(previousBudgets);
      }
      setLoading(false);
    };

    if (supplierId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierId]);

  const handleOnAddSupplierFormFinish = async () => {
    onUpdated();
    const userSupplierListData = await getUserSuppliers(supplierId);
    if (userSupplierListData) {
      setUserSupplierList(userSupplierListData);
    }
    setOpenEditForm(false);
  };

  const productList = (
    <>
      <GoBackLink
        className={styles['back-button']}
        customOnClick={() => setOpenProductList(false)}
        id="productList-goBack-button"
        label={t('navigation_back_supplier_details')}
        pathname="#"
      />
      <ProductRefListDetails productRefs={externalSuppliers} responsive />
    </>
  );

  // Header Buttons
  const buttons = [
    <ButtonsCustom
      key="offer-edit-button"
      classType="action"
      method={() => setOpenEditForm(true)}
      startIconCustom={<PencilIcon />}
      disabled={loading}
      id={useDynId('editSupplierButton')}
    />
  ];

  return (
    <div className={styles['supplier-details']}>
      {openProductList ? (
        productList
      ) : openEditForm ? (
        <div>
          <GoBackLink
            className={styles['back-button']}
            customOnClick={() => setOpenEditForm(false)}
            id="editForm-goBack-button"
            label={t('navigation_back_supplier_details')}
            pathname="#"
          />
          <AddSupplierForm
            onCancel={() => setOpenEditForm(false)}
            onFinish={handleOnAddSupplierFormFinish}
            supplier={supplier ? JSON.parse(JSON.stringify(supplier)) : undefined}
            title={t(`supplier_edit`)}
          />
        </div>
      ) : (
        <>
          <DrawerHeader title={t('supplier_details_title')} buttons={buttons} />
          {loading ? (
            <div className={styles['loader-container']}>
              <span>{t('commun_loading')}</span>
              <Loader />
            </div>
          ) : (
            <>
              <div className={styles['supplier-name']}>{supplier?.name}</div>
              <div className={styles['supplier-id']}>ID : {supplier?.id}</div>
              <section>
                <div className={styles['budget-title']}>{t('commun_budget')}</div>
                <div className={styles['budget-with-progressbar-container']}>
                  {supplier?.budgets &&
                    [
                      supplier?.budgets?.[0]?.budgetN1,
                      supplier?.budgets?.[1]?.budgetN1,
                      supplier?.budgets?.[0]?.budgetN,
                      supplier?.budgets?.[1]?.budgetN
                    ]
                      .filter((i) => i?.total)
                      .map((budget) => (
                        <div key={budget.id}>
                          <div className={styles['budget-recap']}>
                            <span className={styles['budget-type-title']}>{`${t(`commun_budget_type_${budget.type}`)} ${
                              budget.year
                            }`}</span>
                            <div className={styles['budget-amounts']}>
                              {t('commun_price', { value: budget.spent, currency: user.currency.code })}
                              <span className={styles['total']}>{` / ${t('commun_price', {
                                value: budget.total,
                                currency: user.currency.code
                              })}`}</span>
                            </div>
                          </div>
                          <div>
                            <BudgetsProgressBar
                              currency={user.currency.code}
                              data={[
                                { key: 'spent', value: budget.spent },
                                { key: 'reserved', value: budget.reserved || null },
                                { key: 'free', value: budget.free || null }
                              ]}
                              total={budget.total}
                            />
                          </div>
                        </div>
                      ))}
                </div>
                {!!supplier?.budgets.length && (
                  <BudgetsProgressLegend
                    list={['budget_progressBar_spent', 'budget_progressBar_reserved', 'budget_progressBar_free']}
                  />
                )}

                {!!previousBudgetsList?.length &&
                  previousBudgetsList.map((el) => (
                    <div className={styles['budget-recap']} key={el.id}>
                      <span className={styles['budget-type-title']}>{`${t(`commun_budget_type_${el.type}`)} ${
                        el.year
                      }`}</span>
                      <div className={styles['budget-amounts']}>
                        {t('commun_price', { value: el.spent, currency: user.currency.code })}
                        <span className={styles['total']}>{` / ${t('commun_price', {
                          value: el.total,
                          currency: user.currency.code
                        })}`}</span>
                      </div>
                    </div>
                  ))}
              </section>
              {supplier?.userManager?.id && (
                <section>
                  <div>{t('supplier_userManager')}</div>
                  <div className={styles['container']}>
                    <EmailsList emails={[{ email: supplier.userManager.email }]} />
                  </div>
                </section>
              )}
              {userSupplierList?.length > 0 && (
                <section>
                  <div>{t('supplier_details_user_list')}</div>
                  <div className={styles['container']}>
                    <EmailsList
                      emails={userSupplierList}
                      handleRelaunch={relaunchUserValidationEmail}
                      retailerId={user.id}
                      supplierId={supplierId}
                    />
                  </div>
                </section>
              )}
              <section>
                <div>{`${t('offers_creation_product_list')} ${t('commun_result', { count: productsCount })}`}</div>
                <div className={styles['container']}>
                  <div className={styles['products-container']}>
                    <div className={styles['tags']}>
                      {externalSuppliers.map((el) => (
                        <span className={styles['tag']} key={`tag-${el.id}`}>
                          {el.name} | #{el.code}
                        </span>
                      ))}
                    </div>
                    <ButtonsCustom
                      classType="link_primary_small"
                      id={showProductListId}
                      method={() => setOpenProductList(true)}
                      text={t('supplier_show_product_list')}
                    />
                  </div>
                </div>
              </section>
              {supplier.universe && (
                <section>
                  <div>{t('supplier_selected_universe')}</div>
                  <div className={styles['framed-info']}>{t(`universe_${supplier.universe}`)}</div>
                </section>
              )}
              {supplier.note && (
                <section>
                  <div>{t('supplier_added_note_title')}</div>
                  <div className={styles['framed-info']}>
                    <div
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(supplier.note.replaceAll('\n', '<br/>')) }}
                    />
                  </div>
                </section>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

SupplierDetails.propTypes = {
  openEditPanel: PropTypes.bool,
  onUpdated: PropTypes.func,
  supplierId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default SupplierDetails;

import moment from 'moment-timezone';
import { getLastDayOfYear } from 'utils/global';

export const getPeriodDiffDays = ({ validityStartDate: a, validityEndDate: b }) => {
  return moment(b).diff(moment(a), 'days');
};

export const getFarthestDublicableOfferEndDate = (offerEndDate) => {
  return moment.min(getLastDayOfYear(), offerEndDate);
};

export const getFarthestDuplicableInitialEndDate = ({ campaign, offerEndDate, initialStartDate }) => {
  const today = moment();

  const samePeriodEndDate = moment(initialStartDate).add(getPeriodDiffDays(campaign), 'days');
  const farthestEndDate = today.isAfter(offerEndDate, 'day')
    ? samePeriodEndDate
    : moment.min(offerEndDate, samePeriodEndDate);

  return moment.min(getLastDayOfYear(), farthestEndDate);
};

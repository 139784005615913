import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';

import { store } from 'store';
import { postLoginRequest, init } from 'api';
import { cleanOldCookies, deleteCookie, getCookie, setCookie } from 'utils/cookies';
import { getToken, TOKENS } from 'utils/token';

import LoginForm from 'components/LoginForm';
import Routes from 'Routes';
import SnackBar from 'components/SnackBar';

import styles from './App.module.scss';

const App = () => {
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const [tokenIsSet, setTokenIsSet] = useState(!!getToken(TOKENS.access_token));
  const [errorLoginForm, setErrorLoginForm] = useState('');

  //set user local globally
  const userLocale = navigator.language.split('-')[0];
  moment.locale(userLocale);

  useEffect(() => {
    if (getCookie('remember-me') !== 'true') {
      deleteCookie('remember-me');
    }

    if (tokenIsSet) {
      init(dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePostLoginRequest = async (params) => {
    cleanOldCookies(); // TODO : can be removed after 2024/01/01
    const postLoginRequestResponse = await postLoginRequest({
      ...params
    });

    setTokenIsSet(!postLoginRequestResponse.errorType);
    setErrorLoginForm(postLoginRequestResponse.errorType);

    if (!postLoginRequestResponse.errorType) {
      if (params.rememberMe) {
        setCookie({ name: 'remember-me', value: 'true', maxAge: 7 * 24 * 60 * 60 });
      }
      init(dispatch);
    }
  };

  return (
    <section className={styles.app}>
      <Routes tokenIsSet={tokenIsSet}>
        <LoginForm
          tokenIsSet={tokenIsSet}
          error={errorLoginForm}
          postLoginRequest={handlePostLoginRequest}
          onInputChange={() => setErrorLoginForm('')}
        />
      </Routes>

      <SnackBar
        desc={state.snackbar.desc}
        dispatch={dispatch}
        title={state.snackbar.title}
        type={state.snackbar.type}
      />
    </section>
  );
};

export default App;

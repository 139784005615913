import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { supplierBudgetTypes } from 'utils/constants';

import ButtonsCustom from 'components/ButtonsCustom';
import TextFieldCustom from 'components/TextFieldCustom';

import { ReactComponent as CrossIcon } from 'assets/22px_croix.svg';
import styles from './BudgetsFields.module.scss';
import SelectCustom from 'components/SelectCustom';

const BudgetsFields = ({ budgets, currency, disabled, hasError, idField, onChange, supplierId }) => {
  const { t } = useTranslation();
  const [showBudgetN1_block1, setShowBudgetN1_block1] = useState(false);
  const [showBudgetN1_block2, setShowBudgetN1_block2] = useState(false);
  const [state, setState] = useState([{ type: '', budgetN: { total: '' }, budgetN1: { total: '' } }]);

  // for supplier edition
  useEffect(() => {
    const parsedBudgets = budgets ? JSON.parse(JSON.stringify(budgets)) : [];
    if (parsedBudgets.length) {
      setState(parsedBudgets);

      if (parsedBudgets[0]?.budgetN1?.total) {
        setShowBudgetN1_block1(true);
      }
      if (parsedBudgets[1]?.budgetN1?.total) {
        setShowBudgetN1_block2(true);
      }
    } else {
      setState([{ type: '', budgetN: { total: '' }, budgetN1: { total: '' } }]);
      setShowBudgetN1_block1(false);
      setShowBudgetN1_block2(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierId]);

  const addNewBudgetBlock = () => {
    const newState = [
      ...state,
      {
        type:
          state[0].type === ''
            ? ''
            : state[0].type === supplierBudgetTypes.DIGITAL
            ? supplierBudgetTypes.PAPER
            : supplierBudgetTypes.DIGITAL,
        budgetN: { total: '' },
        budgetN1: { total: '' },
      },
    ];
    setState(newState);
  };

  return (
    <div>
      {state.map((block, index) => {
        const disableInput = disabled || !block.type;
        return (
          <div className={styles['budgets-fields']} key={index.toString()}>
            <SelectCustom
              disabled={disabled}
              placeHolderText={t('supplier_budget_type_select_placeholder')}
              methodOnChange={(i) => {
                let newState = JSON.parse(JSON.stringify(state));
                newState[index].type = i.target.value;
                setState(newState);
                onChange(idField, newState);
              }}
              listData={[
                { id: supplierBudgetTypes.DIGITAL, label: t('commun_budget_type_DIGITAL') },
                { id: supplierBudgetTypes.PAPER, label: t('commun_budget_type_PAPER') },
              ]}
              value={block.type}
              id={`block-type-${index}`}
            />
            <div className={styles['budgets-values-container']}>
              <TextFieldCustom
                InputProps={{
                  endAdornment: (
                    <>
                      <span>{currency}</span>
                      <CrossIcon
                        className={disableInput ? styles['disabled'] : ''}
                        id={`reset_budgetN_block-${index}`}
                        title=""
                        onClick={() => {
                          if (disableInput) {
                            return false;
                          }
                          let newState = JSON.parse(JSON.stringify(state));
                          newState[index].budgetN.total = '';
                          setState(newState);
                          onChange(idField, newState);
                        }}
                      />
                    </>
                  ),
                }}
                label={new Date().getFullYear().toString()}
                className={styles['form-textfield']}
                disabled={disableInput}
                onChange={(event) => {
                  const value = event.target.value;
                  const valueParsed = parseInt(value, 10) >= 0 ? parseInt(value, 10) : '';
                  let newState = JSON.parse(JSON.stringify(state));
                  newState[index].budgetN.total = valueParsed;
                  setState(newState);
                  onChange(idField, newState);
                }}
                type="text"
                value={block.budgetN.total}
                id={`budgetN_block-${index}`}
              />
              {(index === 0 && showBudgetN1_block1) || (index === 1 && showBudgetN1_block2) ? (
                <TextFieldCustom
                  InputProps={{
                    endAdornment: (
                      <>
                        <span>{currency}</span>
                        <CrossIcon
                          className={disableInput ? styles['disabled'] : ''}
                          id={`reset_budgetN1_block-${index}`}
                          title=""
                          onClick={() => {
                            if (disableInput) {
                              return false;
                            }
                            let newState = JSON.parse(JSON.stringify(state));
                            newState[index].budgetN1.total = '';
                            setState(newState);
                            onChange(idField, newState);

                            if (index === 0) setShowBudgetN1_block1(false);
                            else setShowBudgetN1_block2(false);
                          }}
                        />
                      </>
                    ),
                  }}
                  label={(new Date().getFullYear() + 1).toString()}
                  className={styles['form-textfield']}
                  disabled={disableInput}
                  onChange={(event) => {
                    const value = event.target.value;
                    const valueParsed = parseInt(value, 10) >= 0 ? parseInt(value, 10) : '';
                    let newState = JSON.parse(JSON.stringify(state));
                    newState[index].budgetN1.total = valueParsed;
                    setState(newState);
                    onChange(idField, newState);
                  }}
                  type="text"
                  value={block.budgetN1?.total}
                  id={`budgetN1_block-${index}`}
                />
              ) : (
                <ButtonsCustom
                  classType="dashed"
                  disabled={disableInput}
                  id={`add_budgetN1_block-${index}`}
                  method={() => {
                    if (index === 0) setShowBudgetN1_block1(true);
                    else setShowBudgetN1_block2(true);
                  }}
                  text={t('supplier_budget_N1_add', { year: new Date().getFullYear() + 1 })}
                />
              )}
            </div>
          </div>
        );
      })}
      {state.length < 2 && (
        <ButtonsCustom
          classType="dashed"
          disabled={disabled || !state[0].type}
          id={'add_new_budget'}
          method={addNewBudgetBlock}
          text={t('supplier_create_new_budget')}
        />
      )}

      {hasError && <div className={styles['error-msg']}>{t('supplier_budget_twice_same_type_error')}</div>}
    </div>
  );
};

BudgetsFields.propTypes = {
  budgets: PropTypes.array,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  idField: PropTypes.string,
  onChange: PropTypes.func,
  supplierId: PropTypes.number,
};

export default BudgetsFields;

import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { checkYearFormat, QsParse, QsStringify } from 'utils/global';
import { getAllSupplierBudgets, getAllSuppliers, getAllUniverses, getAllUserManager } from 'api';
import { store } from 'store';
import { useHistory } from 'react-router-dom';
import { userTypes } from 'utils/constants';

import BudgetInfoBlock from './BudgetInfoBlock';
import FiltersContainer, { getInitialFilters } from 'components/Filters';
import Header from './Header';
import MarketingStrategiesBlock from './MarketingStrategiesBlock';
import NoData from './NoData';
import OffersBlock from './OffersBlock';
import SpendingPaceBlock from './SpendingPace';

import styles from './DashboardSupplier.module.scss';

import { supplierBudgetType } from 'types/supplierBudget';
import { supplierType } from 'types/supplier';
import { userManagerType } from 'types/userManager';

interface IUrlFilters {
  supplierBudgetIds?: number[];
  supplierId?: number[];
  universe?: string[];
  userManagerId?: number[];
  year?: number;
}

interface IFiltersContainerResetRef {
  resetAllFilters: () => void;
}

interface IGlobalState {
  user: any;
  queryFilters: any;
  suppliers: supplierType[];
  universesRetailerList: string[];
  userManagers: userManagerType[];
}

const thisYear = new Date().getFullYear();

/*** this component is used by default on /home (homepage as a supplier user) ***/
/*** and on /dashboard retailer (as a retailer user) ***/

const DashboardSupplier = (): JSX.Element => {
  const history = useHistory();
  const globalState = useContext(store);
  const { dispatch, state } = globalState as any;
  const { user, queryFilters, suppliers, universesRetailerList, userManagers }: IGlobalState = state;

  const isRetailerUser = user.userType === userTypes.RETAILER;
  const retailerId = isRetailerUser ? user.id : undefined;
  const urlFilters: IUrlFilters = QsParse(history.location.search);

  // get initial year value in the url
  const initialUrlYear: number = useMemo(
    () => (urlFilters?.year ? (checkYearFormat(urlFilters.year) ? urlFilters.year : thisYear) : thisYear),
    [urlFilters.year]
  );

  // get initial supplierBudgetIds value in the url
  const initialUrlSupplierBudgetIds: number[] = useMemo(
    () =>
      urlFilters.supplierBudgetIds
        ? typeof urlFilters.supplierBudgetIds === 'number'
          ? [urlFilters.supplierBudgetIds]
          : urlFilters.supplierBudgetIds
        : [],
    [urlFilters.supplierBudgetIds]
  );

  const [allSupplierBudgetList, setAllSupplierBudgetList] = useState<supplierBudgetType[]>([]);
  const [allSupplierBudgetsByYear, setAllSupplierBudgetsByYear] = useState<supplierBudgetType[]>([]);
  const [filterSupplierBudgetIds, setFilterSupplierBudgetIds] = useState<number[]>(initialUrlSupplierBudgetIds);
  const [filterYear, setFilterYear] = useState<number>(initialUrlYear);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const currency: string = isRetailerUser ? user.currency.code : allSupplierBudgetList?.[0]?.retailer?.currency?.code;

  // specific data for retailer user filters
  useEffect(() => {
    if (isRetailerUser) {
      getAllSuppliers(retailerId);
      getAllUniverses();
      getAllUserManager();
    }
  }, [isRetailerUser, retailerId]);

  useEffect(() => {
    const fetchAllSupplierBudgetsByYear = async () => {
      const list = await getAllSupplierBudgets({ retailerId, total: 0, year: filterYear });
      setAllSupplierBudgetsByYear(list);
    };
    if (isRetailerUser) {
      fetchAllSupplierBudgetsByYear();
    }
  }, [filterYear, isRetailerUser, retailerId]);

  const suppliersWithBudget: supplierType[] = useMemo(() => {
    if (!!allSupplierBudgetsByYear?.length) {
      // first create the supplier list from supplierBudgets fetched by budgetYears
      // with uniqueness because of budget Digital and budget Paper on the same supplier
      const list = new Set<string>([]);
      allSupplierBudgetsByYear.map((budgetSupplier: supplierBudgetType) =>
        list.add(JSON.stringify(budgetSupplier.supplier))
      );
      const newList = [...list].map((el) => JSON.parse(el));
      return newList;
    } else if (isRetailerUser && !!suppliers?.length) {
      return suppliers;
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSupplierBudgetsByYear, suppliers]);

  // set initial filters from url for retailer user view
  const initFilters = useMemo(
    () => {
      return getInitialFilters({
        history,
        queryFilters: queryFilters,
        suppliers: suppliersWithBudget,
        universes: universesRetailerList,
        userManagers: userManagers
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filtersContainerRef = useRef<IFiltersContainerResetRef>(null);
  const [filters, setFilters] = useState<IUrlFilters>({ ...initFilters });
  const conditionsAreOKforRetailerUser =
    isRetailerUser && (!!filters.supplierId?.length || !!filters.universe?.length || !!filters.userManagerId?.length);

  const suppliersAvailableList = useMemo(() => {
    // keep only the suppliers with selected universes
    // else return suppliersWithBudget
    const withSelectedUniverse = !filters.universe?.length
      ? suppliersWithBudget
      : suppliersWithBudget.filter((s) => {
          if (s.universe) {
            return filters.universe?.includes(s.universe);
          }
          return false;
        });

    const withSelectedUniverseAndUserManager = !filters.userManagerId?.length
      ? withSelectedUniverse
      : withSelectedUniverse.filter((s) => {
          if (s.userManager) {
            return filters.userManagerId?.includes(s.userManager?.id);
          }
          return false;
        });

    return withSelectedUniverseAndUserManager;
  }, [filters.universe, filters.userManagerId, suppliersWithBudget]);

  useEffect(() => {
    updateUrl({
      supplierBudgetIds: isRetailerUser && !filters.supplierId?.length ? undefined : filterSupplierBudgetIds,
      supplierId: filters.supplierId,
      universe: filters.universe,
      userManagerId: filters.userManagerId,
      year: filterYear
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, filterSupplierBudgetIds, filterYear]);

  useEffect(() => {
    const fetchAllAvailableSupplierBudgetList = async () => {
      setIsLoading(true);
      const list = await getAllSupplierBudgets({
        retailerId,
        supplierIds: isRetailerUser ? filters.supplierId : undefined,
        universes: isRetailerUser ? filters.universe : undefined,
        userManagerId: isRetailerUser ? filters.userManagerId : undefined,
        total: 0,
        year: filterYear
      });

      setAllSupplierBudgetList(list);

      setIsLoading(false);
    };

    if (!isRetailerUser || conditionsAreOKforRetailerUser) {
      fetchAllAvailableSupplierBudgetList();
    } else {
      handleUpdateFilters({});
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    conditionsAreOKforRetailerUser,
    filterYear,
    filters.supplierId,
    filters.universe,
    filters.userManagerId,
    isRetailerUser,
    retailerId
  ]);

  const updateUrl = (obj: IUrlFilters) => {
    const urlFilters = QsParse(history.location.search);
    const qs = QsStringify({ ...urlFilters, ...obj });
    history.push({
      pathname: history.location.pathname,
      search: qs
    });

    if (isRetailerUser) {
      localStorage.setItem('dashboardRetailer_supplierTabFilter', `?${qs}`);
    }
  };

  const handleSupplierBudgetIdsChange = (newList: number[]) => {
    setFilterSupplierBudgetIds([...newList]);
    updateUrl({ supplierBudgetIds: [...newList] });
  };

  const handleYearFilterChange = (value: number) => {
    handleSupplierBudgetIdsChange([]);
    setFilterYear(value);
    if (isRetailerUser) {
      filtersContainerRef.current?.resetAllFilters();
      setAllSupplierBudgetList([]);
    }
  };

  const handleUpdateFilters = (newFilters: IUrlFilters) => {
    const filtersCopy = { ...newFilters, supplierBudgetIds: [], year: filterYear };
    handleSupplierBudgetIdsChange([]);
    setAllSupplierBudgetList([]);
    updateUrl(filtersCopy);
    setFilters(filtersCopy);
  };

  // get positionedTargetsLevels from retailer when only 1 supplier is selected
  // and filter to keep only the entries with filled data (beacause month and value can be null)
  const positionedTargetsLevels =
    filterSupplierBudgetIds?.length === 1
      ? allSupplierBudgetList
          .find((sup) => sup.id === filterSupplierBudgetIds[0])
          ?.retailer?.positionedTargetsLevels?.filter((level) => level.month) || []
      : undefined;

  /* fix: for retailer user : */
  /* if top filters are selected, by default all budget ids must be sent */
  const selectedBudgetIds = (() => {
    if (isRetailerUser && !!allSupplierBudgetList?.length && !filterSupplierBudgetIds?.length) {
      return allSupplierBudgetList.map((sp) => sp.id);
    }
    return filterSupplierBudgetIds;
  })();

  const hasAtLeastOneFilter = isRetailerUser && (!!filters.supplierId || !!filters.universe || !!filters.userManagerId);

  return (
    <section className={clsx(styles['root'], !isRetailerUser && styles['content-wrapper'])}>
      {isRetailerUser && (
        <FiltersContainer
          /* @ts-ignore */
          dispatch={dispatch}
          filters={filters}
          filtersListToDisplay={['retailerUser', 'universe', 'userManager']}
          ref={filtersContainerRef}
          suppliersList={suppliersAvailableList}
          universesList={state.universesRetailerList}
          updateFilters={handleUpdateFilters}
          userManagersList={state.userManagers}
          user={state.user}
        />
      )}
      <Header
        allSupplierBudgetList={allSupplierBudgetList}
        isLoading={isLoading}
        onSupplierBudgetIdsChange={handleSupplierBudgetIdsChange}
        onYearChange={handleYearFilterChange}
        selectedSupplierBudgetIds={filterSupplierBudgetIds}
        selectedYear={filterYear}
      />

      {!!allSupplierBudgetList.length ? (
        <div>
          <div className={clsx(styles['line-container'], styles['line-1'])}>
            <BudgetInfoBlock
              budgetIds={selectedBudgetIds}
              currency={currency}
              retailerId={retailerId}
              year={filterYear}
            />
          </div>
          <div className={clsx(styles['line-container'], styles['line-2'])}>
            <SpendingPaceBlock
              budgetIds={selectedBudgetIds}
              currency={currency}
              positionedTargetsLevels={positionedTargetsLevels}
              retailerId={retailerId}
              year={filterYear}
            />
            <OffersBlock budgetIds={selectedBudgetIds} retailerId={retailerId} year={filterYear} />
          </div>
          <div className={clsx(styles['line-container'], styles['line-3'])}>
            <MarketingStrategiesBlock
              budgetIds={selectedBudgetIds}
              currency={currency}
              retailerId={retailerId}
              year={filterYear}
            />
          </div>
        </div>
      ) : (
        <NoData hasAtLeastOneFilter={hasAtLeastOneFilter} isRetailerUser={isRetailerUser} year={filterYear} />
      )}
    </section>
  );
};

export default DashboardSupplier;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';

import { ReactComponent as CheckboxIcon } from 'assets/checkbox.svg';
import { ReactComponent as CheckboxIconChecked } from 'assets/checkbox_on.svg';

const CheckboxCustom = ({ idText, ...props }) => (
  <Checkbox
    checkedIcon={<CheckboxIconChecked />}
    disableRipple
    icon={<CheckboxIcon />}
    id={idText?.toString()}
    {...props}
  />
);

CheckboxCustom.propTypes = {
  idText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default memo(CheckboxCustom);

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './GeneralInfoBox.module.scss';

export const GeneralInfoBoxContainer = ({ children, gridTemplate = 2 }) => (
  <div className={clsx(styles['general-info-box-container'], styles[`gridTemplate-${gridTemplate}`])}>{children}</div>
);
GeneralInfoBoxContainer.propTypes = {
  children: PropTypes.node,
  gridTemplate: PropTypes.number,
  isColumn: PropTypes.bool
};

const GeneralInfoBox = ({ children, fullWidth, height = 'medium', label, size = 'medium', status = '' }) => {
  return (
    <div
      className={clsx(
        styles['general-info-box'],
        styles[`height-${height}`],
        styles[`size-${size}`],
        fullWidth && styles['general-info-box-full-width'],
        status
      )}
    >
      {label && <p className={styles['general-info-box-label']}>{label}</p>}
      <div className={styles['general-info-box-content']}>{children}</div>
    </div>
  );
};

GeneralInfoBox.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  height: PropTypes.oneOf(['thin', 'medium']),
  label: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  status: PropTypes.oneOf(['success', 'pending'])
};

export default GeneralInfoBox;

// check for a cookie, even with empty value
export const checkCookieExists = (name) => {
  return document.cookie?.includes(`${name}=`);
};

export const deleteCookie = (name) => {
  document.cookie = name + '=; max-age=0; path=/;';
};

export const emptyCookie = ({ name, maxAge }) => {
  setCookie({ name, value: '', maxAge });
};

// retrieve a cookie's value
export const getCookie = (name) => {
  const regex = new RegExp(`(^| )${name}=([^;]+)`);
  const match = document.cookie.match(regex);
  return match?.[2];
};

// maxAge is optionnal
export const setCookie = ({ name, value, maxAge }) => {
  document.cookie = `${name}=${value}; ${maxAge ? 'max-age=' + maxAge + '; ' : ''}path=/;`;
};

export const cleanOldCookies = () => {
  const list = ['token', 'refresh_token', 'remember-me-token', 'remember-me-refresh-token'];
  list.forEach((name) => deleteCookie(name));
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoResult } from 'assets/no_result.svg';
import styles from './UnknownId.module.scss';

const UnknownId = () => {
  const { t } = useTranslation();
  return (
    <div className={styles['unknown-id']}>
      <NoResult className={styles['icon']} />
      <div className={styles['title']}>{t('commun_error_page_display')}</div>
      <div className={styles['desc']}>{t('commun_unknown_id')}</div>
    </div>
  );
};

export default UnknownId;

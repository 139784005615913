import React, { memo, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import {
  launchAllocation,
  getCampaignKpisFile,
  getCampaignTargetingFile,
  getCampaignTargetingStatus,
  getCampaignOffersFile,
  getCampaignOffersSuperEditableCount,
  getCampaignVisualFile,
  generateCampaignVisualFile,
  downloadVisualFile,
  getCampaign
} from 'api';
import { campaignTargetingStatuses, campaignTypes, segmentUpdatingStatuses, visualFileStatuses } from 'utils/constants';
import AsidePanel from 'components/AsidePanel';
import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import Loader from 'components/Loaders/Dots';
import NoAllocationPanel from './NoAllocationPanel';
import Tooltip from 'components/Tooltip';
import useDynId from 'customHooks/useDynId';

import { ReactComponent as AlertIcon } from 'assets/24px_alert_round_red.svg';
import { ReactComponent as RefreshIcon } from 'assets/30px_refresh.svg';
import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';

import FailureIcon from 'assets/30px_echouer.svg';
import SuccessIcon from 'assets/30px_valide.svg';
import CiblageImg from 'assets/file_ciblage.svg';
import OffreImg from 'assets/file_offre.svg';
import KpiImg from 'assets/32px_file-KPI.svg';
import VisuelsImg from 'assets/file_visuels.svg';

import './AllocationPanel.scss';

const today = moment();

const AllocationPanel = ({
  campaign,
  campaign: {
    campaignType,
    cashCoupons,
    externalSegment,
    id,
    personalized,
    targetingStatus,
    validityStartDate,
    validityEndDate
  },
  targetingEnabled
}) => {
  const { t } = useTranslation();
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [superEditableOffersCount, setSuperEditableOffersCount] = useState(null);
  const [isPullingTargetingStatus, setIsPullingTargetingStatus] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [loaderKpisFile, setLoaderKpisFile] = useState(false);
  const [loaderOffersFile, setLoaderOffersFile] = useState(false);
  const [loaderTargetingFile, setLoaderTargetingFile] = useState(false);
  const [loaderVisualFile, setLoaderVisualFile] = useState(false);
  const [visualsFile, setVisualsFile] = useState();
  const [visualsFileFetched, setVisualsFileFetched] = useState(false);

  const isAllocationSuccess = targetingStatus === campaignTargetingStatuses.SUCCESS;

  const allocationId = useDynId('startAllocation');
  const downloadKpisId = useDynId('downloadKpisFile');
  const downloadOffersId = useDynId('downloadOffersFile');
  const downloadTargetingId = useDynId('downloadTargetingFile');
  const downloadVisualId = useDynId('downloadVisualId');
  const refreshId = useDynId('refreshId');

  /**
   * - FILE UNAVAILABLE <- NO URL [OR] CAMPAIGN TOO OLD (30 days after its end)
   * - FILE OUTDATED <- USER CHANGED OFFER VISUALS, A FILE WAS ALREADY GENERATED AND CAMPAIGN NOT TOO OLD (30 days after its end)
   * - FILE GENERATED <- URL DEFINED AND CAMPAIGN NOT TOO OLD (30 days after its end)
   * - PROCESSING <- STATUS TO_GENERATE OR IN_PROCESS
   * - CAN GENERATE A NEW FILE <- IF UNAVAILABLE, CAMPAIGN STILL RUNNING AND NO FILE IS PROCESSING
   */
  const shouldShowUnavailableFileTooltip = !visualsFile?.url || today.diff(validityEndDate, 'days') > 30;
  const shouldShowOutdatedTooltip =
    visualsFile?.url &&
    visualsFile?.status === visualFileStatuses.MODIFY_TO_REGENERATE &&
    today.diff(validityEndDate, 'days') <= 30;
  const shouldDisableVisualDownloadButton = shouldShowUnavailableFileTooltip || loaderVisualFile;
  const shouldShowGenerateButton =
    visualsFileFetched &&
    !visualsFile?.url &&
    today.isSameOrBefore(validityEndDate, 'day') &&
    ![visualFileStatuses.TO_GENERATE, visualFileStatuses.IN_PROCESS].includes(visualsFile?.status);
  const shouldShowVisualLoader =
    visualsFileFetched &&
    (loaderVisualFile || [visualFileStatuses.TO_GENERATE, visualFileStatuses.IN_PROCESS].includes(visualsFile?.status));

  // for non personalised campaign, the campaign validation can take sec or min, so use pulling targetingStatus to update the allocation/validation state
  // for personalised campaign, user must reload the page, as the allocation can take hours
  const targetingStatusTimeoutIdRef = useRef();

  // relaunch pulling after page changed or reloaded
  useEffect(() => {
    if (!personalized && targetingStatus === campaignTargetingStatuses.IN_PROCESS && !isPullingTargetingStatus) {
      pullTargetingStatus();
    }

    // stop pulling when leaving the page
    return () => {
      clearTimeout(targetingStatusTimeoutIdRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetingStatus]);

  useEffect(() => {
    if (!id) return;
    fetchVisualFile(id);
  }, [id]);

  useEffect(() => {
    const checkHasSuperEditableOffers = async () => {
      const count = await getCampaignOffersSuperEditableCount(id);
      // null is used to display api response error
      setSuperEditableOffersCount(count || count === 0 ? count : null);
    };
    if (id) {
      checkHasSuperEditableOffers();
    }
  }, [id]);

  useEffect(() => {
    setHasError(today.isBefore(validityStartDate, 'day') && targetingStatus === campaignTargetingStatuses.FAIL_DATA);
  }, [targetingStatus, validityStartDate]);

  const fetchVisualFile = async (campaignId) => {
    setVisualsFileFetched(false);
    const file = await getCampaignVisualFile(campaignId);
    setVisualsFile(file);
    setVisualsFileFetched(true);
  };

  // fetch campaign every 30 sec to check targetingStatus
  // recursive
  const pullTargetingStatus = async () => {
    setIsPullingTargetingStatus(true);
    const status = await getCampaignTargetingStatus(id);
    if (status === campaignTargetingStatuses.IN_PROCESS) {
      clearTimeout(targetingStatusTimeoutIdRef.current);
      targetingStatusTimeoutIdRef.current = setTimeout(pullTargetingStatus, 30000);
    } else {
      clearTimeout(targetingStatusTimeoutIdRef.current);
      targetingStatusTimeoutIdRef.current = null;
      setIsPullingTargetingStatus(false);
      await getCampaign(id);
    }
  };

  const launchAllocationAction = async () => {
    setIsSending(true);
    setHasError(false);
    const success = await launchAllocation(campaign);
    const campaignData = await getCampaign(id);
    const failed = !success || campaignData.targetingStatus === campaignTargetingStatuses.FAIL_DATA;
    if (!failed && !personalized) {
      pullTargetingStatus();
    }
    setHasError(failed);
    setIsSending(false);
    setDialogIsOpen(false);
  };

  const getOffersFile = async () => {
    setLoaderOffersFile(true);
    await getCampaignOffersFile(id);
    setLoaderOffersFile(false);
  };

  const getTargetingFile = async () => {
    setLoaderTargetingFile(true);
    await getCampaignTargetingFile(id);
    setLoaderTargetingFile(false);
  };

  const getKpisFile = async () => {
    setLoaderKpisFile(true);
    await getCampaignKpisFile(id);
    setLoaderKpisFile(false);
  };

  const generateVisualFile = async () => {
    await generateCampaignVisualFile(id);
    await fetchVisualFile(id);
  };

  const getVisualFile = async () => {
    setLoaderVisualFile(true);
    await downloadVisualFile(visualsFile.id);
    setLoaderVisualFile(false);
  };

  const displayErrorFrame = () => {
    return (
      <div className="errorFrame">
        <WarningIcon />
        <div>
          <p>{t('commun_error_500')}</p>
          <p>
            {t(
              targetingStatus === campaignTargetingStatuses.FAIL_DATA
                ? 'commun_error_allocation_failed_data'
                : 'commun_error_allocation'
            )}
          </p>
        </div>
      </div>
    );
  };

  const downloadPanel = () => {
    return (
      <>
        <AsidePanel className="download-panel">
          <div className="download-link">
            <img src={OffreImg} alt="Ciblage Icon" className="targetingIcon" />
            <button
              id={downloadOffersId}
              className="download-link-button"
              onClick={getOffersFile}
              disabled={loaderOffersFile}
            >
              {t('campaign_details_allocation_download_offers_file')}
            </button>
            {loaderOffersFile && <Loader className="small-loader" />}
          </div>
        </AsidePanel>
        {campaignType === campaignTypes.PRODUCT && (
          <AsidePanel className="download-panel">
            <div
              className={`download-link with-generate-button ${shouldDisableVisualDownloadButton ? 'disabled' : ''}`}
            >
              <div>
                <img src={VisuelsImg} alt="Ciblage Icon" className="targetingIcon" />
                <Tooltip
                  title={
                    shouldShowUnavailableFileTooltip
                      ? t('campaign_details_allocation_file_unavailable')
                      : shouldShowOutdatedTooltip
                      ? t('campaign_details_outdated_file')
                      : ''
                  }
                >
                  <div>
                    <button
                      className="download-link-button"
                      onClick={getVisualFile}
                      disabled={shouldDisableVisualDownloadButton}
                      id={downloadVisualId}
                    >
                      {t('campaign_details_allocation_download_visuals_file')}
                    </button>
                  </div>
                </Tooltip>
              </div>
              {/** NO FILE -> GENERATE FILE **/}
              {shouldShowGenerateButton && (
                <Tooltip title={t('campaign_details_regenerate_file')}>
                  <button className="generate-button" onClick={generateVisualFile} id={refreshId}>
                    <RefreshIcon />
                  </button>
                </Tooltip>
              )}
              {/** FILE IN PROCESS -> LOADER **/}
              {shouldShowVisualLoader && <Loader className="small-loader" />}
            </div>
          </AsidePanel>
        )}
        {personalized && (
          <AsidePanel className="download-panel">
            <Tooltip title={!isAllocationSuccess ? t('campaign_details_allocation_file_unavailable') : ''}>
              <div className={`download-link ${!isAllocationSuccess ? 'disabled' : ''}`}>
                <img src={CiblageImg} alt="Ciblage Icon" className="targetingIcon" />
                <button
                  id={downloadTargetingId}
                  className="download-link-button"
                  onClick={getTargetingFile}
                  disabled={loaderTargetingFile}
                >
                  {t('campaign_details_allocation_download_targeting_file')}
                </button>
                {loaderTargetingFile && <Loader className="small-loader" />}
              </div>
            </Tooltip>
          </AsidePanel>
        )}
        {personalized && (
          <AsidePanel className="download-panel">
            <Tooltip title={!isAllocationSuccess ? t('campaign_details_allocation_file_unavailable') : ''}>
              <div className={`download-link ${!isAllocationSuccess ? 'disabled' : ''}`}>
                <img src={KpiImg} alt="kpi Icon" className="kpiIcon" />
                <button
                  id={downloadKpisId}
                  className="download-link-button"
                  onClick={getKpisFile}
                  disabled={loaderKpisFile}
                >
                  {t('campaign_details_allocation_download_kpis_file')}
                </button>
                {loaderKpisFile && <Loader className="small-loader" />}
              </div>
            </Tooltip>
          </AsidePanel>
        )}
      </>
    );
  };

  const statusPanel = () => {
    // loading
    if (!validityStartDate) {
      return (
        <AsidePanel>
          <Loader />
        </AsidePanel>
      );
    }

    // impossible, too late
    if (
      (targetingStatus === campaignTargetingStatuses.NOT_REQUESTED ||
        targetingStatus === campaignTargetingStatuses.FAIL_DATA) &&
      moment().tz(campaign?.retailer.timezone.name).isAfter(validityStartDate, 'day')
    ) {
      return (
        <AsidePanel>
          <img src={FailureIcon} alt="FailureIcon" />
          <h2 className="title">
            {t(
              personalized
                ? 'campaign_details_allocation_impossible_title'
                : 'campaign_details_validation_impossible_title'
            )}
          </h2>
          <p className="desc">
            {t(
              personalized
                ? 'campaign_details_allocation_impossible_desc'
                : 'campaign_details_validation_impossible_desc'
            )}
          </p>
        </AsidePanel>
      );
    }

    // allocation not launched yet
    if (
      targetingStatus === campaignTargetingStatuses.NOT_REQUESTED ||
      targetingStatus === campaignTargetingStatuses.FAIL_DATA
    ) {
      const contentDialogTitle = isSending
        ? t(
            personalized
              ? 'campaign_details_allocation_confirm_modal_send_button'
              : 'campaign_details_validation_confirm_modal_send_button'
          )
        : t(
            personalized
              ? 'campaign_details_allocation_confirm_modal_title'
              : 'campaign_details_validation_confirm_modal_title'
          );
      const contentDialogDesc = isSending
        ? null
        : personalized
        ? t('campaign_details_allocation_confirm_modal_desc')
        : t('campaign_details_validation_confirm_modal_desc');

      // allocation can not be launched because at least one segment is not updatingStatus === SUCCESS
      const hasAtLeastOneNotSuccessSegment =
        campaignType === campaignTypes.CASH_COUPON
          ? !!cashCoupons?.filter((c) => c.externalSegment.updatingStatus !== segmentUpdatingStatuses.SUCCESS).length
          : externalSegment?.updatingStatus && externalSegment?.updatingStatus !== segmentUpdatingStatuses.SUCCESS;

      return (
        <>
          <AsidePanel>
            <h2 className="title">
              {t(personalized ? 'campaign_details_allocation_title' : 'campaign_details_validation_title')}
            </h2>
            <p className="desc">
              {t(personalized ? 'campaign_details_allocation_desc' : 'campaign_details_validation_desc')}
            </p>
            <div className="some-margin-top">
              {/* check superEditableOffersCount error */}
              {superEditableOffersCount === null ? (
                <img src={FailureIcon} alt="FailureIcon" />
              ) : (
                <Tooltip
                  title={
                    hasAtLeastOneNotSuccessSegment
                      ? t(
                          `campaign_details_retailerSegment_not_success_${
                            campaignType === campaignTypes.CASH_COUPON ? 'cashCouponType' : 'productType'
                          }_tooltip`
                        )
                      : ''
                  }
                >
                  <span>
                    <ButtonsCustom
                      id={allocationId}
                      classType="action_primary_big"
                      disabled={hasAtLeastOneNotSuccessSegment || !!superEditableOffersCount}
                      text={t(
                        personalized
                          ? 'campaign_details_allocation_launch_button'
                          : 'campaign_details_validation_launch_button'
                      )}
                      method={() => {
                        setDialogIsOpen(true);
                      }}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
            {!!superEditableOffersCount && (
              <div className="some-margin-top error-msg d-flex">
                <AlertIcon />
                <p>{t('campaign_details_has_superEditable_offers', { count: superEditableOffersCount })}</p>
              </div>
            )}
          </AsidePanel>
          <ContentDialog
            centerText
            isOpen={dialogIsOpen}
            handleClose={
              !isSending
                ? () => {
                    setDialogIsOpen(false);
                  }
                : null // do not display the Close cross icon
            }
            title={contentDialogTitle}
            desc={contentDialogDesc}
            maxWidth="xs"
          >
            {isSending ? (
              <Loader />
            ) : (
              <div className="confirm-dialog">
                <ButtonsCustom
                  classType="canceled"
                  text={t('commun_button_no')}
                  method={() => setDialogIsOpen(false)}
                />
                <ButtonsCustom
                  classType="action_primary_big"
                  text={t('commun_button_continue')}
                  method={launchAllocationAction}
                />
              </div>
            )}
          </ContentDialog>
        </>
      );
    }

    // status success
    if (targetingStatus === campaignTargetingStatuses.SUCCESS) {
      return (
        <AsidePanel>
          <img src={SuccessIcon} alt="SuccessIcon" />
          <h2 className="title">
            {t(
              personalized ? 'campaign_details_allocation_success_title' : 'campaign_details_validation_success_title'
            )}
          </h2>
          {personalized && (
            <p className="date-info">
              {t('campaign_details_allocation_success_date', {
                date: campaign.targetingDate,
                datetime: campaign.targetingDate
              })}
            </p>
          )}
          <p className="desc">
            {t(personalized ? 'campaign_details_allocation_success_desc' : 'campaign_details_validation_success_desc')}
          </p>
        </AsidePanel>
      );
    }

    // status inprogress
    if ([campaignTargetingStatuses.REQUESTED, campaignTargetingStatuses.IN_PROCESS].includes(targetingStatus)) {
      return (
        <AsidePanel>
          <h2 className="title">
            {t(personalized ? 'campaign_details_allocation_in_progress' : 'campaign_details_Validation_in_progress')}
          </h2>
          {personalized && (
            <>
              <p className="date-info">
                {t('campaign_details_allocation_in_progress_date_requestedAt', {
                  date: campaign.targetingRequestedAt,
                  datetime: campaign.targetingRequestedAt
                })}
              </p>
              <p className="date-info">
                {today.isBefore(campaign.targetingEstimatedAt)
                  ? t('campaign_details_allocation_in_progress_date_estimatedTime', {
                      date: campaign.targetingEstimatedAt,
                      datetime: campaign.targetingEstimatedAt
                    })
                  : t('campaign_details_allocation_in_progress_date_estimatedTime_expired')}
              </p>
            </>
          )}
          <p className="desc">
            {t(
              personalized
                ? 'campaign_details_allocation_in_progress_desc'
                : 'campaign_details_validation_in_progress_desc'
            )}
          </p>
          <div className="loader-spacing">
            <Loader />
          </div>
          {personalized && (
            <ButtonsCustom
              classType="action_primary_big"
              text={t('campaign_details_allocation_refresh_button')}
              method={() => {
                window.location.reload();
              }}
              startIconCustom={<RefreshIcon />}
            />
          )}
        </AsidePanel>
      );
    }

    // status failure
    if (targetingStatus === campaignTargetingStatuses.FAIL) {
      return (
        <AsidePanel>
          <img src={FailureIcon} alt="FailureIcon" />
          <h2 className="title">
            {t(personalized ? 'campaign_details_allocation_failed_title' : 'campaign_details_validation_failed_title')}
          </h2>
          <p className="desc">{t('campaign_details_allocation_failed_desc')}</p>
        </AsidePanel>
      );
    }

    return null;
  };

  return (
    <div className="allocationPanel">
      {hasError && displayErrorFrame()}
      {targetingEnabled ? statusPanel() : <NoAllocationPanel />}
      {downloadPanel()}
    </div>
  );
};

AllocationPanel.propTypes = {
  campaign: PropTypes.shape({
    campaignType: PropTypes.oneOf(Object.values(campaignTypes)),
    cashCoupons: PropTypes.array,
    externalSegment: PropTypes.object,
    id: PropTypes.number,
    retailer: PropTypes.object,
    targetingStatus: PropTypes.oneOf(Object.values(campaignTargetingStatuses)),
    transactionalKpisCashCouponCampaign: PropTypes.object,
    validityStartDate: PropTypes.string,
    validityEndDate: PropTypes.string
  }),
  targetingEnabled: PropTypes.bool
};

export default memo(AllocationPanel);

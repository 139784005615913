import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AlertIcon } from 'assets/24px_alert_round_red.svg';
import styles from './NoBudget.module.scss';

const NoBudget = ({ year }) => {
  const { t } = useTranslation();

  if (!year) return false;
  return (
    <div className={styles['root']}>
      <AlertIcon />
      <p>{t('offers_creation_no_supplier_budget_frame', { year })}</p>
    </div>
  );
};

NoBudget.propTypes = {
  year: PropTypes.number,
};

export default NoBudget;

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatFloat, scrollToElementById, withFractionDigits } from 'utils/global';

import FormManager from 'components/FormManager';
import GeneralInfoBox from 'components/GeneralInfoBox';
import InputAdornment from 'components/InputAdornment';

import ModuloIcon from 'assets/modulo.svg';

import { technicalsFeeType } from 'types/technicalsFees';

interface IState {
  technicalsFees: technicalsFeeType[];
}

type Props = {
  data: technicalsFeeType[];
  onChangeState: (value: IState) => void;
};

const currentYear = new Date().getFullYear();

const TechnicalFees = forwardRef(({ data, onChangeState }: Props, ref): JSX.Element => {
  const { t } = useTranslation();
  const [errorMsgN_plus1, setErrorMsgN_plus1] = useState<boolean>(false);
  const [errorMsgN, setErrorMsgN] = useState<boolean>(false);

  const technicalsFees_N_Minus1_raw = data?.find((fee) => fee.year === currentYear - 1)?.value;
  const technicalsFees_N_Minus1 = technicalsFees_N_Minus1_raw && formatFloat(technicalsFees_N_Minus1_raw);

  const technicalsFees_N_Minus2_raw = data?.find((fee) => fee.year === currentYear - 2)?.value;
  const technicalsFees_N_Minus2 = technicalsFees_N_Minus2_raw && formatFloat(technicalsFees_N_Minus2_raw);

  useImperativeHandle(ref, () => ({
    checkHasError() {
      return handleCheckError();
    }
  }));

  const handleCheckError = () => {
    let errorFieldIdList: string[] = [];
    const technicalsFees_N_plus1 = data?.find((fee) => fee.year === currentYear + 1)?.value;
    const technicalsFees_N = data?.find((fee) => fee.year === currentYear)?.value;

    if (technicalsFees_N_plus1 && technicalsFees_N_plus1 > 100) {
      setErrorMsgN_plus1(true);
      errorFieldIdList.push('technicals-fees-block-id');
    } else {
      setErrorMsgN_plus1(false);
    }

    if (technicalsFees_N && technicalsFees_N > 100) {
      setErrorMsgN(true);
      errorFieldIdList.push('technicals-fees-block-id');
    } else {
      setErrorMsgN(false);
    }

    if (errorFieldIdList.length) {
      setTimeout(() => {
        scrollToElementById(errorFieldIdList[0]);
      }, 150);
    }
    return !!errorFieldIdList.length;
  };

  return (
    <FormManager
      id="technicals-fees-block-id"
      data={{
        title: t('retailers_creation_technical_fees'),
        fieldsets: [
          {
            classnames: ['grid'],
            id: 'creation-form-technical-fees-line-1',
            fields: [
              {
                outerContainerClass: ['half-size-left'],
                classnames: ['without-margin-top'],
                label: currentYear + 1,
                defaultValue: data?.find((fee) => fee.year === currentYear + 1)?.value || '',
                type: 'NumberField',
                id: `technicalFees-${currentYear + 1}`,
                allowZero: true,
                maximumFractionDigits: 2,
                error: errorMsgN_plus1 && t('retailers_creation_retailer_technical_fees_range_error'),
                fieldProps: {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <img alt="percentage icon" src={ModuloIcon} />
                      </InputAdornment>
                    )
                  }
                },
                onFieldChange: (value: number) => {
                  const newData: technicalsFeeType[] = !!data.length
                    ? data.map((fee) => {
                        if (fee.year === currentYear + 1) {
                          return { ...fee, value };
                        } else if (!data.find((el) => el.year === currentYear + 1)) {
                          return { value, year: currentYear + 1 };
                        }
                        return fee;
                      })
                    : [{ value, year: currentYear + 1 }];

                  if (value && value > 100) {
                    setErrorMsgN_plus1(true);
                  } else {
                    setErrorMsgN_plus1(false);
                  }
                  onChangeState({ technicalsFees: newData });
                }
              },
              {
                outerContainerClass: ['half-size-right'],
                classnames: ['without-margin-top'],
                label: currentYear,
                defaultValue: data?.find((fee) => fee.year === currentYear)?.value || '',
                type: 'NumberField',
                id: `technicalFees-${currentYear}`,
                allowZero: true,
                maximumFractionDigits: 2,
                error: errorMsgN && t('retailers_creation_retailer_technical_fees_range_error'),
                fieldProps: {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <img alt="percentage icon" src={ModuloIcon} />
                      </InputAdornment>
                    )
                  }
                },
                onFieldChange: (value: number) => {
                  const newData: technicalsFeeType[] = !!data.length
                    ? data.map((fee) => {
                        if (fee.year === currentYear) {
                          return { ...fee, value };
                        } else if (!data.find((el) => el.year === currentYear)) {
                          return { value, year: currentYear };
                        }
                        return fee;
                      })
                    : [{ value, year: currentYear }];

                  if (value && value > 100) {
                    setErrorMsgN(true);
                  } else {
                    setErrorMsgN(false);
                  }
                  onChangeState({ technicalsFees: newData });
                }
              }
            ]
          },
          {
            classnames: ['with-margin-top', 'grid'],
            id: 'creation-form-technical-fees-line-2',
            fields: [
              {
                outerContainerClass: ['half-size-left'],
                id: `technicalFees-${currentYear - 1}`,
                type: 'CustomContent',
                component: (
                  <GeneralInfoBox fullWidth height="thin" label={(currentYear - 1).toString()}>
                    <span>
                      {technicalsFees_N_Minus1
                        ? t('commun_percentage_number', {
                            value: technicalsFees_N_Minus1,
                            maximumFractionDigits: withFractionDigits(technicalsFees_N_Minus1)
                          })
                        : '-'}
                    </span>
                  </GeneralInfoBox>
                )
              },
              {
                outerContainerClass: ['half-size-right'],
                id: `technicalFees-${currentYear - 2}`,
                type: 'CustomContent',
                component: (
                  <GeneralInfoBox fullWidth height="thin" label={(currentYear - 2).toString()}>
                    <span>
                      {technicalsFees_N_Minus2
                        ? t('commun_percentage_number', {
                            value: technicalsFees_N_Minus2,
                            maximumFractionDigits: withFractionDigits(technicalsFees_N_Minus2)
                          })
                        : '-'}
                    </span>
                  </GeneralInfoBox>
                )
              }
            ]
          }
        ]
      }}
    />
  );
});

export default TechnicalFees;

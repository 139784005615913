import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useDynId from 'customHooks/useDynId';
import TextFieldCustom from 'components/TextFieldCustom';
import { ReactComponent as SearchIcon } from 'assets/20px_recherche.svg';
import { ReactComponent as CrossIcon } from 'assets/22px_croix.svg';

import styles from './PictureGallery.module.scss';

const SearchBox = ({ onChange }) => {
  const { t } = useTranslation();

  const [keyword, setKeyword] = useState('');
  const [keywords, setKeywords] = useState([]);
  const searchId = useDynId('search');

  useEffect(() => {
    onChange(keywords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords]);

  const handleKeyUp = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      handleSubmit();
    }
  };

  const handleChange = (e) => setKeyword(e.target.value);

  const handleSubmit = () => {
    if (!keyword) return;

    setKeywords((list) => [...list, keyword]);
    setKeyword('');
  };

  const handleRemove = (item) => {
    setKeywords((list) => {
      const newList = list.filter((i) => i !== item);
      return newList;
    });
  };

  return (
    <div className={styles['search-box']}>
      <TextFieldCustom
        className={styles['search-field']}
        id="emailInput"
        classType="gray"
        type="text"
        value={keyword}
        label={t('dam_search_placeholder')}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        inputProps={{ autoComplete: 'off' }}
        InputProps={{
          endAdornment: (
            <span id={searchId} role="button" onClick={handleSubmit} className={styles['email-endAdornment']}>
              <SearchIcon />
            </span>
          ),
        }}
      />

      <div className={styles['keywords']}>
        {keywords.map((item) => (
          <div className={styles['keyword']} key={item}>
            <span className={styles['keyword-text']}>{item}</span>
            {handleRemove && (
              <span onClick={() => handleRemove(item)} role="button" className={styles['keyword-remove']}>
                <CrossIcon />
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

SearchBox.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default SearchBox;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Snackbar from '@mui/material/Snackbar';

import { ReactComponent as CloseIcon } from 'assets/22px_croix.svg';
import { ReactComponent as ErrorIcon } from 'assets/30px_error_cross.svg';
import { ReactComponent as SuccessIcon } from 'assets/30px_check.svg';
import { ReactComponent as WarningIcon } from 'assets/30px_attention.svg';

import styles from './SnackBar.module.scss';

const SnackBar = ({ desc, dispatch, title, type }) => {
  const { t } = useTranslation();
  const translatedTitle = typeof title === 'string' ? title : t(title.key, title.params);
  const translatedDesc = typeof desc === 'string' ? desc : t(desc.key, desc.params);

  const typeIcons = {
    ERROR: <ErrorIcon />,
    INFO: <WarningIcon />,
    SUCCESS: <SuccessIcon />,
    WARNING: <WarningIcon />
  };

  const handleClose = () => {
    dispatch({ type: 'UI_SNACKBAR', payload: { type: null, title: '', desc: '' } });
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={!!type}
      autoHideDuration={4000}
      ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
      onClose={handleClose}
    >
      <div className={`${styles['snack-bar']} ${styles[type?.toLowerCase()]}`}>
        {typeIcons[type] && <div className={styles['snack-bar-icon']}>{typeIcons[type]}</div>}
        <div className={styles['snack-bar-message']}>
          <div className={styles['title']}>{translatedTitle}</div>
          {desc && <div className={styles['desc']}>{translatedDesc}</div>}
        </div>
        <CloseIcon className={styles['snack-bar-close-icon']} onClick={handleClose} />
      </div>
    </Snackbar>
  );
};

SnackBar.propTypes = {
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  type: PropTypes.string
};

export default memo(SnackBar);

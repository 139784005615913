import moment from 'moment-timezone';
import { MONTH_LABELS, NON_POSITIONED_BUDGET_THRESHOLD_PERIODS } from './constants';
import { positionedTargetsLevelType } from 'types/positionedTargetsLevels';

const CURRENT_MONTH_INDEX: number = moment().month();

type MonthIndexByLevelsType =
  | {
      monthIndexLevelStart: number;
      monthIndexLevelHalf: number;
      monthIndexLevelEnd: number;
      positionedTargetsLevels_start: positionedTargetsLevelType | undefined;
      positionedTargetsLevels_half: positionedTargetsLevelType | undefined;
      positionedTargetsLevels_end: positionedTargetsLevelType | undefined;
    }
  | undefined;

export const getMonthIndexByLevels = (targetsLevels?: positionedTargetsLevelType[]): MonthIndexByLevelsType => {
  if (!targetsLevels) {
    return undefined;
  }
  // filter to keep only the entries with filled data (beacause month and value can be null)
  const positionedTargetsLevels: positionedTargetsLevelType[] = targetsLevels.filter(
    (level: positionedTargetsLevelType) => level.month
  );

  const positionedTargetsLevels_start: positionedTargetsLevelType | undefined = positionedTargetsLevels?.find(
    (level: positionedTargetsLevelType) => level.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.START_OF_YEAR
  );
  const positionedTargetsLevels_half: positionedTargetsLevelType | undefined = positionedTargetsLevels?.find(
    (level: positionedTargetsLevelType) => level.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.INTERMEDIATE
  );
  const positionedTargetsLevels_end: positionedTargetsLevelType | undefined = positionedTargetsLevels?.find(
    (level: positionedTargetsLevelType) => level.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.END_OF_YEAR
  );

  const monthIndexLevelStart: number = MONTH_LABELS.findIndex((m) => m === positionedTargetsLevels_start?.month);
  const monthIndexLevelHalf: number = MONTH_LABELS.findIndex((m) => m === positionedTargetsLevels_half?.month);
  const monthIndexLevelEnd: number = MONTH_LABELS.findIndex((m) => m === positionedTargetsLevels_end?.month);
  return {
    monthIndexLevelStart,
    monthIndexLevelHalf,
    monthIndexLevelEnd,
    positionedTargetsLevels_start,
    positionedTargetsLevels_half,
    positionedTargetsLevels_end
  };
};

// returns true if nonPositionedPercentage value is greater than positionedTargetsLevels value linked to CURRENT_MONTH_INDEX
export const checkIsPositioningLate = (
  monthIndexByLevels: MonthIndexByLevelsType,
  percentageUnpositioned?: number
): boolean => {
  if (!monthIndexByLevels) return false;

  const {
    monthIndexLevelStart,
    monthIndexLevelHalf,
    monthIndexLevelEnd,
    positionedTargetsLevels_start,
    positionedTargetsLevels_half,
    positionedTargetsLevels_end
  } = monthIndexByLevels;

  // compare with positionedTargetsLevels_end
  if (
    monthIndexLevelEnd > -1 &&
    CURRENT_MONTH_INDEX >= monthIndexLevelEnd &&
    positionedTargetsLevels_end &&
    (positionedTargetsLevels_end.value || positionedTargetsLevels_end.value === 0) &&
    (percentageUnpositioned || percentageUnpositioned === 0)
  ) {
    return positionedTargetsLevels_end.value < percentageUnpositioned;
  }

  // compare with positionedTargetsLevels_half
  else if (
    monthIndexLevelHalf > -1 &&
    CURRENT_MONTH_INDEX >= monthIndexLevelHalf &&
    positionedTargetsLevels_half &&
    (positionedTargetsLevels_half.value || positionedTargetsLevels_half.value === 0) &&
    (percentageUnpositioned || percentageUnpositioned === 0)
  ) {
    return positionedTargetsLevels_half.value < percentageUnpositioned;
  }

  // compare with positionedTargetsLevels_start
  else if (
    monthIndexLevelStart > -1 &&
    CURRENT_MONTH_INDEX >= monthIndexLevelStart &&
    positionedTargetsLevels_start &&
    (positionedTargetsLevels_start.value || positionedTargetsLevels_start.value === 0) &&
    (percentageUnpositioned || percentageUnpositioned === 0)
  ) {
    return positionedTargetsLevels_start.value < percentageUnpositioned;
  }

  // CURRENT_MONTH_INDEX < monthIndexLevelStart
  return false;
};

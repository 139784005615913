import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { store } from 'store';
import { getCashCoupons } from 'api';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { discountTypes } from 'utils/constants';
import TableCustom from 'components/TableCustom';
import ScrollToTop from 'components/ScrollToTop';
import FiltersContainer, { getInitialFilters } from 'components/Filters';
import Drawer from 'components/Drawer';
import ButtonsCustom from 'components/ButtonsCustom';
import CashCouponDetails from 'pages/CashCouponDetails';
import useDynId from 'customHooks/useDynId';
import OfferTopTab from 'components/OfferTopTab';

import styles from '../OfferListSupplier/OfferList.module.scss';

const OfferListCashCoupon = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: cashCouponId } = useParams();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { cashCoupons, queryFilters, user } = state;
  const tableSize = 30;

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [tableOrderBy, setTableOrderBy] = useState('desc');
  const [tableSortBy, setTableSortBy] = useState('id');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initFilters = useMemo(() => getInitialFilters({ history, queryFilters }), []);
  const [filters, setFilters] = useState(initFilters);

  const filtersListToDisplay = ['id', 'title', 'discountType', 'validityDates'];

  const formatCashCouponOffersList = (list) => {
    return list.map((cashCouponOffer) => {
      return {
        id: [{ value: cashCouponOffer.id }],
        discountValue: [
          {
            value:
              cashCouponOffer.discountUnit === 'CURRENCY'
                ? t('commun_price', {
                    value: cashCouponOffer.discountValue,
                    currency: user.currency.code,
                    maximumFractionDigits: 2
                  })
                : `${cashCouponOffer.discountValue} %`
          }
        ],
        discountBasketThreshold: [
          {
            value: t('commun_price', {
              value: cashCouponOffer.discountBasketThreshold,
              currency: user.currency.code
            })
          }
        ],
        title: [{ value: cashCouponOffer.title, color: 'disabled' }],
        discountType: [
          {
            value: t(`_dyn_commun_${discountTypes.find((t) => t.denomination === cashCouponOffer.discountType)?.label}`)
          }
        ],
        validityStartDate: [
          {
            value: t('commun_from_to_dates', {
              valueFrom: cashCouponOffer.validityStartDate,
              valueTo: cashCouponOffer.validityEndDate
            }),
            color: 'disabled'
          }
        ]
      };
    });
  };

  const tableHeader = [
    { id: 1, field: 'id', headerName: 'ID', type: 'text', sortable: true },
    { id: 2, field: 'discountValue', headerName: t('commun_generosity_short'), type: 'text' },
    {
      id: 3,
      field: 'discountBasketThreshold',
      headerName: t('commun_basket_threshold'),
      type: 'text',
      sortable: true
    },
    {
      id: 4,
      field: 'title',
      headerName: t('commun_title_capital'),
      type: 'text',
      sortable: true,
      hasTooltip: true
    },
    {
      id: 5,
      field: 'discountType',
      headerName: t('commun_discount_type'),
      type: 'text'
    },
    {
      id: 6,
      field: 'validityStartDate',
      headerName: t('commun_validation_dates'),
      type: 'text',
      sortable: true
    }
  ];

  const dataList = formatCashCouponOffersList(cashCoupons.list);

  const fetchData = useCallback(
    async (params) => {
      setIsLoading(true);
      await getCashCoupons({
        filters,
        page,
        orderBy: tableOrderBy,
        retailerId: user.id,
        size: tableSize,
        sortBy: tableSortBy,
        ...params
      });
      setIsLoading(false);
    },
    [filters, page, tableOrderBy, tableSortBy, user.id]
  );

  useEffect(() => {
    // on unmount
    return () => {
      dispatch({ type: 'OFFERS_RESET' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, filters, page, tableOrderBy, tableSortBy, user.id]);

  const handleTableNextPage = () => {
    setPage(page + 1);
  };
  const handleTablePrevPage = () => {
    setPage(page - 1);
  };

  const handleTableSort = (field) => {
    let orderBy = '';
    if (field !== tableSortBy) {
      orderBy = 'desc';
    } else {
      orderBy = tableOrderBy === 'asc' ? 'desc' : 'asc';
    }
    setTableSortBy(field);
    setTableOrderBy(orderBy);
    setPage(0);
  };

  const handleTableRowClick = ({ id }) => {
    history.push({ pathname: `/offers/offersRetailerCashCoupon/${id[0].value}`, search: history.location.search });
  };

  const handleUpdateFilters = useCallback((newFilters) => {
    setFilters({ ...newFilters });
  }, []);

  const onCloseFinished = () => {
    dispatch({ type: 'CASH_COUPONS_DETAILS_RESET' });
  };

  const handleClose = () => {
    // reset offer
    history.push({ pathname: '/offers/offersRetailerCashCoupon', search: history.location.search });
  };

  const handleDeletedOffer = () => {
    handleClose();
    setPage(cashCoupons.list.length > 1 ? page : Math.max(0, page - 1));
    fetchData();
  };

  return (
    <section className={styles['root']}>
      <OfferTopTab index={2} />
      <div className={styles['top-wrapper']}>
        <p className={styles['count-result']} id={useDynId('Xcashcoupon')}>
          {t('commun_cash_coupon_count', { count: cashCoupons.total })}
        </p>
        <Link to={'/offers/add-cash-coupon'} role="link">
          <ButtonsCustom
            classType="action_primary_big"
            id={useDynId('createBR')}
            text={t('commun_button_create_offer')}
          />
        </Link>
      </div>
      <FiltersContainer
        dispatch={dispatch}
        filters={filters}
        filtersListToDisplay={filtersListToDisplay}
        updateFilters={handleUpdateFilters}
        user={user}
      />
      <TableCustom
        isLoading={isLoading}
        rows={dataList}
        columns={tableHeader}
        total={cashCoupons.total}
        handleSort={handleTableSort}
        page={page}
        size={tableSize}
        prev={handleTablePrevPage}
        next={handleTableNextPage}
        sort={tableSortBy}
        order={tableOrderBy}
        type="big"
        onRowClick={handleTableRowClick}
        isUsingFilters={!!Object.keys(filters)?.length}
        clickedRawUniqueKeyValue={cashCouponId?.toString()}
      />
      <Drawer isOpen={!!cashCouponId} onClose={handleClose} onCloseFinished={onCloseFinished}>
        <CashCouponDetails cashCouponId={cashCouponId} onDeleted={handleDeletedOffer} />
      </Drawer>
      <ScrollToTop />
    </section>
  );
};

export default OfferListCashCoupon;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import AutocompleteCustom from 'components/AutocompleteCustom';

const Autocomplete = (props) => {
  const {
    className,
    data: { id, defaultValue, disabled, placeholder, fieldProps },
    onChange,
    error,
  } = props;

  useEffect(() => {
    handleChange(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleChange = (item) => {
    if (onChange) onChange(id, item);
  };

  return (
    <AutocompleteCustom
      className={className}
      disabled={disabled}
      error={error}
      onSelection={handleChange}
      placeholderText={placeholder}
      value={defaultValue}
      {...fieldProps}
    />
  );
};

Autocomplete.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
};

export default Autocomplete;

import { getToken, tokenParser, TOKENS } from 'utils/token';

export const checkUserHasChanged = () => {
  // userCode saved in sessionStorage differs from user code in the access_token
  // means a user is logged-in with remember-me on 2 tabs as 2 differents users (with private tabs ?)
  const tokenParsed: any = tokenParser(getToken(TOKENS.access_token));
  if (!tokenParsed) return true;

  const { retailer_code, supplier_code, supplier_retailer_id } = tokenParsed;
  const userCode = retailer_code || supplier_retailer_id?.[0]?.supplier_code || supplier_code;
  const userCodeSaved = sessionStorage.getItem('userCode');

  if (userCodeSaved && userCode !== userCodeSaved) {
    return true;
  }

  // default to false
  return false;
};

import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDynId from 'customHooks/useDynId';
import GoBackLink from 'components/GoBackLink';

import BrCampaignCreationIllustation from 'assets/illu_campaign_br.svg';
import ProductsCampaignCreationIllustation from 'assets/illu_campaign_products.svg';
import ProductsCampaignNonPersonalizedCreationIllustation from 'assets/illu_campaign_products_non_personalized.svg';
import { ReactComponent as ArrowIcon } from 'assets/14px_arrow_right_red.svg';
import styles from './AddCampaign.module.scss';

const AddCampaign = () => {
  const { t } = useTranslation();
  const config = [
    {
      linkTo: 'add-campaign/cash-coupons',
      id: useDynId('createCashCouponCampaign'),
      iconSrc: BrCampaignCreationIllustation,
      altIcon: 'campaign-br-creation-icon',
      title: 'commun_button_create_campaign_cash_coupon',
      desc: 'commun_button_create_campaign_cash_coupon_desc'
    },
    {
      linkTo: 'add-campaign/products',
      id: useDynId('createOfferCampaign'),
      iconSrc: ProductsCampaignCreationIllustation,
      altIcon: 'campaign-products-creation-icon',
      title: 'commun_button_create_campaign_product_offer',
      desc: 'commun_button_create_campaign_product_offer_desc'
    },
    {
      linkTo: 'add-campaign/products-non-personalized',
      id: useDynId('createOfferCampaignNonPersonalized'),
      iconSrc: ProductsCampaignNonPersonalizedCreationIllustation,
      altIcon: 'campaign-products-non-personalized-creation-icon',
      title: 'commun_button_create_campaign_product_non_personalized_offer',
      desc: 'commun_button_create_campaign_product_non_personalized_offer_desc'
    }
  ];

  return (
    <section className={styles['root']}>
      <GoBackLink pathname="/campaigns" />
      <div className={styles['add-campaign']}>
        {config.map((el) => (
          <Link to={el.linkTo} id={el.id} key={el.linkTo}>
            <div className={styles['add-campaign-link']}>
              <div className={styles['add-campaign-link-icon']}>
                <img src={el.iconSrc} alt={el.altIcon} />
              </div>
              <div className={styles['flex-space-between']}>
                <div>
                  <div className={styles['add-campaign-link-title']}>{t(el.title)}</div>
                  <div className={styles['add-campaign-link-desc']}>{t(el.desc)}</div>
                </div>
                <ArrowIcon className={styles['arrow-icon']} />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default AddCampaign;

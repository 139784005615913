import React, { memo, useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { checkCampaignTitle, scrollToElementById } from 'utils/global';
import {
  deleteProductCampaignOffer,
  deleteProductCampaignAllOffers,
  getGenericTargetingStrategyInProductCampaignOffersCount,
  getOffersCompatibleCount,
  getProductCampaignOffers,
  updateProductCampaign,
  updateProductCampaignOffers
} from 'api';
import useDynId from 'customHooks/useDynId';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import FiltersContainer from './FiltersContainer.js';
import FormManager from 'components/FormManager';
import OfferList from './OfferList.js';

const ProductStep2 = forwardRef(
  ({ campaignProduct, disabled, isEditMode, isLoading, strategyData, suppliersData }, ref) => {
    const { t } = useTranslation();
    const thisRef = useRef(null);
    const filtersRef = useRef(null);

    const tableSize = 6;
    const [filters, setFilters] = useState({});
    const [isAddingOffers, setIsAddingOffers] = useState(false);
    const [isAddingOffersSuccess, setIsAddingOffersSuccess] = useState(false);
    const [isLoadingCleaningList, setIsLoadingCleaningList] = useState(false);
    const [isLoadingFilters, setIsLoadingFilters] = useState(false);
    const [openNoOfferSelectedDialog, setOpenNoOfferSelectedDialog] = useState(false);
    const [pageList, setPageList] = useState({ value: 0 });

    useImperativeHandle(ref, () => ({
      ref: thisRef,
      filtersRef: filtersRef,
      step2CheckHasError() {
        // no need to test step2 if step1 dates input is not filled
        const hasError =
          !checkCampaignTitle(campaignProduct.title) || !campaignProduct.startDate || !campaignProduct.endDate
            ? false
            : !campaignProduct.offers.length;

        if (hasError) {
          setOpenNoOfferSelectedDialog(true);
        }
        return hasError;
      }
    }));

    const fetchCompatibleOffersCount = async ({ updatedCampaignProduct, updatedFilters }) => {
      await getOffersCompatibleCount({
        campaignProduct: updatedCampaignProduct || campaignProduct,
        filters: updatedFilters || filters
      });
      setIsAddingOffersSuccess(false);
      return true;
    };

    const fetchOffersCampaign = async () => {
      return await getProductCampaignOffers({
        campaignProduct,
        excludeUntargetable: true,
        page: pageList.value,
        size: tableSize
      });
    };

    useEffect(() => {
      const fetchData = async () => {
        setIsLoadingFilters(true);
        let updatedCampaignProduct = null;
        if (isEditMode) {
          const data = await fetchOffersCampaign();
          if (data) {
            updatedCampaignProduct = data;
            await getGenericTargetingStrategyInProductCampaignOffersCount(updatedCampaignProduct);
          }
        }
        await fetchCompatibleOffersCount({ updatedCampaignProduct });
        setIsLoadingFilters(false);
      };
      if (!disabled && !isLoadingCleaningList) {
        fetchData();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled, filters]);

    useEffect(() => {
      if (!disabled && campaignProduct.id) {
        fetchOffersCampaign();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageList]);

    const handleClearList = async () => {
      if (!isLoadingCleaningList) {
        setIsLoadingCleaningList(true);
        // first we need to update quotes parameters before deleting 1 or all campaign-offers
        const updateCampaignSuccess = await updateProductCampaign(campaignProduct);
        if (updateCampaignSuccess) {
          const deleteSuccess = await deleteProductCampaignAllOffers(campaignProduct.id);
          if (deleteSuccess) {
            await fetchCompatibleOffersCount({ updatedFilters: { id: [], title: '' } });
          }
        }
        setIsAddingOffersSuccess(false);
        setPageList({ value: 0 });
        setIsLoadingCleaningList(false);
      }
    };

    const handleChangePageList = (val) => {
      setPageList(({ value }) => ({ value: value + val }));
    };

    const handleAddOffers = async () => {
      setIsAddingOffers(true);
      const success = await updateProductCampaignOffers({ campaignProduct, filters });
      if (success) {
        setIsAddingOffersSuccess(true);
        const updatedCampaignProduct = await fetchOffersCampaign();
        if (updatedCampaignProduct) {
          await fetchCompatibleOffersCount({ updatedCampaignProduct });
          await getGenericTargetingStrategyInProductCampaignOffersCount(updatedCampaignProduct);
        }
      }
      setIsAddingOffers(false);
    };

    const handleChangeFilters = (newFilters) => {
      setFilters(newFilters);
    };

    const handleDeleteOneInOfferList = async (offerId) => {
      setIsLoadingCleaningList(true);
      let updateCampaignSuccess = true;

      if (campaignProduct.genericTargetingStrategyCampaignOffersCount === 1) {
        // first we need to update quotes parameters before deleting the last linked campaign-offer
        updateCampaignSuccess = await updateProductCampaign(campaignProduct);
      }
      if (updateCampaignSuccess) {
        const deleted = await deleteProductCampaignOffer(offerId);
        if (deleted) {
          await fetchCompatibleOffersCount({});
          await getGenericTargetingStrategyInProductCampaignOffersCount(campaignProduct);
        }
      }

      setIsAddingOffersSuccess(false);
      setIsLoadingCleaningList(false);
      setPageList(({ value }) => ({ value: campaignProduct.offers.length === 1 ? Math.max(0, value - 1) : value }));
    };

    const handleCloseAlertDialog = () => {
      setOpenNoOfferSelectedDialog(false);
      setTimeout(() => {
        scrollToElementById('field-campaign-creation-step-02');
      }, 200);
    };

    return (
      <>
        <div ref={thisRef}>
          <FormManager
            data={{
              title: t('campaign_add_offers_label'),
              subTitle: t('campaign_product_step2_desc'),
              fieldsets: [
                {
                  id: 'add-campaign-product-step-02',
                  fields: [
                    {
                      outerContainerClass: ['full-width'],
                      id: 'offers-filters',
                      type: 'CustomContent',
                      component: (
                        <FiltersContainer
                          campaignProduct={campaignProduct}
                          disabled={disabled}
                          filters={filters}
                          isAddingOffers={isAddingOffers}
                          isAddingOffersSuccess={isAddingOffersSuccess}
                          isLoading={isLoadingFilters || isLoading || isLoadingCleaningList}
                          onAddingOffers={handleAddOffers}
                          onChangeFilters={handleChangeFilters}
                          pageList={pageList}
                          strategyData={strategyData}
                          suppliersData={suppliersData}
                          ref={filtersRef}
                        />
                      )
                    },
                    {
                      outerContainerClass: ['full-width'],
                      id: 'offers-list',
                      type: 'CustomContent',
                      component: (
                        <OfferList
                          campaignProduct={campaignProduct}
                          isAddingOffers={isAddingOffers}
                          isLoading={isLoading}
                          isLoadingCleaningList={isLoadingCleaningList}
                          onClearList={handleClearList}
                          onDeleteOne={handleDeleteOneInOfferList}
                          page={pageList.value}
                          tableSize={tableSize}
                          updatePage={handleChangePageList}
                        />
                      )
                    }
                  ]
                }
              ]
            }}
          />
        </div>
        {/* no offers selected */}
        <ContentDialog
          centerText
          isOpen={openNoOfferSelectedDialog}
          handleClose={handleCloseAlertDialog}
          title={t('campaign_product_step2_modal_message')}
          maxWidth="xs"
        >
          <div className="confirm-dialog">
            <ButtonsCustom
              classType="action_primary_big"
              text={t('commun_button_got_it')}
              method={handleCloseAlertDialog}
              id={useDynId('step2Valid1Modal')}
            />
          </div>
        </ContentDialog>
      </>
    );
  }
);

ProductStep2.propTypes = {
  campaignProduct: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isLoading: PropTypes.bool,
  strategyData: PropTypes.array.isRequired,
  suppliersData: PropTypes.array.isRequired
};

export default memo(ProductStep2);

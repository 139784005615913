import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FormProvider } from './context';

import ButtonsCustom from 'components/ButtonsCustom';
import FieldsetManager from './FieldsetManager';

import './FormManager.scss';

const FormManager = (props) => {
  const {
    data: {
      raiseErrorOnSubmit,
      title,
      subTitle,
      classnames = [],
      fieldsets,
      submit,
      cancel,
      onFormChange,
      alignFormButtons = 'right'
    },
    defaultValues = {},
    id,
    onSubmit,
    onCancel,
    loading
  } = props;

  const [formData, setFormData] = useState({ ...defaultValues });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (onFormChange) onFormChange(formData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const shouldUpdateForm = (id, value) => {
    setFormData((prev) => ({
      ...prev,
      [id]: value
    }));
  };

  const shouldRaiseError = (id, message) => {
    setErrors((prev) => ({
      ...prev,
      [id]: message
    }));
  };

  const shouldFlagValid = (id) => {
    setErrors((prev = {}) => {
      delete prev[id];
      return prev;
    });
  };

  const handleCancel = () => {
    if (cancel.onClick) {
      cancel.onClick(formData);
    }
    if (onCancel) {
      onCancel(formData);
    }
  };

  const handleSubmit = (event) => {
    if (!onSubmit) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    const isFormValid = !Object.entries(errors).length;

    setSubmitted(true);

    if (!isFormValid) {
      const [firstError] = Object.entries(errors);
      const firstElementInError = document.getElementById(`field-${firstError[0]}`);

      if (firstElementInError) {
        firstElementInError.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      onSubmit(formData);
    }
  };

  const myContext = {
    formData,
    errors: submitted || !raiseErrorOnSubmit ? errors : [],
    defaultValues: { ...defaultValues, ...formData },
    raiseErrorOnSubmit,
    shouldUpdateForm,
    shouldFlagValid,
    shouldRaiseError
  };

  const isFormValid = !Object.entries(errors).length;

  return (
    <FormProvider value={myContext}>
      <form className={['form', ...classnames].join(' ')} id={id} onSubmit={handleSubmit} autoComplete="off">
        <div className="form-container">
          {title && (
            <div className="form-container-header">
              <h3>{title}</h3>
              {subTitle && <p>{subTitle}</p>}
            </div>
          )}
          {fieldsets.map((el) => (
            <FieldsetManager key={el.id} data={el} />
          ))}
        </div>
        {(submit || cancel) && (
          <div className={clsx('form-buttons', 'spaced', `form-buttons-align-${alignFormButtons}`)}>
            {cancel && (
              <ButtonsCustom
                classType="canceled"
                text={cancel.label}
                method={handleCancel}
                id={cancel.id}
                disabled={cancel.disabled}
                startIconCustom={cancel.icon}
                tooltip={cancel.tooltip}
              />
            )}
            {submit && (
              <ButtonsCustom
                classType="action_primary_big"
                text={submit.label}
                id={submit.id}
                method={handleSubmit}
                loading={loading || submit.loading}
                disabled={(!raiseErrorOnSubmit && !isFormValid) || submit.disabled}
                endIconCustom={submit.icon}
                tooltip={submit.tooltip}
              />
            )}
          </div>
        )}
      </form>
    </FormProvider>
  );
};

FormManager.propTypes = {
  alignFormButtons: PropTypes.oneOf(['left', 'center', 'right']),
  data: PropTypes.object,
  defaultValues: PropTypes.object,
  id: PropTypes.string,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
};

export default FormManager;

import React, { memo } from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import styles from './TextFieldCustom.module.scss';
import clsx from 'clsx';

const TextFieldCustom = ({
  className,
  classType,
  error,
  errorMsg,
  InputProps,
  inputProps,
  label,
  readOnly,
  shrinkPlaceholder = false,
  type = 'text',
  ...props
}) => {
  return (
    <TextField
      className={clsx(
        className,
        styles['text-field-custom'],
        error && styles['error'],
        classType && styles[classType],
        readOnly && styles['input-readOnly'],
        shrinkPlaceholder && styles['no-shrinkPlaceholder']
      )}
      error={error}
      variant="filled"
      InputLabelProps={{
        classes: {
          root: styles['input-label-root'],
          disabled: styles['input-label-disabled'],
          focused: styles['input-label-focused']
        }
      }}
      InputProps={{
        classes: {
          root: styles['input-root'],
          input: styles['input-base'],
          disabled: styles['input-disabled']
        },
        readOnly,
        ...InputProps
      }}
      inputProps={{ ...inputProps, autoComplete: 'off', autofill: 'off' }}
      hiddenLabel={!shrinkPlaceholder}
      label={shrinkPlaceholder ? label : null}
      placeholder={label && !shrinkPlaceholder ? label : null}
      helperText={error && typeof errorMsg === 'string' && <span className={styles['error']}>{errorMsg}</span>}
      type={type}
      {...props}
    />
  );
};

TextFieldCustom.propTypes = {
  InputProps: PropTypes.object,
  className: PropTypes.string,
  classType: PropTypes.string,
  error: PropTypes.bool,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  inputProps: PropTypes.object,
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  shrinkPlaceholder: PropTypes.bool
};

export default memo(TextFieldCustom);

import { discountTypes, offerHeadTypes } from 'utils/constants';

/**
 * Modéle contenant les données du formulaire de création/modification d'offre<br>
 * Le modèle contient aussi des innformations sur l'état d'utilsation du formulaire:<br>
 * nombre de réquètes, chargements en cours, erreurs, etc
 * @type {{step4: {budgetType: string, supplierBill: boolean, isLoadingSupplierBudgets: boolean, recoCounter: number, isLoadingRecommendation: boolean, recommendation: null, completed: boolean, validityDates: [{endDate: null, startDate: null}], budget: string}, step2: {targetingStrategy: {id: string}, completed: boolean}, step3: {additionalInformation: {value: string}, image: boolean, quantity: {value: string}, resultListEan: *[], brands: *[], filterCategory: {value: string}, completed: boolean, numberMax: {value: string}, title: {value: string}, generosity: {unit: number, value: string}, offerType: string, filterBrand: {value: string}, filterBrandList: *[], discountType: {}, tag: {value: string}, filterCategoryList: *[]}, errors: {resultListBrandError: boolean, dateError: boolean, numberMaxError: boolean, discountTypeError: boolean, targetError: boolean, strategyError: boolean, titleError: boolean, unitError: boolean, stepOneError: boolean, quantityError: boolean, budgetTypeError: boolean, generosityError: boolean, resultListEanError: boolean, budgetError: boolean, imageError: boolean}, step1: {damName: null, multiSupplierId: string, code: string, completed: boolean, id: string}}}
 */
export const initialObj = {
  /** Choix du fournisseur ou du distributeur */
  step1: {
    code: '',
    completed: false,
    damName: null,
    id: '',
    multiSupplierId: null
  },
  /** Choix de la stratégie du ciblage en accord avec le choix du fournisseur */
  step2: {
    completed: false,
    externalSegment: { id: null },
    targetingStrategy: { id: '' }
  },
  /**
   * Produis de l'offre par marque ou par produit, image de l'offre,<br>
   * paramètres de la promotion, titre et informations supplémentaires,<br>
   * dates de validité de l'offre.
   */
  step3: {
    completed: false,
    offerType: offerHeadTypes.PRODUCT_OFFER,
    discountType: {},
    numberMax: { value: '' },
    quantity: { value: '' },
    generosity: { value: '', unit: 1 },
    title: { value: '' },
    additionalInformation: { value: '' },
    tag: { value: '' },
    image: false,
    cropData: null,
    resultListEan: [],
    brands: [],
    filterBrand: { value: '' },
    filterBrandList: [],
    filterCategory: { value: '' },
    filterCategoryList: []
  },
  /**
   * Recommandation du montant du budget,<br>
   * et allocation manuelle du la somme pour l'offre avant validation
   */
  step4: {
    completed: false,
    supplierBill: true,
    budget: '',
    budgetType: '',
    isLoadingRecommendation: false,
    isLoadingSupplierBudgets: false,
    recoCounter: 0,
    recommendation: null,
    validityDates: [{ startDate: null, endDate: null }]
  },
  errors: {
    stepOneError: false,
    segmentError: false,
    targetError: false,
    discountTypeError: false,
    numberMaxError: false,
    quantityError: false,
    generosityError: false,
    unitError: false,
    titleError: false,
    imageError: false,
    resultListEanError: false,
    resultListBrandError: false,
    dateError: false,
    budgetError: false,
    budgetTypeError: false
  }
};

export const mapOfferToForm = (offer = {}, user) => {
  const oppositeUserType = user.userType === 'retailer' ? 'supplier' : 'retailer';
  const { offerDetails, resultListEan, validityDatesList } = offer;

  if (offerDetails) {
    return {
      ...initialObj,
      step1: {
        completed: true,
        id: offerDetails.offerHead.budget?.[oppositeUserType]?.id,
        code: offerDetails.offerHead.budget?.[oppositeUserType]?.code,
        damName: offerDetails.retailer.damName,
        multiSupplierId: user.isMultiSupplierAccount ? offerDetails.offerHead.budget?.supplier?.id : null
      },
      step2: {
        completed: true,
        externalSegment: { id: offerDetails.offerHead.externalSegment?.id },
        targetingStrategy: { id: offerDetails.targetingStrategy?.id || '' }
      },
      step3: {
        ...initialObj.step3,
        brands: offer.offerBrands,
        offerType: offerDetails.offerHead.type,
        additionalInformation: { value: offerDetails.offerHead.additionalInformation || '' },
        completed: true,
        discountType: { id: discountTypes.find((d) => d.denomination === offerDetails.discountType)?.id },
        numberMax: { value: offerDetails.discountMaximumUses },
        quantity: { value: offerDetails.discountMinQuantityOfProducts },
        generosity: {
          value: offerDetails.discountValue,
          unit: user.discountUnits.find((d) => d.denomination === offerDetails.discountUnit)?.id
        },
        title: { value: offerDetails.offerHead.title || '' },
        tag: { value: offerDetails.offerHead.descriptionTag || '' },
        image: offerDetails.offerHead.fileDescriptor,
        cropData: offerDetails.croppedImageMetadata,
        resultListEan
      },
      step4: {
        completed: true,
        budget: offerDetails.offerHead.budgetTarget,
        budgetType: offerDetails.offerHead.budget?.type,
        isLoadingRecommendation: false,
        isLoadingSupplierBudgets: false,
        recoCounter: 0,
        recommendation: offerDetails.offerHead.budgetEstimated,
        supplierBill: true,
        validityDates: validityDatesList
      }
    };
  }

  return initialObj;
};

export const checkAddOfferStep1 = (step) => !!step.id;

export const checkAddOfferStep2 = (step) => !!step.targetingStrategy?.id;

export const checkAddOfferStep3 = (step) => {
  const { discountType, numberMax, quantity, generosity, title, image, resultListEan, brands } = step;
  return !!(
    discountType.id &&
    numberMax.value &&
    quantity.value &&
    generosity.value &&
    generosity.unit &&
    title.value &&
    image &&
    (resultListEan.length || brands.length)
  );
};

export const checkAddOfferStep4 = (step, budgetFree) => {
  const { budget, budgetType, validityDates } = step;
  const hasAtLeastOneValidityDate = !!validityDates?.find((v) => !!v.startDate && !!v.endDate);
  return budgetType && !!(budget && budget <= budgetFree && hasAtLeastOneValidityDate);
};

import React from 'react';
import PropTypes from 'prop-types';

import DamImage from 'components/DamImage';

import { getDamImage } from 'api';

import styles from './PictureGallery.module.scss';

const Card = ({ ean, label, retailerCode, selected, onClick }) => {
  const handleClick = async () => {
    if (onClick) {
      const img = await getDamImage(ean, retailerCode, 'M1_S1', 'jpg');
      const fl = new File([img], `file_${ean}_${Date.now()}.jpg`, { type: 'image/jpeg' });

      if (fl) {
        onClick(fl);
      }
    }
  };

  return (
    <div
      className={`${styles['product-card']} ${selected ? styles['selected-product'] : ''}`}
      onClick={(file) => handleClick(file)}
      role="button"
    >
      <DamImage className={styles['product-card-image']} retailerCode={retailerCode} productCode={ean} />
      <div className={styles['product-card-details']}>
        <span className={styles['product-card-label']}>{label}</span>
        <span className={styles['product-card-tag']}>{ean}</span>
      </div>
    </div>
  );
};

Card.propTypes = {
  ean: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  retailerCode: PropTypes.string,
  selected: PropTypes.bool,
};

export default Card;

import React, { ReactNode } from 'react';
import MaterialTooltip from '@mui/material/Tooltip';
import styles from './Tooltip.module.scss';

type Props = {
  children: JSX.Element;
  disabled?: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  title: ReactNode;
};

const Tooltip = ({ children, placement, title, disabled }: Props): JSX.Element =>
  disabled ? (
    children
  ) : (
    <MaterialTooltip
      classes={{ popper: styles['popper'], tooltip: styles['tooltip'], arrow: styles['arrow'] }}
      title={title}
      arrow
      enterDelay={300}
      enterNextDelay={300}
      placement={placement}
    >
      {children}
    </MaterialTooltip>
  );

export default Tooltip;

import React, { memo, useState, useEffect } from 'react';
import clsx from 'clsx';
import { crossProductPercentage } from 'utils/global';

import styles from './ProgressBar.module.scss';

type Props = {
  color?: 'primary' | 'secondary' | 'tertiary';
  small?: boolean;
  total: number;
  value: number;
};

const ProgressBar = ({ color = 'primary', small, total, value }: Props): JSX.Element => {
  const [hidePreload, setHidePreload] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHidePreload(true);
    });
  }, []);

  // unlimited layout
  if (total === -1) {
    return <div className={clsx(styles['root'], small && styles['small'], styles['bar-unlimited'])}></div>;
  }

  let val = crossProductPercentage({ value: value || 0, total });
  // enhance visuals
  if (val > 0) {
    val = Math.max(val, 2);
  }

  return (
    <div className={clsx(styles['root'], small && styles['small'])}>
      <div
        className={clsx(styles['bar'], styles[`bar-${color}`], !hidePreload && styles['preload'])}
        style={{ width: `${Math.min(100, val)}%` }}
      ></div>
    </div>
  );
};

export default memo(ProgressBar);

import axios from 'axios';
import moment from 'moment-timezone';
import {
  apiUrlCouponingEngine,
  apiUrlSegmentManager,
  apiUrlSegmentManagerEstimateCustomerCount,
  apiUrl,
  campaignStates,
  campaignStatuses,
  campaignTargetingStatuses,
  campaignTypes,
  discountTypes,
  discountUnits,
  initialCampaignProductCreationParams,
  initialCampaignProductCreationParamsQuotesWithGenericTargetingOffers,
  initialCampaignProductCreationParamsQuotesWithoutGenericTargetingOffers,
  offerHeadTypes,
  offerStatuses,
  offerTypes,
  segmentTypes,
  segmentUpdatingStatuses,
  snackbarTypes,
  supplierStatuses,
  visualFileStatuses,
  supplierBudgetTypes,
  segmentScopes
} from 'utils/constants';
import { checkCurrency, checkYearFormat, objectOrderedKeys, QsStringify } from 'utils/global';
import { getToken, isTokenExpired, removeAllTokens, saveTokens, tokenParser, TOKENS } from 'utils/token';
import { snakeToCamel } from 'utils/namecases';
import { checkUserHasChanged } from 'utils/auth';
import { getCookie, setCookie } from 'utils/cookies';

let dispatch;
const currentYear = new Date().getFullYear();
const keycloak = {
  refreshToken: () => getToken(TOKENS.refresh_token),
  token: () => getToken(TOKENS.access_token),
  tokenParsed: () => tokenParser(getToken(TOKENS.access_token))
};

const apiCall = async (payload) => {
  const {
    method,
    baseUrl,
    url,
    data,
    params,
    contentType,
    responseType,
    statusCodesToSkip = [],
    disableSnackbar
  } = payload;
  const token = keycloak.token();

  if (token) {
    // check if userCode in sessionStorage matches the one in access_token
    const userHasChanged = checkUserHasChanged();
    if (userHasChanged) {
      removeAllTokens();
      window.location.reload();
      return false;
    }

    try {
      return await axios({
        baseURL: baseUrl || apiUrl,
        method: method || 'get',
        url,
        params,
        paramsSerializer: (params) => QsStringify(params),
        data,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': contentType || 'application/json'
        },
        responseType,
        validateStatus: (status) => status < 400
      });
    } catch (e) {
      // Unauthorized
      if (e?.response?.status === 401 || isTokenExpired(token)) {
        const refreshToken = keycloak.refreshToken();
        if (isTokenExpired(refreshToken)) {
          removeAllTokens();
          window.location.reload();
          return false;
        } else {
          const fetchRefreshTokenSuccess = await postRefreshToken(refreshToken);
          if (fetchRefreshTokenSuccess) {
            if (getCookie('remember-me') === 'true') {
              setCookie({ name: 'remember-me', value: 'true', maxAge: 7 * 24 * 60 * 60 });
            }
            await init(dispatch);
            return await apiCall(payload);
          } else {
            window.location.reload();
          }
          return false;
        }
      }

      // Other cases
      if (!disableSnackbar && !statusCodesToSkip.includes(e?.response?.status)) {
        // eslint-disable-next-line no-console
        console.log('Oups! Something went wrong !', e.message || JSON.stringify(e));

        const errorMessage = e?.response?.data?.errorKey
          ? e?.response?.data?.errorKey.toLowerCase()
          : 'commun_api_error';

        dispatch({
          type: 'UI_SNACKBAR',
          payload: { type: snackbarTypes.ERROR, title: { key: errorMessage } }
        });
        return false;
      }
    }
  } else {
    window.location.reload();
  }
};

export const init = async (storeDispatch) => {
  dispatch = storeDispatch;
  const token = keycloak.token();
  const refreshToken = keycloak.refreshToken();

  if (!token || isTokenExpired(refreshToken)) {
    // eslint-disable-next-line no-console
    console.log('access_token is not available or refresh_token is expired');
    removeAllTokens();
    window.location.reload();
    return false;
  }

  // get user info from tokenParsed
  const {
    given_name = '',
    family_name = '',
    retailer_code,
    supplier_code,
    supplier_retailer_id,
    roles = '',
    preferred_username,
    user_manager
  } = keycloak.tokenParsed();
  const isSuperAdmin = roles.includes('SUPER_ADMIN');
  const isRetailerService = roles.includes('RETAILER_SERVICE');

  if (isSuperAdmin || isRetailerService) {
    dispatch({
      type: 'INIT',
      payload: {
        user: {
          firstname: given_name,
          lastname: family_name,
          roles: isSuperAdmin ? ['SUPER_ADMIN'] : ['RETAILER_SERVICE'],
          userType: isSuperAdmin ? 'superAdmin' : 'retailerService',
          token
        }
      }
    });
    return true;
  }

  const userType = retailer_code ? 'retailer' : 'supplier';
  const isRetailer = userType === 'retailer';
  const isMultiSupplierAccount = !isRetailer && supplier_retailer_id?.length > 1;
  const userCode = retailer_code || supplier_retailer_id?.[0]?.supplier_code || supplier_code;

  if (!userCode) return false;

  // set userCode in sessionStorage
  sessionStorage.setItem('userCode', userCode);

  const userInfo = {
    code: userCode,
    firstname: given_name,
    isMultiSupplierAccount,
    lastname: family_name,
    userType: userType,
    roles: roles
  };

  // get more user info from api
  const responseUser = await apiCall({
    url: `/${userType}s`
  });
  if (!responseUser) return false;

  // if retailer : to be sure this is the good user
  // if supplier : get the matching user (when multi supplier manager)
  let user = responseUser.data.find((user) => user.code === userCode);
  if (!user) return false;

  user.currency = isRetailer
    ? { ...user.currency, symbol: checkCurrency(user.currency.code) }
    : { ...user.retailer.currency, symbol: checkCurrency(user.retailer.currency.code) };
  user.timezone = isRetailer ? { ...user.timezone } : { ...user.retailer.timezone };
  user.token = token;
  user.userManagerId = user_manager ? (await getUserManagerByEmail(preferred_username))?.id : null;

  // set retailer timezone globally
  moment.tz.setDefault(user.timezone?.name);
  //set user local globally
  const userLocale = navigator.language.split('-')[0];
  moment.locale(userLocale);

  const settingsDiscountUnits = [...discountUnits];
  settingsDiscountUnits[1] = { ...settingsDiscountUnits[1], label: user.currency.symbol };
  user.discountUnits = settingsDiscountUnits;

  dispatch({
    type: 'INIT',
    payload: {
      user: { ...userInfo, ...user }
    }
  });

  return true;
};

/*** Authentication ***/

export const postLoginRequest = async ({ login, password, rememberMe }) => {
  try {
    const params = new URLSearchParams();
    params.append('client_id', 'app');
    params.append('grant_type', 'password');
    params.append('username', login);
    params.append('password', password);

    const postLogin = await axios.post(
      `${window.REACT_APP_KEYCLOAK_URL}/realms/${window.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );

    if (postLogin?.status === 200) {
      saveTokens(postLogin.data.access_token, postLogin.data.refresh_token);

      if (postLogin.errorMsg === 'Account is not fully set up') {
        window.location = `${window.REACT_APP_KEYCLOAK_URL}/realms/${window.REACT_APP_KEYCLOAK_REALM}/account`;
      }

      return postLogin;
    }
  } catch (error) {
    if (error.response?.data?.error_description === 'Account is not fully set up') {
      window.location = `${window.REACT_APP_KEYCLOAK_URL}/realms/${window.REACT_APP_KEYCLOAK_REALM}/account`;
    }

    // eslint-disable-next-line no-console
    console.log('Login Error --- ', error);
    // eslint-disable-next-line no-console
    console.log(
      `Login Error --- type: ${error.response?.data?.error} / description: ${error.response?.data?.error_description}`
    );

    return { errorType: error.response?.data?.error, errorMsg: error.response?.data?.error_description };
  }
};

export const postRefreshToken = async (refreshToken) => {
  const params = new URLSearchParams();
  params.append('client_id', 'app');
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', refreshToken);

  try {
    const postLogin = await axios.post(
      `${window.REACT_APP_KEYCLOAK_URL}/realms/${window.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );

    if (postLogin?.status === 200) {
      saveTokens(postLogin.data.access_token, postLogin.data.refresh_token);
      return true;
    }
  } catch (e) {
    return false;
  }
};

/*** Campaigns ***/

export const deleteCampaign = async ({ campaign, displaySnackbar = true }) => {
  const response = await apiCall({
    method: 'delete',
    url: `/campaigns/${campaign.id}`
  });
  if (!response) return false;

  if (displaySnackbar && campaign.status !== 'DRAFT') {
    dispatch({
      type: 'UI_SNACKBAR',
      payload: {
        type: snackbarTypes.SUCCESS,
        title: { key: 'campaign_details_deleted', params: { campaignId: campaign.id } }
      }
    });
  }

  return true;
};

export const duplicateCampaign = async ({ campaignId, validityEndDate, validityStartDate }) => {
  const response = await apiCall({
    method: 'post',
    url: `/campaigns/${campaignId}/duplicate`,
    data: { validityEndDate, validityStartDate }
  });
  if (!response) return false;

  dispatch({
    type: 'UI_SNACKBAR',
    payload: {
      type: snackbarTypes.SUCCESS,
      title: { key: 'campaign_duplicate_snackbar_success' }
    }
  });
  return response.data;
};

export const launchAllocation = async (campaign) => {
  let response = await apiCall({
    method: 'put',
    url: '/campaigns',
    data: {
      ...campaign,
      targetingStatus: campaignTargetingStatuses.REQUESTED
    }
  });
  if (!response) return false;

  return true;
};

export const getCampaign = async (campaignId) => {
  const getExternalSegmentMapping = (oldRetailerSegment) => {
    return {
      archived: oldRetailerSegment?.archived,
      customerCount: oldRetailerSegment?.segmentNumberOfLoyaltyCards,
      description: oldRetailerSegment?.segment?.defaultDescription,
      id: oldRetailerSegment?.segment?.id,
      lastUpdate: oldRetailerSegment?.segmentDateOfLastUpdate,
      name: oldRetailerSegment?.segment?.defaultName,
      retailerCode: oldRetailerSegment?.retailer?.code,
      retailerSegmentId: oldRetailerSegment?.id,
      updatingStatus: oldRetailerSegment?.segmentDateOfLastUpdate ? 'SUCCESS' : null
    };
  };

  let campaignResponse = await apiCall({ url: `/campaigns/${campaignId}` });
  if (!campaignResponse) return false;

  const isDeletableResponse = await apiCall({ url: `/campaigns/${campaignId}/deletable-status` });
  if (!isDeletableResponse) return false;
  campaignResponse.data.isDeletable = { ...isDeletableResponse.data };

  // add cashCoupons and retailerSegment data to campaign object
  let extraResponse = await apiCall({
    url:
      campaignResponse.data.campaignType === campaignTypes.CASH_COUPON
        ? '/cash-coupon-campaigns'
        : '/retailer-segment-campaigns',
    params: { 'campaignId.equals': campaignId }
  });
  if (!extraResponse) return false;

  if (campaignResponse.data.campaignType === campaignTypes.CASH_COUPON) {
    campaignResponse.data.cashCoupons = extraResponse.data.map((extra) => ({
      ...extra,
      externalSegment: extra.externalSegment?.id
        ? extra.externalSegment
        : getExternalSegmentMapping(extra.retailerSegment)
    }));
  } else {
    const segmentRaw = extraResponse.data[0];
    const segment = segmentRaw?.externalSegment?.id
      ? { ...segmentRaw?.externalSegment, retailerSegmentId: segmentRaw.id }
      : getExternalSegmentMapping(segmentRaw?.retailerSegment);
    campaignResponse.data.externalSegment = segment;
  }

  dispatch({
    type: 'CAMPAIGNS_DETAILS_UPDATE',
    payload: {
      campaign: campaignResponse.data
    }
  });
  return campaignResponse.data;
};

export const getCampaignKpisFile = async (campaignId) => {
  return downloadFile(`/campaigns/${campaignId}/files-by-type?fileType=KPI`);
};

export const getCampaignOffersFile = async (campaignId) => {
  return downloadFile(`/campaigns/${campaignId}?format=EXCEL`);
};

export const getCampaignTargetingFile = async (campaignId) => {
  return downloadFile(`/campaigns/${campaignId}/files-by-type?fileType=TARGETING`);
};

export const getCampaignTargetingStatus = async (campaignId) => {
  const campaignResponse = await apiCall({ url: `/campaigns/${campaignId}` });
  if (!campaignResponse) return false;
  return campaignResponse.data.targetingStatus;
};

export const getCampaigns = async ({ orderBy, page, sortBy, filters }) => {
  const hasIdFilter =
    (filters.id && !Array.isArray(filters.id)) || (filters.id && Array.isArray(filters.id) && filters.id?.length);

  // link REQUESTED with IN_PROCESS
  const newTargetingStatus = filters.targetingStatus?.length && [...filters.targetingStatus];
  if (newTargetingStatus?.includes(campaignTargetingStatuses.IN_PROCESS)) {
    newTargetingStatus.push(campaignTargetingStatuses.REQUESTED);
  }

  if (newTargetingStatus?.includes(campaignTargetingStatuses.FAIL)) {
    newTargetingStatus.push(campaignTargetingStatuses.FAIL_DATA);
  }

  const filtersParams = {
    'id.in': filters.id,
    'title.contains': !hasIdFilter ? filters.title : undefined,
    'campaignType.in': !hasIdFilter ? filters.type : undefined,
    'campaignState.in': !hasIdFilter ? filters.state : undefined,
    'targetingStatus.in': !hasIdFilter && newTargetingStatus?.length ? newTargetingStatus : undefined,
    [`validityStartDate.${filters.validityStartDate?.[0]}`]:
      !hasIdFilter && filters.validityStartDate?.[1]
        ? moment(filters.validityStartDate[1]).startOf('day').utc().format()
        : undefined,
    [`validityEndDate.${filters.validityEndDate?.[0]}`]:
      !hasIdFilter && filters.validityEndDate?.[1]
        ? moment(filters.validityEndDate[1]).endOf('day').utc().format()
        : undefined,
    [`discountGranted.${filters.budgetSpent?.[0]}`]:
      !hasIdFilter && filters.budgetSpent?.[1] ? filters.budgetSpent[1] : undefined
  };

  const params = { page, size: 30, sort: sortBy ? `${sortBy},${orderBy}` : undefined, ...filtersParams };

  const campaignsResponse = await apiCall({
    url: '/campaigns',
    params: { 'status.equals': 'VALIDATED', ...params }
  });

  if (!campaignsResponse) return false;

  const offerCountsPromises = campaignsResponse.data.map((campaign) => {
    const campaignType = campaign.campaignType === 'CASH_COUPON' ? 'cash-coupon' : 'offer';
    return apiCall({ url: `/${campaignType}-campaigns/count`, params: { 'campaignId.equals': campaign.id } });
  });

  const offerCounts = await Promise.all(offerCountsPromises);
  if (!offerCounts) return false;

  const campaigns = campaignsResponse.data.map((c, index) => {
    return { ...c, offerCount: offerCounts[index].data };
  });

  dispatch({
    type: 'CAMPAIGNS_UPDATE',
    payload: {
      page: page ?? 0,
      total: parseInt(campaignsResponse.headers['x-total-count'], 10) ?? 0,
      list: campaigns ?? []
    }
  });

  return true;
};

export const getCampaignsByIds = async (ids) => {
  const campaignsResponse = await apiCall({
    url: '/campaigns',
    params: { 'status.equals': 'VALIDATED', 'id.in': ids }
  });
  if (!campaignsResponse) return false;

  return campaignsResponse.data;
};

export const getDuplicateCampaignStatus = async ({ campaignId, validityEndDate, validityStartDate }) => {
  const response = await apiCall({
    method: 'post',
    url: `/campaigns/${campaignId}/duplicable-status`,
    data: {
      validityEndDate: validityEndDate.hours(12).minutes(0).seconds(0).utc(),
      validityStartDate: validityStartDate.hours(12).minutes(0).seconds(0).utc()
    }
  });
  if (!response) return false;
  return response.data;
};

/*** Campaign-creation CashCoupon ***/

export const postCashCouponCampaign = async ({ campaign, retailerId }) => {
  const data = {
    targetingStatus: campaignTargetingStatuses.NOT_REQUESTED,
    title: campaign.title,
    validityStartDate: campaign.date.startDate, // no need for utc
    validityEndDate: campaign.date.endDate,
    status: 'VALIDATED',
    retailer: {
      id: retailerId
    }
  };

  const postCampaignResponse = await apiCall({ method: 'post', url: '/campaigns', data });
  if (!postCampaignResponse) return false;
  const createCouponData = campaign.offers.map((offer) => ({
    campaign: { id: postCampaignResponse.data.id },
    cashCoupon: { id: offer.cashCoupon.id },
    externalSegment: { id: offer.retailerSegment.id }
  }));

  const postCampaignCouponResponse = await apiCall({
    method: 'post',
    url: '/cash-coupon-campaigns/bulk',
    data: createCouponData
  });
  if (!postCampaignCouponResponse) return false;

  return postCampaignResponse.data;
};

export const updateCashCouponCampaign = async ({ campaign, retailerId }) => {
  // 1/ check if original campaign can still be edited
  const originalCampaignResponse = await apiCall({ url: `/campaigns/${campaign.id}` });
  if (!originalCampaignResponse) {
    return { success: false, msg: 'campaign_creation_update_deleted_campaign_error' };
  }

  if (originalCampaignResponse.data.targetingStatus !== campaignTargetingStatuses.NOT_REQUESTED) {
    dispatch({
      type: 'UI_SNACKBAR',
      payload: {
        type: snackbarTypes.ERROR,
        title: { key: 'campaign_creation_update_allocated_campaign_error' }
      }
    });
    return { success: false, msg: 'campaign_creation_update_allocated_campaign_error' };
  }

  const data = {
    id: campaign.id,
    targetingStatus: campaignTargetingStatuses.NOT_REQUESTED,
    title: campaign.title,
    validityStartDate: campaign.date.startDate, // no need for utc
    validityEndDate: campaign.date.endDate,
    status: 'VALIDATED',
    retailer: {
      id: retailerId
    }
  };

  const updateCampaignResponse = await apiCall({ method: 'put', url: '/campaigns', data });
  if (!updateCampaignResponse) return false;

  /* check and create deleted and added cash coupons lists */
  let deletedList = [];
  let addedList = [];

  // check if list has changed
  if (JSON.stringify(campaign.originalCampaignOffers) !== JSON.stringify(campaign.offers)) {
    const currentOfferIds = campaign.offers.map((o) => o.id);
    // fill deletedList
    campaign.originalCampaignOffers.forEach((o) => {
      if (!!o.id && !currentOfferIds.includes(o.id)) {
        deletedList.push(o);
      }
    });

    //fill addedList
    addedList = campaign.offers.filter((o) => !o.id);
  }

  // first delete
  if (deletedList.length) {
    const promises = deletedList.map((o) =>
      apiCall({
        method: 'delete',
        url: `/cash-coupon-campaigns/${o.id}`
      })
    );
    await Promise.all(promises);
  }

  // then add new cash coupons
  if (addedList.length) {
    const createCouponData = addedList.map((offer) => ({
      campaign: { id: updateCampaignResponse.data.id },
      cashCoupon: { id: offer.cashCoupon.id },
      externalSegment: { id: offer.retailerSegment.id }
    }));

    const postCampaignCouponResponse = await apiCall({
      method: 'post',
      url: '/cash-coupon-campaigns/bulk',
      data: createCouponData
    });
    if (!postCampaignCouponResponse) return false;
  }

  dispatch({
    type: 'UI_SNACKBAR',
    payload: {
      type: snackbarTypes.SUCCESS,
      title: { key: 'campaign_edition_snackbar_message_ok' }
    }
  });

  return updateCampaignResponse.data;
};

/*** Campaign-creation Product ***/

export const deleteProductCampaignAllOffers = async (productCampaignId) => {
  const response = await apiCall({ method: 'delete', url: `/campaigns/${productCampaignId}/offers/all` });
  if (!response) return false;

  dispatch({ type: 'CAMPAIGN_CREATION_PRODUCT_OFFERS_RESET' });
  return true;
};

export const deleteProductCampaignOffer = async (offerCampaignId) => {
  const deleteResponse = await apiCall({ method: 'delete', url: `/offer-campaigns/${offerCampaignId}` });
  if (!deleteResponse) return false;
  return true;
};

export const getGenericTargetingStrategyInProductCampaignOffersCount = async (campaignProduct) => {
  const params = {
    'campaignId.equals': campaignProduct.id,
    'targetingStrategyCode.equals': 'ALL'
  };

  const response = await apiCall({ url: '/offer-campaigns/count', params });
  if (!response) return false;

  dispatch({
    type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE',
    payload: {
      genericTargetingStrategyCampaignOffersCount: response.data
    }
  });
  return response.data;
};

export const getProductCampaignOffers = async ({
  checkHasActiveTargetingStatusCampaign = false,
  campaignProduct,
  excludeUntargetable = false,
  filters = {},
  orderBy = 'desc',
  page = 0,
  size = 30,
  sortBy = 'id'
}) => {
  let budgetIds;
  if (!filters.id?.length && filters.supplierId?.length) {
    const budgetsResponse = await getAllSupplierBudgets({
      retailerId: filters.id,
      supplierIds: filters.supplierId,
      year: `${currentYear},${currentYear + 1}`
    });
    if (!budgetsResponse) return false;

    budgetIds = budgetsResponse.map((b) => b.id);
  }

  const filtersParams = {
    'offerOfferHeadId.in': filters.id,
    'offerTitle.contains': !filters.id ? filters.title : undefined,
    'offerDescriptionTag.containsIn': !filters.id && filters.tagContains?.length ? filters.tagContains : undefined,
    'offerDescriptionTag.notContainsIn':
      !filters.id && filters.tagNotContains?.length ? filters.tagNotContains : undefined,
    [`offerDiscountMinQuantityOfProducts.${filters.discountMinQuantity?.[0]}`]:
      !filters.id && filters.discountMinQuantity?.[1] ? filters.discountMinQuantity[1] : undefined,
    'targetingMarketingCode.in':
      !filters.id && filters.targetingMarketingCode?.length ? filters.targetingMarketingCode : undefined,
    'offerBudgetId.in': budgetIds,
    [`offerOfferHeadBudgetTarget.${filters.budgetTarget?.[0]}`]:
      !filters.id && filters.budgetTarget?.[1] ? filters.budgetTarget[1] : undefined,
    'offerDiscountUnit.equals':
      !filters.id && filters.generosity?.[2]
        ? discountUnits.find((unit) => unit.id === filters.generosity[2])?.denomination
        : undefined,
    [`offerDiscountValue.${filters.generosity?.[0]}`]:
      !filters.id && filters.generosity?.[1] ? filters.generosity[1] : undefined,
    [`offerAveragePrice.${filters.averagePrice?.[0]}`]:
      !filters.id && filters.averagePrice?.[1] ? filters.averagePrice[1] : undefined,
    'offerDiscountType.in': !filters.id && filters.discountType?.length ? filters.discountType : undefined,
    'offerOfferHeadSuperEditable.equals':
      !filters.id && typeof filters.superEditable === 'boolean' ? filters.superEditable : undefined
  };

  const promises = [
    apiCall({
      url: '/offer-campaigns',
      params: {
        'offerFrozen.equals': false,
        'campaignId.equals': campaignProduct.id,
        page,
        size,
        sort: sortBy ? `${sortBy},${orderBy}` : undefined,
        ...filtersParams,
        'targetingStrategyCode.notEquals': excludeUntargetable ? 'UNTARGETABLE' : undefined
      }
    }),
    apiCall({ url: `/campaigns/${campaignProduct.id}` })
  ];
  const [getOffersCampaign, getCampaign] = await Promise.all(promises);
  if (!getOffersCampaign || !getCampaign) return false;

  let activeTargetingStatusCampaignLinkedList = [];
  if (checkHasActiveTargetingStatusCampaign) {
    getOffersCampaign.data.forEach((el) => {
      activeTargetingStatusCampaignLinkedList.push(
        getOfferLinkedToActiveTargetingStatusCampaignCount(el.offer.offerHead.id)
      );
    });
  }
  const getActiveTargetingStatusCampaignLinkedListResponses = await Promise.all(
    activeTargetingStatusCampaignLinkedList
  );

  const payload = {
    id: getCampaign.data.id,
    campaignState: getCampaign.data.campaignState,
    campaignType: campaignTypes.PRODUCT,
    offers: getOffersCampaign.data.map((i, index) => ({
      ...i.offer,
      offerCampaignId: i.id,
      isLinkedToActiveTargetingStatusCampaign:
        getActiveTargetingStatusCampaignLinkedListResponses[index] !== null &&
        getActiveTargetingStatusCampaignLinkedListResponses[index] !== 0
    })),
    offersCount: parseInt(getOffersCampaign.headers['x-total-count'], 10) ?? 0,
    targetingKpisOfferCampaign: getCampaign.data.targetingKpisOfferCampaign,
    title: getCampaign.data.title
  };

  dispatch({
    type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE',
    payload
  });
  return { ...campaignProduct, ...payload };
};

export const getCampaignOffersSuperEditableCount = async (campaignId) => {
  const response = await apiCall({
    url: '/offer-campaigns/count',
    params: {
      'campaignId.equals': campaignId,
      'offerOfferHeadSuperEditable.equals': true
    }
  });
  if (!response) return false;
  return response.data;
};

export const getOfferLinkedToActiveTargetingStatusCampaignCount = async (offerHeadId) => {
  const offerCampaignParams = {
    'offerOfferHeadId.equals': offerHeadId,
    'campaignTargetingStatus.notIn': [campaignTargetingStatuses.NOT_REQUESTED, campaignTargetingStatuses.FAIL_DATA]
  };

  const response = await apiCall({ url: '/offer-campaigns/count', params: offerCampaignParams });
  if (!response) return false;
  return response.data;
};

export const getUntargetableOffers = async ({
  campaignProduct,
  page = 0,
  size = 30,
  filters = { id: [], title: [], tag: [] },
  orderBy = 'desc',
  sortBy = 'id'
}) => {
  const params = {
    'frozen.equals': false,
    'status.equals': 'VALIDATED',
    'validityEndDate.greaterThanOrEqual': moment(campaignProduct.endDate).format(),
    'validityStartDate.lessThanOrEqual': moment(campaignProduct.startDate).format(),
    'hasReservedBudgetOrUnlimitedBudget.equals': 'true',
    'offerHeadDisabled.equals': false,
    'targetingStrategyCode.equals': 'UNTARGETABLE',
    'type.equals': offerTypes.RETAILER_PRODUCT_OFFER,
    page,
    size,
    sort: sortBy ? `${sortBy},${orderBy}` : undefined,
    'offerHeadId.in': filters.id,
    'title.contains': !filters.id?.length ? filters.title : undefined,
    'descriptionTag.containsIn': !filters.id?.length && filters.tagContains?.length ? filters.tagContains : undefined,
    'descriptionTag.notContainsIn':
      !filters.id?.length && filters.tagNotContains?.length ? filters.tagNotContains : undefined
  };

  const response = await apiCall({ url: '/offers', params });
  if (!response) return false;

  dispatch({
    type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE',
    payload: {
      offersUntargetablePreviewCount: parseInt(response.headers['x-total-count'], 10),
      offersUntargetablePreviewList: response.data
    }
  });
  return response.data;
};

export const getUntargetableProductCampaignOffers = async ({
  campaignProduct,
  page = 0,
  size = 30,
  sortBy = 'id',
  orderBy = 'desc'
}) => {
  const params = {
    'campaignId.equals': campaignProduct.id,
    page,
    size,
    sort: sortBy ? `${sortBy},${orderBy}` : undefined,
    'targetingStrategyCode.equals': 'UNTARGETABLE'
  };

  const promises = [
    apiCall({
      url: '/offer-campaigns',
      params
    }),
    apiCall({ url: `/campaigns/${campaignProduct.id}` })
  ];
  let [getOffersCampaign, getCampaign] = await Promise.all(promises);
  if (!getOffersCampaign || !getCampaign) return false;

  const totalCount = parseInt(getOffersCampaign.headers['x-total-count'], 10);

  let allOffersCampaign = [...getOffersCampaign.data];
  // fetch all offer-campaigns list
  if (totalCount > getOffersCampaign.data.length) {
    allOffersCampaign = await apiCall({
      url: '/offer-campaigns',
      params: { ...params, size: totalCount, sort: undefined, page: undefined }
    });
    if (!allOffersCampaign) return false;
    allOffersCampaign = allOffersCampaign.data;
  }

  const payload = {
    targetingKpisOfferCampaign: getCampaign.data.targetingKpisOfferCampaign,
    untargetableProductCampaignOffers: {
      list: getOffersCampaign.data.map((d) => ({ ...d.offer, offerCampaignId: d.id })),
      listAll: allOffersCampaign.map((d) => ({ ...d.offer, offerCampaignId: d.id })),
      total: parseInt(totalCount, 10) ?? 0
    }
  };

  dispatch({
    type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE',
    payload
  });

  return true;
};

export const updateUntargetableProductCampaignOffers = async ({ newIdList, oldIdList, campaignProduct }) => {
  const diffIds = oldIdList.filter((id) => !newIdList.includes(id));

  if (diffIds.length) {
    // get offerCampaignId from listAll
    const deleteIdList = diffIds.map(
      (id) => campaignProduct.untargetableProductCampaignOffers.listAll.find((el) => id === el.id)?.offerCampaignId
    );
    const promises = deleteIdList.map((id) => apiCall({ method: 'delete', url: `/offer-campaigns/${id}` }));
    const deleteResponse = await Promise.all(promises);
    const isOK = deleteResponse.every((res) => res.status < 300);
    if (!isOK) return false;
  }

  if (newIdList.length) {
    const postResponse = await apiCall({
      method: 'post',
      url: `/campaigns/${campaignProduct.id}/offers`,
      params: { 'id.in': newIdList }
    });
    if (!postResponse) return false;
  }
  return true;
};

export const updateProductCampaignOffers = async ({ campaignProduct, filters }) => {
  const {
    id,
    offerType,
    privateLabel,
    supplierBudgetType,
    supplierId,
    tagContains,
    targetingMarketingCode,
    tagNotContains,
    title
  } = filters;

  let budgetIds;
  if (!id?.length && supplierId?.length) {
    const budgetsResponse = await getAllSupplierBudgets({
      retailerId: id,
      supplierIds: supplierId,
      year: currentYear
    });
    if (!budgetsResponse) return false;

    budgetIds = budgetsResponse.map((b) => b.id);
  }

  const usePrivateLabelFilter = offerType?.length !== 1 || offerType[0] !== offerTypes.SUPPLIER_PRODUCT_OFFER;

  const filtersParams = {
    'offerHeadId.in': id,
    'title.contains': !id?.length ? title : undefined,
    'descriptionTag.containsIn': !id?.length && tagContains?.length ? tagContains : undefined,
    'descriptionTag.notContainsIn': !id?.length && tagNotContains?.length ? tagNotContains : undefined,
    'budgetId.in': budgetIds,
    'targetingMarketingCode.in': !id?.length && targetingMarketingCode?.length ? targetingMarketingCode : undefined,
    'type.equals': !id?.length && offerType?.length === 1 ? offerTypes[offerType[0]] : undefined,
    'privateLabel.equals':
      !id?.length && privateLabel?.length === 1 && usePrivateLabelFilter ? privateLabel[0] === 'mdd' : undefined,
    'offerHeadSupplierBudgetType.equals':
      !id?.length && supplierBudgetType?.length === 1 ? supplierBudgetType[0] : undefined
  };

  const postResponse = await apiCall({
    method: 'post',
    url: `/campaigns/${campaignProduct.id}/offers`,
    params: {
      'status.equals': 'VALIDATED',
      'validityEndDate.greaterThanOrEqual': moment(campaignProduct.endDate).utc().format(),
      'validityStartDate.lessThanOrEqual': moment(campaignProduct.startDate).utc().format(),
      'hasReservedBudgetOrUnlimitedBudget.equals': 'true',
      'offerHeadDisabled.equals': false,
      'targetingStrategyCode.notEquals': 'UNTARGETABLE',
      ...filtersParams
    }
  });
  if (!postResponse) return false;

  dispatch({
    type: 'UI_SNACKBAR',
    payload: {
      type: snackbarTypes.SUCCESS,
      title: { key: 'campaign_product_step2_adding_done' }
    }
  });

  return true;
};

export const postProductCampaign = async ({ campaign, userId }) => {
  const data = {
    personalized: campaign.personalized,
    retailer: {
      id: userId
    },
    targetingStatus: campaignTargetingStatuses.NOT_REQUESTED,
    title: campaign.title,
    validityEndDate: campaign.endDate,
    validityStartDate: campaign.startDate // no need for utc
  };
  const postCampaignResponse = await apiCall({ method: 'post', url: '/campaigns', data });
  if (!postCampaignResponse) return false;

  const payload = {
    ...campaign,
    ...postCampaignResponse.data,
    retailer: { id: postCampaignResponse.data.retailer.id },
    startDate: moment(postCampaignResponse.data.validityStartDate),
    endDate: moment(postCampaignResponse.data.validityEndDate)
  };

  dispatch({ type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE', payload });
  return payload;
};

export const postProductCampaignClone = async (originalCampaign) => {
  const cloneOriginalCampaign = JSON.parse(JSON.stringify(originalCampaign));

  const data = {
    paramsOfferCampaign: cloneOriginalCampaign.paramsOfferCampaign,
    personalized: cloneOriginalCampaign.personalized,
    targetingStatus: campaignTargetingStatuses.NOT_REQUESTED,
    retailer: {
      id: cloneOriginalCampaign.retailer.id
    },
    title: cloneOriginalCampaign.title,
    validityEndDate: cloneOriginalCampaign.validityEndDate,
    validityStartDate: moment.max(moment(), moment(cloneOriginalCampaign.validityStartDate))
  };

  const postCampaignResponse = await apiCall({ method: 'post', url: '/campaigns', data: data });
  if (!postCampaignResponse) return false;

  // 1/ get all campaign offers of the original campaign
  const originalCampaignOffersIdListResponse = await apiCall({
    url: `/campaigns/${cloneOriginalCampaign.id}/offer-head-ids/all`
  });
  if (!originalCampaignOffersIdListResponse) return false;

  // 2/ clone current offer-campaigns for new created draft campaign
  let ids = [...originalCampaignOffersIdListResponse.data];
  let promises = [];
  do {
    promises.push(
      apiCall({
        method: 'post',
        url: `/campaigns/${postCampaignResponse.data.id}/offers`,
        params: { 'offerHeadId.in': ids.splice(0, 500) }
      })
    );
  } while (ids.length);

  const cloneCampaignOffersResponses = await Promise.all(promises);
  if (!cloneCampaignOffersResponses.every((res) => !!res)) return false;

  // 3 clone retailer segment
  let responseRetailerSegment;
  if (cloneOriginalCampaign.externalSegment?.id) {
    responseRetailerSegment = await apiCall({
      method: 'post',
      url: '/retailer-segment-campaigns',
      data: {
        campaign: {
          id: postCampaignResponse.data.id
        },
        externalSegment: {
          id: cloneOriginalCampaign.externalSegment.id
        }
      }
    });
    if (!responseRetailerSegment) return false;
  }

  // 4 get new campaign
  const newCampaignResponse = await apiCall({ url: `/campaigns/${postCampaignResponse.data.id}` });

  const payload = {
    ...newCampaignResponse.data,
    retailer: { id: newCampaignResponse.data.retailer.id },
    startDate: moment(newCampaignResponse.data.validityStartDate),
    endDate: moment(newCampaignResponse.data.validityEndDate),
    parameters: {
      ...newCampaignResponse.data.paramsOfferCampaign
    },
    retailerSegment: responseRetailerSegment?.data?.externalSegment
      ? { ...responseRetailerSegment.data.externalSegment }
      : null
  };

  dispatch({ type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE', payload });
  return true;
};

export const updateProductCampaign = async (campaign) => {
  const paramsOfferCampaign =
    campaign.parameters.offersMinimumNumber > 0
      ? {
          ...initialCampaignProductCreationParams,
          ...campaign.parameters,
          ...initialCampaignProductCreationParamsQuotesWithGenericTargetingOffers
        }
      : {
          ...initialCampaignProductCreationParams,
          ...campaign.parameters,
          ...initialCampaignProductCreationParamsQuotesWithoutGenericTargetingOffers
        };

  const data = {
    id: campaign.id,
    title: campaign.title,
    validityStartDate: campaign.startDate.local(),
    validityEndDate: campaign.endDate.local(),
    targetingStatus: campaign.targetingStatus,
    targetingUrl: campaign.targetingUrl,
    retailer: campaign.retailer,
    paramsOfferCampaign,
    personalized: campaign.personalized,
    distributionChannels: campaign.distributionChannels
  };
  const response = await apiCall({ method: 'put', url: '/campaigns', data });
  if (!response) return false;

  const payload = {
    ...campaign,
    ...response.data,
    endDate: moment(response.data.validityEndDate),
    retailer: { id: response.data.retailer.id },
    startDate: moment(response.data.validityStartDate),
    targetingKpisOfferCampaign: { ...campaign.targetingKpisOfferCampaign }
  };

  dispatch({ type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE', payload });

  return payload;
};

export const updateOriginalProductCampaignEdition = async ({ campaign, originalCampaign }) => {
  // 1/ check if original campaign can still be edited
  const originalCampaignResponse = await apiCall({ url: `/campaigns/${originalCampaign.id}` });
  if (!originalCampaignResponse) {
    return { success: false, msg: 'campaign_creation_update_deleted_campaign_error' };
  }

  if (originalCampaignResponse.data.targetingStatus !== campaignTargetingStatuses.NOT_REQUESTED) {
    dispatch({
      type: 'UI_SNACKBAR',
      payload: {
        type: snackbarTypes.ERROR,
        title: { key: 'campaign_creation_update_allocated_campaign_error' }
      }
    });
    return { success: false, msg: 'campaign_creation_update_allocated_campaign_error' };
  }

  // 2/ update original campaign
  const paramsOfferCampaign =
    campaign.parameters.offersMinimumNumber > 0
      ? {
          ...initialCampaignProductCreationParams,
          ...campaign.parameters,
          ...initialCampaignProductCreationParamsQuotesWithGenericTargetingOffers
        }
      : {
          ...initialCampaignProductCreationParams,
          ...campaign.parameters,
          ...initialCampaignProductCreationParamsQuotesWithoutGenericTargetingOffers
        };

  const data = {
    id: originalCampaign.id,
    distributionChannels: campaign.distributionChannels,
    paramsOfferCampaign,
    personalized: campaign.personalized,
    retailer: campaign.retailer,
    status: 'VALIDATED',
    targetingStatus: campaign.targetingStatus,
    targetingUrl: campaign.targetingUrl,
    title: campaign.title,
    validityStartDate: campaign.startDate.local(),
    validityEndDate: campaign.endDate.local()
  };

  const response = await apiCall({ method: 'put', url: '/campaigns', data });
  if (!response.data) return false;

  // 3/ update originalCampaign retailerSegment
  if (originalCampaign.externalSegment?.id !== campaign.retailerSegment?.id) {
    if (originalCampaign.externalSegment?.id) {
      const deleteRetailerSegmentResponse = await apiCall({
        method: 'delete',
        url: `/retailer-segment-campaigns/${originalCampaign.externalSegment.retailerSegmentId}`
      });
      if (!deleteRetailerSegmentResponse) return false;
    }

    if (campaign.retailerSegment?.id) {
      const responseRetailerSegment = await apiCall({
        method: 'post',
        url: '/retailer-segment-campaigns',
        data: {
          campaign: {
            id: originalCampaign.id
          },
          externalSegment: {
            id: campaign.retailerSegment.id
          }
        }
      });
      if (!responseRetailerSegment) return false;
    }
  }

  // 4/ check offers list
  // get all offers ids from current draft campaign and original campaign
  const promises = [
    apiCall({
      url: `/campaigns/${campaign.id}/offer-ids/all`
    }),
    apiCall({
      url: `/campaigns/${originalCampaign.id}/offer-ids/all`
    })
  ];
  const [draftCampaignOffersIdListResponse, originalCampaignOffersIdListResponse] = await Promise.all(promises);
  if (!draftCampaignOffersIdListResponse || !originalCampaignOffersIdListResponse) return false;

  // create diffIdsToDelete and diffIdsToAdd lists
  let diffIdsToDelete = [];
  let diffIdsToAdd = [];

  if (
    draftCampaignOffersIdListResponse.data.sort().join(',') !==
    originalCampaignOffersIdListResponse.data.sort().join(',')
  ) {
    // diff missing offers list
    originalCampaignOffersIdListResponse.data.forEach((id) => {
      if (!draftCampaignOffersIdListResponse.data.includes(id)) {
        diffIdsToDelete.push(id);
      }
    });
    // diff new offers list
    draftCampaignOffersIdListResponse.data.forEach((id) => {
      if (!originalCampaignOffersIdListResponse.data.includes(id)) {
        diffIdsToAdd.push(id);
      }
    });
  }

  // delete missing offers list
  if (diffIdsToDelete.length) {
    const deleteCampaignOffersResponse = await apiCall({
      method: 'delete',
      url: `/campaigns/${originalCampaign.id}/unlink/offers`,
      data: diffIdsToDelete
    });
    if (!deleteCampaignOffersResponse) return false;
  }

  // add new offers list
  if (diffIdsToAdd.length) {
    const addCampaignOffersResponse = await apiCall({
      method: 'post',
      url: `/campaigns/${originalCampaign.id}/link/offers`,
      data: diffIdsToAdd
    });
    if (!addCampaignOffersResponse) return false;
  }

  // 5/ check if visualFile status should be changed
  const visualFile = await getCampaignVisualFile(originalCampaign.id);

  if (visualFile) {
    await apiCall({
      method: 'put',
      url: '/campaigns/files',
      data: {
        ...visualFile,
        campaign: { id: originalCampaign.id },
        status: visualFileStatuses.MODIFY_TO_REGENERATE
      }
    });
  }

  dispatch({
    type: 'UI_SNACKBAR',
    payload: {
      type: snackbarTypes.SUCCESS,
      title: { key: 'campaign_edition_snackbar_message_ok' }
    }
  });

  return true;
};

export const updateProductCampaignCreation = async (campaign) => {
  const paramsOfferCampaign =
    campaign.parameters.offersMinimumNumber > 0
      ? {
          ...initialCampaignProductCreationParams,
          ...campaign.parameters,
          ...initialCampaignProductCreationParamsQuotesWithGenericTargetingOffers
        }
      : {
          ...initialCampaignProductCreationParams,
          ...campaign.parameters,
          ...initialCampaignProductCreationParamsQuotesWithoutGenericTargetingOffers
        };

  const data = {
    id: campaign.id,
    distributionChannels: campaign.distributionChannels,
    title: campaign.title,
    status: 'VALIDATED',
    validityStartDate: campaign.startDate.local(),
    validityEndDate: campaign.endDate.local(),
    targetingStatus: campaign.targetingStatus,
    targetingUrl: campaign.targetingUrl,
    retailer: campaign.retailer,
    paramsOfferCampaign,
    personalized: campaign.personalized
  };
  const response = await apiCall({ method: 'put', url: '/campaigns', data });
  if (!response.data) return false;

  if (campaign?.retailerSegment) {
    const responseRetailerSegment = await apiCall({
      method: 'post',
      url: '/retailer-segment-campaigns',
      data: {
        campaign: {
          id: campaign.id
        },
        externalSegment: {
          id: campaign.retailerSegment.id
        }
      }
    });
    if (!responseRetailerSegment) return false;
  }

  return true;
};

export const notifyProductCampaignSuppliers = async ({ campaign }) => {
  const response = await apiCall({ method: 'post', url: `/campaigns/${campaign.id}/notify-suppliers` });
  if (!response) return false;

  return true;
};

/*** CashCoupons ***/

export const deleteCashCoupon = async (cashCouponId) => {
  const response = await apiCall({ method: 'delete', url: `/cash-coupons/${cashCouponId}` });
  if (!response) return false;

  dispatch({
    type: 'UI_SNACKBAR',
    payload: {
      type: snackbarTypes.SUCCESS,
      title: { key: 'offer_details_deleted', params: { offerId: cashCouponId } }
    }
  });
  return true;
};

/* */
/* @retailerId required */
/* Use @page for fetching paginated list */
/* Without @page for fetching all cash coupons list */
/* */
export const getCashCoupons = async ({ retailerId, page, orderBy = 'desc', sortBy = 'id', size, filters = {} }) => {
  const hasIdFilter =
    (filters.id && !Array.isArray(filters.id)) || (filters.id && Array.isArray(filters.id) && filters.id?.length);

  const filtersParams = {
    'id.in': filters.id,
    'title.contains': !hasIdFilter ? filters.title : undefined,
    'discountType.in': !hasIdFilter && filters.discountType?.length ? filters.discountType : undefined,
    [`validityStartDate.${filters.validityStartDate?.[0]}`]:
      !hasIdFilter && filters.validityStartDate?.[1]
        ? moment(filters.validityStartDate[1]).startOf('day').utc().format()
        : undefined,
    [`validityEndDate.${filters.validityEndDate?.[0]}`]:
      !hasIdFilter && filters.validityEndDate?.[1]
        ? moment(filters.validityEndDate[1]).endOf('day').utc().format()
        : undefined
  };

  const params = {
    'retailerId.equals': retailerId,
    'status.equals': 'VALIDATED',
    page,
    size,
    sort: sortBy ? `${sortBy},${orderBy}` : undefined,
    ...filtersParams
  };

  let response = await apiCall({ url: '/cash-coupons', params });
  if (!response) return false;

  const totalCount = response.headers['x-total-count'];

  // fetch all cash coupons list
  if (page === undefined && totalCount > response.data.length) {
    response = await apiCall({
      url: '/cash-coupons',
      params: {
        ...params,
        size: totalCount
      }
    });
    if (!response) return false;
  }

  const payload = Object.fromEntries(
    [
      ['list', page !== undefined ? response.data : undefined],
      ['listAll', page !== undefined ? undefined : response.data.map((i) => ({ ...i, label: `${i.title} | ${i.id}` }))],
      ['page', page ?? 0],
      ['total', page !== undefined ? parseInt(totalCount, 10) ?? 0 : undefined]
    ].filter((el) => el[1] !== undefined) // remove undefined entries
  );

  dispatch({
    type: 'CASH_COUPONS_UPDATE',
    payload
  });
  return response.data;
};

export const getCashCoupon = async (cashCouponId) => {
  const promises = [
    apiCall({ url: `/cash-coupons/${cashCouponId}` }),
    apiCall({
      url: '/cash-coupon-campaigns',
      params: { 'cashCouponId.equals': cashCouponId, sort: 'campaignValidityEndDate,desc' }
    })
  ];

  let [cashcouponDetailsResponse, offerCampaignsResponse] = await Promise.all(promises);
  if (!cashcouponDetailsResponse || !offerCampaignsResponse) return false;

  const offerCampaignCount = offerCampaignsResponse.headers['x-total-count'];
  if (offerCampaignCount > 20) {
    offerCampaignsResponse = await apiCall({
      url: '/cash-coupon-campaigns',
      params: {
        'cashCouponId.equals': cashCouponId,
        size: offerCampaignCount
      }
    });
    if (!offerCampaignsResponse) return false;
  }

  dispatch({
    type: 'CASH_COUPONS_UPDATE',
    payload: { details: { cashCoupon: cashcouponDetailsResponse.data, offerCampaigns: offerCampaignsResponse.data } }
  });
  return true;
};

export const getCashCouponsCampaigns = async ({
  campaignId,
  cashCouponId,
  page = 0,
  sortBy = 'id',
  orderBy = 'desc',
  size = 30
}) => {
  const response = await apiCall({
    url: '/cash-coupon-campaigns',
    params: {
      'campaignId.equals': campaignId,
      'cashCouponId.equals': cashCouponId,
      page,
      size,
      sort: `${sortBy},${orderBy}`
    }
  });
  if (!response) return false;
  return response.data;
};

export const postCashCoupon = async ({ userId, cashCoupon, discountUnits }) => {
  const data = {
    title: cashCoupon.title,
    validityStartDate: cashCoupon.validityDates?.startDate?.format(),
    validityEndDate: cashCoupon.validityDates?.endDate?.format(),
    status: 'VALIDATED',
    discountUnit: discountUnits.filter((i) => i.id === cashCoupon.unit)[0].denomination,
    discountType: discountTypes.filter((i) => i.id === cashCoupon.discountType)[0].denomination,
    discountValue: cashCoupon.generosity,
    discountMaximumUses: cashCoupon.numberMax,
    discountBasketThreshold: cashCoupon.basketThreshold,
    discountCurrency: null,
    retailer: {
      id: userId
    }
  };

  const response = await apiCall({ method: 'post', url: '/cash-coupons', data });
  if (!response) return false;

  return true;
};

export const updateCashCoupon = async ({ userId, cashCoupon, discountUnits }) => {
  const data = {
    id: cashCoupon.id,
    title: cashCoupon.title,
    validityStartDate: cashCoupon.validityDates?.startDate?.format(),
    validityEndDate: cashCoupon.validityDates?.endDate?.format(),
    status: 'VALIDATED',
    discountUnit: discountUnits.filter((i) => i.id === cashCoupon.unit)[0].denomination,
    discountType: discountTypes.filter((i) => i.id === cashCoupon.discountType)[0].denomination,
    discountValue: cashCoupon.generosity,
    discountMaximumUses: cashCoupon.numberMax,
    discountBasketThreshold: cashCoupon.basketThreshold,
    discountCurrency: null,
    retailer: {
      id: userId
    }
  };

  const response = await apiCall({ method: 'put', url: '/cash-coupons', data });
  if (!response) return false;

  return true;
};

/*** Dashboard Retailer ***/

export const getAllAvailableBudgetYearsCashCouponByRetailerId = async (retailerId) => {
  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/cash-coupons/available-budget-years`
  });
  if (!response) return false;

  return response.data;
};

export const getAllAvailableBudgetYearsRetailerOfferByRetailerId = async (retailerId) => {
  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/retailers-offers/available-budget-years`
  });
  if (!response) return false;

  return response.data;
};

export const getAllAvailableBudgetYearsSupplierOfferByRetailerId = async (retailerId) => {
  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/suppliers-offers/available-budget-years`
  });
  if (!response) return false;

  return response.data;
};

export const getBudgetsAreaCashCoupon = async ({ budgetYear, retailerId }) => {
  if (!checkYearFormat(budgetYear)) {
    return null;
  }

  const promises = [
    apiCall({
      url: `/homepage/retailer/${retailerId}/cash-coupons/render-budgets-area/${budgetYear}`,
      statusCodesToSkip: [400]
    }),
    apiCall({
      url: `/homepage/retailer/${retailerId}/kpis/${budgetYear}`,
      statusCodesToSkip: [404]
    })
  ];
  let [budgetKpisResponse, singleTargetCustomerKpisResponse] = await Promise.all(promises);
  if (!budgetKpisResponse || !singleTargetCustomerKpisResponse) return false;

  budgetKpisResponse.data.keyFigure = {
    ...budgetKpisResponse.data.keyFigure,
    singleTargetCustomer: singleTargetCustomerKpisResponse.data.kpis?.singleTargetCustomerBR
  };

  return budgetKpisResponse.data;
};

export const getBudgetsAreaRetailerOffer = async ({ budgetYear, retailerId }) => {
  if (!checkYearFormat(budgetYear)) {
    return null;
  }

  const promises = [
    apiCall({
      url: `/homepage/retailer/${retailerId}/retailers-offers/render-budgets-area/${budgetYear}`,
      statusCodesToSkip: [400]
    }),
    apiCall({
      url: `/homepage/retailer/${retailerId}/kpis/${budgetYear}`,
      statusCodesToSkip: [404]
    })
  ];
  let [budgetKpisResponse, singleTargetCustomerKpisResponse] = await Promise.all(promises);
  if (!budgetKpisResponse || !singleTargetCustomerKpisResponse) return false;

  budgetKpisResponse.data.keyFigure = {
    ...budgetKpisResponse.data.keyFigure,
    singleTargetCustomer: singleTargetCustomerKpisResponse.data.kpis?.singleTargetCustomerOPD
  };

  return budgetKpisResponse.data;
};

export const getBudgetsAreaSupplierOffer = async ({ budgetYear, retailerId }) => {
  if (!checkYearFormat(budgetYear)) {
    return null;
  }

  const promises = [
    apiCall({
      url: `/homepage/retailer/${retailerId}/suppliers-offers/render-budgets-area/${budgetYear}`,
      statusCodesToSkip: [400]
    }),
    apiCall({
      url: `/homepage/retailer/${retailerId}/kpis/${budgetYear}`,
      statusCodesToSkip: [404]
    })
  ];
  let [budgetKpisResponse, singleTargetCustomerKpisResponse] = await Promise.all(promises);
  if (!budgetKpisResponse || !singleTargetCustomerKpisResponse) return false;

  budgetKpisResponse.data.keyFigure = {
    ...budgetKpisResponse.data.keyFigure,
    singleTargetCustomer: singleTargetCustomerKpisResponse.data.kpis?.singleTargetCustomerOPF
  };

  return budgetKpisResponse.data;
};

export const getBudgetSpentByPeriod = async ({ end, granularity, offerType, start, year }) => {
  const response = await apiCall({
    baseUrl: apiUrlCouponingEngine,
    url: '/periodic_spent_budget',
    params: {
      end,
      granularity,
      offerType,
      start,
      year
    }
  });

  if (!response) return false;

  return response.data?.data;
};

export const getCampaignsToAllocateCount = async ({ createdAt, retailerId }) => {
  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/campaigns/count-by-criteria`,
    params: {
      'campaignState.in': [campaignStates.TO_COME, campaignStates.IN_PROGRESS],
      'createdAt.greaterThanOrEqual': createdAt?.utc()?.format(),
      'status.equals': campaignStatuses.VALIDATED,
      'targetingStatus.equals': campaignTargetingStatuses.NOT_REQUESTED,
      'validityStartDate.greaterThanOrEqual': moment().startOf('day').utc().format()
    }
  });
  if (!response) return false;

  return response.data;
};

export const getDashboardNotifications = async ({ code, createdAt, type }) => {
  const response = await apiCall({
    url: '/notification',
    params: {
      'code.equals': code,
      'createdAt.greaterThanOrEqual': createdAt.startOf('day').utc().format(),
      'type.equals': type
    }
  });
  if (!response) return false;

  return response.data;
};

export const getDashboardNotificationsCount = async ({ code, createdAt, type }) => {
  const response = await apiCall({
    url: '/notification/count',
    params: {
      'code.equals': code,
      'createdAt.greaterThanOrEqual': createdAt.startOf('day').utc().format(),
      'type.equals': type
    }
  });
  if (!response) return false;

  return response.data;
};

export const getOffersWithBudgetToCheck = async (retailerId) => {
  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/suppliers-offers/offers/find-by-criteria`,
    params: {
      page: 0,
      size: 6,
      'budgetSpent.greaterThan': 0,
      'frozen.equals': false,
      'offerHeadDisabled.equals': false,
      sort: 'offerHead.percentageBudgetRemaining,desc',
      'type.equals': offerTypes.SUPPLIER_PRODUCT_OFFER,
      'validityEndDate.greaterThanOrEqual': moment().endOf('day').utc().format(),
      'validityStartDate.greaterThanOrEqual': moment().startOf('year').utc().format()
    }
  });
  if (!response) return false;

  return response.data;
};

export const getSupplierOffersToValidateCount = async ({ createdAt, retailerId }) => {
  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/suppliers-offers/offers/count-by-criteria`,
    params: {
      'createdAt.greaterThanOrEqual': createdAt?.utc()?.format(),
      'frozen.equals': false,
      'status.equals': offerStatuses.TO_VALIDATE,
      'type.equals': offerTypes.SUPPLIER_PRODUCT_OFFER,
      'validityEndDate.greaterThanOrEqual': moment().endOf('day').utc().format(),
      'validityStartDate.greaterThanOrEqual': moment().startOf('year').utc().format()
    }
  });
  if (!response) return false;

  return response.data;
};

export const getSuppliersWithBudgetToAllocate = async (retailerId) => {
  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/suppliers-offers/supplier-budgets/find-by-criteria`,
    params: {
      page: 0,
      size: 6,
      sort: ['percentageUnpositioned', 'desc'],
      'year.equals': currentYear
    }
  });
  if (!response) return false;

  return response.data;
};

export const getDashboardRetailerBudgetInfo = async ({
  budgetIds = [],
  retailerId,
  userManagerIds = [],
  universes = [],
  year
}) => {
  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/budget-info/${year}`,
    params: {
      budgetIds,
      universes: !budgetIds.length ? universes : undefined,
      userManagerIds: !budgetIds.length ? userManagerIds : undefined
    }
  });
  if (!response) return false;

  return response.data;
};

export const getDashboardRetailerBudgetUsage = async ({
  budgetIds = [],
  retailerId,
  userManagerIds = [],
  universes = [],
  year
}) => {
  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/budget-usage/${year}`,
    params: {
      budgetIds,
      universes: !budgetIds.length ? universes : undefined,
      userManagerIds: !budgetIds.length ? userManagerIds : undefined
    }
  });
  if (!response) return false;

  return response.data;
};

export const getDashboardRetailerOfferInfo = async ({
  budgetIds = [],
  retailerId,
  userManagerIds = [],
  universes = [],
  year
}) => {
  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/offer-info/${year}`,
    params: {
      budgetIds,
      universes: !budgetIds.length ? universes : undefined,
      userManagerIds: !budgetIds.length ? userManagerIds : undefined
    }
  });
  if (!response) return false;

  return response.data;
};

/*** Homepage / Supplier ***/

export const getDashboardSupplierBudgetInfo = async ({ budgetIds = [], year }) => {
  const response = await apiCall({
    url: `/homepage/supplier/budget-info/${year}`,
    params: { budgetIds }
  });
  if (!response) return false;

  return response.data;
};

export const getDashboardSupplierBudgetUsage = async ({ budgetIds = [], year }) => {
  const response = await apiCall({
    url: `/homepage/supplier/budget-usage/${year}`,
    params: { budgetIds }
  });
  if (!response) return false;

  return response.data;
};

export const getDashboardSupplierOfferInfo = async ({ budgetIds = [], year }) => {
  const response = await apiCall({
    url: `/homepage/supplier/offer-info/${year}`,
    params: { budgetIds }
  });
  if (!response) return false;

  return response.data;
};

/*** Pilotage / Retailer ***/

export const getMonitoredSupplierBudgetsAndOffersCount = async ({
  retailerId,
  sortBy = 'percentageUnpositioned',
  orderBy = 'desc',
  size = 30,
  page = 0,
  filters = {},
  year = currentYear
}) => {
  const filtersParams = {
    'retailerId.equals': retailerId,
    'supplierId.in': filters.supplierId,
    'supplierUserManagerId.in':
      !filters.supplierId?.length && !!filters.userManagerId?.length ? filters.userManagerId : undefined,
    'year.equals': year
  };
  let response = await apiCall({
    url: '/supplier-budgets',
    params: { ...filtersParams, page, size, sort: `${sortBy},${orderBy}` }
  });

  if (!response) return false;

  const total = Number(response.headers['x-total-count']);

  const promises = response.data.map((budget) =>
    apiCall({
      url: '/offers/count',
      params: { 'budgetId.equals': budget.id }
    })
  );
  const offerResponses = await Promise.all(promises);
  if (!offerResponses.every((res) => !!res)) return false;

  // fix negative free budget
  // add offersCount property
  const list = response.data.map((budget, index) => ({
    ...budget,
    free: Math.max(0, budget.free),
    offersCount: offerResponses[index].data
  }));

  dispatch({ type: 'MONITORING_RETAILER_SUPPLIER_BUDGETS_LIST', payload: { list, page, total } });
  return true;
};

export const getMonitoredOffersToDisableCount = async ({ filters, retailerId, year = currentYear }) => {
  const budgetIds = filters.supplierId?.length
    ? (
        await getAllSupplierBudgets({
          retailerId,
          supplierIds: filters.supplierId,
          year
        })
      )?.map((b) => b.id)
    : undefined;

  const params = {
    'frozen.equals': false,
    'offerHeadPercentageBudgetRemaining.greaterThanOrEqual': 90.0,
    'status.equals': offerStatuses.VALIDATED,
    'type.equals': offerTypes.SUPPLIER_PRODUCT_OFFER,
    'retailerId.equals': retailerId,
    'budgetId.in': budgetIds,
    'supplierUserManagerId.in':
      !filters.supplierId?.length && !!filters.userManagerId?.length ? filters.userManagerId : undefined
  };

  const response = await apiCall({ url: '/offers/count', params });
  if (!response) return false;

  return response.data;
};

export const getMonitoredOffersToDisable = async ({
  retailerId,
  sortBy = 'offerHead.percentageBudgetRemaining',
  orderBy = 'desc',
  size = 30,
  page = 0,
  filters = {},
  year = currentYear
}) => {
  const budgetIds = filters.supplierId?.length
    ? (
        await getAllSupplierBudgets({
          retailerId,
          supplierIds: filters.supplierId,
          year
        })
      )?.map((b) => b.id)
    : undefined;

  const filtersParams = {
    'frozen.equals': false,
    'offerHeadPercentageBudgetRemaining.greaterThanOrEqual': 90.0,
    'retailerId.equals': retailerId,
    'status.equals': offerStatuses.VALIDATED,
    'budgetId.in': budgetIds,
    'supplierUserManagerId.in':
      !filters.supplierId?.length && !!filters.userManagerId?.length ? filters.userManagerId : undefined,
    'type.equals': offerTypes.SUPPLIER_PRODUCT_OFFER
  };

  const params = {
    page,
    size,
    sort: `${sortBy},${orderBy}`,
    ...filtersParams
  };

  const response = await apiCall({ url: '/offers', params });
  if (!response) return false;

  const total = Number(response.headers['x-total-count']);

  const disableStatusResponses = await Promise.all(
    response.data.map((offer) => getOfferDisableStatus(offer.offerHead.id))
  );

  const list = response.data.map((offer, index) => ({
    ...offer,
    disableStatus: disableStatusResponses[index]
  }));

  dispatch({ type: 'MONITORING_RETAILER_OFFERS_TO_DISABLE_LIST', payload: { list, page, total } });
  return true;
};

export const getMonitoredOffersToValidateCount = async ({ filters, retailerId, year = currentYear }) => {
  const budgetIds = filters.supplierId?.length
    ? (
        await getAllSupplierBudgets({
          retailerId,
          supplierIds: filters.supplierId,
          year
        })
      )?.map((b) => b.id)
    : undefined;

  const params = {
    'budgetId.in': budgetIds,
    'frozen.equals': false,
    'retailerId.equals': retailerId,
    'status.in': [offerStatuses.PROPOSAL, offerStatuses.TO_VALIDATE],
    'supplierUserManagerId.in':
      !filters.supplierId?.length && !!filters.userManagerId?.length ? filters.userManagerId : undefined,
    'type.equals': offerTypes.SUPPLIER_PRODUCT_OFFER
  };

  const response = await apiCall({ url: '/offers/count', params });
  if (!response) return false;

  return response.data;
};

export const getMonitoredOffersToValidate = async ({
  retailerId,
  sortBy = 'offerHead.percentageBudgetRemaining',
  orderBy = 'desc',
  size = 30,
  page = 0,
  filters = {},
  year = currentYear
}) => {
  const budgetIds = filters.supplierId?.length
    ? (
        await getAllSupplierBudgets({
          retailerId,
          supplierIds: filters.supplierId,
          year
        })
      )?.map((b) => b.id)
    : undefined;

  const filtersParams = {
    'budgetId.in': budgetIds,
    'frozen.equals': false,
    'retailerId.equals': retailerId,
    'status.in': [offerStatuses.PROPOSAL, offerStatuses.TO_VALIDATE],
    'supplierUserManagerId.in':
      !filters.supplierId?.length && !!filters.userManagerId?.length ? filters.userManagerId : undefined,
    'type.equals': offerTypes.SUPPLIER_PRODUCT_OFFER
  };

  const params = {
    page,
    size,
    sort: `${sortBy},${orderBy}`,
    ...filtersParams
  };

  const response = await apiCall({ url: '/offers', params });
  if (!response) return false;

  let list = response.data;
  const total = Number(response.headers['x-total-count']);

  // get periods count per offer
  const periodsCount = !!list.length && (await getOfferPeriodsList(list.map((el) => el.offerHead.id)));

  if (periodsCount?.length) {
    const regroupedByOfferHeadId = periodsCount.reduce((acc, period) => {
      if (acc[period.offerHead.id]) {
        acc[period.offerHead.id]++;
      } else {
        acc[period.offerHead.id] = 1;
      }
      return acc;
    }, {});

    list = list.map((el) => {
      if (regroupedByOfferHeadId[el.offerHead.id]) {
        el = { ...el, periodsCount: regroupedByOfferHeadId[el.offerHead.id] };
      }
      return el;
    });
  }

  dispatch({ type: 'MONITORING_RETAILER_OFFERS_TO_VALIDATE_LIST', payload: { list, page, total } });
  return true;
};

export const getDashboardRetailerOffersArea = async ({ budgetIds, filters = {}, retailerId, useCompare = false }) => {
  const filtersParams = {
    'budgetId.in': budgetIds,
    'status.in': filters.status,
    'supplierBudgetYear.in': filters.budgetYears,
    'supplierUniverse.in': filters.universe,
    'targetingMarketingCode.in': filters.targetingMarketingCode,
    [`averagePrice.${filters.averagePrice?.[0]}`]: filters.averagePrice?.[1],
    [`budgetTarget.${filters.budgetTarget?.[0]}`]: filters.budgetTarget?.[1],
    [`discountMinQuantityOfProducts.${filters.discountMinQuantity?.[0]}`]: filters.discountMinQuantity?.[1],
    [`generosityPercentage.${filters.generosityPercentage?.[0]}`]: filters.generosityPercentage?.[1]
  };

  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/offer/render-offers-area`,
    params: { compare: useCompare, ...filtersParams }
  });
  if (!response) return false;

  return response.data;
};

export const getDashboardRetailerOffersCharts = async ({ budgetIds, filters = {}, retailerId }) => {
  const params = {
    'budgetId.in': budgetIds,
    'status.in': filters.status,
    'supplierBudgetYear.in': filters.budgetYears,
    'supplierUniverse.in': filters.universe,
    'targetingMarketingCode.in': filters.targetingMarketingCode,
    [`averagePrice.${filters.averagePrice?.[0]}`]: filters.averagePrice?.[1],
    [`budgetTarget.${filters.budgetTarget?.[0]}`]: filters.budgetTarget?.[1],
    [`discountMinQuantityOfProducts.${filters.discountMinQuantity?.[0]}`]: filters.discountMinQuantity?.[1],
    [`generosityPercentage.${filters.generosityPercentage?.[0]}`]: filters.generosityPercentage?.[1]
  };

  const response = await apiCall({
    url: `/homepage/retailer/${retailerId}/offer/render-offers-charts`,
    params
  });
  if (!response) return false;

  return response.data;
};

/*** Offer-Periods ***/

const addOfferPeriods = async ({ offerHeadId, periodsList }) => {
  if (!periodsList?.length) return true;

  const addPeriod = async (period) => {
    const maxRetryCounter = 5; // retry up to 5 times
    let retryCounter = 1;
    let isSuccess;

    const action = async () => {
      isSuccess = await apiCall({
        method: 'post',
        url: '/offer-periods/add',
        data: {
          offerHead: {
            id: offerHeadId
          },
          validityStartDate: period.startDate,
          validityEndDate: period.endDate
        }
      });

      if (isSuccess) {
        return true;
      } else {
        if (retryCounter >= maxRetryCounter) {
          return false;
        }

        retryCounter++;
        await action(period);
      }
    };

    return await action(period);
  };

  const promises = periodsList.map((period) => addPeriod(period));
  const responses = await Promise.all(promises);
  return responses.every((success) => success);
};

const deleteOfferPeriods = async (periodsList) => {
  if (!periodsList?.length) return true;

  const deletePeriod = async (period) => {
    const maxRetryCounter = 5; // retry up to 5 times
    let retryCounter = 1;
    let isSuccess;

    const action = async () => {
      isSuccess = await apiCall({ method: 'delete', url: `/offer-periods/${period.id}` });

      if (isSuccess) {
        return true;
      } else {
        if (retryCounter >= maxRetryCounter) {
          return false;
        }

        retryCounter++;
        await action(period);
      }
    };

    return await action(period);
  };

  const promises = periodsList.map((period) => deletePeriod(period));
  const responses = await Promise.all(promises);
  return responses.every((success) => success);
};

const findAllActiveCampaignIds = async (offerPeriodId) => {
  const response = await apiCall({
    url: `/offer-periods/${offerPeriodId}/active-campaign-ids`
  });
  if (!response) return false;
  return response.data;
};

export const getOfferPeriods = async (offerHeadId) => {
  const offerPeriodsResponse = await apiCall({
    url: '/offer-periods',
    params: { 'offerHeadId.equals': offerHeadId, sort: ['validityStartDate', 'asc'] }
  });
  if (!offerPeriodsResponse) return false;
  return offerPeriodsResponse.data;
};

export const getOfferPeriodsList = async (offerHeadIdList) => {
  let offerPeriodsResponse = await apiCall({
    url: '/offer-periods',
    params: { 'offerHeadId.in': offerHeadIdList, sort: ['validityStartDate', 'asc'] }
  });
  const size = offerPeriodsResponse.headers['x-total-count'];
  if (!offerPeriodsResponse) return false;

  if (size > offerPeriodsResponse.data.length) {
    offerPeriodsResponse = await apiCall({
      url: '/offer-periods',
      params: { 'offerHeadId.in': offerHeadIdList, sort: ['validityStartDate', 'asc'], size }
    });
    if (!offerPeriodsResponse) return false;
  }

  return offerPeriodsResponse.data;
};

const postOfferPeriods = async ({ offerHeadId, validityDatesList }) => {
  const maxRetryCounter = 5; // retry up to 5 times
  let retryCounter = 1;

  const addValidityDates = async () => {
    const isSuccess = await apiCall({
      method: 'post',
      url: '/offer-periods/bulk',
      data: validityDatesList.map((d) => ({
        offerHead: {
          id: offerHeadId
        },
        validityStartDate: d.startDate,
        validityEndDate: d.endDate
      }))
    });
    if (!isSuccess) {
      if (retryCounter > maxRetryCounter) {
        return false;
      }
      retryCounter++;
      await addValidityDates();
    } else {
      return true;
    }
  };

  if (validityDatesList?.length) {
    const isSuccess = await addValidityDates();
    if (!isSuccess) return false;
  }
  return true;
};

const updateOfferPeriods = async ({ offerHeadId, periodsList }) => {
  if (!periodsList?.length) return true;

  const updatePeriod = async (period) => {
    const maxRetryCounter = 5; // retry up to 5 times
    let retryCounter = 1;
    let isSuccess;

    const action = async () => {
      isSuccess = await apiCall({
        method: 'put',
        url: '/offer-periods',
        data: {
          id: period.id,
          offerHead: {
            id: offerHeadId
          },
          validityEndDate: period.endDate,
          validityStartDate: period.startDate
        }
      });

      if (isSuccess) {
        return true;
      } else {
        if (retryCounter >= maxRetryCounter) {
          return false;
        }

        retryCounter++;
        await action(period);
      }
    };

    return await action(period);
  };

  const promises = periodsList.map((period) => updatePeriod(period));
  const responses = await Promise.all(promises);
  return responses.every((success) => success);
};

/*** Offers ***/

export const deleteOffer = async ({ offerId, offerHeadId }) => {
  const response = await apiCall({ method: 'delete', url: `/offers/${offerId}` });
  if (!response) return false;

  dispatch({
    type: 'UI_SNACKBAR',
    payload: { type: snackbarTypes.SUCCESS, title: { key: 'offer_details_deleted', params: { offerId: offerHeadId } } }
  });
  return true;
};

export const disableOffer = async (offerHeadId) => {
  const response = await apiCall({ method: 'put', url: `/offers/offer-heads/${offerHeadId}/disable` });
  if (!response) return false;

  return true;
};

export const notifyRetailers = async (offerHeadId) => {
  const response = await apiCall({ method: 'post', url: `/offer-head/${offerHeadId}/notify-retailers` });
  if (!response) return false;

  return true;
};

export const getLastOffersImport = async (user) => {
  const importList = await apiCall({
    url: '/import-descriptors',
    params: {
      [`${user.userType === 'supplier' ? 'supplierId' : 'retailerId'}.equals`]: user.id,
      sort: 'requestDate,desc',
      size: 10,
      'type.equals': offerTypes.RETAILER_PRODUCT_OFFER,
      page: 0
    }
  });
  if (!importList) return false;

  const fetchSuccessStatus = async (item) => {
    if (item.status === 'SUCCESS') {
      const failedImageCount = await apiCall({
        url: `offers/count?importDescriptorId.equals=${item.id}&fileDescriptorId.specified=false`
      });
      const offerCount = await apiCall({
        url: `offers/count?importDescriptorId.equals=${item.id}&fileDescriptorId.specified=true`
      });

      return {
        offerCount: offerCount.data || 0,
        failedImageCount: failedImageCount.data || 0,
        ...item
      };
    }
    return { ...item, errors: [...item.importErrors] };
  };

  dispatch({
    type: 'OFFERS_IMPORT_UPDATE',
    payload: {
      list: await Promise.all(importList.data.map((item) => fetchSuccessStatus(item)))
    }
  });
  return true;
};

export const getLastOffersImportById = async ({ importDescriptorId, page = 0, size, getOffersWithPicture = false }) => {
  if (!importDescriptorId) return;

  const offersResponse = await apiCall({
    url: `offers?importDescriptorId.equals=${importDescriptorId}&page=${page}&size=${size}&fileDescriptorId.specified=${getOffersWithPicture}`
  });
  if (!offersResponse) return;

  const offersData = offersResponse.data;
  const offerListWithImage = [];
  const offerListWithoutImage = [];

  for (const index in offersData) {
    const item = offersData[index];
    const campaignCount = await apiCall({
      url: `offer-campaigns/count?offerId.equals=${item.id}`
    });

    if (item.offerHead.fileDescriptor) {
      offerListWithImage.push({ campaignCount: campaignCount.data, ...item });
    } else {
      offerListWithoutImage.push({ campaignCount: campaignCount.data, ...item });
    }
  }

  dispatch({
    type: 'OFFERS_IMPORT_DETAILS_UPDATE',
    payload: {
      listWithImage: [...offerListWithImage],
      listWithoutImage: [...offerListWithoutImage]
    }
  });

  return true;
};

export const getOffer = async (offerHeadId) => {
  const offerDetailsResponse = await apiCall({
    url: `/offers`,
    params: { 'frozen.equals': false, 'offerHeadId.equals': offerHeadId }
  });
  if (!offerDetailsResponse || !offerDetailsResponse.data?.[0]?.id) return false;

  const offer = offerDetailsResponse.data[0];
  const offerCampaignParams = {
    'offerOfferHeadId.equals': offerHeadId,
    'campaignStatus.equals': campaignStatuses.VALIDATED,
    sort: ['campaign.validityStartDate', 'desc']
  };

  const promises = [
    apiCall({
      url: '/offer-campaigns',
      params: offerCampaignParams
    }),
    apiCall({ url: `/offers/${offer.id}/product-levels` })
  ];

  let [offerCampaignsResponse, offerDetailsBrandsResponse] = await Promise.all(promises);
  if (!offerCampaignsResponse || !offerDetailsBrandsResponse) return false;

  const offerCampaignCount = offerCampaignsResponse.headers['x-total-count'];
  if (offerCampaignCount > offerCampaignsResponse.data.length) {
    offerCampaignsResponse = await apiCall({
      url: '/offer-campaigns',
      params: {
        ...offerCampaignParams,
        size: offerCampaignCount
      }
    });
    if (!offerCampaignsResponse) return false;
  }
  dispatch({
    type: 'OFFERS_UPDATE',
    payload: {
      details: {
        offer,
        brandList: offerDetailsBrandsResponse.data,
        offerCampaigns: offerCampaignsResponse.data
      }
    }
  });
  return offer;
};

export const getOfferForEdit = async (offerHeadId) => {
  const productsSize = 2000;
  const offerDetailsResponse = await apiCall({
    url: `/offers`,
    params: { 'frozen.equals': false, 'offerHeadId.equals': offerHeadId }
  });
  if (!offerDetailsResponse || !offerDetailsResponse.data?.[0]?.id) return false;

  const offer = offerDetailsResponse.data[0];
  offer.croppedImageMetadata = offer.offerHead.fileDescriptor.croppedImageMetadata;

  const promises = [
    apiCall({
      url: '/offer-campaigns',
      params: {
        'offerOfferHeadId.equals': offerHeadId,
        'campaignStatus.equals': campaignStatuses.VALIDATED,
        sort: ['campaign.validityEndDate', 'desc']
      } // sort by campaign.validityEndDate for rule MODIF_OFFRE_PRODUIT_2
    }),
    apiCall({ url: `/offers/${offer.id}/products?size=${productsSize}` }),
    apiCall({ url: `/offers/${offer.id}/product-levels` })
  ];

  const [offerCampaignsResponse, offerProductsResponse, offerBrandsResponse] = await Promise.all(promises);
  if (!offerCampaignsResponse || !offerProductsResponse || !offerBrandsResponse) return false;

  dispatch({
    type: 'OFFERS_DETAILS_UPDATE',
    payload: {
      offer,
      brandList: offerBrandsResponse.data,
      offerCampaigns: offerCampaignsResponse.data
    }
  });

  // get all offer products
  const offerProductsCount = offerProductsResponse.headers['x-total-count'];
  let allOfferProductsResponse;
  if (offerProductsCount > productsSize) {
    allOfferProductsResponse = await apiCall({
      url: `/offers/${offer.id}/products`,
      params: { size: offerProductsCount }
    });
    if (!allOfferProductsResponse) return false;
  } else {
    allOfferProductsResponse = offerProductsResponse;
  }

  const offerPeriodsResponse = await getOfferPeriods(offerHeadId);
  if (!offerPeriodsResponse) return false;

  // get linked campaigns per offer-period
  const activeCampaignIdsPromise = offerPeriodsResponse?.map((p) => {
    if (p.countActiveCampaign) {
      return findAllActiveCampaignIds(p.id);
    } else return false;
  });
  const activeCampaignIdsPerOfferPeriod = await Promise.all(activeCampaignIdsPromise);

  return {
    offerDetails: offer,
    offerCampaigns: offerCampaignsResponse.data,
    offerBrands: offerBrandsResponse.data,
    resultListEan: allOfferProductsResponse.data.map((p) => ({
      averagePrice: p.averagePrice,
      customProductId: p.customProductId,
      ean: p.code,
      id: p.id,
      label: p.description,
      productLevelFourthCode: p.productLevelFourth?.code
    })),
    validityDatesList: offerPeriodsResponse?.map((d, index) => ({
      activeCampaignIds: activeCampaignIdsPerOfferPeriod?.[index],
      countActiveCampaign: d.countActiveCampaign,
      endDate: moment(d.validityEndDate),
      id: d.id,
      startDate: moment(d.validityStartDate)
    }))
  };
};

export const getOfferForDuplication = async ({ currentRetailer, offerHeadId, suppliersWithBudget, t }) => {
  const productsSize = 2000;
  const offerDetailsResponse = await apiCall({
    url: `/offers`,
    params: { 'frozen.equals': false, 'offerHeadId.equals': offerHeadId }
  });
  if (!offerDetailsResponse || !offerDetailsResponse.data?.[0]?.id) return false;

  const offer = offerDetailsResponse.data[0];

  // check if supplier has budget for current or next year
  const initialOfferSupplierId = offer.offerHead.budget.supplier.id;
  const selectedSupplierHasBudget = !!suppliersWithBudget.find((sup) => sup.id === initialOfferSupplierId);

  const promises = [];

  if (selectedSupplierHasBudget) {
    promises.push(
      apiCall({
        url: '/offer-campaigns',
        params: {
          'offerOfferHeadId.equals': offerHeadId,
          'campaignStatus.equals': campaignStatuses.VALIDATED,
          sort: ['campaign.validityEndDate', 'desc']
        } // sort by campaign.validityEndDate for rule MODIF_OFFRE_PRODUIT_2
      }),
      apiCall({ url: `/offers/${offer.id}/products?size=${productsSize}` }),
      apiCall({ url: `/offers/${offer.id}/product-levels` })
    );
  }

  const [offerCampaignsResponse, offerProductsResponse, offerBrandsResponse] = !!promises.length
    ? await Promise.all(promises)
    : [];
  if (!!promises.length && (!offerCampaignsResponse || !offerProductsResponse || !offerBrandsResponse)) return false;

  // get all offer products
  let allOfferProductsResponse;
  const offerProductsCount = !!promises.length ? offerProductsResponse.headers['x-total-count'] : 0;
  if (offerProductsCount > productsSize) {
    allOfferProductsResponse = await apiCall({
      url: `/offers/${offer.id}/products`,
      params: { size: offerProductsCount }
    });
    if (!!promises.length && !allOfferProductsResponse) return false;
  } else {
    allOfferProductsResponse = offerProductsResponse || { data: [] };
  }

  const offerPeriodsResponse = await getOfferPeriods(offerHeadId);
  if (!offerPeriodsResponse) return false;

  const payload = {
    offerDetails: JSON.parse(JSON.stringify(offer)),
    offerCampaigns: offerCampaignsResponse?.data || [],
    offerBrands: offerBrandsResponse?.data || [],
    resultListEan: allOfferProductsResponse.data.map((p) => ({
      averagePrice: p.averagePrice,
      customProductId: p.customProductId,
      ean: p.code,
      id: p.id,
      label: p.description,
      productLevelFourthCode: p.productLevelFourth?.code
    })),
    validityDatesList: offerPeriodsResponse?.map((d) => ({
      activeCampaignIds: [],
      countActiveCampaign: d.countActiveCampaign,
      endDate: moment(d.validityEndDate),
      id: d.id,
      startDate: moment(d.validityStartDate)
    }))
  };

  /* ************ */
  /* duplication specifications overrides */
  /* ************ */
  payload.offerDetails.averagePrice = undefined;
  payload.offerDetails.createdAt = undefined;
  payload.offerDetails.creator = undefined;
  payload.offerDetails.status = undefined;
  payload.offerDetails.statusUpdatesHistory = undefined;

  if (!selectedSupplierHasBudget) {
    payload.offerDetails.offerHead.budget = {};
    payload.offerDetails.retailer = {};
    payload.resultListEan = [];
    payload.offerBrands = [];
  }
  // delete offer id
  payload.offerDetails.id = null;
  payload.offerDetails.offerHead.id = null;
  // budget
  payload.offerDetails.offerHead.budgetTarget = '';
  payload.offerDetails.offerHead.budgetEstimated = null;
  // offerCampaigns
  if (!selectedSupplierHasBudget) {
    payload.offerCampaigns = [];
  }
  // offer constaints params
  const constraints = await getOfferConstraints(currentRetailer?.id);
  const existingDiscountTypeConstraint = constraints.find((el) => el.target === 'discountType') || {
    selectedValues: ['LOYALTY', 'IMMEDIATE']
  };
  const existingDiscountUnitConstraint = constraints.find((el) => el.target === 'discountUnit') || {
    selectedValues: ['PERCENT', 'CURRENCY']
  };

  payload.offerDetails.discountType =
    existingDiscountTypeConstraint?.selectedValues?.length === 1
      ? existingDiscountTypeConstraint?.selectedValues[0]
      : payload.offerDetails.discountType;

  payload.offerDetails.discountUnit =
    existingDiscountUnitConstraint?.selectedValues?.length === 1
      ? existingDiscountUnitConstraint?.selectedValues[0]
      : payload.offerDetails.discountUnit;

  if (payload.offerDetails.discountUnit !== offer.discountUnit) {
    payload.offerDetails.discountValue = '';
  }
  // title
  payload.offerDetails.offerHead.title = `${t('offers_creation_duplication_title_copy_prefix')} ${
    payload.offerDetails.offerHead.title
  }`;
  // validityDates
  if (selectedSupplierHasBudget) {
    payload.validityDatesList = payload.validityDatesList.some((dates) => moment().isAfter(dates.startDate, 'day'))
      ? [{ startDate: null, endDate: null }]
      : payload.validityDatesList;
  } else {
    payload.validityDatesList = [{ startDate: null, endDate: null }];
  }
  // image
  payload.offerDetails.croppedImageMetadata = payload.offerDetails.offerHead.fileDescriptor.croppedImageMetadata;

  dispatch({
    type: 'OFFERS_DETAILS_UPDATE',
    payload: {
      offer: payload.offerDetails,
      brandList: payload.offerBrands,
      offerCampaigns: payload.offerCampaigns
    }
  });

  dispatch({
    type: 'GET_OFFER_CONSTRAINTS',
    payload: constraints
  });

  return payload;
};

/* */
/* @offerId required */
/* Use @page for fetching paginated list */
/* Without @page for fetching all offer products list */
/* */
export const getOfferProducts = async ({ offerId, page, size = 2000 }) => {
  let products = [];
  const response = await apiCall({
    url: `/offers/${offerId}/products`,
    params: { page, size }
  });
  if (!response) return false;

  const count = response.headers['x-total-count'];
  if (page === undefined && count > size) {
    const allResponse = await apiCall({
      url: `/offers/${offerId}/products`,
      params: { size: count }
    });

    if (!allResponse) return false;

    products = allResponse.data;
  } else {
    products = response.data;
  }

  return products;
};

export const getOfferDisableStatus = async (offerHeadId) => {
  const response = await apiCall({ url: `/offers/offer-heads/${offerHeadId}/disable-status` });
  if (!response) return false;

  const linkedCampaignsListResponses = !!response.data.infos?.length
    ? await Promise.all(
        response.data.infos.map((info) => (info.lstCampaignId?.length ? getCampaignsByIds(info.lstCampaignId) : null))
      )
    : [];

  const payload = {
    ...response.data,
    infos: !!response.data.infos.length
      ? response.data.infos.map((info, index) => ({
          ...info,
          campaignsList: linkedCampaignsListResponses[index] !== null ? linkedCampaignsListResponses[index] : []
        }))
      : []
  };
  return payload;
};

export const getOffers = async ({
  user,
  sortBy,
  orderBy,
  page,
  size,
  offerType,
  filters = {},
  fetchMultiPeriodCount
}) => {
  const hasIdFilter =
    (filters.id && !Array.isArray(filters.id)) || (filters.id && Array.isArray(filters.id) && filters.id?.length);

  let budgetIds;
  if (!hasIdFilter && filters.supplierId?.length) {
    const budgetsResponse = await getAllSupplierBudgets({
      retailerId: user.id,
      supplierIds: filters.supplierId.join(',')
    });
    if (!budgetsResponse) return false;

    budgetIds = budgetsResponse.map((b) => b.id).join(',');
  }

  const filtersParams = {
    'frozen.equals': false,
    'offerHeadId.in': filters.id,
    'offerHeadType.in': !hasIdFilter && filters.offerHeadType?.length ? filters.offerHeadType : undefined,
    'offerHeadHasOverrunBudget.equals': (!hasIdFilter && filters.onlyOverrunBudget) || undefined,
    'title.contains': !hasIdFilter ? filters.title : undefined,
    'descriptionTag.containsIn': !hasIdFilter && filters.tagContains?.length ? filters.tagContains : undefined,
    'descriptionTag.notContainsIn': !hasIdFilter && filters.tagNotContains?.length ? filters.tagNotContains : undefined,
    'status.in': !hasIdFilter && filters.status?.length ? filters.status : undefined,
    'targetingMarketingCode.in':
      !hasIdFilter && filters.targetingMarketingCode?.length ? filters.targetingMarketingCode : undefined,
    'budgetId.in': budgetIds,
    'retailerId.in': !hasIdFilter && filters.retailerId?.length ? filters.retailerId : undefined,
    'privateLabel.equals':
      !hasIdFilter && filters.privateLabel?.length === 1 ? filters.privateLabel[0] === 'mdd' : undefined,
    'supplierUserManagerId.in': !hasIdFilter && !!filters.userManagerId?.length ? filters.userManagerId : undefined,
    [`budgetSpent.${filters.budgetSpent?.[0]}`]:
      !hasIdFilter && filters.budgetSpent?.[1] ? filters.budgetSpent[1] : undefined,
    [`budgetTarget.${filters.budgetTarget?.[0]}`]:
      !hasIdFilter && filters.budgetTarget?.[1] ? filters.budgetTarget[1] : undefined,
    [`validityStartDate.${filters.validityStartDate?.[0]}`]:
      !hasIdFilter && filters.validityStartDate?.[1]
        ? moment(filters.validityStartDate[1]).startOf('day').utc().format()
        : undefined,
    [`validityEndDate.${filters.validityEndDate?.[0]}`]:
      !hasIdFilter && filters.validityEndDate?.[1]
        ? moment(filters.validityEndDate[1]).endOf('day').utc().format()
        : undefined
  };
  const params = {
    page,
    size,
    sort: sortBy && orderBy ? `${sortBy},${orderBy}` : undefined,
    'type.equals': offerType,
    ...filtersParams
  };

  const response = await apiCall({ url: '/offers', params });
  if (!response) return false;
  let list = response.data;

  // get budgetRecommendations per offer
  const recommendations =
    offerType === 'SUPPLIER_PRODUCT_OFFER' &&
    !!list.length &&
    (await getBudgetRecommendations(list.map((el) => el.offerHead.id)));

  if (recommendations?.length) {
    list = list.map((el) => {
      const reco = recommendations.find((reco) => reco.offerHead.id === el.offerHead.id);
      if (
        reco &&
        moment(reco.createdAt).isSame(moment(), 'day') &&
        reco.recommendedBudget !== reco.offerHead.budgetTarget &&
        !reco.used
      ) {
        el = { ...el, recommendation: reco };
      }
      return el;
    });
  }

  // get periods count per offer
  const periodsCount =
    fetchMultiPeriodCount &&
    offerType === 'SUPPLIER_PRODUCT_OFFER' &&
    !!list.length &&
    (await getOfferPeriodsList(list.map((el) => el.offerHead.id)));

  if (periodsCount?.length) {
    const regroupedByOfferHeadId = periodsCount.reduce((acc, period) => {
      if (acc[period.offerHead.id]) {
        acc[period.offerHead.id]++;
      } else {
        acc[period.offerHead.id] = 1;
      }
      return acc;
    }, {});

    list = list.map((el) => {
      if (regroupedByOfferHeadId[el.offerHead.id]) {
        el = { ...el, periodsCount: regroupedByOfferHeadId[el.offerHead.id] };
      }
      return el;
    });
  }

  dispatch({
    type: 'OFFERS_UPDATE',
    payload: {
      total: parseInt(response.headers['x-total-count'], 10),
      list
    }
  });

  return true;
};

export const getOffersCompatibleCount = async ({ campaignProduct, filters = {} }) => {
  const { id: campaignId, offersCount, endDate, startDate } = campaignProduct;
  const {
    id: filtersIds,
    offerType,
    privateLabel,
    supplierBudgetType,
    supplierId,
    tagContains,
    targetingMarketingCode,
    tagNotContains,
    title
  } = filters;
  const usePrivateLabelFilter = offerType?.length !== 1 || offerType[0] !== offerTypes.SUPPLIER_PRODUCT_OFFER;

  let budgetIds;
  if (!filtersIds?.length && supplierId?.length) {
    const budgetsResponse = await getAllSupplierBudgets({
      retailerId: filtersIds,
      supplierIds: supplierId.join(','),
      year: `${currentYear},${currentYear + 1}`
    });
    if (!budgetsResponse) return false;

    budgetIds = budgetsResponse.map((b) => b.id).join(',');
  }

  const filtersParams = {
    'offerHeadId.in': filtersIds,
    'title.contains': !filtersIds?.length ? title : undefined,
    'descriptionTag.containsIn': !filtersIds?.length && tagContains?.length ? tagContains : undefined,
    'descriptionTag.notContainsIn': !filtersIds?.length && tagNotContains?.length ? tagNotContains : undefined,
    'budgetId.in': budgetIds,
    'targetingMarketingCode.in': !filtersIds && targetingMarketingCode?.length ? targetingMarketingCode : undefined,
    'type.equals': !filtersIds?.length && offerType?.length === 1 ? offerTypes[offerType[0]] : undefined,
    'privateLabel.equals':
      !filtersIds?.length && privateLabel?.length === 1 && usePrivateLabelFilter
        ? privateLabel[0] === 'mdd'
        : undefined,
    'offerHeadSupplierBudgetType.equals':
      !filtersIds?.length && supplierBudgetType?.length === 1 ? supplierBudgetType[0] : undefined
  };

  const response = await apiCall({
    url: '/offers/count',
    params: {
      'frozen.equals': false,
      'status.equals': 'VALIDATED',
      'validityEndDate.greaterThanOrEqual': endDate.utc().format(),
      'validityStartDate.lessThanOrEqual': startDate.utc().format(),
      'hasReservedBudgetOrUnlimitedBudget.equals': 'true',
      'offerHeadDisabled.equals': false,
      'targetingStrategyCode.notEquals': 'UNTARGETABLE',
      ...filtersParams
    }
  });
  if (!response) return false;

  let offersPreviewAlreadyAddedCountResponse;
  if (offersCount) {
    const offerFiltersParams = {
      'offerOfferHeadId.in': filtersIds,
      'offerTitle.contains': !filtersIds?.length ? title : undefined,
      'offerDescriptionTag.containsIn': !filtersIds?.length && tagContains?.length ? tagContains : undefined,
      'offerDescriptionTag.notContainsIn': !filtersIds?.length && tagNotContains?.length ? tagNotContains : undefined,
      'offerBudgetId.in': budgetIds,
      'targetingMarketingCode.in': !filtersIds && targetingMarketingCode?.length ? targetingMarketingCode : undefined,
      'offerType.equals': !filtersIds?.length && offerType?.length === 1 ? offerTypes[offerType[0]] : undefined,
      'offerPrivateLabel.equals':
        !filtersIds?.length && privateLabel?.length === 1 && usePrivateLabelFilter
          ? privateLabel[0] === 'mdd'
          : undefined,
      'offerOfferHeadSupplierBudgetType.equals':
        !filtersIds?.length && supplierBudgetType?.length === 1 ? supplierBudgetType[0] : undefined
    };

    offersPreviewAlreadyAddedCountResponse = await apiCall({
      url: '/offer-campaigns/count',
      params: {
        'campaignId.equals': campaignId,
        'offerStatus.equals': 'VALIDATED',
        'offerOfferHeadValidityEndDate.greaterThanOrEqual': endDate.utc().format(),
        'offerValidityStartDate.lessThanOrEqual': startDate.utc().format(),
        'offerHasReservedBudgetOrUnlimitedBudget.equals': 'true',
        'offerOfferHeadDisabled.equals': false,
        'targetingStrategyCode.notEquals': 'UNTARGETABLE',
        ...offerFiltersParams
      }
    });
    if (!offersPreviewAlreadyAddedCountResponse) return false;
  }

  dispatch({
    type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE',
    payload: {
      offersPreviewCount: response.data,
      offersPreviewAlreadyAddedCount: offersPreviewAlreadyAddedCountResponse?.data
    }
  });
  return response.data;
};

export const getOffersCount = async ({
  budgetIds,
  offerType,
  retailerId,
  statuses = [offerStatuses.VALIDATED],
  year = currentYear
}) => {
  const response = await apiCall({
    url: '/offers/count',
    params: {
      'budgetId.in': budgetIds,
      'frozen.equals': false,
      'retailerId.equals': retailerId,
      'status.in': statuses,
      'type.equals': offerType,
      'validityEndDate.lessThanOrEqual': moment().year(year).endOf('year').utc().format(),
      'validityStartDate.greaterThanOrEqual': moment().year(year).startOf('year').utc().format()
    }
  });
  if (!response) return false;

  return response.data;
};

export const getOffersExportFile = async ({ extension, filters, orderBy, sortBy, userId }) => {
  const hasIdFilter =
    (filters.id && !Array.isArray(filters.id)) || (filters.id && Array.isArray(filters.id) && filters.id?.length);

  let budgetIds;
  if (!hasIdFilter && filters.supplierId?.length) {
    const budgetsResponse = await getAllSupplierBudgets({
      retailerId: userId,
      supplierIds: filters.supplierId.join(',')
    });
    if (!budgetsResponse) return false;

    budgetIds = budgetsResponse.map((b) => b.id).join(',');
  }

  const filtersParams = {
    'frozen.equals': false,
    'offerHeadId.in': filters.id,
    'offerHeadType.in': !hasIdFilter && filters.offerHeadType?.length ? filters.offerHeadType : undefined,
    'offerHeadHasOverrunBudget.equals': (!hasIdFilter && filters.onlyOverrunBudget) || undefined,
    'title.contains': !hasIdFilter ? filters.title : undefined,
    'descriptionTag.containsIn': !hasIdFilter && filters.tagContains?.length ? filters.tagContains : undefined,
    'descriptionTag.notContainsIn': !hasIdFilter && filters.tagNotContains?.length ? filters.tagNotContains : undefined,
    'status.in': !hasIdFilter && filters.status?.length ? filters.status : undefined,
    'targetingMarketingCode.in':
      !hasIdFilter && filters.targetingMarketingCode?.length ? filters.targetingMarketingCode : undefined,
    'budgetId.in': budgetIds,
    'retailerId.in': !hasIdFilter && filters.retailerId?.length ? filters.retailerId : undefined,
    'privateLabel.equals':
      !hasIdFilter && filters.privateLabel?.length === 1 ? filters.privateLabel[0] === 'mdd' : undefined,
    [`budgetSpent.${filters.budgetSpent?.[0]}`]:
      !hasIdFilter && filters.budgetSpent?.[1] ? filters.budgetSpent[1] : undefined,
    [`budgetTarget.${filters.budgetTarget?.[0]}`]:
      !hasIdFilter && filters.budgetTarget?.[1] ? filters.budgetTarget[1] : undefined,
    [`validityStartDate.${filters.validityStartDate?.[0]}`]:
      !hasIdFilter && filters.validityStartDate?.[1]
        ? moment(filters.validityStartDate[1]).startOf('day').utc().format()
        : undefined,
    [`validityEndDate.${filters.validityEndDate?.[0]}`]:
      !hasIdFilter && filters.validityEndDate?.[1]
        ? moment(filters.validityEndDate[1]).endOf('day').utc().format()
        : undefined
  };
  const params = {
    sort: `${sortBy},${orderBy}`,
    'type.equals': offerTypes.SUPPLIER_PRODUCT_OFFER,
    ...filtersParams
  };

  await downloadFile(`offers/export/${extension}`, params);
  return true;
};

export const applyBudgetRecommendation = async ({ offerHeadId, recommendationId }) => {
  const response = await apiCall({
    method: 'put',
    url: '/offers/offer-heads/recommendation-apply',
    params: { offerHeadId, recommendationId }
  });
  if (!response) return false;
  return true;
};

export const getBudgetRecommendation = async (id) => {
  const response = await apiCall({
    url: `/offers/offer-heads/${id}/last-recommendation`
  });
  if (!response || !response.data) return false;
  if (
    !moment(response.data.createdAt).isSame(moment(), 'day') ||
    response.data.recommendedBudget === response.data.offerHead.budgetTarget ||
    response.data.used
  ) {
    return false;
  }
  return response.data;
};

export const getBudgetRecommendations = async (ids) => {
  let response = await apiCall({
    url: `/offers/offer-heads/last-recommendation`,
    params: { offerHeadIds: ids }
  });
  if (!response || !response.data) return false;

  const size = response.headers['x-total-count'];
  if (size > response.data.length) {
    response = await apiCall({
      url: `/offers/offer-heads/last-recommendation`,
      params: { offerHeadIds: ids, size }
    });
    if (!response || !response.data) return false;
  }

  return response.data;
};

export const getUntargetableOffersCampaigns = async ({
  campaignId,
  endDate,
  startDate,
  page = 0,
  size = 30,
  sortBy = 'id',
  orderBy = 'desc'
}) => {
  const response = await apiCall({
    url: '/offer-campaigns',
    params: {
      'campaignId.equals': campaignId,
      'status.equals': 'VALIDATED',
      'validityEndDate.greaterThanOrEqual': endDate?.utc()?.format(),
      'validityStartDate.lessThanOrEqual': startDate?.utc()?.format(),
      'hasReservedBudgetOrUnlimitedBudget.equals': 'true',
      'offerOfferHeadDisabled.equals': false,
      'targetingStrategyCode.notEquals': 'UNTARGETABLE',
      page,
      size,
      sort: `${sortBy},${orderBy}`
    }
  });
  if (!response) return false;
  return response.data;
};

export const postOffer = async ({ file, cropData, offer, products, retailerCode, brandList, validityDatesList }) => {
  const formData = new FormData();
  const currFile = file.file || file;
  formData.append('file', currFile);
  formData.append('name', currFile.name);
  formData.append('type', 'IMG');
  formData.append('croppedImageMetadata', cropData ? JSON.stringify(cropData) : null);
  formData.append('retailer', retailerCode);

  const postFile = await apiCall({
    method: 'post',
    url: '/files',
    data: formData,
    contentType: 'multipart/form-data'
  });
  if (!postFile) return false;

  const newOffer = JSON.parse(JSON.stringify(offer));
  delete newOffer.offerHead.croppedImageFileDescriptor;
  newOffer.offerHead.id = undefined; // remove id for duplicating feature
  newOffer.offerHead.fileDescriptor.id = postFile.data[0].id;
  newOffer.offerHead.imageMediumFileDescriptor = { id: postFile.data[1].id };
  newOffer.offerHead.imageRetailerFileDescriptor = { id: postFile.data[2].id };
  if (postFile.data[3]?.id) {
    newOffer.offerHead.croppedImageFileDescriptor = { id: postFile.data[3].id };
  }

  const postOffer = await apiCall({ method: 'post', url: '/offers', data: newOffer });
  if (!postOffer) return false;

  const isProductOffer = offer.offerHead.type === offerHeadTypes.PRODUCT_OFFER;

  const maxRetryCounterToAddProductsOrBrands = 5; // retry up to 5 times
  let retryCounterToAddProductsOrBrands = 1;

  const addProductsOrBrands = async () => {
    const postProductsOrBrands = await apiCall({
      method: 'post',
      url: `/offers/${postOffer.data.id}/${isProductOffer ? 'products' : 'product-levels'}`,
      data: isProductOffer ? products : brandList
    });
    if (!postProductsOrBrands) {
      if (retryCounterToAddProductsOrBrands > maxRetryCounterToAddProductsOrBrands) {
        return false;
      }
      retryCounterToAddProductsOrBrands++;
      await addProductsOrBrands();
    }
  };
  await addProductsOrBrands();
  await postOfferPeriods({ offerHeadId: postOffer.data.offerHead.id, validityDatesList });

  return postOffer.data;
};

export const postOfferRecommendation = async (payload) => {
  const response = await apiCall({
    method: 'post',
    url: `/budget-estimated/${payload.retailer}/calculate`,
    data: payload,
    disableSnackbar: true
  });
  if (response) {
    return response.data;
  }
  return false;
};

export const postOffersCsv = async ({ file, retailerCode }) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('retailer', retailerCode);
  formData.append('type', offerTypes.RETAILER_PRODUCT_OFFER);
  formData.append('contentType', 'text/csv');

  const response = await apiCall({
    method: 'post',
    url: '/files',
    data: formData,
    contentType: 'multipart/form-data'
  });
  return !!response;
};

export const updateOffer = async ({
  brandList,
  cropData,
  file,
  isSuperEditable,
  offer,
  products,
  resultListBrandRaw,
  resultListEanRaw,
  validityDatesList,
  validityDatesListRaw
}) => {
  let postFile;
  if (file && !file.id) {
    const formData = new FormData();

    formData.append('file', file?.file ? file.file : file);
    formData.append('name', offer.offerHead.fileDescriptor.name || file?.file?.name || file?.name);
    formData.append('type', 'IMG');
    formData.append('croppedImageMetadata', cropData ? JSON.stringify(cropData) : null);
    formData.append('retailer', offer.retailer.code);

    postFile = await apiCall({
      method: 'post',
      url: '/files',
      data: formData,
      contentType: 'multipart/form-data'
    });
    if (!postFile) return false;
  }

  const newOffer = JSON.parse(JSON.stringify(offer));
  if (postFile) {
    newOffer.offerHead.fileDescriptor = { id: postFile.data[0].id };
    newOffer.offerHead.imageMediumFileDescriptor = { id: postFile.data[1].id };
    newOffer.offerHead.imageRetailerFileDescriptor = { id: postFile.data[2].id };
    if (postFile.data[3]?.id) {
      newOffer.offerHead.croppedImageFileDescriptor = { id: postFile.data[3].id };
    }
  }

  /*** START - update products ***/
  const isProductOffer = offer.offerHead.type === offerHeadTypes.PRODUCT_OFFER;

  if ((products && resultListEanRaw) || (brandList && resultListBrandRaw)) {
    let listToDelete = (isProductOffer ? resultListEanRaw : resultListBrandRaw)
      .filter((el) => !(isProductOffer ? products : brandList).includes(el.id))
      .map((el) => el.id);

    let deleteProductsSuccess = true;
    if (listToDelete.length) {
      deleteProductsSuccess = await apiCall({
        method: 'delete',
        url: `/offers/${newOffer.id}/${isProductOffer ? 'products' : 'product-levels'}`,
        data: listToDelete
      });
    }
    if (!deleteProductsSuccess) return false;
  }

  if (products || brandList) {
    const maxRetryCounterToAddProductsOrBrands = 5; // retry up to 5 times
    let retryCounterToAddProductsOrBrands = 1;

    const addProductsOrBrands = async () => {
      const postProductsOrBrands = await apiCall({
        method: 'post',
        url: `/offers/${newOffer.id}/${isProductOffer ? 'products' : 'product-levels'}`,
        data: isProductOffer ? products : brandList
      });
      if (!postProductsOrBrands) {
        if (retryCounterToAddProductsOrBrands > maxRetryCounterToAddProductsOrBrands) {
          return false;
        }
        retryCounterToAddProductsOrBrands++;
        await addProductsOrBrands();
      }
    };
    await addProductsOrBrands();
  }
  /***END - update products ***/

  /*** START - update periods ***/
  // compare validityDatesList <---> validityDatesListRaw
  const handleMultiPeriod = async () => {
    // check which ids are missing
    let deletedOfferPeriodList = validityDatesListRaw?.filter(
      (raw) => !validityDatesList?.find((d) => d.id === raw.id)
    );
    // new period has no id yet
    let newOfferPeriodList = validityDatesList?.filter((d) => !d.id);
    // periods that must be edited
    let updatedOfferPeriodList = validityDatesList
      ?.filter((d) => {
        if (!d.id) {
          return false;
        }
        const match = validityDatesListRaw.find((raw) => raw.id === d.id);
        if (match && (!match.startDate.isSame(d.startDate, 'day') || !match.endDate.isSame(d.endDate, 'day'))) {
          return true;
        }
        return false;
      })
      .sort((a, b) => b.startDate.valueOf() - a.startDate.valueOf());
    // in case number of deleted periods equals validityDatesListRaw.length
    // one of the period to delete must be edited with the dates of a new added period
    if (!!deletedOfferPeriodList?.length && validityDatesListRaw?.length === deletedOfferPeriodList?.length) {
      let editInsteadOfDeletePeriod = deletedOfferPeriodList.pop();
      const addedPeriodToUse = newOfferPeriodList.shift();
      editInsteadOfDeletePeriod = {
        ...editInsteadOfDeletePeriod,
        startDate: addedPeriodToUse.startDate,
        endDate: addedPeriodToUse.endDate
      };
      updatedOfferPeriodList.unshift(editInsteadOfDeletePeriod);
    }

    // order matters !!!
    // deleting must be before adding
    const isSuccessDeleting = !deletedOfferPeriodList?.length || (await deleteOfferPeriods(deletedOfferPeriodList));
    if (!isSuccessDeleting) return false;

    const isSuccessUpdating =
      !updatedOfferPeriodList?.length ||
      (await updateOfferPeriods({
        offerHeadId: offer.offerHead.id,
        periodsList: updatedOfferPeriodList
      }));
    if (!isSuccessUpdating) return false;

    const isSuccessAdding =
      !newOfferPeriodList?.length ||
      (await addOfferPeriods({
        offerHeadId: offer.offerHead.id,
        periodsList: newOfferPeriodList
      }));
    if (!isSuccessAdding) return false;
    return true;
  };

  const isOK = await handleMultiPeriod();
  if (!isOK) return false;
  /*** END - update periods ***/

  // get new offer status if it was expired before edition
  // because changing dates or adding periods changes the status
  if (newOffer.status === offerStatuses.EXPIRED) {
    const updatedStatusOfferResponse = await apiCall({
      url: `/offers`,
      params: { 'frozen.equals': false, 'offerHeadId.equals': offer.offerHead.id }
    });
    if (!updatedStatusOfferResponse) return false;
    newOffer.status = updatedStatusOfferResponse.data[0].status;
  }
  // update offer
  const updatedOfferReponse = isSuperEditable
    ? await apiCall({ method: 'put', url: '/offers-super-editable', data: newOffer })
    : await apiCall({ method: 'put', url: '/offers', data: newOffer });
  if (!updatedOfferReponse) return false;

  return updatedOfferReponse.data;
};

export const updateOfferImage = async ({ file, cropData, offer }) => {
  let postFile;
  if (file && !file.id && offer) {
    const formData = new FormData();
    formData.append('file', file.file || file);
    formData.append('name', offer.offerHead.fileDescriptor?.name || file.file?.name || file.name);
    formData.append('type', 'IMG');
    formData.append('croppedImageMetadata', cropData ? JSON.stringify(cropData) : null);
    formData.append('retailer', offer.retailer.code);

    postFile = await apiCall({
      method: 'post',
      url: '/files',
      data: formData,
      contentType: 'multipart/form-data'
    });
    if (!postFile) return false;

    const newData = {
      fileDescriptor: { id: postFile.data.find((el) => el.format === 'ORIGINAL')?.id },
      imageMediumFileDescriptor: { id: postFile.data[1].id },
      imageRetailerFileDescriptor: { id: postFile.data[2].id },
      croppedImageFileDescriptor: postFile.data[3]?.id ? { id: postFile.data[3].id } : null
    };

    const updatedOfferReponse = await apiCall({ method: 'put', url: `/offers/${offer.id}/images`, data: newData });
    if (!updatedOfferReponse) return false;

    return updatedOfferReponse.data;
  }
  return true;
};

export const updateOfferSuperEditableFlag = async ({ isSuperEditable = false, offerId }) => {
  const response = await apiCall({
    method: 'PUT',
    url: `/offers/${offerId}/flag-super-editable?superEditable=${isSuperEditable}`
  });

  if (!response) return false;
  return true;
};

/*** Product-Levels ***/

export const getAllProductlevels = async ({ productLevel, retailerId, supplierId }) => {
  // WARNING: back api uses lvl1-4 but segment manager api uses level0-3
  const levelMapping = () => {
    if (productLevel === 'level3') return 'lvl4';
    if (productLevel === 'level2') return 'lvl3';
    if (productLevel === 'level1') return 'lvl2';
    if (productLevel === 'level0') return 'lvl1';
    else return productLevel;
  };

  let response = await apiCall({
    url: '/product-levels',
    params: {
      'retailerSettingCode.equals': levelMapping(),
      'retailerId.equals': retailerId,
      'supplierId.equals': supplierId,
      sort: 'name,asc',
      size: 1000
    }
  });

  if (!response) return false;

  const count = response.headers['x-total-count'];

  if (count > response.data.length) {
    response = await apiCall({
      url: '/product-levels',
      params: {
        'retailerSettingCode.equals': levelMapping(),
        'retailerId.equals': retailerId,
        'supplierId.equals': supplierId,
        size: count,
        sort: 'name,asc'
      }
    });
  }
  if (!response) return false;

  return response.data;
};

export const getAllProductLevelsByCode = async ({ codeList = [], retailerId }) => {
  let response = [];
  if (codeList.length) {
    const params = { 'code.in': codeList, 'retailerId.equals': retailerId, size: 2000 };

    response = await apiCall({
      url: '/product-levels',
      params
    });
    if (!response) return false;

    const count = response.headers['x-total-count'];
    if (count > response.data.length) {
      response = await apiCall({
        url: '/product-levels',
        params: { ...params, size: count }
      });
    }
    if (!response) return false;
  }

  return response.data.map((p) => ({
    code: p.code,
    id: p.id,
    label: p.name
  }));
};

export const getProductLevels = async ({
  keyword,
  order = 'asc',
  page = 0,
  productLevel,
  retailerId,
  sort = 'name',
  supplierId
}) => {
  // WARNING: back api uses lvl1-4 but segment manager api uses level0-3
  const levelMapping = () => {
    if (productLevel === 'level3') return 'lvl4';
    if (productLevel === 'level2') return 'lvl3';
    if (productLevel === 'level1') return 'lvl2';
    if (productLevel === 'level0') return 'lvl1';
    else return productLevel;
  };
  const response = await apiCall({
    url: '/product-levels',
    params: {
      'retailerSettingCode.equals': levelMapping(),
      'retailerId.equals': retailerId,
      'supplierId.equals': supplierId,
      'name.contains': keyword,
      page,
      size: 30,
      sort: `${sort},${order}`
    }
  });
  if (!response) return false;

  return {
    list: response.data.map((el) => ({ code: el.code, id: el.id, label: el.name })),
    total: parseInt(response.headers['x-total-count'], 10)
  };
};

export const getAllProductsByLevelSku = async ({ retailerId, supplierId, brandId, categoryId }) => {
  const size = 2000;
  const params = {
    'brandId.equals': brandId,
    'productLevelFourthId.equals': categoryId,
    'retailerId.equals': retailerId,
    'supplierId.in': supplierId,
    page: 0,
    size
  };

  const response = await apiCall({
    url: '/products',
    params
  });
  if (!response) return false;

  const productsCount = response.headers['x-total-count'];
  let allProductsResponse;
  let allProducts = [...response.data];

  if (productsCount > response.data.length) {
    let promises = [];
    const pageCountMax = Math.floor(productsCount / size);
    let pageCount = 1;

    do {
      promises.push(
        apiCall({
          url: '/products',
          params: { ...params, page: pageCount }
        })
      );
      pageCount++;
    } while (pageCount <= pageCountMax);

    allProductsResponse = await Promise.all(promises);
    if (!allProductsResponse.every((res) => !!res)) return false;
    allProducts = allProducts.concat(...allProductsResponse.map((res) => [...res.data]));
  }

  return allProducts.map((p) => ({
    code: p.code,
    id: p.id,
    label: p.description
  }));
};

export const getAllProductsLevelSkuByCode = async ({ codeList = [], retailerId, supplierId }) => {
  const total = codeList.length;
  const limit = 100;
  const params = {
    'code.in': codeList.slice(0, limit),
    'retailerId.equals': retailerId,
    'supplierId.in': supplierId,
    page: 0,
    size: limit
  };

  const response = await apiCall({
    url: '/products',
    params
  });
  if (!response) return false;

  let allProductsResponse;
  let allProducts = [...response.data];

  if (total > limit) {
    let promises = [];
    const counterMax = Math.floor(total / limit);
    let counter = 1;

    do {
      promises.push(
        apiCall({
          url: '/products',
          params: { ...params, 'code.in': codeList.slice(counter * limit, counter * limit + limit) }
        })
      );
      counter++;
    } while (counter <= counterMax);

    allProductsResponse = await Promise.all(promises);
    if (!allProductsResponse.every((res) => !!res)) return false;
    allProducts = allProducts.concat(...allProductsResponse.map((res) => [...res.data]));
  }

  return allProducts.map((p) => ({
    code: p.code,
    id: p.id,
    label: p.description
  }));
};

/*** Products ***/

export const getAllProducts = async ({ brandIds, retailerId, supplierId }) => {
  const size = 2000;
  const params = {
    'brandId.in': brandIds,
    'retailerId.equals': retailerId,
    'supplierId.in': supplierId,
    page: 0,
    size
  };

  const response = await apiCall({
    url: '/products',
    params
  });
  if (!response) return false;

  const productsCount = response.headers['x-total-count'];
  let allProductsResponse;
  let allProducts = [...response.data];

  if (productsCount > response.data.length) {
    let promises = [];
    const pageCountMax = Math.floor(productsCount / size);
    let pageCount = 1;

    do {
      promises.push(
        apiCall({
          url: '/products',
          params: { ...params, page: pageCount }
        })
      );
      pageCount++;
    } while (pageCount <= pageCountMax);

    allProductsResponse = await Promise.all(promises);
    if (!allProductsResponse.every((res) => !!res)) return false;
    allProducts = allProducts.concat(...allProductsResponse.map((res) => [...res.data]));
  }

  const payload = allProducts.map((product) => ({
    averagePrice: product.averagePrice,
    customProductId: product.customProductId,
    ean: product.code,
    id: product.id,
    label: product.description,
    productLevelFourthCode: product.productLevelFourth.code
  }));

  dispatch({
    type: 'PRODUCTS_UPDATE',
    payload: {
      listAll: payload
    }
  });
  return payload;
};

export const getProducts = async ({
  retailerId,
  supplierId,
  sort,
  order,
  page,
  brandIds,
  categoryId,
  keyword,
  size = 30,
  exactEanSearch = false
}) => {
  dispatch({
    type: 'PRODUCTS_UPDATE',
    payload: { loading: true }
  });

  const params = {
    page,
    size,
    sort: sort ? [sort, order] : undefined,
    'brandId.in': brandIds,
    [`codeOrDescription.${exactEanSearch ? 'equals' : 'contains'}`]: keyword,
    'retailerId.equals': retailerId,
    'supplierId.equals': supplierId,
    'productLevelFourthId.equals': categoryId
  };

  let response = await apiCall({
    url: '/products',
    params
  });
  if (!response) return false;

  const productsCount = response.headers['x-total-count'];
  if (page === undefined && productsCount > response.data.length) {
    response = await apiCall({
      url: '/products',
      params: {
        ...params,
        size: productsCount
      }
    });
  }
  if (!response) return false;

  const products = response.data.map((p) => ({
    averagePrice: p.averagePrice,
    customProductId: p.customProductId,
    ean: p.code,
    id: p.id,
    label: p.description,
    productLevelFourthCode: p.productLevelFourth.code
  }));

  const payload = Object.fromEntries(
    [
      ['list', !keyword ? products : undefined],
      ['searchResultList', keyword !== undefined ? products : undefined],
      ['total', !keyword ? parseInt(response.headers['x-total-count'], 10) ?? 0 : undefined],
      ['loading', false]
    ].filter((el) => el[1] !== undefined) // remove undefined entries
  );

  dispatch({
    type: 'PRODUCTS_UPDATE',
    payload
  });
  return payload;
};

export const getProductsByKeyword = async ({
  retailerId,
  supplierId,
  sort = 'description',
  order = 'asc',
  page = 0,
  brandId,
  categoryId,
  keyword
}) => {
  const params = {
    page,
    size: 30,
    sort: [sort, order],
    'brandId.equals': brandId,
    'codeOrDescription.contains': keyword,
    'productLevelFourthId.equals': categoryId,
    'retailerId.equals': retailerId,
    'supplierId.in': supplierId
  };

  const response = await apiCall({
    url: '/products',
    params
  });
  if (!response) return false;

  return {
    list: response.data.map((p) => ({
      averagePrice: p.averagePrice,
      customProductId: p.customProductId,
      ean: p.code,
      id: p.id,
      label: p.description,
      productLevelFourthCode: p.productLevelFourth.code
    })),
    total: parseInt(response.headers['x-total-count'], 10)
  };
};

export const getProductsCount = async ({ brandIds, categoryId, codeList, retailerId, supplierId }) => {
  const response = await apiCall({
    url: `/products/count`,
    params: {
      'brandId.in': brandIds,
      'code.in': codeList?.slice(0, 250),
      'productLevelFourthId.equals': categoryId,
      'retailerId.equals': retailerId,
      'supplierId.in': supplierId
    }
  });

  if (!response) return false;
  return response.data;
};

export const getExternalSupplierProducts = async ({ retailer, externalSupplierId, size = 30, page }) => {
  const optionalParams = {
    page: page,
    size
  };

  let response = await apiCall({
    url: '/products',
    params: {
      'retailerId.equals': retailer,
      'externalSupplierId.equals': externalSupplierId,
      ...optionalParams
    }
  });
  if (!response) return false;

  const productsCount = response.headers['x-total-count'];

  const productsBrut = response.data.map((p) => ({
    averagePrice: p.averagePrice,
    customProductId: p.customProductId,
    ean: p.code,
    id: p.id,
    label: p.description,
    productLevelFourthCode: p.productLevelFourth.code
  }));
  const products = [];
  productsBrut.forEach((i) => {
    if (!products.filter((j) => j.ean === i.ean).length) {
      products.push(i);
    }
  });

  const payload = Object.fromEntries(
    [
      ['list', page !== undefined ? products : undefined],
      ['listAll', page !== undefined ? undefined : products],
      ['total', page !== undefined ? parseInt(productsCount, 10) ?? 0 : undefined]
    ].filter((el) => el[1] !== undefined) // remove undefined entries
  );

  return payload;
};

/*** Segments ***/

export const getSegmentDetails = async (segmentId) => {
  const response = await apiCall({
    baseUrl: apiUrlSegmentManager,
    url: `/segments/${segmentId}`
  });
  if (!response) return false;

  dispatch({
    type: 'SEGMENTS_UPDATE',
    payload: { details: response.data || {} }
  });
  return response.data;
};

export const getSegmentDetailsLinkedCampaigns = async ({ segmentId, scope = segmentScopes.CAMPAIGN }) => {
  const getScopeCampaignList = async () => {
    const promises = [
      apiCall({
        url: '/retailer-segment-campaigns',
        params: { 'externalSegmentId.equals': segmentId, size: 2000 }
      }),
      apiCall({
        url: '/cash-coupon-campaigns',
        params: { 'externalSegmentId.equals': segmentId, size: 2000 }
      })
    ];
    const [campaignProductListWithThisSegment, campaignBRListWithThisSegment] = await Promise.all(promises);
    if (!campaignProductListWithThisSegment || !campaignBRListWithThisSegment) return false;

    return [
      ...campaignProductListWithThisSegment.data.map((el) => el.campaign),
      ...campaignBRListWithThisSegment.data.map((el) => el.campaign)
    ].sort((a, b) => new Date(b.validityStartDate) - new Date(a.validityStartDate));
  };

  const getScopeOfferList = async () => {
    const campaignProductListWithThisSegment = await apiCall({
      url: '/offer-campaigns',
      params: { 'offerOfferHeadExternalSegmentId.equals': segmentId, size: 2000 }
    });
    if (!campaignProductListWithThisSegment) return false;

    return campaignProductListWithThisSegment.data
      .map((el) => el.campaign)
      .sort((a, b) => new Date(b.validityStartDate) - new Date(a.validityStartDate));
  };

  return segmentScopes.CAMPAIGN === scope ? await getScopeCampaignList() : await getScopeOfferList();
};

export const getSegmentDetailsLinkedOffers = async (segmentId) => {
  const response = await apiCall({
    url: '/offers',
    params: {
      'frozen.equals': false,
      'offerHeadExternalSegmentId.equals': segmentId,
      'validityStartDate.greaterThanOrEqual': moment().startOf('year').utc().format(),
      size: 2000
    }
  });
  if (!response) return false;

  return response.data.sort((a, b) => new Date(b.validityStartDate) - new Date(a.validityStartDate));
};

/* */
/* @retailerCode required */
/* Use @page for fetching paginated list */
/* Without @page for fetching all segments list */
/* This endpoint does not have orderBy / sortBy params */
/* */
export const getSegments = async ({
  filters = {
    archived: [false],
    segmentUpdatingStatus: [segmentUpdatingStatuses.SUCCESS]
  },
  page,
  retailerCode,
  scope,
  size = 30,
  supplierCodes
}) => {
  const hasIdFilter =
    (filters.id && !Array.isArray(filters.id)) || (filters.id && Array.isArray(filters.id) && filters.id?.length);

  const filtersParams = {
    id: filters.id,
    archived: !hasIdFilter && filters.archived?.length === 1 ? filters.archived : undefined,
    name: !hasIdFilter && filters.title?.length ? filters.title : undefined,
    scope: !hasIdFilter && (scope || undefined),
    type: !hasIdFilter && filters.segmentType?.length ? filters.segmentType : undefined,
    updatingStatus: !hasIdFilter && filters.segmentUpdatingStatus?.length ? filters.segmentUpdatingStatus : undefined
  };

  const params = {
    page,
    retailerCode,
    size: page === undefined ? 2000 : size,
    ...filtersParams,
    supplierCode: supplierCodes
  };

  let response = await apiCall({
    baseUrl: apiUrlSegmentManager,
    url: '/segments',
    params
  });
  if (!response) return false;

  const totalCount = response.headers['x-total-count'];

  // fetch all Segments
  if (page === undefined && totalCount > response.data.length) {
    response = await apiCall({
      baseUrl: apiUrlSegmentManager,
      url: '/segments',
      params: { ...params, size: totalCount }
    });
    if (!response) return false;
  }

  const payload = Object.fromEntries(
    [
      ['list', page !== undefined ? response.data : undefined],
      ['listAll', page !== undefined ? undefined : response.data],
      ['total', parseInt(totalCount, 10)]
    ].filter((el) => el[1] !== undefined) // remove undefined entries
  );

  dispatch({
    type: 'SEGMENTS_UPDATE',
    payload
  });
  return true;
};

export const checkRetailerHasAccessToSegmentEstimateCustomerCount = async (retailerCode) => {
  const response = await apiCall({
    baseUrl: apiUrlSegmentManagerEstimateCustomerCount,
    url: `/has-access?retailerCode=${retailerCode}`
  });
  if (!response) return false;
  return response.data;
};

export const getSegmentEstimateCustomerCount = async (segment) => {
  const responseData = await apiCall({
    method: 'post',
    baseUrl: apiUrlSegmentManagerEstimateCustomerCount,
    url: '/estimate-customer-count',
    data: segment
  });
  if (!responseData) return false;

  dispatch({
    type: 'SEGMENTS_UPDATE',
    payload: { estimateCustomerCount: responseData.data }
  });
  return true;
};

export const postSegment = async ({ segment, file }) => {
  const responseData = await apiCall({
    method: 'post',
    baseUrl: apiUrlSegmentManager,
    url: '/segments',
    data: { ...segment, type: file ? segmentTypes.IMPORT : segmentTypes.STUDIO }
  });
  if (!responseData) return false;

  if (file) {
    await updateSegmentFile({ file, segmentId: responseData.data.id });
  }

  return true;
};

export const updateOneSegment = async ({ segment, file }) => {
  const responseData = await apiCall({
    method: 'put',
    baseUrl: apiUrlSegmentManager,
    url: '/segments',
    data: segment
  });
  if (!responseData) return false;

  if (file) {
    await updateSegmentFile({ file, segmentId: segment.id });
  }
  return true;
};

export const updateSegments = async (retailerSegments) => {
  const promises = [
    ...retailerSegments.map((retailerSegment) =>
      apiCall({ method: 'put', baseUrl: apiUrlSegmentManager, url: '/segments', data: retailerSegment })
    )
  ];

  const responses = await Promise.all(promises);
  if (!responses) return false;
  const isOK = responses.every((res) => res.status < 300);
  if (!isOK) return false;

  dispatch({
    type: 'UI_SNACKBAR',
    payload: {
      type: snackbarTypes.SUCCESS,
      title: {
        key: `segment_${retailerSegments[0].archived ? 'archive' : 'restore'}_success${
          retailerSegments.length > 1 ? '_plural' : ''
        }`
      }
    }
  });

  return true;
};

export const updateSegmentFile = async ({ file, segmentId }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('contentType', 'text/csv');
  formData.append('importedAt', moment().utc().format());

  const response = await apiCall({
    method: 'put',
    baseUrl: apiUrlSegmentManager,
    url: `/import_segment/${segmentId}`,
    data: formData,
    contentType: 'multipart/form-data'
  });
  return !!response;
};

export const downloadErrorImportFile = async (segmentId) => {
  const response = await apiCall({
    baseUrl: apiUrlSegmentManager,
    url: `/segments/${segmentId}/import-errors-file`,
    responseType: 'text/CSV'
  });
  if (!response) return false;

  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(new Blob([response.data]));
  a.target = '_self';
  a.download = 'import_errors.csv';
  a.click();
  a.remove();

  return true;
};

/*** Stores ***/

export const getAllStores = async () => {
  let response = [];
  const params = { size: 2000 };

  response = await apiCall({
    url: '/stores',
    params
  });
  if (!response) return false;

  const count = response.headers['x-total-count'];
  if (count > response.data.length) {
    response = await apiCall({
      url: '/stores',
      params: { ...params, size: count }
    });
  }
  if (!response) return false;

  return response.data.map((el) => ({
    code: el.code,
    id: el.id,
    label: el.name
  }));
};

export const getAllStoresByCode = async ({ codeList = [] }) => {
  let response = [];
  if (codeList.length) {
    const params = { 'code.in': codeList, size: 2000 };

    response = await apiCall({
      url: '/stores',
      params
    });
    if (!response) return false;

    const count = response.headers['x-total-count'];
    if (count > response.data.length) {
      response = await apiCall({
        url: '/stores',
        params: { ...params, size: count }
      });
    }
    if (!response) return false;
  }

  return response.data.map((el) => ({
    code: el.code,
    id: el.id,
    label: el.name
  }));
};

export const getStores = async ({ keyword, order = 'asc', page = 0, sort = 'code' }) => {
  const params = {
    'code.contains': keyword,
    page,
    size: 30,
    sort: `${sort},${order}`
  };
  const response = await apiCall({ url: '/stores', params });
  if (!response) return false;

  return {
    list: response.data.map((el) => ({
      code: el.code,
      id: el.id,
      label: el.name
    })),
    total: parseInt(response.headers['x-total-count'], 10)
  };
};

export const getStoresCount = async () => {
  const response = await apiCall({ url: '/stores/count' });
  if (!response) return false;

  return response.data;
};

/*** Suppliers ***/

export const getSupplierBudgetsCount = async ({ retailerId, filters = {}, year = currentYear }) => {
  const filtersParams = {
    'supplierId.in': filters.supplierId?.length ? filters.supplierId : undefined,
    'supplierUserManagerId.in':
      !filters.supplierId?.length && !!filters.userManagerId?.length ? filters.userManagerId : undefined
  };
  const response = await apiCall({
    url: '/supplier-budgets/count',
    params: { 'retailerId.equals': retailerId, 'year.equals': year, ...filtersParams }
  });
  if (!response) return false;
  return response.data;
};

export const getAllSupplierBudgets = async ({
  retailerId,
  supplierIds = [],
  total,
  universes = [],
  userManagerId = [],
  year
}) => {
  const params = {
    'retailerId.equals': retailerId,
    'supplierId.in': supplierIds,
    'supplierUniverse.in': universes,
    'supplierUserManagerId.in': userManagerId,
    'total.greaterThan': total ?? undefined,
    'year.in': year
  };
  let response = await apiCall({
    url: '/supplier-budgets',
    params: { ...params, size: 200 }
  });
  if (!response) return false;

  const count = response.headers['x-total-count'];

  if (count > response.data.length) {
    response = await apiCall({
      url: '/supplier-budgets',
      params: { ...params, size: count, page: 0 }
    });
  }

  // fix negative free budget
  const payload = response.data.map((b) => ({ ...b, free: Math.max(0, b.free) }));

  dispatch({ type: 'SUPPLIER_BUDGETS_ALL', payload });

  if (!payload.length) {
    dispatch({
      type: 'OFFERS_UPDATE',
      payload: { total: 0, list: [] }
    });
  }

  return payload;
};

export const getSupplierPreviousBudgets = async ({ retailerId, supplierId }) => {
  const params = {
    'retailerId.equals': retailerId,
    'supplierId.equals': supplierId
  };
  const pastYearLimit = 2021;
  const promises = [];
  for (let i = pastYearLimit; i < currentYear; i++) {
    promises.push(
      apiCall({
        url: '/supplier-budgets',
        params: { ...params, 'year.equals': i }
      })
    );
  }

  const responses = await Promise.all(promises);
  if (!responses.every((res) => !!res)) return false;

  // reverse data to sort by the closest years first
  // flat because data is a list and can contain digital and paper budgets elements
  // map at the end to fix negative free budgets
  const budgets = responses
    .map((rep) => rep.data)
    .reverse()
    .flat()
    .map((b) => ({ ...b, free: Math.max(0, b.free) }));

  return budgets;
};

/*** TargetingStrategies - available for all retailers ***/
export const getTargetingStrategies = async () => {
  const response = await apiCall({
    url: `/targeting-strategies`,
    params: { 'deprecated.equals': false }
  });
  if (!response) return false;

  dispatch({
    type: 'GET_TARGETING_STRATEGIES',
    payload: {
      targetingStrategies: response.data.filter((st) => st.code !== 'UNTARGETABLE'),
      targetingStrategiesWithUntargetable: response.data
    }
  });
  return true;
};

/*** Visuals ***/
export const getCampaignVisualFile = async (campaignId) => {
  const { data: files } = await apiCall({
    url: `/campaigns/${campaignId}/files`
  });

  return Array.isArray(files) ? files[0] : undefined;
};

export const generateCampaignVisualFile = async (campaignId) => {
  const response = await apiCall({
    url: `/campaigns/${campaignId}/files/generate`
  });

  if (!response) return false;

  return true;
};

export const downloadVisualFile = async (fileId) => {
  return downloadFile(`/campaigns/files/${fileId}/download`);
};

/** RETAILERS **/
export const getGuidelinesByRetailerId = async (retailerId) => {
  const response = await apiCall({
    url: `/guidelines?retailerId.equals=${retailerId}`
  });
  if (!response) return false;

  const getGuidelinesByLanguage = (language) => response.data.filter((element) => element.language === language);

  const mapGuideLinesByLanguage = (language) => {
    let result = {};

    for (const { type, content, id } of getGuidelinesByLanguage(language)) {
      result[snakeToCamel(type)] = { content, type, id };
    }

    return result;
  };

  return {
    FR: mapGuideLinesByLanguage('FR'),
    EN: mapGuideLinesByLanguage('EN')
  };
};

export const getAllRetailers = async (supplierId) => {
  let response = await apiCall({
    url: '/retailers',
    params: { size: 2000, 'supplierId.equals': supplierId }
  });
  if (!response) return false;

  const count = response.headers['x-total-count'];
  if (count > response.data.length) {
    response = await apiCall({
      url: '/retailers',
      params: { size: count }
    });
    if (!response) return false;
  }

  dispatch({
    type: 'GET_ALL_RETAILERS',
    payload: response.data
  });
  return true;
};

export const getRetailerDetails = async (retailerId) => {
  const retailerResponse = await apiCall({
    url: `/retailers/${retailerId}`
  });

  if (!retailerResponse) return false;

  const retailerNotificationResponse = await apiCall({
    url: `/retailers/${retailerId}/notification-information`,
    statusCodesToSkip: [404]
  });

  const guidelines = await getGuidelinesByRetailerId(retailerId);

  dispatch({
    type: 'GET_RETAILER_DETAILS',
    payload: {
      ...retailerResponse.data,
      notificationInformationRetailer: retailerNotificationResponse?.data?.find((el) => el.type === 'RETAILER'),
      notificationInformationSupplier: retailerNotificationResponse?.data?.find((el) => el.type === 'SUPPLIER'),
      guidelines
    }
  });
  return true;
};

export const getRetailerNotificationInformation = async (retailerId) => {
  const response = await apiCall({
    url: `/retailers/${retailerId}/notification-information`,
    statusCodesToSkip: [404]
  });
  if (!response) return false;

  return response.data;
};

export const getRetailers = async ({ page, size = 30, orderBy = 'asc', sortBy = 'code', filters }) => {
  const filtersParams = {
    'type.in': filters.retailerType
  };
  const params = { ...filtersParams, page, size, sort: sortBy ? `${sortBy},${orderBy}` : undefined };
  const response = await apiCall({
    url: '/retailers',
    params
  });
  if (!response) return false;

  dispatch({
    type: 'RETAILERS_UPDATE',
    payload: {
      page: page ?? 0,
      total: parseInt(response.headers['x-total-count'], 10) ?? 0,
      list: response.data
    }
  });
  return true;
};

export const checkRetailerExistsByCode = async (code) => {
  const response = await apiCall({ url: '/retailers', params: { 'code.equals': code } });

  if (!response) return false;

  return Array.isArray(response.data) && response.data.length > 0;
};

const postGuidelines = async (guidelines, retailerId) => {
  if (guidelines.removed.length > 0) {
    const emptyGuidelinesIds = guidelines.removed.map((guideline) => guideline.id);

    const deleteGuidelinesById = async (ids) => {
      const apiCalls = ids.map(async (id) => {
        return await apiCall({
          method: 'delete',
          url: `/guidelines/${id}`
        });
      });

      return Promise.all(apiCalls);
    };

    const deletedGuidelines = await deleteGuidelinesById(emptyGuidelinesIds);

    if (!deletedGuidelines) return false;
  }

  const mapGuidelines = (guidelines) =>
    guidelines.map((guideline) => ({
      ...guideline,
      retailer: { id: retailerId }
    }));

  if (guidelines.modified.length > 0) {
    const postModifiedGuidelines = await apiCall({
      method: 'put',
      url: '/guidelines',
      data: mapGuidelines(guidelines.modified)
    });

    if (!postModifiedGuidelines) return false;
  }

  if (guidelines.created.length > 0) {
    const putCreatedGuidelines = await apiCall({
      method: 'post',
      url: '/guidelines',
      data: mapGuidelines(guidelines.created)
    });

    if (!putCreatedGuidelines) return false;
  }

  return true;
};

export const postRetailer = async ({
  isCreation,
  data,
  notificationPayload,
  retailer,
  guidelines,
  technicalsFees,
  positionedTargetsLevels
}) => {
  const retailerMethod = isCreation ? 'post' : 'put';
  let newRetailer = JSON.parse(JSON.stringify(data));
  newRetailer.logo = data.logo;

  //Post new retailer logo if existing
  if (newRetailer.logo?.id) {
    newRetailer.logo = { id: newRetailer.logo.id };
  } else if (newRetailer.logo) {
    const formData = new FormData();

    formData.append('file', newRetailer.logo);
    formData.append('name', 'retailersImg');
    formData.append('type', 'IMG');
    formData.append('logo', 'true');

    const postFile = await apiCall({
      method: 'post',
      url: '/files',
      data: formData,
      contentType: 'multipart/form-data'
    });
    if (!postFile) return false;

    newRetailer.logo = { id: postFile.data.find((el) => el.format === 'ORIGINAL')?.id };
  }

  //Post or Put retailer
  if (!isCreation) {
    newRetailer.id = retailer?.id;
  }

  const postOrPutRetailer = await apiCall({
    method: retailerMethod,
    url: '/retailers',
    data: newRetailer
  });

  if (!postOrPutRetailer) return false;

  const retailerId = postOrPutRetailer.data.id;

  // Post or Put notification information
  if (isCreation || (!retailer?.notificationInformationRetailer && !retailer?.notificationInformationSupplier)) {
    if (data.notifyRetailersEnabled || data.notifySuppliersEnabled) {
      const notificationInformationResponse = await apiCall({
        method: 'post',
        url: `retailers/${retailerId}/notification-information/bulk`,
        data: notificationPayload
      });
      if (!notificationInformationResponse) return false;
    }
  } else {
    if (data.notifyRetailersEnabled) {
      const notificationPayloadRetailer = objectOrderedKeys(notificationPayload.find((el) => el.type === 'RETAILER'));
      const notificationInformationRetailer = retailer.notificationInformationRetailer
        ? objectOrderedKeys(retailer.notificationInformationRetailer)
        : null;

      if (JSON.stringify(notificationPayloadRetailer) !== JSON.stringify(notificationInformationRetailer)) {
        const notificationInformationResponse = await apiCall({
          method: notificationInformationRetailer ? 'put' : 'post',
          url: `retailers/${retailerId}/notification-information`,
          data: notificationPayloadRetailer
        });
        if (!notificationInformationResponse) return false;
      }
    }
    if (data.notifySuppliersEnabled) {
      const notificationPayloadSupplier = objectOrderedKeys(notificationPayload.find((el) => el.type === 'SUPPLIER'));
      const notificationInformationSupplier = retailer.notificationInformationSupplier
        ? objectOrderedKeys(retailer.notificationInformationSupplier)
        : null;

      if (JSON.stringify(notificationPayloadSupplier) !== JSON.stringify(notificationInformationSupplier)) {
        const notificationInformationResponse = await apiCall({
          method: notificationInformationSupplier ? 'put' : 'post',
          url: `retailers/${retailerId}/notification-information`,
          data: notificationPayloadSupplier
        });
        if (!notificationInformationResponse) return false;
      }
    }
    if (!data.notifySuppliersEnabled && retailer?.notificationInformationSupplier) {
      const deleteNotificationInformationResponse = await apiCall({
        method: 'delete',
        url: `retailers/${retailerId}/notification-information/${retailer?.notificationInformationSupplier.id}`
      });
      if (!deleteNotificationInformationResponse) return false;
    }
    if (!data.notifyRetailersEnabled && retailer?.notificationInformationRetailer) {
      const deleteNotificationInformationResponse = await apiCall({
        method: 'delete',
        url: `retailers/${retailerId}/notification-information/${retailer?.notificationInformationRetailer.id}`
      });
      if (!deleteNotificationInformationResponse) return false;
    }
  }

  // update guidelines
  const postGuidelinesResponse = await postGuidelines(guidelines, retailerId);
  if (!postGuidelinesResponse) return false;

  // update technical fees
  const updateTechnicalFeesResponse = technicalsFees?.length
    ? await apiCall({
        method: 'patch',
        url: `/retailers/${retailerId}/technicals-fees`,
        data: technicalsFees
      })
    : true;
  if (!updateTechnicalFeesResponse) return false;

  // update positionedTargetsLevels
  const updatePositionedTargetsLevelsResponse = positionedTargetsLevels?.length
    ? await apiCall({
        method: 'patch',
        url: `/retailers/${retailerId}/positioned-targets-levels`,
        data: positionedTargetsLevels
      })
    : true;
  if (!updatePositionedTargetsLevelsResponse) return false;

  return true;
};

export const getNotificationTemplates = async () => {
  const response = await apiCall({
    url: '/campaigns/available-notification-templates'
  });
  if (!response) return false;

  dispatch({
    type: 'NOTIFICATION_TEMPLATES_UPDATE',
    payload: response.data
  });
  return true;
};

export const getTimezones = async () => {
  const response = await apiCall({
    url: '/timezones'
  });
  if (!response) return false;

  dispatch({
    type: 'TIMEZONES_UPDATE',
    payload: response.data
  });
  return true;
};

export const getCurrencies = async () => {
  const response = await apiCall({
    url: '/currencies'
  });
  if (!response) return false;

  dispatch({
    type: 'CURRENCIES_UPDATE',
    payload: response.data
  });
  return true;
};

export const getCountries = async (locale = 'en') => {
  const response = await apiCall({
    url: `/countries?locale=${locale.replace('-', '_')}&sort=name,asc`
  });
  if (!response) return false;

  dispatch({
    type: 'COUNTRIES_UPDATE',
    payload: response.data
  });
  return true;
};

export const getLocales = async () => {
  const response = await apiCall({
    url: '/locales'
  });
  if (!response) return false;

  dispatch({
    type: 'LOCALES_UPDATE',
    payload: response.data
  });
  return true;
};

/** SUPPLIERS **/

// when user is a Supplier, DO NOT provide retailerId
export const getAllSuppliers = async (retailerId) => {
  let response = [];
  const params = { 'retailerId.equals': retailerId, 'status.equals': 'VALIDATED', size: 2000 };

  response = await apiCall({
    url: '/suppliers',
    params
  });
  if (!response) return false;

  const count = response.headers['x-total-count'];
  if (count > response.data.length) {
    response = await apiCall({
      url: '/suppliers',
      params: { ...params, size: count }
    });
  }
  if (!response) return false;

  const payload = response.data.map((item) => ({ ...item, label: item.name }));
  dispatch({
    type: 'INIT_SUPPLIERS_UPDATE',
    payload
  });
  return payload;
};

export const getAllSuppliersByCode = async ({ codeList = [], retailerId }) => {
  let response = [];
  if (codeList.length) {
    const params = { 'code.in': codeList, 'retailerId.equals': retailerId, 'status.equals': 'VALIDATED', size: 2000 };

    response = await apiCall({
      url: '/suppliers',
      params
    });
    if (!response) return false;

    const count = response.headers['x-total-count'];
    if (count > response.data.length) {
      response = await apiCall({
        url: '/suppliers',
        params: { ...params, size: count }
      });
    }
    if (!response) return false;
  }

  return response.data.map((el) => ({
    code: el.code,
    id: el.id,
    label: el.name
  }));
};

export const getSuppliers = async ({ keyword, order = 'asc', page = 0, retailerId, sort = 'name' }) => {
  const params = {
    'status.equals': 'VALIDATED',
    'retailerId.equals': retailerId,
    'name.contains': keyword,
    page,
    size: 30,
    sort: `${sort},${order}`
  };
  const response = await apiCall({
    url: '/suppliers',
    params
  });
  if (!response) return false;

  return {
    list: response.data.map((el) => ({ code: el.code, id: el.id, label: el.name })),
    total: parseInt(response.headers['x-total-count'], 10)
  };
};

export const getSuppliersCount = async (retailerId) => {
  const response = await apiCall({
    url: '/suppliers',
    params: { 'retailerId.equals': retailerId, 'status.equals': 'VALIDATED', size: 1 }
  });
  if (!response) return false;

  return parseInt(response.headers['x-total-count'], 10);
};

export const getSuppliersAndBudgets = async ({
  retailer,
  page,
  size = 30,
  orderBy = 'asc',
  sortBy = 'id',
  filters = {}
}) => {
  const hasIdFilter =
    (filters.supplierId && !Array.isArray(filters.supplierId)) ||
    (filters.supplierId && Array.isArray(filters.supplierId) && filters.supplierId?.length);

  const filtersParams = {
    'id.in': filters.supplierId?.length ? filters.supplierId.join(',') : undefined,
    [`currentYearBudgetSpent.${filters.budgetSpent?.[0]}`]:
      !hasIdFilter && filters.budgetSpent?.[1] ? filters.budgetSpent[1] : undefined,
    [`currentYearBudgetTotal.${filters.currentYearBudget?.[0]}`]:
      !hasIdFilter && filters.currentYearBudget?.[1] ? filters.currentYearBudget[1] : undefined,
    [`nextYearBudgetTotal.${filters.nextYearBudget?.[0]}`]:
      !hasIdFilter && filters.nextYearBudget?.[1] ? filters.nextYearBudget[1] : undefined,
    'userManagerId.in': !hasIdFilter && !!filters.userManagerId?.length ? filters.userManagerId : undefined
  };

  const params = {
    'status.equals': 'VALIDATED',
    page,
    size,
    sort: sortBy ? `${sortBy},${orderBy}` : undefined,
    ...filtersParams
  };

  const response = await apiCall({
    url: '/suppliers',
    params
  });
  if (!response) return false;

  const budgetsPromises = response.data.map((supplier) => {
    const params = {
      'retailerId.equals': retailer,
      'supplierId.equals': supplier.id,
      'year.equals': currentYear
    };
    return apiCall({
      url: '/supplier-budgets',
      params
    });
  });

  let currentBudgets = await Promise.all(budgetsPromises);
  if (!currentBudgets) return false;

  const nextBudgetsPromises = response.data.map((supplier) => {
    const params = {
      'retailerId.equals': retailer,
      'supplierId.equals': supplier.id,
      'year.equals': currentYear + 1
    };
    return apiCall({
      url: '/supplier-budgets',
      params
    });
  });

  let nextBudgets = await Promise.all(nextBudgetsPromises);
  if (!nextBudgets) return false;

  // fix negative free budget
  currentBudgets = currentBudgets.map((resp) =>
    resp.data.map((budget) => ({ ...budget, free: Math.max(0, budget.free) }))
  );
  nextBudgets = nextBudgets.map((resp) => resp.data.map((budget) => ({ ...budget, free: Math.max(0, budget.free) })));

  const suppliers = response.data.map((s, index) => {
    //build budgets for each suppliers
    const budgetDigital = currentBudgets[index]?.find((b) => b.type === supplierBudgetTypes.DIGITAL);
    const nextYearBudgetDigital = nextBudgets[index]?.find((b) => b.type === supplierBudgetTypes.DIGITAL);
    const budgetPaper = currentBudgets[index]?.find((b) => b.type === supplierBudgetTypes.PAPER);
    const nextYearBudgetPaper = nextBudgets[index]?.find((b) => b.type === supplierBudgetTypes.PAPER);
    let budgets = [];

    if (budgetDigital || nextYearBudgetDigital) {
      budgets.push({
        type: supplierBudgetTypes.DIGITAL,
        budgetN: budgetDigital || { total: '' },
        budgetN1: nextYearBudgetDigital || { total: '' }
      });
    }
    if (budgetPaper || nextYearBudgetPaper) {
      budgets.push({
        type: supplierBudgetTypes.PAPER,
        budgetN: budgetPaper || { total: '' },
        budgetN1: nextYearBudgetPaper || { total: '' }
      });
    }

    return {
      ...s,
      budgets,
      budgetTypes: [
        ...new Set([
          currentBudgets[index][0]?.type,
          currentBudgets[index][1]?.type,
          nextBudgets[index][0]?.type,
          nextBudgets[index][1]?.type
        ])
      ].filter((el) => !!el)
    };
  });

  dispatch({
    type: 'SUPPLIERS_UPDATE',
    payload: {
      page: page ?? 0,
      total: parseInt(response.headers['x-total-count'], 10) ?? 0,
      list: suppliers ?? []
    }
  });
  return true;
};

export const getSuppliersExportFile = async ({ extension, filters = {}, orderBy = 'asc', sortBy = 'id' }) => {
  const hasIdFilter =
    (filters.supplierId && !Array.isArray(filters.supplierId)) ||
    (filters.supplierId && Array.isArray(filters.supplierId) && filters.supplierId?.length);

  const filtersParams = {
    'id.in': filters.supplierId?.length ? filters.supplierId.join(',') : undefined,
    [`currentYearBudgetSpent.${filters.budgetSpent?.[0]}`]:
      !hasIdFilter && filters.budgetSpent?.[1] ? filters.budgetSpent[1] : undefined,
    [`currentYearBudgetTotal.${filters.currentYearBudget?.[0]}`]:
      !hasIdFilter && filters.currentYearBudget?.[1] ? filters.currentYearBudget[1] : undefined,
    [`nextYearBudgetTotal.${filters.nextYearBudget?.[0]}`]:
      !hasIdFilter && filters.nextYearBudget?.[1] ? filters.nextYearBudget[1] : undefined
  };

  const params = {
    sort: `${sortBy},${orderBy}`,
    'status.equals': 'VALIDATED',
    ...filtersParams
  };

  await downloadFile(`suppliers/export/${extension}`, params);
  return true;
};

export const validSuppliers = async ({ supplierIds = [] }) => {
  const response = await apiCall({
    method: 'PATCH',
    data: supplierIds.map((i) => ({ id: i, status: 'VALIDATED' })),
    url: `/suppliers/bulk`
  });

  if (!response) return false;

  dispatch({
    type: 'UI_SNACKBAR',
    payload: {
      type: snackbarTypes.SUCCESS,
      title: { key: 'supplier_added', params: { supplierCount: supplierIds.length } }
    }
  });

  return true;
};

export const deleteSupplier = async (supplierId) => {
  const response = await apiCall({ method: 'delete', url: `/suppliers/${supplierId}` });
  return response;
};

export const getExternalSupplier = async (keyword) => {
  const response = await apiCall({
    url: `/external-suppliers?codeOrName.contains=${keyword}`
  });

  if (!response) return false;

  return response.data;
};

export const getExternalSupplierSuppliers = async (id) => {
  const response = await apiCall({
    url: `/suppliers/${id}/external-suppliers`
  });

  if (!response) return false;

  return response.data;
};

export const getProductsCountBySupplier = async (supplier) => {
  const response = await apiCall({
    url: '/products/count',
    params: {
      'retailerId.equals': supplier.retailer.id,
      'externalSupplierId.equals': supplier.id
    }
  });

  if (!response) return false;

  return response.data;
};

export const getDraftSuppliers = async ({ retailerId }) => {
  const response = await apiCall({
    url: '/suppliers',
    params: {
      'status.equals': 'DRAFT',
      sort: `createdAt,desc`
    }
  });

  if (!response) return false;

  const budgetsPromises = response.data.map((supplier) => {
    const params = {
      'retailerId.equals': retailerId,
      'supplierId.equals': supplier.id,
      'year.equals': currentYear
    };
    return apiCall({
      url: '/supplier-budgets',
      params
    });
  });
  let budgetsResponses = await Promise.all(budgetsPromises);
  if (!budgetsResponses) return false;

  const nextBudgetsPromises = response.data.map((supplier) => {
    const params = {
      'retailerId.equals': retailerId,
      'supplierId.equals': supplier.id,
      'year.equals': currentYear + 1
    };
    return apiCall({
      url: '/supplier-budgets',
      params
    });
  });
  let nextYearBudgetsResponses = await Promise.all(nextBudgetsPromises);
  if (!nextYearBudgetsResponses) return false;

  // fix negative free budget
  budgetsResponses = budgetsResponses.map((resp) =>
    resp.data.map((budget) => ({ ...budget, free: Math.max(0, budget.free) }))
  );
  nextYearBudgetsResponses = nextYearBudgetsResponses.map((resp) =>
    resp.data.map((budget) => ({ ...budget, free: Math.max(0, budget.free) }))
  );

  const externalSuppliersPromises = response.data.map((supplier) =>
    apiCall({
      url: `/suppliers/${supplier.id}/external-suppliers`
    })
  );

  const externalSuppliers = await Promise.all(externalSuppliersPromises);
  if (!externalSuppliers) return false;

  const productsPromises = externalSuppliers.map((externalSupplier) =>
    Promise.all(
      externalSupplier.data.map((supplier) =>
        apiCall({
          url: '/products/count',
          params: {
            'retailerId.equals': supplier.retailer.id,
            'externalSupplierId.equals': supplier.id
          }
        })
      )
    )
  );

  const products = await Promise.all(productsPromises);
  if (!products) return false;

  const userSuppliersPromises = response.data.map((supplier) => getUserSuppliers(supplier.id));
  const userSuppliers = await Promise.all(userSuppliersPromises);
  if (!userSuppliers) return false;

  //build budgets for each suppliers
  const suppliers = response.data.map((supplier, index) => {
    const budgetDigital = budgetsResponses[index]?.find((b) => b.type === supplierBudgetTypes.DIGITAL);
    const nextYearBudgetDigital = nextYearBudgetsResponses[index]?.find((b) => b.type === supplierBudgetTypes.DIGITAL);
    const budgetPaper = budgetsResponses[index]?.find((b) => b.type === supplierBudgetTypes.PAPER);
    const nextYearBudgetPaper = nextYearBudgetsResponses[index]?.find((b) => b.type === supplierBudgetTypes.PAPER);
    let budgets = [];

    if (budgetDigital || nextYearBudgetDigital) {
      budgets.push({
        type: supplierBudgetTypes.DIGITAL,
        budgetN: budgetDigital || { total: '' },
        budgetN1: nextYearBudgetDigital || { total: '' }
      });
    }
    if (budgetPaper || nextYearBudgetPaper) {
      budgets.push({
        type: supplierBudgetTypes.PAPER,
        budgetN: budgetPaper || { total: '' },
        budgetN1: nextYearBudgetPaper || { total: '' }
      });
    }

    return {
      ...supplier,
      budgets,
      externalSuppliers: externalSuppliers[index].data || [],
      productsCount:
        products?.[index]
          .map((i) => i.data)
          .reduce((acc, val) => {
            return acc + val;
          }, 0) || [],
      userSuppliersCount: userSuppliers[index].length
    };
  });

  return suppliers;
};

export const postSupplier = async ({ data, user }) => {
  const createSupplierResponse = await apiCall({
    method: 'post',
    url: '/suppliers',
    data: {
      countryCode: user.countryCode,
      name: data.supplier_name,
      note: data.supplier_note.replaceAll('\n', '</br>'),
      retailer: {
        id: user.id
      },
      status: 'DRAFT',
      universe: data.universe || null,
      userManager: data.userManager_email ? { email: data.userManager_email } : null
    }
  });
  if (!createSupplierResponse) return false;

  const budgetsData = {
    retailer: {
      id: user.id
    },
    supplier: {
      id: createSupplierResponse.data.id
    },
    year: currentYear,
    positioned: 0,
    spent: 0,
    free: 0,
    reserved: 0,
    total: 0
  };

  if (data['budgets_fields']?.[0]?.budgetN?.total) {
    const postSupplierBudgets = await apiCall({
      method: 'post',
      url: '/supplier-budgets',
      data: {
        ...budgetsData,
        total: data['budgets_fields']?.[0]?.budgetN?.total,
        type: data['budgets_fields']?.[0]?.type
      }
    });
    if (!postSupplierBudgets) return false;
  }

  if (data['budgets_fields']?.[0]?.budgetN1?.total) {
    const postSupplierBudgetsN1 = await apiCall({
      method: 'post',
      url: '/supplier-budgets',
      data: {
        ...budgetsData,
        year: currentYear + 1,
        total: data['budgets_fields']?.[0]?.budgetN1?.total,
        type: data['budgets_fields']?.[0]?.type
      }
    });
    if (!postSupplierBudgetsN1) return false;
  }

  if (data['budgets_fields']?.[1]?.budgetN?.total) {
    const postSupplierBudgets = await apiCall({
      method: 'post',
      url: '/supplier-budgets',
      data: {
        ...budgetsData,
        total: data['budgets_fields']?.[1]?.budgetN?.total,
        type: data['budgets_fields']?.[1]?.type
      }
    });
    if (!postSupplierBudgets) return false;
  }

  if (data['budgets_fields']?.[1]?.budgetN1?.total) {
    const postSupplierBudgetsN1 = await apiCall({
      method: 'post',
      url: '/supplier-budgets',
      data: {
        ...budgetsData,
        year: currentYear + 1,
        total: data['budgets_fields']?.[1]?.budgetN1?.total,
        type: data['budgets_fields']?.[1]?.type
      }
    });
    if (!postSupplierBudgetsN1) return false;
  }

  const externalSuppliersPromises = data['product_ref_list'].linked.map((externalSupplier) => {
    return apiCall({
      method: 'post',
      url: `/external-suppliers/${externalSupplier.id}/suppliers/${createSupplierResponse.data.id}`
    });
  });

  const externalSuppliers = await Promise.all(externalSuppliersPromises);
  if (!externalSuppliers.every((res) => !!res)) return false;

  const usersList = data.users_list || [];
  if (usersList.length) {
    // create users
    const newUsers = await postUsers(usersList.map((el) => el.email));
    if (!newUsers) return false;

    const userSupplierPayload = newUsers.map((newUser) => ({
      userPlatform: {
        id: newUser.id,
        email: newUser.email
      },
      supplier: {
        id: createSupplierResponse.data.id
      }
    }));
    const attachUserSupplier = await postUserSupplier(userSupplierPayload);
    if (!attachUserSupplier) return false;
  }

  return true;
};

export const updateSupplier = async ({ data, initialBudgets, initialUserSupplierList, supplier, user }) => {
  const updateSupplierResponse = await apiCall({
    method: 'put',
    url: '/suppliers',
    data: {
      code: supplier.code,
      countryCode: user.countryCode,
      id: supplier.id,
      name: data.supplier_name,
      note: data.supplier_note.replaceAll('\n', '</br>'),
      retailer: {
        id: user.id
      },
      status: supplier.status || supplierStatuses.DRAFT,
      universe: data.universe || null,
      userManager: data.userManager_email ? { email: data.userManager_email } : null
    }
  });
  if (!updateSupplierResponse) return false;

  /* ************** budgets handler **************** */
  const budgets = data['budgets_fields'];
  let initialBudgetsClone = [];
  if (initialBudgets?.[0]?.type) {
    initialBudgetsClone.push(JSON.parse(JSON.stringify(initialBudgets[0])));
  }
  if (initialBudgets?.[1]?.type) {
    initialBudgetsClone.push(JSON.parse(JSON.stringify(initialBudgets[1])));
  }

  const budgetsDataSkeleton = {
    retailer: {
      id: user.id
    },
    supplier: {
      id: supplier.id
    },
    positioned: 0,
    spent: 0,
    free: 0,
    reserved: 0,
    total: 0
  };

  // if type change : cannot change the type directly -> delete all budgets and create new ones
  let promisesDeleteAllBudgets = [];
  const deleteAllBudgets = async () => {
    if (initialBudgetsClone?.[0]?.budgetN?.id) {
      promisesDeleteAllBudgets.push(
        apiCall({
          method: 'delete',
          url: `/supplier-budgets/${initialBudgetsClone[0].budgetN.id}`
        })
      );
    }
    if (initialBudgetsClone?.[0]?.budgetN1?.id) {
      promisesDeleteAllBudgets.push(
        apiCall({
          method: 'delete',
          url: `/supplier-budgets/${initialBudgetsClone[0].budgetN1.id}`
        })
      );
    }
    if (initialBudgetsClone?.[1]?.budgetN?.id) {
      promisesDeleteAllBudgets.push(
        apiCall({
          method: 'delete',
          url: `/supplier-budgets/${initialBudgetsClone[1].budgetN.id}`
        })
      );
    }
    if (initialBudgetsClone?.[1]?.budgetN1?.id) {
      promisesDeleteAllBudgets.push(
        apiCall({
          method: 'delete',
          url: `/supplier-budgets/${initialBudgetsClone[1].budgetN1.id}`
        })
      );
    }
  };

  if (
    (budgets?.[0]?.type && initialBudgetsClone?.[0]?.type && budgets?.[0]?.type !== initialBudgetsClone?.[0]?.type) ||
    (budgets?.[1]?.type && initialBudgetsClone?.[1]?.type && budgets?.[1]?.type !== initialBudgetsClone?.[1]?.type)
  ) {
    await deleteAllBudgets();
    initialBudgetsClone = [];
    if (promisesDeleteAllBudgets.length) {
      const responseList = await Promise.all(promisesDeleteAllBudgets);
      if (!responseList.every((res) => !!res)) return false;
    }
  }

  let promisesHandleBudgets = [];
  const handleBudgets = async () => {
    data['budgets_fields']?.forEach(async (el, index) => {
      /* --> budgetN  */
      // If it's a new budget -> post
      if (el.budgetN?.total && !initialBudgetsClone?.[index]?.budgetN?.total) {
        promisesHandleBudgets.push(
          apiCall({
            method: 'post',
            url: '/supplier-budgets',
            data: {
              ...budgetsDataSkeleton,
              year: currentYear,
              total: el.budgetN?.total,
              type: el.type
            }
          })
        );
      }
      // if budget exists and new one is different -> put
      else if (el.budgetN?.total && el.budgetN.total !== initialBudgetsClone?.[index]?.budgetN.total) {
        promisesHandleBudgets.push(
          apiCall({
            method: 'put',
            url: '/supplier-budgets',
            data: {
              ...budgetsDataSkeleton,
              id: el.budgetN?.id,
              year: currentYear,
              total: el.budgetN?.total,
              type: el.type
            }
          })
        );
      }
      // if prev budget exists and there is not a new one -> delete
      else if (!el.budgetN?.total && initialBudgetsClone?.[index]?.budgetN?.id) {
        promisesHandleBudgets.push(
          apiCall({
            method: 'delete',
            url: `/supplier-budgets/${initialBudgetsClone[index].budgetN.id}`
          })
        );
      }

      /* --> budgetN1  */
      // If it's a new budget -> post
      if (el.budgetN1?.total && !initialBudgetsClone?.[index]?.budgetN1?.total) {
        promisesHandleBudgets.push(
          apiCall({
            method: 'post',
            url: '/supplier-budgets',
            data: {
              ...budgetsDataSkeleton,
              year: currentYear + 1,
              total: el.budgetN1?.total,
              type: el.type
            }
          })
        );
      }
      // if budget exists and new one is different -> put
      else if (el.budgetN1?.total && el.budgetN1.total !== initialBudgetsClone?.[index]?.budgetN1.total) {
        promisesHandleBudgets.push(
          apiCall({
            method: 'put',
            url: '/supplier-budgets',
            data: {
              ...budgetsDataSkeleton,
              id: el.budgetN1?.id,
              year: currentYear + 1,
              total: el.budgetN1?.total,
              type: el.type
            }
          })
        );
      }
      // if prev budget exists and there is not a new one -> delete
      else if (!el.budgetN1?.total && initialBudgetsClone?.[index]?.budgetN1?.id) {
        promisesHandleBudgets.push(
          apiCall({
            method: 'delete',
            url: `/supplier-budgets/${initialBudgetsClone[index].budgetN1.id}`
          })
        );
      }
    });
  };

  if (data['budgets_fields']?.length) {
    await handleBudgets();
    if (promisesHandleBudgets.length) {
      const responseList = await Promise.all(promisesHandleBudgets);
      if (!responseList.every((res) => !!res)) return false;
    }
  }
  /* ************** budgets handler END **************** */

  const externalSuppliersPromises = [];
  data['product_ref_list'].linked.forEach((externalSupplier) => {
    if (data['product_ref_list'].alreadyLinked.find((it) => it.id === externalSupplier.id)) {
      return;
    }

    externalSuppliersPromises.push(
      apiCall({
        method: 'post',
        url: `/external-suppliers/${externalSupplier.id}/suppliers/${supplier.id}`
      })
    );
  });

  const removeExternalSuppliersPromises = data['product_ref_list'].removed.map((id) => {
    return apiCall({
      method: 'delete',
      url: `/external-suppliers/${id}/suppliers/${supplier.id}`
    });
  });

  const externalSuppliers = await Promise.all([...externalSuppliersPromises, ...removeExternalSuppliersPromises]);
  if (!externalSuppliers.every((res) => !!res)) return false;

  // check user_list diff
  const usersList = data.users_list || [];

  let removedUserIdList = [];
  initialUserSupplierList.forEach((u) => {
    if (!usersList.map((el) => el.email).includes(u.email)) {
      removedUserIdList.push(u.id);
    }
  });

  const removedUserIdListPromises = removedUserIdList.map((id) => {
    return deleteUserSupplier({ supplierId: supplier.id, userPlatformId: id });
  });
  await Promise.all(removedUserIdListPromises);

  if (usersList.length) {
    // create users
    const newUsers = await postUsers(usersList.map((el) => el.email));
    if (!newUsers) return false;

    const userSupplierPayload = newUsers.map((newUser) => ({
      userPlatform: {
        id: newUser.id,
        email: newUser.email
      },
      supplier: {
        id: supplier.id
      }
    }));
    const attachUserSupplier = await postUserSupplier(userSupplierPayload);
    if (!attachUserSupplier) return false;
  }

  if (supplier?.status === supplierStatuses.VALIDATED) {
    await getSupplier({ retailerId: user.id, supplierId: supplier.id });
    dispatch({
      type: 'UI_SNACKBAR',
      payload: {
        type: snackbarTypes.SUCCESS,
        title: { key: 'supplier_updated' }
      }
    });
  }

  return true;
};

export const updateSupplierNote = async (supplier) => {
  const response = await apiCall({
    method: 'put',
    url: '/suppliers',
    data: { ...supplier, note: supplier.note?.replaceAll('\n', '</br>') }
  });
  if (!response) return false;
  return true;
};

export const getSupplier = async ({ retailerId, supplierId }) => {
  const promises = [
    apiCall({ url: `/suppliers/${supplierId}` }),
    apiCall({
      url: '/supplier-budgets',
      params: {
        'retailerId.equals': retailerId,
        'supplierId.equals': supplierId,
        'year.equals': currentYear
      }
    }),
    apiCall({
      url: '/supplier-budgets',
      params: {
        'retailerId.equals': retailerId,
        'supplierId.equals': supplierId,
        'year.equals': currentYear + 1
      }
    }),
    apiCall({
      url: `/suppliers/${supplierId}/external-suppliers`
    })
  ];

  let [supplierDetailsResponse, currentYearBudgetsReponse, nextYearBudgetsResponse, externalSuppliersResponse] =
    await Promise.all(promises);

  if (!supplierDetailsResponse || !currentYearBudgetsReponse || !nextYearBudgetsResponse || !externalSuppliersResponse)
    return false;

  let productsCount = 0;
  if (externalSuppliersResponse.data.length) {
    const productsCountPromises = await externalSuppliersResponse.data.map((i) => getProductsCountBySupplier(i));
    const counts = await Promise.all(productsCountPromises);
    counts.map((count) => (productsCount += count));
  }

  // fix negative free budget
  currentYearBudgetsReponse = currentYearBudgetsReponse.data?.map((b) => ({ ...b, free: Math.max(0, b.free) }));
  nextYearBudgetsResponse = nextYearBudgetsResponse.data?.map((b) => ({ ...b, free: Math.max(0, b.free) }));

  //build budgets
  const budgetDigital = currentYearBudgetsReponse?.find((b) => b.type === supplierBudgetTypes.DIGITAL);
  const nextYearBudgetDigital = nextYearBudgetsResponse?.find((b) => b.type === supplierBudgetTypes.DIGITAL);
  const budgetPaper = currentYearBudgetsReponse?.find((b) => b.type === supplierBudgetTypes.PAPER);
  const nextYearBudgetPaper = nextYearBudgetsResponse?.find((b) => b.type === supplierBudgetTypes.PAPER);
  let budgets = [];
  if (budgetDigital || nextYearBudgetDigital) {
    budgets.push({
      type: supplierBudgetTypes.DIGITAL,
      budgetN: budgetDigital || { total: '' },
      budgetN1: nextYearBudgetDigital || { total: '' }
    });
  }
  if (budgetPaper || nextYearBudgetPaper) {
    budgets.push({
      type: supplierBudgetTypes.PAPER,
      budgetN: budgetPaper || { total: '' },
      budgetN1: nextYearBudgetPaper || { total: '' }
    });
  }

  dispatch({
    type: 'SUPPLIERS_UPDATE',
    payload: {
      details: {
        externalSuppliers: externalSuppliersResponse.data,
        productsCount: productsCount,
        supplier: { ...supplierDetailsResponse.data, budgets }
      }
    }
  });
  return true;
};

export const postSupplierEmailNotification = async ({ content, supplierId, title, userPlatformIds }) => {
  const response = await apiCall({
    method: 'POST',
    url: '/suppliers/email/manual',
    data: { content: content.replaceAll('\n', '<br/>'), supplierId, title, userPlatformIds }
  });
  if (!response) return false;

  dispatch({
    type: 'UI_SNACKBAR',
    payload: {
      type: snackbarTypes.SUCCESS,
      title: { key: 'commun_email_resend_notification_success' }
    }
  });
  return true;
};

/*** USER ***/
export const postUsers = async (emails = []) => {
  const data = emails.length ? emails.map((email) => ({ email, dns: window.location.origin })) : [];
  const response = await apiCall({ method: 'post', url: '/user-platform', data });
  if (!response) return false;

  return response.data;
};

/*** USER-MANAGER (gestionnaire) ***/
export const getAllUserManager = async () => {
  const response = await apiCall({ url: '/user-manager', params: { size: 2000 } });
  if (!response) return false;

  dispatch({ type: 'USER_MANAGERS_UPDATE', payload: response.data });
  return response.data;
};

export const getUserManagerByEmail = async (email) => {
  const response = await apiCall({ url: '/user-manager', params: { 'email.equals': email } });
  if (!response) return false;
  return response.data[0];
};

/*** USER-SUPPLIER ***/
export const deleteUserSupplier = async ({ supplierId, userPlatformId }) => {
  const response = await apiCall({
    method: 'delete',
    url: `/user-supplier?supplierId=${supplierId}&userPlatformId=${userPlatformId}`
  });
  if (!response) return false;
  return true;
};

export const getUserSuppliers = async (supplierId) => {
  const response = await apiCall({ url: `/user-supplier/supplier/${supplierId}` });
  if (!response) return false;
  return response.data;
};

export const postUserSupplier = async (data) => {
  const response = await apiCall({ method: 'post', url: '/user-supplier', data });
  if (!response) return false;
  return true;
};

/*** DAM ***/
export const getDams = async () => {
  const response = await apiCall({
    url: '/files/dam-image/dam-retailer-support'
  });
  if (!response) return false;

  dispatch({
    type: 'DAMS_UPDATE',
    payload: response.data.map((el) => ({ id: el, name: el }))
  });
  return true;
};

export const getDamImage = async (productCode, retailerCode, format = 'M1_T1', extension = 'jpg') => {
  const response = await apiCall({
    url: '/files/dam',
    responseType: 'blob',
    params: {
      productCode,
      retailerCode,
      format,
      extension
    }
  });
  if (!response) return false;

  return response.data;
};

/*** CONSTRAINTS ***/
export const getOfferConstraints = async (retailerId) => {
  const constraints = await apiCall({
    url: '/offer-constraints',
    params: {
      'retailerId.equals': retailerId
    }
  });
  if (!constraints) return false;

  dispatch({
    type: 'GET_OFFER_CONSTRAINTS',
    payload: constraints.data
  });
  return constraints.data;
};

export const addOfferConstraint = async (payload) => {
  const response = await apiCall({
    method: 'post',
    url: '/offer-constraints',
    data: payload
  });
  if (!response) return false;
  return true;
};

export const updateOfferConstraint = async (payload) => {
  const response = await apiCall({
    method: 'put',
    url: '/offer-constraints',
    data: payload
  });
  if (!response) return false;
  return true;
};

export const removeOfferConstraint = async (id) => {
  const response = await apiCall({
    method: 'delete',
    url: `/offer-constraints/${id}`
  });
  if (!response) return false;
  return true;
};

/*** Utils ***/
const downloadFile = async (url, params) => {
  const response = await apiCall({
    url,
    params,
    responseType: 'blob',
    contentType: 'application/octet-stream'
  });
  if (!response) return false;

  const dataUrl = window.URL.createObjectURL(new Blob([response.data]));
  const fileName = response.headers['content-disposition']?.split('=')[1];

  const a = document.createElement('a');
  a.href = dataUrl;
  a.target = '_self';
  a.download = fileName;
  a.click();
  a.remove();

  return fileName;
};

export const getApiDocs = async () => {
  const response = await apiCall({
    url: '/api-docs'
  });
  if (!response) return false;

  dispatch({
    type: 'API_DOCS',
    payload: response.data
  });
  return true;
};

export const relaunchUserValidationEmail = async (userId) => {
  await apiCall({
    method: 'PATCH',
    url: `/user-platform/${userId}/relaunch`
  });
};

export const sendEmailFeedback = async ({ content, title, templateId }) => {
  const response = await apiCall({ method: 'POST', url: '/email/send-opinion', data: { content, title, templateId } });
  if (!response) return false;
  return true;
};

/*** Import Use Case ***/

export const getImportUseCase = async ({ page = 0, size = 10 }) => {
  const responseData = await apiCall({ url: '/import-use-case', params: { page, size, sort: 'id,desc' } });
  if (!responseData) return false;

  return { totalCount: responseData.headers['x-total-count'], list: responseData.data };
};

export const postImportUseCase = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('name', file.name);

  const postFile = await apiCall({
    method: 'post',
    url: '/import-use-case/upload-file',
    data: formData,
    contentType: 'multipart/form-data'
  });
  if (!postFile) return false;
  return true;
};

/*** Universes ***/

// fetch all existing universes
export const getAllReferentialUniverses = async () => {
  const response = await apiCall({ url: '/referential/supplier/universes' });
  if (!response) return false;
  return response.data;
};

// fetch all available universes for this user (back api uses the token to distinguish the user type)
export const getAllUniverses = async () => {
  const response = await apiCall({ url: '/suppliers/universes' });
  if (!response) return false;

  dispatch({ type: 'UNIVERSE_RETAILER_UPDATE', payload: response.data });
  return response.data;
};

/*** Campaign Distribution channels ***/
export const getAllReferentialCampaignDistributionChannels = async () => {
  const response = await apiCall({ url: '/referential/campaign/distribution-channels' });
  if (!response) return false;
  return response.data;
};

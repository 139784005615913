import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import CampaignParametersTable from 'components/CampaignParametersTable';
import RetailerSegmentSelectionResult from './RetailerSegmentSelectionResult';

import styles from './ConfirmationStep.module.scss';

const ConfirmationStep = ({ campaignProduct, settingProductLevelLabelThree }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['root']}>
      <div className={styles['campaign-title']}>{campaignProduct.title}</div>
      <div>
        <div className={styles['details']}>
          <div className={styles['details-title']}>{t('commun_validation_dates')}</div>
          <div className={styles['detail-desc']}>
            {t('commun_from_to_dates', {
              valueFrom: campaignProduct.startDate.format(),
              valueTo: campaignProduct.endDate.format(),
            })}
          </div>
        </div>
        <div className={styles['divider']}></div>
        {campaignProduct.retailerSegment?.id && (
          <RetailerSegmentSelectionResult retailerSegment={campaignProduct.retailerSegment} />
        )}
        <div className={styles['parameters']}>
          <CampaignParametersTable
            parameters={campaignProduct.paramsOfferCampaign}
            settingProductLevelLabelThree={settingProductLevelLabelThree}
          />
        </div>
      </div>
    </div>
  );
};

ConfirmationStep.propTypes = {
  campaignProduct: PropTypes.object,
  settingProductLevelLabelThree: PropTypes.string,
};
export default memo(ConfirmationStep);

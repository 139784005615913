import React from 'react';
import PropTypes from 'prop-types';

import Item from './Item';

import styles from './HelpGuide.module.scss';

const HelpGuide = ({ className, content = [] }) => (
  <div className={`${styles['helpGuide-container']} ${className}`}>
    {content.map((el, index) => (
      <Item key={index} index={index} element={el} />
    ))}
  </div>
);

HelpGuide.propTypes = {
  className: PropTypes.object,
  content: PropTypes.array,
};

export default HelpGuide;

import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getCampaigns } from 'api';
import { campaignDeletionStatuses } from 'utils/constants';
import useDynId from 'customHooks/useDynId';

import ButtonsCustom from 'components/ButtonsCustom';
import CampaignTargetingStatusTag from 'components/CampaignTargetingStatusTag';
import ScrollToTop from 'components/ScrollToTop';
import TableCustom from 'components/TableCustom';
import Tags from 'components/Tags';
import FiltersContainer, { getInitialFilters } from 'components/Filters';
import Tooltip from 'components/Tooltip';

import { ReactComponent as TrashIcon } from '../../assets/trash_loading_red.svg';
import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';

import styles from './Campaigns.module.scss';

const Campaigns = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { campaigns, queryFilters, user } = state;

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [tableOrderBy, setTableOrderBy] = useState('desc');
  const [tableSortBy, setTableSortBy] = useState('id');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initFilters = useMemo(() => getInitialFilters({ history, queryFilters }), []);
  const [filters, setFilters] = useState(initFilters);

  const filtersListToDisplay = ['id', 'title', 'type', 'state', 'targetingStatus', 'validityDates', 'budgetSpent'];
  const getCampaignsParams = useMemo(
    () => ({
      page,
      sortBy: tableSortBy,
      orderBy: tableOrderBy,
      filters
    }),
    [page, filters, tableOrderBy, tableSortBy]
  );

  const displayDeletionStatus = (status) => {
    if (!status) return null;
    if (status === campaignDeletionStatuses.FAIL_DELETE_DATA) {
      return (
        <Tooltip title={t('commun_deleting_failed')}>
          <WarningIcon />
        </Tooltip>
      );
    }
    return (
      <Tooltip title={t('commun_deleting_inProcess')}>
        <TrashIcon />
      </Tooltip>
    );
  };

  const formatCampaigns = (campaigns) => {
    const typeMatching = {
      CASH_COUPON: t('commun_cash_coupon_initial'),
      PRODUCT: t('commun_product'),
      MIXED: t('commun_mixed'),
      NONE: ''
    };

    return campaigns.map((campaign) => {
      return {
        id: [{ value: campaign.id, color: 'disabled', rowBgColor: campaign.deletionStatus ? 'corail' : undefined }],
        offerCount: [{ value: campaign.offerCount }],
        campaignType: [
          {
            value: typeMatching[campaign.campaignType]
          }
        ],
        title: [{ value: campaign.title }],
        campaignState: <Tags status={campaign.campaignState} />,
        targetingStatus: <CampaignTargetingStatusTag status={campaign.targetingStatus} />,
        validityStartDate: [{ value: t('commun_date', { value: campaign.validityStartDate }), color: 'disabled' }],
        validityEndDate: [{ value: t('commun_date', { value: campaign.validityEndDate }), color: 'disabled' }],
        budgetSpent: [
          {
            value: campaign.discountGranted
              ? t('commun_price', {
                  value: campaign.discountGranted,
                  currency: user.currency.code
                })
              : '-'
          }
        ],
        deleteInProcess: displayDeletionStatus(campaign.deletionStatus)
      };
    });
  };

  const tableHeader = [
    { id: 1, field: 'id', headerName: 'ID', type: 'text', sortable: true },
    {
      id: 2,
      field: 'title',
      headerName: t('commun_title'),
      type: 'text',
      sortable: true,
      hasTooltip: true
    },
    {
      id: 3,
      field: 'campaignType',
      headerName: t('campaign_details_type'),
      type: 'text',
      sortable: true
    },
    { id: 4, field: 'campaignState', headerName: t('commun_state'), type: 'component', sortable: true },
    { id: 5, field: 'targetingStatus', headerName: t('campaign_details_targeting_status'), type: 'component' },
    {
      id: 6,
      field: 'validityStartDate',
      headerName: t('commun_start_date'),
      type: 'text',
      sortable: true
    },
    {
      id: 7,
      field: 'validityEndDate',
      headerName: t('commun_end_date'),
      type: 'text',
      sortable: true
    },
    {
      id: 8,
      field: 'offerCount',
      headerName: t('commun_number_offer'),
      type: 'text'
    },
    {
      id: 9,
      field: 'budgetSpent',
      headerName: t('commun_budget_spent'),
      type: 'text',
      sortable: true,
      sortPath: 'discountGranted'
    },
    {
      id: 10,
      field: 'deleteInProcess',
      headerName: '',
      type: 'component'
    }
  ];

  const campaignList = formatCampaigns(campaigns.list);

  useEffect(() => {
    return () => {
      dispatch({ type: 'CAMPAIGNS_RESET' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getCampaigns({ ...getCampaignsParams });
      setIsLoading(false);
    };
    fetchData();
  }, [getCampaignsParams]);

  const handleTableNextPage = () => {
    setPage(page + 1);
  };

  const handleTablePrevPage = () => {
    setPage(page - 1);
  };

  const handleTableRowClick = ({ id }) => {
    history.push(`/campaigns/${id[0].value}`);
  };

  const handleTableSort = (field) => {
    // TODO: keeping state of orderBy and sortedBy should be managed in TableCustom
    let orderBy = '';
    if (field !== tableSortBy) {
      orderBy = 'desc';
    } else {
      orderBy = tableOrderBy === 'asc' ? 'desc' : 'asc';
    }
    setTableSortBy(field);
    setTableOrderBy(orderBy);
    setPage(0); // reset the page when changing the sorting
  };

  const handleUpdateFilters = useCallback((newFilters) => {
    setFilters({ ...newFilters });
  }, []);

  return (
    <section className={styles['root']}>
      <div className={styles['top-wrapper']}>
        <div className={styles['header']}>
          <div className={styles['header-title']}>{t('campaign_list_desc')}</div>
          <div className={styles['header-desc']}>{t('campaign_details_desc')}</div>
        </div>
      </div>
      <div className={styles['top-wrapper']}>
        <p className={styles['count-result']} id={useDynId('Xcampaign')}>
          {t('commun_campaign_count', { count: campaigns.total })}
        </p>
        <Link to={'campaigns/add-campaign'} role="link">
          <ButtonsCustom
            classType="action_primary_big"
            id={useDynId('create')}
            text={t('commun_button_create_a_campaign')}
          />
        </Link>
      </div>

      <FiltersContainer
        dispatch={dispatch}
        filters={filters}
        filtersListToDisplay={filtersListToDisplay}
        updateFilters={handleUpdateFilters}
        user={user}
      />
      <TableCustom
        isLoading={isLoading}
        rows={campaignList}
        columns={tableHeader}
        total={campaigns.total}
        handleSort={handleTableSort}
        page={campaigns.page}
        size={30}
        prev={handleTablePrevPage}
        next={handleTableNextPage}
        sort={tableSortBy}
        order={tableOrderBy}
        onRowClick={handleTableRowClick}
        type="big"
        isUsingFilters={!!Object.keys(filters)?.length}
      />

      <ScrollToTop />
    </section>
  );
};

export default Campaigns;

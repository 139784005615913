import React from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';

import styles from './TopNavLinkTabs.module.scss';

type Tabs = {
  id: string;
  label: string;
  path: string;
};

type Props = {
  disabled?: boolean;
  selectedTabIndex: number;
  tabs: Tabs[];
};

const TopNavLinkTabs = ({ disabled, selectedTabIndex, tabs }: Props): JSX.Element => {
  if (disabled) {
    return (
      <div className={styles['root']}>
        {tabs.map((tab) => (
          <span className={styles['nav-link-disabled']} key={tab.label}>
            {tab.label}
          </span>
        ))}
      </div>
    );
  }

  return (
    <div className={styles['root']}>
      {tabs.map((tab, index) => (
        <NavLink
          id={tab.id}
          className={clsx(
            styles['nav-link'],
            index === selectedTabIndex && styles['selected'],
            !tab.path && styles['disabled']
          )}
          exact
          key={tab.label}
          to={tab.path}
        >
          {tab.label}
        </NavLink>
      ))}
    </div>
  );
};

export default TopNavLinkTabs;

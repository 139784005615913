import React from 'react';
import PropTypes from 'prop-types';

import styles from './TitleBlock.module.scss';

const TitleBlock = ({ desc, title }) => (
  <div className={styles['root']}>
    <div className={styles['title']}>{title}</div>
    {desc && <div className={styles['desc']}>{desc}</div>}
  </div>
);

TitleBlock.propTypes = {
  desc: PropTypes.string,
  title: PropTypes.string,
};

export default TitleBlock;

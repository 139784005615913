import React from 'react';
import clsx from 'clsx';
import styles from './Divider.module.scss';

type Props = {
  borderSize?: 'thin' | 'normal';
  marginSize?: 'thin' | 'medium' | 'large';
};

const Divider = ({ borderSize = 'normal', marginSize = 'medium' }: Props): JSX.Element => (
  <div className={clsx(styles['root'], styles[`border-${borderSize}`], styles[`margin-${marginSize}`])}></div>
);
export default Divider;

import React, { useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { store } from 'store';
import { getLastOffersImport, getLastOffersImportById } from 'api';

import CardsList from './CardsList';
import Drawer from 'components/Drawer';
import GoBackLink from 'components/GoBackLink';
import Loader from 'components/Loaders/Dots';
import OffersDetails from 'pages/OffersDetails';
import ScrollToTop from 'components/ScrollToTop';

import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';
import styles from './OfferRetailerImportDetails.module.scss';

const OfferRetailerImport = () => {
  const { t } = useTranslation();

  const pageSize = 30;
  const { id } = useParams();
  const history = useHistory();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    list: importsList,
    details: { listWithImage, listWithoutImage }
  } = state.offersImports;
  const [initialLoading, setInitialLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [pageCurrentImportList, setPageCurrentImportList] = useState(0);
  const [showDetailsPanelOfferId, setShowDetailsPanelOfferId] = useState(null);
  const [resetingAllData, setResetingAllData] = useState(false);
  const [getOffersWithPicture, setGetOffersWithPicture] = useState(false);
  const [currentImportDataReady, setCurrentImportDataReady] = useState(false);
  const [currentImport, setCurrentImport] = useState({
    requestDate: new Date(),
    retailer: { timezone: { name: 'Europe/Paris' } },
    id: null
  });

  // prevents a bug : fast scrolling is skipping pages
  const isLoading = useRef(true);

  const updateCurrentImport = () => {
    const updatedCurrentImport = importsList.find((el) => {
      return el.id === parseInt(id);
    });

    if (!updatedCurrentImport) {
      history.push('/offers/offersProductsRetailerImport');
      return;
    } else {
      setCurrentImport(updatedCurrentImport);
    }
  };

  useEffect(() => {
    if (!importsList.length) {
      loadFilesList();
    } else {
      updateCurrentImport();
    }

    return () => {
      dispatch({ type: 'OFFERS_IMPORT_DETAILS_RESET' });
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!importsList.length) return;
    updateCurrentImport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importsList]);

  useEffect(() => {
    if (importsList.length && currentImport.id) {
      setCurrentImportDataReady(true);
      setGetOffersWithPicture(currentImport.failedImageCount === listWithoutImage.length);
    }
  }, [currentImport, importsList.length, listWithoutImage.length]);

  useEffect(() => {
    if (resetingAllData) {
      loadFilesList();
      setCurrentImportDataReady(false);
      setGetOffersWithPicture();
      setPageCurrentImportList(0);
      setResetingAllData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetingAllData]);

  useEffect(() => {
    if (currentImportDataReady) {
      if (getOffersWithPicture) {
        setPageCurrentImportList(0);
        loadFilesListDetail({ page: 0 });
      } else {
        loadFilesListDetail();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOffersWithPicture, currentImportDataReady]);

  const loadFilesList = async () => {
    setInitialLoading(true);
    setLoadingList(true);
    await getLastOffersImport(state.user);
    setLoadingList(false);
    setInitialLoading(false);
  };

  const loadFilesListDetail = async ({ page } = {}) => {
    if (loadingList && page !== 0) return;
    if (getOffersWithPicture && currentImport.offerCount === listWithImage.length) return;

    setLoadingList(true);
    isLoading.current = true;
    const pageIndex = typeof page === 'number' ? page : pageCurrentImportList;
    await getLastOffersImportById({
      getOffersWithPicture: getOffersWithPicture,
      importDescriptorId: id,
      page: pageIndex,
      size: pageSize
    });
    setLoadingList(false);
    isLoading.current = false;
  };

  const flushStore = () => {
    dispatch({ type: 'OFFERS_IMPORT_RESET' });
  };

  const handleScrollCheckOffer = () => {
    if (loadingList || isLoading.current) return;

    if ((window.scrollY + document.body.offsetHeight) / document.body.scrollHeight > 0.8) {
      isLoading.current = true;
      setPageCurrentImportList((state) => state + 1);
      loadFilesListDetail({ page: pageCurrentImportList + 1 });
    }
  };

  const resetAllData = () => {
    flushStore();
    setResetingAllData(true);
  };

  const loaderOfferImportDom = () => (
    <div className={styles['offer-import-loader']}>
      <Loader />
    </div>
  );

  const handleDeletedOffer = () => {
    resetAllData();
    setShowDetailsPanelOfferId(null);
  };

  return (
    <>
      <section className={styles['root']}>
        <div className={styles['back-button-container']}>
          <GoBackLink
            flushStore={flushStore}
            label={t('commun_back')}
            pathname="/offers/offersProductsRetailerImport"
          />
        </div>

        <div className={styles['header-container']}>
          <h2>{t('imported_by', { text: currentImport.author })}</h2>
          <div className={styles['date']}>
            {t('commun_date_on', {
              value: currentImport.requestDate,
              datetime: currentImport.requestDate
            })}
          </div>
          <div className={styles['desc']}>{t('import_header_info')}</div>
        </div>
        {initialLoading && (
          <div className={styles['generic-loader']}>
            <p>{t('commun_loading')}</p>
            <Loader />
          </div>
        )}

        {!!currentImport.failedImageCount && (
          <>
            <div className={styles['import-offer-header-info-label-line']}>
              <WarningIcon />
              <span className={`${styles['label']} ${styles['error']}`}>
                <Trans i18nKey="offer_without_image" values={{ count: currentImport.failedImageCount }} />
              </span>
            </div>
            <div className={styles['cards-container']}>
              <CardsList
                list={listWithoutImage}
                handleOfferSelection={(id) => {
                  setShowDetailsPanelOfferId(id);
                }}
                resetAllData={resetAllData}
              />
            </div>
            {loadingList && currentImport.failedImageCount !== listWithoutImage.length && loaderOfferImportDom()}
            <div className={styles['divider']}></div>
          </>
        )}

        {/* currentImport.offerCount */}
        <div className={styles['import-offer-header-info-label-line']}>
          <span className={styles['label']}>
            <Trans
              i18nKey={currentImport.failedImageCount ? 'offer_other' : ''}
              values={{ count: currentImport.offerCount }}
            />
          </span>
        </div>

        <div className={styles['cards-container']}>
          <CardsList
            list={listWithImage}
            handleOfferSelection={(id) => {
              setShowDetailsPanelOfferId(id);
            }}
            resetAllData={resetAllData}
          />
        </div>
        {loadingList &&
          getOffersWithPicture &&
          currentImport.offerCount !== listWithImage.length &&
          loaderOfferImportDom()}

        <ScrollToTop handleScroll={handleScrollCheckOffer} />
      </section>
      <Drawer
        isOpen={!!showDetailsPanelOfferId}
        onClose={() => {
          setShowDetailsPanelOfferId(null);
        }}
        onCloseFinished={() => {
          dispatch({ type: 'OFFERS_DETAILS_RESET' });
        }}
      >
        <OffersDetails
          details={state.offers.details}
          offerHeadId={showDetailsPanelOfferId}
          onUpdated={resetAllData}
          onDeleted={handleDeletedOffer}
          user={state.user}
        />
      </Drawer>
    </>
  );
};

export default OfferRetailerImport;

import React from 'react';
import { useTranslation } from 'react-i18next';

import TopNavLinkTabs from 'components/TopNavLinkTabs';

type Props = {
  index: number;
};

const TopNav = ({ index }: Props): JSX.Element => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 'retailer',
      label: t('commun_retailer'),
      path: `/dashboard/retailer${localStorage.getItem('dashboardRetailer_retailerTabFilter') || ''}`
    },
    {
      id: 'supplier',
      label: t('commun_supplier'),
      path: `/dashboard/supplier${localStorage.getItem('dashboardRetailer_supplierTabFilter') || ''}`
    },
    {
      id: 'offer',
      label: t('commun_offer_capitalized'),
      path: `/dashboard/offer${localStorage.getItem('dashboardRetailer_offerTabFilter') || ''}`
    }
  ];
  return <TopNavLinkTabs selectedTabIndex={index} tabs={tabs} />;
};

export default TopNav;

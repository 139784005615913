import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getProducts, getProductsByKeyword } from 'api';

import AutocompleteCustom from 'components/AutocompleteCustom';
import TableProducts from './TableProducts';

import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';
import styles from './EanAutocompleteInputWithResultAndErrorList.module.scss';

let timeoutId; // used for debouncing controlledTextValue effect

/* used in offer creation and segmentManager products condition
// if defaultValue is not hydrated, the TableProducts will not display the alreadyAddedList (usefull in segmentManager)
// otherwise the new list will be added / stacked to the alreadyAddedList and displayed in the TableProducts
*/

const EanAutocompleteInputWithResultAndErrorList = ({
  alreadyAddedList,
  defaultValue,
  disabled,
  isLoading,
  onChange,
  onDeleteOne,
  retailerId,
  supplierId,
  tableSize
}) => {
  const { t } = useTranslation();

  const [controlledTextValue, setControlledTextValue] = useState('');
  const [eanProductsErrors, setEanProductsErrors] = useState([]);
  const [eanProducts, setEanProducts] = useState(defaultValue || []);
  const [productKeyword, setProductKeyword] = useState('');
  const [productKeywordList, setProductKeywordList] = useState([]);
  const [verifyEansListLoading, setVerifyEansListLoading] = useState(false);

  useEffect(() => {
    setEanProducts(defaultValue || []);
  }, [defaultValue]);

  // used when free input keyword changes
  useEffect(() => {
    const fetchProducts = async () => {
      setVerifyEansListLoading(true);
      const response = await getProductsByKeyword({
        retailerId,
        supplierId,
        page: 0,
        keyword: productKeyword
      });

      if (response) {
        setProductKeywordList(response.list);
      }
      setVerifyEansListLoading(false);
    };
    if (retailerId && productKeyword) {
      fetchProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productKeyword]);

  useEffect(() => {
    // implementing debounce
    clearTimeout(timeoutId); // clear timeout when a new text is pressed
    // create new setTimeout
    timeoutId = setTimeout(() => {
      if (controlledTextValue?.length > 1) {
        const asList = [...new Set(controlledTextValue.split(/[/\s,;\-|]/g).filter((ean) => !!ean))];

        //check if it's a list of eans : first entry should be a number
        if (asList.length > 1 && !isNaN(asList[0])) {
          // process pasted eans
          verifyEansList(asList);
        } else {
          // process autocomplete with manual selection
          setProductKeyword(controlledTextValue);
        }
      }

      return () => {
        clearTimeout(timeoutId);
      };
    }, 250);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlledTextValue]);

  const handleAddEanProduct = (eanProductToAdd) => {
    const newEanProducts = [...eanProducts, eanProductToAdd];

    // add it on TableProducts if not in the default list
    if (!defaultValue) {
      setEanProducts(newEanProducts);
    }
    // add it only if new
    if (
      alreadyAddedList.filter((item) => item.ean === eanProductToAdd.ean || item.code === eanProductToAdd.ean)
        .length === 0
    ) {
      setEanProducts(newEanProducts);
      onChange(newEanProducts);
    }
  };

  const handleRemoveEanProduct = (eanToRemove) => {
    const newEanProducts = eanProducts.filter((itemFilter) => itemFilter.ean !== eanToRemove);
    setEanProducts(newEanProducts);
    onDeleteOne(eanToRemove);
  };

  const handleClearErrorList = () => {
    setEanProductsErrors([]);
  };

  const handleProductRefAutocompleteChange = async (event) => {
    if (event) {
      setControlledTextValue(event.target?.value);
    }
  };

  const verifyEansList = async (pastedEansList) => {
    setVerifyEansListLoading(true);
    let verifiedEanList = new Set(eanProducts.map((item) => JSON.stringify(item)));
    let errorEanList = new Set(eanProductsErrors);

    let productFoundListPromises = [];
    pastedEansList.forEach((elem) => {
      productFoundListPromises.push(
        getProducts({
          retailerId,
          supplierId,
          sort: 'code',
          order: 'desc',
          page: 0,
          keyword: elem,
          exactEanSearch: true
        })
      );
    });

    let productFoundList = await Promise.all(productFoundListPromises);
    productFoundList = productFoundList.map((el) => el.searchResultList[0]);

    pastedEansList.forEach((elem, index) => {
      const productFound = productFoundList[index];
      if (productFound) {
        // order matters, must be same as resultListEan mapped in api.js --> getProducts()
        verifiedEanList.add(
          JSON.stringify({
            averagePrice: productFound.averagePrice,
            customProductId: productFound.customProductId,
            ean: productFound.ean,
            id: productFound.id,
            label: productFound.label,
            productLevelFourthCode: productFound.productLevelFourthCode
          })
        );
      } else {
        errorEanList.add(elem);
      }
    });

    setVerifyEansListLoading(false);
    setEanProductsErrors([...errorEanList]);
    setControlledTextValue(''); // empty input field after pasting a list

    const newList = [...verifiedEanList].map((item) => JSON.parse(item));
    setEanProducts(newList);
    // update parent's data
    onChange(newList);
  };

  return (
    <>
      <AutocompleteCustom
        blurOnSelect
        controlledTextValue={controlledTextValue}
        disabled={disabled}
        id="eanAutocomplete_id"
        isLoading={isLoading || verifyEansListLoading}
        labelAttribute={['label', 'ean', 'customProductId']} // order matters
        listData={productKeywordList}
        loadingText={t('loading_text_product')}
        onInputChange={handleProductRefAutocompleteChange}
        onSelection={handleAddEanProduct}
        placeholderText={t(`commun_ean_autocomplete_input_placeholder`)}
        tagAttribut="ean"
      />
      <div className={styles['help-text']}>{t('offers_creation_dialog_copied_ean_desc')}</div>

      {/* display error ean list */}
      {!!eanProductsErrors.length && (
        <div className={styles['error-block']}>
          <div className={styles['header']}>
            <div>
              <h3>
                <WarningIcon />
                {t('offers_creation_dialog_copied_ean_error_number', { count: eanProductsErrors.length || 0 })}
              </h3>
            </div>
            <div className={styles['delete-icon']} onClick={handleClearErrorList} role="button">
              <TrashIcon />
              {t('commun_button_erase')}
            </div>
          </div>
          <div className={styles['desc']}>{t('offers_creation_dialog_copied_ean_error_desc')}</div>
          <div className={styles['list']}>
            {eanProductsErrors.map((error, i) => (
              <span className={styles['ean']} key={i.toString()}>
                {error}
              </span>
            ))}
          </div>
        </div>
      )}

      {/* display added ean list */}
      {!!eanProducts.length && (
        <div className={styles['added-products-block']}>
          <h3>{t('offers_creation_product_selected', { count: eanProducts.length })}</h3>
          <TableProducts
            list={eanProducts.map((item) => ({
              averagePrice: item.averagePrice,
              customProductId: item.customProductId,
              code: item.ean,
              id: item.id,
              description: item.label,
              productLevelFourthCode: item.productLevelFourth?.code
            }))}
            onRemoveItem={!disabled ? handleRemoveEanProduct : undefined}
            tableSize={tableSize}
          />
        </div>
      )}
    </>
  );
};

EanAutocompleteInputWithResultAndErrorList.propTypes = {
  alreadyAddedList: PropTypes.array,
  defaultValue: PropTypes.array,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
  onDeleteOne: PropTypes.func,
  retailerId: PropTypes.number,
  supplierId: PropTypes.number,
  tableSize: PropTypes.number
};

export default EanAutocompleteInputWithResultAndErrorList;

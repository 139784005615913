import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DAYS_UNIT } from 'utils/constants';
import { getFirstValidityStartDate } from 'utils/global';

import DurationSummary from './DurationSummary';
import FormManager from 'components/FormManager';
import HelperText from 'components/HelpText';
import ValidityDatesSelection from './ValidityDatesSelection';

const ValidityDatesBlock = ({
  disabled,
  errors,
  helperText,
  linkedOfferCampaigns,
  offerConstraints,
  offerCreation,
  supplierBudgets = [],
  updateOfferCreation
}) => {
  const { t } = useTranslation();
  const existingDurationConstraint = offerConstraints.find((el) => el.target === 'duration');

  const combinedGuidelineText = useMemo(() => {
    const durationConstraintText = existingDurationConstraint
      ? t(
          existingDurationConstraint.unit === DAYS_UNIT
            ? 'offers_creation_total_duration_info_days'
            : 'offers_creation_total_duration_info_weeks',
          {
            count: existingDurationConstraint.threshold
          }
        )
      : '';

    return `${helperText ?? ''}<div>${durationConstraintText}</div>`;
  }, [existingDurationConstraint, helperText, t]);

  const startDate = getFirstValidityStartDate(offerCreation.step4.validityDates);
  const year = startDate ? startDate.year() : null;

  return (
    <>
      <FormManager
        data={{
          title: t('offers_creation_block_validity_dates_title'),
          fieldsets: [
            {
              id: 'add-offer-dates-block',
              fields: [
                {
                  display: !!existingDurationConstraint || !!helperText,
                  id: 'offerValidityDatesHelperText',
                  classnames: ['with-margin-bottom'],
                  type: <HelperText content={combinedGuidelineText} />
                },
                {
                  type: <ValidityDatesSelection />,
                  id: 'offer-dates',
                  defaultValue: offerCreation.step4.validityDates,
                  fieldProps: {
                    linkedOfferCampaigns,
                    offerConstraints,
                    updateOfferCreation
                  },
                  disabled: disabled,
                  error: errors.dateError
                },
                {
                  outerContainerClass: ['full-width'],
                  type: 'CustomContent',
                  component: (
                    <DurationSummary
                      existingDurationConstraint={existingDurationConstraint}
                      validityDates={offerCreation.step4.validityDates}
                    />
                  ),
                  id: 'total-duration'
                }
              ]
            }
          ]
        }}
      />
      {offerCreation.step1.id && !supplierBudgets.length && !!year && (
        <div className="error-msg">{t('offers_creation_no_supplier_budget_frame', { year })}</div>
      )}
    </>
  );
};

ValidityDatesBlock.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  helperText: PropTypes.string,
  linkedOfferCampaigns: PropTypes.array,
  offerConstraints: PropTypes.array,
  offerCreation: PropTypes.object,
  supplierBudgets: PropTypes.array,
  updateOfferCreation: PropTypes.func
};

export default memo(ValidityDatesBlock);

import React from 'react';

const PrivacyPolicy = () => (
  <main>
    <h2>Politique de protection des données à caractère personnel</h2>
    <p>RELEVANC attache une grande importance à la protection de la vie privée et des données personnelles.</p>
    <p>
      Conformément au règlement européen 2016/679 du 26 avril 2016 et à la loi « informatique et libertés » du 6 janvier
      1978 modifiée, RELEVANC prend des engagements forts à l’égard des personnes concernées.
    </p>
    <p>
      L’objet de cette politique est d’informer les utilisateurs de la plateforme accessible à l’URL
      https://rcdmp.relevanc.io (le « <em>Site</em> » ou la « <em>Plateforme</em> ») des conditions dans lesquelles
      RELEVANC collecte et traite leurs données à caractère personnel à titre de responsable de traitement.
    </p>

    <h3>1. Catégories de données collectées</h3>
    <p>RELEVANC collecte les données personnelles en toute transparence et de manière explicite.</p>
    <p>A ce titre, RELEVANC collecte :</p>
    <ul>
      <li>
        Les données d’identification de l’utilisateur de la Plateforme : nom, prénom, email, et numéro de téléphone
        (facultatif)
      </li>
      <li>Les données de connexion de l’utilisateur de la Plateforme : logs de connexion et journaux d’événements.</li>
    </ul>

    <h3>2. Finalités des traitements</h3>
    <p>
      Chaque collecte de données est réalisée spécifiquement pour des finalités explicites, déterminées et légitimes. A
      ce titre, il est rappelé que :
    </p>
    <ul>
      <li>
        Pour ce qui concerne les données d’identification de l’utilisateur du Site, les finalités de collecte et de
        traitement sont la gestion des comptes des utilisateurs de la Plateforme (administration, accès, support client,
        etc.). La base juridique de ce traitement est l’exécution d’un contrat (article 6.1.b du règlement européen)
      </li>
      <li>
        Pour ce qui concerne les données de connexion, la finalité de collecte et de traitement est la gestion de la
        traçabilité des évènements survenus sur la Plateforme et la sécurisation de la Plateforme. La base juridique de
        ce traitement est le respect d’une obligation légale (article 6.1.c du règlement européen) ainsi que l’intérêt
        légitime de RELEVANC (article 6.1.f du règlement européen)
      </li>
    </ul>
    <p>
      RELEVANC se réserve la faculté de créer des moyens de collecte supplémentaires et/ou complémentaires. A ce titre,
      RELEVANC précisera, conformément à la réglementation, les finalités propres au traitement concerné au moment de la
      collecte des données.
    </p>
    <p>
      Il n’existe aucune prise de décision entièrement automatisée par RELEVANC sur la base de vos données personnelles.
    </p>

    <h3>3. Destinataires des traitements</h3>
    <p>Les destinataires des données sont de plusieurs ordres :</p>
    <ul>
      <li>les équipes de RELEVANC</li>
      <li>les sous-traitants pour l’exécution du traitement concerné</li>
      <li>
        les utilisateurs ayant la qualité d’administrateur, uniquement pour ce qui concerne certaines données
        spécifiques, et le cas échéant, les autorités publiques conformément à la loi
      </li>
    </ul>
    <p>
      Ces catégories de destinataires sont complétées, le cas échéant, de catégories propres à chaque traitement et
      expressément visées dans les mentions apparentes sur la page de collecte des données.
    </p>
    <p>
      Les transmissions de données personnelles avec les destinataires (quelle que soit leur nature juridique,
      sous-traitant, responsable de traitement ou simple destinataire) sont réalisées de manière sécurisée et en
      application d’un accord entre RELEVANC et chaque destinataire. RELEVANC s’engage à ce que chaque destinataire
      connaisse les principes directeurs de la protection des données personnelles et y soient soumis en application de
      la loi et/ou d’un contrat spécifique.
    </p>

    <h3>4. Conservation de vos données</h3>
    <p>
      RELEVANC prend le soin de conserver vos données personnelles à l’intérieur de l’Union européenne auprès
      d’hébergeurs qui sont soumis à la réglementation européenne relative à la protection des données personnelles. Vos
      données personnelles sont ainsi conservées, par RELEVANC de manière sécurisée et conformément à la réglementation.
    </p>
    <p>
      Les durées de conservation pratiquées par RELEVANC respectent la réglementation applicable au jour des présentes,
      à savoir :
    </p>
    <ul>
      <li>
        pour les données relatives à la création du compte utilisateur, une durée de 2 ans à compter de la résiliation
        du compte utilisateur
      </li>
      <li>
        pour les données relatives à la journalisation des évènements (logs) survenus sur la Plateforme, une durée de 1
        an à compter de l’événement
      </li>
    </ul>
    <p>
      RELEVANC se réserve la faculté de créer des moyens de collecte supplémentaires et/ou complémentaires. A ce titre,
      RELEVANC précisera, conformément à la réglementation, les durées de conservation propres au traitement concerné
      sur la page de collecte des données.
    </p>

    <h3>5. Vos droits</h3>
    <p>Vous disposez des droits suivants :</p>
    <ul>
      <li>droit d’accès à vos données personnelles</li>
      <li>droit de rectification de vos données personnelles si elles sont inexactes ou incomplètes</li>
      <li>droit de limitation dans les conditions visées à l’article 18 du règlement européen</li>
      <li>droit d’effacement de vos données personnelles si :</li>
      <ul>
        <li>
          elles ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées ou traitées
          d’une autre manière
        </li>
        <li>
          vous retirez votre consentement pour ce qui concerne les traitements soumis à consentement (ex : prospection
          commerciale)
        </li>
        <li>vous vous opposez valablement au traitement</li>
        <li>elles ont fait l’objet d’un traitement illicite</li>
        <li>une loi l’oblige</li>
      </ul>
      <li>droit d’opposition pour motif légitime</li>
      <li>droit à la portabilité de vos données</li>
      <li>
        droit de définir des directives relatives au sort de vos données à caractère personnel après votre mort et, pour
        les traitements basés sur le consentement, de retirer votre consentement à tout moment
      </li>
    </ul>
    <p>
      Pour exercer un ou plusieurs de ces droits il convient de contacter le délégué à la protection des données de
      RELEVANC (<a href="mailto:dpo@relevanc.com">dpo@relevanc.com</a>
      ).
    </p>

    <h3>6. Coordonnées</h3>
    <p>
      En cas de réclamation, vous pouvez contacter la CNIL (
      <a href="https://www.cnil.fr" rel="noopener noreferrer" target="_blank">
        www.cnil.fr
      </a>
      ).
    </p>

    <h3>7. Cookies</h3>
    <p>
      Cette politique s’applique aux cookies et autres systèmes technologiques présents sur les services numériques
      édités par RELEVANC et accessibles par un utilisateur via son ordinateur, tablette, smartphone ou autre terminal
      mobile.
    </p>
    <p>
      L’utilisateur est informé que, lors de ses visites sur la Plateforme, un cookie peut s’installer automatiquement
      sur son logiciel de navigation. Le cookie est un bloc de données qui ne permet pas d’identifier les utilisateurs
      mais sert à enregistrer des informations relatives à la navigation de celui-ci sur le site.
    </p>
    <p>
      Les cookies sont sécurisés, ils ne peuvent que stocker les informations qui sont mises à disposition par le
      navigateur, informations que l’utilisateur aura préalablement entré dans le navigateur ou qui sont incluses dans
      les demandes de page.
    </p>
    <p>
      Il y a différents types de cookies dont les utilisations et le contenu sont différents, temporaires et persistants
      :
    </p>
    <ul>
      <li>
        les cookies temporaires contiennent des informations qui sont utilisées au sein de votre session de navigation.
        Ces cookies sont automatiquement effacés lorsque vous fermez votre navigateur. Rien n’est conservé sur votre
        ordinateur une fois votre navigation terminée.
      </li>
      <li>
        les cookies persistants servent à stocker des informations qui sont utilisées entre les visites. Ces données
        permettent aux sites de reconnaître que vous êtes un utilisateur récurrent, et s’adaptent en conséquence. Les
        cookies persistants ont une valeur à long terme qui est définie par le site, et qui peut varier de quelques
        minutes à plusieurs années.
      </li>
    </ul>

    <h4>7.1 Dépôt des cookies</h4>
    <p>
      Afin de permettre une navigation optimale sur la Plateforme ainsi qu’un meilleur fonctionnement des différentes
      interfaces et applications, RELEVANC pourra procéder à l’implantation de traceurs destinés à sécuriser votre
      identification et authentification à la Plateforme. Ils permettent de vous reconnaître dès que vous vous connectez
      sur un de nos services et d’authentifier votre connexion à la Plateforme. Ces cookies sont nécessaires et ne
      peuvent pas être désactivés.
    </p>
    <p>
      RELEVANC se réserve par ailleurs le droit de collecter l’adresse IP publique. La collecte de cette adresse IP sera
      effectuée de façon anonyme, elle sera conservée pour la même durée que les cookies et ne sera destinée qu’à
      permettre une bonne administration des services proposés sur la Plateforme.
    </p>

    <h4>7.2 Cookies utilisés</h4>
    <table>
      <thead>
        <tr>
          <th>Nom du cookie</th>
          <th>Catégorie</th>
          <th>Durée de vie</th>
          <th>Objet</th>
          <th>Origine (interne ou tiers)</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>XSRF-TOKEN</td>
          <td>Cookie temporaire</td>
          <td>Durée de la session</td>
          <td>Ce cookie est utilisé afin de sécuriser la session</td>
          <td>Cookie interne</td>
        </tr>
        <tr>
          <td>remember-me-token</td>
          <td>Cookie persistant</td>
          <td>7 jours</td>
          <td>
            Ce cookie est utilisé pour garantir l’authentification des utilisateurs ayant coché la case « se souvenir de
            moi »
          </td>
          <td>Cookie interne</td>
        </tr>
        <tr>
          <td>remember-me-refresh-token</td>
          <td>Cookie persistant</td>
          <td>7 jours</td>
          <td>
            Ce cookie est utilisé pour garantir l’authentification des utilisateurs ayant coché la case « se souvenir de
            moi »
          </td>
          <td>Cookie interne</td>
        </tr>
        <tr>
          <td>refresh_token</td>
          <td>Cookie temporaire</td>
          <td>Durée de la session</td>
          <td>Ce cookie est utilisé pour la communication avec le back-end du service</td>
          <td>Cookie interne</td>
        </tr>
        <tr>
          <td>token</td>
          <td>Cookie temporaire</td>
          <td>Durée de la session</td>
          <td>Ce cookie est utilisé pour la communication avec le back-end du service</td>
          <td>Cookie interne</td>
        </tr>
      </tbody>
    </table>

    <h4>7.3 Désactivation et suppression des cookies</h4>
    <p>
      Ces cookies ont vocation à être conservés pour une durée variable allant jusqu’à 13 mois, et pourront être lus et
      utilisés par RELEVANC lors d’une visite ultérieure sur le site.
    </p>
    <p>
      Tous les navigateurs Web vous permettent de limiter les comportements des cookies ou de les désactiver dans les
      paramètres ou les options du navigateur. Les étapes à suivre sont différentes pour chaque navigateur, vous pouvez
      trouver des instructions dans le menu « Aide » de votre navigateur respectif.
    </p>
    <p>
      Grâce à votre navigateur, vous pouvez aussi consulter les cookies présents sur votre ordinateur, et les supprimer
      un à un ou tous d’un coup.
    </p>
    <p>
      Les cookies sont des fichiers texte de sorte que vous pouvez les ouvrir et lire le contenu. Les données contenues
      à l’intérieur sont souvent cryptées et correspondent à une session Web si bien qu’elles n’ont du sens que pour le
      site internet qui les a écrites.
    </p>
    <p>
      Pour plus d’informations sur les cookies :{' '}
      <a
        href="https://www.cnil.fr/fr/cookies-et-autres-traceurs/regles/cookies"
        rel="noopener noreferrer"
        target="_blank"
      >
        https://www.cnil.fr/fr/cookies-et-autres-traceurs/regles/cookies
      </a>
      .
    </p>
  </main>
);

export default PrivacyPolicy;

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Popover } from '@mui/material';

import ButtonsCustom from 'components/ButtonsCustom';
import DateRangePicker from 'components/DateRangePicker';
import SelectCustom from 'components/SelectCustom';

import { ReactComponent as CloseIcon } from 'assets/22px_croix.svg';
import { ReactComponent as DropdownIcon } from 'assets/dropdown_arrow.svg';
import { ReactComponent as CalendarIcon } from 'assets/24px_calendrier.svg';

import styles from './GranularityPeriodSelector.module.scss';

const GRANULARITY = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH'
};

const today = moment();
const currentWeek = today.week();
const currentMonth = today.month() + 1; // +1 to match data index start value
const currentYear = today.year();

const GranularityPeriodSelector = ({ filterGranularityPeriod, filterYear, isLoading, onValidation }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [onMouseOverItemIndex, setOnMouseOverItemIndex] = useState(null);
  const [state, setState] = useState(filterGranularityPeriod);
  const [datePickerFocusInput, setDatePickerFocusInput] = useState(null); // check if datepicker is open

  const allMonthList = useMemo(() => {
    return [...new Array(12)].map((el, index) => t(`commun_month_${index + 1}_short`));
  }, [t]);

  const allWeekList = useMemo(() => {
    return [...new Array(moment().year(filterYear).weeksInYear())].map((el, index) => {
      const idx = (index + 1).toString();
      return t('commun_week_very_short') + (idx.length > 1 ? idx : `0${idx}`);
    });
  }, [filterYear, t]);

  const list = state.granularity === GRANULARITY.MONTH ? allMonthList : allWeekList;

  useEffect(() => {
    const newState = { ...filterGranularityPeriod, period: { ...filterGranularityPeriod.period } };
    setState(newState);
  }, [filterGranularityPeriod]);

  const handleOpenModal = (event) => {
    if (!isLoading) {
      const newState = { ...filterGranularityPeriod, period: { ...filterGranularityPeriod.period } };
      setState(newState);
      setAnchorEl(event.currentTarget);

      // scroll to have the main button on top of the screen so we can display the popover under it
      document
        .getElementById('granularity-period-selector-display-container')
        ?.scrollIntoView({ block: 'start', behavior: 'instant' });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGranularitySelection = (item) => {
    let defaultPeriod;
    switch (item.target.value) {
      case GRANULARITY.DAY:
        defaultPeriod = moment().year(filterYear);
        break;
      case GRANULARITY.WEEK:
        defaultPeriod = currentWeek;
        break;
      case GRANULARITY.MONTH:
        defaultPeriod = currentMonth;
        break;
      default:
        break;
    }
    setState({ granularity: item.target.value, period: { start: defaultPeriod, end: defaultPeriod } });
  };

  const handlePeriodSelection = (index) => {
    const {
      period: { start, end }
    } = state;

    if (typeof start !== 'number' || (typeof start === 'number' && typeof end === 'number')) {
      setState((state) => ({ ...state, period: { start: index, end: null } }));
    } else if (typeof start === 'number' && typeof end !== 'number' && start <= index) {
      setState((state) => ({ ...state, period: { start: state.period.start, end: index } }));
    } else {
      setState((state) => ({ ...state, period: { start: index, end: null } }));
    }
  };

  const handleValidation = () => {
    const newState = {
      granularity: state.granularity,
      period: {
        start: state.period.start,
        end: state.period.end !== null && state.period.end !== '' ? state.period.end : state.period.start
      }
    };
    setState(newState);
    onValidation?.(newState);
    setAnchorEl(null);
  };

  const displaySelectedPeriodValues = () => {
    switch (filterGranularityPeriod.granularity) {
      case GRANULARITY.MONTH:
        return (
          <p>
            {`${allMonthList[filterGranularityPeriod.period.start - 1]} ${filterYear?.toString()?.slice(2) || ''}`}
            {filterGranularityPeriod.period.end === filterGranularityPeriod.period.start
              ? ''
              : ` - ${allMonthList[filterGranularityPeriod.period.end - 1]} ${filterYear?.toString()?.slice(2) || ''}`}
          </p>
        );

      case GRANULARITY.WEEK:
        let idxStart = filterGranularityPeriod.period.start.toString();
        idxStart = t('commun_week_very_short') + (idxStart.length > 1 ? idxStart : `0${idxStart}`);
        let idxEnd = filterGranularityPeriod.period.end.toString();
        idxEnd = t('commun_week_very_short') + (idxEnd.length > 1 ? idxEnd : `0${idxEnd}`);
        return (
          <p>
            {idxStart}
            {idxEnd === idxStart ? '' : ` - ${idxEnd}`}
          </p>
        );

      case GRANULARITY.DAY:
        return (
          <p>
            {t('commun_date', { value: filterGranularityPeriod.period.start })}
            {filterGranularityPeriod.period.end.isSame(filterGranularityPeriod.period.start, 'day')
              ? ''
              : ` - ${t('commun_date', { value: filterGranularityPeriod.period.end })}`}
          </p>
        );

      default:
        return null;
    }
  };

  const displaySelectedStyleOnItem = (index) => {
    const {
      period: { start, end }
    } = state;

    if (index === start || index === end || (start < index && end > index)) {
      return true;
    } else if (
      typeof start === 'number' &&
      typeof end !== 'number' &&
      start < index &&
      start < onMouseOverItemIndex &&
      index <= onMouseOverItemIndex
    ) {
      return true;
    }
    return false;
  };

  const displayHighlightedStyleOnItem = (index) => {
    if (state.granularity === 'MONTH') {
      return index === currentMonth;
    } else if (state.granularity === 'WEEK') {
      return index === currentWeek;
    }
  };

  const checkIsItemDisabled = (index) => {
    if (state.granularity === 'MONTH') {
      return currentYear === filterYear && index > currentMonth;
    } else if (state.granularity === 'WEEK') {
      return currentYear === filterYear && index > currentWeek;
    }
  };

  const checkIsOutsideRange = (date) => {
    return (currentYear === filterYear && date.isAfter(today)) || date.year() > filterYear || date.year() < filterYear;
  };

  return (
    <div className={styles['root']}>
      <div
        className={`${styles['display-container']} ${isLoading ? styles['disabled'] : ''}`}
        id="granularity-period-selector-display-container"
        onClick={handleOpenModal}
        role="button"
      >
        <div className={styles['block-container']}>
          <CalendarIcon className={styles['calendar-icon']} />
          <p>
            {t(`_dyn_commun_select_interval_${filterGranularityPeriod.granularity}`, {
              // display plural label ?
              count:
                filterGranularityPeriod.granularity === GRANULARITY.DAY
                  ? filterGranularityPeriod.period.end.isSame(filterGranularityPeriod.period.start, 'day')
                    ? 1
                    : 2
                  : filterGranularityPeriod.period.start !== filterGranularityPeriod.period.end
                  ? 2
                  : 1
            })}
          </p>
        </div>
        <div className={styles['vertical-divider']}></div>
        <div className={styles['block-container']}>
          {displaySelectedPeriodValues()}
          <DropdownIcon />
        </div>
      </div>

      <Popover
        id={'granularity-period-picker'}
        classes={{
          root: styles['popper'],
          paper: `${styles['paper']} ${state.granularity === GRANULARITY.WEEK ? styles['large'] : ''} ${
            state.granularity === GRANULARITY.DAY ? styles['wide'] : ''
          } ${datePickerFocusInput ? styles['tall'] : ''}`
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disablePortal
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {/* {popperContent} */}
        <div className={styles['popper-container']}>
          <div>
            <div className={styles['granularity-selection-container']}>
              <SelectCustom
                blurOnSelect
                id={'select-granularity'}
                listData={Object.values(GRANULARITY).map((el) => ({
                  id: el,
                  label: t(`_dyn_commun_select_interval_${el}_plural`)
                }))}
                methodOnChange={handleGranularitySelection}
                placeHolderText={t('commun_period')}
                value={state.granularity}
              />
            </div>
            <div className={styles['year-container']}>{filterYear}</div>

            {state.granularity === GRANULARITY.DAY ? (
              <div>
                <DateRangePicker
                  disableYearSelection
                  endDate={state.period.end}
                  fullWidth
                  getFocusInput={setDatePickerFocusInput}
                  id="date-picker"
                  isOutsideRange={checkIsOutsideRange}
                  onDatesChange={(dates) => {
                    setState({ ...state, period: { end: dates.endDate, start: dates.startDate } });
                  }}
                  startDate={state.period.start}
                />
              </div>
            ) : (
              <div
                className={styles['period-selection-container']}
                onMouseLeave={() => {
                  setOnMouseOverItemIndex();
                }}
              >
                {list.map((el, index) => {
                  // " index +1 " to match data index start value
                  const disabled = checkIsItemDisabled(index + 1);
                  return (
                    <div
                      className={`${styles['item']} 
                      ${displaySelectedStyleOnItem(index + 1) ? styles['selected'] : ''} 
                      ${displayHighlightedStyleOnItem(index + 1) ? styles['highlighted'] : ''} 
                      ${disabled ? styles['disabled'] : ''}`}
                      key={el}
                      onClick={() => {
                        if (!disabled) handlePeriodSelection(index + 1);
                      }}
                      onFocus={() => {
                        if (!disabled) setOnMouseOverItemIndex(index + 1);
                      }}
                      onMouseOver={() => {
                        if (!disabled) setOnMouseOverItemIndex(index + 1);
                      }}
                      role="button"
                    >
                      <div>{el}</div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className={styles['bottom-validation-container']}>
            <ButtonsCustom
              classType="action_secondary"
              disabled={state.period.start === null}
              id="date-year-picker-popover-button-apply"
              text={t('commun_apply')}
              method={handleValidation}
            />
          </div>
        </div>
        <span className={styles['popper-arrow']}></span>
        <span className={styles['popper-close-icon']}>
          <CloseIcon onClick={handleClose} />
        </span>
      </Popover>
    </div>
  );
};

GranularityPeriodSelector.propTypes = {
  filterGranularityPeriod: PropTypes.object,
  filterYear: PropTypes.number,
  isLoading: PropTypes.bool,
  onValidation: PropTypes.func
};

export default GranularityPeriodSelector;

const getModifiedGuidelines = (language, initState, retailerState) => {
  const results = [];
  const oldGuidelines = initState.guidelines[language];
  const newGuidelines = retailerState.guidelines[language];

  for (const prop in newGuidelines) {
    const { content } = newGuidelines[prop];

    if (typeof oldGuidelines[prop] !== 'undefined' && oldGuidelines[prop].content !== content && content !== '') {
      results.push({ ...newGuidelines[prop], language });
    }
  }

  return results;
};

const getRemovedGuidelines = (language, initState, retailerState) => {
  const results = [];
  const oldGuidelines = initState.guidelines[language];
  const newGuidelines = retailerState.guidelines[language];

  for (const prop in oldGuidelines) {
    if (newGuidelines[prop].content === '') {
      results.push({ ...oldGuidelines[prop], language });
    }
  }

  return results;
};

const getCreatedGuidelines = (language, initState, retailerState) => {
  const results = [];
  const oldGuidelines = initState.guidelines[language];
  const newGuidelines = retailerState.guidelines[language];

  for (const prop in newGuidelines) {
    if (typeof oldGuidelines[prop] === 'undefined' && newGuidelines[prop].content !== '') {
      results.push({ ...newGuidelines[prop], language });
    }
  }

  return results;
};

export default function getGuidelinesToEdit(initState, retailerState) {
  return {
    modified: [
      ...getModifiedGuidelines('FR', initState, retailerState),
      ...getModifiedGuidelines('EN', initState, retailerState),
    ],
    removed: [
      ...getRemovedGuidelines('FR', initState, retailerState),
      ...getRemovedGuidelines('EN', initState, retailerState),
    ],
    created: [
      ...getCreatedGuidelines('FR', initState, retailerState),
      ...getCreatedGuidelines('EN', initState, retailerState),
    ],
  };
}

import React from 'react';
import PropTypes, { bool } from 'prop-types';
import { Switch } from '@mui/material';
import './SwitchCustom.scss';

const SwitchCustom = ({ checked, disabled, name, onChange, useMonoBlueColorCustomStyle }) => {
  return (
    <Switch
      checked={checked}
      classes={
        useMonoBlueColorCustomStyle
          ? {
              root: 'switch-custom-root',
              switchBase: 'switch-custom-base-blue',
              colorSecondary: 'switch-custom-checked-blue',
              checked: 'switch-custom-checked-blue',
              thumb: 'switch-custom-thumb',
              track: 'switch-custom-track-blue',
              disabled: 'switch-custom-disabled'
            }
          : {
              root: 'switch-custom-root',
              switchBase: 'switch-custom-base',
              colorSecondary: 'switch-custom-checked',
              checked: 'switch-custom-checked',
              thumb: 'switch-custom-thumb',
              track: 'switch-custom-track',
              disabled: 'switch-custom-disabled'
            }
      }
      disabled={disabled}
      disableRipple
      name={name}
      onChange={onChange}
    />
  );
};

SwitchCustom.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  useMonoBlueColorCustomStyle: bool
};

export default SwitchCustom;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'utils/global';
import { ReactComponent as ArrowIcon } from 'assets/arrow_up.svg';
import './ScrollToTop.scss';

const ScrollToTop = ({ handleScroll }) => {
  const [scrollTopPos, setScrollTopPos] = useState(0);
  const debounceSetScrollPos = () => {
    const functionsToDebounce = () => {
      handleScroll && handleScroll();
      setScrollTopPos(window.scrollY);
    };
    debounce(functionsToDebounce(), 1000);
  };
  useEffect(() => {
    window.addEventListener('scroll', debounceSetScrollPos);
    return () => {
      window.removeEventListener('scroll', debounceSetScrollPos);
    };
  });

  return (
    <div className="to-top-wrapper ">
      {scrollTopPos > 100 && (
        <button
          className="to-top-button "
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          <ArrowIcon />
        </button>
      )}
    </div>
  );
};

ScrollToTop.propTypes = {
  handleScroll: PropTypes.func,
};

export default ScrollToTop;

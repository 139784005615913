import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { withFractionDigits } from 'utils/global';

import ScatterPlotGraph, { DataPropType, DatumType, ScatterPlotGraphLegend } from 'components/ScatterPlotGraph';
import DotsLoader from 'components/Loaders/Dots';

import { ReactComponent as CloseIcon } from 'assets/cross.svg';
import { ReactComponent as ExpandIcon } from 'assets/expand_full_screen.svg';
import styles from '../Dashboard.module.scss';

type Props = {
  children?: JSX.Element;
  currencyCode?: string;
  data: DataPropType[];
  handleClick?: (datum: DatumType) => void;
  handleClickCancelZoomedState: () => void;
  handleClickZoomedIndex: () => void;
  indexForZoom?: number;
  isLoading: boolean;
  title: string;
  yAxisFormatType: 'currency' | 'number' | 'percentage';
  yPropData: string;
};

const ScatterPlotCustom = ({
  children,
  currencyCode,
  data = [],
  handleClick,
  handleClickCancelZoomedState,
  handleClickZoomedIndex,
  indexForZoom,
  isLoading,
  title,
  yAxisFormatType,
  yPropData
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const hasIndexForZoom = indexForZoom !== undefined;

  const renderScatterPlotTooltip = useCallback(
    (tooltipData: any): JSX.Element => {
      const offer = tooltipData.raw.rawData;

      const displayHighlightedKpi = (): string => {
        if (yPropData === 'burnRate') {
          return `${t('commun_burnRate')} : ${
            offer.burnRate || offer.burnRate === 0
              ? t('commun_percentage_number', {
                  maximumFractionDigits: withFractionDigits(offer.burnRate || 0),
                  value: offer.burnRate || 0
                })
              : '-'
          }`;
        } else if (yPropData === 'estimatedTurnover') {
          return `${t('commun_turnover_earned')} : ${
            offer.estimatedTurnover || offer.estimatedTurnover === 0
              ? t('commun_price', {
                  currency: currencyCode,
                  maximumFractionDigits: withFractionDigits(offer.estimatedTurnover || 0),
                  value: offer.estimatedTurnover || 0
                })
              : '-'
          }`;
        } else if (yPropData === 'returnOnAdSpent') {
          return `${t('commun_roas')} : ${
            offer.returnOnAdSpent || offer.returnOnAdSpent === 0
              ? t('commun_number', {
                  maximumFractionDigits: withFractionDigits(offer.returnOnAdSpent || 0),
                  value: offer.returnOnAdSpent || 0
                })
              : '-'
          }`;
        }
        return '';
      };

      const leftPosition = tooltipData.element.x - 130;
      const topPosition = tooltipData.element.y - 175;

      return (
        <div className={styles['tooltip-container']} style={{ left: leftPosition, top: topPosition }}>
          <p className={styles['titleA']}>{offer.supplierName}</p>
          <p className={styles['titleB']}>{offer.offerTitle}</p>
          <p className={styles['id']}>{`#${offer.offerHeadId}`}</p>
          <br />
          <p className={styles['kpi']}>{displayHighlightedKpi()}</p>
          <p className={styles['kpi']}>{`${t('commun_generosity')} : ${
            offer.generosityPercentage || offer.generosityPercentage === 0
              ? t('commun_percentage_number', {
                  value: offer.generosityPercentage || 0,
                  maximumFractionDigits: withFractionDigits(offer.generosityPercentage || 0)
                })
              : '-'
          }`}</p>
          <p className={styles['kpi']}>{`${t('offers_creation_quantity_to_add_bis')} : ${t('commun_number', {
            value: offer.quantityMinimum || 0,
            maximumFractionDigits: withFractionDigits(offer.quantityMinimum || 0)
          })}`}</p>
        </div>
      );
    },
    [currencyCode, t, yPropData]
  );

  const displayScatterPlotLegends = (): JSX.Element => {
    return (
      <ScatterPlotGraphLegend
        labels={[
          `${t('offers_creation_quantity_to_add_short')} 1`,
          `${t('offers_creation_quantity_to_add_short')} 2`,
          `${t('offers_creation_quantity_to_add_short')} ≥ 3`
        ]}
      />
    );
  };

  return (
    <div
      className={clsx(
        styles['graph-container'],
        hasIndexForZoom ? styles['full-page-graph-container'] : styles['small-graph-container']
      )}
    >
      <div>
        <div className={styles['title-container']}>
          <div className={clsx(hasIndexForZoom && styles['big-title'])}>{title}</div>
          {hasIndexForZoom ? (
            <CloseIcon
              onClick={() => {
                handleClickCancelZoomedState();
              }}
            />
          ) : (
            <ExpandIcon
              onClick={() => {
                handleClickZoomedIndex();
              }}
            />
          )}
        </div>

        {hasIndexForZoom && children}

        <div className={styles['graph']}>
          <ScatterPlotGraph
            currencyCode={currencyCode}
            data={data}
            handleClick={handleClick}
            layoutPadding={hasIndexForZoom ? { top: 30, right: 10, bottom: 6, left: 6 } : undefined}
            renderTooltip={renderScatterPlotTooltip}
            xAxisFormatType="percentage"
            xLabel={t('commun_generosity')}
            xTickValues={5}
            yAxisFormatType={yAxisFormatType}
            yTickValues={4}
          />
          <div className={styles['x-label']}>{t('commun_generosity')}</div>
        </div>
        {isLoading && (
          <div className={styles['loading-frame']}>
            <DotsLoader />
          </div>
        )}
        {displayScatterPlotLegends()}
      </div>
    </div>
  );
};

export default ScatterPlotCustom;

import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CashCouponForm from 'components/CashCouponForm';

const AddCashCouponPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <CashCouponForm
      wrapperProps={{
        headerTitle: t(id ? 'cash_coupon_editing_title' : 'commun_button_create_cash_coupon'),
        headerSubTitle: t('cash_coupon_creation_desc'),
      }}
    />
  );
};

export default AddCashCouponPage;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import FormContext from './context';
import Tooltip from 'components/Tooltip';

import InfoIcon from 'assets/30px_info_round.svg';

import FieldManager from './FieldManager';

const FieldsetManager = (props) => {
  const { formData } = useContext(FormContext);
  const {
    data: { classnames = [], title, description, display = true, fields, requirements = [] },
  } = props;

  const verifyRequirements = () => {
    for (let element of requirements) {
      const { field, eq, neq } = element;
      const reqValue = formData[field];

      if ((eq && !eq.includes(reqValue)) || (neq && neq.includes(reqValue))) {
        return false;
      }
    }

    return true;
  };
  const shown = verifyRequirements();

  if (!display || !shown) {
    return false;
  }

  return (
    <div className={['form-fieldset', ...classnames].join(' ')}>
      {title && <div className="main-form-step-title">{title}</div>}
      {description && <div className="main-form-step-desc">{description}</div>}
      {fields.map((el) => (
        <div
          key={el.id}
          id={`field-${el.id}`}
          className={`field-block ${el.label ? 'with-label' : ''} ${
            el.outerContainerClass ? el.outerContainerClass.join(' ') : ''
          }`}
        >
          {el.label && (
            <div className={`${el.labelClass || 'field-label'} ${el.subLabel ? 'has-sub-label' : ''}`}>
              <span>{el.label}</span>
              {el.tooltip && (
                <Tooltip placement="right" title={el.tooltip}>
                  <img className="field-tooltip" alt="info icon" src={InfoIcon} />
                </Tooltip>
              )}
            </div>
          )}
          {el.subLabel && <div className="field-sub-label">{el.subLabel}</div>}

          <div className={`custom-field-container ${el.containerClass} ${el.fieldTooltip ? 'no-wrap' : ''}`}>
            <div>
              <FieldManager className="custom-field-container-field" data={el} key={el.id} />
              {el.fieldTooltip && (
                <Tooltip
                  arrow
                  placement="right"
                  title={el.fieldTooltip}
                  classes={{ arrow: 'tooltip-custom-arrow', tooltip: 'tooltip-custom' }}
                >
                  <img alt="info icon" className="custom-field-container-tooltip" src={InfoIcon} />
                </Tooltip>
              )}
            </div>
          </div>
          {el.info &&
            (typeof el.info === 'string' ? (
              <div className="custom-field-container-info">{el.info}</div>
            ) : (
              React.cloneElement(el.info, {
                className: ['custom-field-container-info', ...el.classnames].join(' '),
              })
            ))}
        </div>
      ))}
    </div>
  );
};

FieldsetManager.propTypes = {
  data: PropTypes.object,
};

export default FieldsetManager;

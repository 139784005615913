import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { isOutOfRange, isRecencyDatePickerOutsideRange } from '../utils';
import { config } from '../index';
import FormManager from 'components/FormManager';

import styles from '../ConditionsForm/ConditionsForm.module.scss';

// this innerCondition is different from the others
// it changes the period property of the segment
const LastVisitBlock = ({ data, groupIndex, listIndex, period, updatePeriod }) => {
  const { t } = useTranslation();
  const [dateFocusedInputState, setDateFocusedInputState] = useState(null);

  useEffect(() => {
    // add sign in period value shape
    const newPeriod = JSON.parse(JSON.stringify(period));
    newPeriod.value.sign = period.value.sign || config.lists.recency.sign[0];
    updatePeriod(newPeriod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isOutsideRange = (date) => {
    return isRecencyDatePickerOutsideRange({
      date,
      dateFocusedInputState,
      end_date: period.value.end_date,
      start_date: period.value.start_date
    });
  };

  return (
    <FormManager
      data={{
        fieldsets: [
          {
            id: `segment-manager-multipurchases-inner-condition-${data.unit}-${groupIndex}-${listIndex}`,
            classnames: [styles['one-line']],
            fields:
              period.type === 'over'
                ? [
                    {
                      type: 'TextField',
                      classnames: [styles['input'], styles['input-large']],
                      defaultValue: t('segmentManager_static_having_bought_last_visit'),
                      fieldProps: {
                        readOnly: true,
                        shrinkPlaceholder: false
                      },
                      id: `inner-condition-wording-static-${data.unit}-${groupIndex}-${listIndex}`
                    },
                    {
                      type: 'Select',
                      classnames: [styles['input'], styles['input-small']],
                      defaultValue: period.type || config.lists.commun.period[0],
                      fieldProps: {
                        listData: config.lists.commun.period.map((el) => ({
                          id: el,
                          label: t(`_dyn_commun_select_period_${el}`)
                        })),
                        shrinkPlaceholder: false
                      },
                      id: `inner-condition-period-type-${data.unit}-${groupIndex}-${listIndex}`,
                      onFieldChange: (value) => {
                        const newPeriod = JSON.parse(JSON.stringify(period));
                        newPeriod.type = value;
                        newPeriod.value = { ...config.types.recency.period.value }; // reset value when period changed
                        updatePeriod(newPeriod);
                      }
                    },
                    {
                      type: 'Select',
                      classnames: [styles['input'], styles['input-small']],
                      defaultValue: period.value.sign || config.lists.recency.sign[0],
                      fieldProps: {
                        listData: config.lists.recency.sign.map((el) => ({
                          id: el,
                          label: t(`_dyn_commun_select_sign_${el}`)
                        })),
                        shrinkPlaceholder: false
                      },
                      id: `inner-condition-period-sign-${data.unit}-${groupIndex}-${listIndex}`,
                      onFieldChange: (value) => {
                        const newPeriod = JSON.parse(JSON.stringify(period));
                        newPeriod.value.sign = value;
                        updatePeriod(newPeriod);
                      }
                    },
                    {
                      type: 'NumberField',
                      allowZero: false,
                      classnames: [styles['input'], styles['input-tiny']],
                      defaultValue: period.value.count || '',
                      fieldProps: {
                        InputProps: {
                          min: 0
                        },
                        shrinkPlaceholder: false
                      },
                      id: `inner-condition-period-value-${data.unit}-${groupIndex}-${listIndex}`,
                      onFieldChange: (value) => {
                        const newPeriod = JSON.parse(JSON.stringify(period));
                        newPeriod.value.count = value;

                        // reset interval Select if count is out of range
                        if (isOutOfRange({ count: value, interval: period.value.interval })) {
                          newPeriod.value.interval = null;
                        }

                        updatePeriod(newPeriod);
                      },
                      placeholder: t('commun_input_number_placeholder')
                    },
                    {
                      type: 'Select',
                      classnames: [styles['input'], styles['input-last']],
                      outerContainerClass: [styles['input-outerContainer']],
                      defaultValue: period.value.interval,
                      fieldProps: {
                        listData: config.lists.commun.interval.map((el) => ({
                          id: el,
                          label: t(`_dyn_commun_select_interval_${el}`, { count: period.value.count || 0 }),
                          disabled: isOutOfRange({ count: period.value.count, interval: el })
                        })),
                        placeHolderText: t('_dyn_commun_select_interval_placeholder'),
                        shrinkPlaceholder: false
                      },
                      id: `inner-condition-period-interval-${data.unit}-${groupIndex}-${listIndex}`,
                      onFieldChange: (value) => {
                        const newPeriod = JSON.parse(JSON.stringify(period));
                        newPeriod.value.interval = value;
                        updatePeriod(newPeriod);
                      }
                    }
                  ]
                : [
                    {
                      type: 'TextField',
                      classnames: [styles['input'], styles['input-large']],
                      defaultValue: t('segmentManager_static_having_bought_last_visit'),
                      fieldProps: {
                        readOnly: true,
                        shrinkPlaceholder: false
                      },
                      id: `inner-condition-wording-static-${data.unit}-${groupIndex}-${listIndex}`
                    },
                    {
                      type: 'Select',
                      classnames: [styles['input'], styles['input-small']],
                      defaultValue: period.type || config.lists.commun.period[0],
                      fieldProps: {
                        listData: config.lists.commun.period.map((el) => ({
                          id: el,
                          label: t(`_dyn_commun_select_period_${el}`)
                        })),
                        shrinkPlaceholder: false
                      },
                      id: `inner-condition-period-type-${data.unit}-${groupIndex}-${listIndex}`,
                      onFieldChange: (value) => {
                        const newPeriod = JSON.parse(JSON.stringify(period));
                        newPeriod.type = value;
                        newPeriod.value = { ...config.types.recency.period.value }; // reset value when period changed
                        updatePeriod(newPeriod);
                      }
                    },
                    {
                      type: 'DateRangePicker',
                      outerContainerClass: [styles['input-outerContainer-datePicker']],
                      defaultValue: {
                        startDate: period.value.start_date ? moment(period.value.start_date) : null,
                        endDate: period.value.end_date ? moment(period.value.end_date) : null
                      },
                      fieldProps: {
                        fullWidth: true,
                        isOutsideRange: isOutsideRange,
                        getFocusInput: setDateFocusedInputState
                      },
                      id: `inner-condition-period-between-dates-${data.unit}-${groupIndex}-${listIndex}`,
                      onFieldChange: (dates) => {
                        const newPeriod = JSON.parse(JSON.stringify(period));
                        newPeriod.value = {
                          end_date: dates?.endDate?.format?.('YYYY-MM-DD'),
                          start_date: dates?.startDate?.format?.('YYYY-MM-DD')
                        };
                        updatePeriod(newPeriod);
                      }
                    }
                  ]
          }
        ]
      }}
    />
  );
};

LastVisitBlock.propTypes = {
  data: PropTypes.object.isRequired,
  groupIndex: PropTypes.string.isRequired,
  listIndex: PropTypes.number.isRequired,
  updateData: PropTypes.func.isRequired
};

export default LastVisitBlock;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import {
  deleteOffer,
  getOffer,
  getOfferDisableStatus,
  getOfferPeriods,
  getOfferProducts,
  getProductsCount,
  updateOffer,
  updateOfferImage
} from 'api';
import {
  offerStatuses,
  discountTypes,
  offerTypes,
  campaignStates,
  campaignTargetingStatuses,
  offerHeadTypes
} from 'utils/constants';
import { withFractionDigits } from 'utils/global';
import moment from 'moment-timezone';

import { ClickAwayListener } from '@mui/material';
import AccordionCustom from 'components/Accordion';
import GeneralInfoBox, { GeneralInfoBoxContainer } from 'components/GeneralInfoBox';
import ButtonsCustom from 'components/ButtonsCustom';
import OfferDetailsAlert from 'components/OfferDetailsAlert';
import ContentDialog from 'components/ContentDialog';
import Tooltip from 'components/Tooltip';
import OptionMenuPopover from 'components/OptionMenuPopover';
import Loader from 'components/Loaders/Dots';
import FramedIcon from 'components/FramedIcon';
import SimpleTable from 'components/TableCustom/SimpleTable';
import TableCustom from 'components/TableCustom';
import UnknownId from 'components/Drawer/UnknownId';
import GoBackLink from 'components/GoBackLink';
import DrawerHeader from 'components/DrawerHeader';
import useDynId from 'customHooks/useDynId';
import Tabs from 'components/TabsInModal';
import OfferBudget from './OfferBudget';
import CampaignTargetingStatusTag from 'components/CampaignTargetingStatusTag';
import RoasFrame from 'components/RoasFrame';
import Tags from 'components/Tags';
import NewFeaturePopup from 'components/NewFeaturePopup';
import OfferDisableDialog from 'components/OfferDisableDialog';
import FormManager from 'components/FormManager';
import PictureGallery from 'components/PictureGallery';

import noImageIcon from 'assets/no_image.svg';
import { ReactComponent as ArchiveIcon } from 'assets/30px_archived.svg';
import { ReactComponent as CameraIcon } from 'assets/30px_camera.svg';
import { ReactComponent as ClientIcon } from 'assets/24px_people.svg';
import { ReactComponent as CopyIcon } from 'assets/copy.svg';
import { ReactComponent as DeleteIcon } from 'assets/16px_trash.svg';
import { ReactComponent as DuplicateIcon } from 'assets/duplicate.svg';
import { ReactComponent as PencilIcon } from 'assets/16px_edit.svg';
import { ReactComponent as RocketIcon } from 'assets/24px_fusée.svg';
import { ReactComponent as RoundCrossIcon } from 'assets/30px_status_fail.svg';
import { ReactComponent as SuccessIcon } from 'assets/30px_check.svg';
import { ReactComponent as TooltipIcon } from 'assets/30px_info_round.svg';
import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';
import { ReactComponent as WaveIcon } from 'assets/30px_campagne.svg';

import styles from './OffersDetails.module.scss';

const OffersDetails = ({
  details: { offer, offerCampaigns, brandList },
  offerHeadId,
  onUpdated,
  onDeleted,
  readOnly = false,
  user
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const defaultCropData = {
    crop: offer?.offerHead?.fileDescriptor?.croppedImageMetadata?.crop,
    cropSize: {
      width: offer?.retailer?.settingOfferImageHorizontalSize,
      height: offer?.retailer?.settingOfferImageVerticalSize
    },
    croppedAreaPixels: offer?.offerHead?.fileDescriptor?.croppedImageMetadata?.croppedAreaPixels,
    zoom: offer?.offerHead?.fileDescriptor?.croppedImageMetadata?.zoom
  };

  const currency = offer.retailer?.currency?.code;
  const isSupplierList = offer.type === offerTypes.SUPPLIER_PRODUCT_OFFER;

  const isDisabledStatus = offer.status === offerStatuses.DISABLED;
  const isDraftStatus = offer.status === offerStatuses.DRAFT;
  const isExpiredStatus = offer.status === offerStatuses.EXPIRED;
  const isProposalStatus = offer.status === offerStatuses.PROPOSAL;
  const isToValidatedStatus = offer.status === offerStatuses.TO_VALIDATE;
  const isValidatedStatus = offer.status === offerStatuses.VALIDATED;

  const [dialogCancelOpen, setDialogCancelOpen] = useState(false);
  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
  const [dialogDisableOpen, setDialogDisableOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [newFile, setNewFile] = useState(offer.offerHead?.fileDescriptor);
  const [slug, setSlug] = useState(null);
  const [offerIsUpdating, setOfferIsUpdating] = useState(false);
  const [cropData, setCropData] = useState(defaultCropData);
  const [imageError, setImageError] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [sortedOfferCampaigns, setSortedOfferCampaigns] = useState(null); // [[TO_COME],[IN_PROGRESS],[TERMINATED]]
  const [offerDisableStatus, setOfferDisableStatus] = useState(null);
  const [offerPeriods, setOfferPeriods] = useState(null);
  const [newFeatureCopyClipboardVisible, setNewFeatureCopyClipboardVisible] = useState(false); // usefull for NewFeaturePopup when in an Accordion component

  const editImageId = useDynId('editImage');
  const editId = useDynId('edit');
  const deleteId = useDynId('delete');
  const duplicateId = useDynId('duplicate');
  const generalInfoId = useDynId('generalinfo');
  const productlistId = useDynId('productlist');
  const perfId = useDynId('perf');
  const campaignId = useDynId('campaign');
  const statusId = useDynId('status');

  const copy_clipboard_button_ref = useRef(null);

  const [resultListEan, setResultListEan] = useState([]);
  const [countProducts, setCountProducts] = useState([]);
  const [paginatedProducts, setPaginatedProducts] = useState([]);
  const [orderProductTable, setOrderProductTable] = useState('asc');
  const [sortProductTable, setSortProductTable] = useState('code');
  const [productsListPage, setProductsListPage] = useState(0);
  const productsListSize = 25;

  const isSupplier = user.userType === 'supplier';
  const damName = isSupplier ? offer.retailer?.damName : user?.damName;

  const isBrandOffer = offer.offerHead?.type === offerHeadTypes.BRAND_OFFER;

  const fetchOfferData = async () => {
    setLoading(true);
    const offerData = await getOffer(offerHeadId);
    const offerDisableStatusData = isSupplier || readOnly ? null : await getOfferDisableStatus(offerHeadId);

    if (offerData) {
      setCropData({
        crop: offerData.offerHead.fileDescriptor?.croppedImageMetadata?.crop,
        cropSize: {
          width: offerData?.retailer?.settingOfferImageHorizontalSize,
          height: offerData?.retailer?.settingOfferImageVerticalSize
        },
        croppedAreaPixels: offerData.offerHead.fileDescriptor?.croppedImageMetadata?.croppedAreaPixels,
        zoom: offerData.offerHead.fileDescriptor?.croppedImageMetadata?.zoom
      });

      const products = await getOfferProducts({ offerId: offerData.id });
      setResultListEan(products);

      //multiPeriods
      if (offerData.offerHead.multiPeriod) {
        const offerPeriodsResponse = await getOfferPeriods(offerHeadId);
        if (offerPeriodsResponse) {
          setOfferPeriods(offerPeriodsResponse);
        }
      }
    }
    if (offerDisableStatusData) {
      setOfferDisableStatus(offerDisableStatusData);
    }
    setLoading(false);
  };

  useEffect(() => {
    const sliceResult = resultListEan.slice(
      productsListSize * productsListPage,
      productsListSize * (productsListPage + 1)
    );
    setPaginatedProducts(
      sliceResult.map((el) => ({
        id: [{ value: el.id }],
        code: [{ value: el.code, color: 'ean', textStyle: 'tag' }],
        description: [{ value: el.description }]
      }))
    );
  }, [resultListEan, productsListPage]);

  useEffect(() => {
    if (offerCampaigns.length) {
      // check if has at least one allocated valid campaign
      const hasAllocatedValidCampaign = offerCampaigns.find(
        (el) =>
          campaignTargetingStatuses.SUCCESS === el.campaign.targetingStatus &&
          [campaignStates.IN_PROGRESS, campaignStates.TERMINATED].includes(el.campaign.campaignState)
      );
      if (hasAllocatedValidCampaign) {
        setTabIndex(1);
      }

      // sort offer campaigns
      let sortedCampaigns = [[], [], []]; // [[TO_COME],[IN_PROGRESS],[TERMINATED]]
      offerCampaigns.forEach((oc) => {
        const campaignState = oc.campaign.campaignState;
        let sortedCampaignsIndex = 0; // campaignState === campaignStates.TO_COME

        if (campaignState === campaignStates.IN_PROGRESS) {
          sortedCampaignsIndex = 1;
        } else if (campaignState === campaignStates.TERMINATED) {
          sortedCampaignsIndex = 2;
        }
        sortedCampaigns[sortedCampaignsIndex].push(oc);
      });
      setSortedOfferCampaigns(sortedCampaigns);
    }
  }, [offerCampaigns]);

  useEffect(() => {
    if (offerHeadId) fetchOfferData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerHeadId]);

  useEffect(() => {
    const fetchData = async () => {
      const countProductsResult = await getProductsCount({
        brandIds: brandList?.map((i) => i.id),
        supplierId: offer.offerHead.budget?.supplier?.id
      });
      setCountProducts(countProductsResult);
    };

    if (brandList?.length) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandList]);

  if (loading) {
    return (
      <div className={styles['offers-details']}>
        <DrawerHeader
          title={isBrandOffer ? t('offer_details_summary_brand') : t('offer_details_summary')}
          buttons={[
            <div key="offer-details-button-placeholder-1" className={styles['offer-header-button-placeholder']} />
          ]}
        />

        <div className={styles['offer-details']}>
          <div className={styles['offer-details-image-container-placeholder']} />
          <div className={styles['offer-details-block-placeholder']}>
            <div className={styles['offer-details-block-tag-placeholder']} />
            <div className={styles['offer-details-block-name-placeholder']} />

            <div className={styles['offer-details-block-generosity-placeholder']} />
            <div className={styles['offer-details-block-title-placeholder']} />

            <div className={styles['offer-details-block-dates']}>
              <div className={styles['offer-details-block-dates-placeholder']} />
            </div>
          </div>
        </div>

        <div className={styles['offer-details-loading']}>
          <p className={styles['offer-details-loading-message']}>{t('offer_details_loading_message')}</p>
          <Loader />
        </div>
      </div>
    );
  }

  if (!offer.offerHead?.title) {
    return <UnknownId />;
  }

  const today = moment();

  const isRetailer = user.userType === 'retailer';
  const offerAssociatedName = isRetailer ? offer.offerHead.budget?.supplier?.name : offer.retailer?.name;
  const isRetailerOffer = offer.type === offerTypes.RETAILER_PRODUCT_OFFER;

  const hasNoCampaign = offerCampaigns.length === 0;
  const hasAllocatedCampaign = !!offerCampaigns.find(
    (el) =>
      ![campaignTargetingStatuses.NOT_REQUESTED, campaignTargetingStatuses.FAIL_DATA].includes(
        el.campaign.targetingStatus
      )
  );

  const generosity =
    offer.discountUnit === 'CURRENCY'
      ? t('commun_price', {
          value: offer.discountValue ?? '-',
          currency,
          maximumFractionDigits: 2
        })
      : `${offer.discountValue ?? '-'} %`;

  const generalInfos = [
    {
      label: t('commun_target_marketing'),
      value: t(`commun_marketingStrategy_${offer.targetingStrategy?.targetMarketing?.code}_title`)
    },
    { label: t('segment_table_id'), value: offer.offerHead.id },
    {
      label: isBrandOffer && t('offers_creation_brand'),
      value: brandList?.map((i) => i.name).join(', ') || '-',
      fullWidth: true
    },
    { label: isBrandOffer && t('offers_creation_count_product'), value: countProducts },
    { label: t('commun_tag'), value: offer.offerHead.descriptionTag || '-' },
    {
      label: t('offers_creation_discount_mechanism_type'),
      value: t(`_dyn_commun_${discountTypes.find((t) => t.denomination === offer.discountType)?.label}`)
    },
    { label: t('offers_creation_quantity_to_add'), value: offer.discountMinQuantityOfProducts },
    { label: t('offers_creation_max_number_use'), value: offer.discountMaximumUses },
    {
      label: offer.offerHead?.budget?.type
        ? `${t('commun_budget_target')} - ` + t(`commun_budget_type_${offer.offerHead?.budget?.type}`)
        : t('commun_budget_target'),
      value:
        offer.offerHead.budgetTarget === -1
          ? t('commun_unlimited')
          : t('commun_price', {
              value: offer.offerHead?.budgetTarget ?? '-',
              currency
            }),
      status: 'success'
    },
    {
      label: t('offers_creation_offer_additionalInformation'),
      value: offer.offerHead.additionalInformation || '-',
      fullWidth: true
    }
  ];

  const sortedStatusHistory = offer.statusUpdatesHistory?.sort((a, b) =>
    moment(b.statusUpdatedAt).diff(moment(a.statusUpdatedAt))
  );

  const resetModificationStates = () => {
    handleCloseDialog();
    setSlug(null);
  };

  const handleValidateOffer = async ({ newStatus }) => {
    setOfferIsUpdating(true);

    const updated = await updateOffer({
      offer: {
        ...offer,
        budget: { id: offer.offerHead.budget?.id },
        descriptionTag: offer.offerHead.descriptionTag || '',
        status: newStatus || offer.status
      }
    });

    if (updated) {
      await fetchOfferData();
      onUpdated();
    }

    setOfferIsUpdating(false);
    return !!updated;
  };

  const handleImageChange = (imageData) => {
    if (imageData?.file) {
      setCropData(imageData?.cropData);
      setNewFile(imageData?.file);
    }
  };

  const handleUpdateImageOffer = async () => {
    setOfferIsUpdating(true);
    const updated = await updateOfferImage({
      file: newFile,
      cropData: cropData,
      offer
    });

    if (updated) {
      const offer = await getOffer(offerHeadId);

      if (offer) {
        setNewFile(offer.offerHead.fileDescriptor);
        onUpdated();
      }
    }

    setOfferIsUpdating(false);
    return !!updated;
  };

  const handleDeleteButton = (ev) => {
    setDialogDeleteOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogCancelOpen(false);
    setDialogDeleteOpen(false);
    setDialogDisableOpen(false);
  };

  const handleCancelDialog = () => {
    if (newFile) {
      setDialogCancelOpen(true);
    } else {
      resetModificationStates();
    }
  };

  const handleEditButton = () => {
    history.push(`/offers/add-offer/${offerHeadId}`);
  };

  const handleDuplicateButton = () => {
    history.push({
      pathname: '/offers/add-offer',
      offerIdToClone: offerHeadId
    });
  };

  const handleDeleteOffer = async () => {
    setIsDeleteLoading(true);
    const deleted = await deleteOffer({ offerId: offer.id, offerHeadId: offer.offerHead.id });
    setIsDeleteLoading(false);

    if (deleted) {
      onDeleted();
    }
  };

  const handleOpenDisableDialog = () => {
    setDialogDisableOpen(true);
  };

  const handleDisplayPartialEdit = (step) => {
    setSlug(step);
  };

  const handleSort = (sort) => {
    const data = {
      sort: sort,
      order: orderProductTable
    };
    handleListUpdate(data);
    setOrderProductTable(orderProductTable !== 'desc' ? 'desc' : 'asc');
    setSortProductTable(sort);
  };

  const handleListUpdate = (data) => {
    let newResultList = [...resultListEan];

    // Order by code
    if (data.sort === 'code' && data.order === 'asc') {
      newResultList = newResultList.sort((a, b) => a.code - b.code);
    }

    if (data.sort === 'code' && data.order === 'desc') {
      newResultList = newResultList.sort((a, b) => b.code - a.code);
    }

    //Order by description
    if (data.sort === 'description' && data.order === 'asc') {
      newResultList = newResultList.sort((x, y) => {
        let a = x.description.toUpperCase(),
          b = y.description.toUpperCase();
        return a === b ? 0 : a > b ? 1 : -1;
      });
    }

    if (data.sort === 'description' && data.order === 'desc') {
      newResultList = newResultList.sort((x, y) => {
        let a = x.description.toUpperCase(),
          b = y.description.toUpperCase();
        return a === b ? 0 : a > b ? -1 : 1;
      });
    }

    setResultListEan(newResultList);
    setProductsListPage(0);
  };

  const rows = paginatedProducts;

  const columns = [
    { id: 1, field: 'code', headerName: 'EAN', type: 'text' },
    { id: 2, field: 'description', headerName: 'LIBELLE', type: 'text' }
  ];

  const columnsTranslated = columns.map((i) => {
    i.headerName = t(`_dyn_commun_${i.field}`);
    return i;
  });

  const displayPerfKpis = () => {
    let data = [
      {
        title: t('commun_clients'),
        icon: <ClientIcon />,
        kpis: [
          {
            value: t('commun_number', {
              value: offer.offerHead.transactionalKpis?.sampleATargetedShoppers ?? '-'
            }),
            desc: t('_dyn_campaign_details_keypoints_targetedClient_product', {
              count: offer.offerHead.transactionalKpis?.sampleATargetedShoppers ?? '-'
            }),
            tooltip: t('_dyn_campaign_details_keypoints_targetedClient_product_tooltip')
          },
          {
            value: t('commun_number', {
              value: offer.offerHead.transactionalKpis?.usedShoppers ?? '-'
            }),
            desc: t('offer_details_kpi_used_shoppers', { count: offer.offerHead.transactionalKpis?.usedShoppers || 0 }),
            tooltip: t('offer_details_kpi_used_shoppers_tooltip')
          },
          {
            value: t('commun_number', {
              value: offer.offerHead.transactionalKpis?.usedCoupons ?? '-'
            }),
            desc: t('offer_details_kpi_used_coupons', { count: offer.offerHead.transactionalKpis?.usedCoupons || 0 }),
            tooltip: t('offer_details_kpi_used_coupons_tooltip')
          }
        ]
      },
      {
        title: t('commun_results'),
        icon: <RocketIcon />,
        kpis: [
          {
            value: t('commun_price', {
              value: offer.offerHead.transactionalKpis?.estimatedTurnover ?? '-',
              maximumFractionDigits: withFractionDigits(offer.offerHead.transactionalKpis?.estimatedTurnover),
              currency
            }),
            desc: t('commun_estimatedTurnover'),
            tooltip: <Trans i18nKey="commun_estimatedTurnover_tooltip" />
          },
          {
            value: `${t('commun_number', {
              value:
                offer.offerHead?.transactionalKpis?.usingRate !== undefined &&
                offer.offerHead?.transactionalKpis?.usingRate !== null
                  ? offer.offerHead?.transactionalKpis?.usingRate * 100
                  : '-',
              maximumFractionDigits: withFractionDigits(offer.offerHead?.transactionalKpis?.usingRate * 100)
            })} %`,
            desc: t('commun_usingRate'),
            tooltip: t('commun_usingRate_tooltip')
          }
        ]
      }
    ];

    return (
      <div className={styles['offer-details-campaign-perf']}>
        <OfferBudget
          budgetSpent={offer.offerHead.budgetSpent}
          budgetTarget={offer.offerHead.budgetTarget}
          budgetType={offer.offerHead.budget?.type}
          currency={currency}
          isExpiredOrDisabled={isExpiredStatus || isDisabledStatus}
          isSupplierList={isSupplierList}
          onUpdated={onUpdated}
        />
        <div className={styles['divider']}></div>
        {data.map((group, index) => (
          <div key={group.title} className={styles['offer-details-campaign-perf-block']}>
            {index !== 0 && <div className={styles['divider']}></div>}
            <div className={styles['title-container']}>
              <FramedIcon icon={group.icon} />
              {group.title}
            </div>
            <div className={styles['content-container']}>
              {group.kpis.map((kpi, index) => {
                if (!kpi) return <div key={`empty-${index}`} className={styles['empty-kpi-block']}></div>;
                return (
                  <div className={styles['block']} key={kpi.desc}>
                    <div className={styles['value']}>{kpi.value}</div>
                    <div className={styles['desc']}>
                      {kpi.desc}
                      <Tooltip title={kpi.tooltip || ''}>
                        <TooltipIcon />
                      </Tooltip>
                      <span></span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
        {offer.offerHead?.transactionalKpis && <RoasFrame value={offer.offerHead.transactionalKpis?.returnOnAdSpent} />}
      </div>
    );
  };

  const displayCampaignsKpis = () => {
    if (!sortedOfferCampaigns) return null;
    return (
      <div className={styles['offer-details-campaigns-kpi-block']}>
        {sortedOfferCampaigns.map((offerCampaign, index) => {
          if (offerCampaign.length) {
            const campaignStateLabels = [campaignStates.TO_COME, campaignStates.IN_PROGRESS, campaignStates.TERMINATED];
            const colorClass = `color-${campaignStateLabels[index].toLowerCase()}`;
            return (
              <AccordionCustom
                key={index}
                id={`offer-campaign-${campaignStateLabels[index].toLowerCase()}`}
                summary={
                  <div className={`${styles['nested-summary']} ${styles[colorClass]}`}>
                    <Trans
                      i18nKey={`offer_details_campaigns_state_${campaignStateLabels[index].toLowerCase()}`}
                      values={{ count: offerCampaign.length }}
                    />
                  </div>
                }
              >
                <SimpleTable
                  rows={offerCampaign.map((el) => {
                    return {
                      id: [{ value: el.campaign.id }],
                      title: [{ value: el.campaign.title }],
                      targetingStatus: <CampaignTargetingStatusTag status={el.campaign.targetingStatus} />,
                      targetedClients: [
                        {
                          color: 'disabled',
                          value: t('commun_number', {
                            value: el.kpis?.sampleATargetedShoppers ?? '-'
                          })
                        }
                      ],
                      budgetSpent: [
                        {
                          value: t('commun_price', {
                            value: el.kpis?.discountGranted ?? '-',
                            currency
                          })
                        }
                      ]
                    };
                  })}
                  columns={[
                    {
                      id: 1,
                      field: 'title',
                      headerName: t('commun_title'),
                      type: 'text',
                      hasTooltip: true,
                      align: 'left',
                      wide: true
                    },
                    {
                      id: 2,
                      field: 'targetingStatus',
                      headerName: t('campaign_details_targeting_status'),
                      type: 'component'
                    },
                    {
                      id: 3,
                      field: 'targetedClients',
                      headerName: t('_dyn_campaign_details_keypoints_targetedClient_product_plural'),
                      type: 'text'
                    },
                    { id: 4, field: 'budgetSpent', headerName: t('commun_budget_spent'), type: 'text' }
                  ]}
                  type="small"
                />
              </AccordionCustom>
            );
          }
          return null;
        })}
      </div>
    );
  };

  if (slug === 'picture') {
    const galleryResultListEans = resultListEan.map((el) => ({
      averagePrice: el.averagePrice,
      customProductId: el.customProductId,
      ean: el.code,
      id: el.id,
      label: el.description,
      productLevelFourthCode: el.productLevelFourth?.code
    }));

    return (
      <div className={styles['offers-details']}>
        <GoBackLink
          className={styles['goBackLink']}
          customOnClick={handleCancelDialog}
          label={t('offer_details_back_to_details')}
          pathname={history.location.pathname}
        />
        <div className={styles['offer-header']}>
          <span className={styles['offer-header-title']}>{t('offer_edit_title')}</span>
        </div>

        <div className={styles['offer-details']}>
          <FormManager
            data={{
              fieldsets: [
                {
                  id: 'add-offer-picture',
                  fields: [
                    {
                      type: 'ImageChoice',
                      label: t('offers_creation_image_choice_title'),
                      defaultValue: newFile || offer.offerHead?.fileDescriptor,
                      originalFile: offer.offerHead?.fileDescriptor,
                      mediumFile: offer?.offerHead?.imageMediumFileDescriptor,
                      defaultCropData: cropData,
                      fieldProps: {
                        gallery: <PictureGallery />,
                        productsGalleryList: galleryResultListEans,
                        productsPreviewList: damName ? galleryResultListEans : [],
                        retailer: offer?.retailer
                      },
                      onFieldChange: handleImageChange,
                      onFieldError: setImageError,
                      id: 'image'
                    }
                  ]
                }
              ]
            }}
          />
        </div>

        <div className={styles['offer-partialEdit-action-button-sticky']}>
          <ButtonsCustom
            classType="canceled"
            text={t('commun_cancel')}
            method={handleCancelDialog}
            disabled={offerIsUpdating}
          />
          <ButtonsCustom
            classType="action_primary_big"
            text={t('commun_button_save')}
            method={async () => {
              const updated = await handleUpdateImageOffer();
              if (updated) {
                resetModificationStates();
              }
            }}
            loading={offerIsUpdating}
            disabled={imageError}
          />
        </div>

        <ContentDialog
          centerText
          isOpen={dialogCancelOpen}
          handleClose={handleCloseDialog}
          title={t('commun_dialog_confirm_change')}
          maxWidth="xs"
        >
          <div className={styles['confirm-dialog']}>
            <ButtonsCustom classType="canceled" text={t('commun_cancel')} method={handleCloseDialog} />
            <ButtonsCustom
              classType="action_primary_big"
              text={t('commun_button_yes_continue')}
              method={resetModificationStates}
            />
          </div>
        </ContentDialog>
      </div>
    );
  }

  // Header Buttons
  const buttons = [];
  if (!isRetailerOffer) {
    buttons.push(
      <ButtonsCustom
        disabled={isDisabledStatus || readOnly}
        id={editId}
        key="offer-edit-button"
        classType="link_black"
        method={handleEditButton}
        startIconCustom={<PencilIcon />}
        text={t('commun_offer_option_menu_edit')}
        tooltip={isDisabledStatus ? t('offer_details_edit_disabled_tooltip') : ''}
      />,
      <ButtonsCustom
        // TODO: GET supplier-budgets/count with year >= currentYear to check disabled
        id={duplicateId}
        key="offer-duplicate-button"
        classType="link_black"
        method={handleDuplicateButton}
        startIconCustom={<DuplicateIcon />}
        text={t('commun_offer_option_menu_duplicate')}
      />
    );
  }

  if (isRetailer) {
    const tooltipOrderCode = ['OFF002', 'OFF001', 'CAMP001', 'CAMP002']; // order matters
    const tooltipCode = tooltipOrderCode
      .map((code) => offerDisableStatus?.infos.find((el) => el.code === code))
      .filter((el) => !!el)[0]?.code;

    buttons.push(
      <ButtonsCustom
        disabled={!offerDisableStatus?.disable || readOnly}
        id={editId}
        key="offer-disable-button"
        classType="link_black"
        method={handleOpenDisableDialog}
        startIconCustom={<ArchiveIcon />}
        text={t('commun_offer_option_menu_disable')}
        tooltip={
          !offerDisableStatus || offerDisableStatus?.disable
            ? ''
            : t(`offer_details_disable_status_code_${tooltipCode}`)
        }
      />
    );
  }

  if (offerCampaigns.length > 0) {
    buttons.push(
      <ButtonsCustom
        id={deleteId}
        disabled
        classType="link_black"
        key="offer-delete-button"
        method={handleDeleteButton}
        startIconCustom={<DeleteIcon />}
        text={t('commun_offer_option_menu_delete')}
        tooltip={t('offer_details_delete_tooltip')}
      />
    );
  } else if (isDraftStatus && offer.type === offerTypes.RETAILER_PRODUCT_OFFER) {
    buttons.push(
      <ButtonsCustom
        id={deleteId}
        disabled
        classType="link_black"
        key="offer-delete-button"
        method={handleDeleteButton}
        startIconCustom={<DeleteIcon />}
        text={t('commun_offer_option_menu_delete')}
        tooltip={t('offer_details_draft_delete_tooltip')}
      />
    );
  } else {
    buttons.push(
      <div key="offer-delete-button-enabled">
        <ButtonsCustom
          id={deleteId}
          disabled={isDisabledStatus || readOnly}
          classType="link_black"
          method={handleDeleteButton}
          startIconCustom={<DeleteIcon />}
          text={t('commun_offer_option_menu_delete')}
        />
      </div>
    );
  }

  const displayMultiPeriods = () => {
    let highlightedPeriodIndex = offerPeriods.findIndex((period) =>
      today.isBetween(moment(period.validityStartDate), moment(period.validityEndDate), 'day', '[]')
    );
    if (highlightedPeriodIndex === -1) {
      const index = offerPeriods.findIndex((period) => moment(period.validityStartDate).isAfter(today, 'day'));
      highlightedPeriodIndex = index !== -1 ? index : offerPeriods.length - 1;
    }

    return (
      <AccordionCustom
        classname={styles['offer-period-accordion']}
        defaultExpanded={false}
        summary={
          <>
            {isExpiredStatus && <WarningIcon className={styles['warning-icon']} />}
            <span className={styles['accordion-summary']}>
              {t('commun_date', { value: offerPeriods?.[highlightedPeriodIndex]?.validityStartDate })}{' '}
              {t('commun_to_dates', { value: offerPeriods?.[highlightedPeriodIndex]?.validityEndDate })}
            </span>
          </>
        }
      >
        {offerPeriods.map((period, index) => (
          <div className={styles['period-container']} key={period.id}>
            {period.countActiveCampaign ? (
              <Tooltip title={t('offer_details_period_in_campaign_tooltip', { count: period.countActiveCampaign })}>
                <WaveIcon />
              </Tooltip>
            ) : (
              <Tooltip title={t('offer_details_period_in_no_campaign_tooltip')}>
                <RoundCrossIcon />
              </Tooltip>
            )}
            <span
              className={`${styles['accordion-summary']} ${
                index === highlightedPeriodIndex ? styles['current-period'] : ''
              } ${index > highlightedPeriodIndex ? styles['futur-period'] : ''}`}
            >
              {t('commun_date', { value: period.validityStartDate })}{' '}
              {t('commun_to_dates', { value: period.validityEndDate })}
            </span>
          </div>
        ))}
      </AccordionCustom>
    );
  };

  const handleCopyToClipboard = async () => {
    const eanList = resultListEan.map((product) => product.code).join(',');
    await navigator.clipboard.writeText(eanList);
  };

  const toggleExpandAccordionTabIndex0 = (value) => {
    setNewFeatureCopyClipboardVisible(value);
  };

  const hideNewFeatureCopyClipboardVisible = () => {
    setNewFeatureCopyClipboardVisible(false);
  };

  return (
    <ClickAwayListener onClickAway={hideNewFeatureCopyClipboardVisible}>
      <div className={styles['offers-details']}>
        <DrawerHeader
          disabledMessage={
            isDisabledStatus
              ? offer.offerHead.notificationSendingDate
                ? t('commun_disabled_date', { value: offer.offerHead.notificationSendingDate })
                : t('commun_disabled')
              : ''
          }
          title={isBrandOffer ? t('offer_details_summary_brand') : t('offer_details_summary')}
          buttons={[<OptionMenuPopover key="offer-option-menu">{buttons}</OptionMenuPopover>]}
        />

        <div className={styles['offer-details']}>
          <div className={styles['offer-details-image-container']}>
            <img
              className={styles['offer-details-image']}
              src={
                offer?.offerHead?.imageMediumFileDescriptor?.url || offer?.offerHead?.fileDescriptor?.url || noImageIcon
              }
              onError={(e) => (e.target.src = noImageIcon)}
              alt={offer.offerHead.title}
            />
            <span className={styles['offer-details-image-edit-icon']}>
              <ButtonsCustom
                id={editImageId}
                classType="action"
                method={() => {
                  handleDisplayPartialEdit('picture');
                }}
                startIconCustom={<CameraIcon />}
              />
            </span>
          </div>
          <div className={styles['offer-details-block']}>
            <div className={styles['offer-details-block-meta']}>
              <Tags status={offer.status} />
            </div>
            <span className={styles['offer-details-block-generosity']}>{generosity}</span>
            {offer.averagePrice && (
              <p className={styles['offer-details-block-averagePrice']}>
                {t('offer_details_average_price', {
                  currency,
                  maximumFractionDigits: withFractionDigits(offer.averagePrice),
                  value: offer.averagePrice
                })}
              </p>
            )}
            <p className={styles['offer-details-block-title']}>{offer.offerHead.title}</p>
            {isRetailerOffer && (
              <span className={styles['offer-details-block-meta']}>
                <Tags status={offer.privateLabel ? 'retailer_brand' : 'national_brand'} />
              </span>
            )}
            {offerAssociatedName && (
              <span className={styles['offer-details-block-meta-item']} title={offerAssociatedName}>
                {offerAssociatedName}
              </span>
            )}
            <div className={styles['offer-details-block-dates']}>
              {offerPeriods ? (
                displayMultiPeriods()
              ) : (
                <>
                  {isExpiredStatus && <WarningIcon className={styles['warning-icon']} />}
                  <span>
                    {t('commun_date', { value: offer.offerHead.validityStartDate })}{' '}
                    {t('commun_to_dates', { value: offer.offerHead.validityEndDate })}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>

        {isExpiredStatus && (
          <OfferDetailsAlert
            title="offer_details_expired_title"
            description={`_dyn_offer_details_expired_desc_${user.userType}`}
          />
        )}

        {hasNoCampaign && (
          <OfferDetailsAlert
            title="offer_details_no_campaign_title"
            description={`_dyn_offer_details_no_campaign_desc_${user.userType}`}
          />
        )}

        {isRetailer && isProposalStatus && <OfferDetailsAlert title={'offer_details_proposal_info'} />}

        {!hasNoCampaign && isValidatedStatus && !hasAllocatedCampaign && (
          <OfferDetailsAlert
            title="offer_details_validated_but_no_allocated_campaign_1"
            description={'offer_details_validated_but_no_allocated_campaign_2'}
          />
        )}

        <Tabs
          className={'margin-x-minus-40'}
          labels={[t('offer_details_tab_info'), t('offer_details_tab_perf'), t('offer_details_tab_hist')]}
          onChange={setTabIndex}
          selectedIndex={tabIndex}
        />

        {tabIndex === 0 && (
          <>
            <AccordionCustom
              expandedByDefault={true}
              id={generalInfoId}
              summaryLabel={t('offer_details_general_info_title')}
            >
              <div className={styles['some-margin-bottom']}>
                <GeneralInfoBoxContainer>
                  {generalInfos.map(
                    (el, index) =>
                      el.label && (
                        <GeneralInfoBox
                          key={`generalInfoItem-${index}`}
                          fullWidth={el.fullWidth}
                          label={el.label}
                          status={el.status}
                        >
                          <span>{el.value}</span>
                        </GeneralInfoBox>
                      )
                  )}
                </GeneralInfoBoxContainer>
              </div>
            </AccordionCustom>
            <AccordionCustom
              id={productlistId}
              handleExpandedChange={toggleExpandAccordionTabIndex0} // keep the state expanded for NewFeaturePopup visibility
              summaryLabel={t('offer_details_products_block_title')}
            >
              <div className={styles['products-list']}>
                <div className={styles['info']}>
                  <p>
                    <Trans i18nKey="offer_details_products_count" values={{ count: resultListEan.length || 0 }} />
                  </p>
                  <div
                    className={styles['copy-clipboard']}
                    id="copy-eans-to-clipboard-button"
                    onClick={handleCopyToClipboard}
                    ref={copy_clipboard_button_ref}
                    role="button"
                  >
                    <CopyIcon />
                    {t('commun_copy')}
                  </div>
                </div>
                <NewFeaturePopup
                  anchorRef={copy_clipboard_button_ref?.current}
                  desc={t('new_feature_popper_copy_clipboard_desc')}
                  expiresAt={'2024-01-22'}
                  id="copy-eans-to-clipboard-popper"
                  title={t('new_feature_popper_copy_clipboard_title')}
                  visible={newFeatureCopyClipboardVisible}
                />
                <TableCustom
                  columns={columnsTranslated}
                  rows={rows}
                  total={resultListEan.length || 0}
                  handleSort={handleSort}
                  page={productsListPage}
                  size={productsListSize}
                  sort={sortProductTable}
                  order={orderProductTable}
                  prev={() => setProductsListPage(productsListPage - 1)}
                  next={() => setProductsListPage(productsListPage + 1)}
                  showHeader={false}
                />
              </div>
            </AccordionCustom>
          </>
        )}

        {tabIndex === 1 && (
          <>
            <AccordionCustom
              expandedByDefault={hasAllocatedCampaign}
              disabled={!hasAllocatedCampaign}
              id={perfId}
              summaryLabel={t('offer_details_campaigns_perf_title')}
            >
              {displayPerfKpis()}
            </AccordionCustom>
            <AccordionCustom
              disabled={!hasAllocatedCampaign}
              id={campaignId}
              summaryLabel={t('offer_details_offer_campaign_title')}
            >
              {displayCampaignsKpis()}
            </AccordionCustom>
          </>
        )}

        {tabIndex === 2 && (
          <AccordionCustom
            expandedByDefault={!isRetailerOffer}
            disabled={isRetailerOffer}
            id={statusId}
            summaryLabel={t('offer_status')}
          >
            <div className={styles['offer-details-block-infos']}>
              {sortedStatusHistory?.map((el, index) => (
                <div
                  key={`${el.status}-${index}`}
                  className={`${styles['status-item']} ${styles[el.status.toLowerCase()]} ${
                    index === 0 ? styles['active'] : ''
                  }`}
                >
                  <Tags status={el.status} withFixedWidth />
                  <span className={styles['icon']}>
                    <SuccessIcon />
                  </span>
                  <span className={styles['text']}>
                    {el.statusUpdatedBy && el.statusUpdatedAt
                      ? t('offer_details_update_by', {
                          author:
                            el.status === offerStatuses.EXPIRED ? t('commun_by_automatisation') : el.statusUpdatedBy,
                          value: el.statusUpdatedAt
                        })
                      : ''}
                  </span>
                </div>
              ))}
            </div>
          </AccordionCustom>
        )}

        {isRetailer && isToValidatedStatus && (
          <div className={styles['offer-sticky']}>
            <div className={styles['offer-sticky-content']}>
              <div className={styles['offer-sticky-content-title']}>
                {t('offer_details_status')} <Tags status={offer.status} textOnly />
              </div>
              <span className={styles['offer-sticky-content-meta']}>
                {t('offer_details_created_by', {
                  value: offer.createdAt,
                  text: offer.creator
                })}
              </span>
            </div>
            <ButtonsCustom
              classType="action_primary_big"
              text={t('offer_details_validate')}
              method={() => handleValidateOffer({ newStatus: offerStatuses.VALIDATED })}
            />
          </div>
        )}

        {isProposalStatus && (
          <div className={styles['offer-sticky']}>
            <div className={styles['offer-sticky-content']}>
              <div className={styles['offer-sticky-content-title']}>
                {t('offer_details_status')} <Tags status={offer.status} textOnly />
              </div>
              <span className={styles['offer-sticky-content-meta']}>
                {t('offer_details_created_by', {
                  value: offer.createdAt,
                  text: offer.creator
                })}
              </span>
            </div>

            <ButtonsCustom
              classType="action_primary_big"
              text={t(!isRetailer ? '_dyn_commun_TO_VALIDATE' : 'offer_validate')}
              method={() =>
                handleValidateOffer({
                  newStatus: !isRetailer ? offerStatuses.TO_VALIDATE : offerStatuses.VALIDATED
                })
              }
            />
          </div>
        )}

        <ContentDialog
          centerText
          isLoading={isDeleteLoading}
          isOpen={dialogDeleteOpen}
          handleClose={handleCloseDialog}
          title={t('offer_details_delete_confirmation_message')}
          maxWidth="xs"
        >
          <div className={styles['confirm-dialog']}>
            <ButtonsCustom
              classType="canceled"
              disabled={isDeleteLoading}
              text={t('commun_cancel')}
              method={handleCloseDialog}
            />
            <ButtonsCustom
              classType="action_primary_big"
              loading={isDeleteLoading}
              text={t('commun_button_yes_continue')}
              method={handleDeleteOffer}
            />
          </div>
        </ContentDialog>

        <OfferDisableDialog
          dialogDisableOpen={dialogDisableOpen}
          disableStatus={offerDisableStatus}
          notifyRetailersEnabled={user.notifyRetailersEnabled}
          offerHeadId={offerHeadId}
          onCloseDialog={handleCloseDialog}
          updateData={async () => {
            await fetchOfferData();
            onUpdated();
          }}
        />
      </div>
    </ClickAwayListener>
  );
};

OffersDetails.propTypes = {
  details: PropTypes.object,
  offerHeadId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onDeleted: PropTypes.func.isRequired,
  onUpdated: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  user: PropTypes.object
};

export default OffersDetails;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReadFile, getBlobByUrl, blobToFile } from 'utils/readFile';
import { useTranslation } from 'react-i18next';

import { isFileOfTypeImage } from 'utils/global';

import Button from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import StyledImageCropper from 'components/ImageCropper';
import DamImage from 'components/DamImage';
import DragDropFile from 'components/DragDropFile';

import noImageIcon from 'assets/no_image.svg';

import { ReactComponent as Picture } from 'assets/30px_picture.svg';
import { ReactComponent as Resize } from 'assets/16px_redimensionner.svg';
import { ReactComponent as UploadIcon } from 'assets/30px_import.svg';

import styles from './ImageChoice.module.scss';
import './ImageChoice.module.scss';

const ImageChoice = (props) => {
  const {
    data: {
      id,
      defaultValue,
      originalFile,
      mediumFile,
      defaultCropData,
      fieldProps: {
        gallery,
        productsPreviewList = [],
        productsGalleryList = [],
        retailer,
        initialSelectedProduct
      } = {},
      disabled,
      displayCustomReco,
      displayRemoveButton,
      importFeatureOnly
    },
    onChange,
    onError,
    error
  } = props;

  const { t } = useTranslation();
  const [extensionError, setExtensionError] = useState(false);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [fileReaded, setFileReaded] = useState(null);
  const [rawAddedImage, setRawAddedImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [mediumImage, setMediumImage] = useState(null);

  const selectedImage = defaultValue
    ? defaultValue.image || (defaultValue.type && !defaultValue.contentType) || defaultValue
    : null;
  const imageUrl = defaultValue?.url;
  const fileName = originalImage?.name ? originalImage.name : defaultValue?.name ? defaultValue?.name : '';
  const fileType = originalImage?.name
    ? originalImage.type
    : defaultValue?.contentType
    ? defaultValue?.contentType
    : '';

  useEffect(() => {
    const transformImageToFileReaded = async (file) => {
      const imgSrc = await ReadFile(file.file || file);
      setFileReaded(imgSrc);
    };

    if (defaultValue && !defaultValue?.url) {
      transformImageToFileReaded(defaultValue);
    } else if (originalFile?.url) {
      handleDefaultImage(originalFile?.url);
    } else {
      setFileReaded(null);
      setRawAddedImage(null);
      setOriginalImage(null);
      setMediumImage(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, originalFile]);

  const handleDefaultImage = async (url) => {
    const defaultFile = await getBlobByUrl(url);
    const imgSrc = await ReadFile(defaultFile);

    setOriginalImage(defaultFile);
    setMediumImage(mediumFile?.url);
    setRawAddedImage(imgSrc);
    setFileReaded(imgSrc);
  };

  /**
   *
   * Previous argument ev InputEvent<br>
   * Event if multiple files are dropped, DragDropFile will pass the first in the FileList object
   * in e.dataTransfer.files
   *
   * @param file File<br>
   *  lastModified: timestamp - number<br>
   *  lastModifiedDate: Date<br>
   *  name: file name in the file system - string<br>
   *  size: size in B -  number<br>
   *  type: "image/png" - string, empty if not accepted<br>
   *  webkitRelativePath: "" Don't have a clue - string<br>
   *
   *
   * @returns {Promise<void>}
   */
  const handleAddImage = async (file) => {
    const imgSrc = await ReadFile(file);
    // this is unlikely in this case because any dragged n dropped file has a name (from file system)
    const getFile = !file.name && (await blobToFile(file, `file_${Date.now()}`, file.type));

    if (isFileOfTypeImage(file)) {
      setExtensionError(false);
      if (onError) onError(false);
    } else {
      setExtensionError(true);
      if (onError) onError(true);
    }

    setOriginalImage(getFile || file);
    setMediumImage(null);
    setRawAddedImage(imgSrc);
    setFileReaded(imgSrc);

    if (onChange) onChange(id, { file: getFile || file });
  };

  const handleImageChange = async ({ croppedImage, crop, cropSize, zoom, croppedAreaPixels }) => {
    const file = await getBlobByUrl(croppedImage);
    const imgSrc = await ReadFile(file);
    const getFile = blobToFile(originalImage, fileName, fileType);
    const imageMetadata = { crop, cropSize, zoom, croppedAreaPixels };

    const imageData = {
      file: getFile || originalImage,
      cropData: imageMetadata
    };

    setOriginalImage(imgSrc);
    setMediumImage(imgSrc);
    setFileReaded(imgSrc);

    if (onChange) onChange(id, imageData);
  };

  const handleRemoveImage = () => {
    setOriginalImage(null);
    setMediumImage(null);
    setRawAddedImage(null);
    setFileReaded(null);

    if (onChange) onChange(id, null);
  };

  const handleDamImageClick = async (file) => {
    if (!disabled) {
      const imgSrc = await ReadFile(file);

      setOriginalImage(file);
      setMediumImage(null);
      setRawAddedImage(imgSrc);
      setFileReaded(imgSrc);
      setExtensionError(false);
      if (onError) onError(false);

      if (onChange) onChange(id, { file });
    }
  };

  const previewList = productsPreviewList.filter((el) => el.customProductId).slice(0, 5);
  const noPreviews = previewList.length === 5 ? [] : Array(5 - previewList.length).fill(null);

  return (
    <div className={`${styles['image-choice']} ${error || extensionError ? styles['error'] : ''}`}>
      <div className={styles['image-choice-drag-n-drop']}>
        <h3 className={styles['image-choice-drag-n-drop-title']}>{t('offers_creation_block_image_drop_area_title')}</h3>
        <DragDropFile acceptedFileType="image/*" disabled={disabled} hasForm={false} onDropFile={handleAddImage}>
          <div className={clsx(styles['drag-drop-zone'], disabled && styles['disabled'])}>
            <UploadIcon />
            <p className={styles['drag-drop-zone-text']}>
              <span className={styles['drag-drop-zone-text-red']}>
                {t('offers_creation_block_image_drop_area_drag_n_drop')}
              </span>{' '}
              <span>{t('offers_creation_block_image_drop_area_or')}</span>{' '}
              <span className={styles['drag-drop-zone-text-red']}>
                {t('offers_creation_block_image_drop_area_enclose')}
              </span>{' '}
              <span>{t('offers_creation_block_image_drop_area_a_picture')}</span>
            </p>
          </div>
        </DragDropFile>

        {extensionError && <div className={styles['image-choice-error']}>{t('offers_creation_image_type_error')}</div>}
      </div>

      {!importFeatureOnly && previewList.length > 0 && (
        <div className={styles['image-choice-selection']}>
          <h3 className={styles['image-choice-selection-title']}>{t('offers_creation_image_choice_selection')}</h3>
          <div className={styles['image-choice-selection-preview']}>
            {previewList.map((el) => (
              <DamImage
                key={`image-choice-${el.ean}`}
                className={`${styles['image-choice-selection-dam-img']} ${disabled ? styles['disabled'] : ''}`}
                retailerCode={retailer?.code}
                productCode={el.ean}
                onClick={handleDamImageClick}
              />
            ))}
            {noPreviews.map((el, index) => (
              <div key={`no-preview-${index}`} className={styles['image-choice-selection-preview-img']} />
            ))}
          </div>
          {gallery && (
            <Button
              classType="link_primary"
              disabled={disabled}
              fullWidth
              method={() => setGalleryOpen(!galleryOpen)}
              size="medium"
              startIconCustom={<Picture />}
              text={t('offers_creation_image_choice_browse')}
              textAlign="left"
            />
          )}
        </div>
      )}

      <div className={styles['image-choice-preview']}>
        <h3 className={styles['image-choice-preview-title']}>{t('offers_creation_image_choice_preview_title')}</h3>
        <div
          className={`${styles['image-choice-preview-image-container']} ${
            !imageUrl && !selectedImage ? styles['no-image'] : ''
          }`}
        >
          <img src={mediumImage || imageUrl || fileReaded || noImageIcon} alt="no selection" />
        </div>
        {!importFeatureOnly && !!selectedImage && (
          <Button
            disabled={disabled || extensionError}
            classType="link_primary"
            fullWidth
            method={() => setCropperOpen(!cropperOpen)}
            size="medium"
            startIconCustom={<Resize />}
            text={t('offers_creation_image_choice_resize')}
            textAlign="left"
          />
        )}
      </div>
      {displayCustomReco && <div className={styles['picture-size-reco']}>{displayCustomReco}</div>}
      {displayRemoveButton && (
        <Button classType="link_primary" method={handleRemoveImage} text={t('commun_button_delete')} />
      )}

      {!importFeatureOnly && (
        <ContentDialog
          isOpen={cropperOpen}
          handleClose={() => setCropperOpen(!cropperOpen)}
          title={t('offers_creation_resize_image')}
          className="image-cropper-modal"
        >
          <StyledImageCropper
            cropData={defaultCropData}
            imgSrc={rawAddedImage || originalImage}
            handleCropperClose={() => setCropperOpen(!cropperOpen)}
            handleSetCroppedImage={handleImageChange}
            retailer={retailer}
          />
        </ContentDialog>
      )}

      {!importFeatureOnly && (
        <ContentDialog
          centerText
          spaceless
          isOpen={galleryOpen}
          handleClose={() => setGalleryOpen(!galleryOpen)}
          className="image-cropper-modal"
        >
          {gallery &&
            React.cloneElement(gallery, {
              products: productsGalleryList,
              retailerCode: retailer?.code,
              initialSelectedProduct: initialSelectedProduct,
              onCancel: () => setGalleryOpen(false),
              onValidate: (item) => {
                if (item) {
                  setGalleryOpen(false);
                  handleDamImageClick(item);
                } else {
                  setGalleryOpen(false);
                }
              }
            })}
        </ContentDialog>
      )}
    </div>
  );
};
ImageChoice.propTypes = {
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  onError: PropTypes.func
};

export default ImageChoice;

import React, { memo, ReactNode } from 'react';
import './FramedIcon.scss';

type Props = {
  color?: string;
  icon: ReactNode;
  marginRightSize?: 'small' | 'medium' | 'big';
  size?: 'small' | 'medium' | 'big';
};

const FramedIcon = ({ color = 'blue', icon, marginRightSize, size = 'small' }: Props): JSX.Element => (
  <div className={`icon-container margin-right-${marginRightSize} ${size} ${color}-color`}>{icon}</div>
);

export default memo(FramedIcon);

import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getBudgetSpentByPeriod } from 'api';
import { offerTypes } from 'utils/constants';
import { dateFormatRegexp, QsParse, withFractionDigits } from 'utils/global';
import { store } from 'store';

import CheckboxCustom from 'components/CheckboxCustom';
import FormControlLabel from '@mui/material/FormControlLabel';
import GranularityPeriodSelector from 'components/GranularityPeriodSelector';
import GraphBar from 'components/GraphBar';
import Spinner from 'components/Loaders/Spinner_red';

import styles from '../../Dashboard.module.scss';

const DEFAULT_COLORS = ['#CCF4D5', '#5D80E8'];
const DEFAULT_REVERSED_COLORS = [...DEFAULT_COLORS].reverse();

const GRANULARITY = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH'
};

const today = moment();

const BudgetSpentByPeriod = ({ filterYear, furthestPastYearInYearList, loadingAvailableYears, updateUrl }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const urlFilters = QsParse(history.location.search);
  const globalState = useContext(store);
  const {
    state: { user }
  } = globalState;

  const defaultState = {
    granularity: GRANULARITY.MONTH,
    period: { start: 1, end: today.year() === filterYear ? moment().month() + 1 : 12 }
  };

  const initialState = useMemo(() => {
    const regexpForPeriodDigitNbByGranularity =
      urlFilters?.granularity === GRANULARITY.DAY ? dateFormatRegexp : /^\d{1,2}$/;

    let newState = {
      granularity: [...Object.values(GRANULARITY)].includes(urlFilters?.granularity)
        ? urlFilters.granularity
        : defaultState.granularity,
      period: {
        start: regexpForPeriodDigitNbByGranularity.test(urlFilters?.periodStart)
          ? urlFilters?.granularity === GRANULARITY.DAY
            ? moment(urlFilters.periodStart).year(filterYear)
            : parseInt(urlFilters.periodStart)
          : null,
        end: regexpForPeriodDigitNbByGranularity.test(urlFilters?.periodEnd)
          ? urlFilters?.granularity === GRANULARITY.DAY
            ? moment(urlFilters.periodEnd).year(filterYear)
            : parseInt(urlFilters.periodEnd)
          : null
      }
    };

    // apply default values if all period values are missing
    if (newState.period.start === null && newState.period.end === null) {
      if (newState.granularity === GRANULARITY.DAY) {
        newState = {
          granularity: GRANULARITY.DAY,
          period: { start: moment().year(filterYear), end: moment().year(filterYear) }
        };
      } else if (newState.granularity === GRANULARITY.WEEK) {
        newState = {
          granularity: GRANULARITY.WEEK,
          period: { start: today.week(), end: today.week() }
        };
      } else {
        newState = {
          granularity: defaultState.granularity,
          period: { start: defaultState.period.start, end: defaultState.period.end }
        };
      }
    } else {
      // apply both same period value if only one is missing
      if (newState.period.start === null && newState.period.end !== null) {
        const value =
          newState.granularity === GRANULARITY.DAY ? moment(newState.period.end).year(filterYear) : newState.period.end;

        newState = {
          ...newState,
          period: { start: value, end: value }
        };
      } else if (newState.period.start !== null && newState.period.end === null) {
        const value =
          newState.granularity === GRANULARITY.DAY
            ? moment(newState.period.start).year(filterYear)
            : newState.period.start;

        newState = {
          ...newState,
          period: { start: value, end: value }
        };
      }
    }

    return newState;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterYear]);

  const [data, setData] = useState(null);
  const [filterGranularity, setFilterGranularity] = useState(initialState.granularity);
  const [filterStart, setFilterStart] = useState(initialState.period.start);
  const [filterEnd, setFilterEnd] = useState(initialState.period.end);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPreviousYearData, setIsLoadingPreviousYearData] = useState(false);
  const [displayPreviousYearData, setDisplayPreviousYearData] = useState(false);

  const hasEmptyData = !data?.length || data?.every((el) => el.budget === 0);

  const fetchData = async (year) => {
    return await getBudgetSpentByPeriod({
      end: filterGranularity === GRANULARITY.DAY ? moment(filterEnd).year(year).format('YYYY-MM-DD') : filterEnd,
      granularity: filterGranularity,
      offerType: offerTypes.SUPPLIER_PRODUCT_OFFER,
      start: filterGranularity === GRANULARITY.DAY ? moment(filterStart).year(year).format('YYYY-MM-DD') : filterStart,
      year
    });
  };

  // update url and states when initialState changes
  useEffect(() => {
    // update state and dates in query string when filterYear changes
    setFilterGranularity(initialState.granularity);
    setFilterStart(initialState.period.start);
    setFilterEnd(initialState.period.end);

    handleUpdateUrl({
      granularity: initialState.granularity,
      periodStart: initialState.period.start,
      periodEnd: initialState.period.end
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState]);

  // update displayPreviousYearData button
  useEffect(() => {
    if (hasEmptyData || filterYear <= furthestPastYearInYearList) {
      setDisplayPreviousYearData(false);
    }
  }, [filterYear, furthestPastYearInYearList, hasEmptyData]);

  // fetch data
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const currYearData = await fetchData(filterYear);

      if (currYearData) {
        let newData = currYearData;
        let prevYearData;

        // get previous year data ?
        if (displayPreviousYearData && filterYear > furthestPastYearInYearList) {
          prevYearData = await fetchData(filterYear - 1);
          if (prevYearData) {
            newData = newData.map((el, index) => ({ ...el, prevBudget: prevYearData[index].budget }));
          }
        }

        newData = newData.map((el, index) => {
          const value = el.budget;
          const formattedValue = t('commun_price', {
            value,
            currency: user.currency.code,
            maximumFractionDigits: withFractionDigits(value)
          });
          const prevYearValue = prevYearData ? prevYearData[index].budget : null;
          const formattedPrevYearValue = t('commun_price', {
            value: prevYearValue,
            currency: user.currency.code,
            maximumFractionDigits: withFractionDigits(prevYearValue)
          });

          return {
            ...el,
            budget: value,
            prevBudget: prevYearValue,
            tooltipParams: {
              i18nKey: 'dashboard_graph_bar_budget_spent_tooltip',
              params: {
                // primary keys must match valueKeys and then i18nKey params keys
                budget: { value: formattedValue, year: filterYear },
                prevBudget: { value: formattedPrevYearValue, year: filterYear - 1 }
              }
            }
          };
        });

        setData(newData);
      }

      setIsLoading(false);
    };

    // avoid fetching with startDate != filterYear
    if (!isLoading && (filterGranularity === GRANULARITY.DAY ? filterYear === filterStart.year() : true)) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterEnd, filterGranularity, filterStart, filterYear, user.currency.code]);

  // fetch prevBudget data
  useEffect(() => {
    const getData = async () => {
      setIsLoadingPreviousYearData(true);
      const prevYearData = await fetchData(filterYear - 1);
      setIsLoadingPreviousYearData(false);

      if (prevYearData) {
        const newData = data.map((el, index) => {
          const prevYearValue = prevYearData[index].budget;
          const formattedPrevYearValue = t('commun_price', {
            value: prevYearValue,
            currency: user.currency.code,
            maximumFractionDigits: withFractionDigits(prevYearValue)
          });

          return {
            ...el,
            prevBudget: prevYearValue,
            tooltipParams: {
              i18nKey: 'dashboard_graph_bar_budget_spent_tooltip',
              params: {
                // primary keys must match valueKeys and then i18nKey params keys
                ...el.tooltipParams.params,
                prevBudget: { value: formattedPrevYearValue, year: filterYear - 1 }
              }
            }
          };
        });

        setData(newData);
      }
    };

    if (displayPreviousYearData && typeof data?.[0]?.prevBudget !== 'number') {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayPreviousYearData]);

  const handleUpdateUrl = (obj) => {
    updateUrl?.({
      granularity: obj.granularity,
      periodStart: obj.granularity === GRANULARITY.DAY ? obj.periodStart.format('YYYY-MM-DD') : obj.periodStart,
      periodEnd: obj.granularity === GRANULARITY.DAY ? obj.periodEnd.format('YYYY-MM-DD') : obj.periodEnd
    });
  };

  const handleValidation = ({ granularity, period }) => {
    setFilterGranularity(granularity);
    setFilterStart(period.start);
    setFilterEnd(period.end);

    handleUpdateUrl({
      granularity,
      periodStart: period.start,
      periodEnd: period.end
    });
  };

  const buildCustomXAxisLabels = () => {
    if (!data) return null;

    if (filterGranularity === GRANULARITY.MONTH) {
      return data.map((el) => ({ ...el, period: t(`commun_month_${el.period}_short`) }));
    } else if (filterGranularity === GRANULARITY.WEEK) {
      return data.map((el) => ({
        ...el,
        period: t('commun_week_very_short') + (el.period.toString().length > 1 ? el.period.toString() : `0${el.period}`)
      }));
    } else if (filterGranularity === GRANULARITY.DAY) {
      return data.map((el) => ({
        ...el,
        period: moment(el.period).format('ll').slice(0, -5) // remove displayed year
      }));
    }

    return data;
  };

  return (
    <div className={styles['block-container']}>
      <div className={`${styles['frame']} ${styles['graph-container']}`}>
        <div className={styles['block-title']}>
          {t('commun_budget_spent')}
          <GranularityPeriodSelector
            filterYear={filterYear}
            filterGranularityPeriod={{ granularity: filterGranularity, period: { start: filterStart, end: filterEnd } }}
            isLoading={loadingAvailableYears || isLoading}
            onValidation={handleValidation}
          />
        </div>

        <div className={styles['bar']}>
          <GraphBar
            // invert colors because we invert valueKeys when displayPreviousYearData is true
            colors={displayPreviousYearData && !isLoadingPreviousYearData ? DEFAULT_COLORS : DEFAULT_REVERSED_COLORS}
            data={buildCustomXAxisLabels()}
            hasEmptyData={hasEmptyData}
            indexKey="period"
            isLoading={isLoading}
            valueKeys={displayPreviousYearData && !isLoadingPreviousYearData ? ['prevBudget', 'budget'] : ['budget']}
          />
        </div>

        <FormControlLabel
          control={
            <CheckboxCustom
              idText={'display-previous-year-data-checkbox'}
              checked={displayPreviousYearData}
              disabled={isLoading || hasEmptyData || filterYear <= furthestPastYearInYearList}
              onChange={(e) => setDisplayPreviousYearData(!displayPreviousYearData)}
            />
          }
          label={
            <div className="d-flex">
              {t(`dashboard_budget_compare_with_last_year_data`)} {isLoadingPreviousYearData && <Spinner />}
            </div>
          }
        />
      </div>
    </div>
  );
};

BudgetSpentByPeriod.propTypes = {
  filterYear: PropTypes.number,
  furthestPastYearInYearList: PropTypes.number,
  loadingAvailableYears: PropTypes.bool,
  updateUrl: PropTypes.func
};

export default BudgetSpentByPeriod;

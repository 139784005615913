import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormManager from 'components/FormManager';
import TabsInModal from 'components/TabsInModal';

const TEXTFIELD_MAX_LENGTH = 256;
const TEXTAREA_MAX_LENGTH = 1024;

const Guidelines = ({ guidelinesState, onChangeState }) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [language, setLanguage] = useState('FR');
  const guidelinesByLanguage = guidelinesState?.[language];

  const setUpdatedGuidelines = (key, value) =>
    guidelinesByLanguage && {
      ...guidelinesState,
      [language]: {
        ...guidelinesByLanguage,
        [key]: { ...value, ...(guidelinesByLanguage[key] && { id: guidelinesByLanguage[key].id }) },
      },
    };

  return (
    <FormManager
      data={{
        title: t('retailers_creation_retailer_guidelines'),
        fieldsets: [
          {
            id: 'creation-form-guidelinesByLanguage',
            fields: [
              {
                type: 'CustomContent',
                id: 'change-lang-tab',
                component: (
                  <TabsInModal
                    className={'margin-x-minus-32'}
                    labels={[t('commun_french'), t('commun_english')]}
                    onChange={async (index) => {
                      setTabIndex(index);
                      setLanguage(index === 0 ? 'FR' : 'EN');
                    }}
                    selectedIndex={tabIndex}
                  />
                ),
              },
              {
                label: t('retailers_creation_retailer_guidelines_offer_product'),
                defaultValue: guidelinesByLanguage?.offerProduct?.content || '',
                type: 'TextField',
                id: 'offer-product',
                fieldProps: {
                  InputProps: {
                    inputProps: { maxLength: TEXTFIELD_MAX_LENGTH },
                    min: 3,
                  },
                  multiline: true,
                },
                onFieldChange: (value) => {
                  onChangeState({
                    guidelines: setUpdatedGuidelines('offerProduct', { type: 'OFFER_PRODUCT', content: value }),
                  });
                },
                counter: TEXTFIELD_MAX_LENGTH,
              },
              {
                label: t('retailers_creation_retailer_guidelines_offer_promotion'),
                defaultValue: guidelinesByLanguage?.offerPromotion?.content || '',
                type: 'TextField',
                id: 'offer-promotion',
                fieldProps: {
                  InputProps: {
                    inputProps: { maxLength: TEXTFIELD_MAX_LENGTH },
                    min: 3,
                  },
                  multiline: true,
                },
                onFieldChange: (value) => {
                  onChangeState({
                    guidelines: setUpdatedGuidelines('offerPromotion', { type: 'OFFER_PROMOTION', content: value }),
                  });
                },
                counter: TEXTFIELD_MAX_LENGTH,
              },
              {
                label: t('retailers_creation_retailer_guidelines_offer_title'),
                defaultValue: guidelinesByLanguage?.offerTitle?.content || '',
                type: 'TextField',
                id: 'offer-title',
                fieldProps: {
                  InputProps: {
                    inputProps: { maxLength: TEXTAREA_MAX_LENGTH },
                    min: 3,
                  },
                  multiline: true,
                  rows: 4,
                },
                onFieldChange: (value) => {
                  onChangeState({
                    guidelines: setUpdatedGuidelines('offerTitle', { type: 'OFFER_TITLE', content: value }),
                  });
                },
                counter: TEXTAREA_MAX_LENGTH,
              },
              {
                label: t('retailers_creation_retailer_guidelines_offer_additional_information'),
                defaultValue: guidelinesByLanguage?.offerAdditionalInformation?.content || '',
                type: 'TextField',
                id: 'offer-additional-information',
                fieldProps: {
                  InputProps: {
                    inputProps: { maxLength: TEXTAREA_MAX_LENGTH },
                    min: 3,
                  },
                  multiline: true,
                  rows: 4,
                },
                onFieldChange: (value) => {
                  onChangeState({
                    guidelines: setUpdatedGuidelines('offerAdditionalInformation', {
                      type: 'OFFER_ADDITIONAL_INFORMATION',
                      content: value,
                    }),
                  });
                },
                counter: TEXTAREA_MAX_LENGTH,
              },
              {
                label: t('retailers_creation_retailer_guidelines_offer_date'),
                defaultValue: guidelinesByLanguage?.offerDate?.content || '',
                type: 'TextField',
                id: 'offer-date',
                fieldProps: {
                  InputProps: {
                    inputProps: { maxLength: TEXTFIELD_MAX_LENGTH },
                    min: 3,
                  },
                  multiline: true,
                },
                onFieldChange: (value) => {
                  onChangeState({
                    guidelines: setUpdatedGuidelines('offerDate', { type: 'OFFER_DATE', content: value }),
                  });
                },
                counter: TEXTFIELD_MAX_LENGTH,
              },
              {
                label: t('retailers_creation_retailer_guidelines_offer_budget'),
                defaultValue: guidelinesByLanguage?.offerRecommendedMinimumBudget?.content || '',
                type: 'TextField',
                id: 'offer-recommended-minimum-budget',
                fieldProps: {
                  InputProps: {
                    inputProps: { maxLength: TEXTFIELD_MAX_LENGTH },
                    min: 3,
                  },
                  multiline: true,
                },
                onFieldChange: (value) => {
                  onChangeState({
                    guidelines: setUpdatedGuidelines('offerRecommendedMinimumBudget', {
                      type: 'OFFER_RECOMMENDED_MINIMUM_BUDGET',
                      content: value,
                    }),
                  });
                },
                counter: TEXTFIELD_MAX_LENGTH,
              },
            ],
          },
        ],
      }}
    />
  );
};

Guidelines.propTypes = {
  guidelinesState: PropTypes.object,
  onChangeState: PropTypes.func,
};

export default Guidelines;

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import './AsidePanel.scss';

const AsidePanel = ({ children, disabled, className, thinContainer }) => {
  return (
    <div
      className={`asidepanel-container ${disabled ? 'disabled' : ''} ${thinContainer ? 'thinContainer' : ''} ${
        className ? className : ''
      }`}
    >
      {children}
    </div>
  );
};

AsidePanel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  thinContainer: PropTypes.bool,
};

export default memo(AsidePanel);

import PropTypes from 'prop-types';
import React from 'react';

const ConditionalWrapper = ({ condition, defaultWrapper, wrapper, children }) => {
  if (!condition && defaultWrapper) return defaultWrapper(children);

  if (!condition) return <>{children}</>;

  if (wrapper) return wrapper(children);
};

ConditionalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  condition: PropTypes.bool,
  default: PropTypes.func,
  wrapper: PropTypes.func.isRequired,
};

export default ConditionalWrapper;

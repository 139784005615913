import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSegmentDetails, getSegmentDetailsLinkedCampaigns, getSegmentDetailsLinkedOffers } from 'api';
import {
  campaignStates,
  campaignTargetingStatuses,
  retailerTypes,
  segmentScopes,
  segmentTypes,
  segmentUpdatingStatuses
} from 'utils/constants';

import Accordion from 'components/Accordion';

import ButtonsCustom from 'components/ButtonsCustom';
import DrawerHeader from 'components/DrawerHeader';
import FramedIcon from 'components/FramedIcon';
import GeneralInfoBox, { GeneralInfoBoxContainer } from 'components/GeneralInfoBox';
import Loader from 'components/Loaders/Dots';
import OfferDetailsAlert from 'components/OfferDetailsAlert';
import SimpleTable from 'components/TableCustom/SimpleTable';
import Tags from 'components/Tags';
import UnknownId from 'components/Drawer/UnknownId';

import { ReactComponent as LoyaltyCardsIcon } from 'assets/24px_fidélisation.svg';
import { ReactComponent as LastUpdateDateIcon } from 'assets/24px_calendrier.svg';
import { ReactComponent as PencilIcon } from 'assets/16px_edit.svg';

import styles from './SegmentDetails.module.scss';

const SegmentDetails = ({ segmentId }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const globalState = useContext(store);
  const {
    state: {
      segments: { details = {} },
      suppliers,
      user: { type }
    }
  } = globalState;

  const [campaignList, setCampaignList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [displayMoreCampaigns, setDisplayMoreCampaigns] = useState(false);
  const [displayMoreOffers, setDisplayMoreOffers] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isClientType = type === retailerTypes.CLIENT;

  // prevent editing the segment while the allocation is calculating in a linked campagne
  const canEdit =
    !campaignList.length ||
    !campaignList.find(
      (c) =>
        [campaignStates.IN_PROGRESS, campaignStates.TO_COME].includes(c.campaignState) &&
        [
          campaignTargetingStatuses.FAIL,
          campaignTargetingStatuses.FAIL_DATA,
          campaignTargetingStatuses.IN_PROCESS,
          campaignTargetingStatuses.REQUESTED
        ].includes(c.targetingStatus)
    );

  useEffect(() => {
    const getData = async () => {
      const segmentDetails = await getSegmentDetails(segmentId);
      const linkedCampaignList = await getSegmentDetailsLinkedCampaigns({ segmentId, scope: segmentDetails.scope });
      const linkedOfferList = await getSegmentDetailsLinkedOffers(segmentId);
      setCampaignList(linkedCampaignList);
      setOfferList(linkedOfferList);
      setIsLoading(false);
    };
    if (segmentId) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentId]);

  const handleEditButton = () => {
    const scope = details.scope ? `-${segmentScopes[details.scope].toLowerCase()}` : '-campaign';
    history.push({
      pathname: `/segmentation/segment-manager${scope}/${segmentId}`,
      search: history.location.search
    });
  };

  if (isLoading) {
    return (
      <div className={`${styles['segment-details']} ${styles['placeholder']}`}>
        <div className={styles['header']}>
          <span className={styles['title']}>{t('segment_details_panel_title')}</span>
          <span className={styles['button']}></span>
        </div>

        <div className={styles['block']}>
          <div className={styles['title']} />
          <div className={styles['id']} />
          <div className={styles['tag']} />
        </div>

        <div className={styles['loading']}>
          <p className={styles['loading-message']}>{t('offer_details_loading_message')}</p>
          <Loader />
        </div>
      </div>
    );
  }

  if (!details.id) {
    return <UnknownId />;
  }

  const displayCampaignList = () => {
    const itemsToDisplay = 3;
    const campaignShortList = campaignList?.slice(0, itemsToDisplay);
    const list = displayMoreCampaigns ? campaignList : campaignShortList;

    return (
      <div className={styles['offer-details-campaign-offers']}>
        {!!list.length && (
          <SimpleTable
            rows={list.map((campaign) => {
              return {
                id: [{ value: campaign.id }],
                title: [{ value: campaign.title }],
                ean: [{ value: campaign.id, color: 'ean', textStyle: 'tag' }],
                status: <Tags status={campaign.campaignState} />
              };
            })}
            columns={[
              {
                id: 1,
                field: 'title',
                type: 'text',
                hasTooltip: true,
                align: 'left',
                wide: true
              },
              { id: 2, field: 'ean' },
              {
                id: 3,
                field: 'status',
                type: 'component',
                wide: true
              }
            ]}
            showHeader={false}
          />
        )}
        {campaignList.length > itemsToDisplay && (
          <ButtonsCustom
            classType="link_primary"
            text={
              displayMoreCampaigns ? (
                <span className={styles['d-flex']}>
                  <em>-</em>
                  <span>{t('commun_show_less')}</span>
                </span>
              ) : (
                <span className={styles['d-flex']}>
                  <em>+</em>
                  <span>{t('commun_show_more')}</span>
                </span>
              )
            }
            method={(e) => {
              setDisplayMoreCampaigns(!displayMoreCampaigns);
            }}
          />
        )}
      </div>
    );
  };

  const displayOfferList = () => {
    const itemsToDisplay = 3;
    const offerShortList = offerList?.slice(0, itemsToDisplay);
    const list = displayMoreOffers ? offerList : offerShortList;

    return (
      <div className={styles['offer-details-campaign-offers']}>
        {!!list.length && (
          <SimpleTable
            rows={list.map((offer) => {
              return {
                id: [{ value: offer.offerHead.id }],
                title: [{ value: offer.offerHead.title }],
                status: <Tags status={offer.status} />
              };
            })}
            columns={[
              {
                id: 1,
                field: 'id',
                type: 'text',
                align: 'left'
              },
              { id: 2, field: 'title', type: 'text', hasTooltip: true, align: 'left', wide: true },
              {
                id: 3,
                field: 'status',
                type: 'component',
                wide: true
              }
            ]}
            showHeader={false}
          />
        )}
        {offerList.length > itemsToDisplay && (
          <ButtonsCustom
            classType="link_primary"
            text={
              displayMoreOffers ? (
                <span className={styles['d-flex']}>
                  <em>-</em>
                  <span>{t('commun_show_less')}</span>
                </span>
              ) : (
                <span className={styles['d-flex']}>
                  <em>+</em>
                  <span>{t('commun_show_more')}</span>
                </span>
              )
            }
            method={(e) => {
              setDisplayMoreOffers(!displayMoreOffers);
            }}
          />
        )}
      </div>
    );
  };

  const segmentKpis = [
    {
      icon: <LoyaltyCardsIcon />,
      label: t('segment_details_number_loyalty_cards'),
      data: t('commun_number', { value: details.customerCount ?? '-' })
    },
    {
      icon: <LastUpdateDateIcon />,
      label: t('segment_details_last_update'),
      data: details.lastUpdate ? t('commun_date', { value: details.lastUpdate }) : '-'
    }
  ];

  return (
    <div className={styles['segment-details']}>
      <DrawerHeader
        title={t('segment_details_panel_title')}
        buttons={[
          <ButtonsCustom
            classType="action"
            disabled={!isClientType || isLoading || !canEdit}
            id={'editButtonId'}
            key="edit-button"
            method={handleEditButton}
            startIconCustom={<PencilIcon />}
            tooltip={
              !isClientType
                ? t('segment_details_edit_button_disabled_not_client_type_tooltip')
                : canEdit
                ? ''
                : t('segment_details_edit_button_disabled_segment_in_allocated_campaign_tooltip')
            }
          />
        ]}
      />

      {details.updatingStatus === segmentUpdatingStatuses.FAIL && details.type !== segmentTypes.IMPORT && (
        <OfferDetailsAlert
          title="segment_details_status_fail_frame_title"
          description="segment_details_status_fail_frame_desc"
        />
      )}
      {details.updatingStatus === segmentUpdatingStatuses.FAIL && details.type === segmentTypes.IMPORT && (
        <OfferDetailsAlert
          title="segment_details_status_fail_frame_title"
          description="segment_details_status_fail_frame_desc_type_import"
        />
      )}

      <div className={styles['informations-title']}>{details.name}</div>
      <div className={styles['informations-id']}>ID : {details.id}</div>

      <Tags
        status={
          details.updatingStatus === segmentUpdatingStatuses.FAIL
            ? 'SEGMENT_FAIL'
            : details.lastUpdate
            ? details.archived
              ? 'SEGMENT_ARCHIVED'
              : 'SEGMENT_SUCCESS'
            : 'SEGMENT_IN_PROCESS'
        }
      />

      <div className={styles['info-box-container']}>
        <GeneralInfoBox fullWidth label={t('segment_details_desc_title')}>
          <span>{details.description}</span>
        </GeneralInfoBox>
        <GeneralInfoBoxContainer>
          <GeneralInfoBox label={t('_dyn_commun_type')}>
            <span>{t(`segment_type_${details.type}`)}</span>
          </GeneralInfoBox>
          {details.scope === segmentScopes.OFFER && (
            <GeneralInfoBox label={t('commun_supplier')}>
              <span>
                {suppliers.length ? suppliers.find((sup) => sup.id === parseInt(details.supplierId))?.name || '-' : '-'}
              </span>
            </GeneralInfoBox>
          )}
        </GeneralInfoBoxContainer>
      </div>
      <div className={styles['divider']}></div>
      <div className={styles['kpis']}>
        {segmentKpis.map((kpis, index) => {
          return (
            <div key={index} className={styles['kpis-standAlone']}>
              <div>
                <FramedIcon icon={kpis.icon} size="medium" />
              </div>
              <div>
                <div className={styles['kpis-standAlone-data']}>{kpis.data}</div>
                <div className={styles['kpis-standAlone-label']}>{kpis.label}</div>
              </div>
            </div>
          );
        })}
      </div>

      <Accordion
        disabled={!campaignList.length}
        expandedByDefault={true}
        summary={t('segment_details_linked_campaigns')}
      >
        {displayCampaignList()}
      </Accordion>

      {details.scope === segmentScopes.OFFER && (
        <>
          <div className={styles['some-space']}></div>
          <Accordion disabled={!offerList.length} expandedByDefault={true} summary={t('segment_details_linked_offers')}>
            {displayOfferList()}
          </Accordion>
        </>
      )}
    </div>
  );
};

SegmentDetails.propTypes = {
  segmentId: PropTypes.string
};

export default SegmentDetails;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation, Trans } from 'react-i18next';
import { withFractionDigits } from 'utils/global';

import FramedIcon from 'components/FramedIcon';

import { ReactComponent as BudgetSpendableIcon } from 'assets/bourse_empty.svg';
import { ReactComponent as InfoIcon } from 'assets/30px_info_round.svg';
import { ReactComponent as SupplierIcon } from 'assets/30px_fournisseur.svg';

import styles from './CampaignKeypoints.module.scss';
import Tooltip from 'components/Tooltip';

const CampaignKeypoints = ({ targetingKpisOfferCampaign }) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state } = globalState;
  const currencyCode = state.user.currency?.code;
  const { numberOfSupplierProductOffers, totalOfProductOffers, totalOfSpendableBudget } =
    targetingKpisOfferCampaign || {};

  const productKpis = totalOfProductOffers
    ? [
        {
          id: 'numberOfSupplierProductOffers',
          icon: <SupplierIcon />,
          rawValue: numberOfSupplierProductOffers || 0,
          value: t('commun_number', { value: numberOfSupplierProductOffers || 0 })
        },
        {
          id: 'totalOfSpendableBudget',
          icon: <BudgetSpendableIcon />,
          hasTooltip: true,
          rawValue: totalOfSpendableBudget || 0,
          value: currencyCode
            ? t('commun_price', {
                currency: currencyCode,
                maximumFractionDigits: withFractionDigits(totalOfSpendableBudget),
                value: totalOfSpendableBudget ?? '-'
              })
            : '-'
        }
      ]
    : null;

  const displayKeypoint = ({ extra, hasTooltip, icon, id, rawValue, value }) => {
    return (
      <div className={`${styles['keypoint-container']} ${extra ? styles['full-height'] : ''}`}>
        <div className={styles['d-flex']}>
          <FramedIcon icon={icon} size="medium" />
          <div className={styles['data-container']}>
            <p>{value}</p>
            <p>
              {t(`_dyn_campaign_details_keypoints_${id}`, { count: rawValue })}
              {hasTooltip && (
                <Tooltip placement="top" title={hasTooltip ? t(`_dyn_campaign_details_keypoints_${id}_tooltip`) : ''}>
                  <span className={styles['tooltip-icon']}>
                    <InfoIcon />
                  </span>
                </Tooltip>
              )}
            </p>
          </div>
        </div>

        {extra && (
          <div
            className={`${styles['data-container']} ${
              extra.filter((el) => el.value).length !== 2 ? styles['one-item'] : ''
            }`}
          >
            {extra.map((el) => {
              if (!el.value) return false;
              return (
                <div className={styles['extra-kpi']} key={el.id}>
                  <p>
                    {t('commun_number', { value: el.value })}
                    <span>
                      {t(`_dyn_campaign_details_keypoints_${el.id}`, {
                        count: el.value
                      })}
                    </span>
                  </p>

                  {typeof el.subValue === 'number' && (
                    <p>
                      <Trans
                        i18nKey="_dyn_campaign_details_keypoints_including_defaultOffers"
                        values={{ count: el.subValue }}
                      />
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  if (!productKpis) return null;
  return (
    <div className={styles['root']}>
      <h3 className={styles['title']}>{t('campaign_details_keypoints_title')}</h3>
      <div className={styles['main-container']}>
        {productKpis.map((keypoint) => {
          if (typeof keypoint.rawValue !== 'number') return false;
          return (
            <div className={styles['group']} key={keypoint.id}>
              {displayKeypoint(keypoint)}
            </div>
          );
        })}
      </div>
    </div>
  );
};

CampaignKeypoints.propTypes = {
  targetingKpisOfferCampaign: PropTypes.object
};

export default CampaignKeypoints;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPLIER_NOTE_MAX_LENGTH } from 'utils/constants';
import { updateSupplierNote } from 'api';

import ContentDialog from 'components/ContentDialog';
import FormManager from 'components/FormManager';

import { supplierType } from 'types/supplier';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onUpdated: () => void;
  supplier: supplierType | null;
};

const SupplierNoteDialog = ({ isOpen, onClose, onUpdated, supplier }: Props): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async ({ supplier_note }: { supplier_note: string }) => {
    if (supplier?.note !== supplier_note) {
      setIsLoading(true);
      await updateSupplierNote({ ...supplier, note: supplier_note });
      setIsLoading(false);
      onUpdated();
      onClose();
    } else {
      onClose();
    }
  };

  return (
    <ContentDialog
      handleClose={onClose}
      isLoading={isLoading}
      isOpen={isOpen}
      maxWidth="sm"
      title={t(supplier?.note ? 'supplier_update_note_dialog_title' : 'supplier_add_note_dialog_title')}
    >
      <div className="full-width">
        <FormManager
          onSubmit={handleSubmit}
          data={{
            fieldsets: [
              {
                id: 'supplier_creation_note',
                fields: [
                  {
                    type: 'TextField',
                    id: 'supplier_note',
                    defaultValue: supplier?.note?.replaceAll('</br>', '\n') || '',
                    fieldProps: {
                      InputProps: {
                        inputProps: { maxLength: SUPPLIER_NOTE_MAX_LENGTH },
                        min: 3
                      },
                      multiline: true
                    },
                    counter: SUPPLIER_NOTE_MAX_LENGTH
                  }
                ]
              }
            ],
            alignFormButtons: 'center',
            submit: {
              id: 'supplier_add_note_button',
              label: t('commun_save'),
              loading: isLoading
            }
          }}
        />
      </div>
    </ContentDialog>
  );
};

export default SupplierNoteDialog;

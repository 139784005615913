import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'components/Tooltip';
import styles from './TableCustom.module.scss';

const TableCustom = ({ columns, rows, showHeader = true, type }) => {
  const displayRowItems = (rowItems) =>
    rowItems.map((rowItemChild, index) => (
      <p
        key={`${rowItemChild.value}-${index}`}
        className={`
      ${styles[rowItemChild.color] ?? styles['default']} 
      ${styles[rowItemChild.textStyle] ?? styles['p3']}`}
      >
        {rowItemChild.value}
      </p>
    ));

  return (
    <div className={`${styles['table-custom']} ${styles['simpleTable']} ${type ? styles[type] : ''}`}>
      <div className={styles['table-content']}>
        <table>
          <thead>
            {showHeader && (
              <tr>
                {columns.map((i) => {
                  return (
                    <th
                      key={i.id}
                      className={`${i.wide ? styles['wide'] : ''} ${i.align ? styles[`align-${i.align}`] : ''}`}
                    >
                      <span className={styles['p3']}>{i.headerName}</span>
                    </th>
                  );
                })}
              </tr>
            )}
          </thead>
          <tbody>
            {rows?.map((rowItem) => (
              <tr key={rowItem.id[0].value}>
                {columns.map((columnItem, index) => {
                  return (
                    <td
                      key={`${rowItem.id[0].value}-${index}`}
                      className={`${columnItem.wide ? styles['wide'] : ''} ${
                        columnItem.align ? styles[`align-${columnItem.align}`] : ''
                      }`}
                    >
                      {columnItem.type === 'component' ? (
                        rowItem[columnItem.field]
                      ) : columnItem.hasTooltip ? (
                        <Tooltip title={displayRowItems(rowItem[columnItem.field])}>
                          <div>{displayRowItems(rowItem[columnItem.field])}</div>
                        </Tooltip>
                      ) : (
                        displayRowItems(rowItem[columnItem.field])
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

TableCustom.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  showHeader: PropTypes.bool,
  type: PropTypes.string,
};

export default TableCustom;

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useDynId from 'customHooks/useDynId';

import ButtonsCustom from 'components/ButtonsCustom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popover from '@mui/material/Popover';

import { ReactComponent as DotsMenuIcon } from 'assets/dots_menu.svg';
import './OptionMenuPopover.scss';

const OptionMenuPopover = ({ children, disabledMessage }) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  const closeOptionMenu = () => {
    setOpen(false);
  };

  const toogleOptionMenu = () => {
    if (!disabledMessage) setOpen((state) => !state);
  };

  return (
    <ClickAwayListener onClickAway={closeOptionMenu}>
      <>
        <div className={`option-menu-button ${open ? 'open' : ''} ${disabledMessage ? 'disabled' : ''}`}>
          <ButtonsCustom
            classType="action"
            key="offer-option-menu-button"
            id={useDynId('option-menu-button')}
            method={toogleOptionMenu}
            ref={buttonRef}
            startIconCustom={<DotsMenuIcon />}
            tooltip={disabledMessage || ''}
          />
        </div>

        <Popover
          id={useDynId('option-menu-button-popover')}
          className="popover"
          disablePortal
          anchorEl={buttonRef.current}
          elevation={0}
          open={open}
          onClose={closeOptionMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {children}
          <span className="popover-arrow"></span>
        </Popover>
      </>
    </ClickAwayListener>
  );
};

OptionMenuPopover.propTypes = {
  children: PropTypes.node.isRequired,
  disabledMessage: PropTypes.string,
};

export default OptionMenuPopover;

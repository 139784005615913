import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { store } from 'store';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getAllSuppliers,
  getAllUserManager,
  getMonitoredOffersToDisableCount,
  getMonitoredOffersToValidateCount,
  getSupplierBudgetsCount
} from 'api';

import FiltersContainer, { getInitialFilters } from 'components/Filters';
import MonitoredBudget from './MonitoredBudget';
import MonitoredOffersToDisable from './MonitoredOffersToDisable';
import MonitoredOffersToValidate from './MonitoredOffersToValidate';
import ScrollToTop from 'components/ScrollToTop';
import Tabs from 'components/TabsInModal';

import styles from './PilotageRetailer.module.scss';

const PilotageRetailer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { user, queryFilters, suppliers, userManagers } = state;

  const suppliersWithBudget = useMemo(
    () => suppliers.filter((s) => s.currentYearBudgetTotal || s.nextYearBudgetTotal),
    [suppliers]
  );

  // Filters
  const initFilters = useMemo(
    () => getInitialFilters({ history, queryFilters, suppliers: suppliersWithBudget, userManagers }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [filters, setFilters] = useState(initFilters);
  const [tabIndex, setTabIndex] = useState(0);

  const [monitoredBudgetsCount, setMonitoredBudgetsCount] = useState(null);
  const [monitoredBudgetsCountLoading, setMonitoredBudgetsCountLoading] = useState(true);

  const [monitoredOffersToDisableCount, setMonitoredOffersToDisableCount] = useState(null);
  const [monitoredOffersToDisableCountLoading, setMonitoredOffersToDisableCountLoading] = useState(true);

  const [monitoredOffersToValidateCount, setMonitoredOffersToValidateCount] = useState(null);
  const [monitoredOffersToValidateCountLoading, setMonitoredOffersToValidateCountLoading] = useState(true);

  const fetchMonitoredBudgetsCount = async () => {
    setMonitoredBudgetsCountLoading(true);
    const count = await getSupplierBudgetsCount({ retailerId: user.id, filters });
    if (count !== false) {
      setMonitoredBudgetsCount(count);
    }
    setMonitoredBudgetsCountLoading(false);
  };

  const fetchMonitoredOffersToDisableCount = async () => {
    setMonitoredOffersToDisableCountLoading(true);
    const count = await getMonitoredOffersToDisableCount({ retailerId: user.id, filters });
    if (count !== false) {
      setMonitoredOffersToDisableCount(count);
    }
    setMonitoredOffersToDisableCountLoading(false);
  };

  const fetchMonitoredOffersToValidateCount = async () => {
    setMonitoredOffersToValidateCountLoading(true);
    const count = await getMonitoredOffersToValidateCount({ retailerId: user.id, filters });
    if (count !== false) {
      setMonitoredOffersToValidateCount(count);
    }
    setMonitoredOffersToValidateCountLoading(false);
  };

  useEffect(() => {
    // for suppliers and userManager filters
    getAllSuppliers();
    getAllUserManager();

    return () => {
      dispatch({
        type: 'MONITORING_RETAILER_RESET'
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // reset data when filters have changed
    dispatch({ type: 'MONITORING_RETAILER_OFFERS_TO_DISABLE_LIST_RESET' });
    dispatch({ type: 'MONITORING_RETAILER_OFFERS_TO_VALIDATE_LIST_RESET' });
    dispatch({ type: 'MONITORING_RETAILER_SUPPLIER_BUDGETS_LIST_RESET' });

    fetchMonitoredBudgetsCount();
    fetchMonitoredOffersToDisableCount();
    fetchMonitoredOffersToValidateCount();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleUpdateFilters = useCallback((newFilters) => {
    setFilters({ ...newFilters });
  }, []);

  return (
    <div className={styles['root']}>
      <div className={clsx(styles['top-wrapper'], styles['no-margin-bottom'])}>
        <div className={styles['header']}>
          <div className={styles['header-title']}>{t('_dyn_navigation_Pilotage')}</div>
        </div>
      </div>
      <FiltersContainer
        dispatch={dispatch}
        filters={filters}
        filtersListToDisplay={['retailerUser', 'userManager']}
        suppliersList={suppliersWithBudget}
        updateFilters={handleUpdateFilters}
        user={user}
        userManagersList={userManagers}
      />
      <Tabs
        className={'margin-x-minus-32'}
        labels={[
          t('pilotage_monitored_budgets_count', {
            count: monitoredBudgetsCountLoading || monitoredBudgetsCount === null ? '-' : monitoredBudgetsCount
          }),
          t('pilotage_monitored_offers_to_disable_count', {
            count:
              monitoredOffersToDisableCountLoading || monitoredOffersToDisableCount === null
                ? '-'
                : monitoredOffersToDisableCount
          }),
          t('pilotage_monitored_offers_to_validate_count', {
            count:
              monitoredOffersToValidateCountLoading || monitoredOffersToValidateCount === null
                ? '-'
                : monitoredOffersToValidateCount
          })
        ]}
        onChange={async (index) => {
          setTabIndex(index);
        }}
        position="left"
        selectedIndex={tabIndex}
      />
      {tabIndex === 0 && <MonitoredBudget count={monitoredBudgetsCount} filters={filters} />}
      {tabIndex === 1 && (
        <MonitoredOffersToDisable
          count={monitoredOffersToDisableCount}
          filters={filters}
          onUpdated={fetchMonitoredOffersToDisableCount}
        />
      )}
      {tabIndex === 2 && (
        <MonitoredOffersToValidate
          count={monitoredOffersToValidateCount}
          filters={filters}
          onUpdated={fetchMonitoredOffersToValidateCount}
        />
      )}
      <ScrollToTop />
    </div>
  );
};

export default PilotageRetailer;

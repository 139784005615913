import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ButtonsCustom from 'components/ButtonsCustom';
import Tooltip from 'components/Tooltip';
import { mailStatuses } from 'utils/constants';
import { getSupplier, getUserSuppliers } from 'api';

import { ReactComponent as CheckIcon } from 'assets/30px_statut-fournisseur.svg';
import { ReactComponent as ClickedIcon } from 'assets/30px_click.svg';
import { ReactComponent as CrossIcon } from 'assets/30px_croix.svg';
import { ReactComponent as DelivredIcon } from 'assets/30px_email_delivered.svg';
import { ReactComponent as MailOpenIcon } from 'assets/30px_mail_open.svg';
import { ReactComponent as RemoveIcon } from 'assets/22px_croix.svg';
import { ReactComponent as RelaunchIcon } from 'assets/enveloppe.svg';
import { ReactComponent as SpamIcon } from 'assets/30px_status_spam.svg';
import { ReactComponent as UserIcon } from 'assets/user.svg';

import styles from './EmailsList.module.scss';

const EmailsList = ({ className, emails = [], handleRemove, handleRelaunch, openEditForm, retailerId, supplierId }) => {
  const { t } = useTranslation();
  const [relaunchingId, setRelaunchingId] = useState(null);
  const [userSupplierList, setUserSupplierList] = useState([]);

  const handleRelaunchAction = async (userId) => {
    setRelaunchingId(userId);
    await handleRelaunch(userId);
    await getSupplier({ retailerId, supplierId });
    const userSupplierListResponse = await getUserSuppliers(supplierId);
    if (userSupplierListResponse) {
      setUserSupplierList(userSupplierList);
    }
    setRelaunchingId(null);
  };

  return (
    <section className={`${className} ${styles['users-list']}`}>
      {emails.map((i, index) => {
        const isEmailVerified = i.verified !== undefined ? i.verified : true;
        return (
          <div
            className={`${styles['user']} ${isEmailVerified ? styles['verified'] : styles['not-verified']}`}
            key={i.email}
          >
            <span className={styles['user-icon']}>{isEmailVerified && <UserIcon />}</span>
            {!openEditForm && !isEmailVerified && i.messageRefreshedAt ? (
              <Tooltip title={t('supplier_details_email_date', { emailDate: i.messageRefreshedAt })}>
                <span className={`${styles['user-email']}`}>{i.email}</span>
              </Tooltip>
            ) : !openEditForm && !isEmailVerified && i.createdAt ? (
              <Tooltip title={t('supplier_details_email_date', { emailDate: i.createdAt })}>
                <span className={`${styles['user-email']}`}>{i.email}</span>
              </Tooltip>
            ) : (
              <span className={`${styles['user-email']}`}>{i.email}</span>
            )}

            {handleRemove ? (
              <span
                onClick={() => handleRemove(i)}
                role="button"
                className={styles['remove-icon']}
                id={`remove_email_${index}`}
              >
                <RemoveIcon />
              </span>
            ) : (
              i.verified !== undefined &&
              (i.verified ? (
                <>
                  <span className={`${styles['user-status']}`}>{t('supplier_details_email_registered')}</span>
                  <span className={`${styles['user-check']}`}>
                    <CheckIcon />
                  </span>
                </>
              ) : (
                <>
                  <span className={`${styles['user-relaunch']}`}>
                    <ButtonsCustom
                      classType="action"
                      method={() => handleRelaunchAction(i.id)}
                      startIconCustom={relaunchingId !== i.id && <RelaunchIcon />}
                      loading={relaunchingId === i.id}
                      tooltip={t('supplier_details_email_relaunch')}
                    />
                  </span>
                  {i.messageStatusHistory?.[0]?.status === mailStatuses.CLICKED ? (
                    <>
                      <span className={`${styles['user-status']}`}>{t('supplier_details_email_clicked')}</span>
                      <span className={`${styles['user-check']}`}>
                        <ClickedIcon />
                      </span>
                    </>
                  ) : i.messageStatusHistory?.[0]?.status === mailStatuses.SPAM ? (
                    <>
                      <span className={`${styles['user-status']}`}>{t('supplier_details_email_spam')}</span>
                      <span className={`${styles['user-check']}`}>
                        <SpamIcon />
                      </span>
                    </>
                  ) : i.messageStatusHistory?.[0]?.status === mailStatuses.OPENED ? (
                    <>
                      <span className={`${styles['user-status']}`}>{t('supplier_details_email_opened')}</span>
                      <span className={`${styles['user-check']}`}>
                        <MailOpenIcon />
                      </span>
                    </>
                  ) : i.messageStatusHistory?.[0]?.status === mailStatuses.NOT_DELIVERED ? (
                    <>
                      <span className={`${styles['user-status']}`}>{t('supplier_details_email_not_delivred')}</span>
                      <span className={`${styles['user-check']}`}>
                        <CrossIcon />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className={`${styles['user-status']}`}>{t('supplier_details_email_delivred')}</span>
                      <span className={`${styles['user-check']}`}>
                        <DelivredIcon />
                      </span>
                    </>
                  )}
                </>
              ))
            )}
          </div>
        );
      })}
    </section>
  );
};

EmailsList.propTypes = {
  className: PropTypes.string,
  emails: PropTypes.array,
  handleRelaunch: PropTypes.func,
  handleRemove: PropTypes.func,
  openEditForm: PropTypes.number,
  retailerId: PropTypes.number,
  supplierId: PropTypes.number,
};

export default EmailsList;

export const initialState = {
  apiCallError: false,
  apiDocs: null,
  campaignCreation: {
    product: {
      campaignType: 'PRODUCT',
      distributionChannels: ['DIGITAL', 'STORE'],
      endDate: null,
      genericTargetingStrategyCampaignOffersCount: 0,
      id: null,
      offers: [],
      offersCount: 0,
      targetingKpisOfferCampaign: {
        numberOfClients: null,
        numberOfDefaultProductOffers: null,
        numberOfPrivateLabelOffers: null,
        numberOfRetailerProductOffers: null,
        numberOfSupplierProductOffers: null,
        productOffersClientsRate: null,
        totalOfProductOffers: null
      },
      offersPreviewAlreadyAddedCount: 0,
      offersPreviewCount: 0,
      offersUntargetablePreviewCount: 0,
      offersUntargetablePreviewList: [],
      parameters: {
        offersMaximumNumber: 20,
        offersMinimumNumber: 0,
        offersNumberByCategory: 1
      },
      untargetableProductCampaignOffers: {
        list: [],
        listAll: [],
        total: null
      },
      retailer: { id: null },
      retailerSegment: null,
      startDate: null,
      targetingStatus: null,
      targetingUrl: '',
      title: ''
    }
  },
  campaigns: {
    details: {},
    list: [],
    page: 0,
    total: 0
  },
  cashCoupons: {
    details: {
      cashCoupon: {},
      offerCampaigns: []
    },
    list: [],
    listAll: [],
    total: 0
  },
  exitModalType: null,
  initIsLoading: true,
  offers: {
    details: { offer: {}, offerCampaigns: [], brandList: [] },
    list: [],
    page: 0,
    total: 0
  },
  offersImports: {
    list: [],
    details: {
      listWithImage: [],
      listWithoutImage: []
    }
  },
  products: {
    searchResultList: [],
    listAll: [],
    list: [],
    page: 0,
    limit: 30,
    sort: 'code',
    order: 'desc',
    total: 0,
    loading: false
  },
  queryFilters: {},
  segments: {
    details: {},
    estimateCustomerCount: null,
    list: [],
    listAll: [],
    total: 0
  },
  snackbar: { type: null, title: '', desc: '' },
  supplierBudgetsAll: [],
  supplierBudgets: { list: [], orderBy: 'desc', page: 0, sortBy: 'percentageUnpositioned', total: 0 },
  retailer: {},
  retailers: [],
  strategies: [],
  suppliers: [],
  retailersList: {
    list: [],
    page: 0,
    total: 0
  },
  suppliersList: {
    details: {},
    list: [],
    page: 0,
    total: 0
  },
  dams: [],
  timezones: [],
  currencies: [],
  countries: [],
  locales: [],
  notificationTemplates: [],
  offerConstraints: [],
  user: {
    id: '',
    code: '',
    currency: '',
    discountUnits: [],
    lastname: '',
    firstname: '',
    userType: '', // userType: retailer or supplier (in order to display dynamic screen)
    roles: [], // roles: admin, crew (in order to display dynamic menu)
    token: '',
    name: '' // retailer or supplier company name
  },
  userManagers: [],
  monitoringRetailer: {
    offersToDisable: { list: [], orderBy: 'desc', page: 0, sortBy: 'offerHead.percentageBudgetRemaining', total: 0 },
    offersToValidate: { list: [], orderBy: 'desc', page: 0, sortBy: 'createdAt', total: 0 },
    supplierBudgets: { list: [], orderBy: 'desc', page: 0, sortBy: 'percentageUnpositioned', total: 0 }
  },
  targetingStrategies: [],
  targetingStrategiesWithUntargetable: [],
  universesRetailerList: []
};

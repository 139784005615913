import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLang } from 'utils/global';
import moment from 'moment-timezone';
import translationEN from './en.json';
import translationFR from './fr.json';

const languages = {
  FR: 'fr',
  EN: 'en',
};

const defaultLanguage = languages.EN;

const formatPrice = (value, lng, currency, maximumFractionDigits = 0) => {
  if (isNaN(parseInt(value))) {
    return value;
  }
  return new Intl.NumberFormat(lng, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    maximumFractionDigits,
  }).format(value);
};

export const formatNumber = (value, lng, maximumFractionDigits = 0) => {
  if (isNaN(parseInt(value))) {
    return value;
  }
  return new Intl.NumberFormat(lng, {
    style: 'decimal',
    maximumFractionDigits,
    minimumFractionDigits: maximumFractionDigits,
  }).format(value);
};

export const formatDate = (value, lng, useShortYear) => {
  const lngCode = lng.split('-');
  return lngCode[0] === languages.FR
    ? moment(value).format(useShortYear ? 'DD/MM/YY' : 'DD/MM/YYYY')
    : moment(value).format('MM-DD-YY');
};

const formatTime = (value, lng) => {
  let lngCode = lng.split('-');
  const time = moment(value);
  return lngCode[0] === languages.FR ? time.format('HH:mm') : time.format('h:mm A');
};

const formatAbbreviation = (value, lng) => {
  let lngCode = lng.split('-')[0];
  const data = {
    fr: { 1: 'er', x: 'ème' },
    en: { 1: 'st', 2: 'nd', 3: 'rd', x: 'th' },
  };
  const attr = data[lngCode] ? data[lngCode][value] || data[lngCode]['x'] : data['en'][value];
  return `${value}${attr}`;
};

export default i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3', // i18n v4 is breaking change on _plural suffix
  lng: getLang(),
  detection: { caches: [] },
  resources: {
    en: {
      translation: translationEN,
    },
    fr: {
      translation: translationFR,
    },
  },
  fallbackLng: [defaultLanguage],
  keySeparator: '.', // to support nested translations
  interpolation: {
    format: (value, format, lng, args) => {
      if (format === 'number') return formatNumber(value, lng, args.maximumFractionDigits);
      if (format === 'price') return formatPrice(value, lng, args.currency, args.maximumFractionDigits);
      if (format === 'date') return formatDate(value, lng, args.useShortYear);
      if (format === 'time') return formatTime(value, lng);
      if (format === 'abbreviation') return formatAbbreviation(value, lng);
      return value;
    },
    escapeValue: false, // react already safes from xss
  },
});

import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { store } from 'store';
import { useHistory } from 'react-router-dom';
import { getAllAvailableBudgetYearsSupplierOfferByRetailerId } from 'api';
import { checkYearFormat, QsParse, QsStringify } from 'utils/global';

import DashboardOffersCashCoupon from './DashboardOffersCashCoupon';
import DashboardOffersRetailer from './DashboardOffersRetailer';
import DashboardOffersSupplier from './DashboardOffersSupplier';
import DateYearPicker from 'components/DateYearPicker';
import TopNav from '../TopNav';

import styles from '../Dashboard.module.scss';

const Dashboard = () => {
  const history = useHistory();
  const globalState = useContext(store);
  const { state } = globalState as any;

  const budgetYear = useMemo(() => {
    const urlFilters = QsParse(history.location.search);
    const year = urlFilters?.budgetYear
      ? checkYearFormat(urlFilters?.budgetYear)
        ? parseInt(urlFilters?.budgetYear as string)
        : new Date().getFullYear()
      : new Date().getFullYear();

    return year;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filterYear, setFilterYear] = useState<number>(budgetYear);
  const [yearList, setYearList] = useState<number[] | undefined>(undefined);
  const [loadingAvailableYears, setLoadingAvailableYears] = useState<boolean>(true);

  useEffect(() => {
    const getYearList = async () => {
      setLoadingAvailableYears(true);
      const list = await getAllAvailableBudgetYearsSupplierOfferByRetailerId(state.user.id);
      if (list?.length) {
        setYearList(list.reverse());
      }
      setLoadingAvailableYears(false);
    };

    getYearList();
    handleUpdateUrl({ budgetYear: filterYear });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleUpdateUrl({ budgetYear: filterYear });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterYear]);

  const handleUpdateUrl = (obj: { budgetYear: number }) => {
    const urlFilters = QsParse(history.location.search);
    const qs = QsStringify({ ...urlFilters, ...obj });
    history.push({
      pathname: history.location.pathname,
      search: qs
    });
    localStorage.setItem('dashboardRetailer_retailerTabFilter', `?${qs}`);
  };

  const handleYearFilterChange = (value: number) => {
    setFilterYear(value);
  };

  return (
    <div className={styles['root']}>
      <TopNav index={0} />
      <section className={styles['main-content']}>
        <div className={clsx(styles['header'], styles['justify-end'])}>
          <DateYearPicker
            loading={loadingAvailableYears}
            onValidation={handleYearFilterChange}
            value={filterYear}
            yearList={yearList}
          />
        </div>
        <DashboardOffersSupplier
          filterYear={filterYear}
          loadingAvailableYears={loadingAvailableYears}
          handleUpdateUrl={handleUpdateUrl}
          yearList={yearList || []}
        />
        <DashboardOffersRetailer filterYear={filterYear} />
        <DashboardOffersCashCoupon filterYear={filterYear} />
      </section>
    </div>
  );
};

export default Dashboard;

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { store } from 'store';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getAllSupplierBudgets,
  getAllUniverses,
  getDashboardRetailerOffersArea,
  getDashboardRetailerOffersCharts,
  getTargetingStrategies
} from 'api';
import { convertDataForScatterPlotGraphs } from './utils';
import { checkYearFormat, QsParse, QsStringify } from 'utils/global';
import { offerStatuses } from 'utils/constants';
import { withFractionDigits } from 'utils/global';

import DateYearMultiPicker from 'components/DateYearMultiPicker';
import Divider from 'components/Divider';
import Drawer from 'components/Drawer';
import FiltersContainer, { getInitialFilters } from 'components/Filters';
import FramedIcon from 'components/FramedIcon';
import ScatterPlotCustom from './ScatterPlotCustom';
import SwitchCustom from 'components/SwitchCustom';
import Tooltip from 'components/Tooltip';
import TopNav from '../TopNav';

import { ReactComponent as CalendarIcon } from 'assets/24px_calendrier.svg';
import { ReactComponent as InfoIcon } from 'assets/30px_info_round.svg';
import { ReactComponent as MoneyBagIcon } from 'assets/20px_bourse.svg';
import { ReactComponent as ModuloIcon } from 'assets/modulo.svg';
import { ReactComponent as MoneyIcon } from 'assets/24px_monnaie.svg';
import { ReactComponent as RocketIcon } from 'assets/24px_fusée.svg';

import styles from '../Dashboard.module.scss';

import {
  DashboardRetailerOffersAreaChartsType,
  DashboardRetailerOffersAreaKeyFigureType
} from 'types/dashboardRetailer';
import { DataPropType, DatumType } from 'components/ScatterPlotGraph';
import { supplierBudgetType } from 'types/supplierBudget';
import { supplierType } from 'types/supplier';
import { targetMarketingType } from 'types/targetMarketing';
import OffersDetails from 'pages/OffersDetails';

interface IResetAllFilters {
  exclusionList: string[];
}
interface IFiltersContainerResetRef {
  resetAllFilters: ({ exclusionList }?: IResetAllFilters) => void;
}

interface IGlobalState {
  offers: { details: { offer: {}; offerCampaigns: []; brandList: [] }; list: []; page: 0; total: 0 };
  queryFilters: any;
  supplierBudgetsAll: supplierBudgetType[];
  targetingStrategies: targetMarketingType[];
  universesRetailerList: string[];
  user: any;
}

interface IUrlFilters {
  status?: string[];
  supplierBudgetIds?: number[];
  supplierId?: number[];
  universe?: string[];
  budgetYears?: number[];
}

interface IKpiContainer {
  key: string;
  color: string;
  icon: JSX.Element;
  value: string;
  valueToCompare: string | null;
}

const currentYear = new Date().getFullYear();

const Dashboard = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalState = useContext(store);
  const { dispatch, state } = globalState as any;
  const {
    offers: { details: offerDetails },
    queryFilters,
    supplierBudgetsAll,
    targetingStrategies,
    universesRetailerList,
    user
  }: IGlobalState = state;

  const currencyCode = user.currency.code;
  const retailerId = user.id;

  const resetFiltersRef = useRef<IFiltersContainerResetRef>(null);
  const resetFiltersRef2 = useRef<IFiltersContainerResetRef>(null);

  const [useCompare, setUseCompare] = useState(false);

  //******** fetch needed data for filters **********/
  useEffect(() => {
    getAllUniverses();
    getTargetingStrategies();
  }, []);

  //******** budgetYears filter ***********//
  const budgetYears = useMemo(() => {
    const urlFilters: IUrlFilters = QsParse(history.location.search);

    const years = (
      urlFilters?.budgetYears
        ? Array.isArray(urlFilters?.budgetYears)
          ? urlFilters?.budgetYears
          : [urlFilters?.budgetYears]
        : [new Date().getFullYear()]
    ).filter((year) => !!checkYearFormat(year));
    return years || currentYear;
  }, [history.location.search]);

  const initialOfferStatuses = useMemo(() => {
    const urlFilters: IUrlFilters = QsParse(history.location.search);

    const statuses = urlFilters?.status
      ? Array.isArray(urlFilters?.status)
        ? urlFilters?.status
        : [urlFilters?.status]
      : [offerStatuses.EXPIRED, offerStatuses.DISABLED];
    return statuses;
  }, [history.location.search]);

  //****** fetch needed data for suppliers filter *******/
  useEffect(() => {
    if (budgetYears && retailerId) {
      getAllSupplierBudgets({
        retailerId,
        total: 0,
        year: budgetYears
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetYears]);

  const suppliersWithBudget: supplierType[] = useMemo(() => {
    if (supplierBudgetsAll) {
      // first create the supplier list from supplierBudgets fetched by budgetYears
      // with uniqueness because of budget Digital and budget Paper on the same supplier
      const list = new Set<string>([]);
      supplierBudgetsAll.map((budgetSupplier: supplierBudgetType) => list.add(JSON.stringify(budgetSupplier.supplier)));
      const newList = [...list].map((el) => JSON.parse(el));
      return newList;
    }
    return [];
  }, [supplierBudgetsAll]);

  //******** set initial filters ********/
  const initFilters = useMemo(
    () => {
      return getInitialFilters({
        history,
        queryFilters: queryFilters,
        suppliers: suppliersWithBudget,
        targetingStrategies: targetingStrategies,
        universes: universesRetailerList
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [targetingStrategies, suppliersWithBudget, universesRetailerList]
  );

  const [filters, setFilters] = useState<IUrlFilters>({ ...initFilters, budgetYears, status: initialOfferStatuses });

  const handleUpdateUrl = (obj: IUrlFilters, disableMerge?: boolean) => {
    let qs: string = '';
    if (disableMerge) {
      qs = QsStringify({ ...obj });
      history.push({
        pathname: history.location.pathname,
        search: qs
      });
    } else {
      const urlFilters = QsParse(history.location.search);
      qs = QsStringify({ ...urlFilters, ...obj });
      history.push({
        pathname: history.location.pathname,
        search: qs
      });
    }

    localStorage.setItem('dashboardRetailer_offerTabFilter', `?${qs}`);
  };

  const suppliersAvailableList = useMemo(() => {
    // keep only the suppliers with selected universes
    // else return suppliersWithBudget
    const withSelectedUniverse = !filters.universe?.length
      ? suppliersWithBudget
      : suppliersWithBudget.filter((s) => {
          if (s.universe) {
            return filters.universe?.includes(s.universe);
          }
          return false;
        });

    return withSelectedUniverse;
  }, [filters.universe, suppliersWithBudget]);

  useEffect(() => {
    if (!!filters.supplierId?.length && !filters.universe?.length) {
      const list: (string | null)[] = suppliersAvailableList
        .filter((supplier) => filters.supplierId?.includes(supplier.id))
        .map((supplier) => supplier.universe);

      if (!list.includes(null)) {
        let newList: string[] = list.filter((universe) => {
          return !!universe;
        }) as string[];
        newList = newList.reduce((acc, value) => {
          if (!acc.includes(value)) {
            acc.push(value);
          }
          return acc;
        }, [] as string[]);

        handleUpdateUrl({ universe: newList });
        setFilters((state) => ({ ...state, universe: newList }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.supplierId]);

  const handleYearFilterChange = (values: number[]) => {
    const offerStatusesCopy = filters.status;
    const newValues = values.length ? values : [currentYear];
    resetFiltersRef.current?.resetAllFilters({ exclusionList: ['status'] });
    resetFiltersRef2.current?.resetAllFilters();
    setFilters({ budgetYears: newValues, status: offerStatusesCopy });
    handleUpdateUrl({ budgetYears: newValues, status: offerStatusesCopy }, true);
  };

  const handleUpdateFilters = (newFilters: IUrlFilters) => {
    const filtersCopy = { ...newFilters };

    handleUpdateUrl(filtersCopy);
    setFilters(filtersCopy);
  };

  const handleUpdateFiltersSecondaryLine = (newFilters: IUrlFilters) => {
    const filtersCopy = {
      ...newFilters,
      budgetYears: filters.budgetYears,
      status: filters.status,
      supplierId: filters.supplierId,
      universe: filters.universe
    };

    handleUpdateUrl(filtersCopy);
    setFilters(filtersCopy);
    // setFiltersSecondLine(filtersCopy);
  };

  //********** kpis data ************//
  const [kpis, setKpis] = useState<DashboardRetailerOffersAreaKeyFigureType>();

  const kpisResults: IKpiContainer[][] = useMemo(() => {
    const keyFigure = kpis?.keyFigure;
    const keyFigureToCompare = kpis?.keyFigureToCompare;
    return [
      [
        {
          key: 'averageDiscount',
          color: 'blue',
          icon: user.currency.symbol,
          value:
            keyFigure?.averageDiscount || keyFigure?.averageDiscount === 0
              ? t('commun_price', {
                  currency: currencyCode,
                  value: keyFigure.averageDiscount,
                  maximumFractionDigits: withFractionDigits(keyFigure.averageDiscount)
                })
              : '-',
          valueToCompare: useCompare
            ? keyFigureToCompare?.averageDiscount || keyFigureToCompare?.averageDiscount === 0
              ? t('commun_price', {
                  currency: currencyCode,
                  value: keyFigureToCompare.averageDiscount,
                  maximumFractionDigits: withFractionDigits(keyFigureToCompare.averageDiscount)
                })
              : '-'
            : null
        },
        {
          key: 'averageDiscountPercentage',
          color: 'blue',
          icon: <ModuloIcon />,
          value:
            keyFigure?.averageDiscountPercentage || keyFigure?.averageDiscountPercentage === 0
              ? t('commun_percentage_number', {
                  value: keyFigure.averageDiscountPercentage,
                  maximumFractionDigits: withFractionDigits(keyFigure.averageDiscountPercentage)
                })
              : '-',
          valueToCompare: useCompare
            ? keyFigureToCompare?.averageDiscountPercentage || keyFigureToCompare?.averageDiscountPercentage === 0
              ? t('commun_percentage_number', {
                  value: keyFigureToCompare.averageDiscountPercentage,
                  maximumFractionDigits: withFractionDigits(keyFigureToCompare.averageDiscountPercentage)
                })
              : '-'
            : null
        },
        {
          key: 'averageDuration',
          color: 'blue',
          icon: <CalendarIcon />,
          value:
            keyFigure?.averageDuration || keyFigure?.averageDuration === 0
              ? t('commun_day_count_short', {
                  value: keyFigure.averageDuration
                })
              : '-',
          valueToCompare: useCompare
            ? keyFigureToCompare?.averageDuration || keyFigureToCompare?.averageDuration === 0
              ? t('commun_day_count_short', {
                  value: keyFigureToCompare.averageDuration
                })
              : '-'
            : null
        },
        {
          key: 'averageTargetBudget',
          color: 'yellow',
          icon: <MoneyBagIcon />,
          value:
            keyFigure?.averageTargetBudget || keyFigure?.averageTargetBudget === 0
              ? t('commun_price', {
                  currency: currencyCode,
                  value: keyFigure.averageTargetBudget,
                  maximumFractionDigits: withFractionDigits(keyFigure.averageTargetBudget)
                })
              : '-',
          valueToCompare: useCompare
            ? keyFigureToCompare?.averageTargetBudget || keyFigureToCompare?.averageTargetBudget === 0
              ? t('commun_price', {
                  currency: currencyCode,
                  value: keyFigureToCompare.averageTargetBudget,
                  maximumFractionDigits: withFractionDigits(keyFigureToCompare.averageTargetBudget)
                })
              : '-'
            : null
        },
        {
          key: 'averageSpentBudget',
          color: 'yellow',
          icon: <MoneyBagIcon />,
          value:
            keyFigure?.averageSpentBudget || keyFigure?.averageSpentBudget === 0
              ? t('commun_price', {
                  currency: currencyCode,
                  value: keyFigure?.averageSpentBudget,
                  maximumFractionDigits: withFractionDigits(keyFigure?.averageSpentBudget)
                })
              : '-',
          valueToCompare: useCompare
            ? keyFigureToCompare?.averageSpentBudget || keyFigureToCompare?.averageSpentBudget === 0
              ? t('commun_price', {
                  currency: currencyCode,
                  value: keyFigureToCompare.averageSpentBudget,
                  maximumFractionDigits: withFractionDigits(keyFigureToCompare.averageSpentBudget)
                })
              : '-'
            : null
        }
      ],
      [
        {
          key: 'averageBurnRate',
          color: 'yellow',
          icon: <MoneyBagIcon />,
          value:
            keyFigure?.averageBurnRate || keyFigure?.averageBurnRate === 0
              ? t('commun_percentage_number', {
                  value: keyFigure.averageBurnRate,
                  maximumFractionDigits: withFractionDigits(keyFigure.averageBurnRate)
                })
              : '-',
          valueToCompare: useCompare
            ? keyFigureToCompare?.averageBurnRate || keyFigureToCompare?.averageBurnRate === 0
              ? t('commun_percentage_number', {
                  value: keyFigureToCompare.averageBurnRate,
                  maximumFractionDigits: withFractionDigits(keyFigureToCompare.averageBurnRate)
                })
              : '-'
            : null
        },
        {
          key: 'averageEstimatedTurnover',
          color: 'green',
          icon: <MoneyIcon />,
          value:
            keyFigure?.averageEstimatedTurnover || keyFigure?.averageEstimatedTurnover === 0
              ? t('commun_price', {
                  currency: currencyCode,
                  value: keyFigure.averageEstimatedTurnover,
                  maximumFractionDigits: withFractionDigits(keyFigure.averageEstimatedTurnover)
                })
              : '-',
          valueToCompare: useCompare
            ? keyFigureToCompare?.averageEstimatedTurnover || keyFigureToCompare?.averageEstimatedTurnover === 0
              ? t('commun_price', {
                  currency: currencyCode,
                  value: keyFigureToCompare?.averageEstimatedTurnover,
                  maximumFractionDigits: withFractionDigits(keyFigureToCompare?.averageEstimatedTurnover)
                })
              : '-'
            : null
        },
        {
          key: 'totalReturnOnAdSpend',
          color: 'green',
          icon: <RocketIcon />,
          value:
            keyFigure?.totalReturnOnAdSpend || keyFigure?.totalReturnOnAdSpend === 0
              ? t('commun_number', {
                  value: keyFigure.totalReturnOnAdSpend,
                  maximumFractionDigits: withFractionDigits(keyFigure.totalReturnOnAdSpend)
                })
              : '-',
          valueToCompare: useCompare
            ? keyFigureToCompare?.totalReturnOnAdSpend || keyFigureToCompare?.totalReturnOnAdSpend === 0
              ? t('commun_number', {
                  value: keyFigureToCompare?.totalReturnOnAdSpend,
                  maximumFractionDigits: withFractionDigits(keyFigureToCompare?.totalReturnOnAdSpend)
                })
              : '-'
            : null
        }
      ]
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpis, useCompare]);

  const renderKpiContainer = (kpi: IKpiContainer, large: boolean) => {
    return (
      <div className={clsx(styles['kpi-container'], styles[kpi.color], large && styles['large'])} key={kpi.key}>
        <FramedIcon color={kpi.color} icon={kpi.icon} size="big" />
        <div className={styles['data-container']}>
          <p>
            {kpi.value}
            {kpi.valueToCompare && !!kpis?.keyFigure?.countOffer && <span> &bull; {kpi.valueToCompare}</span>}
          </p>
          <p>
            {t(`dashboardRetailer_offerArea_kpi_${kpi.key}_label`)}
            <Tooltip placement="bottom" title={t(`dashboardRetailer_offerArea_kpi_${kpi.key}_tooltip`)}>
              <span className={styles['tooltip-icon']}>
                <InfoIcon />
              </span>
            </Tooltip>
          </p>
        </div>
      </div>
    );
  };

  //********** Graphs data **********//
  const [graphs, setGraphs] = useState<DataPropType[][]>([[]]);
  const [isLoadingGraph, setIsLoadingGraph] = useState<boolean>(true);
  const [offerHeadIdClicked, setOfferHeadIdClicked] = useState<number>();
  const [zoomedGraphIndex, setZoomedGraphIndex] = useState<number>();

  const getSupplierBudgets = async () => {
    const supplierBudgets = !!filters?.supplierId?.length
      ? await getAllSupplierBudgets({
          retailerId,
          supplierIds: filters.supplierId,
          total: 0,
          year: filters.budgetYears
        })
      : [];
    return supplierBudgets;
  };

  const fetchKpiData = async (supplierBudgets: supplierBudgetType[]) => {
    const dataKpis: DashboardRetailerOffersAreaKeyFigureType | false = await getDashboardRetailerOffersArea({
      budgetIds: supplierBudgets.map((b) => b.id),
      filters: filters,
      retailerId,
      useCompare: true // always fetch with useCompare true
    });

    setKpis(dataKpis || undefined);
  };

  const fetchChartsData = async (supplierBudgets: supplierBudgetType[]) => {
    setIsLoadingGraph(true);
    const dataGraphs: DashboardRetailerOffersAreaChartsType | false = await getDashboardRetailerOffersCharts({
      budgetIds: supplierBudgets.map((b) => b.id),
      filters: filters,
      retailerId
    });

    if (dataGraphs) {
      const newData: DataPropType[][] = convertDataForScatterPlotGraphs(dataGraphs);
      setIsLoadingGraph(false);
      setGraphs(newData);
    } else {
      setIsLoadingGraph(false);
      setGraphs([]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const supplierBudgets: supplierBudgetType[] = await getSupplierBudgets();

      if (supplierBudgets) {
        setUseCompare(false);
        fetchKpiData(supplierBudgets);

        setIsLoadingGraph(true);
        fetchChartsData(supplierBudgets);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleScatterPlotDotClick = useCallback((datum: DatumType) => {
    const offerHeadId: number = datum.rawData.offerHeadId as number;
    setOfferHeadIdClicked(offerHeadId);
  }, []);

  const renderScatterPlot = (index: number): JSX.Element | false => {
    const defaultProps = {
      handleClick: handleScatterPlotDotClick,
      handleClickCancelZoomedState: () => {
        setZoomedGraphIndex(undefined);
      },
      indexForZoom: zoomedGraphIndex,
      isLoading: isLoadingGraph
    };

    switch (index) {
      case 0:
        return (
          <ScatterPlotCustom
            data={graphs[0]}
            handleClickZoomedIndex={() => {
              setZoomedGraphIndex(0);
            }}
            title={t('commun_burnRate')}
            yAxisFormatType="percentage"
            yPropData="burnRate"
            {...defaultProps}
          >
            {renderKpiContainer(kpisResults[1][0], true)}
          </ScatterPlotCustom>
        );
      case 1:
        return (
          <ScatterPlotCustom
            currencyCode={currencyCode}
            data={graphs[1]}
            handleClickZoomedIndex={() => {
              setZoomedGraphIndex(1);
            }}
            title={t('commun_turnover_earned')}
            yAxisFormatType="currency"
            yPropData="estimatedTurnover"
            {...defaultProps}
          >
            {renderKpiContainer(kpisResults[1][1], true)}
          </ScatterPlotCustom>
        );
      case 2:
        return (
          <ScatterPlotCustom
            data={graphs[2]}
            handleClickZoomedIndex={() => {
              setZoomedGraphIndex(2);
            }}
            title={t('commun_roas')}
            yAxisFormatType="number"
            yPropData="returnOnAdSpent"
            {...defaultProps}
          >
            {renderKpiContainer(kpisResults[1][2], true)}
          </ScatterPlotCustom>
        );
      default:
        return false;
    }
  };

  return (
    <>
      <div className={styles['root']}>
        <TopNav index={2} />
        <section className={styles['main-content']}>
          <div className={styles['filters-first-line']}>
            <div>
              <FiltersContainer
                /* @ts-ignore */
                containerWithNoMargin={true}
                dispatch={dispatch}
                filters={filters}
                filtersListToDisplay={['retailerUser', 'universeLinkedToSupplierList', 'dashboardOfferStatus']}
                ref={resetFiltersRef}
                suppliersList={suppliersAvailableList}
                universesList={universesRetailerList}
                updateFilters={handleUpdateFilters}
                useResetButton={false}
                user={user}
              />
              {!!kpis?.keyFigure?.countOffer &&
                !!kpis.keyFigureToCompare &&
                !!filters.supplierId?.length &&
                !!filters.universe?.length && (
                  <div className={styles['switch-useCompare-container']}>
                    <SwitchCustom
                      checked={useCompare}
                      name="use compare"
                      onChange={() => {
                        setUseCompare((state) => !state);
                      }}
                      useMonoBlueColorCustomStyle={true}
                    />
                    <p>{t('dashboardRetailer_useCompare_switch_label', { text: t(`universe_${filters.universe}`) })}</p>
                  </div>
                )}
            </div>
            <DateYearMultiPicker
              onValidation={handleYearFilterChange}
              values={filters.budgetYears}
              yearList={[currentYear - 2, currentYear - 1, currentYear].map((year) => ({
                id: year.toString(),
                label: t('homepage_budgets_title', { year }),
                value: year
              }))}
            />
          </div>
          <Divider borderSize="thin" marginSize="thin" />

          <FiltersContainer
            /* @ts-ignore */
            dispatch={dispatch}
            filters={filters}
            filtersListToDisplay={[
              'discountMinQuantity',
              'targetingMarketingStrategy',
              'budgetTarget',
              'generosityPercentage',
              'averagePrice'
            ]}
            ref={resetFiltersRef2}
            suppliersList={suppliersAvailableList}
            targetingStrategiesList={targetingStrategies}
            universesList={universesRetailerList}
            updateFilters={handleUpdateFiltersSecondaryLine}
            user={user}
          />

          <div className={styles['block-main-title']}>
            {!!kpis?.keyFigure?.countOffer || kpis?.keyFigure?.countOffer === 0
              ? t('commun_offer_count', { count: kpis.keyFigure.countOffer })
              : '-'}
            {useCompare &&
              (!!kpis?.keyFigure?.countOffer || kpis?.keyFigure?.countOffer === 0) &&
              !!kpis?.keyFigureToCompare?.countOffer && (
                <span> &bull; {t('commun_offer_count', { count: kpis.keyFigureToCompare.countOffer })}</span>
              )}
          </div>
          {kpisResults.map((group, index) => (
            <div className={styles['kpi-section']} key={index.toString()}>
              {group.map((kpi) => renderKpiContainer(kpi, index === 1))}
            </div>
          ))}

          <div className={styles['graph-section']}>
            {renderScatterPlot(0)}
            {renderScatterPlot(1)}
            {renderScatterPlot(2)}
          </div>
          {zoomedGraphIndex !== undefined && renderScatterPlot(zoomedGraphIndex)}
        </section>
      </div>
      <Drawer
        isOpen={!!offerHeadIdClicked}
        onClose={() => {
          setOfferHeadIdClicked(undefined);
        }}
      >
        <OffersDetails
          details={offerDetails}
          offerHeadId={offerHeadIdClicked}
          onDeleted={() => {}}
          onUpdated={() => {}}
          readOnly={true}
          user={user}
        />
      </Drawer>
    </>
  );
};

export default Dashboard;

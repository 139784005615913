import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import getCroppedImg from './cropImage';
import ButtonsCustom from 'components/ButtonsCustom';
import Slider from 'components/SliderCustom';

import { ReactComponent as MinusIcon } from 'assets/30px_minus.svg';
import { ReactComponent as PlusIcon } from 'assets/30px_plus.svg';

import styles from './ImageCropper.module.scss';

const ImageCropper = ({ cropData, imgSrc, handleCropperClose, handleSetCroppedImage, retailer }) => {
  const { t } = useTranslation();

  const [crop, setCrop] = useState(cropData?.crop || { x: 0, y: 0 });
  const [zoom, setZoom] = useState(cropData?.zoom || 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(cropData?.croppedAreaPixels || null);
  const cropSize = {
    width: retailer?.settingOfferImageHorizontalSize,
    height: retailer?.settingOfferImageVerticalSize
  };

  // Cropper parameters
  const minZoom = 0.4;
  const maxZoom = 3;
  const adaptiveScale = 500 / (cropSize.width + 100);
  const adaptiveTranslate = (cropSize.width + 100) / 10;

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      const calculatedWidth = croppedAreaPixels.width * adaptiveScale;
      const calculatedheight = croppedAreaPixels.height * adaptiveScale;

      const newCropAreaPixels = {
        width: calculatedWidth,
        height: calculatedheight,
        x: croppedAreaPixels.x + (croppedAreaPixels.width - calculatedWidth) / 2,
        y: croppedAreaPixels.y + (croppedAreaPixels.height - calculatedheight) / 2
      };

      setCroppedAreaPixels(newCropAreaPixels);
    },
    [adaptiveScale]
  );

  const showCroppedImage = async () => {
    const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels);
    handleSetCroppedImage({ croppedImage, crop, cropSize, zoom, croppedAreaPixels });
    handleCropperClose();
  };

  return (
    <div className={styles['crop']}>
      <div className={styles['crop-container']}>
        <Cropper
          image={imgSrc}
          crop={crop}
          cropSize={cropSize}
          minZoom={minZoom}
          maxZoom={maxZoom}
          zoom={zoom}
          zoomSpeed={0.1}
          showGrid={false}
          restrictPosition={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          style={{
            containerStyle: {
              width: '100%',
              height: '100%'
            },
            cropAreaStyle: {
              transform: `scale(${adaptiveScale}) translate(-${adaptiveTranslate}%, -${adaptiveTranslate}%)`
            }
          }}
        />
      </div>
      <div className={styles['slider-container']}>
        <ButtonsCustom
          classType="actionLight"
          startIconCustom={<MinusIcon />}
          method={() => {
            setZoom((state) => {
              return state - 0.1;
            });
          }}
          disabled={zoom <= minZoom}
        />
        <Slider
          value={zoom}
          min={minZoom}
          max={maxZoom}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => {
            setZoom(zoom);
          }}
        />
        <ButtonsCustom
          classType="actionLight"
          startIconCustom={<PlusIcon />}
          method={() => {
            setZoom((state) => {
              return state + 0.1;
            });
          }}
          disabled={zoom >= maxZoom}
        />
      </div>
      <div className={styles['crop-validation']}>
        <ButtonsCustom classType="canceled" text={t('commun_cancel')} method={handleCropperClose} />
        <ButtonsCustom classType="action_primary_big" text={t('commun_save')} method={showCroppedImage} />
      </div>
    </div>
  );
};

ImageCropper.propTypes = {
  cropData: PropTypes.object,
  handleCropperClose: PropTypes.func.isRequired,
  handleSetCroppedImage: PropTypes.func.isRequired,
  imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default ImageCropper;

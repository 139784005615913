import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { CONSTRAINTS_TYPES, DAYS_UNIT } from 'utils/constants';
import { getLastDayOfYear, getLastValidityEndDate } from 'utils/global';

import ButtonsCustom from 'components/ButtonsCustom';
import DateRangePicker from 'components/DateRangePicker';
import Tooltip from 'components/Tooltip';

import { ReactComponent as DeleteIcon } from 'assets/22px_croix.svg';
import styles from './ValidityDatesSelection.module.scss';

const today = moment();

// order matters for moment().day()
const matchingMomentDaysOrder = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

const ValidityDatesSelection = ({
  data: {
    id,
    defaultValue = [],
    disabled,
    fieldProps: { linkedOfferCampaigns, offerConstraints, updateOfferCreation }
  },
  error,
  onChange
}) => {
  const { t } = useTranslation();

  const [datesList, setDatesList] = useState(defaultValue);
  const [dateFocusedInputState, setDateFocusedInputState] = useState(null); // null, startDate, endDate

  const existingDelayConstraint = offerConstraints.find((el) => el.target === CONSTRAINTS_TYPES.delay);
  const existingDurationConstraint = offerConstraints.find((el) => el.target === CONSTRAINTS_TYPES.duration);
  const existingEndDayConstraint = offerConstraints.find((el) => el.target === CONSTRAINTS_TYPES.endDay)
    ?.selectedValues?.[0];
  const existingStartDayConstraint = offerConstraints.find((el) => el.target === CONSTRAINTS_TYPES.startDay)
    ?.selectedValues?.[0];

  const isOutsideRange = ({ date, index, isLinkedToValidatedCampaign, lastEndingOfferCampaignDate }) => {
    // date can never be before today
    if (date.isBefore(today, 'day')) {
      return true;
    }

    // check existing delay (creationToStartGap) constraints
    if (existingDelayConstraint) {
      const delayDays =
        existingDelayConstraint.unit === DAYS_UNIT
          ? existingDelayConstraint.threshold
          : existingDelayConstraint.threshold * 7;

      if (date.diff(today, 'day') < delayDays) {
        return true;
      }
    }

    // check existing start days contraints
    // january 1st must be pickable
    if (
      existingStartDayConstraint &&
      dateFocusedInputState === 'startDate' &&
      matchingMomentDaysOrder[date.day()] !== existingStartDayConstraint &&
      date.dayOfYear() !== 1
    ) {
      return true;
    }

    // check existing end days contraints
    // december 31 must be pickable
    if (
      existingEndDayConstraint &&
      dateFocusedInputState === 'endDate' &&
      matchingMomentDaysOrder[date.day()] !== existingEndDayConstraint &&
      moment(date).endOf('year').dayOfYear() !== date.dayOfYear()
    ) {
      return true;
    }

    // prevents from selecting an endDate before a selected startDate
    // prevents a bug with day constraints
    if (
      dateFocusedInputState === 'endDate' &&
      datesList[index]?.startDate &&
      date.isBefore(datesList[index]?.startDate, 'day') &&
      !date.isSame(getLastDayOfYear(date.year()), 'day') &&
      date.year() !== getLastDayOfYear(date.year())
    ) {
      return true;
    }

    // let user select whatever endDate provided that endDate year < datepicker year
    const usefullDateWithYear = datesList.find((d) => d?.startDate || d?.endDate);
    const usefullDateYear = usefullDateWithYear?.startDate?.year() || usefullDateWithYear?.endDate?.year();

    // when only 1 period, limit the endDate selection but not the startDate
    if (
      datesList.length === 1 &&
      dateFocusedInputState === 'endDate' &&
      usefullDateYear &&
      usefullDateYear !== date.year()
    ) {
      return true;
    }

    // when more than 1 period, check is same year as usefullDateYear
    if (datesList.length > 1 && usefullDateYear && date.year() !== usefullDateYear) {
      return true;
    }

    // check is linked to a validated campaign
    // rule MODIF_OFFRE_PRODUIT_2
    if (
      isLinkedToValidatedCampaign &&
      lastEndingOfferCampaignDate &&
      (date.year() > getLastValidityEndDate(datesList).year() || date.isBefore(lastEndingOfferCampaignDate, 'day'))
    ) {
      return true;
    }

    // check no overlapping
    if (
      datesList.length > 1 &&
      datesList.some((d, idx) => {
        // [] param => inclusive
        // && skip check date against current datepicker old dates range
        if (date.isBetween(d.startDate, d.endDate, 'day', '[]') && idx !== index) {
          return true;
        }

        if (dateFocusedInputState === 'startDate' && datesList[index]?.endDate) {
          const minDate = moment.max(
            datesList.reduce(
              (acc, d) => {
                if (datesList[index].endDate.isAfter(d.endDate, 'day')) {
                  acc.push(d.endDate);
                }
                return acc;
              },
              [moment().subtract(1, 'day')]
            )
          );
          if (date.isSameOrBefore(minDate, 'day')) {
            return true;
          }
        }

        if (dateFocusedInputState === 'endDate' && datesList[index]?.startDate) {
          const maxDate = moment.min(
            datesList.reduce(
              (acc, d) => {
                if (datesList[index].startDate.isBefore(d.startDate, 'day')) {
                  acc.push(d.startDate);
                }
                return acc;
              },
              [moment(datesList[index].startDate).endOf('year').add(1, 'day')]
            )
          );
          if (date.isSameOrAfter(maxDate, 'day')) {
            return true;
          }
        }

        return false;
      })
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (defaultValue?.length) {
      setDatesList(
        defaultValue.map((d) => ({
          id: d.id,
          activeCampaignIds: d.activeCampaignIds,
          countActiveCampaign: d.countActiveCampaign,
          startDate: (d.startDate && moment(d.startDate)) || null,
          endDate: (d.endDate && moment(d.endDate)) || null
        }))
      );
    } else {
      setDatesList([{ startDate: null, endDate: null }]);
    }
  }, [defaultValue]);

  useEffect(() => {
    onChange(datesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datesList]);

  const checkErrorMessage = ({ startDate, endDate, isLinkedToCampaign, isDisabled }) => {
    if (!isLinkedToCampaign && startDate.isBefore(today, 'day')) {
      return t('offers_creation_startdate_before_today_error');
    }
    // can occur when editing an expired offer
    if (!isDisabled && endDate.isBefore(today, 'day')) {
      return t('offers_creation_enddate_before_today_error');
    }
    if (startDate.year() !== endDate.year()) {
      return t('offers_creation_datepicker_not_same_year_error');
    }
    if (existingDurationConstraint?.threshold) {
      const minDays =
        existingDurationConstraint.unit === DAYS_UNIT
          ? existingDurationConstraint.threshold
          : existingDurationConstraint.threshold * 7;

      if (endDate.diff(startDate, 'day') + 1 < minDays) {
        const i18nKey =
          existingDurationConstraint.unit === DAYS_UNIT
            ? 'offers_creation_period_constraints_diff_error'
            : 'offers_creation_period_constraints_diff_error_weeks';
        return t(i18nKey, { count: existingDurationConstraint.threshold });
      }
    }
    return false;
  };

  const handleAddNewPeriod = () => {
    let lastSelectedDate = getLastValidityEndDate(datesList);
    if (lastSelectedDate.isBefore(today, 'day')) {
      lastSelectedDate = moment().subtract(1, 'day');
    }
    if (existingDelayConstraint) {
      const delayDays =
        existingDelayConstraint.unit === DAYS_UNIT
          ? existingDelayConstraint.threshold
          : existingDelayConstraint.threshold * 7;

      const lastSelectedDateWithDelay = moment(lastSelectedDate).add(delayDays + 1, 'day');
      if (lastSelectedDate.year() !== lastSelectedDateWithDelay.year()) {
        lastSelectedDate = getLastDayOfYear(lastSelectedDate.year());
      } else {
        lastSelectedDate = lastSelectedDateWithDelay;
      }
    }

    let defaultNextDay = null;

    if (lastSelectedDate) {
      defaultNextDay = moment(lastSelectedDate).add(1, 'day');
      const matchingMomentDayIndex =
        existingStartDayConstraint && matchingMomentDaysOrder.findIndex((el) => el === existingStartDayConstraint);

      // apply startDay constraint
      if (existingStartDayConstraint) {
        defaultNextDay = moment(lastSelectedDate).day(matchingMomentDayIndex);
        if (defaultNextDay.isSameOrBefore(lastSelectedDate)) {
          defaultNextDay = moment(lastSelectedDate).day(matchingMomentDayIndex + 7); // +7 for bubbling to the next week
        }
      }

      // check if nextDay is same year
      if (getLastValidityEndDate(datesList).year() !== defaultNextDay.year()) {
        const startDatesList = datesList.map((d) => d.startDate);

        const possibleStartDatesList = datesList.reduce((acc, date) => {
          let availableStartDay;

          // apply startDay constraint
          if (existingStartDayConstraint) {
            availableStartDay = moment(date.endDate).day(matchingMomentDayIndex);
            if (availableStartDay.isSameOrBefore(date.endDate)) {
              availableStartDay = moment(date.endDate).day(matchingMomentDayIndex + 7); // +7 for bubbling to the next week
            }
          } else {
            availableStartDay = moment(date.endDate).add(1, 'day');
          }
          // create a list of available startDates
          if (
            availableStartDay.year() === lastSelectedDate.year() &&
            !startDatesList.find((startD) => startD.isSame(availableStartDay, 'day'))
          ) {
            acc.push(availableStartDay);
          }
          return acc;
        }, []);

        // pick up the first available startDate
        defaultNextDay = possibleStartDatesList.length ? possibleStartDatesList[0] : null;
      }
    }
    setDatesList([...datesList, { startDate: defaultNextDay, endDate: null }]);
  };

  const handleDateChange = ({ dates: { startDate, endDate }, index }) => {
    const newData = datesList.map((d) => ({
      id: d.id,
      activeCampaignIds: d.activeCampaignIds,
      countActiveCampaign: d.countActiveCampaign,
      startDate: d.startDate ? moment(d.startDate) : null,
      endDate: d.endDate ? moment(d.endDate) : null
    }));
    newData[index] = {
      ...newData[index],
      startDate: startDate ? moment(startDate) : null,
      endDate: endDate ? moment(endDate) : null
    };
    setDatesList(newData);

    updateOfferCreation({
      step4: {
        validityDates: newData
      }
    });
  };

  const handleRemovePeriod = (index) => {
    const newData = datesList.filter((el, idx) => idx !== index);
    setDatesList(newData);

    updateOfferCreation({
      step4: {
        validityDates: newData
      }
    });
  };

  return (
    <div className={styles['root']}>
      <div>
        {datesList.map(({ startDate, endDate, activeCampaignIds, countActiveCampaign }, index) => {
          const isLinkedToValidatedCampaign = !!countActiveCampaign;
          // check if Period can be removed
          const displayDeletePeriodIcons = datesList.length > 1 && !isLinkedToValidatedCampaign;
          // is editable ?
          const customDisabled = isLinkedToValidatedCampaign ? 'startDate' : disabled;

          const errorMsg =
            startDate &&
            endDate &&
            checkErrorMessage({
              startDate: startDate?.local(),
              endDate: endDate?.local(),
              isLinkedToCampaign: isLinkedToValidatedCampaign,
              isDisabled: customDisabled === true
            });

          // get lastEndingOfferCampaignDate for this period
          let lastEndingOfferCampaignDate;
          let campaignsInThisPeriodList;

          if (isLinkedToValidatedCampaign) {
            campaignsInThisPeriodList = activeCampaignIds.reduce((acc, value) => {
              const campaignFound = linkedOfferCampaigns.find((l) => l.campaign.id === value)?.campaign;
              if (campaignFound) {
                acc.push(campaignFound);
              }
              return acc;
            }, []);

            lastEndingOfferCampaignDate = getLastValidityEndDate(campaignsInThisPeriodList, 'validityEndDate');
          }

          // highlight campaigns dates on datepickers
          const highlightDates = campaignsInThisPeriodList
            ?.map((camp) => {
              const campDuration =
                moment.duration(moment(camp.validityEndDate).diff(moment(camp.validityStartDate))).days() + 1;
              const campaignRangeDates = Array(campDuration)
                .fill(null)
                .map((_, index) => {
                  return moment(camp.validityStartDate).add(index, 'days').toDate();
                });
              return campaignRangeDates;
            })
            .flat();

          return (
            <div key={index.toString()} className={styles['flex-container']}>
              <DateRangePicker
                className={clsx(displayDeletePeriodIcons && styles['datepicker-with-delete-icon'])}
                disabled={disabled || customDisabled}
                endDate={endDate}
                error={error || !!errorMsg}
                errorMsg={errorMsg}
                getFocusInput={setDateFocusedInputState}
                highlightDates={highlightDates}
                id={`${id}-${index}`}
                isOutsideRange={(date) =>
                  isOutsideRange({
                    date,
                    isLinkedToValidatedCampaign,
                    index,
                    lastEndingOfferCampaignDate
                  })
                }
                onDatesChange={(dates) => {
                  handleDateChange({ dates, index });
                }}
                placeholder={t('commun_validation_dates')}
                startDate={startDate}
                fullWidth
              />
              {displayDeletePeriodIcons && (
                <Tooltip title={t('offers_creation_delete_dates_period')} placement="right">
                  <DeleteIcon
                    className={styles['deleteIcon']}
                    onClick={() => {
                      handleRemovePeriod(index);
                    }}
                  />
                </Tooltip>
              )}
            </div>
          );
        })}
      </div>
      <ButtonsCustom
        classType="dashed"
        disabled={disabled || datesList.some((d) => !d.startDate || !d.endDate)}
        id="offer-creation-add-new-dates-period-button"
        method={handleAddNewPeriod}
        text={t('offers_creation_add_new_period_button_label')}
      />
    </div>
  );
};

ValidityDatesSelection.propTypes = {
  data: PropTypes.object,
  error: PropTypes.bool,
  onChange: PropTypes.func
};

export default ValidityDatesSelection;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';

import { useTranslation } from 'react-i18next';
import { getBudgetsAreaCashCoupon } from 'api';

import { ReactComponent as BudgetTotalIcon } from 'assets/30px_fournisseur.svg';
import { ReactComponent as GenerosityIcon } from 'assets/24px_generosité.svg';
import { ReactComponent as MoneyIcon } from 'assets/20px_bourse.svg';

import styles from '../../Dashboard.module.scss';

const Dashboard = ({ filterYear }) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state } = globalState;
  const { user } = state;

  const [budgetKpis, setBudgetKpis] = useState(null);

  const budgetKpisList = useMemo(() => {
    return [
      {
        icon: <BudgetTotalIcon />,
        label: 'dashboard_budgets_kpis_total_budget_spent',
        value: budgetKpis?.keyFigure?.totalBudgetSpent
          ? t('commun_price', { value: budgetKpis.keyFigure.totalBudgetSpent, currency: user.currency.code })
          : '-'
      },
      {
        icon: <GenerosityIcon />,
        label: 'commun_targeted_customers',
        value: budgetKpis?.keyFigure?.singleTargetCustomer
          ? t('commun_number', { value: budgetKpis.keyFigure.singleTargetCustomer })
          : '-'
      },
      {
        icon: <MoneyIcon />,
        label: 'dashboard_budgets_kpis_turnover_total',
        value: budgetKpis?.keyFigure?.totalTurnover
          ? t('commun_price', { value: budgetKpis.keyFigure.totalTurnover, currency: user.currency.code })
          : '-'
      }
    ];
  }, [budgetKpis, t, user.currency.code]);

  useEffect(() => {
    const getBudgetKpis = async () => {
      const data = await getBudgetsAreaCashCoupon({ budgetYear: filterYear, retailerId: user.id });
      setBudgetKpis(data);
    };
    getBudgetKpis();
  }, [filterYear, user.id]);

  return (
    <section className={styles['main-content']}>
      <div className={styles['block-title']}>{t('commun_cash_coupon')}</div>
      <div className={styles['block-container']}>
        {budgetKpisList.map((el) => (
          <div className={`${styles['frame']} ${styles['kpi-container-big']}`} key={el.label}>
            <div className={styles['icon-container']}>{el.icon}</div>
            <div className={styles['data-container']}>
              <div>{t(el.label)}</div>
              <div>{el.value}</div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

Dashboard.propTypes = {
  filterYear: PropTypes.number
};

export default Dashboard;

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { getAllProducts } from 'api';

import Accordion from 'components/AccordionExpandIconLeft';
import SelectCustom from 'components/SelectCustom';
import TableProducts from './TableProducts';

import { ReactComponent as TrashIcon } from 'assets/16px_trash.svg';
import styles from './BrandAutocomplete.module.scss';

const BrandAutocomplete = (props) => {
  const {
    error,
    data: {
      id,
      disabled,
      placeholder,
      fieldProps: { offerCreation, retailerId, supplierId, updateOfferCreation },
      onChange,
    },
  } = props;
  const { t } = useTranslation();
  const { dispatch } = useContext(store);

  const [allProductsCount, setAllProductsCount] = useState(0);
  const [linkedProductsCountList, setLinkedProductsCountList] = useState({}); // get the total of products per brand from the children

  const allBrandList = offerCreation.step3.filterBrandList || [];
  const brandList = offerCreation.step3.brands;

  useEffect(() => {
    if (onChange) onChange(id, brandList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandList]);

  useEffect(() => {
    const fetchData = async () => {
      const brandIds = brandList.map((i) => i.id);
      // TODO: rework image gallery to avoid using getAllProducts instead of getProductsCount
      // const countProductsResult = await getProductsCount({
      //   brandIds,
      //   supplierId,
      // });
      // setAllProductsCount(countProductsResult);

      const productsResult = await getAllProducts({
        brandIds,
        retailerId,
        supplierId,
      });
      if (productsResult) {
        setAllProductsCount(productsResult.length);
        updateOfferCreation({ step3: { resultListEan: productsResult } });
      }
    };

    if (brandList.length && supplierId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandList, supplierId]);

  const handleAddBrand = async (item) => {
    if (brandList.find((brand) => brand.id === item.target.value)) return;

    let newBrand = allBrandList.find((i) => i.id === item.target.value);
    const newBrandList = [...brandList, newBrand];

    updateOfferCreation({
      errors: { resultListBrandError: false },
      step3: {
        brands: newBrandList,
      },
    });
  };

  const handleRemoveBrand = (item) => {
    const newBrandList = brandList.filter((brand) => brand.id !== item.id);

    if (!newBrandList.length) {
      // TODO: rework image gallery to avoid using/simulating getAllProducts
      dispatch({ type: 'PRODUCTS_RESET' });
      updateOfferCreation({
        step3: {
          brands: [],
          resultListEan: [],
          image: false,
        },
      });
    } else {
      updateOfferCreation({
        step3: {
          brands: newBrandList,
          image: false,
        },
      });
    }
  };

  // get the total of products per brand from the children
  const handleUpdateLinkedProductsCountList = ({ brandId, value }) => {
    setLinkedProductsCountList((state) => ({ ...state, ...{ [brandId]: value } }));
  };

  return (
    <div className={styles['root']}>
      <div>
        <div className={styles['select']}>
          <SelectCustom
            blurOnSelect
            disabled={disabled}
            error={error}
            labelAttribute={'name'}
            listData={[...allBrandList]}
            methodOnChange={handleAddBrand}
            placeHolderText={placeholder}
            id={id}
          />
        </div>
        <p className={styles['warning-msg']}>{t('offers_creation_brand_products_warning_msg')}</p>
        {!!brandList.length && (
          <div className={styles['count-title']}>
            {t('offers_creation_brand_products_selected_title', { count: parseInt(brandList.length) })}
            <i> · {t('commun_product_count', { count: parseInt(allProductsCount) })}</i>
          </div>
        )}

        {brandList.map((brand) => (
          <Accordion
            classname={styles['accordion-custom']}
            defaultExpanded={false}
            key={brand.id}
            summary={
              <div className={styles['accordion-summary']}>
                <div>
                  {brand.name}
                  <i> · {t('commun_product_count', { count: linkedProductsCountList[brand.id] })}</i>
                </div>
                {!disabled && (
                  <TrashIcon
                    className={styles['trash-icon']}
                    onClick={() => {
                      handleRemoveBrand(brand);
                    }}
                  />
                )}
              </div>
            }
          >
            <TableProducts
              brand={brand}
              offerCreation={offerCreation}
              retailerId={retailerId}
              supplierId={supplierId}
              updateProductsCountList={handleUpdateLinkedProductsCountList}
            />
          </Accordion>
        ))}
      </div>
    </div>
  );
};

BrandAutocomplete.propTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  offerCreation: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  retailerId: PropTypes.number,
};

export default BrandAutocomplete;

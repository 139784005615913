import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import DOMPurify from 'dompurify';

import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { checkIsPositioningLate, getMonthIndexByLevels } from 'utils/budget';
import { withFractionDigits } from 'utils/global';
import { getMonitoredSupplierBudgetsAndOffersCount } from 'api';

import ButtonsCustom from 'components/ButtonsCustom';
import EmailsNotificationDialog from './EmailsNotificationDialog';
import ProgressBar from 'components/ProgressBar';
import SupplierNoteDialog from 'components/SupplierNoteDialog';
import TableCustom from 'components/TableCustom';
import Tooltip from 'components/Tooltip';

import { ReactComponent as NotesIcon } from 'assets/notes.svg';
import { ReactComponent as NotificationIcon } from 'assets/30px_email.svg';
import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';
import styles from './PilotageRetailer.module.scss';

import { stateSupplierBudgetsType } from 'types/storeState';
import { supplierBudgetType } from 'types/supplierBudget';
import { supplierType } from 'types/supplier';

type Props = {
  count: number | null;
  filters: { supplierId?: string[]; userManagerId?: string[] };
};

const tableSize = 10;

const MonitoredBudget = ({ count, filters }: Props): JSX.Element => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state, dispatch } = globalState as any;
  const {
    user,
    monitoringRetailer: { supplierBudgets }
  } = state;
  const currency = user.currency.code;

  // use the store for not refetching data when mounting after changing tab index
  const { list, orderBy, page, sortBy, total } = supplierBudgets as stateSupplierBudgetsType;

  const [isLoading, setIsLoading] = useState(true);
  const [openNotesDialogSupplierData, setOpenNotesDialogSupplierData] = useState<supplierType | null>(null);
  const [pageIndex, setPageIndex] = useState(page);
  const [selectedBudget, setSelectedBudget] = useState<supplierBudgetType>();
  const [sorting, setSorting] = useState({ orderBy, sortBy });

  // data needed to manage isPositioningLate
  const monthIndexByLevels = useMemo(
    () => getMonthIndexByLevels(user.positionedTargetsLevels),
    [user.positionedTargetsLevels]
  );

  const fetchData = useCallback(
    async ({ orderBy, page, sortBy }: { orderBy?: 'asc' | 'desc'; page?: number; sortBy?: string }) => {
      setIsLoading(true);
      await getMonitoredSupplierBudgetsAndOffersCount({
        retailerId: user.id,
        sortBy,
        orderBy,
        page,
        size: tableSize,
        filters
      });
      setIsLoading(false);
    },
    [filters, user.id]
  );

  const refreshData = useCallback(() => {
    fetchData({ orderBy: sorting.orderBy, page, sortBy: sorting.sortBy });
  }, [fetchData, page, sorting.orderBy, sorting.sortBy]);

  useEffect(() => {
    if (!list?.length && count && count !== total) {
      fetchData({});
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, filters, list]);

  useEffect(() => {
    dispatch({
      type: 'MONITORING_RETAILER_SUPPLIER_BUDGETS_LIST',
      payload: { orderBy: sorting.orderBy, page: pageIndex, sortBy: sorting.sortBy }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex, sorting.orderBy, sorting.sortBy]);

  const columns = useMemo(
    () => [
      { id: 1, field: 'budgetInfos', headerName: t('commun_budget'), type: 'component', wide: true },
      {
        id: 2,
        field: 'percentageUnpositioned',
        headerName: t('commun_budget_positioning'),
        type: 'component',
        sortable: true
      },
      {
        id: 3,
        field: 'spent',
        headerName: t('commun_budget_spending'),
        type: 'component'
      },
      {
        id: 4,
        field: 'total',
        headerName: t('commun_total'),
        type: 'number',
        sortable: true
      },
      {
        id: 5,
        field: 'offersCount',
        headerName: t('commun_offers'),
        type: 'number'
      },
      {
        id: 6,
        field: 'userManager',
        headerName: t('supplier_userManager'),
        type: 'text'
      },
      { id: 7, field: 'action', type: 'component' }
    ],
    [t]
  );

  const rows = useMemo(
    () =>
      list.map((budget) => {
        const isPositioningLate = checkIsPositioningLate(monthIndexByLevels, budget.percentageUnpositioned);
        const unSpent = Math.max(budget.total - budget.spent);
        return {
          id: [{ value: budget.id }],
          budgetInfos: (
            <div className={styles['budgetInfos']}>
              <div className={styles['name']}>{budget.supplier.name}</div>
              <div className={clsx(styles['id'])}>
                <span>{budget.id}</span> - <span>{t(`commun_budget_type_${budget.type}`)}</span>
              </div>
              <div className="visibility-hidden">
                <ButtonsCustom
                  classType="action_secondary"
                  id={`relaunch_notification_button_${budget.id}`}
                  method={(event) => {
                    event.preventDefault();
                    setSelectedBudget(budget);
                  }}
                  startIconCustom={<NotificationIcon />}
                  size="thin"
                  text={t('supplier_details_email_relaunch_short')}
                />
              </div>
            </div>
          ),
          percentageUnpositioned: (
            <div className={styles['budgetProgress']}>
              <div className={clsx(styles['value'], isPositioningLate && styles['warning'])}>
                {t('commun_price', {
                  value: budget.total - budget.free,
                  currency,
                  maximumFractionDigits: withFractionDigits(budget.total - budget.free)
                })}
                {isPositioningLate && <WarningIcon />}
              </div>
              <ProgressBar color="secondary" small total={100} value={100 - budget.percentageUnpositioned} />
              <div className={styles['desc']}>
                {t('pilotage_monitored_budgets_unPositioned', {
                  value: budget.free,
                  currency,
                  maximumFractionDigits: withFractionDigits(budget.free)
                })}
              </div>
            </div>
          ),
          spent: (
            <div className={styles['budgetProgress']}>
              <div className={styles['value']}>
                {t('commun_price', {
                  value: budget.spent,
                  currency,
                  maximumFractionDigits: withFractionDigits(budget.spent)
                })}
              </div>
              <ProgressBar color="primary" small total={100} value={100 - budget.percentageRemaining} />
              <div className={styles['desc']}>
                {t('pilotage_monitored_budgets_unSpent', {
                  value: unSpent,
                  currency,
                  maximumFractionDigits: withFractionDigits(unSpent)
                })}
              </div>
            </div>
          ),
          total: [
            {
              value: t('commun_price', {
                value: budget.total,
                currency,
                maximumFractionDigits: withFractionDigits(budget.total)
              })
            }
          ],
          offersCount: [{ value: budget.offersCount }],
          userManager: [
            {
              value:
                budget.supplier.userManager?.firstName || budget.supplier.userManager?.lastName
                  ? `${budget.supplier.userManager.firstName} ${budget.supplier.userManager.lastName}`
                  : budget.supplier.userManager?.email
            }
          ],
          action: (
            <Tooltip
              placement="top"
              title={
                budget.supplier.note ? (
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(budget.supplier.note) }} />
                ) : (
                  t('supplier_add_note_tooltip_placeholder')
                )
              }
            >
              <div>
                <ButtonsCustom
                  classType="action"
                  method={() => {
                    setOpenNotesDialogSupplierData({ ...budget.supplier, retailer: { ...budget.retailer } });
                  }}
                  startIconCustom={<NotesIcon />}
                />
              </div>
            </Tooltip>
          )
        };
      }),
    [list, monthIndexByLevels, t, currency]
  );

  const handleNextPage = () => {
    const newPage = page + 1;
    setPageIndex(newPage);
    fetchData({ orderBy: sorting.orderBy, page: newPage, sortBy: sorting.sortBy });
  };

  const handlePrevPage = () => {
    const newPage = page - 1;
    setPageIndex((page) => page - 1);
    fetchData({ orderBy: sorting.orderBy, page: newPage, sortBy: sorting.sortBy });
  };

  const handleSorting = (newSortBy: string) => {
    setPageIndex(0);
    const newSorting = {
      orderBy: (newSortBy === sortBy && orderBy === 'desc' ? 'asc' : 'desc') as 'asc' | 'desc',
      sortBy: newSortBy
    };
    setSorting(newSorting);
    fetchData({ orderBy: newSorting.orderBy, page: 0, sortBy: newSorting.sortBy });
  };

  return (
    <>
      <div className={styles['monitoredBudget-container']}>
        <h4>{t('pilotage_monitored_budgets_title')}</h4>
        <TableCustom
          borderOnHover
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          total={total}
          handleSort={handleSorting}
          page={pageIndex}
          size={tableSize}
          prev={handlePrevPage}
          next={handleNextPage}
          order={sorting.orderBy}
          sort={sorting.sortBy}
          type="huge"
          isUsingFilters={!!Object.keys(filters)?.length}
          clickedRawUniqueKeyValue={selectedBudget?.id.toString()}
        />
      </div>
      <EmailsNotificationDialog
        onDialogClose={() => {
          setSelectedBudget(undefined);
        }}
        selectedBudget={selectedBudget}
      />
      <SupplierNoteDialog
        isOpen={!!openNotesDialogSupplierData}
        onClose={() => {
          setOpenNotesDialogSupplierData(null);
        }}
        onUpdated={refreshData}
        supplier={openNotesDialogSupplierData}
      />
    </>
  );
};

export default MonitoredBudget;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { store } from 'store';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCampaign, getSegments } from 'api';
import { exitModalTypes, segmentScopes } from 'utils/constants';
import moment from 'moment-timezone';
import useDynId from 'customHooks/useDynId';

import ButtonsCustom from 'components/ButtonsCustom';
import CashCouponStep1 from 'components/AddCampaign/CashCouponSteps/CashCouponStep1';
import CashCouponStep2 from 'components/AddCampaign/CashCouponSteps/CashCouponStep2';
import CashCouponStep3 from 'components/AddCampaign/CashCouponSteps/CashCouponStep3';
import ContentDialog from 'components/ContentDialog';
import FooterButtonsContainer from 'components/LayoutWrappers/Form/FooterButtonsContainer';
import FormLayoutWrapper from 'components/LayoutWrappers/Form';

const initState = {
  title: '',
  date: {
    startDate: null,
    endDate: null
  },
  offers: [
    {
      retailerSegment: { id: '' },
      cashCoupon: { id: '' }
    }
  ]
};

const CashCouponSteps = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const {
    user,
    campaigns: { details: campaign }
  } = state;

  const step1Ref = useRef(null);
  const step2Ref = useRef(null);

  const [campaignCashCoupon, setCampaignCashCoupon] = useState(JSON.parse(JSON.stringify(initState)));
  const [displayValidationStep, setDisplayValidationStep] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (id) {
        const data = await getCampaign(id);
        if (!data) {
          history.push({ pathname: '/campaigns', search: history.location.search });
        }
      }
      await getSegments({ retailerCode: user.code, scope: segmentScopes.CAMPAIGN });
      setIsLoading(false);
    };

    fetchData();

    return () => {
      dispatch({ type: 'CAMPAIGN_CREATION_RESET' });
      dispatch({ type: 'CAMPAIGNS_RESET' });
      dispatch({ type: 'SEGMENTS_RESET' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (campaign?.id) {
      const data = {
        id: campaign.id,
        title: campaign.title,
        date: {
          startDate: moment(campaign.validityStartDate),
          endDate: moment(campaign.validityEndDate)
        },
        originalCampaignDates: {
          startDate: moment(campaign.validityStartDate),
          endDate: moment(campaign.validityEndDate)
        },
        offers: campaign.cashCoupons?.map((offer) => ({
          id: offer.id,
          retailerSegment: { id: offer.externalSegment.id },
          cashCoupon: { id: offer.cashCoupon.id }
        })),
        originalCampaignOffers: campaign.cashCoupons?.map((offer) => ({
          id: offer.id,
          retailerSegment: { id: offer.externalSegment.id },
          cashCoupon: { id: offer.cashCoupon.id }
        }))
      };
      setCampaignCashCoupon(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign?.id]);

  useEffect(() => {
    //add conditional for form not empty //
    if (campaignCashCoupon.title || campaignCashCoupon.date.startDate || campaignCashCoupon.date.endDate) {
      dispatch({
        type: 'EXIT_MODAL_TYPE_UPDATE',
        payload: id ? exitModalTypes.CAMPAIGN_EDITION : exitModalTypes.CAMPAIGN_CASH_COUPON
      });
    }

    return () => {
      dispatch({
        type: 'EXIT_MODAL_TYPE_UPDATE',
        payload: null
      });
    };
  }, [dispatch, campaignCashCoupon.title, campaignCashCoupon.date.startDate, campaignCashCoupon.date.endDate, id]);

  const handleCashCouponUpdate = (data = {}) => {
    setCampaignCashCoupon({ ...campaignCashCoupon, ...data });
  };

  const handleCancelCampaign = () => {
    if (JSON.stringify(campaignCashCoupon) !== JSON.stringify(initState)) {
      setOpenDialog(true);
    } else {
      handleDialogConfirm();
    }
  };

  const handleValidation = () => {
    const step1HasError = step1Ref.current.step1CheckHasError();

    //no need to test step2 if step1 dates input is not filled
    const step2HasError =
      !campaignCashCoupon.date.startDate || !campaignCashCoupon.date.endDate
        ? false
        : step2Ref.current.step2CheckHasError();

    if (!step1HasError && !step2HasError) {
      setDisplayValidationStep(true);
    }
  };

  const handleDialogConfirm = () => {
    history.push(id ? `/campaigns/${campaignCashCoupon.id}` : '/campaigns/add-campaign');
  };

  const handleDialogCancel = () => {
    setOpenDialog(false);
  };

  const toggleValidationStep = () => {
    setDisplayValidationStep((state) => !state);
  };

  return (
    <>
      <FormLayoutWrapper
        headerTitle={
          displayValidationStep
            ? t('campaign_visualize_your_campaign_label')
            : t(id ? 'campaign_cash_coupon_edition_timeline_title' : 'campaign_cash_coupon_creation_timeline_title')
        }
        headerSubTitle={
          displayValidationStep
            ? t('campaign_product_step4_desc')
            : t('campaign_cash_coupon_creation_timeline_subtitle')
        }
        onClose={handleCancelCampaign}
      >
        {displayValidationStep ? (
          <CashCouponStep3
            campaignCashCoupon={campaignCashCoupon}
            cashCouponsListAll={state.cashCoupons.listAll}
            handlePreviousStep={toggleValidationStep}
            segmentsListAll={state.segments.listAll}
            user={state.user}
          />
        ) : (
          <>
            <CashCouponStep1
              campaignCashCoupon={campaignCashCoupon}
              isEditMode={!!id}
              updateCashCoupon={handleCashCouponUpdate}
              ref={step1Ref}
            />
            <CashCouponStep2
              campaignCashCoupon={campaignCashCoupon}
              cashCouponsListAll={state.cashCoupons.listAll}
              disabled={!campaignCashCoupon.date.startDate || !campaignCashCoupon.date.endDate}
              segmentsListAll={state.segments.listAll}
              updateCashCoupon={handleCashCouponUpdate}
              user={user}
              ref={step2Ref}
            />
            <FooterButtonsContainer
              confirmLabel={t('campaign_creation_footer_button_visualization')}
              disabled={isLoading}
              isLoading={isLoading}
              onConfirm={handleValidation}
            />
          </>
        )}
      </FormLayoutWrapper>

      <ContentDialog
        centerText
        isOpen={openDialog}
        handleClose={handleDialogCancel}
        title={id ? t('campaign_edition_leave_modal_message') : t('campaign_creation_leave_modal_message')}
        maxWidth="xs"
      >
        <div className="confirm-dialog">
          <ButtonsCustom
            id={useDynId('step1CancelModal')}
            classType="canceled"
            text={t('commun_button_cancel')}
            method={handleDialogCancel}
          />
          <ButtonsCustom
            id={useDynId('step1ConfirmModal')}
            classType="action_primary_big"
            text={t('commun_button_continue')}
            method={handleDialogConfirm}
          />
        </div>
      </ContentDialog>
    </>
  );
};

export default CashCouponSteps;

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { scrollToElementById } from 'utils/global';

import FormManager from 'components/FormManager';
import InputAdornment from 'components/InputAdornment';

const OffersSettings = forwardRef(({ onChangeState, retailerState }, ref) => {
  const { t } = useTranslation();

  const [errorMsgAllowedExtensions, setErrorMsgAllowedExtensions] = useState(null);
  const [errorMsgSettingOfferImageHorizontalSize, setErrorMsgSettingOfferImageHorizontalSize] = useState(null);
  const [errorMsgSettingOfferImageVerticalSize, setErrorMsgSettingOfferImageVerticalSize] = useState(null);
  const [errorMsgSettingOfferTitleSize, setErrorMsgSettingOfferTitleSize] = useState(null);
  const [errorMsgSettingOfferAdditionalInfoSize, setErrorMsgSettingOfferAdditionalInfoSize] = useState(null);

  useImperativeHandle(ref, () => ({
    checkHasError() {
      return handleCheckError();
    }
  }));

  const handleCheckError = () => {
    let errorFieldIdList = [];

    if (!retailerState.allowedExtensions?.length) {
      setErrorMsgAllowedExtensions(t('retailers_creation_image_format_error'));
      errorFieldIdList.push('field-retailer-creation-form-offers-parameters');
    } else {
      setErrorMsgAllowedExtensions();
    }

    if (!retailerState.settingOfferImageHorizontalSize) {
      setErrorMsgSettingOfferImageHorizontalSize(t('retailers_creation_image_width_error_required'));
      errorFieldIdList.push('field-allowedExtensions');
    } else if (retailerState.settingOfferImageHorizontalSize > 10000) {
      setErrorMsgSettingOfferImageHorizontalSize(t('retailers_creation_image_width_error_max'));
      errorFieldIdList.push('field-allowedExtensions');
    } else {
      setErrorMsgSettingOfferImageHorizontalSize();
    }

    if (!retailerState.settingOfferImageVerticalSize) {
      setErrorMsgSettingOfferImageVerticalSize(t('retailers_creation_image_height_error_required'));
      errorFieldIdList.push('field-allowedExtensions');
    } else if (retailerState.settingOfferImageVerticalSize > 10000) {
      setErrorMsgSettingOfferImageVerticalSize(t('retailers_creation_image_height_error_max'));
      errorFieldIdList.push('field-allowedExtensions');
    } else {
      setErrorMsgSettingOfferImageVerticalSize();
    }

    if (!retailerState.settingOfferTitleSize) {
      setErrorMsgSettingOfferTitleSize(t('retailers_creation_product_title_length_error_required'));
      errorFieldIdList.push('field-settingOfferImageHorizontalSize');
    } else if (retailerState.settingOfferTitleSize > 10000) {
      setErrorMsgSettingOfferTitleSize(t('retailers_creation_product_title_length_error_max'));
      errorFieldIdList.push('field-settingOfferImageHorizontalSize');
    } else {
      setErrorMsgSettingOfferTitleSize();
    }

    if (!retailerState.settingOfferAdditionalInformationSize) {
      setErrorMsgSettingOfferAdditionalInfoSize(t('retailers_creation_product_title_length_error_required'));
      errorFieldIdList.push('field-settingOfferImageHorizontalSize');
    } else if (retailerState.settingOfferAdditionalInformationSize > 10000) {
      setErrorMsgSettingOfferAdditionalInfoSize(t('retailers_creation_product_title_length_error_max'));
      errorFieldIdList.push('field-settingOfferImageHorizontalSize');
    } else {
      setErrorMsgSettingOfferAdditionalInfoSize();
    }

    if (errorFieldIdList.length) {
      setTimeout(() => {
        scrollToElementById(errorFieldIdList[0]);
      }, 150);
    }

    return !!errorFieldIdList.length;
  };

  return (
    <FormManager
      data={{
        title: t('retailers_creation_offers_params'),
        fieldsets: [
          {
            id: 'creation-form-offers-part1',
            fields: [
              {
                outerContainerClass: ['without-margin-top'],
                label: t('retailers_creation_image_format'),
                defaultValue:
                  retailerState?.allowedExtensions != null ? retailerState.allowedExtensions : ['PNG', 'JPG'],
                type: 'CheckboxList',
                id: 'allowedExtensions',
                fieldProps: {
                  list: [
                    { value: 'JPG', label: 'jpg' },
                    { value: 'PNG', label: 'png' }
                  ]
                },
                error: errorMsgAllowedExtensions,
                onFieldChange: (value) => {
                  setErrorMsgAllowedExtensions();
                  onChangeState({ allowedExtensions: value });
                }
              },
              {
                label: t('retailers_creation_image_size'),
                id: 'label-for-part2',
                type: 'CustomContent',
                component: <></>
              }
            ]
          },

          {
            id: 'creation-form-offers-part2',
            classnames: ['grid'],
            fields: [
              {
                outerContainerClass: ['half-size-left'],
                placeholder: t('retailers_creation_image_width'),
                defaultValue: retailerState?.settingOfferImageHorizontalSize || '',
                type: 'NumberField',
                fieldProps: {
                  InputProps: {
                    min: 1,
                    max: 10000,
                    endAdornment: <InputAdornment position="end">{t('retailers_creation_image_pixels')}</InputAdornment>
                  }
                },
                id: 'settingOfferImageHorizontalSize',
                error: errorMsgSettingOfferImageHorizontalSize,
                onFieldChange: (value) => {
                  setErrorMsgSettingOfferImageHorizontalSize();
                  onChangeState({ settingOfferImageHorizontalSize: value });
                }
              },
              {
                outerContainerClass: ['half-size-right'],
                classnames: ['without-margin-top'],
                placeholder: t('retailers_creation_image_height'),
                defaultValue: retailerState?.settingOfferImageVerticalSize || '',
                type: 'NumberField',
                fieldProps: {
                  InputProps: {
                    min: 1,
                    max: 10000,
                    endAdornment: <InputAdornment position="end">{t('retailers_creation_image_pixels')}</InputAdornment>
                  }
                },
                id: 'settingOfferImageVerticalSize',
                error: errorMsgSettingOfferImageVerticalSize,
                onFieldChange: (value) => {
                  setErrorMsgSettingOfferImageVerticalSize();
                  onChangeState({ settingOfferImageVerticalSize: value });
                }
              }
            ]
          },
          {
            id: 'creation-form-offers-part3',
            fields: [
              {
                label: t('retailers_creation_product_title'),
                placeholder: t('retailers_creation_product_max_chars_placeholder'),
                defaultValue: retailerState?.settingOfferTitleSize || '',
                type: 'NumberField',
                fieldProps: {
                  InputProps: {
                    min: 1,
                    max: 1000
                  }
                },
                id: 'settingOfferTitleSize',
                error: errorMsgSettingOfferTitleSize,
                onFieldChange: (value) => {
                  setErrorMsgSettingOfferTitleSize();
                  onChangeState({ settingOfferTitleSize: value });
                }
              },
              {
                label: t('retailers_creation_product_additional_information'),
                placeholder: t('retailers_creation_product_max_chars_placeholder'),
                defaultValue: retailerState?.settingOfferAdditionalInformationSize || '',
                type: 'NumberField',
                fieldProps: {
                  InputProps: {
                    min: 1,
                    max: 1000
                  }
                },
                id: 'settingOfferAdditionalInformationSize',
                error: errorMsgSettingOfferAdditionalInfoSize,
                onFieldChange: (value) => {
                  setErrorMsgSettingOfferAdditionalInfoSize();
                  onChangeState({ settingOfferAdditionalInformationSize: value });
                }
              }
            ]
          }
        ]
      }}
    />
  );
});

OffersSettings.propTypes = {
  onChangeState: PropTypes.func,
  retailerState: PropTypes.object
};

export default OffersSettings;

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { getProducts } from 'api';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import DialogSearchProduct from './DialogSearchProduct';
import EanAutocompleteInputWithResultAndErrorList from 'components/EanAutocompleteInputWithResultAndErrorList';

import { ReactComponent as SearchIcon } from 'assets/16px_redimensionner.svg';

import styles from './EanAutocomplete.module.scss';

const EanAutocomplete = ({
  data,
  data: {
    id,
    defaultValue = [],
    disabled,
    fieldProps: {
      offerCreation,
      onOpenDialogSearchProduct,
      retailerId,
      settingProductLevelLabelFour,
      supplierId,
      updateOfferCreation
    }
  },
  onChange
}) => {
  const { t } = useTranslation();
  const { dispatch, state } = useContext(store);

  const { loading, sort, order, page } = state.products;
  const { filterBrand, filterCategory } = offerCreation.step3;

  const [dialogSearchOpened, setDialogSearchOpened] = useState(false);
  const [eanProducts, setEanProducts] = useState(defaultValue || []);

  useEffect(() => {
    setEanProducts(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    // TODO: rework image gallery to avoid using/simulating getAllProducts
    dispatch({
      type: 'PRODUCTS_UPDATE',
      payload: {
        listAll: eanProducts
      }
    });

    if (
      onChange &&
      eanProducts
        ?.map((p) => p.id)
        .sort()
        .join(',') !==
        defaultValue
          ?.map((p) => p.id)
          .sort()
          .join(',')
    ) {
      onChange(id, eanProducts);
    }
    updateOfferCreation({ errors: { resultListEanError: false } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eanProducts]);

  // used in search modal
  useEffect(() => {
    const fetchProducts = async () => {
      getProducts({
        retailerId,
        supplierId,
        sort,
        order,
        page,
        brandId: filterBrand.value,
        categoryId: filterCategory.value
      });
    };
    if (retailerId && supplierId) {
      fetchProducts();
    }
  }, [
    filterBrand.value,
    filterCategory.value,
    offerCreation.step1.multiSupplierId,
    order,
    page,
    retailerId,
    sort,
    supplierId
  ]);

  const handleSearchDialogChange = (data) => {
    setEanProducts(data);
  };

  const handleOpenSearch = async () => {
    if (!disabled) {
      setDialogSearchOpened(true);
    }
  };

  const handleCloseSearch = () => {
    // reset pagination and sorting
    handleListUpdate({ page: 0, sort: 'code', order: 'desc' });
    // reset filters
    updateOfferCreation({
      step3: { filterBrand: { value: '' }, filterCategory: { value: '' } }
    });

    setDialogSearchOpened(false);
  };

  const handleListUpdate = (data) => {
    dispatch({
      type: 'PRODUCTS_UPDATE',
      payload: data
    });
  };

  return (
    <>
      <EanAutocompleteInputWithResultAndErrorList
        alreadyAddedList={offerCreation.step3.resultListEan}
        disabled={disabled}
        defaultValue={eanProducts}
        isLoading={loading}
        onChange={(productIds) => {
          onChange(id, productIds);
        }}
        onDeleteOne={(eanToRemove) => {
          const newList = eanProducts.filter((item) => item.ean !== eanToRemove);
          onChange(id, newList);
        }}
        retailerId={retailerId}
        supplierId={supplierId}
      />

      <div className={`${styles['product-search-button']} ${eanProducts.length ? styles['negative-margin'] : ''}`}>
        <ButtonsCustom
          classType="link_primary"
          disabled={disabled}
          method={handleOpenSearch}
          text={
            <span className={`${styles['custom_svg']} ${disabled ? styles['disabled'] : ''}`}>
              <SearchIcon />
              {t('offers_creation_product_search')}
            </span>
          }
        />
      </div>

      <ContentDialog centerText isOpen={dialogSearchOpened} handleClose={handleCloseSearch} spaceless>
        <DialogSearchProduct
          data={data}
          handleListUpdate={handleListUpdate}
          list={eanProducts}
          onChange={handleSearchDialogChange}
          onClose={handleCloseSearch}
          onOpen={onOpenDialogSearchProduct}
          updateOfferCreation={updateOfferCreation}
          settingProductLevelLabelFour={settingProductLevelLabelFour}
        />
      </ContentDialog>
    </>
  );
};

EanAutocomplete.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  error: PropTypes.bool,
  onChange: PropTypes.func
};

export default EanAutocomplete;

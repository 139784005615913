import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDynId from 'customHooks/useDynId';
import AutocompleteSelect from 'components/AutocompleteCustom/AutocompleteSelect';
import ButtonsCustom from 'components/ButtonsCustom';

import { ReactComponent as TrashIcon } from 'assets/trash.svg';
import styles from './BrSelection.module.scss';

const BrSelection = ({
  cashCouponsListAll,
  disabled,
  errorMessage,
  fieldError,
  isLoading,
  offers,
  onDelete,
  segmentsListAll,
  updateList
}) => {
  const { t } = useTranslation();
  const deleteCashCouponId = useDynId('step2DeleteCashCoupon');
  const segmentsList = useMemo(
    () =>
      segmentsListAll.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }),
    [segmentsListAll]
  );

  return (
    <div className={styles['root']}>
      {offers.map((el, index) => {
        return (
          <div className={styles['container']} key={index}>
            {offers.length > 1 && (
              <div className={styles['container-label']}>
                <div>{t('campaign_cash_coupon_br_selection_title_count', { value: index + 1 })}</div>
                <ButtonsCustom
                  id={deleteCashCouponId}
                  classType="action"
                  method={(event) => {
                    event.preventDefault();
                    onDelete(index);
                  }}
                  startIconCustom={<TrashIcon />}
                />
              </div>
            )}

            <AutocompleteSelect
              disabled={disabled || isLoading}
              disablePortal
              error={!disabled && fieldError.indexOf(index) !== -1}
              id={`select-segment-${index}`}
              isLoading={isLoading}
              labelAttribute="name"
              listData={segmentsList}
              onSelection={(option) => {
                updateList({ id: option?.id, index, key: 'retailerSegment' });
              }}
              placeholderText={t('campaign_cash_coupon_step2_form_segment_place_holder')}
              useCustomSegmentOption
              value={segmentsList.find((seg) => seg.id === el.retailerSegment?.id)}
            />

            <AutocompleteSelect
              disabled={disabled || isLoading}
              disablePortal
              error={!disabled && fieldError.indexOf(index) !== -1}
              id={`select-cashcoupon-${index}`}
              isLoading={isLoading}
              listData={[...cashCouponsListAll]}
              onSelection={(option) => {
                updateList({ id: option?.id, index, key: 'cashCoupon' });
              }}
              placeholderText={t('campaign_cash_coupon_step2_form_cash_coupon_place_holder')}
              value={cashCouponsListAll.find((cc) => cc.id === el.cashCoupon?.id)}
            />
            {!disabled &&
              fieldError.indexOf(index) !== -1 &&
              errorMessage.map(
                (error) =>
                  error.id === index && (
                    <div className={styles['error']} key={error.id}>
                      <p>{error.message}</p>
                    </div>
                  )
              )}
          </div>
        );
      })}
    </div>
  );
};

BrSelection.propTypes = {
  cashCouponsListAll: PropTypes.array,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.array,
  fieldError: PropTypes.array,
  isLoading: PropTypes.bool,
  offers: PropTypes.array,
  onDelete: PropTypes.func.isRequired,
  segmentsListAll: PropTypes.array,
  updateList: PropTypes.func.isRequired
};

export default BrSelection;

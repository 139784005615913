import moment from 'moment-timezone';
import { offerHeadTypes, offerStatuses, segmentedMarketingStrategyCodes } from 'utils/constants';
import { checkFileExtension } from 'utils/global';

const today = moment();

export const checkAddOfferFields = (
  isBudgetStep,
  {
    offerCreation,
    freeBudget,
    offerDetails,
    discountUnits,
    discountTypes,
    offerConstraints,
    validityDatesListRaw,
    targetingStrategies
  },
  t
) => {
  const { step1, step2, step3, step4 } = offerCreation;
  const { externalSegment, targetingStrategy } = step2;
  const { discountType, numberMax, quantity, generosity, title, image, resultListEan, brands, offerType } = step3;
  const { budget, budgetType, validityDates } = step4;
  const isEditMode = !!offerDetails.id;
  const isExpiredOfferStatus = offerDetails.status === offerStatuses.EXPIRED;
  const isValidatedOfferStatus = offerDetails.status === offerStatuses.VALIDATED;

  const oldBudget = offerDetails.status === offerStatuses.EXPIRED ? 0 : offerDetails?.offerHead?.budgetTarget;

  const existingBudgetConstraint = offerConstraints.find((el) => el.target === 'budget');
  const existingDurationConstraint = offerConstraints.find((el) => el.target === 'duration');
  const existingDiscountTypeConstraint = offerConstraints
    .find((el) => el.target === 'discountType')
    ?.selectedValues?.map((el) => discountTypes.find((i) => i.denomination === el)?.id) || [1, 2];
  const existingDiscountUnitConstraint = offerConstraints
    .find((el) => el.target === 'discountUnit')
    ?.selectedValues?.map((el) => discountUnits.find((i) => i.denomination === el)?.id) || [1, 2];

  const sortedStatusHistory = offerDetails.statusUpdatesHistory?.sort((a, b) =>
    moment(b.statusUpdatedAt).diff(moment(a.statusUpdatedAt))
  );
  const previousOfferStatusBeforeExpiredWasValidated =
    isExpiredOfferStatus && sortedStatusHistory && sortedStatusHistory?.[1]?.status === offerStatuses.VALIDATED;

  const marketingStrategyCode =
    targetingStrategy.id &&
    targetingStrategies.find((st) => st.id.toString() === targetingStrategy.id.toString())?.targetMarketing?.code;
  const isSegmentedMarketingStrategyCode = segmentedMarketingStrategyCodes.includes(marketingStrategyCode);

  const mappingFieldNames = [
    {
      name: t('offers_creation_retailer_choice'),
      value: step1.completed,
      errorName: 'stepOneError'
    },
    {
      name: t('_dyn_campaign_product_step2_form_strategyInput'),
      value: targetingStrategy.id,
      errorName: 'targetError'
    },
    {
      name: t('_dyn_campaign_product_step2_form_strategyInput'),
      value: isSegmentedMarketingStrategyCode ? externalSegment.id : true,
      errorName: 'segmentError'
    },
    {
      name: t('offers_creation_discount_mechanism_type'),
      value: discountType.id && existingDiscountTypeConstraint?.includes(discountType.id),
      errorName: 'discountTypeError'
    },
    {
      name: t('offers_creation_max_number_use'),
      value: numberMax.value,
      errorName: 'numberMaxError'
    },
    {
      name: t('offers_creation_quantity_to_add'),
      value: quantity.value,
      errorName: 'quantityError'
    },
    {
      name: t('commun_generosity'),
      value: generosity.value && existingDiscountUnitConstraint?.includes(generosity.unit),
      errorName: 'generosityError'
    },
    {
      name: t('offers_creation_offer_title'),
      value: title.value,
      errorName: 'titleError'
    },
    {
      name: t('offers_creation_image_choice_title'),
      value: image?.size || image?.url,
      errorName: 'imageError'
    },
    {
      name: t('offers_creation_image_choice_title'),
      value: checkFileExtension(image?.name, ['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG']),
      errorName: 'imageError'
    },
    {
      name: t('commun_product_list'),
      value: offerType === offerHeadTypes.BRAND_OFFER || resultListEan.length,
      errorName: 'resultListEanError'
    },
    {
      name: t('commun_brand_list'),
      value: offerType === offerHeadTypes.PRODUCT_OFFER || brands?.length,
      errorName: 'resultListBrandError'
    },
    {
      name: t('commun_validation_dates'),
      value: validityDates.every(({ startDate, endDate }) => startDate && endDate),
      errorName: 'dateError'
    },
    {
      name: t('commun_validation_dates'),
      value: validityDates.every(
        ({ startDate, endDate }) =>
          moment.isMoment(startDate) && moment.isMoment(endDate) && startDate.isSame(endDate, 'year')
      ),
      errorName: 'dateError'
    },
    {
      name: t('commun_validation_dates'),
      // check for endDates before today
      value:
        !!validityDates?.length &&
        validityDates.some(
          ({ startDate, endDate }) =>
            moment.isMoment(startDate) &&
            moment.isMoment(endDate) &&
            startDate.isSameOrBefore(endDate, 'day') &&
            today.isSameOrBefore(endDate, 'day')
        ),
      errorName: 'dateError'
    },
    {
      name: t('commun_validation_dates'),
      // rules MODIF_OFFRE_PRODUITF_AVAL_4 && MODIF_OFFRE_PRODUIT_2
      value:
        isEditMode && (isValidatedOfferStatus || previousOfferStatusBeforeExpiredWasValidated)
          ? validityDates.every(
              ({ startDate, endDate, countActiveCampaign }) =>
                !!countActiveCampaign ||
                (!countActiveCampaign &&
                  moment.isMoment(startDate) &&
                  today.isSameOrBefore(startDate, 'day') &&
                  moment.isMoment(endDate) &&
                  startDate.isSameOrBefore(endDate, 'day'))
            )
          : !!validityDates?.length &&
            validityDates.every(
              ({ startDate, endDate }) =>
                moment.isMoment(startDate) &&
                today.isSameOrBefore(startDate, 'day') &&
                moment.isMoment(endDate) &&
                startDate.isSameOrBefore(endDate, 'day')
            ),
      errorName: 'dateError'
    },
    {
      name: t('commun_validation_dates'),
      value:
        isEditMode &&
        !isValidatedOfferStatus &&
        !previousOfferStatusBeforeExpiredWasValidated &&
        validityDates?.length > 1
          ? validityDates.every(({ startDate, endDate }, index) => {
              let isPristine = true;
              if (isEditMode && validityDatesListRaw?.length) {
                if (
                  !validityDatesListRaw[index]?.countActiveCampaign &&
                  (!validityDatesListRaw[index]?.startDate.isSame(startDate, 'day') ||
                    !validityDatesListRaw[index]?.endDate.isSame(endDate, 'day'))
                ) {
                  isPristine = false;
                }
              }
              return (
                isPristine ||
                (moment.isMoment(startDate) &&
                  today.isSameOrBefore(startDate, 'day') &&
                  moment.isMoment(endDate) &&
                  startDate.isSameOrBefore(endDate, 'day'))
              );
            })
          : true,
      errorName: 'dateError'
    },
    {
      name: t('commun_validation_dates'),
      value: existingDurationConstraint
        ? validityDates.every(
            ({ startDate, endDate }) =>
              Math.floor(moment(endDate).diff(startDate, 'days') + 1) >=
              existingDurationConstraint.threshold * (existingDurationConstraint?.unit === 'DAYS' ? 1 : 7)
          )
        : true,
      errorName: 'dateError'
    },
    {
      name: t('offers_creation_budget_title'),
      value: isBudgetStep ? budget : true,
      errorName: 'budgetError'
    },
    {
      name: t('offers_creation_budget_title'),
      value: isBudgetStep ? budgetType : true,
      errorName: 'budgetError'
    },
    {
      name: t('offers_creation_budget_insufficient'),
      // rules MODIF_OFFRE_PRODUITF_AVAL_2 and MODIF_OFFRE_PRODUITF_AVAL_3
      value: isBudgetStep
        ? isEditMode
          ? budget !== oldBudget
            ? budget - oldBudget <= freeBudget
            : true
          : budget <= freeBudget
        : true,
      errorName: 'budgetError'
    },
    {
      name: t('offers_creation_budget_title'),
      value: isBudgetStep ? (existingBudgetConstraint ? budget >= existingBudgetConstraint.threshold : true) : true,
      errorName: 'budgetError'
    },
    {
      name: t('offers_creation_budget_target_insufficient'),
      // rule MODIF_OFFRE_PRODUIT_4
      value:
        isBudgetStep && budget && isEditMode && budget !== oldBudget
          ? offerDetails?.offerHead?.budgetSpent <= budget
          : true,
      errorName: 'budgetError'
    }
  ];

  const errors = mappingFieldNames.filter((i) => !i.value);

  return [...new Map(errors.map((item) => [item['errorName'], item])).values()];
};

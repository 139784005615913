import React, { useContext, useEffect, useState } from 'react';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { getAllReferentialCampaignDistributionChannels } from 'api';

import FormManager from 'components/FormManager';

import { referentialCampaignDistributionChannelsType } from 'types/referentialCampaignDistributionChannels';

import styles from './DistributionChannels.module.scss';

type Props = {
  disabled: boolean;
};

const DistributionChannels = ({ disabled }: Props): JSX.Element => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state, dispatch } = globalState as any;
  const campaignState = state.campaignCreation.product as any;

  const [referentials, setReferentials] = useState<referentialCampaignDistributionChannelsType>([]);

  useEffect(() => {
    const fetchReferentials = async () => {
      const values: referentialCampaignDistributionChannelsType = await getAllReferentialCampaignDistributionChannels();
      if (values) {
        setReferentials(values);
      }
    };
    fetchReferentials();
  }, []);

  return (
    <div className={styles['root']}>
      <FormManager
        data={{
          fieldsets: [
            {
              id: 'add-campaign-product-step-distribution-channels',
              fields: [
                {
                  label: (
                    <div>
                      <span>{t('campaign_product_creation_distribution_channels_title')}</span>{' '}
                      <span className={styles['optional']}>{t('commun_optional_with_parentheses')}</span>
                    </div>
                  ),
                  type: 'CheckboxList',
                  id: 'distributionChannels',
                  defaultValue: campaignState.distributionChannels || [],
                  disabled,
                  fieldProps: {
                    list: referentials.map((ref) => ({
                      value: ref,
                      label: t(`commun_referential_distribution_channels_${ref}`)
                    }))
                  },
                  onFieldChange: (data: string[]) => {
                    dispatch({
                      type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE',
                      payload: { distributionChannels: data }
                    });
                  }
                }
              ]
            }
          ]
        }}
      />
    </div>
  );
};

export default DistributionChannels;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isOutOfRange } from '../utils';

import AddElements from '../AddElements';
import AddMultiPurchasesInnerConditions from '../AddMultiPurchasesInnerConditions';
import FormManager from 'components/FormManager';
import HeaderBlock from './_HeaderBlock';

// innerConditions
import CurrencyBlock from '../MultiPurchasesInnerConditionsForm/Currency.js';
import LastVisitBlock from '../MultiPurchasesInnerConditionsForm/LastVisit.js';
import QuantityBlock from '../MultiPurchasesInnerConditionsForm/Quantity.js';
import VisitBlock from '../MultiPurchasesInnerConditionsForm/Visit';
import VisitEachBlock from '../MultiPurchasesInnerConditionsForm/VisitEach.js';

import { ReactComponent as DeleteIcon } from 'assets/16px_trash.svg';

import styles from './ConditionsForm.module.scss';

const MultiPurchasesBlock = ({ config, groupIndex, onDeleteBlock, segment, updateData }) => {
  const { t } = useTranslation();
  const addedInnerConditionsList = segment.conditions.map((c) => c.unit);

  const blockFactory = ({ listIndex, innerConditionName }) => {
    const props = {
      data: segment.conditions[listIndex],
      groupIndex,
      listIndex,
      period: segment.period,
      updateData: (innerCondition) => {
        const newSegment = JSON.parse(JSON.stringify(segment));
        newSegment.conditions[listIndex] = innerCondition;
        updateData(newSegment);
      },
      updatePeriod: (newPeriod) => {
        const newSegment = JSON.parse(JSON.stringify(segment));
        newSegment.period = newPeriod;
        updateData(newSegment);
      }
    };

    switch (innerConditionName.toLowerCase()) {
      case 'currency':
        return <CurrencyBlock {...props} />;
      case 'last_visit':
        return <LastVisitBlock {...props} />;
      case 'quantity':
        return <QuantityBlock {...props} />;
      case 'visit':
        return <VisitBlock {...props} />;
      case 'visit_each_day':
      case 'visit_each_week':
      case 'visit_each_month':
      case 'visit_each_year':
        return <VisitEachBlock {...props} />;

      default:
        return false;
    }
  };

  const handleDeleteInnerCondition = (index) => {
    let newSegment = JSON.parse(JSON.stringify(segment));

    // reset period when empty
    // prevents last_visit specific period value shape
    // prevents leaking period values
    if (newSegment.conditions.length === 1) {
      newSegment = {
        ...newSegment,
        conditions: [],
        period: {
          type: 'over',
          value: {
            count: null,
            interval: null
          }
        }
      };
    } else {
      newSegment.conditions = [...newSegment.conditions.slice(0, index), ...newSegment.conditions.slice(index + 1)];
    }
    updateData(newSegment);
  };

  const updateInnerConditionsList = (newInnerConditionName) => {
    const newSegment = JSON.parse(JSON.stringify(segment));
    newSegment.conditions.push(config.innerConditionTypes[newInnerConditionName]);
    updateData(newSegment);
  };

  return (
    <div className={`${styles['mainContainer']} `}>
      <HeaderBlock onDelete={onDeleteBlock} segment={segment} />
      <FormManager
        data={{
          classnames: [styles['customForm']],
          fieldsets: [
            {
              id: 'segment-manager-multipurchases-line-1',
              classnames: [styles['one-line']],
              fields: [
                {
                  type: 'TextField',
                  classnames: [styles['input'], styles['input-medium']],
                  defaultValue:
                    segment.level === 'store'
                      ? t('segmentManager_static_having_bought')
                      : t('segmentManager_static_buyers_of'),
                  fieldProps: {
                    readOnly: true,
                    shrinkPlaceholder: false
                  },
                  id: `segment-${segment.type}-wording-static-${groupIndex}`
                },
                {
                  type: 'CustomContent',
                  outerContainerClass: ['full-width'],
                  id: `segment-${segment.type}-on-container-${groupIndex}`,
                  component: (
                    <AddElements
                      onSubmit={(list) => {
                        const newSegment = JSON.parse(JSON.stringify(segment));
                        newSegment.on = list;
                        updateData(newSegment);
                      }}
                      segment={segment}
                    />
                  )
                }
              ]
            },
            {
              id: 'segment-manager-multipurchases-line-2',
              classnames: [styles['one-line']],
              display: true,
              fields: [
                {
                  type: 'CustomContent',
                  outerContainerClass: ['full-width'],
                  id: `segment-${segment.type}-inner-conditions-added-list-${groupIndex}`,
                  component: (
                    <div>
                      {addedInnerConditionsList.map((el, index) => (
                        <div className={styles['innerConditionsContainer']} key={el}>
                          {index !== 0 && (
                            <div className={styles['fake-and-operator']}>{t('_dyn_commun_select_operator_$and')}</div>
                          )}
                          {blockFactory({ listIndex: index, innerConditionName: el })}
                          <DeleteIcon
                            onClick={() => {
                              handleDeleteInnerCondition(index);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  )
                }
              ]
            },
            {
              id: 'segment-manager-multipurchases-line-3-generic-period',
              classnames: [styles['one-line']],
              display: segment.conditions.length && segment.conditions[0].unit !== 'last_visit', // display only if inner condition is not empty and not 'last_visit' / recency
              fields: [
                {
                  type: 'TextField',
                  classnames: [styles['input'], styles['input-medium']],
                  defaultValue: t('segmentManager_static_having_bought_during'),
                  fieldProps: {
                    readOnly: true,
                    shrinkPlaceholder: false
                  },
                  id: `segment-${segment.type}-period-static-${groupIndex}`
                },
                {
                  type: 'NumberField',
                  allowZero: false,
                  classnames: [styles['input'], styles['input-small']],
                  defaultValue: segment.period.value.count || '',
                  fieldProps: {
                    InputProps: {
                      min: 0
                    },
                    shrinkPlaceholder: false
                  },
                  id: `segment-${segment.type}-period-count-${groupIndex}`,
                  onFieldChange: (value) => {
                    const newSegment = JSON.parse(JSON.stringify(segment));
                    newSegment.period.value.count = value;

                    // reset interval Select if count is out of range
                    if (isOutOfRange({ count: value, interval: segment.period.value.interval })) {
                      newSegment.period.value.interval = null;
                    }

                    updateData(newSegment);
                  },
                  placeholder: t('commun_input_number_placeholder')
                },
                {
                  type: 'Select',
                  classnames: [styles['input'], styles['input-last']],
                  outerContainerClass: [styles['input-outerContainer']],
                  defaultValue: segment.period.value.interval,
                  fieldProps: {
                    listData: config.lists.commun.interval.map((el) => ({
                      id: el,
                      label: t(`_dyn_commun_select_interval_last_${el}`, { count: segment.period.value.count || 0 }),
                      disabled: isOutOfRange({ count: segment.period.value.count, interval: el })
                    })),
                    placeHolderText: t('_dyn_commun_select_interval_placeholder'),
                    shrinkPlaceholder: false
                  },
                  id: `segment-${segment.type}-period-interval-${groupIndex}`,
                  onFieldChange: (value) => {
                    const newSegment = JSON.parse(JSON.stringify(segment));
                    newSegment.period.value.interval = value;
                    updateData(newSegment);
                  }
                }
              ]
            },
            {
              id: 'segment-manager-multipurchases-line-4',
              classnames: [styles['one-line']],
              display: true,
              fields: [
                {
                  type: 'CustomContent',
                  outerContainerClass: ['full-width'],
                  id: `segment-${segment.type}-inner-conditions-selection-${groupIndex}`,
                  component: (
                    <AddMultiPurchasesInnerConditions
                      list={addedInnerConditionsList}
                      addNewInnerCondition={updateInnerConditionsList}
                    />
                  )
                }
              ]
            }
          ]
        }}
      />
    </div>
  );
};

MultiPurchasesBlock.propTypes = {
  config: PropTypes.object.isRequired,
  groupIndex: PropTypes.string.isRequired,
  onDeleteBlock: PropTypes.func.isRequired,
  segment: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired
};

export default memo(MultiPurchasesBlock);

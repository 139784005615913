import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FormControlLabel, FormGroup, Popover } from '@mui/material';
import ButtonsCustom from 'components/ButtonsCustom';
import CheckboxCustom from 'components/CheckboxCustom';

import { ReactComponent as CloseIcon } from 'assets/22px_croix.svg';
import { ReactComponent as CalendarIcon } from 'assets/24px_calendrier.svg';
import styles from './DateYearMultiPicker.module.scss';

const DateYearMultiPicker = ({ loading, onValidation, values = [], yearList = [] }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [state, setState] = useState(values);

  useEffect(() => {
    setState(values);
  }, [values]);

  const handleClick = (event) => {
    if (!loading) {
      setState(values);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (newValue) => {
    setState(newValue);
  };

  const handleValidation = () => {
    onValidation?.(state);
    setAnchorEl(null);
  };

  return (
    <div className={styles['root']}>
      <div
        className={`${styles['display-container']} ${loading ? styles['disabled'] : ''}`}
        onClick={handleClick}
        role="button"
      >
        {loading || !values.length ? '-' : values.join(', ')}
        <CalendarIcon className={styles['calendar-icon']} />
      </div>

      <Popover
        id={'date-year-picker'}
        classes={{ root: styles['popper'], paper: styles['paper'] }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        disablePortal
        anchorOrigin={{
          vertical: 55,
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className={styles['paper-container']}>
          {/* {popperContent} */}
          <div className={styles['popper-content']}>
            <FormGroup>
              {yearList.map(({ id, label, value }) => (
                <FormControlLabel
                  key={id}
                  control={
                    <CheckboxCustom
                      idText={id}
                      checked={state.includes(value)}
                      onChange={(e) => {
                        //    add or remove year value in the array
                        const newYearValue = state.includes(value)
                          ? state.filter((s) => s !== value)
                          : [...state, value].sort();

                        handleSelection(newYearValue);
                      }}
                    />
                  }
                  label={label}
                />
              ))}
            </FormGroup>
          </div>

          <div className={styles['popper-validation']}>
            <ButtonsCustom
              classType="action_secondary"
              id="date-year-picker-popover-button-apply"
              text={t('commun_apply')}
              method={handleValidation}
            />
          </div>
          <span className={styles['popper-arrow']}></span>
          <span className={styles['popper-close-icon']}>
            <CloseIcon onClick={handleClose} />
          </span>
        </div>
      </Popover>
    </div>
  );
};

DateYearMultiPicker.propTypes = {
  loading: PropTypes.bool,
  onValidation: PropTypes.func,
  values: PropTypes.arrayOf(PropTypes.number),
  yearList: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, label: PropTypes.string, value: PropTypes.number })
  )
};

export default DateYearMultiPicker;

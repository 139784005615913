import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './BudgetsProgressBar.module.scss';

const Legend = ({ list = ['budget_progressBar_spent', 'budget_progressBar_reserved', 'budget_progressBar_free'] }) => {
  const { t } = useTranslation();
  return (
    <div className={styles['progressBarLegend']}>
      {list.map((el, index) => {
        if (el) {
          return (
            <Fragment key={el}>
              <div className={`${styles['legend-bar']} ${styles[`bar--${index}`]}`}></div>
              <p className={styles['legend-label']}>{t(el)}</p>
            </Fragment>
          );
        }
        return null;
      })}
    </div>
  );
};

Legend.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
};

export default Legend;

import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { Trans, useTranslation } from 'react-i18next';
import { scrollToElementById } from 'utils/global';

import FormManager from 'components/FormManager';

const SuppliersNotifications = forwardRef(({ onChangeState, retailerState }, ref) => {
  const { t } = useTranslation();
  const {
    state: { locales, notificationTemplates },
  } = useContext(store);

  const [errorMsgTemplateIdInputSupplierFieldError, setErrorMsgTemplateIdInputSupplierFieldError] = useState(null);
  const [errorMsgLocaleNotifySupplier, setErrorMsgLocaleNotifySupplier] = useState(null);

  useImperativeHandle(ref, () => ({
    checkHasError() {
      return handleCheckError();
    },
  }));

  const handleCheckError = () => {
    let errorFieldIdList = [];

    if (retailerState.notifySuppliersEnabled && !retailerState.templateIdNotifySupplier) {
      setErrorMsgTemplateIdInputSupplierFieldError(
        t('retailers_creation_retailer_notification_template_error_required')
      );
      errorFieldIdList.push('field-notifySuppliersEnabled');
    } else {
      setErrorMsgTemplateIdInputSupplierFieldError();
    }

    if (retailerState.notifySuppliersEnabled && !retailerState.localeNotifySupplier) {
      setErrorMsgLocaleNotifySupplier(t('retailers_creation_retailer_notification_region_error_required'));
      errorFieldIdList.push('field-templateIdNotifySupplier');
    } else {
      setErrorMsgLocaleNotifySupplier();
    }

    if (errorFieldIdList.length) {
      setTimeout(() => {
        scrollToElementById(errorFieldIdList[0]);
      }, 150);
    }

    return !!errorFieldIdList.length;
  };

  return (
    <FormManager
      data={{
        title: t('retailers_creation_suppliers_notifications'),
        subTitle: t('retailers_creation_suppliers_notifications_description'),
        fieldsets: [
          {
            id: 'creation-form-notify-suppliers-fieldset',
            classnames: ['no-padding-bottom'],
            fields: [
              {
                outerContainerClass: ['without-margin-top'],
                label: t('retailers_creation_suppliers_notifications'),
                defaultValue: retailerState?.notifySuppliersEnabled || false,
                type: 'Switch',
                id: 'notifySuppliersEnabled',
                switchLabels: {
                  off: t('retailers_creation_notifications_off'),
                  on: t('retailers_creation_notifications_on'),
                },
                fieldProps: { size: 'small' },
                onFieldChange: (value) => {
                  onChangeState({ notifySuppliersEnabled: value });
                },
              },
            ],
          },
          {
            id: 'creation-form-suppliers-notification-fieldset',
            requirements: [{ field: 'notifySuppliersEnabled', eq: [true] }],
            fields: [
              {
                outerContainerClass: ['without-margin-top'],
                label: t('retailers_creation_notifications_template'),
                placeholder: t('retailers_creation_notifications_template_placeholder'),
                defaultValue: retailerState?.templateIdNotifySupplier || '',
                type: 'Select',
                fieldProps: { listData: notificationTemplates, labelAttribute: 'name' },
                id: 'templateIdNotifySupplier',
                error: errorMsgTemplateIdInputSupplierFieldError,
                onFieldChange: (value) => {
                  setErrorMsgTemplateIdInputSupplierFieldError();
                  onChangeState({ templateIdNotifySupplier: value });
                },
              },
              {
                label: t('retailers_creation_notifications_region'),
                placeholder: t('retailers_creation_notifications_region_placeholder'),
                defaultValue: retailerState?.localeNotifySupplier || '',
                type: 'Select',
                fieldProps: { listData: locales, labelAttribute: 'locale' },
                id: 'localeNotifySupplier',
                error: errorMsgLocaleNotifySupplier,
                onFieldChange: (value) => {
                  setErrorMsgLocaleNotifySupplier();
                  onChangeState({ localeNotifySupplier: value });
                },
              },
              {
                label: <Trans i18nKey="retailers_creation_notifications_bcc_optional" />,
                subLabel: t('retailers_creation_notifications_bcc_description'),
                type: 'UsersList',
                defaultValue: retailerState?.supplierHiddenCopyReceivers,
                id: 'supplierHiddenCopyReceivers',
                onFieldChange: (list) => {
                  onChangeState({ supplierHiddenCopyReceivers: list });
                },
              },
            ],
          },
        ],
      }}
    />
  );
});

SuppliersNotifications.propTypes = {
  onChangeState: PropTypes.func,
  retailerState: PropTypes.object,
};

export default SuppliersNotifications;

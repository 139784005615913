import React, { useEffect, memo, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  campaignStates,
  campaignTargetingStatuses,
  campaignTypes,
  discountTypes,
  filterSigns,
  offerHeadTypes,
  offerStatuses,
  offerTypes,
  retailerTypes,
  segmentTypes,
  segmentUpdatingStatuses
} from 'utils/constants';
import { QsParse, QsStringify } from 'utils/global';
import { useHistory } from 'react-router-dom';
import Filters from './Filters';

export const getInitialFilters = ({
  history,
  queryFilters,
  suppliers = [],
  retailers = [],
  targetingStrategies = [],
  userManagers = [],
  universes = []
}) => {
  const queryString =
    !history.location.search && queryFilters[history.location.pathname]
      ? queryFilters[history.location.pathname]
      : history.location.search;

  /*** query string init validation **/
  const initFilters = QsParse(queryString);

  // --- descriptionTag / tagContains (containsIn)
  if (initFilters.tagContains) {
    if (!Array.isArray(initFilters.tagContains)) {
      initFilters.tagContains = [initFilters.tagContains];
    }
  }
  // --- descriptionTag / tagNotContains (notContainsIn)
  if (initFilters.tagNotContains) {
    if (!Array.isArray(initFilters.tagNotContains)) {
      initFilters.tagNotContains = [initFilters.tagNotContains];
    }
  }
  // --- tag contains or doesNotContain
  if (initFilters.tag) {
    if (
      !Array.isArray(initFilters.tag) ||
      initFilters.tag.length !== 2 ||
      !['contains', 'doesNotContain', 'containsAndNotContains'].includes(initFilters.tag[0]) ||
      !initFilters.tag[1]
    ) {
      initFilters.tag = undefined;
    }
  }
  // --- title
  if (initFilters.title && Array.isArray(initFilters.title)) {
    // keep only the first entry
    initFilters.title = initFilters.title[0];
  }
  // --- id
  if (initFilters.id && !Array.isArray(initFilters.id)) {
    initFilters.id = [initFilters.id];
  }
  // --- status / offer statuses
  if (initFilters.status) {
    if (!Array.isArray(initFilters.status)) {
      initFilters.status = [initFilters.status];
    }
    // filter unknown values
    initFilters.status = initFilters.status.filter((s) => Object.keys(offerStatuses).includes(s));
  }
  // --- campaign type
  if (initFilters.type) {
    if (!Array.isArray(initFilters.type)) {
      initFilters.type = [initFilters.type];
    }
    // filter unknown values
    initFilters.type = initFilters.type.filter((s) => Object.keys(campaignTypes).includes(s));
  }
  // --- offerHead type
  if (initFilters.offerHeadType) {
    if (!Array.isArray(initFilters.offerHeadType)) {
      initFilters.offerHeadType = [initFilters.offerHeadType];
    }
    // filter unknown values
    initFilters.offerHeadType = initFilters.offerHeadType.filter((s) => Object.keys(offerHeadTypes).includes(s));
  }
  // --- offer type
  if (initFilters.offerType) {
    if (!Array.isArray(initFilters.offerType)) {
      initFilters.offerType = [initFilters.offerType];
    }
    // filter unknown values
    initFilters.offerType = initFilters.offerType.filter((s) =>
      [offerTypes.RETAILER_PRODUCT_OFFER, offerTypes.RETAILER_PRODUCT_OFFER].includes(s)
    );
  }
  // --- targetingStatus / campaign allocation
  if (initFilters.targetingStatus) {
    if (!Array.isArray(initFilters.targetingStatus)) {
      initFilters.targetingStatus = [initFilters.targetingStatus];
    }
    // filter unknown values
    initFilters.targetingStatus = initFilters.targetingStatus.filter((t) =>
      Object.keys(campaignTargetingStatuses).includes(t)
    );
  }
  // --- discountType
  if (initFilters.discountType) {
    if (!Array.isArray(initFilters.discountType)) {
      initFilters.discountType = [initFilters.discountType];
    }
    // filter unknown values
    const discountTypesList = discountTypes.map((d) => d.denomination);
    initFilters.discountType = initFilters.discountType.filter((d) => discountTypesList.includes(d));
  }
  // --- targetingStrategyId
  if (initFilters.targetingStrategyId) {
    if (!Array.isArray(initFilters.targetingStrategyId)) {
      initFilters.targetingStrategyId = [initFilters.targetingStrategyId];
    }
    //filter unknow values if targetingStrategies list already fetched
    if (targetingStrategies?.length) {
      initFilters.targetingStrategyId = initFilters.targetingStrategyId.filter((id) =>
        targetingStrategies.find((t) => t.id === id)
      );
    }
  }
  // --- targetingMarketingCode
  if (initFilters.targetingMarketingCode) {
    if (!Array.isArray(initFilters.targetingMarketingCode)) {
      initFilters.targetingMarketingCode = [initFilters.targetingMarketingCode];
    }
    //filter unknow values if targetingStrategies list already fetched
    if (targetingStrategies?.length) {
      initFilters.targetingMarketingCode = initFilters.targetingMarketingCode.filter((code) =>
        targetingStrategies.find((t) => t.targetMarketing.code === code)
      );
    }
  }
  // --- privateLabel
  if (initFilters.privateLabel) {
    if (!Array.isArray(initFilters.privateLabel)) {
      initFilters.privateLabel = [initFilters.privateLabel];
    }
    initFilters.privateLabel = initFilters.privateLabel.filter((p) => ['mdd', 'national'].includes(p));
  }
  // --- supplierId
  if (initFilters.supplierId) {
    if (!Array.isArray(initFilters.supplierId)) {
      initFilters.supplierId = [initFilters.supplierId];
    }
    //filter unknow values if suppliers list already fetched
    if (suppliers?.length) {
      initFilters.supplierId = initFilters.supplierId.filter((id) => suppliers.find((supplier) => supplier.id === id));
    }
  }
  // --- retailerId
  if (initFilters.retailerId) {
    if (!Array.isArray(initFilters.retailerId)) {
      initFilters.retailerId = [initFilters.retailerId];
    }
    //filter unknow values if retailers list already fetched
    if (retailers?.length) {
      initFilters.retailerId = initFilters.retailerId.filter((id) => retailers.find((retailer) => retailer.id === id));
    }
  }
  // --- retailerType
  if (initFilters.retailerType) {
    if (!Array.isArray(initFilters.retailerType)) {
      initFilters.retailerType = [initFilters.retailerType];
    }
    // filter unknown values
    initFilters.retailerType = initFilters.retailerType.filter((s) => Object.keys(retailerTypes).includes(s));
  }
  // --- budgetSpent
  if (initFilters.budgetSpent) {
    if (
      !Array.isArray(initFilters.budgetSpent) ||
      initFilters.budgetSpent.length !== 2 ||
      !filterSigns
        .filter((sign) => ['lessThan', 'greaterThan'].includes(sign.id))
        .find((sign) => sign.id === initFilters.budgetSpent[0]) ||
      isNaN(initFilters.budgetSpent[1])
    ) {
      initFilters.budgetSpent = undefined;
    }
  }
  // --- budget over spent / hasOverrunBudget
  if (initFilters.onlyOverrunBudget) {
    if (initFilters.onlyOverrunBudget !== true) {
      initFilters.onlyOverrunBudget = undefined;
    }
  }
  // --- budgetTarget
  if (initFilters.budgetTarget) {
    if (
      !Array.isArray(initFilters.budgetTarget) ||
      initFilters.budgetTarget.length !== 2 ||
      !filterSigns
        .filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))
        .find((sign) => sign.id === initFilters.budgetTarget[0]) ||
      isNaN(initFilters.budgetTarget[1])
    ) {
      initFilters.budgetTarget = undefined;
    }
  }
  // --- validityStartDate & validityEndDate
  let validityDates = [initFilters.validityStartDate, initFilters.validityEndDate];
  validityDates = validityDates.map((vd) =>
    vd &&
      Array.isArray(vd) &&
      vd.length > 1 &&
      filterSigns
        .filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))
        .find((sign) => sign.id === vd[0]) &&
      moment(vd[1], 'YYYY-MM-DD', true).isValid()
      ? [vd[0], moment(vd[1]).format('YYYY-MM-DD')]
      : undefined
  );
  if (initFilters.validityStartDate) initFilters.validityStartDate = validityDates[0];
  if (initFilters.validityEndDate) initFilters.validityEndDate = validityDates[1];
  /******/

  // --- creationDate
  if (initFilters.creationDate) {
    initFilters.creationDate =
      Array.isArray(initFilters.creationDate) &&
        initFilters.creationDate.length > 1 &&
        filterSigns
          .filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))
          .find((sign) => sign.id === initFilters.creationDate[0]) &&
        moment(initFilters.creationDate[1], 'YYYY-MM-DD', true).isValid()
        ? [initFilters.creationDate[0], moment(initFilters.creationDate[1]).format('YYYY-MM-DD')]
        : undefined;
  }
  // --- campaign state
  if (initFilters.state) {
    if (!Array.isArray(initFilters.state)) {
      initFilters.state = [initFilters.state];
    }
    // filter unknown values
    initFilters.state = initFilters.state.filter((s) => Object.keys(campaignStates).includes(s));
  }
  // --- segment status / archived
  if (initFilters.archived || typeof initFilters.archived === 'boolean') {
    if (!Array.isArray(initFilters.archived)) {
      initFilters.archived = [initFilters.archived];
    }
    // filter unknown values
    initFilters.archived = initFilters.archived.filter((s) => [true, false].includes(s));
  }
  // --- segment updatingStatus / segmentUpdatingStatus
  if (initFilters.segmentUpdatingStatus || typeof initFilters.segmentUpdatingStatus === 'boolean') {
    if (!Array.isArray(initFilters.segmentUpdatingStatus)) {
      initFilters.segmentUpdatingStatus = [initFilters.segmentUpdatingStatus];
    }
    // filter unknown values
    initFilters.segmentUpdatingStatus = initFilters.segmentUpdatingStatus.filter((s) =>
      Object.keys(segmentUpdatingStatuses).includes(s)
    );
  }
  // --- segment type / segmentType
  if (initFilters.segmentType) {
    if (!Array.isArray(initFilters.segmentType)) {
      initFilters.segmentType = [initFilters.segmentType];
    }
    // filter unknown values
    initFilters.segmentType = initFilters.segmentType.filter((s) => Object.keys(segmentTypes).includes(s));
  }
  // --- discountMinQuantity / discountMinQuantityOfProducts (new filter on offer-campaigns page)
  if (initFilters.discountMinQuantity) {
    if (
      !Array.isArray(initFilters.discountMinQuantity) ||
      initFilters.discountMinQuantity.length !== 2 ||
      !filterSigns
        .filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))
        .find((sign) => sign.id === initFilters.discountMinQuantity[0]) ||
      isNaN(initFilters.discountMinQuantity[1])
    ) {
      initFilters.discountMinQuantity = undefined;
    }
  }
  // --- averagePrice (new filter on offer-campaigns page)
  if (initFilters.averagePrice) {
    if (
      !Array.isArray(initFilters.averagePrice) ||
      initFilters.averagePrice.length !== 2 ||
      !filterSigns
        .filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))
        .find((sign) => sign.id === initFilters.averagePrice[0]) ||
      isNaN(initFilters.averagePrice[1])
    ) {
      initFilters.averagePrice = undefined;
    }
  }
  // --- generosity / discountUnit and discountValue (new filter on offer-campaigns page)
  if (initFilters.generosity) {
    if (
      !Array.isArray(initFilters.generosity) ||
      initFilters.generosity.length !== 3 ||
      !filterSigns
        .filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))
        .find((sign) => sign.id === initFilters.generosity[0]) ||
      isNaN(initFilters.generosity[1]) ||
      isNaN(initFilters.generosity[2])
    ) {
      initFilters.generosity = undefined;
    }
  }
  // --- generosityPercentage (new filter on dashboard retailer / offer tab)
  if (initFilters.generosityPercent) {
    if (
      !Array.isArray(initFilters.generosityPercent) ||
      initFilters.generosityPercent.length !== 2 ||
      !filterSigns
        .filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))
        .find((sign) => sign.id === initFilters.generosityPercent[0]) ||
      isNaN(initFilters.generosityPercent[1])
    ) {
      initFilters.generosityPercent = undefined;
    }
  }
  // --- superEditable (new filter on offer-campaigns page)
  if (initFilters.superEditable || typeof initFilters.superEditable === 'boolean') {
    // keep only if true
    // because false should display all offers
    initFilters.superEditable = initFilters.superEditable || undefined;
  }
  // --- userManagerId
  if (initFilters.userManagerId) {
    if (!Array.isArray(initFilters.userManagerId)) {
      initFilters.userManagerId = [initFilters.userManagerId];
    }
    //filter unknow values if userManagerId list already fetched
    if (userManagers?.length) {
      initFilters.userManagerId = initFilters.userManagerId.filter((id) =>
        userManagers.find((userManager) => userManager.id === id)
      );
    }
  }
  // --- universe
  if (initFilters.universe) {
    if (!Array.isArray(initFilters.universe)) {
      initFilters.universe = [initFilters.universe];
    }
    //filter unknow values if userManagerId list already fetched
    if (universes?.length) {
      initFilters.universe = initFilters.universe.filter((universe) => universes.find((el) => el === universe));
    }
  }

  return initFilters;
};

const FiltersContainer = forwardRef(
  (
    {
      containerWithNoMargin = false,
      dispatch,
      filters,
      filtersListToDisplay,
      retailersList = [],
      suppliersList = [],
      targetingStrategiesList = [],
      updateFilters,
      user,
      useResetButton = true,
      userManagersList = [],
      universesList = []
    },
    ref
  ) => {
    const history = useHistory();
    const filtersRef = useRef(null);

    useImperativeHandle(
      ref,
      () => ({
        resetAllFilters: filtersRef.current.resetAllFilters
      }),
      [filtersRef]
    );

    // query string validation and correction after async data fetched
    useEffect(() => {
      let hasCorrection = false;
      const currentFilters = { ...filters };

      // targetingStrategyId correction
      if (currentFilters.targetingStrategyId && targetingStrategiesList?.length) {
        currentFilters.targetingStrategyId = currentFilters.targetingStrategyId.filter((id) => {
          const found = targetingStrategiesList.find((t) => t.id === id);
          if (!found) {
            hasCorrection = true;
          }
          return found;
        });
      }
      // targetingMarketingCode correction
      if (currentFilters.targetingMarketingCode && targetingStrategiesList?.length) {
        currentFilters.targetingMarketingCode = currentFilters.targetingMarketingCode.filter((code) => {
          const found = targetingStrategiesList.find((t) => t.targetMarketing.code === code);
          if (!found) {
            hasCorrection = true;
          }
          return found;
        });
      }
      // supplierId correction
      if (currentFilters.supplierId && suppliersList.length) {
        currentFilters.supplierId = currentFilters.supplierId.filter((id) => {
          const found = suppliersList.find((sup) => sup.id === id);
          if (!found) {
            hasCorrection = true;
          }
          return found;
        });
      }
      // retailerId correction
      if (currentFilters.retailerId && retailersList.length) {
        currentFilters.retailerId = currentFilters.retailerId.filter((id) => {
          const found = retailersList.find((ret) => ret.id === id);
          if (!found) {
            hasCorrection = true;
          }
          return found;
        });
      }
      // userManagerId correction
      if (currentFilters.userManagerId && userManagersList?.length) {
        currentFilters.userManagerId = currentFilters.userManagerId.filter((id) => {
          const found = userManagersList.find((userManager) => userManager.id === id);
          if (!found) {
            hasCorrection = true;
          }
          return found;
        });
      }
      // universe correction
      if (currentFilters.universe && universesList?.length) {
        currentFilters.universe = currentFilters.universe.filter((universe) => {
          const found = universesList.find((el) => el === universe);
          if (!found) {
            hasCorrection = true;
          }
          return found;
        });
      }

      if (hasCorrection) updateFilters(currentFilters);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [retailersList, suppliersList, targetingStrategiesList, userManagersList, universesList]);

    // update url query string
    useEffect(() => {
      // format on dates must be re-applyed since timezone user-story
      const correctedFilters = {
        ...filters,
        validityStartDate: filters.validityStartDate
          ? [filters.validityStartDate[0], moment(filters.validityStartDate[1]).format('YYYY-MM-DD')]
          : undefined,
        validityEndDate: filters.validityEndDate
          ? [filters.validityEndDate[0], moment(filters.validityEndDate[1]).format('YYYY-MM-DD')]
          : undefined,
        creationDate: filters.creationDate
          ? [filters.creationDate[0], moment(filters.creationDate[1]).format('YYYY-MM-DD')]
          : undefined
      };

      dispatch({
        type: 'QUERY_FILTERS_UPDATE',
        payload: { [history.location.pathname]: QsStringify(correctedFilters) }
      });

      history.push({
        pathname: history.location.pathname,
        search: QsStringify(correctedFilters)
      });
    }, [dispatch, filters, history]);

    return (
      <Filters
        containerWithNoMargin={containerWithNoMargin}
        currency={user?.currency}
        filters={filters}
        filtersListToDisplay={filtersListToDisplay}
        ref={filtersRef}
        retailersList={retailersList}
        suppliersList={suppliersList}
        targetingStrategiesList={targetingStrategiesList}
        universesList={universesList}
        updateFilters={(newFilters) => {
          if (JSON.stringify(newFilters) !== JSON.stringify(filters)) {
            updateFilters(newFilters);
          }
        }}
        userManagersList={userManagersList}
        useResetButton={useResetButton}
      />
    );
  }
);

FiltersContainer.propTypes = {
  containerWithNoMargin: PropTypes.bool,
  dispatch: PropTypes.func,
  filters: PropTypes.object,
  filtersListToDisplay: PropTypes.array,
  retailersList: PropTypes.array,
  suppliersList: PropTypes.array,
  targetingStrategiesList: PropTypes.array,
  updateFilters: PropTypes.func,
  user: PropTypes.object,
  useResetButton: PropTypes.bool,
  userManagersList: PropTypes.array,
  universesList: PropTypes.array
};

export default memo(FiltersContainer);

import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { store } from 'store';
import { formatFloat, withFractionDigits } from 'utils/global';
import { MONTH_i18n_MAPPING, NON_POSITIONED_BUDGET_THRESHOLD_PERIODS } from 'utils/constants';
// import { getRetailerDetails, getTargetingStrategies, getNotificationTemplates } from 'api';
import { getRetailerDetails, getNotificationTemplates } from 'api';

import ButtonsCustom from 'components/ButtonsCustom';
import DrawerHeader from 'components/DrawerHeader';
import EmailsList from 'components/EmailsList';
import TabsInModal from 'components/TabsInModal';
import HelperText from 'components/HelpText';
import GeneralInfoBox, { GeneralInfoBoxContainer } from 'components/GeneralInfoBox';
import Loader from 'components/Loaders/Dots';
import useDynId from 'customHooks/useDynId';

import { ReactComponent as Pencil } from 'assets/16px_edit.svg';
import noImageIcon from 'assets/no_image.svg';

import styles from './RetailerDetails.module.scss';

const currentYear = new Date().getFullYear();

const RetailerDetails = () => {
  const { t } = useTranslation();
  const { id: retailerId } = useParams();
  const {
    state: { retailer, countries, notificationTemplates }
  } = useContext(store);

  const editId = useDynId('edit-button');
  const [loading, setLoading] = useState(true);
  const [guidelinesLanguage, setGuidelinesLanguage] = useState('FR');
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const loadOffer = async () => {
      await getRetailerDetails(retailerId);
      // await getTargetingStrategies(retailerId);
      await getNotificationTemplates();
      setLoading(false);
    };

    loadOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className={styles['loading-root']}>
        <DrawerHeader
          title={t('retailer_summary_title')}
          buttons={[<div className={styles['header-button-placeholder']} key="button-placeholder" />]}
        />
        <div className={styles['loading']}>
          <p className={styles['loading-message']}>{t('offer_details_loading_message')}</p>
          <Loader />
        </div>
      </div>
    );
  }

  const technicalsFees_N_Plus1_raw = retailer.technicalsFees?.find((fee) => fee.year === currentYear + 1)?.value;
  const technicalsFees_N_Plus1 = technicalsFees_N_Plus1_raw && formatFloat(technicalsFees_N_Plus1_raw);

  const technicalsFees_N_raw = retailer.technicalsFees?.find((fee) => fee.year === currentYear)?.value;
  const technicalsFees_N = technicalsFees_N_raw && formatFloat(technicalsFees_N_raw);

  const technicalsFees_N_Minus1_raw = retailer.technicalsFees?.find((fee) => fee.year === currentYear - 1)?.value;
  const technicalsFees_N_Minus1 = technicalsFees_N_Minus1_raw && formatFloat(technicalsFees_N_Minus1_raw);

  const technicalsFees_N_Minus2_raw = retailer.technicalsFees?.find((fee) => fee.year === currentYear - 2)?.value;
  const technicalsFees_N_Minus2 = technicalsFees_N_Minus2_raw && formatFloat(technicalsFees_N_Minus2_raw);

  const nonPositionedBudget_objectives_thresholds = retailer.positionedTargetsLevels?.filter((level) => !!level.month);
  const nonPositionedBudget_startOfYear = nonPositionedBudget_objectives_thresholds?.find(
    (level) => level.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.START_OF_YEAR
  );
  const nonPositionedBudget_halfOfYear = nonPositionedBudget_objectives_thresholds?.find(
    (level) => level.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.INTERMEDIATE
  );
  const nonPositionedBudget_endOfYear = nonPositionedBudget_objectives_thresholds?.find(
    (level) => level.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.END_OF_YEAR
  );

  return (
    <div>
      <DrawerHeader
        title={t('retailer_summary_title')}
        buttons={[
          <Link to={`/retailers/add-retailer/${retailerId}`} role="link" key="edit-button">
            <ButtonsCustom classType="action" id={editId} startIconCustom={<Pencil />} tooltip={t('commun_edit')} />
          </Link>
        ]}
      />

      <div className={styles['root']}>
        <div className={styles['header-container']}>
          <div className={styles['logo']}>
            <img
              className={styles['logo-image']}
              src={retailer.logo?.url || noImageIcon}
              alt={retailer.logo?.name || t('commun_none')}
            />
          </div>
          <div className={styles['name']}>{retailer.name}</div>
          <div className={styles['code']}>
            {t('retailer_summary_code')} : {retailer.code}
          </div>
          <div className={styles['type']}>{t(`retailer_type_${retailer.type}`)}</div>
        </div>

        <p className={styles['params-title']}>{t('retailers_creation_general_params')}</p>
        <GeneralInfoBoxContainer gridTemplate={2}>
          <GeneralInfoBox key={`generalInfoItem-general-params-${0}`} label={t('retailers_creation_country')}>
            <span>{countries.find((el) => el.code === retailer.countryCode)?.name || retailer.countryCode}</span>
          </GeneralInfoBox>
          <GeneralInfoBox key={`generalInfoItem-general-params-${1}`} label={t('retailers_creation_timezone')}>
            <span>{retailer.timezone?.name}</span>
          </GeneralInfoBox>
          <GeneralInfoBox key={`generalInfoItem-general-params-${2}`} label={t('retailers_creation_currency')}>
            <span>{retailer.currency?.code}</span>
          </GeneralInfoBox>
          <GeneralInfoBox key={`generalInfoItem-general-params-${3}`} label={t('retailers_creation_vll')}>
            <span>
              {retailer.settingCashCouponVllCodeFormat
                ? t('retailers_creation_switch_on')
                : t('retailers_creation_switch_off')}
            </span>
          </GeneralInfoBox>
          <GeneralInfoBox key={`generalInfoItem-general-params-${4}`} label={t('retailers_creation_dam')}>
            <span>{retailer.damName || t('retailers_creation_dam_none')}</span>
          </GeneralInfoBox>
          <GeneralInfoBox key={`generalInfoItem-general-params-${5}`} label={t('retailers_creation_use_allocation')}>
            <span>
              {retailer.targetingEnabled ? t('retailers_creation_switch_on') : t('retailers_creation_switch_off')}
            </span>
          </GeneralInfoBox>
          <GeneralInfoBox
            key={`generalInfoItem-general-params-${6}`}
            label={t('retailers_creation_use_budgetEstimation')}
          >
            <span>
              {retailer.budgetEstimatedEnabled ? t('retailers_creation_switch_on') : t('retailers_creation_switch_off')}
            </span>
          </GeneralInfoBox>
        </GeneralInfoBoxContainer>

        <p className={styles['params-title']}>{t('retailers_creation_offers_params')}</p>
        <GeneralInfoBoxContainer gridTemplate={2}>
          <GeneralInfoBox key={`generalInfoItem-offers-params-${0}`} label={t('retailers_creation_image_size')}>
            <span>
              {retailer.settingOfferImageHorizontalSize} x {retailer.settingOfferImageVerticalSize}
            </span>
          </GeneralInfoBox>
          <GeneralInfoBox
            key={`generalInfoItem-offers-params-${1}`}
            label={t('retailers_creation_product_title_max_chars')}
          >
            <span>{retailer.settingOfferTitleSize}</span>
          </GeneralInfoBox>
          <GeneralInfoBox
            key={`generalInfoItem-offers-params-${2}`}
            label={t('retailers_creation_product_image_format')}
          >
            <span>{retailer.allowedExtensions === null ? 'JPG, PNG' : retailer.allowedExtensions?.join(', ')}</span>
          </GeneralInfoBox>
          <GeneralInfoBox
            key={`generalInfoItem-offers-params-${3}`}
            label={t('retailers_creation_product_additional_information_max_chars')}
          >
            <span>{retailer.settingOfferAdditionalInformationSize}</span>
          </GeneralInfoBox>
        </GeneralInfoBoxContainer>

        <p className={styles['params-title']}>{t('retailers_creation_technical_fees')}</p>
        <GeneralInfoBoxContainer gridTemplate={2}>
          <GeneralInfoBox key={`generalInfoItem-technical-fees-${0}`} label={(currentYear + 1).toString()}>
            <span>
              {technicalsFees_N_Plus1
                ? t('commun_percentage_number', {
                    value: technicalsFees_N_Plus1,
                    maximumFractionDigits: withFractionDigits(technicalsFees_N_Plus1)
                  })
                : '-'}
            </span>
          </GeneralInfoBox>
          <GeneralInfoBox key={`generalInfoItem-technical-fees-${1}`} label={currentYear.toString()}>
            <span>
              {technicalsFees_N
                ? t('commun_percentage_number', {
                    value: technicalsFees_N,
                    maximumFractionDigits: withFractionDigits(technicalsFees_N)
                  })
                : '-'}
            </span>
          </GeneralInfoBox>
          <GeneralInfoBox key={`generalInfoItem-technical-fees-${2}`} label={(currentYear - 1).toString()}>
            <span>
              {technicalsFees_N_Minus1
                ? t('commun_percentage_number', {
                    value: technicalsFees_N_Minus1,
                    maximumFractionDigits: withFractionDigits(technicalsFees_N_Minus1)
                  })
                : '-'}
            </span>
          </GeneralInfoBox>
          <GeneralInfoBox key={`generalInfoItem-technical-fees-${3}`} label={(currentYear - 2).toString()}>
            <span>
              {technicalsFees_N_Minus2
                ? t('commun_percentage_number', {
                    value: technicalsFees_N_Minus2,
                    maximumFractionDigits: withFractionDigits(technicalsFees_N_Minus2)
                  })
                : '-'}
            </span>
          </GeneralInfoBox>
        </GeneralInfoBoxContainer>

        <p className={styles['params-title']}>{t('retailers_creation_nonPositioned_budget_objectives_thresholds')}</p>
        <GeneralInfoBoxContainer gridTemplate={3}>
          <GeneralInfoBox
            key={`generalInfoItem-nonPositioned_budget_objectives_thresholds-${0}`}
            label={
              nonPositionedBudget_startOfYear ? t(MONTH_i18n_MAPPING[nonPositionedBudget_startOfYear?.month]) : '-'
            }
          >
            <span>
              {nonPositionedBudget_startOfYear
                ? t('commun_percentage_number', {
                    value: nonPositionedBudget_startOfYear?.value,
                    maximumFractionDigits: withFractionDigits(nonPositionedBudget_startOfYear?.value)
                  })
                : '-'}
            </span>
          </GeneralInfoBox>
          <GeneralInfoBox
            key={`generalInfoItem-nonPositioned_budget_objectives_thresholds-${1}`}
            label={nonPositionedBudget_halfOfYear ? t(MONTH_i18n_MAPPING[nonPositionedBudget_halfOfYear?.month]) : '-'}
          >
            <span>
              {nonPositionedBudget_halfOfYear
                ? t('commun_percentage_number', {
                    value: nonPositionedBudget_halfOfYear?.value,
                    maximumFractionDigits: withFractionDigits(nonPositionedBudget_halfOfYear?.value)
                  })
                : '-'}
            </span>
          </GeneralInfoBox>
          <GeneralInfoBox
            key={`generalInfoItem-nonPositioned_budget_objectives_thresholds-${2}`}
            label={nonPositionedBudget_endOfYear ? t(MONTH_i18n_MAPPING[nonPositionedBudget_endOfYear?.month]) : '-'}
          >
            <span>
              {nonPositionedBudget_endOfYear
                ? t('commun_percentage_number', {
                    value: nonPositionedBudget_endOfYear?.value,
                    maximumFractionDigits: withFractionDigits(nonPositionedBudget_endOfYear?.value)
                  })
                : '-'}
            </span>
          </GeneralInfoBox>
        </GeneralInfoBoxContainer>

        <p className={styles['params-title']}>{t('retailers_creation_retailer_guidelines')}</p>
        <TabsInModal
          className={'margin-x-minus-32'}
          labels={[t('commun_french'), t('commun_english')]}
          onChange={async (index) => {
            setTabIndex(index);
            setGuidelinesLanguage(index === 0 ? 'FR' : 'EN');
          }}
          selectedIndex={tabIndex}
        />
        <div className={styles['params']}>
          <GeneralInfoBoxContainer gridTemplate={1}>
            <GeneralInfoBox
              fullWidth
              key={`creation-form-guidelines-${0}`}
              label={t('retailers_creation_retailer_guidelines_offer_product')}
            >
              {retailer.guidelines?.[guidelinesLanguage]?.offerProduct?.content ? (
                <HelperText content={retailer.guidelines?.[guidelinesLanguage]?.offerProduct?.content} isPreview />
              ) : (
                '-'
              )}
            </GeneralInfoBox>
            <GeneralInfoBox
              fullWidth
              key={`creation-form-guidelines-${1}`}
              label={t('retailers_creation_retailer_guidelines_offer_promotion')}
            >
              {retailer.guidelines?.[guidelinesLanguage]?.offerPromotion?.content ? (
                <HelperText content={retailer.guidelines?.[guidelinesLanguage]?.offerPromotion?.content} isPreview />
              ) : (
                '-'
              )}
            </GeneralInfoBox>
            <GeneralInfoBox
              fullWidth
              key={`creation-form-guidelines-${2}`}
              label={t('retailers_creation_retailer_guidelines_offer_title')}
            >
              {retailer.guidelines?.[guidelinesLanguage]?.offerTitle?.content ? (
                <HelperText
                  content={retailer.guidelines?.[guidelinesLanguage]?.offerTitle?.content}
                  isPreview
                  title={t('retailers_creation_retailer_guidelines_offer_title_box_title')}
                />
              ) : (
                '-'
              )}
            </GeneralInfoBox>
            <GeneralInfoBox
              fullWidth
              key={`creation-form-guidelines-${3}`}
              label={t('retailers_creation_retailer_guidelines_offer_additional_information')}
            >
              {retailer.guidelines?.[guidelinesLanguage]?.offerAdditionalInformation?.content ? (
                <HelperText
                  content={retailer.guidelines?.[guidelinesLanguage]?.offerAdditionalInformation?.content}
                  title={t('retailers_creation_retailer_guidelines_offer_additional_information_box_title')}
                  isPreview
                />
              ) : (
                '-'
              )}
            </GeneralInfoBox>
            <GeneralInfoBox
              fullWidth
              key={`creation-form-guidelines-${4}`}
              label={t('retailers_creation_retailer_guidelines_offer_date')}
            >
              {retailer.guidelines?.[guidelinesLanguage]?.offerDate?.content ? (
                <HelperText content={retailer.guidelines?.[guidelinesLanguage]?.offerDate?.content} isPreview />
              ) : (
                '-'
              )}
            </GeneralInfoBox>
            <GeneralInfoBox
              fullWidth
              key={`creation-form-guidelines-${5}`}
              label={t('retailers_creation_retailer_guidelines_offer_budget')}
            >
              {retailer.guidelines?.[guidelinesLanguage]?.offerRecommendedMinimumBudget?.content ? (
                <HelperText
                  content={retailer.guidelines?.[guidelinesLanguage]?.offerRecommendedMinimumBudget?.content}
                  isPreview
                />
              ) : (
                '-'
              )}
            </GeneralInfoBox>
          </GeneralInfoBoxContainer>
        </div>

        <p className={styles['params-title']}>{t('retailers_creation_products_ref')}</p>
        <GeneralInfoBoxContainer gridTemplate={2}>
          {retailer.settingProductLevelLabelOne !== null && retailer.settingProductLevelLabelOne !== '' && (
            <GeneralInfoBox key={`generalInfoItem-products_ref-params-${0}`} label={t('retailers_details_label_one')}>
              <span>{retailer.settingProductLevelLabelOne}</span>
            </GeneralInfoBox>
          )}
          <GeneralInfoBox key={`generalInfoItem-products_ref-params-${1}`} label={t('retailers_creation_label_two')}>
            <span>{retailer.settingProductLevelLabelTwo}</span>
          </GeneralInfoBox>
          <GeneralInfoBox key={`generalInfoItem-products_ref-params-${2}`} label={t('retailers_creation_label_three')}>
            <span>{retailer.settingProductLevelLabelThree}</span>
          </GeneralInfoBox>
          <GeneralInfoBox key={`generalInfoItem-products_ref-params-${3}`} label={t('retailers_creation_label_four')}>
            <span>{retailer.settingProductLevelLabelFour}</span>
          </GeneralInfoBox>
        </GeneralInfoBoxContainer>

        <p className={styles['params-title']}>{t('retailers_creation_enable_offer_segment_params')}</p>
        <GeneralInfoBoxContainer gridTemplate={1}>
          <GeneralInfoBox key={`generalInfoItem-products_ref-params-${0}`} height="thin">
            <span>{retailer.offerSegmentEnabled ? t('commun_switch_ON_plural') : t('commun_switch_OFF_plural')}</span>
          </GeneralInfoBox>
        </GeneralInfoBoxContainer>

        <p className={styles['params-title']}>{t('retailers_creation_suppliers_notifications')}</p>
        {retailer.notifySuppliersEnabled ? (
          <>
            <GeneralInfoBoxContainer gridTemplate={2}>
              <GeneralInfoBox
                key={`generalInfoItem-suppliers_notifications-params-${0}`}
                label={t('retailers_creation_notifications')}
              >
                <span>{t('retailers_creation_notifications_on')}</span>
              </GeneralInfoBox>
              <GeneralInfoBox
                key={`generalInfoItem-suppliers_notifications-params-${1}`}
                label={t('retailers_creation_notifications_template')}
              >
                <span>
                  {
                    notificationTemplates?.find((el) => el.id === retailer.notificationInformationSupplier?.templateId)
                      ?.name
                  }
                </span>
              </GeneralInfoBox>
              <GeneralInfoBox
                key={`generalInfoItem-suppliers_notifications-params-${2}`}
                label={t('retailers_creation_notifications_region')}
              >
                <span>{retailer.notificationInformationSupplier?.locale}</span>
              </GeneralInfoBox>
            </GeneralInfoBoxContainer>
            {retailer.notificationInformationSupplier?.hiddenCopyReceivers?.length > 0 && (
              <div className={styles['full-width']}>
                <div className={styles['emails-title']}>{t('retailers_creation_notifications_bcc')}</div>
                <EmailsList
                  emails={retailer.notificationInformationSupplier?.hiddenCopyReceivers?.map((el) => ({
                    email: el.mail
                  }))}
                />
              </div>
            )}
          </>
        ) : (
          <GeneralInfoBox
            key={`generalInfoItem-suppliers_notifications-params-${0}`}
            label={t('retailers_creation_notifications')}
          >
            <span>{t('retailers_creation_notifications_off')}</span>
          </GeneralInfoBox>
        )}

        <p className={styles['params-title']}>{t('retailers_creation_retailers_notifications')}</p>
        <div className={styles['params']}>
          {retailer.notifyRetailersEnabled ? (
            <>
              <GeneralInfoBoxContainer gridTemplate={2}>
                <GeneralInfoBox
                  key={`generalInfoItem-retailers_notifications-params-${0}`}
                  label={t('retailers_creation_notifications')}
                >
                  <span>{t('retailers_creation_notifications_on')}</span>
                </GeneralInfoBox>
                <GeneralInfoBox
                  key={`generalInfoItem-retailers_notifications-params-${1}`}
                  label={t('retailers_creation_notifications_template')}
                >
                  <span>
                    {
                      notificationTemplates?.find(
                        (el) => el.id === retailer.notificationInformationRetailer?.templateId
                      )?.name
                    }
                  </span>
                </GeneralInfoBox>
                <GeneralInfoBox
                  key={`generalInfoItem-retailers_notifications-params-${2}`}
                  label={t('retailers_creation_notifications_region')}
                >
                  <span>{retailer.notificationInformationRetailer?.locale}</span>
                </GeneralInfoBox>
              </GeneralInfoBoxContainer>
              {retailer.notificationInformationRetailer?.receivers?.length > 0 && (
                <div className={styles['full-width']}>
                  <div className={styles['emails-title']}>{t('retailers_creation_notifications_recipients')}</div>
                  <EmailsList
                    emails={retailer.notificationInformationRetailer?.receivers?.map((el) => ({
                      email: el.mail
                    }))}
                  />
                </div>
              )}
              {retailer.notificationInformationRetailer?.hiddenCopyReceivers?.length > 0 && (
                <div className={styles['full-width']}>
                  <div className={styles['emails-title']}>{t('retailers_creation_notifications_bcc')}</div>
                  <EmailsList
                    emails={retailer.notificationInformationRetailer?.hiddenCopyReceivers?.map((el) => ({
                      email: el.mail
                    }))}
                  />
                </div>
              )}
            </>
          ) : (
            <GeneralInfoBox
              key={`generalInfoItem-retailers_notifications-params-${0}`}
              label={t('retailers_creation_notifications')}
            >
              <span>{t('retailers_creation_notifications_off')}</span>
            </GeneralInfoBox>
          )}
        </div>
      </div>
    </div>
  );
};

export default RetailerDetails;

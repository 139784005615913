/* eslint-disable react/display-name */
import React, { useState, memo, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ContentDialog from 'components/ContentDialog';
import ButtonsCustom from 'components/ButtonsCustom';
import FormManager from 'components/FormManager';

const MultiSupplierSelectionBlock = ({ disabled, error, listData, multiSupplierId, updateOfferCreation }) => {
  const { t } = useTranslation();
  const [dialogOpened, setDialogOpen] = useState(false);
  const [itemSelectedId, setItemSelectedId] = useState(multiSupplierId);
  const [tempOldItemId, setTempOldItemId] = useState();

  const listDataWithComposedName = useMemo(() => {
    return listData.map((sup) => {
      const newSupplier = JSON.parse(JSON.stringify(sup));
      newSupplier.composedName = sup.name;
      if (listData.filter((el) => el.name === sup.name).length > 1) {
        newSupplier.composedName = `${newSupplier.name} - ${newSupplier.retailer.code}`;
      }
      return newSupplier;
    });
  }, [listData]);

  useEffect(() => {
    setItemSelectedId(multiSupplierId);
  }, [multiSupplierId]);

  const updateOffer = (id) => {
    // reset also products when step1.id changes
    updateOfferCreation({
      step1: {
        code: '',
        damName: null,
        id: '',
        multiSupplierId: id
      },
      step3: {
        brands: [],
        image: false,
        resultListEan: []
      }
    });
  };

  const handleChange = (id) => {
    if (id) {
      if (itemSelectedId) {
        if (id !== itemSelectedId) {
          setDialogOpen(true);
          setTempOldItemId(itemSelectedId);
        }
      } else {
        updateOffer(id);
      }
      setItemSelectedId(id);
    }
  };

  const handleDialogCancel = () => {
    setDialogOpen(false);
    setItemSelectedId(tempOldItemId);
  };

  const handleDialogConfirm = () => {
    setDialogOpen(false);
    updateOffer(itemSelectedId);
  };

  return (
    <>
      <FormManager
        data={{
          title: t('offers_creation_block_retailer_title'),
          fieldsets: [
            {
              id: 'select-supplier-supplier-step',
              fields: [
                {
                  placeholder: t('commun_choose'),
                  type: 'Select',
                  defaultValue: itemSelectedId,
                  fieldProps: { listData: listDataWithComposedName, labelAttribute: 'composedName' },
                  onFieldChange: handleChange,
                  id: 'name-supplier',
                  disabled,
                  error
                }
              ]
            }
          ]
        }}
      />
      <ContentDialog
        centerText
        isOpen={dialogOpened}
        handleClose={handleDialogCancel}
        title={t('offers_creation_confirm_change_step1')}
        maxWidth="xs"
      >
        <div className="confirm-dialog">
          <ButtonsCustom classType="canceled" text={t('commun_button_cancel')} method={handleDialogCancel} />
          <ButtonsCustom
            classType="action_primary_big"
            text={t('commun_button_continue')}
            method={handleDialogConfirm}
          />
        </div>
      </ContentDialog>
    </>
  );
};

MultiSupplierSelectionBlock.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ),
  multiSupplierId: PropTypes.number,
  updateOfferCreation: PropTypes.func
};

export default memo(MultiSupplierSelectionBlock);

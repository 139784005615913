import { store } from 'store';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';

// TODO: what is this for ?
const locationMapping = [
  { key: 'add-campaign/products', page: 'addCampaignProduct' },
  { key: 'add-campaigns', page: 'addCampaign' },
  { key: 'add-offer', page: 'addOffer' },
  { key: 'campaigns', page: 'campaigns' },
  { key: 'offersProductsRetailer', page: 'offersProductsRetailer' },
  { key: 'offersProductsSupplier', page: 'offersProductsSupplier' },
  { key: 'offersRetailerCashCoupon', page: 'cashcoupon' },
];

const useDynId = (textId) => {
  const history = useHistory();
  const globalState = useContext(store);
  const {
    state: { user },
  } = globalState;

  const page = locationMapping.find((i) => history.location.pathname === i.key)?.page || history.location.pathname;

  return `${user.userType}_${page}_${textId}`;
};

export default useDynId;

import React, { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withFractionDigits } from 'utils/global';
import { offerStatuses, offerTypes } from 'utils/constants';

import ButtonsCustom from 'components/ButtonsCustom';
import ScrollToTop from 'components/ScrollToTop';
import TableCustom from 'components/TableCustom';

import { ReactComponent as FlagActiveIcon } from 'assets/flag_active.svg';
import { ReactComponent as FlagInactiveIcon } from 'assets/flag_inactive.svg';
import { ReactComponent as PencilIcon } from 'assets/16px_edit.svg';

import styles from './TableOffers.module.scss';

import { offerType } from 'types/offer';

type FlagHandlerType = {
  offerListIndex: number;
  isSuperEditable: boolean;
  offerId: number;
};

type UrlParamsType = {
  id: string;
};

type Props = {
  clickedRawUniqueKeyValue?: string;
  isCampaignStateTerminated: boolean;
  isLoading: boolean;
  isUsingFilters: boolean;
  handleFlagClick: ({ offerListIndex, isSuperEditable, offerId }: FlagHandlerType) => void;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  handleSort: (type: string) => void;
  offers: offerType[];
  orderBy: 'asc' | 'desc';
  page: number;
  sortBy: string;
  total: number;
};

const TableOffers = ({
  clickedRawUniqueKeyValue,
  isCampaignStateTerminated,
  isLoading,
  isUsingFilters,
  handleFlagClick,
  handleNextPage,
  handlePrevPage,
  handleSort,
  offers,
  orderBy,
  page,
  sortBy,
  total
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: campaignId } = useParams<UrlParamsType>();

  const columns = [
    {
      id: 1,
      field: 'offerHeadId',
      headerName: 'ID',
      type: 'text',
      align: 'left',
      sortable: true,
      sortPath: 'offerHead.id'
    },
    {
      id: 2,
      field: 'discountMinQuantityOfProducts',
      headerName: t('offers_creation_quantity_to_add_short'),
      type: 'text',
      align: 'center',
      sortable: true
    },
    {
      id: 3,
      field: 'title',
      headerName: t('commun_title'),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.title',
      hasTooltip: true
    },
    {
      id: 4,
      field: 'tag',
      headerName: t('commun_tag'),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.descriptionTag'
    },
    {
      id: 5,
      field: 'additionalInformation',
      headerName: t('offers_creation_offer_additionalInformation_short'),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.additionalInformation',
      hasTooltip: true
    },
    {
      id: 6,
      field: 'discountValue',
      headerName: t('commun_generosity_short'),
      type: 'text',
      align: 'right',
      sortable: true
    },
    {
      id: 7,
      field: 'averagePrice',
      headerName: t('offers_creation_average_price_short'),
      type: 'text',
      align: 'right',
      sortable: true
    },
    {
      id: 8,
      field: 'budget',
      headerName: t('commun_budget'),
      type: 'text',
      align: 'right',
      sortable: true,
      sortPath: 'offerHead.budgetTarget'
    },
    {
      id: 9,
      field: 'discountType',
      headerName: t('offers_creation_discount_mechanism_type'),
      type: 'text',
      sortable: true
    },
    {
      id: 10,
      field: 'marketingStrategy',
      headerName: t('commun_target_marketing'),
      type: 'text',
      sortable: true,
      sortPath: 'targetingStrategy.targetMarketing.title'
    },
    {
      id: 11,
      field: 'offerHeadType',
      headerName: t('_dyn_commun_type'),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.type'
    },
    { id: 12, field: 'actionButtonFlag', type: 'component' },
    { id: 13, field: 'actionButtonEdit', type: 'component' }
  ];

  const rows = useMemo(() => {
    return offers.map((i: offerType, index) => {
      const displayFlagButton =
        !isCampaignStateTerminated &&
        i.type === offerTypes.SUPPLIER_PRODUCT_OFFER &&
        i.status === offerStatuses.VALIDATED &&
        !i.isLinkedToActiveTargetingStatusCampaign;

      const displayEditButton =
        !isCampaignStateTerminated &&
        i.type === offerTypes.SUPPLIER_PRODUCT_OFFER &&
        i.status === offerStatuses.VALIDATED;

      return {
        id: [{ value: i.offerHead.id }],
        offerHeadId: [{ value: i.offerHead.id }],
        discountMinQuantityOfProducts: [{ value: i.discountMinQuantityOfProducts }],
        title: [{ value: i.offerHead?.title }],
        tag: [{ value: i.offerHead?.descriptionTag, color: 'proposal' }],
        additionalInformation: [{ value: i.offerHead?.additionalInformation }],
        discountValue: [
          {
            value:
              i.discountUnit === 'CURRENCY'
                ? t('commun_price', {
                    value: i.discountValue,
                    currency: i.retailer?.currency?.code,
                    maximumFractionDigits: withFractionDigits(i.discountValue)
                  })
                : `${i.discountValue} %`
          }
        ],
        averagePrice: [
          {
            value: i.averagePrice
              ? t('commun_price', {
                  value: i.averagePrice,
                  currency: i.retailer?.currency?.code,
                  maximumFractionDigits: 2
                })
              : '-'
          }
        ],
        budget: [
          {
            value:
              i.offerHead?.budgetTarget === -1
                ? t('commun_unlimited')
                : t('commun_price', { value: i.offerHead?.budgetTarget ?? '-', currency: i.retailer?.currency?.code })
          }
        ],
        discountType: [{ value: t(`product_offers_settings_discount_type_${i.discountType.toLowerCase()}`) }],
        marketingStrategy: [
          { value: t(`commun_marketingStrategy_${i.targetingStrategy?.targetMarketing?.code}_title`) }
        ],
        offerHeadType: [{ value: t(`offer_head_type_${i.offerHead.type}`), color: 'disabled' }],
        actionButtonFlag: displayFlagButton ? (
          <div
            className={styles['action-icon-flag']}
            onClick={() => {
              handleFlagClick({ offerListIndex: index, isSuperEditable: i.offerHead.superEditable, offerId: i.id });
            }}
            role="button"
          >
            {i.offerHead?.superEditable ? <FlagActiveIcon /> : <FlagInactiveIcon />}
          </div>
        ) : null,
        actionButtonEdit: displayEditButton ? (
          <ButtonsCustom
            classType={i.offerHead?.superEditable ? 'icon_primary_tiny' : 'icon_reverse_tiny'}
            method={() => {
              history.push({
                pathname: `/campaigns/${campaignId}/offers/${i.offerHead.id}`,
                search: history.location.search
              });
            }}
            startIconCustom={<PencilIcon />}
          />
        ) : null
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offers]);

  return (
    <div className={styles['root']}>
      <TableCustom
        isLoading={isLoading}
        rows={rows}
        columns={columns}
        total={total}
        handleSort={handleSort}
        page={page}
        size={30}
        prev={handlePrevPage}
        next={handleNextPage}
        order={orderBy}
        sort={sortBy}
        type={'tiny-white'}
        isUsingFilters={isUsingFilters}
        clickedRawUniqueKeyValue={clickedRawUniqueKeyValue}
      />
      <ScrollToTop />
    </div>
  );
};

export default TableOffers;

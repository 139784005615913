import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import Data from './Data';
import Informations from './Informations';
import Review from './Review';
import TabSwitch from 'components/TabSwitch';

import styles from './SidePanel.module.scss';

const SidePanel = ({
  forceReviewTab,
  hasAccessToEstimateCustomerCount,
  isLoadingEstimate,
  isVectorFilled,
  segment
}) => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(segment?.vector?.segments?.length ? 1 : 0);

  const tabsContent = [
    <Informations key="0" scope={segment.scope} />,
    <Review
      key="1"
      hasAccessToEstimateCustomerCount={hasAccessToEstimateCustomerCount}
      isLoadingEstimate={isLoadingEstimate}
      isVectorFilled={isVectorFilled}
      segment={segment}
    />
  ];
  const tabs = [
    {
      label: t('segmentManager_sidePanel_tabs_informations_label'),
      isActive: tabIndex === 0,
      onClick: () => {
        setTabIndex(0);
      }
    },
    {
      label: t('segmentManager_sidePanel_tabs_review_label'),
      isActive: tabIndex === 1,
      onClick: () => {
        setTabIndex(1);
      }
    }
  ];

  useEffect(() => {
    if (forceReviewTab === true) {
      setTabIndex(1);
    }
  }, [forceReviewTab]);

  return (
    <div className={styles['sidePanel']}>
      <div className={styles['tabs-container']}>
        <TabSwitch tabs={tabs} />
      </div>
      {tabsContent[tabIndex]}
    </div>
  );
};

SidePanel.propTypes = {
  forceReviewTab: PropTypes.bool,
  hasAccessToEstimateCustomerCount: PropTypes.bool,
  isLoadingEstimate: PropTypes.bool,
  isVectorFilled: PropTypes.bool,
  segment: PropTypes.object.isRequired
};

export default memo(SidePanel);

import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip';
import { getConditionTitle, isProductLevelXType } from '../utils';

import { ReactComponent as DeleteIcon } from 'assets/16px_trash.svg';
import { ReactComponent as FrequencyIcon } from 'assets/frequency_icon.svg';
import { ReactComponent as InfoIcon } from 'assets/30px_info_circle.svg';
import { ReactComponent as PurchasesIcon } from 'assets/24px_produit.svg';
import { ReactComponent as RecencyIcon } from 'assets/recency_icon.svg';
import { ReactComponent as StoreIcon } from 'assets/30px_distributeur.svg';
import { ReactComponent as ValueIcon } from 'assets/basket_icon.svg';

import styles from './_HeaderBlock.module.scss';

const HeaderBlock = ({ onDelete, segment }) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const {
    state: { user }
  } = globalState;

  const segmentType = segment.type.toLowerCase();
  const conditionTitle = getConditionTitle({ segment, t, user });

  const conditionTooltip = (() => {
    const productLevel = segment?.condition?.level || segment?.level;
    if (isProductLevelXType(productLevel)) {
      return t('segmentManager_vector_type_purchases_levelX_tooltip', { text: conditionTitle });
    } else {
      const type = productLevel ? `${segmentType}_${productLevel}` : segmentType;
      return t(`segmentManager_vector_type_${type}_tooltip`);
    }
  })();

  const displayIcon = () => {
    switch (segmentType) {
      case 'frequency':
        return <FrequencyIcon />;
      case 'multipurchases':
        if (segment.condition?.level === 'store' || segment.level === 'store') {
          return <StoreIcon />;
        }
        return <PurchasesIcon />;
      case 'recency':
        return <RecencyIcon />;
      case 'value':
        return <ValueIcon />;
      default:
        return false;
    }
  };

  return (
    <div className={styles['header']}>
      <div>
        {displayIcon()}
        <div>{conditionTitle}</div>
      </div>
      <div>
        <Tooltip title={conditionTooltip}>
          <InfoIcon title="" />
        </Tooltip>
        <DeleteIcon onClick={onDelete} />
      </div>
    </div>
  );
};

HeaderBlock.propTypes = {
  onDelete: PropTypes.func.isRequired,
  segment: PropTypes.object.isRequired
};

export default memo(HeaderBlock);

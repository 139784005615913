import React, { useState, memo, forwardRef, useRef, useImperativeHandle, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { checkCampaignTitle, scrollToElementById } from 'utils/global';
import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import FormManager from 'components/FormManager';
import useDynId from 'customHooks/useDynId';

const ProductStep1 = forwardRef(({ campaignProduct, disabled, dispatch, isEditMode }, ref) => {
  const { t } = useTranslation();
  const thisRef = useRef(null);

  const [errorMessageTitleField, setErrorMessageTitleField] = useState(null);
  const [errorMessageDateField, setErrorMessageDateField] = useState(null);
  const [showAlertDialog, setShowAlertDialog] = useState(isEditMode || !!campaignProduct.offers.length);
  const [showAlertDialogAlreadyShown, setShowAlertDialogAlreadyShown] = useState(false);
  const [dates, setDates] = useState({ startDate: null, endDate: null });
  const [focusState, setFocusState] = useState(null);

  useImperativeHandle(ref, () => ({
    ref: thisRef,
    step1CheckHasError() {
      // need both to execute
      const titleHasError = checkHasTitleError();
      const dateHasError = checkHasDateError();
      return titleHasError || dateHasError;
    }
  }));

  useEffect(() => {
    const { startDate, endDate } = dates;
    if (
      !focusState &&
      startDate &&
      endDate &&
      (!moment(campaignProduct.startDate?.toISOString())?.isSame(moment(startDate).utc(), 'day') ||
        !moment(campaignProduct.endDate?.toISOString())?.isSame(moment(endDate).utc(), 'day'))
    ) {
      dispatch({
        type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE',
        payload: { startDate, endDate }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, focusState]);

  const checkHasTitleError = () => {
    const { title } = campaignProduct;
    let hasError = false;
    if (!title) {
      hasError = true;
      setErrorMessageTitleField(t('commun_field_required'));
    } else if (!checkCampaignTitle(title)) {
      hasError = true;
      setErrorMessageTitleField(t('campaign_creation_title_error'));
    }

    if (hasError) {
      scrollToElementById('field-campaign-creation-step-01');
    } else {
      setErrorMessageTitleField();
    }
    return hasError;
  };

  const checkHasDateError = () => {
    const { endDate, startDate } = campaignProduct;
    let hasError = false;

    if (!endDate || !startDate) {
      hasError = true;
      setErrorMessageDateField(t('commun_field_required'));
    } else if (moment().isAfter(startDate, 'day')) {
      hasError = true;
      setErrorMessageDateField(t('campaign_creation_startdate_error'));
    }

    if (hasError) {
      scrollToElementById('field-campaign-creation-step-01');
    } else {
      setErrorMessageDateField();
    }

    return hasError;
  };

  const handleTitle = (title) => {
    //reset title errors
    if (title !== '') {
      setErrorMessageTitleField();
    }

    dispatch({
      type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE',
      payload: { title }
    });
  };

  const handleDates = async ({ startDate, endDate }) => {
    // reset dates errors
    if (startDate && endDate) {
      setErrorMessageDateField();
    }
    setDates({ startDate, endDate });
  };

  const handleDatePickerFocusInput = (focus) => {
    setFocusState(focus);
    if (focus && !showAlertDialogAlreadyShown && campaignProduct.offers.length) {
      setShowAlertDialog(true);
    }
  };

  const handleCloseDatesAlertDialog = () => {
    setShowAlertDialog(false);
    setShowAlertDialogAlreadyShown(true);
  };

  return (
    <>
      <div ref={thisRef}>
        <FormManager
          data={{
            title: isEditMode ? t('campaign_edit_your_campaign_label') : t('campaign_create_your_campaign_label'),
            subTitle: t('campaign_product_creation_step_01_group_desc'),
            fieldsets: [
              {
                id: 'add-campaign-product-step-01',
                fields: [
                  {
                    outerContainerClass: ['without-margin-top'],
                    placeholder: t('campaign_product_step1_input_title'),
                    type: 'TextField',
                    id: 'title',
                    disabled: disabled,
                    defaultValue: campaignProduct.title,
                    error: errorMessageTitleField,
                    onFieldChange: handleTitle
                  },
                  {
                    type: 'DateRangePicker',
                    id: 'date',
                    classnames: ['full-width'],
                    disabled: disabled,
                    defaultValue: { startDate: campaignProduct.startDate, endDate: campaignProduct.endDate },
                    error: errorMessageDateField,
                    fieldProps: {
                      getFocusInput: (focus) => {
                        handleDatePickerFocusInput(focus);
                      },
                      minDate: moment()
                    },
                    onFieldChange: (dates) => {
                      handleDates(dates);
                    }
                  }
                ]
              }
            ]
          }}
        />
      </div>
      <ContentDialog
        centerText
        isOpen={showAlertDialog}
        handleClose={handleCloseDatesAlertDialog}
        title={t('campaign_product_step2_modal_alert_message')}
        maxWidth="xs"
      >
        <div className="confirm-dialog">
          <ButtonsCustom
            classType="action_primary_big"
            text={t('commun_button_got_it')}
            method={handleCloseDatesAlertDialog}
            id={useDynId('step1Valid2Modal')}
          />
        </div>
      </ContentDialog>
    </>
  );
});

ProductStep1.propTypes = {
  campaignProduct: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool
};

export default memo(ProductStep1);

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { campaignTypes } from 'utils/constants';
import styles from './ABTesting.module.scss';

const ABTesting = ({ campaign, currency, hasBudgetSpent }) => {
  const { t } = useTranslation();
  const {
    campaignType,
    targetingKpis,
    targetingKpisOfferCampaign,
    transactionalKpisCashCouponCampaign,
    transactionalKpisOfferCampaign,
  } = campaign;

  const data =
    campaignType === campaignTypes.CASH_COUPON
      ? {
          targetedClients: [targetingKpis?.sampleATargetedClients, targetingKpis?.sampleBTargetedClients],
          turnOverPerClient: [
            transactionalKpisCashCouponCampaign?.sampleATurnoverPerClient,
            transactionalKpisCashCouponCampaign?.sampleBTurnoverPerClient,
          ],
        }
      : {
          targetedClients: [
            targetingKpisOfferCampaign?.sampleATargetedShoppers,
            targetingKpisOfferCampaign?.sampleBTargetedShoppers,
          ],
          turnOverPerClient: [
            transactionalKpisOfferCampaign?.sampleATurnover,
            transactionalKpisOfferCampaign?.sampleBTurnover,
          ],
        };

  return (
    <div className={`${styles['ABTestingRoot']} ${hasBudgetSpent ? styles['withMarginTop'] : ''}`}>
      <div className={styles['header']}>
        <p>{t('commun_ABTesting_sampleA')}</p>
        <p>{t('commun_ABTesting_sampleB')}</p>
      </div>
      <div className={styles['row']}>
        <p>{t('commun_targeted_customers')}</p>
        <p> {t('commun_number', { value: data.targetedClients[0] ?? '-' })}</p>|
        <p> {t('commun_number', { value: data.targetedClients[1] ?? '-' })}</p>
      </div>
      {hasBudgetSpent && (
        <div className={styles['row']}>
          <p>{t(campaignType === campaignTypes.CASH_COUPON ? 'commun_turnover_per_customer' : 'commun_turnover')}</p>
          <p> {t('commun_price', { value: data.turnOverPerClient[0] ?? '-', currency })}</p>|
          <p> {t('commun_price', { value: data.turnOverPerClient[1] ?? '-', currency })}</p>
        </div>
      )}
    </div>
  );
};

ABTesting.propTypes = {
  campaign: PropTypes.object.isRequired,
  currency: PropTypes.string.isRequired,
  hasBudgetSpent: PropTypes.bool,
};
export default ABTesting;

import React, { startTransition, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { Trans, useTranslation } from 'react-i18next';
import {
  getAllProductlevels,
  getAllProductLevelsByCode,
  getAllProductsByLevelSku,
  getAllProductsLevelSkuByCode,
  getAllStores,
  getAllStoresByCode,
  getProductLevels,
  getProductsByKeyword,
  getProductsCount,
  getStores
} from 'api';
import { delay } from 'utils/global';
import { getSettingProductLevelXLabel, isProductLevelXType } from '../utils';
import { productLevels } from '../index';
import useDynId from 'customHooks/useDynId';

import AutocompleteSelect from 'components/AutocompleteCustom/AutocompleteSelect';
import ButtonsCustom from 'components/ButtonsCustom';
import EanSelectedList from 'components/EanSelectedList';
import TableCustom from 'components/TableCustom';
import Tabs from 'components/TabsInModal';
import TextFieldCustom from 'components/TextFieldCustom';
import Tooltip from 'components/Tooltip';

import { ReactComponent as ArrowIcon } from 'assets/14px_arrow.svg';
import { ReactComponent as SearchIcon } from 'assets/illu_recherche.svg';
import { ReactComponent as TrashIcon } from 'assets/16px_trash.svg';
import { ReactComponent as WarningIcon } from 'assets/24px_alert_round_yellow.svg';

import styles from './ModalContent.module.scss';
import EanAutocompleteInputWithResultAndErrorList from 'components/EanAutocompleteInputWithResultAndErrorList';

const ModalContent = ({ onChange, onClose, segment }) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { products, suppliers, user } = state;

  const addedElementIdList = segment.condition?.on || segment.on;
  const productLevel = segment.condition?.level || segment.level;

  const isLevelBrand = productLevel === productLevels.BRAND;
  const isLevelStore = productLevel === productLevels.STORE;
  const isProductLevelSku = productLevel === productLevels.SKU;
  const isProductLevelX = isProductLevelXType(productLevel);
  const productLevelXLabel = getSettingProductLevelXLabel({ productLevel, user });

  const configs = {
    brand: {
      fetchAllElementListFunc: getAllProductlevels,
      fetchElementListFunc: getProductLevels,
      fetchInitialAddedElementListFunc: getAllProductLevelsByCode,
      searchInputMinimumCharacters: 3,
      searchInputPlaceholderWording: t(`commun_search_label_input_placeholder`),
      searchTabWording: t('tab_select_brand'),
      sortKey: 'name',
      tableColumnWordingCode: null,
      tableWordingTitle: t('commun_brand_list_plural'),
      title: t('commun_search_brand_title')
    },
    levelX: {
      fetchAllElementListFunc: getAllProductlevels,
      fetchElementListFunc: getProductLevels,
      fetchInitialAddedElementListFunc: getAllProductLevelsByCode,
      searchInputMinimumCharacters: 3,
      searchInputPlaceholderWording: t(`commun_search_label_input_placeholder`),
      searchTabWording: t('tab_select_product_levelX', {
        text: productLevelXLabel
      }),
      sortKey: 'name',
      tableColumnWordingCode: null,
      tableWordingTitle: t('_dyn_product_levelX_table_total_result_title', {
        text: productLevelXLabel
      }),
      title: t('_dyn_search_product_levelX_title', {
        text: productLevelXLabel
      })
    },
    sku: {
      fetchAllElementListFunc: getAllProductsByLevelSku,
      fetchElementListFunc: getProductsByKeyword,
      fetchInitialAddedElementListFunc: getAllProductsLevelSkuByCode,
      searchInputMinimumCharacters: 3,
      searchInputPlaceholderWording: t(`offers_creation_product_place_holder`),
      searchTabWording: t('tab_select_products'),
      sortKey: 'description',
      tableColumnWordingCode: t('_dyn_commun_ean'),
      tableWordingTitle: t('offers_creation_product_list'),
      title: t('commun_search_product_title')
    },
    store: {
      fetchAllElementListFunc: getAllStores,
      fetchElementListFunc: getStores,
      fetchInitialAddedElementListFunc: getAllStoresByCode,
      searchInputMinimumCharacters: 2,
      searchInputPlaceholderWording: t(`commun_search_label_input_store_placeholder`),
      searchTabWording: t('tab_select_store'),
      sortKey: 'code',
      tableColumnWordingCode: null,
      tableWordingTitle: t('commun_store'),
      title: t('commun_search_store_title')
    }
  };
  const config = configs[isProductLevelX ? 'levelX' : productLevel];

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFilters, setIsLoadingFilters] = useState(false);
  const [elementKeyword, setElementKeyword] = useState('');
  const [tempItems, setTempItems] = useState([]);
  const [filterBrandId, setFilterBrandId] = useState('');
  const [brandList, setBrandList] = useState([]);
  const [filterCategoryLevelId, setFilterCategoryLevelId] = useState('');
  const [filterSupplierId, setFilterSupplierId] = useState('');
  const [productLevelList, setProductLevelList] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [inputHasError, setInputHasError] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [displayTableCustomMessage, setDisplayTableCustomMessage] = useState(isProductLevelSku);
  const [allProductsCount, setAllProductsCount] = useState(null);
  const [alreadyAddedItemsCount, setAlreadyAddedItemsCount] = useState(0);

  const [sortBy, setSortBy] = useState(config.sortKey);
  const [orderBy, setOrderBy] = useState('asc');
  const [page, setPage] = useState(0);

  // for optimisation
  const [allFilteredItemsList, setAllFilteredItemsList] = useState([]);
  const [processingLoop, setProcessingLoop] = useState(false);

  const inputBrandId = useDynId('select-brand');
  const inputCategoryId = useDynId('select-category');
  const inputSupplierId = useDynId('select-supplier');
  const inputAutocompleteId = useDynId('input-autocomplete');

  const tableSize = 30;

  const fetchAllFilteredItems = async () => {
    const list = await config.fetchAllElementListFunc({
      productLevel,
      retailerId: user.id,
      supplierId: filterSupplierId,
      brandId: filterBrandId,
      categoryId: filterCategoryLevelId
    });
    if (list) {
      setAllFilteredItemsList(list);
    } else {
      setAllFilteredItemsList([]);
    }

    return list;
  };

  // count already added items
  const updateAlreadyAddedItemsCount = (list = allFilteredItemsList) => {
    if (list?.length) {
      const tempItemsIds = tempItems.map((el) => el.id);
      setAlreadyAddedItemsCount(list.filter((el) => tempItemsIds.includes(el.id)).length);
    } else {
      setAlreadyAddedItemsCount(0);
    }
  };

  // fetch initial selected items
  useEffect(() => {
    const getInitialElementsData = async () => {
      const list = await config.fetchInitialAddedElementListFunc({
        retailerId: user.id,
        codeList: addedElementIdList
      });
      if (list?.length) {
        setTempItems(list);
      }
    };

    if (addedElementIdList?.length) {
      getInitialElementsData();
    }

    return () => {
      dispatch({ type: 'PRODUCTS_RESET' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch product levels and brand lists
  useEffect(() => {
    const getFiltersData = async () => {
      setIsLoadingFilters(true);

      const filterBrandList = await getAllProductlevels({
        productLevel: productLevels.BRAND,
        retailerId: user.id,
        supplierId: filterSupplierId
      });
      const filterCategoryList = await getAllProductlevels({
        productLevel: 'lvl4',
        retailerId: user.id,
        supplierId: filterSupplierId
      });

      setBrandList(filterBrandList);
      setProductLevelList(filterCategoryList);
      setIsLoadingFilters(false);
    };

    if (isProductLevelSku && filterSupplierId) {
      getFiltersData();
    } else {
      setBrandList([]);
      setProductLevelList([]);
    }
  }, [filterSupplierId, isProductLevelSku, user]);

  // update table content
  useEffect(() => {
    const fetchElements = async () => {
      setIsLoading(true);

      const payload = await config.fetchElementListFunc({
        brandId: filterBrandId,
        categoryId: filterCategoryLevelId,
        keyword: elementKeyword,
        order: orderBy,
        page,
        productLevel,
        retailerId: user.id,
        sort: sortBy,
        supplierId: filterSupplierId
      });

      if (payload) {
        dispatch({
          type: 'PRODUCTS_UPDATE',
          payload
        });
      }

      setAllProductsCount(null);
      setDisplayTableCustomMessage(false);
      setIsLoading(false);
    };

    const getAllProductsCount = async () => {
      setIsLoading(true);
      const count = await getProductsCount({});
      setAllProductsCount(count);
      setIsLoading(false);
    };

    const getAlreadyAddedItemsCount = async () => {
      // get all filtered items
      const listAll = await fetchAllFilteredItems();
      updateAlreadyAddedItemsCount(listAll);
    };

    if (!isProductLevelSku || (isProductLevelSku && (filterSupplierId || elementKeyword))) {
      fetchElements();

      startTransition(() => {
        getAlreadyAddedItemsCount();
      });
    } else {
      getAllProductsCount();
      setDisplayTableCustomMessage(true);
      setAlreadyAddedItemsCount(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterBrandId, filterCategoryLevelId, filterSupplierId, orderBy, page, sortBy, user, elementKeyword]);

  const rows = products.list.map((item) => ({
    id: [{ value: item.id, color: 'default', textStyle: 'p3' }],
    checked: !!tempItems.filter((i) => i.id === item.id).length,
    code: [
      {
        value: item.code || item.ean,
        color: isProductLevelSku ? 'ean' : 'default',
        textStyle: isProductLevelSku ? 'tag' : 'text'
      }
    ],
    label: [{ value: item.label, color: 'default', textStyle: 'p1' }]
  }));

  let columns = [
    {
      id: 1,
      field: 'code',
      headerName: t('_dyn_commun_code'),
      type: 'text',
      sortable: true,
      sortPath: 'code'
    },
    {
      id: 2,
      field: 'label',
      headerName: t('_dyn_commun_label'),
      type: 'text',
      sortable: true,
      sortPath: config.sortKey
    }
  ];
  if (isLevelBrand) {
    columns = [
      {
        id: 2,
        field: 'label',
        headerName: t('_dyn_commun_label'),
        type: 'text',
        sortable: true,
        sortPath: config.sortKey
      }
    ];
  }
  if (isProductLevelSku) {
    columns = [
      {
        id: 1,
        field: 'code',
        headerName: t('_dyn_commun_ean'),
        type: 'text',
        sortable: true
      },
      {
        id: 2,
        field: 'label',
        headerName: t('_dyn_commun_label'),
        type: 'text',
        sortable: true,
        sortPath: config.sortKey
      }
    ];
  }
  if (isLevelStore) {
    columns = [
      {
        id: 1,
        field: 'code',
        headerName: t('_dyn_commun_code'),
        type: 'text',
        sortable: true,
        sortPath: 'code'
      },
      {
        id: 2,
        field: 'label',
        headerName: t('_dyn_commun_label'),
        type: 'text',
        sortable: true,
        sortPath: 'name'
      }
    ];
  }

  const handleCheck = (elem) => {
    let newItems;
    if (elem.id === 'all') {
      // Set uniqueness works with primitive values (number, string)
      newItems = new Set(tempItems.map((t) => JSON.stringify(t)));
      if (elem.checked) {
        rows.forEach((item) => {
          newItems.add(
            JSON.stringify({
              code: item.code[0].value,
              id: item.id[0].value,
              label: item.label[0].value
            })
          );
        });
      } else {
        rows.forEach((item) => {
          newItems.delete(
            JSON.stringify({
              code: item.code[0].value,
              id: item.id[0].value,
              label: item.label[0].value
            })
          );
        });
      }
      // back to an Array of Objects
      newItems = [...newItems].map((str) => JSON.parse(str));
    } else {
      newItems = elem.checked
        ? [
            ...tempItems,
            {
              code: elem.item.code[0].value,
              id: elem.item.id[0].value,
              label: elem.item.label[0].value
            }
          ]
        : tempItems.filter((i) => i.code !== elem.item.code[0].value);
    }
    setTempItems(newItems);
  };

  useEffect(() => {
    updateAlreadyAddedItemsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempItems]);

  const handleAddAllItems = async () => {
    // add some delay to let React update UI with processingLoop
    setProcessingLoop(true);
    await delay();

    let newItems = new Set(tempItems.map((t) => JSON.stringify(t)));
    await new Promise((resolve) => {
      allFilteredItemsList.forEach((item) => {
        newItems.add(
          JSON.stringify({
            code: item.code,
            id: item.id,
            label: item.name || item.label
          })
        );
      });
      resolve();
    });

    // // back to an Array of Objects
    newItems = [...newItems].map((str) => JSON.parse(str));
    setTempItems(newItems);
    setProcessingLoop(false);
  };

  const handleRemoveFilteredItems = async () => {
    // add some delay to let React update UI with processingLoop
    setProcessingLoop(true);
    setAlreadyAddedItemsCount(0);
    await delay();

    let newItems = new Set(tempItems.map((t) => JSON.stringify(t)));
    await new Promise((resolve) => {
      allFilteredItemsList.forEach((item) => {
        newItems.delete(
          JSON.stringify({
            code: item.code,
            id: item.id,
            label: item.name || item.label
          })
        );
      });
      resolve();
    });

    // back to an Array of Objects
    newItems = [...newItems].map((str) => JSON.parse(str));
    setTempItems(newItems);
    setProcessingLoop(false);
  };

  const handleInputSearch = (event) => {
    let value = event.target.value;
    let hasError = false;

    if (!value) {
      value = '';
    } else if (value.length < config.searchInputMinimumCharacters) {
      hasError = true;
      value = '';
    } else {
      resetPaginationAndSorting();
    }
    setInputHasError(hasError);
    setInputSearch(value);
    setElementKeyword(value);
  };

  const handleNext = () => {
    setPage((state) => state + 1);
  };
  const handlePrev = () => {
    setPage((state) => state - 1);
  };
  const handleSort = (sort) => {
    setSortBy(sort);
    setOrderBy(orderBy !== 'desc' ? 'desc' : 'asc');
  };
  const resetPaginationAndSorting = () => {
    setOrderBy('asc');
    setSortBy(config.sortKey);
    setPage(0);
  };

  const customMessageNode = () => {
    return (
      <div className={styles['customMessage']}>
        <SearchIcon />
        <div>{t('segmentManager_table_waiting_filters')}</div>
      </div>
    );
  };

  const customTabNode = () => {
    return (
      <div>
        <Trans i18nKey={`${t('tab_visualize_selection')} (<i>${tempItems.length}</i>)`} />
        <Tooltip title={t('segmentManager_warning_products_not_same_counts_tooltip')}>
          <WarningIcon />
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={styles['root']}>
      <h2>{config.title}</h2>
      <Tabs
        labels={[
          config.searchTabWording,
          isProductLevelSku ? t('tab_select_by_ean') : '',
          tempItems.length
            ? addedElementIdList?.length && addedElementIdList?.length !== tempItems.length
              ? customTabNode()
              : `${t('tab_visualize_selection')} (<i>${tempItems.length}</i>)`
            : t('tab_visualize_selection')
        ]}
        onChange={setTabIndex}
        position="left"
        selectedIndex={tabIndex}
      />

      <div className={tabIndex === 0 ? styles['block'] : styles['hidden']}>
        {!isLevelStore && (
          <div className={styles['filters-container']}>
            <AutocompleteSelect
              disabled={processingLoop || !suppliers?.length || isLoading}
              id={inputSupplierId}
              labelAttribute="name"
              listData={suppliers ? [{ id: undefined, name: t('offers_creation_all_search') }, ...suppliers] : []}
              onSelection={(option) => {
                resetPaginationAndSorting();
                setFilterBrandId('');
                setFilterCategoryLevelId('');
                setFilterSupplierId(option?.id);
              }}
              placeholderText={t('suppliers_list_title')}
              value={suppliers.find((option) => option.id === filterSupplierId)}
            />

            {isProductLevelSku && (
              <Tooltip
                title={
                  !filterSupplierId || !brandList.length ? t('segmentManager_disabled_select_brandList_tooltip') : ''
                }
              >
                <div>
                  <AutocompleteSelect
                    disabled={processingLoop || !filterSupplierId || !brandList.length || isLoading || isLoadingFilters}
                    id={inputBrandId}
                    labelAttribute="name"
                    listData={[{ id: undefined, name: t('offers_creation_all_search') }, ...brandList]}
                    onSelection={(option) => {
                      resetPaginationAndSorting();
                      setFilterBrandId(option?.id);
                    }}
                    placeholderText={t('offers_creation_brand')}
                    value={brandList.find((option) => option.id === filterBrandId)}
                  />
                </div>
              </Tooltip>
            )}

            {isProductLevelSku && (
              <Tooltip
                title={
                  !filterSupplierId || !productLevelList.length
                    ? t('segmentManager_disabled_select_productLevelList_tooltip')
                    : ''
                }
              >
                <div>
                  <AutocompleteSelect
                    disabled={
                      processingLoop || !filterSupplierId || !productLevelList.length || isLoading || isLoadingFilters
                    }
                    id={inputCategoryId}
                    labelAttribute="name"
                    listData={[{ id: undefined, name: t('offers_creation_all_search') }, ...productLevelList]}
                    onSelection={(option) => {
                      resetPaginationAndSorting();
                      setFilterCategoryLevelId(option?.id);
                    }}
                    placeholderText={user.settingProductLevelLabelFour}
                    value={productLevelList.find((option) => option.id === filterCategoryLevelId)}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        )}

        {!isProductLevelSku && (
          <div className={`${styles['filters-container']} ${styles['inputSearch']}`}>
            <TextFieldCustom
              error={inputHasError}
              errorMsg={t('commun_input_minimum_length', { count: config.searchInputMinimumCharacters })}
              disabled={isLoading || processingLoop}
              id={inputAutocompleteId}
              InputProps={{
                endAdornment: (
                  <ButtonsCustom
                    classType="icon_secondary_small"
                    id="input-arrow-icon"
                    startIconCustom={<ArrowIcon />}
                  />
                )
              }}
              inputProps={{ autoComplete: 'off' }}
              label={config.searchInputPlaceholderWording}
              onBlur={handleInputSearch}
              onChange={(event) => {
                setInputSearch(event.target.value);
              }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  handleInputSearch(event);
                }
              }}
              value={inputSearch}
            />
          </div>
        )}

        <div className={styles['table-head-container']}>
          <div className={styles['wait-text']}>{processingLoop && t('commun_please_wait')}</div>
          <div className={styles['oneline']}>
            <h3>
              {config.tableWordingTitle}{' '}
              {isLoading ? (
                <span>...</span>
              ) : allProductsCount || products.total ? (
                <span>
                  {t('commun_result', {
                    count: allProductsCount || products.total
                  })}
                </span>
              ) : (
                <span>{t('commun_no_result')}</span>
              )}
            </h3>
            <div className={styles['action-buttons-container']}>
              <ButtonsCustom
                id="add-all-filtered-items-button"
                classType="action_primary_small"
                text={t('commun_select_all')}
                method={handleAddAllItems}
                disabled={processingLoop || (!isLevelStore && !filterSupplierId)}
                tooltip={!isLevelStore && !filterSupplierId ? t('supplier_choose_one') : ''}
              />
              {!!alreadyAddedItemsCount && (isLevelStore || !!filterSupplierId) && (
                <ButtonsCustom
                  id="remove-all-filtered-items-button"
                  classType="link_primary_small"
                  text={t('segmentManager_button_remove_x_filtered_items', { count: alreadyAddedItemsCount })}
                  method={handleRemoveFilteredItems}
                  disabled={processingLoop}
                  startIconCustom={<TrashIcon />}
                />
              )}
            </div>
          </div>
        </div>
        <TableCustom
          isLoading={isLoading}
          sort={sortBy}
          order={orderBy}
          rows={rows}
          columns={columns}
          total={products.total}
          handleSort={handleSort}
          page={page}
          size={tableSize}
          prev={handlePrev}
          next={handleNext}
          check={handleCheck}
          customMessageNode={displayTableCustomMessage ? customMessageNode() : null}
          sticky
          inModal
        />
      </div>

      {tabIndex === 1 && (
        <div className={tabIndex === 1 ? styles['block'] : styles['hidden']}>
          <div className={styles['selected-list-container']}>
            <EanAutocompleteInputWithResultAndErrorList
              alreadyAddedList={tempItems}
              isLoading={products.loading}
              onChange={(list) => {
                let newList = new Set(tempItems.map((el) => JSON.stringify(el)));
                list.forEach((item) => {
                  newList.add(
                    JSON.stringify({
                      code: item.ean,
                      id: item.id,
                      label: item.label
                    })
                  );
                });
                // back to an Array of Objects
                newList = [...newList].map((str) => JSON.parse(str));
                setTempItems(newList);
              }}
              onDeleteOne={(eanToRemove) => {
                setTempItems(tempItems.filter((item) => item.code !== eanToRemove));
              }}
              retailerId={user.id}
              tableSize={10}
            />
          </div>
        </div>
      )}

      <div className={tabIndex === 2 ? styles['block'] : styles['hidden']}>
        <div className={styles['selected-list-container']}>
          <ButtonsCustom
            classType="link_primary_small"
            text={t('commun_button_clear_list')}
            method={() => {
              setTempItems([]);
            }}
            disabled={!tempItems.length}
            id="clear-all-selection"
            startIconCustom={<TrashIcon />}
          />
          <EanSelectedList
            displayTag={isProductLevelSku}
            inModal
            list={
              isLevelStore || isProductLevelX
                ? tempItems.map((el) => ({ ...el, label: el.label ? `${el.code} - ${el.label}` : el.code }))
                : tempItems
            }
            onRemoveItem={(item) => {
              const newList = tempItems.filter((tempItem) => tempItem.id !== item.id);
              setTempItems(newList);
            }}
            tagAttribute="code"
          />
        </div>
      </div>

      <div className={styles['confirm-bottom-dialog']}>
        <ButtonsCustom classType="canceled" text={t('commun_cancel')} method={onClose} />
        <ButtonsCustom
          classType="action_primary_big"
          text={t('commun_save')}
          method={() => {
            // remove duplicate and map codes
            const newItems = [...new Set([...tempItems.map((el) => el.code)])];
            onChange(newItems);
            onClose();
          }}
          disabled={processingLoop}
        />
      </div>
    </div>
  );
};

ModalContent.propTypes = {
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  segment: PropTypes.object
};

export default ModalContent;

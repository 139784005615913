import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonsCustom from 'components/ButtonsCustom';
import styles from './FooterButtonsContainer.module.scss';

const FooterButtonsContainer = ({
  cancelLabel,
  confirmLabel,
  disabled,
  isLoading,
  onCancel,
  onConfirm,
  tooltipMsg,
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles['root']}>
      {onCancel && (
        <ButtonsCustom
          classType="canceled"
          text={cancelLabel || t('commun_cancel')}
          method={onCancel}
          disabled={isLoading || disabled}
        />
      )}

      <ButtonsCustom
        classType="action_primary_big"
        text={confirmLabel || t('commun_button_save')}
        method={onConfirm}
        loading={isLoading}
        disabled={disabled}
        tooltip={tooltipMsg || ''}
      />
    </div>
  );
};

FooterButtonsContainer.propTypes = {
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  tooltipMsg: PropTypes.string,
};

export default memo(FooterButtonsContainer);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import AddConditionsPanel from 'components/SegmentManager/AddConditionsPanel';
import ButtonsCustom from 'components/ButtonsCustom';
import Drawer from 'components/Drawer';
import Frequency from './ConditionsForm/Frequency';
import MultiPurchases from './ConditionsForm/MultiPurchases';
import Operator from './Operator';
import Recency from './ConditionsForm/Recency';
import Value from './ConditionsForm/Value';

import { ReactComponent as AddIcon } from 'assets/30px_plus.svg';
import { ReactComponent as CrossIcon } from 'assets/22px_croix2.svg';
import styles from './index.module.scss';

export const productLevels = {
  SKU: 'sku',
  LEVEL0: 'level0',
  LEVEL1: 'level1',
  LEVEL2: 'level2',
  LEVEL3: 'level3',
  BRAND: 'brand',
  STORE: 'store'
};

export const config = {
  lists: {
    commun: {
      interval: ['DAY', 'WEEK', 'MONTH', 'YEAR'],
      operator: ['$and', '$or'],
      period: ['over', 'between']
    },
    frequency: {
      sign: ['$lte', '$gte', '$lt', '$gt', '$in']
    },
    purchases: {
      sign: ['$lte', '$gte', '$lt', '$gt', '$in']
    },
    recency: {
      sign: ['$lte', '$gte', '$eq']
    },
    value: {
      sign: ['$lte', '$gte', '$lt', '$gt', '$in']
    },
    visitEach: {
      sign: ['$lte', '$gte', '$lt', '$gt', '$in'],
      unit: ['visit_each_day', 'visit_each_week', 'visit_each_month', 'visit_each_year']
    }
  },
  types: {
    recency: {
      type: 'Recency',
      period: {
        type: 'over',
        value: {
          sign: '$gte',
          count: null,
          interval: null
        }
      }
    },
    frequency: {
      type: 'Frequency',
      condition: {
        sign: '$lte',
        value: null
      },
      period: {
        type: 'over',
        value: {
          count: null,
          interval: null
        }
      }
    },
    value: {
      type: 'Value',
      condition: {
        sign: '$lte',
        value: null
      },
      period: {
        type: 'over',
        value: {
          count: null,
          interval: null
        }
      }
    },
    multipurchases_sku: {
      type: 'MultiPurchases',
      level: productLevels.SKU,
      on: [],
      conditions: [],
      period: {
        type: 'over',
        value: {
          count: null,
          interval: null
        }
      }
    },
    multipurchases_level3: {
      type: 'MultiPurchases',
      level: productLevels.LEVEL3,
      on: [],
      conditions: [],
      period: {
        type: 'over',
        value: {
          count: null,
          interval: null
        }
      }
    },
    multipurchases_level2: {
      type: 'MultiPurchases',
      level: productLevels.LEVEL2,
      on: [],
      conditions: [],
      period: {
        type: 'over',
        value: {
          count: null,
          interval: null
        }
      }
    },
    multipurchases_level1: {
      type: 'MultiPurchases',
      level: productLevels.LEVEL1,
      on: [],
      conditions: [],
      period: {
        type: 'over',
        value: {
          count: null,
          interval: null
        }
      }
    },
    multipurchases_level0: {
      type: 'MultiPurchases',
      level: productLevels.LEVEL0,
      on: [],
      conditions: [],
      period: {
        type: 'over',
        value: {
          count: null,
          interval: null
        }
      }
    },
    multipurchases_brand: {
      type: 'MultiPurchases',
      level: productLevels.BRAND,
      on: [],
      conditions: [],
      period: {
        type: 'over',
        value: {
          count: null,
          interval: null
        }
      }
    },
    multipurchases_store: {
      type: 'MultiPurchases',
      level: productLevels.STORE,
      on: [],
      conditions: [],
      period: {
        type: 'over',
        value: {
          count: null,
          interval: null
        }
      }
    }
  },
  innerConditionTypes: {
    currency: { sign: '$lte', unit: 'currency', value: null },
    last_visit: { unit: 'last_visit' },
    quantity: { sign: '$lte', unit: 'quantity', value: null },
    visit: { sign: '$lte', unit: 'visit', value: null },
    visit_each_day: { sign: '$lte', value: null, unit: 'visit_each_day' }
  }
};

const MainBlock = ({ data = {}, scope, updateData }) => {
  const { t } = useTranslation();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [addConditionsPanelButtonGroupIndex, setAddConditionsPanelButtonGroupIndex] = useState(null);

  const handleAddConditions = ({ groupIndex, newConditionsList }) => {
    const groupArr = groupIndex?.split('/');
    const newData = { ...data };
    const newList = newConditionsList.map((el) => JSON.parse(JSON.stringify(config.types[el])));

    if (!groupArr) {
      newData.segments.push(...newList);
      newData.operator = config.lists.commun.operator[0];
    } else if (groupArr?.length === 3) {
      newData.segments[groupArr[0]].segments[groupArr[1]].segments = newData.segments[groupArr[0]].segments[
        groupArr[1]
      ].segments.filter((s) => !!s);

      newData.segments[groupArr[0]].segments[groupArr[1]].segments.push(...newList);
    } else {
      newData.segments[groupArr[0]].segments = newData.segments[groupArr[0]].segments.filter((s) => !!s);
      newData.segments[groupArr[0]].segments.push(...newList);
    }

    updateData(newData);
    setAddConditionsPanelButtonGroupIndex(null);
  };

  const handleDeleteCondition = (groupIndex) => {
    const groupArr = groupIndex.split('/').map((g) => parseInt(g));
    let newData = { ...data };

    if (groupArr.length === 1) {
      const otherIndex = groupArr[0] === 0 ? 1 : 0;
      if (newData.segments.length === 2 && !newData.segments[otherIndex]?.type) {
        newData = {
          operator: newData.segments[otherIndex].operator,
          segments: newData.segments[otherIndex].segments.filter((s) => !!s)
        };
      } else {
        newData.segments = newData.segments.filter((el, index) => index !== groupArr[0]);
      }
    }

    if (groupArr.length === 2) {
      const otherIndex = groupArr[1] === 0 ? 1 : 0;
      if (newData.segments[groupArr[0]].segments.length === 2) {
        newData.segments[groupArr[0]] = newData.segments[groupArr[0]].segments[otherIndex];
        newData.segments = newData.segments.filter((s) => !!s);

        // recheck level 1
        if (newData.segments.length === 1 && newData.segments[0]?.segments) {
          newData = {
            operator: newData.segments[0].operator,
            segments: newData.segments[0].segments
          };
        }
        newData.segments = newData.segments.filter((s) => !!s);
      } else {
        newData.segments[groupArr[0]].segments = newData.segments[groupArr[0]].segments.filter(
          (el, index) => index !== groupArr[1]
        );
      }
    }

    if (groupArr.length === 3) {
      const otherIndex = groupArr[2] === 0 ? 1 : 0;
      if (newData.segments[groupArr[0]].segments[groupArr[1]].segments.length === 2) {
        newData.segments[groupArr[0]].segments[groupArr[1]] =
          newData.segments[groupArr[0]].segments[groupArr[1]].segments[otherIndex];

        newData.segments[groupArr[0]].segments = newData.segments[groupArr[0]].segments.filter((s) => !!s);

        // re-check level 2
        if (
          newData.segments[groupArr[0]].segments.length === 1 &&
          newData.segments[groupArr[0]]?.segments[0]?.segments
        ) {
          newData.segments[groupArr[0]] = {
            operator: newData.segments[groupArr[0]].segments[0].operator,
            segments: newData.segments[groupArr[0]].segments[0].segments
          };
        }
      } else {
        newData.segments[groupArr[0]].segments[groupArr[1]].segments = newData.segments[groupArr[0]].segments[
          groupArr[1]
        ].segments.filter((el, index) => index !== groupArr[2]);
      }
    }

    updateData(newData);
  };

  const handleDeleteGroup = (groupIndex) => {
    const index = groupIndex.split('/').map((g) => parseInt(g))[0];
    let newData = { ...data, segments: [...data.segments.slice(0, index), ...data.segments.slice(index + 1)] };

    if (newData.segments.length === 1 && newData.segments?.[0]?.segments) {
      newData = newData.segments[0];

      if (newData.segments.length === 2 && newData.segments[1] === null) {
        newData.segments = [newData.segments[0]];
      }
    }
    updateData(newData);
  };

  const handleUpdateBlock = ({ groupIndex, value }) => {
    const groupArr = groupIndex.split('/');
    const newData = { ...data };

    if (groupArr.length === 1) {
      newData.segments[groupIndex] = value;
    }
    if (groupArr.length === 2) {
      newData.segments[groupArr[0]].segments[groupArr[1]] = value;
    }
    if (groupArr.length === 3) {
      newData.segments[groupArr[0]].segments[groupArr[1]].segments[groupArr[2]] = value;
    }

    updateData(newData);
  };

  const handleToggleOperator = ({ groupIndex }) => {
    const groupArr = groupIndex.split('/');
    const newData = { ...data };

    if (groupArr.length === 1) {
      newData.operator =
        data.operator === config.lists.commun.operator[0]
          ? config.lists.commun.operator[1]
          : config.lists.commun.operator[0];
    }
    if (groupArr.length === 2) {
      newData.segments[groupArr[0]].operator =
        data.segments[groupArr[0]].operator === config.lists.commun.operator[0]
          ? config.lists.commun.operator[1]
          : config.lists.commun.operator[0];
    }
    if (groupArr.length === 3) {
      newData.segments[groupArr[0]].segments[groupArr[1]].operator =
        data.segments[groupArr[0]].segments[groupArr[1]].operator === config.lists.commun.operator[0]
          ? config.lists.commun.operator[1]
          : config.lists.commun.operator[0];
    }

    updateData(newData);
  };

  const handleToggleDrawer = (groupIndex = null) => {
    setAddConditionsPanelButtonGroupIndex(isDrawerOpen ? null : groupIndex);
    setIsDrawerOpen((state) => !state);
  };

  const buildGroupIndex = ({ groupIndex, index }) => {
    return groupIndex ? `${groupIndex}/${index}` : index.toString();
  };

  const blockFactory = ({ groupIndex, segment }) => {
    const props = {
      config,
      groupIndex: groupIndex.toString(),
      onDeleteBlock: () => {
        handleDeleteCondition(groupIndex);
      },
      segment,
      updateData: (value) => {
        handleUpdateBlock({ groupIndex, value });
      }
    };

    switch (segment?.type?.toLowerCase()) {
      case 'frequency':
        return <Frequency {...props} />;
      case 'multipurchases':
        return <MultiPurchases {...props} />;
      case 'recency':
        return <Recency {...props} />;
      case 'value':
        return <Value {...props} />;

      default:
        return false;
    }
  };

  const createSubGroup = ({ groupIndex, segment }) => {
    const groupArr = groupIndex.split('/').map((g) => parseInt(g));
    let oppositeOperator;
    if (groupArr.length === 1) {
      oppositeOperator =
        data.operator === config.lists.commun.operator[0]
          ? config.lists.commun.operator[1]
          : config.lists.commun.operator[0];
    }
    if (groupArr.length === 2) {
      oppositeOperator =
        data.segments[groupArr[0]].operator === config.lists.commun.operator[0]
          ? config.lists.commun.operator[1]
          : config.lists.commun.operator[0];
    }
    const newGroup = { operator: oppositeOperator, segments: [segment, null] };

    handleUpdateBlock({ groupIndex, value: newGroup });
  };

  const groupFactory = ({ currData, groupIndex }) => {
    return (
      <>
        {currData.segments.map((el, index) => {
          const currGroupIndex = buildGroupIndex({ groupIndex, index });
          const currGroupArr = currGroupIndex.split('/');

          let classnameGroup = '';
          if (currGroupArr.length === 1) {
            classnameGroup = 'secondLevelSubGroup';
          }
          if (currGroupArr.length === 2) {
            classnameGroup = 'thirdLevelSubGroup';
          }

          return (
            <div className={styles['segmentBlock']} key={`segment-${el?.type || 'subGroup'}-${currGroupIndex}`}>
              {index !== 0 && (
                <div className={styles['button-operator']}>
                  <Operator
                    onClick={() => {
                      handleToggleOperator({ groupIndex: currGroupIndex });
                    }}
                    text={t(`_dyn_commun_select_operator_${currData.operator}`)}
                  />
                </div>
              )}
              {!el && (
                <div className={styles['segmentBlock-footer']}>
                  <div className={styles['with-delete-group-button']}>
                    <ButtonsCustom
                      classType="action_secondary"
                      key={`segment-add-conditions-button-${currGroupIndex}`}
                      method={() => {
                        handleToggleDrawer(currGroupIndex);
                      }}
                      size="thin"
                      startIconCustom={<AddIcon />}
                      text={t('segmentManager_button_add_condition_label')}
                    />

                    <div className={styles['crossIcon']}>
                      <ButtonsCustom
                        classType="icon_primary_small"
                        method={() => {
                          handleDeleteCondition(currGroupIndex);
                        }}
                        text={<CrossIcon />}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* recursive function for nested conditions */}
              {el && !el.type && (
                <div
                  className={`${styles['subGroup']} ${styles[classnameGroup]}`}
                  key={`segment-subGroup-${currGroupIndex}`}
                >
                  {groupFactory({ currData: el, groupIndex: currGroupIndex })}
                </div>
              )}

              {/* display condition */}
              {el?.type && blockFactory({ groupIndex: currGroupIndex, segment: el })}

              {/* display buttons */}
              {el &&
                (currGroupArr.length === 1 || el?.type) &&
                currData.segments[index + 1] !== null &&
                (currGroupArr.length !== 3 || index === currData.segments.length - 1) && (
                  <div
                    className={`${styles['segmentBlock-footer']} ${
                      currGroupArr.length === 1 ? styles['withTopBorder'] : ''
                    }`}
                  >
                    {currGroupArr.length !== 3 && el?.type ? (
                      <ButtonsCustom
                        classType="action_primary"
                        disabled={currData.segments.length === 1 && currGroupArr.length === 1}
                        method={() => {
                          createSubGroup({ groupIndex: currGroupIndex, segment: el });
                        }}
                        size="thin"
                        startIconCustom={<AddIcon />}
                        text={t('segmentManager_button_add_subGroup_label')}
                      />
                    ) : (
                      <div className={styles['with-delete-group-button']}>
                        <ButtonsCustom
                          classType="action_secondary"
                          disabled={
                            currGroupArr.length === 1 &&
                            data.segments[currGroupArr[0]]?.segments?.length === 2 &&
                            data.segments[currGroupArr[0]]?.segments?.[1] === null
                          }
                          method={() => {
                            handleToggleDrawer(currGroupIndex);
                          }}
                          size="thin"
                          startIconCustom={<AddIcon />}
                          text={t('segmentManager_button_add_condition_label')}
                        />
                        {currGroupArr.length === 1 ? (
                          <ButtonsCustom
                            classType="link_primary_small"
                            method={() => {
                              handleDeleteGroup(currGroupIndex);
                            }}
                            text={t('segmentManager_button_delete_group_label')}
                          />
                        ) : (
                          <div />
                        )}
                      </div>
                    )}
                  </div>
                )}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div className={styles['root']}>
      {!data.segments?.length && (
        <>
          <p className={styles['add-contidion-desc']}>
            <Trans i18nKey="segmentManager_button_add_condition_desc" />
          </p>
          <ButtonsCustom
            classType="action_primary"
            method={() => {
              handleToggleDrawer(null);
            }}
            startIconCustom={<AddIcon />}
            text={t('segmentManager_button_add_condition_label')}
          />
        </>
      )}

      {!!data.segments?.length && (
        <div className={`${styles['subGroup']} ${data.segments?.length > 1 ? styles['firstLevelSubGroup'] : ''}`}>
          {groupFactory({ currData: data, groupIndex: '' })}
          <ButtonsCustom
            classType="action_secondary_big"
            method={() => {
              handleToggleDrawer(null);
            }}
            startIconCustom={<AddIcon />}
            text={t('segmentManager_button_add_condition_label')}
          />
        </div>
      )}

      <Drawer isOpen={isDrawerOpen} onClose={handleToggleDrawer}>
        <AddConditionsPanel
          groupIndex={addConditionsPanelButtonGroupIndex}
          onClose={handleToggleDrawer}
          onSubmit={handleAddConditions}
          scope={scope}
        />
      </Drawer>
    </div>
  );
};

MainBlock.propTypes = {
  data: PropTypes.shape({ operator: PropTypes.string, segments: PropTypes.arrayOf(PropTypes.object) }),
  scope: PropTypes.string,
  updateData: PropTypes.func.isRequired
};

export default MainBlock;

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NON_POSITIONED_BUDGET_THRESHOLD_PERIODS } from 'utils/constants';
import { scrollToElementById } from 'utils/global';

import FormManager from 'components/FormManager';
import ValuePerMonthInput from './ValuePerMonthInput';

import { positionedTargetsLevelType } from 'types/positionedTargetsLevels';

interface IState {
  positionedTargetsLevels: positionedTargetsLevelType[];
}

type Props = {
  data: positionedTargetsLevelType[];
  onChangeState: (value: IState) => void;
};

enum MONTHS {
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER'
}

const ObjectivesThresholds = forwardRef(({ data, onChangeState }: Props, ref): JSX.Element => {
  const { t } = useTranslation();
  const [hasError, setHasError] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    checkHasError() {
      return handleCheckError();
    }
  }));

  const handleCheckError = () => {
    let errorList = [];
    const startYearObj = data.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.START_OF_YEAR);
    const halfYearObj = data.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.INTERMEDIATE);
    const endYearObj = data.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.END_OF_YEAR);

    const startYearValue = startYearObj?.value;
    const halfYearValue = halfYearObj?.value;
    const endYearValue = endYearObj?.value;

    const startYearMonth = startYearObj?.month;
    const halfYearMonth = halfYearObj?.month;
    const endYearMonth = endYearObj?.month;

    // check if is out of range 0 - 100
    if (
      (startYearValue && !isNaN(startYearValue) && (startYearValue < 0 || startYearValue > 100)) ||
      (halfYearValue && !isNaN(halfYearValue) && (halfYearValue < 0 || halfYearValue > 100)) ||
      (endYearValue && !isNaN(endYearValue) && (endYearValue < 0 || endYearValue > 100))
    ) {
      errorList.push(true);
    }

    // values must be startYearValue > halfYearValue > endYearValue
    if (
      ((startYearValue || startYearValue === 0) &&
        (halfYearValue || halfYearValue === 0) &&
        startYearValue < halfYearValue) ||
      ((startYearValue || startYearValue === 0) &&
        (endYearValue || endYearValue === 0) &&
        startYearValue < endYearValue) ||
      ((halfYearValue || halfYearValue === 0) && (endYearValue || endYearValue === 0) && halfYearValue < endYearValue)
    ) {
      errorList.push(true);
    }

    // must be pairs of filled value and month
    if (
      ((startYearValue || startYearValue === 0) && !startYearMonth) ||
      (!startYearValue && startYearValue !== 0 && startYearMonth) ||
      ((halfYearValue || halfYearValue === 0) && !halfYearMonth) ||
      (!halfYearValue && halfYearValue !== 0 && halfYearMonth) ||
      ((endYearValue || endYearValue === 0) && !endYearMonth) ||
      (!endYearValue && endYearValue !== 0 && endYearMonth)
    ) {
      errorList.push(true);
    }

    if (errorList.length) {
      setTimeout(() => {
        scrollToElementById('creation-form-objectives-thresholds-block-id');
      }, 150);
    }

    setHasError(!!errorList.length);
    return !!errorList.length;
  };

  const handleStateChange = (d: positionedTargetsLevelType) => {
    const currentDataIndex = data.findIndex((el) => el.period === d.period);
    if (currentDataIndex < 0) return false;

    const newData = JSON.parse(JSON.stringify(data));
    newData[currentDataIndex] = { ...d };

    // reset period value if month is canceled
    if (!d.month && data[currentDataIndex]?.month) {
      newData[currentDataIndex] = { month: null, period: d.period, value: null };
    }
    setHasError(false);
    onChangeState({ positionedTargetsLevels: newData });
  };

  const getStartYearRangeMonths = (): number[] => {
    let valEnd = 12;
    const foundMonthEnd =
      data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.INTERMEDIATE)?.month ||
      data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.END_OF_YEAR)?.month;
    if (foundMonthEnd) {
      valEnd = Math.max(0, MONTHS[foundMonthEnd]);
    }
    return [0, valEnd];
  };

  const getHalfYearRangeMonths = (): number[] => {
    let valStart = 0;
    let valEnd = 12;
    const foundMonthStart = data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.START_OF_YEAR)
      ?.month;
    const foundMonthEnd = data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.END_OF_YEAR)?.month;
    if (foundMonthStart) {
      valStart = MONTHS[foundMonthStart] + 1;
    }
    if (foundMonthEnd) {
      valEnd = Math.max(0, MONTHS[foundMonthEnd]);
    }
    return [valStart, valEnd];
  };

  const getEndYearRangeMonths = (): number[] => {
    let valStart = 0;
    const foundMonthStart =
      data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.INTERMEDIATE)?.month ||
      data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.START_OF_YEAR)?.month;
    if (foundMonthStart) {
      valStart = MONTHS[foundMonthStart] + 1;
    }
    return [valStart, 12];
  };

  return (
    <FormManager
      id="creation-form-objectives-thresholds-block-id"
      data={{
        title: t('retailers_creation_nonPositioned_budget_objectives_thresholds'),
        subTitle: t('retailers_creation_nonPositioned_budget_objectives_thresholds_desc'),
        fieldsets: [
          {
            id: 'objectives-thresholds',
            fields: [
              {
                type: <ValuePerMonthInput />,
                label: t('retailers_creation_nonPositioned_budget_objective_startYear_label'),
                defaultValue: {
                  month:
                    data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.START_OF_YEAR)?.month ||
                    '',
                  period: NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.START_OF_YEAR,
                  value:
                    data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.START_OF_YEAR)?.value ?? ''
                },
                rangeMonths: getStartYearRangeMonths(),
                onFieldChange: handleStateChange,
                id: 'START_OF_YEAR'
              },
              {
                type: <ValuePerMonthInput />,
                label: t('retailers_creation_nonPositioned_budget_objective_halfYear_label'),
                defaultValue: {
                  month:
                    data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.INTERMEDIATE)?.month || '',
                  period: NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.INTERMEDIATE,
                  value:
                    data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.INTERMEDIATE)?.value ?? ''
                },
                rangeMonths: getHalfYearRangeMonths(),
                onFieldChange: handleStateChange,
                id: 'INTERMEDIATE'
              },
              {
                type: <ValuePerMonthInput />,
                label: t('retailers_creation_nonPositioned_budget_objective_endYear_label'),
                defaultValue: {
                  month:
                    data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.END_OF_YEAR)?.month || '',
                  period: NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.END_OF_YEAR,
                  value:
                    data?.find((el) => el.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.END_OF_YEAR)?.value ?? ''
                },
                rangeMonths: getEndYearRangeMonths(),
                onFieldChange: handleStateChange,
                id: 'END_OF_YEAR'
              },
              {
                display: hasError,
                type: 'CustomContent',
                id: 'objectives-thresholds-error-message',
                classnames: ['objectives-thresholds-error-message'],
                component: <div>{t('retailers_creation_nonPositioned_budget_objective_threshold_error_message')}</div>
              }
            ]
          }
        ]
      }}
    />
  );
});

export default ObjectivesThresholds;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isOutOfRangePast18Month as isOutOfRange } from '../utils';
import FormManager from 'components/FormManager';
import HeaderBlock from './_HeaderBlock';

import styles from './ConditionsForm.module.scss';

const FrequencyBlock = ({ config, groupIndex, onDeleteBlock, segment, updateData }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles['mainContainer']} `}>
      <HeaderBlock onDelete={onDeleteBlock} segment={segment} />
      <FormManager
        data={{
          classnames: [styles['customForm']],
          fieldsets: [
            {
              id: 'segment-manager-frequency-line-1',
              classnames: [styles['one-line']],
              fields: [
                {
                  type: 'Select',
                  classnames: [styles['input'], styles['input-small']],
                  defaultValue: segment.condition.sign || config.lists.frequency.sign[0],
                  fieldProps: {
                    listData: config.lists.frequency.sign.map((el) => ({
                      id: el,
                      label: t(`_dyn_commun_select_sign_${el}`)
                    })),
                    shrinkPlaceholder: false
                  },
                  id: `segment-${segment.type}-sign-${groupIndex}`,
                  onFieldChange: (value) => {
                    const newSegment = JSON.parse(JSON.stringify(segment));
                    newSegment.condition.sign = value;

                    // update value when sign changed from '$in' to other signs
                    if (newSegment.condition.value !== null) {
                      if (newSegment.condition.value?.length && segment.condition.sign === '$in' && value !== '$in') {
                        if (newSegment.condition.value.length > 1) {
                          // reset value when there were multiple values with '$in' sign
                          newSegment.condition.value = null;
                        } else {
                          // insert unique '$in' value into other signs value
                          newSegment.condition.value = newSegment.condition.value[0];
                        }
                      } else if (segment.condition.sign !== '$in' && value === '$in') {
                        // update value when sign changed from other signs to '$in'
                        // insert other signs value into '$in' value
                        newSegment.condition.value = [newSegment.condition.value];
                      }
                    }

                    updateData(newSegment);
                  }
                },
                {
                  type: 'AutocompleteTag',
                  classnames: [styles['input'], styles['input-last']],
                  outerContainerClass: [styles['input-outerContainer']],
                  defaultValue:
                    typeof segment.condition.value === 'number' || segment.condition.value
                      ? segment.condition.sign === '$in'
                        ? segment.condition.value
                        : [segment.condition.value]
                      : [],
                  fieldProps: {
                    freeSolo: true,
                    inputType: 'number',
                    multipleValue: segment.condition.sign === '$in',
                    valueToLabel: (value) => t('commun_x_times', { count: parseInt(value) || 0 })
                  },
                  id: `segment-${segment.type}-value-${groupIndex}`,
                  onFieldChange: (value) => {
                    const newSegment = JSON.parse(JSON.stringify(segment));
                    newSegment.condition.value = value.length
                      ? segment.condition.sign === '$in'
                        ? [...new Set(value.map((n) => parseInt(n)))] // restore uniqueness because last input value from AutocompleteTag is a String
                        : parseInt(value[0])
                      : null;
                    updateData(newSegment);
                  },
                  placeholder: t('commun_x_times_input_placeholder')
                }
              ]
            },
            {
              id: 'segment-manager-frequency-line-2',
              classnames: [styles['one-line']],
              fields: [
                {
                  type: 'TextField',
                  classnames: [styles['input'], styles['input-small']],
                  defaultValue: t('commun_period_over'),
                  fieldProps: {
                    readOnly: true,
                    shrinkPlaceholder: false
                  },
                  id: `segment-${segment.type}-period-static-${groupIndex}`
                },
                {
                  type: 'NumberField',
                  allowZero: false,
                  classnames: [styles['input'], styles['input-small']],
                  defaultValue: segment.period.value.count || '',
                  fieldProps: {
                    InputProps: {
                      min: 0
                    },
                    shrinkPlaceholder: false
                  },
                  id: `segment-${segment.type}-period-count-${groupIndex}`,
                  onFieldChange: (value) => {
                    const newSegment = JSON.parse(JSON.stringify(segment));
                    newSegment.period.value.count = value;

                    // reset interval Select if count is out of range
                    if (isOutOfRange({ count: value, interval: segment.period.value.interval })) {
                      newSegment.period.value.interval = null;
                    }

                    updateData(newSegment);
                  },
                  placeholder: t('commun_input_number_placeholder')
                },
                {
                  type: 'Select',
                  classnames: [styles['input'], styles['input-last']],
                  outerContainerClass: [styles['input-outerContainer']],
                  defaultValue: segment.period.value.interval,
                  fieldProps: {
                    listData: config.lists.commun.interval.map((el) => ({
                      id: el,
                      label: t(`_dyn_commun_select_interval_last_${el}`, { count: segment.period.value.count || 0 }),
                      disabled: isOutOfRange({ count: segment.period.value.count, interval: el })
                    })),
                    placeHolderText: t('_dyn_commun_select_interval_placeholder'),
                    shrinkPlaceholder: false
                  },
                  id: `segment-${segment.type}-period-interval-${groupIndex}`,
                  onFieldChange: (value) => {
                    const newSegment = JSON.parse(JSON.stringify(segment));
                    newSegment.period.value.interval = value;
                    updateData(newSegment);
                  }
                }
              ]
            }
          ]
        }}
      />
    </div>
  );
};

FrequencyBlock.propTypes = {
  config: PropTypes.object.isRequired,
  groupIndex: PropTypes.string.isRequired,
  onDeleteBlock: PropTypes.func.isRequired,
  segment: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired
};

export default memo(FrequencyBlock);

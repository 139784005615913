import React, { useState, useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { store } from 'store';
import { snackbarTypes } from 'utils/constants';
import { applyBudgetRecommendation, getOffer } from 'api';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';

import { ReactComponent as Overperformed } from 'assets/overperformed.svg';
import { ReactComponent as Underperformed } from 'assets/underperformed.svg';
import { ReactComponent as Arrowgreen } from 'assets/arrowgreen.svg';
import { ReactComponent as Arrowred } from 'assets/arrowred.svg';

import './OfferBudgetRecommendation.scss';

const OfferBudgetRecommendation = ({ budgetRecommendation, budgetTarget, currency, onUpdated }) => {
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { t } = useTranslation();

  const offerDetails = state.offers.details.offer;
  const freeBudget = offerDetails.offerHead.budget.free;
  const supplierName = offerDetails.offerHead.budget.supplier.name;

  const recommendedBudget = budgetRecommendation.recommendedBudget;
  const differenceTargetAndRecommendedBudget = recommendedBudget - budgetTarget;
  const isOverperformed = differenceTargetAndRecommendedBudget > 0;
  const isOverperformedLabel = isOverperformed ? 'overperformed' : 'underperformed';
  const classType = isOverperformed ? 'activeGreen' : 'activeRed';

  const recommendedBudgetLabel = {
    value: recommendedBudget,
    currency,
  };

  const budgetTargetLabel = {
    value: budgetTarget,
    currency,
  };
  const differenceTargetAndRecommendedBudgetLabel = {
    value: Math.abs(differenceTargetAndRecommendedBudget),
    currency,
  };
  const title = t(
    isOverperformed ? 'offer_details_budget_overperformed' : 'offer_details_budget_underperformed',
    budgetTargetLabel
  );

  const buttonLabel = isOverperformed
    ? t('offer_details_add_budget', differenceTargetAndRecommendedBudgetLabel)
    : t('offer_details_release_budget', differenceTargetAndRecommendedBudgetLabel);

  const dialogLabel = isOverperformed
    ? t('offer_details_add_budget_recommended_to_target_budget', differenceTargetAndRecommendedBudgetLabel)
    : t('offer_details_release_budget_recommended_to_target_budget', differenceTargetAndRecommendedBudgetLabel);

  const statIcon = isOverperformed ? <Overperformed /> : <Underperformed />;
  const arrowIcon = isOverperformed ? <Arrowgreen /> : <Arrowred />;

  const [offerIsUpdating, setOfferIsUpdating] = useState(false);
  const [dialogOpened, setDialogOpen] = useState(false);

  const handleChangeOfferBudget = async (newBudgetTarget) => {
    if (isOverperformed && freeBudget < newBudgetTarget) {
      dispatch({
        type: 'UI_SNACKBAR',
        payload: {
          type: snackbarTypes.WARNING,
          title: {
            key: t('offers_creation_budget_insufficient'),
          },
          desc: { key: 'offer_details_free_budget_is_not_enough' },
        },
      });
      return false;
    }
    setDialogOpen(true);
  };

  const handleDialogConfirmUpdate = async () => {
    setOfferIsUpdating(true);

    const updated = await applyBudgetRecommendation({
      offerHeadId: offerDetails.offerHead.id,
      recommendationId: budgetRecommendation.id,
    });

    if (updated) {
      const newOffer = await getOffer(offerDetails.offerHead.id);
      onUpdated();
      dispatch({
        type: 'UI_SNACKBAR',
        payload: {
          type: snackbarTypes.SUCCESS,
          title: {
            key: isOverperformed
              ? t('offer_details_target_budget_has_been_increased')
              : t('offer_details_target_budget_has_been_reduced'),
          },
          desc: {
            key: isOverperformed
              ? t('offer_details_new_remaining_budget_to_position', {
                  value: newOffer.offerHead.budget.free,
                  currency,
                  text: supplierName,
                })
              : t('offer_details_new_remaining_budget_to_release', {
                  value: newOffer.offerHead.budget.reserved,
                  currency,
                  text: supplierName,
                }),
          },
        },
      });
    }

    setOfferIsUpdating(false);
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <div className={`${isOverperformedLabel} budget-recommendation`}>
      <div className="arrow">{arrowIcon}</div>
      <div className="statIcon"> {statIcon}</div>
      <div className={`budget-recommendation-content`}>
        <span>{title}</span>
        <div className="budget-recommendation-description">
          <span className={`${isOverperformedLabel}-content-title`}>{t('offer_details_recommended_budget_label')}</span>
          <span>{t('commun_price', recommendedBudgetLabel)}</span>
        </div>
        <ButtonsCustom
          classType={classType}
          text={buttonLabel}
          size="small"
          disabled={offerIsUpdating}
          method={() => handleChangeOfferBudget(recommendedBudget)}
        />

        <ContentDialog
          centerText
          isOpen={dialogOpened}
          handleClose={handleCloseDialog}
          maxWidth="xs"
          title={dialogLabel}
        >
          <div>
            <div className="text-dialog">
              <span>{t('offer_details_new_budget', recommendedBudgetLabel)}</span>
              <span>{t('commun_price', recommendedBudgetLabel)}</span>
            </div>
            <div>
              <ButtonsCustom classType="canceled" text={t('commun_button_cancel')} method={handleCloseDialog} />
              <ButtonsCustom
                classType="action_primary_big"
                text={t('commun_button_yes_valid')}
                method={handleDialogConfirmUpdate}
              />
            </div>
          </div>
        </ContentDialog>
      </div>
    </div>
  );
};

OfferBudgetRecommendation.propTypes = {
  budgetRecommendation: PropTypes.object,
  budgetTarget: PropTypes.number,
  currency: PropTypes.string.isRequired,
  onUpdated: PropTypes.func,
};

export default memo(OfferBudgetRecommendation);

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CoinIcon } from 'assets/coin.svg';
import { ReactComponent as NoResultIcon } from 'assets/no_result.svg';
import EmptyImage from 'assets/illu_petit_robot.png';

import styles from './DashboardSupplier.module.scss';

type Props = {
  hasAtLeastOneFilter: boolean;
  isRetailerUser?: boolean;
  year: number;
};

const NoData = ({ hasAtLeastOneFilter, isRetailerUser, year }: Props): JSX.Element => {
  const { t } = useTranslation();

  if (!!isRetailerUser) {
    if (hasAtLeastOneFilter) {
      return (
        <div className={styles['no-data']}>
          <div>
            <NoResultIcon />
            <p>{t('commun_no_budget_filters_result')}</p>
            <p>{t('commun_no_budget_filters_result_desc')}</p>
          </div>
        </div>
      );
    }
    return (
      <div className={styles['no-data']}>
        <div>
          <img src={EmptyImage} alt="no selected filter" />
          <p>{t('commun_select_a_filter')}</p>
          <p>{t('dashboardRetailer_select_a_filter')}</p>
        </div>
      </div>
    );
  }

  // supplier homepage view
  return (
    <div className={styles['no-data']}>
      <div>
        <CoinIcon />
        <p>{t('commun_no_budget_short')}</p>
        <p>{t('dashboard_budget_no_data_by_year', { year })}</p>
        <br />
        <p>{t('dashboard_budhet_no_data_by_year_desc')}</p>
      </div>
    </div>
  );
};

export default NoData;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { validateEmail } from 'utils/global';
import ButtonsCustom from 'components/ButtonsCustom';
import EmailsList from 'components/EmailsList';
import TextFieldCustom from 'components/TextFieldCustom';

import { ReactComponent as EmailArrow } from 'assets/14px_arrow.svg';
import styles from './UsersList.module.scss';

const UsersList = (props) => {
  const {
    disabled,
    data: { id, defaultValue = [], fieldProps, openEditForm, requiredErrorMsg },
    onChange
  } = props;
  const { t } = useTranslation();
  const [emails, setEmails] = useState([]);
  const [emailInput, setEmailInput] = useState([]);
  const [errorEmailFormat, setErrorEmailFormat] = useState();
  const [errorEmailAlreadyExist, setErrorEmailAlreadyExist] = useState();
  const [errorEmailNotAdded, setErrorEmailNotAdded] = useState(false);

  useEffect(() => {
    if (JSON.stringify(defaultValue) !== JSON.stringify(emails)) {
      setEmails(defaultValue);
      onChange(id, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const checkErrorReminder = () => {
    if (emailInput) {
      setErrorEmailNotAdded(true);
    }
  };

  const displayErrorMessage = () => {
    if (errorEmailAlreadyExist) return t('supplier_email_already_exist');
    else if (errorEmailFormat) return t('supplier_invalid_email');
    else if (requiredErrorMsg) return requiredErrorMsg;
    else if (errorEmailNotAdded) return t('supplier_input_validation_reminder');
    else return '';
  };

  const handleRemove = (item) => {
    const list = emails.filter((i) => i.email !== item.email);
    onChange(id, list);
    setEmails(list);
  };

  const handleAddEmail = () => {
    if (disabled) return;
    if (emails.length && emails.map((x) => x.email).includes(emailInput)) {
      setErrorEmailAlreadyExist(true);
      return;
    } else {
      setErrorEmailAlreadyExist(false);
    }

    if (emailInput && validateEmail(emailInput)) {
      const list = [...emails, { email: emailInput }];
      onChange(id, list);
      setEmails(list);
      setEmailInput('');
      setErrorEmailFormat(false);
      setErrorEmailNotAdded(false);
    } else {
      setErrorEmailFormat(true);
    }
  };

  const handleSetEmail = (e) => {
    setEmailInput(e.target.value.trim());
    if (!e.target.value.trim()) {
      setErrorEmailFormat(false);
      setErrorEmailNotAdded(false);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      handleAddEmail();
    }
  };

  return (
    <>
      <div className={styles['select-list']}>
        <TextFieldCustom
          id="emailInput"
          type="text"
          label={t('supplier_email')}
          onBlur={checkErrorReminder}
          onChange={handleSetEmail}
          value={emailInput}
          error={!!requiredErrorMsg || errorEmailFormat || errorEmailAlreadyExist || errorEmailNotAdded}
          errorMsg={displayErrorMessage()}
          onKeyUp={handleKeyUp}
          InputProps={{
            disabled: disabled,
            endAdornment: (
              <ButtonsCustom
                classType="icon_secondary_small"
                id="add_email"
                method={handleAddEmail}
                startIconCustom={<EmailArrow />}
              />
            )
          }}
        />
      </div>
      {fieldProps?.showTitle && emails?.length ? (
        <span className={styles['list-title']}>{t('supplier_list_title')}</span>
      ) : (
        ''
      )}
      {!!emails.length && <EmailsList emails={emails} handleRemove={handleRemove} openEditForm={openEditForm} />}
    </>
  );
};

UsersList.propTypes = {
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default UsersList;

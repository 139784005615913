import React from 'react';
import PropTypes from 'prop-types';
import spinner from 'assets/spinner.png';
import styles from './Spinner.module.scss';

const Spinner = ({ className }) => {
  return <img className={`${styles['root']} ${className || ''}`} src={spinner} alt="spinner loader" />;
};

Spinner.propTypes = {
  className: PropTypes.string,
};

export default Spinner;

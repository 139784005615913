import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { deleteCookie, getCookie, setCookie } from 'utils/cookies';

import ButtonsCustom from 'components/ButtonsCustom';
import CheckboxCustom from 'components/CheckboxCustom';
import Logo from 'components/Logos';
import TextFieldCustom from 'components/TextFieldCustom';

import { ReactComponent as ArrowIcon } from 'assets/14px_arrow.svg';
import { ReactComponent as ConnexionBg } from 'assets/connexion_bg.svg';
import { ReactComponent as DecoStripes } from 'assets/deco_login.svg';
import { ReactComponent as UserIcon } from 'assets/user_login.svg';
import { ReactComponent as LockIcon } from 'assets/lock.svg';

import styles from './LoginForm.module.scss';

const LoginForm = ({ postLoginRequest, tokenIsSet, error, onInputChange }) => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const hasRememberMeChoice = getCookie('remember-me') === 'true';
    setRememberMe(hasRememberMeChoice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyUp = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      postLoginRequest({ login, password, rememberMe });
    }
  };

  const handleRememberMe = () => {
    const newState = !rememberMe;
    setRememberMe(newState);

    if (newState === true) {
      setCookie({ name: 'remember-me', value: 'true', maxAge: 7 * 24 * 60 * 60 });
    } else {
      deleteCookie('remember-me');
    }
  };

  return !tokenIsSet ? (
    <div className={styles['login-container']}>
      <div className={styles['login-container-left']}>
        <div className={styles['login-container-left-wrapper']}>
          <Logo />

          <>
            <span className={styles['label']}>{t('commun_welcome')}</span>
            <span className={styles['label-grey']}>{t('commun_welcome_on')}</span>
            <div className={styles['text-field-container']}>
              <TextFieldCustom
                InputProps={{
                  endAdornment: <UserIcon />
                }}
                onChange={(e) => {
                  onInputChange();
                  setLogin(e.target.value);
                }}
                label={t('login_label')}
                error={!!error}
                onKeyUp={handleKeyUp}
                errorMsg={t('commun_api_error_login')}
              />
              <TextFieldCustom
                InputProps={{
                  endAdornment: <LockIcon />
                }}
                onChange={(e) => {
                  onInputChange();
                  setPassword(e.target.value);
                }}
                label={t('login_password')}
                type="password"
                error={!!error}
                onKeyUp={handleKeyUp}
                errorMsg={t('commun_api_error_login')}
              />
            </div>
            <div className={styles['remember-me-container']}>
              <FormControlLabel
                control={
                  <CheckboxCustom idText={'remember-me-checkbox'} checked={rememberMe} onChange={handleRememberMe} />
                }
                label={t(`login_remember_me`)}
              />
            </div>
            <div className={styles['button-container']}>
              <ButtonsCustom
                classType="action_primary_big"
                endIconCustom={<ArrowIcon />}
                text={t('login_button_label')}
                method={() => postLoginRequest({ login, password, rememberMe })}
                disabled={!login || !password}
                error={!!error}
              />
              <ButtonsCustom
                classType="link_primary"
                text={t('lost_password')}
                method={() =>
                  window.open(
                    `${window.REACT_APP_KEYCLOAK_URL}/realms/${window.REACT_APP_KEYCLOAK_REALM}/login-actions/reset-credentials?client_id=app`,
                    '_blank'
                  )
                }
              />
            </div>
          </>
        </div>
        <div className={styles['deco-stripes']}>
          <DecoStripes />
        </div>
      </div>
      <div className={styles['login-container-right']}>
        <ConnexionBg />

        <div className={styles['legals-container']}>
          <Link to={'/legal-mentions'} role="link">
            {t('legals_legal_mentions')}
          </Link>
          {'|'}
          <Link to={'/privacy-policy'} role="link">
            {t('legals_privacy_policy')}
          </Link>
        </div>
      </div>
    </div>
  ) : null;
};

LoginForm.propTypes = {
  error: PropTypes.string,
  onInputChange: PropTypes.func,
  postLoginRequest: PropTypes.func,
  tokenIsSet: PropTypes.bool
};

export default LoginForm;

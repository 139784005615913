import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/22px_croix.svg';
import styles from './Drawer.module.scss';

const DrawerCustom = ({ children, isOpen, onClose, onCloseFinished, small }) => (
  <Drawer
    transitionDuration={500}
    SlideProps={{ onExited: onCloseFinished }}
    anchor="right"
    open={isOpen}
    onClose={onClose}
    BackdropProps={{ className: styles['backdrop'] }}
  >
    <div className={`${styles['drawer']} ${small ? styles['small'] : ''}`}>
      <div className={styles['close-icon']} id="drawer-close-button" onClick={onClose} role="button">
        <CloseIcon />
      </div>
      <div className={styles['children']}>{children}</div>
    </div>
  </Drawer>
);

DrawerCustom.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCloseFinished: PropTypes.func,
  small: PropTypes.bool,
};

export default DrawerCustom;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { ResponsiveBar } from '@nivo/bar';
import { withFractionDigits } from 'utils/global';
import Loader from 'components/Loaders/Dots';

import { ReactComponent as NoResult } from 'assets/no_result.svg';

import styles from './GraphBar.module.scss';

const DEFAULT_COLORS = ['#ccf4d5', '#c8dfff'];

const GraphBar = ({ colors = DEFAULT_COLORS, data, hasEmptyData, indexKey, isLoading, valueKeys }) => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div className={styles['root']}>
        <Loader />
        <p className={styles['loading-msg']}>{t('commun_loading')}</p>
      </div>
    );
  }

  if (hasEmptyData) {
    return (
      <div className={styles['root']}>
        <NoResult />
        <p className={styles['no-result-msg']}>{t('commun_no_budget_spent')}</p>
      </div>
    );
  }

  const renderTooltip = ({ data, id, value }) => {
    return (
      <div className={styles['custom-tooltip']}>
        {data.tooltipParams?.params ? (
          <div>
            <Trans i18nKey={data.tooltipParams.i18nKey} values={data.tooltipParams.params[id]} />
          </div>
        ) : (
          t('commun_number', { value, maximumFractionDigits: withFractionDigits(value) })
        )}
      </div>
    );
  };

  return (
    <div className={styles['root']}>
      <ResponsiveBar
        data={data}
        keys={valueKeys}
        indexBy={indexKey}
        margin={{ top: 20, right: 10, bottom: 30, left: 90 }}
        innerPadding={2}
        padding={valueKeys?.length > 1 ? 0.3 : 0.5}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={colors}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          legend: '',
          tickPadding: 8,
          tickSize: 0
        }}
        axisLeft={{
          legend: '',
          tickPadding: 20,
          tickSize: 0
        }}
        role="application"
        enableLabel={false}
        tooltip={renderTooltip}
        groupMode="grouped"
        borderRadius={6}
        width={Math.max(720, data.length * 50)}
      />
    </div>
  );
};

GraphBar.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.array,
  hasEmptyData: PropTypes.bool,
  indexKey: PropTypes.string,
  isLoading: PropTypes.bool,
  valueKeys: PropTypes.array
};
export default GraphBar;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { checkNumber } from 'utils/global';

import TextFieldCustom from 'components/TextFieldCustom';

const NumberField = (props) => {
  const {
    className,
    data: { id, defaultValue = '', placeholder, allowZero, maximumFractionDigits = 0, fieldProps, disabled },
    onChange,
    error,
  } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) onChange(id, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleBlur = (value) => {
    let valueParsed = checkNumber(value) ? parseInt(value) : '';
    if (maximumFractionDigits) {
      valueParsed = checkNumber(value) ? parseFloat(value) : '';
    }
    setValue(valueParsed);
  };

  const handleChange = (value) => {
    let needDispatch = false;
    let valueParsed = '';

    if (maximumFractionDigits) {
      if (checkNumber(value) || value === '') {
        valueParsed = value.replace(',', '.');
        needDispatch = true;
        const fractionsLength = valueParsed.split('.')?.[1]?.length;

        if (fractionsLength && fractionsLength > maximumFractionDigits) {
          needDispatch = false;
        }
      }
    } else {
      valueParsed = parseInt(value) >= 0 ? parseInt(value) : '';
      needDispatch = valueParsed || valueParsed === '';

      if (allowZero) {
        needDispatch = valueParsed >= 0 || valueParsed === '';
      }
    }

    if (needDispatch) {
      setValue(valueParsed);
    }
  };

  return (
    <TextFieldCustom
      className={className}
      label={placeholder}
      type="text"
      onBlur={(ev) => handleBlur(ev.target.value)}
      onChange={(ev) => handleChange(ev.target.value)}
      value={value}
      name={id}
      id={id}
      error={!!error}
      errorMsg={error}
      disabled={disabled}
      {...fieldProps}
    />
  );
};

NumberField.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
};

export default NumberField;

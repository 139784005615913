import React, { memo, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { sendEmailFeedback } from 'api';
import { store } from 'store';
import { snackbarTypes } from 'utils/constants';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import FormManager from 'components/FormManager';

import styles from './SendFeedbackEmail.module.scss';

const MAX_INPUT_LENGTH = 500;

const SendFeedbackEmail = ({ emailObject, emailTemplateId }) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(store);
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setText('');
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleSendEmail = async () => {
    setIsLoading(true);
    const responseOK = await sendEmailFeedback({ content: text, title: emailObject, templateId: emailTemplateId });
    setIsLoading(false);

    if (responseOK) {
      handleClose();
      dispatch({
        type: 'UI_SNACKBAR',
        payload: {
          type: snackbarTypes.SUCCESS,
          title: { key: 'commun_feedback_email_snackbar_title' },
          desc: { key: 'commun_feedback_email_snackbar_desc' },
        },
      });
    }
  };

  return (
    <>
      <ButtonsCustom classType="link_primary_small" method={handleOpen} text={t('commun_send_feedback_email')} />
      <ContentDialog
        isOpen={isOpen}
        handleClose={handleClose}
        title={t('commun_feedback_email_title')}
        bigTitle
        maxWidth="xs"
      >
        <div className={styles['root']}>
          <div className={styles['desc']}>{t('commun_feedback_email_desc')}</div>
          <FormManager
            data={{
              fieldsets: [
                {
                  id: 'segment-manager-title-and-desc',
                  fields: [
                    {
                      fieldProps: {
                        inputProps: {
                          minLength: 0,
                          maxLength: MAX_INPUT_LENGTH,
                          rows: 3,
                        },
                        multiline: true,
                      },
                      id: 'email-content',
                      defaultValue: text,
                      onFieldChange: setText,
                      type: 'TextField',
                    },
                  ],
                },
              ],
            }}
          />
          <div className={styles['form-button']}>
            <ButtonsCustom
              classType="action_primary_big"
              disabled={!text}
              loading={isLoading}
              method={handleSendEmail}
              text={t('commun_send')}
            />
          </div>
        </div>
      </ContentDialog>
    </>
  );
};

SendFeedbackEmail.propTypes = { emailObject: PropTypes.string, emailTemplateId: PropTypes.number };

export default memo(SendFeedbackEmail);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import Loader from 'components/Loaders/Dots';
import TextFieldCustom from 'components/TextFieldCustom';

import { ReactComponent as ClearIcon } from 'assets/22px_croix.svg';
import { ReactComponent as DropdownArrowIcon } from 'assets/dropdown_arrow.svg';
import './AutocompleteCustom.scss';

const AutocompleteSelect = ({
  className,
  disablePortal,
  disabled,
  error = false,
  id,
  isLoading,
  labelAttribute = 'label',
  listData,
  loadingText,
  noOptionsText,
  onSelection,
  placeholderText,
  useCustomSegmentOption,
  value = null
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  return (
    <Autocomplete
      classes={{
        root: `autocomplete-custom autocompleteSelect ${className}`,
        paper: 'autocomplete-custom-paper paper-option-fullWidth',
        clearIndicator: 'clearIcon',
        clearIndicatorDirty: 'clearIconDirty',
        popupIndicator: 'popupIcon',
        endAdornment: 'autocomplete-custom-endAdornment',
        noOptions: 'autocomplete-custom-noOptions',
        listbox: 'autocomplete-custom-listBox',
        option: 'autocomplete-custom-option'
      }}
      clearIcon={
        <div className="clearIcon" role="button">
          <ClearIcon />
        </div>
      }
      clearText=""
      closeText=""
      disabled={disabled}
      disablePortal={disablePortal}
      forcePopupIcon
      getOptionLabel={(option) => option[labelAttribute]}
      isOptionEqualToValue={(option, val) => option.id === val.id}
      id={`autocomplete-select-${id}`}
      inputValue={inputValue}
      loading={isLoading}
      loadingText={
        <div className="loader-container">
          <div>{loadingText || t('commun_loading')}</div> <Loader />
        </div>
      }
      noOptionsText={noOptionsText || t('commun_no_result')}
      onChange={(event, option) => {
        onSelection(option);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      openText=""
      options={listData}
      popupIcon={listData?.length && listData !== value?.length ? <DropdownArrowIcon /> : null}
      renderInput={(params) => (
        <TextFieldCustom
          {...params}
          error={!!error}
          errorMsg={error}
          onChange={(event) => setInputValue(event.target.value)}
          label={placeholderText}
          onBlur={() => {
            if (!value) setInputValue('');
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              const option = listData?.find((l) => l[labelAttribute] === inputValue);
              onSelection(option);
            }
          }}
        />
      )}
      renderOption={(props, option) => {
        if (useCustomSegmentOption) {
          return (
            <li {...props} key={`${option.id}-${option.name}`}>
              <p>{option.name}</p>
              <p>{option.customerCount}</p>
            </li>
          );
        }
        return (
          <li {...props} key={`${option.id}-${option[labelAttribute]}`}>
            {option[labelAttribute]}
          </li>
        );
      }}
      value={value}
    />
  );
};

AutocompleteSelect.propTypes = {
  className: PropTypes.string,
  disablePortal: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  labelAttribute: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  listData: PropTypes.array,
  loadingText: PropTypes.string,
  noOptionsText: PropTypes.string,
  onInputChange: PropTypes.func,
  onSelection: PropTypes.func,
  placeholderText: PropTypes.string,
  textlimit: PropTypes.number,
  useCustomSegmentOption: PropTypes.bool,
  value: PropTypes.object
};

export default AutocompleteSelect;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { withFractionDigits } from 'utils/global';
import Tooltip from 'components/Tooltip';
import { ReactComponent as TooltipIcon } from 'assets/30px_info_round.svg';
import styles from './RoasFrame.module.scss';

const Roas = ({ value }) => {
  const { t } = useTranslation();
  return (
    <div className={styles['root']}>
      <span>
        {t('commun_roas')}
        <Tooltip title={<Trans i18nKey="commun_roas_tooltip" />}>
          <TooltipIcon />
        </Tooltip>
      </span>
      <span>
        {t('commun_number', {
          value: value ?? '-',
          maximumFractionDigits: withFractionDigits(value)
        })}
      </span>
    </div>
  );
};

Roas.propTypes = { value: PropTypes.number };

export default memo(Roas);

import React from 'react';
import PropTypes from 'prop-types';
import RadioButton from 'components/RadioButton';
import { RadioGroup, FormControlLabel } from '@mui/material';

import styles from './RadioGroup.module.scss';
import clsx from 'clsx';

const RadioGroupCustom = ({
  disabled,
  error,
  errorMsg,
  fullWidth,
  id,
  listData,
  onChange,
  row = true,
  selectedValue,
  withBorder
}) => {
  return (
    <>
      {error && errorMsg && <p className={styles['error-msg']}>{errorMsg}</p>}
      <RadioGroup
        aria-label="radio-group"
        className={clsx(styles['root'], row && fullWidth && styles['radioGroup-full-width'])}
        disabled={disabled}
        id={id}
        name="row-radio-buttons-group"
        onChange={onChange}
        row={row}
        value={selectedValue}
      >
        {listData.map(({ disabled: disabledItem, label, value }, index) => {
          return (
            <FormControlLabel
              key={`${label}-${index}`}
              className={clsx(
                `${styles['form-control-label']}`,
                (disabled || disabledItem) && styles['disabled'],
                !row && styles['full-width'],
                selectedValue === value && styles['selected'],
                withBorder && styles['with-border']
              )}
              control={
                <RadioButton size="medium" disabled={disabled || disabledItem} checked={value === selectedValue} />
              }
              label={label}
              value={value}
            />
          );
        })}
      </RadioGroup>
    </>
  );
};

RadioGroupCustom.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired,
  row: PropTypes.bool,
  selectedValue: PropTypes.string,
  withBorder: PropTypes.bool
};

export default RadioGroupCustom;

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import CheckboxCustom from 'components/CheckboxCustom';
import Loader from 'components/Loaders/Dots';
import Radio from 'components/RadioButton';
import Tooltip from 'components/Tooltip';

import { ReactComponent as ArrowUp } from 'assets/arrow_up.svg';
import { ReactComponent as TimelineArrow } from 'assets/timeline_arrow.svg';
import { ReactComponent as TimelineArrowLeft } from 'assets/timeline_arrow_left.svg';
import { ReactComponent as NoResult } from 'assets/no_result.svg';
import styles from './TableCustom.module.scss';

const TableCustom = ({
  borderOnHover,
  className,
  isLoading,
  columns,
  rows,
  uniqueKey = 'id',
  total,
  handleSort,
  inModal,
  page,
  size,
  prev,
  next,
  check,
  checkType = 'checkbox',
  sort,
  onRowClick,
  order,
  type = 'small',
  sticky,
  showHeader = true,
  customMessageNode,
  isUsingFilters,
  clickedRawUniqueKeyValue
}) => {
  const { t } = useTranslation();
  const areAllChecked = useCallback(() => {
    return !isLoading && !customMessageNode && rows.every((row) => row.checked || row.checked === null);
  }, [customMessageNode, isLoading, rows]);
  const [checkAll, setCheckAll] = useState(areAllChecked());

  useEffect(() => {
    if (check) setCheckAll(areAllChecked());
  }, [areAllChecked, check, page]);

  const displayRowItems = (rowItems) =>
    rowItems.map((rowItemChild, index) => (
      <p
        key={`${rowItemChild.value}-${index}`}
        className={`
        ${styles[rowItemChild.color] ?? styles['default']} 
        ${styles[rowItemChild.textStyle] ?? styles['p4-m']}`}
      >
        {rowItemChild.value}
      </p>
    ));

  const displayRowItemsTooltip = (rowItems) =>
    rowItems.map((rowItemChild, index) => (
      <p
        key={`${rowItemChild.value}-${index}`}
        className={`
        ${styles[rowItemChild.color] ?? styles['default']} 
        ${styles[rowItemChild.textStyle] ?? styles['p4-m']}`}
      >
        {rowItemChild.rawValue || rowItemChild.value}
      </p>
    ));

  return (
    <div className={`${className} ${styles['table-custom']} ${styles[type]}`}>
      <div className={`${styles['table-content']} ${inModal ? styles['inModal'] : ''}`}>
        <table>
          <thead>
            {showHeader && (
              <tr>
                {check && checkType === 'checkbox' && (
                  <th className={`${sticky ? styles['sticky'] : ''} ${styles['checkbox']}`}>
                    <CheckboxCustom
                      checked={checkAll}
                      onClick={(e) => {
                        e.stopPropagation();
                        setCheckAll(!checkAll);
                        check({ id: 'all', checked: !checkAll });
                      }}
                    />
                  </th>
                )}
                {check && checkType === 'radio' && <th className={styles['radioButton']}></th>}

                {columns.map((columnItem) => {
                  const sortField = columnItem.sortPath || columnItem.field;

                  return (
                    <th
                      key={columnItem.id}
                      className={`${sticky ? styles['sticky'] : ''} ${columnItem.wide ? styles['wide'] : ''} ${
                        !columnItem.headerName ? styles['action-column'] : ''
                      }`}
                    >
                      {!columnItem.headerName ? null : (
                        <div className={columnItem.align ? styles[`align-${columnItem.align}`] : ''}>
                          <button
                            className={`${styles['filter-button']} ${
                              !isLoading && columnItem.sortable ? styles['sortable'] : ''
                            }`}
                            onClick={() => !isLoading && columnItem.sortable && handleSort(sortField)}
                          >
                            {columnItem?.headerName?.toUpperCase()}
                            {columnItem.sortable && sort !== sortField && (
                              <ArrowUp className={styles['display-on-hover']} />
                            )}
                            {columnItem.sortable && sort === sortField && <ArrowUp className={styles[order]} />}
                          </button>
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            )}
          </thead>
          <tbody>
            {isLoading ? (
              <tr className={styles['bg-white']}>
                <td colSpan={columns.length + 3} className={styles['customNode']}>
                  <div
                    className={
                      inModal
                        ? `${styles['flex-content']} ${styles['small-content']}`
                        : `${styles['flex-content']} ${styles['loading']} ${size < 11 ? styles['small-size'] : ''}`
                    }
                  >
                    <p>{t('commun_loading')}</p>
                    <Loader />
                  </div>
                </td>
              </tr>
            ) : customMessageNode ? (
              <tr className={styles['bg-white']}>
                <td colSpan={columns.length + 3} className={styles['customNode']}>
                  <div
                    className={
                      inModal
                        ? `${styles['flex-content']} ${styles['small-content']}`
                        : `${styles['flex-content']} ${size < 11 ? styles['small-size'] : ''}`
                    }
                  >
                    {customMessageNode}
                  </div>
                </td>
              </tr>
            ) : !total ? (
              <tr className={styles['bg-white']}>
                <td colSpan={columns.length + 3} className={styles['customNode']}>
                  <div
                    className={`${styles['flex-content']} ${styles['no-result']} ${
                      size < 11 ? styles['small-size'] : ''
                    }`}
                  >
                    <NoResult />
                    {isUsingFilters ? (
                      <div>
                        <p>{t('commun_no_result_due_to_filters_title')}</p>
                        <span>{t('commun_no_result_due_to_filters_desc')}</span>
                      </div>
                    ) : (
                      <p>{t('commun_no_result')}</p>
                    )}
                  </div>
                </td>
              </tr>
            ) : (
              rows?.map((rowItem) => (
                <tr
                  key={rowItem[uniqueKey][0].value}
                  className={clsx(
                    onRowClick && styles['clickable'],
                    borderOnHover && styles['borderOnHover'],
                    rowItem[uniqueKey][0].value?.toString() === clickedRawUniqueKeyValue && styles['clicked'],
                    rowItem.id[0].rowBgColor && styles['rowBgColor-' + rowItem.id[0].rowBgColor]
                  )}
                  onClick={() => {
                    onRowClick?.(rowItem);
                  }}
                >
                  {check && checkType === 'checkbox' && (
                    <td className={styles['checkbox']}>
                      {rowItem.checked !== null && (
                        <CheckboxCustom
                          checked={rowItem.checked}
                          onClick={(e) => {
                            e.stopPropagation();
                            check({ item: rowItem, checked: e.target.checked });
                          }}
                        />
                      )}
                    </td>
                  )}
                  {check && checkType === 'radio' && (
                    <td className={styles['radioButton']}>
                      <Radio
                        checked={rowItem.checked}
                        onChange={(e) => {
                          e.stopPropagation();
                          check(e.target.value);
                        }}
                        value={rowItem.id[0].value}
                      />
                    </td>
                  )}

                  {columns.map((columnItem, index) => {
                    return (
                      <td
                        key={`${rowItem[uniqueKey][0].value}-${index}`}
                        className={clsx(
                          columnItem.wide && styles['wide'],
                          columnItem.align && styles[`align-${columnItem.align}`],
                          !columnItem.headerName && styles['action-column'],
                          !showHeader && styles['without-header']
                        )}
                      >
                        {columnItem.type === 'component' ? (
                          <div className={clsx(borderOnHover && styles['displayOnHover'])}>
                            {rowItem[columnItem.field]}
                          </div>
                        ) : rowItem[columnItem.field][0]?.customTooltip ? (
                          <Tooltip title={rowItem[columnItem.field][0]?.customTooltip}>
                            <div>{displayRowItems(rowItem[columnItem.field])}</div>
                          </Tooltip>
                        ) : columnItem.hasTooltip ? (
                          <Tooltip title={displayRowItemsTooltip(rowItem[columnItem.field])}>
                            <div>{displayRowItems(rowItem[columnItem.field])}</div>
                          </Tooltip>
                        ) : (
                          displayRowItems(rowItem[columnItem.field])
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className={`${styles['table-footer']} ${showHeader ? '' : styles['without-header']}`}>
        <span className={styles['page-number']}>
          {page * size + 1} - {Math.min(total, page * size + size)}
        </span>
        <span className={styles['total']}> / {total}</span>
        <TimelineArrowLeft
          onClick={() => {
            if (page > 0) {
              if (check) setCheckAll(false);
              prev();
            }
          }}
        />
        <TimelineArrow
          onClick={() => {
            if (page < Math.floor(total / size) && page * size + size !== total) {
              if (check) setCheckAll(false);
              next();
            }
          }}
        />
      </div>
    </div>
  );
};

TableCustom.propTypes = {
  borderOnHover: PropTypes.bool,
  check: PropTypes.func,
  checkType: PropTypes.oneOf(['checkbox', 'radio']),
  className: PropTypes.string,
  clickedRawUniqueKeyValue: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object),
  customMessageNode: PropTypes.node,
  handleSort: PropTypes.func,
  inModal: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUsingFilters: PropTypes.bool,
  next: PropTypes.func,
  onRowClick: PropTypes.func,
  order: PropTypes.string,
  page: PropTypes.number,
  prev: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.object),
  showHeader: PropTypes.bool,
  size: PropTypes.number,
  sort: PropTypes.string,
  sticky: PropTypes.bool,
  total: PropTypes.number,
  type: PropTypes.string,
  uniqueKey: PropTypes.string
};

export default TableCustom;

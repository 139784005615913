import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { withFractionDigits } from 'utils/global';
import { getDashboardRetailerBudgetUsage, getDashboardSupplierBudgetUsage } from 'api';

import ButtonsCustom from 'components/ButtonsCustom';
import GaugeBar from 'components/GaugeBar';
import NonPositionedBudgetThresholds from './NonPositionedBudgetThresholds';

import styles from './DashboardSupplier.module.scss';

import { positionedTargetsLevelType } from 'types/positionedTargetsLevels';
import { supplierBudgetUsageType } from 'types/supplierBudgetUsage';

type Props = {
  budgetIds?: number[];
  currency?: string;
  positionedTargetsLevels?: positionedTargetsLevelType[];
  retailerId?: number;
  year: number;
};

const SpendingPaceBlock = ({ budgetIds, currency, positionedTargetsLevels, retailerId, year }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [data, setData] = useState<supplierBudgetUsageType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response: supplierBudgetUsageType = retailerId
        ? await getDashboardRetailerBudgetUsage({
            budgetIds,
            retailerId,
            year
          })
        : await getDashboardSupplierBudgetUsage({ budgetIds, year });
      setData(response);
      setIsLoading(false);
    };

    if (!retailerId || !!budgetIds?.length) {
      fetchData();
    }
  }, [budgetIds, retailerId, year]);

  return (
    <div className={clsx(styles['card'], styles['spending-pace-block'])}>
      <p className={styles['block-title']}>{t('dashboard_spending_pace_block_title')}</p>
      <div>
        <div className={styles['gauge-container']}>
          <div className={styles['chart-title']}>
            <p className={styles['small-value']}>
              {!isLoading && currency ? t('commun_price', { value: data?.totalBudget, currency }) : '-'}
            </p>
            <p className={styles['sub-label']}>{t('commun_budget_total')}</p>
          </div>
          <div className={styles['gauges-container']}>
            <GaugeBar
              color="secondary"
              label={
                <>
                  <p className={styles['small-value']}>
                    {!isLoading && currency ? t('commun_price', { value: data?.positionedBudget, currency }) : '-'}
                  </p>
                  <p className={styles['sub-label']}>{t('budget_progressBar_reserved')}</p>
                </>
              }
              percent={data?.positionedPercentage || 0}
              position="left"
            />
            <GaugeBar
              color="primary"
              label={
                <>
                  <p className={styles['small-value']}>
                    {!isLoading && currency ? t('commun_price', { value: data?.spentBudget, currency }) : '-'}
                  </p>
                  <p className={styles['sub-label']}>{t('budget_progressBar_spent')}</p>
                </>
              }
              percent={data?.spentPercentage || 0}
              position="right"
            />
          </div>
        </div>
        <div className={styles['vertical-separator']}></div>
        <div className={styles['targeting-objectives-container']}>
          <div /> {/* keep this empty div for alignement with flex */}
          <div className={styles['percentage-kpi-container']}>
            <div>
              <p className={styles['value']}>
                {!isLoading
                  ? t('commun_percentage_number', {
                      value: data?.nonPositionedPercentage ?? 0,
                      maximumFractionDigits: withFractionDigits(data?.nonPositionedPercentage)
                    })
                  : '-'}
              </p>
              <p className={styles['sub-label']}>{t('commun_budget_nonPositioned')}</p>
            </div>
            <div>
              <p className={styles['value']}>
                {!isLoading
                  ? t('commun_percentage_number', {
                      value: data?.unspentPercentage ?? 0,
                      maximumFractionDigits: withFractionDigits(data?.unspentPercentage)
                    })
                  : '-'}
              </p>
              <p className={styles['sub-label']}>{t('commun_budget_unSpent')}</p>
            </div>
          </div>
          {!isLoading && (
            <NonPositionedBudgetThresholds
              nonPositionedPercentage={data?.nonPositionedPercentage}
              positionedTargetsLevels={positionedTargetsLevels}
            />
          )}
          {data?.nonPositionedPercentage !== 0 ? (
            <Link to={'/offers/add-offer'} role="link">
              <ButtonsCustom
                classType="action_primary_home"
                id={'dashboard-supplier-createOffer-button'}
                text={t('commun_button_create_offer')}
              />
            </Link>
          ) : (
            <div /> // keep this empty div for alignement with flex
          )}
        </div>
      </div>
    </div>
  );
};

export default SpendingPaceBlock;

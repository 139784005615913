import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { store } from 'store';
import { discountTypes, exitModalTypes } from 'utils/constants';
import useDynId from 'customHooks/useDynId';
import { getCashCoupon, postCashCoupon, updateCashCoupon } from 'api';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import FormManager from 'components/FormManager';
import FormLayoutWrapper from 'components/LayoutWrappers/Form';
import GenerosityRecap from 'components/GenerosityRecap';
import InputAdornment from 'components/InputAdornment';

const initialFormValues = { generosity: { value: '', unit: 1 }, basketThreshold: '', numberMax: '', title: '' };

const CashCouponForm = ({ onCanceled, onSubmited, wrapperProps }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const {
    user,
    cashCoupons: {
      details: { cashCoupon, offerCampaigns }
    }
  } = state;
  const { id: cashCouponId } = useParams();
  const isEditing = !!cashCouponId && /add-cash-coupon/.test(history.location.pathname);
  const lastLinkedCampaignEndDate = offerCampaigns?.[0]?.campaign?.validityEndDate;

  const idGenerosity = useDynId('generosity');
  const idDiscountType = useDynId('discountType');
  const idBasketThreshold = useDynId('basketThreshold');
  const idNumberMax = useDynId('numberMax');
  const idDates = useDynId('dates');
  const idTitle = useDynId('title');
  const idSummary = useDynId('summary');

  const [displayLeaveModal, setDisplayLeaveModal] = useState(false);
  const [initialFormData, setInitialFormData] = useState(initialFormValues);
  const [isFetchingInitialData, setIsFetchingInitialData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPristine, setIsPristine] = useState(true);
  const today = moment();

  useEffect(() => {
    const fetchData = async () => {
      setIsFetchingInitialData(true);
      await getCashCoupon(cashCouponId);
      setIsFetchingInitialData(false);
    };

    if (isEditing && !cashCoupon.id) fetchData();

    return () => {
      dispatch({
        type: 'EXIT_MODAL_TYPE_UPDATE',
        payload: null
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initEditingMapping = useCallback(() => {
    if (!cashCoupon.id) return JSON.parse(JSON.stringify(initialFormValues));
    return {
      generosity: {
        value: cashCoupon?.discountValue,
        unit: user.discountUnits.find((u) => u.denomination === cashCoupon?.discountUnit)?.id
      },
      discountType: discountTypes.find((t) => t.denomination === cashCoupon?.discountType)?.id,
      basketThreshold: cashCoupon?.discountBasketThreshold,
      numberMax: cashCoupon?.discountMaximumUses,
      dates: {
        endDate: moment(cashCoupon.validityEndDate),
        startDate: moment(cashCoupon.validityStartDate)
      },
      title: cashCoupon?.title
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashCoupon.id]);

  const [formData, setFormData] = useState(initEditingMapping());

  useEffect(() => {
    if (cashCoupon.id) {
      const initData = initEditingMapping();
      setFormData(initData);
      setInitialFormData(initData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashCoupon.id]);

  const handleChangeState = (data) => {
    const newFormData = { ...formData, ...data };
    setFormData(newFormData);

    const pristine = JSON.stringify(newFormData) === JSON.stringify(initialFormData);
    setIsPristine(pristine);

    dispatch({
      type: 'EXIT_MODAL_TYPE_UPDATE',
      payload: pristine ? null : cashCoupon.id ? exitModalTypes.OFFER_EDITION : exitModalTypes.OFFER_CREATION
    });
  };

  const handlePostNew = async () => {
    const cashCoupon = {
      title: formData.title,
      generosity: formData.generosity.value,
      unit: formData.generosity.unit,
      basketThreshold: formData.basketThreshold,
      numberMax: formData.numberMax,
      discountType: formData.discountType,
      validityDates: formData.dates
    };

    setIsLoading(true);
    const success = await postCashCoupon({ userId: user.id, cashCoupon, discountUnits: user.discountUnits });
    setIsLoading(false);
    if (success) {
      if (onSubmited) onSubmited();
      else {
        handleGoBack();
      }
    }
  };

  const handleUpdate = async () => {
    const cashCoupon = {
      id: cashCouponId,
      title: formData.title,
      generosity: formData.generosity.value,
      unit: formData.generosity.unit,
      basketThreshold: formData.basketThreshold,
      numberMax: formData.numberMax,
      discountType: formData.discountType,
      validityDates: formData.dates
    };

    setIsLoading(true);
    const success = await updateCashCoupon({ userId: user.id, cashCoupon, discountUnits: user.discountUnits });
    setIsLoading(false);
    if (success) {
      if (onSubmited) onSubmited();
      else {
        handleGoBack();
      }
    }
  };

  const handleDisplayLeaveModal = () => {
    if (isPristine) {
      handleLeaveConfim();
    } else {
      setDisplayLeaveModal(true);
    }
  };

  const toggleDisplayLeaveModal = () => {
    setDisplayLeaveModal((state) => !state);
  };

  const handleLeaveConfim = () => {
    if (onCanceled) onCanceled();
    else handleGoBack();
  };

  const isOutsideRange = (date) => {
    if (lastLinkedCampaignEndDate) {
      const minDate = moment.max(today, moment(lastLinkedCampaignEndDate));
      return date.isBefore(minDate, 'day');
    }
    return date.isBefore(today, 'day');
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <FormLayoutWrapper {...wrapperProps} onClose={handleDisplayLeaveModal}>
      <FormManager
        onSubmit={isEditing ? handleUpdate : handlePostNew}
        data={{
          raiseErrorOnSubmit: true,
          fieldsets: [
            {
              id: 'add-cash-coupon-step',
              fields: [
                {
                  label: t('commun_generosity'),
                  placeholder: t('commun_generosity_placeholder'),
                  outerContainerClass: ['without-margin-top'],
                  type: 'Generosity',
                  fieldProps: {
                    listData: user.discountUnits.map((i) => ({
                      ...i,
                      idCustom: `${idGenerosity}-${i.denomination}`
                    }))
                  },
                  defaultValue: formData.generosity,
                  onFieldChange: (value) => {
                    if (value !== undefined) handleChangeState({ generosity: value });
                  },
                  id: idGenerosity,
                  disabled: !!lastLinkedCampaignEndDate,
                  validations: [
                    {
                      func: (d) => d?.value && d?.value > 0,
                      message: t('commun_field_required')
                    }
                  ]
                },
                {
                  label: t('cash_coupon_creation_basket_threshold'),
                  placeholder: t('cash_coupon_creation_basket_threshold_placeholder'),
                  type: 'NumberField',
                  id: idBasketThreshold,
                  allowZero: true,
                  fieldProps: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <span className="currency-symbol">{user.currency.symbol}</span>
                        </InputAdornment>
                      )
                    }
                  },
                  defaultValue: formData.basketThreshold,
                  onFieldChange: (value) => {
                    if (value !== undefined) handleChangeState({ basketThreshold: value });
                  },
                  disabled: !!lastLinkedCampaignEndDate,
                  validations: [
                    {
                      func: (value) => value !== '',
                      message: t('commun_field_required')
                    }
                  ]
                },
                {
                  label: t('offers_creation_discount_mechanism_type'),
                  placeholder: t('offers_creation_discount_mechanism_type_placeholder'),
                  type: 'Select',
                  id: idDiscountType,
                  fieldProps: {
                    listData: discountTypes.map((i) => ({
                      ...i,
                      idCustom: `${idDiscountType}-${i.denomination}`,
                      label: t(`_dyn_commun_${i.label}`)
                    })),
                    labelAttribute: 'label'
                  },
                  defaultValue: formData.discountType,
                  onFieldChange: (value) => {
                    if (value !== undefined) handleChangeState({ discountType: value });
                  },
                  disabled: !!lastLinkedCampaignEndDate,
                  validations: [
                    {
                      func: (value) => !!value,
                      message: t('commun_field_required')
                    }
                  ]
                },
                {
                  label: t('offers_creation_max_number_use'),
                  placeholder: t('offers_creation_max_number_use_placeholser'),
                  type: 'NumberField',
                  id: idNumberMax,
                  defaultValue: formData.numberMax,
                  onFieldChange: (value) => {
                    if (value !== undefined) handleChangeState({ numberMax: value });
                  },
                  disabled: !!lastLinkedCampaignEndDate,
                  validations: [
                    {
                      func: (value) => !!value,
                      message: t('commun_field_required')
                    }
                  ]
                },
                {
                  label: t('commun_validation_dates'),
                  type: 'DateRangePicker',
                  id: idDates,
                  defaultValue: formData.dates,
                  onFieldChange: (dates) => {
                    !!dates && handleChangeState({ dates });
                  },
                  disabled: lastLinkedCampaignEndDate ? 'startDate' : false,
                  fieldProps: { isOutsideRange },
                  validations: [
                    {
                      func: (dates) => dates?.startDate && dates?.endDate,
                      message: t('commun_field_required')
                    }
                  ]
                },
                {
                  outerContainerClass:
                    formData.basketThreshold && formData.generosity && formData.discountType
                      ? ['full-width', 'with-margin-top']
                      : [],
                  id: idSummary,
                  type: 'CustomContent',
                  component: (
                    <GenerosityRecap
                      basketThreshold={formData.basketThreshold}
                      currency={user.currency}
                      discountType={formData.discountType}
                      discountUnits={user.discountUnits}
                      generosity={formData.generosity}
                      recapType="cash_coupon_creation"
                    />
                  )
                },
                {
                  label: t('cash_coupon_creation_title'),
                  placeholder: t('cash_coupon_creation_title_placeholder'),
                  type: 'TextField',
                  id: idTitle,
                  fieldProps: { multiline: true },
                  defaultValue: formData.title,
                  onFieldChange: (value) => {
                    if (value !== undefined) handleChangeState({ title: value });
                  },
                  validations: [
                    {
                      func: (value) => !!value,
                      message: t('commun_field_required')
                    }
                  ]
                }
              ]
            }
          ],
          submit: {
            label: t(isEditing ? 'cash_coupon_editing_title' : 'commun_button_create_cash_coupon'),
            disabled: isFetchingInitialData,
            id: useDynId('addoffer'),
            loading: isLoading
          }
        }}
      />

      <ContentDialog
        centerText
        isOpen={displayLeaveModal}
        handleClose={toggleDisplayLeaveModal}
        maxWidth="xs"
        title={
          isEditing
            ? t('offers_edition_leave_popup_confirmation_message')
            : t('offers_creation_confirmation_message_not_saved')
        }
      >
        <ButtonsCustom classType="canceled" text={t('commun_button_cancel')} method={toggleDisplayLeaveModal} />
        <ButtonsCustom classType="action_primary_big" text={t('commun_button_continue')} method={handleLeaveConfim} />
      </ContentDialog>
    </FormLayoutWrapper>
  );
};

CashCouponForm.propTypes = {
  onCanceled: PropTypes.func,
  onSubmited: PropTypes.func,
  wrapperProps: PropTypes.object
};

export default CashCouponForm;

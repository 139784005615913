import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import TextFieldCustom from 'components/TextFieldCustom';
import { ReactComponent as DropdownArrow } from 'assets/dropdown_arrow.svg';
import Loader from 'components/Loaders/Dots';
import './AutocompleteCustom.scss';

const AutocompleteCustom = ({
  autoFocus = false,
  blurOnSelect = true,
  className,
  controlledTextValue,
  disabled,
  error = false,
  id,
  isLoading,
  labelAttribute = 'label',
  listData,
  loadingText,
  noOptionsText,
  onInputChange,
  onSelection,
  placeholderText,
  tagAttribut,
  textlimit = 2,
  value = null
}) => {
  const { t } = useTranslation();
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    if (controlledTextValue !== undefined) {
      setTextValue(controlledTextValue);
    }
  }, [controlledTextValue]);

  const renderInput = (params) => (
    <TextFieldCustom
      {...params}
      autoFocus={autoFocus}
      error={!!error}
      errorMsg={error}
      inputProps={{
        ...params.inputProps,
        value: controlledTextValue !== undefined ? textValue : params.inputProps.value
      }}
      label={placeholderText}
      onBlur={() => setTextValue('')}
      onChange={(event) => setTextValue(event.target.value)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
    />
  );

  const renderOption = (props, option) => (
    <div
      className="option-item"
      key={typeof labelAttribute === 'string' ? option[labelAttribute] : option[labelAttribute[0]]}
      onClick={() => {
        setTextValue('');
        onSelection(option);
      }}
      role="button"
      {...props}
    >
      <div className={tagAttribut ? 'options-tag' : ''}>
        {tagAttribut && <span className="tag-label">{option[tagAttribut]}</span>}
        {typeof labelAttribute === 'string' ? option[labelAttribute] : option[labelAttribute[0]]}
      </div>
    </div>
  );

  return (
    <Autocomplete
      blurOnSelect={blurOnSelect}
      classes={{
        root: `autocomplete-custom ${className}`,
        paper: 'autocomplete-custom-paper',
        endAdornment: 'autocomplete-custom-endAdornment',
        noOptions: 'autocomplete-custom-noOptions',
        listbox: 'autocomplete-custom-listBox',
        option: 'autocomplete-custom-option'
      }}
      disabled={disabled}
      getOptionLabel={(option) =>
        typeof labelAttribute === 'string'
          ? option[labelAttribute] || ''
          : labelAttribute.map((attr) => option[attr]).join(' ')
      }
      id={id}
      loading={isLoading}
      loadingText={
        <div className="loader-container">
          <span>{loadingText}</span> <Loader />
        </div>
      }
      noOptionsText={noOptionsText || t('commun_no_result')}
      onChange={(event, selectedItem) => {
        setTextValue('');
        onSelection(selectedItem);
      }}
      onInputChange={onInputChange}
      open={textValue.length >= textlimit}
      options={listData}
      popupIcon={<DropdownArrow />}
      renderInput={renderInput}
      renderOption={renderOption}
      value={value}
    />
  );
};

AutocompleteCustom.propTypes = {
  autoFocus: PropTypes.bool,
  blurOnSelect: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  controlledTextValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string,
  isLoading: PropTypes.bool,
  labelAttribute: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  listData: PropTypes.array,
  loadingText: PropTypes.string,
  noOptionsText: PropTypes.string,
  onInputChange: PropTypes.func,
  onSelection: PropTypes.func,
  placeholderText: PropTypes.string,
  tagAttribut: PropTypes.string,
  textlimit: PropTypes.number,
  value: PropTypes.object
};

export default AutocompleteCustom;

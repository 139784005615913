import * as jwt from 'jose';
import moment from 'moment-timezone';

export enum TOKENS {
  'access_token' = 'access_token',
  'refresh_token' = 'refresh_token'
}

export const tokenParser = (token: string | null): jwt.JWTPayload | undefined => {
  if (!token) return undefined;
  try {
    const decodedToken = jwt.decodeJwt(token);
    return decodedToken;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Fail decoding JWT token: ', error);
    return undefined;
  }
};

export const isTokenExpired = (token: string) => {
  const parsedToken = tokenParser(token);
  if (!parsedToken?.exp) return true;
  return moment(parsedToken.exp * 1000).isBefore(moment());
};

/*** Manage access_token and refresh_token in localStorage ***/
// tokens are removed when logged out

export const deleteToken = (key: string) => {
  localStorage.removeItem(key);
};

export const getToken = (key: string) => {
  return localStorage.getItem(key);
};

export const saveTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(TOKENS.access_token, accessToken);
  localStorage.setItem(TOKENS.refresh_token, refreshToken);
};

export const removeAllTokens = () => {
  deleteToken(TOKENS.access_token);
  deleteToken(TOKENS.refresh_token);
  sessionStorage.removeItem('userCode');
};

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CampaignKeypoints from './CampaignKeypoints';
import ConfirmationStep from './ConfirmationStep';

import FooterButtonsContainer from 'components/LayoutWrappers/Form/FooterButtonsContainer';
import FormManager from 'components/FormManager';

const ProductStep4 = ({
  campaignProduct,
  handlePreviousStep,
  handleSave,
  isLoading,
  settingProductLevelLabelThree,
  tooltipSaveErrorMsg
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormManager
        data={{
          fieldsets: [
            {
              id: 'add-campaign-product-step-04',
              fields: [
                {
                  id: 'campaign-creation-step-04-confirmation',
                  outerContainerClass: ['full-width'],
                  type: 'CustomContent',
                  component: (
                    <ConfirmationStep
                      campaignProduct={campaignProduct}
                      settingProductLevelLabelThree={settingProductLevelLabelThree}
                    />
                  )
                }
              ]
            }
          ]
        }}
      />

      <CampaignKeypoints targetingKpisOfferCampaign={campaignProduct?.targetingKpisOfferCampaign} />

      <FooterButtonsContainer
        cancelLabel={t('commun_button_previous_step')}
        confirmLabel={t('commun_button_save_campaign')}
        disabled={!!tooltipSaveErrorMsg}
        isLoading={isLoading}
        onCancel={handlePreviousStep}
        onConfirm={handleSave}
        tooltipMsg={tooltipSaveErrorMsg ? t(tooltipSaveErrorMsg) : ''}
      />
    </>
  );
};

ProductStep4.propTypes = {
  campaignProduct: PropTypes.object,
  handlePreviousStep: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  settingProductLevelLabelThree: PropTypes.string.isRequired,
  tooltipSaveErrorMsg: PropTypes.string
};

export default memo(ProductStep4);

import React, { useCallback, useContext, useState, useEffect, useMemo } from 'react';
import { store } from 'store';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { getAllSuppliers, getProductCampaignOffers, getTargetingStrategies, updateOfferSuperEditableFlag } from 'api';
import { campaignStates } from 'utils/constants';
import useDynId from 'customHooks/useDynId';

import AddOfferForm from 'pages/AddOffer/AddOfferForm';
import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import FiltersContainer, { getInitialFilters } from 'components/Filters';
import GoBackLink from 'components/GoBackLink';
import TableOffers from './TableOffers';

import { ReactComponent as DuplicateIcon } from 'assets/duplicate.svg';
import { ReactComponent as FlagActiveIcon } from 'assets/flag_active.svg';

import styles from './CampaignOffers.module.scss';

const CampaignOffers = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const {
    campaignCreation: { product: campaignProduct },
    queryFilters,
    targetingStrategies,
    suppliers,
    user
  } = state;

  const initFilters = useMemo(
    () => getInitialFilters({ history, queryFilters, suppliers, targetingStrategies }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { id, offerId } = useParams();
  const editingOffer = offerId ? campaignProduct.offers.find((o) => o.offerHead.id.toString() === offerId) : null;

  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingFlagChange, setLoadingFlagChange] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({ type: 'id', sort: 'desc' });
  const [offerCreation, setOfferCreation] = useState({ step3: { title: { value: '' } } });
  const [openOfferEditionDialog, setOpenOfferEditionDialog] = useState(false);

  const [filters, setFilters] = useState(initFilters);
  const filtersListToDisplay = [
    'id',
    'title',
    'descriptionTag',
    'discountMinQuantity',
    'targetingMarketingStrategy',
    'retailerUser',
    'budgetTarget',
    'generosity',
    'averagePrice',
    'discountType',
    'superEditable'
  ];

  const fetchProductCampaignOffers = async () => {
    setLoading(true);
    await getProductCampaignOffers({
      checkHasActiveTargetingStatusCampaign: true,
      campaignProduct: { id },
      filters,
      orderBy: order.sort,
      page,
      sortBy: `offer.${order.type}`
    });
    setInitialLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    if (!suppliers.length) {
      getAllSuppliers();
    }

    if (!targetingStrategies.length) {
      getTargetingStrategies();
    }

    return () => {
      dispatch({ type: 'CAMPAIGN_CREATION_RESET' });
      dispatch({ type: 'OFFERS_RESET' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (setInitialLoading) {
      fetchProductCampaignOffers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && !initialLoading) {
      fetchProductCampaignOffers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, order, page]);

  useEffect(() => {
    setOpenOfferEditionDialog(!!offerId);
  }, [offerId]);

  const handleCloseOfferEditionDialog = () => {
    history.push({
      pathname: `/campaigns/${id}/offers`,
      search: history.location.search
    });
  };

  const handleCopyToClipboard = async () => {
    await navigator.clipboard.writeText(window.location.href);
  };

  const handleFlagClick = async ({ offerListIndex, isSuperEditable, offerId }) => {
    if (!loadingFlagChange) {
      setLoadingFlagChange(true);
      const isOK = await updateOfferSuperEditableFlag({ isSuperEditable: !isSuperEditable, offerId });
      if (isOK) {
        // ugly but good shortcut
        const updatedOffer = {
          ...campaignProduct.offers[offerListIndex],
          offerHead: { ...campaignProduct.offers[offerListIndex].offerHead, superEditable: !isSuperEditable }
        };
        const newOffersList = [
          ...campaignProduct.offers.slice(0, offerListIndex),
          updatedOffer,
          ...campaignProduct.offers.slice(offerListIndex + 1)
        ];
        dispatch({
          type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE',
          payload: { offers: [...newOffersList] }
        });
      }
      setLoadingFlagChange(false);
    }
  };

  const handleOfferCreationChange = (offerCreation) => {
    setOfferCreation(offerCreation);
  };

  const handleSort = (type) => {
    setPage(0); // reset the page when changing the sorting
    setOrder({ type, sort: type === order.type && order.sort === 'desc' ? 'asc' : 'desc' });
  };

  const handleUpdateFilters = useCallback((newFilters) => {
    setPage(0); // reset the page when changing the filters
    setFilters({ ...newFilters });
  }, []);

  const handleOfferSaved = async () => {
    await fetchProductCampaignOffers();
    handleCloseOfferEditionDialog();
  };

  return (
    <>
      <section className={styles['root']}>
        <GoBackLink pathname={`/campaigns/${id}`} label={t('navigation_back_campaign_details')} />

        <p className={styles['header']} id={useDynId('XcampaignOffer')}>
          <Trans
            i18nKey="campaign_details_list_offers_count"
            values={{
              count: campaignProduct.offersCount,
              text: campaignProduct.title
            }}
          />
        </p>

        <div className={styles['filtersBlock']}>
          <FiltersContainer
            dispatch={dispatch}
            filters={filters}
            filtersListToDisplay={filtersListToDisplay}
            suppliersList={suppliers}
            targetingStrategiesList={targetingStrategies}
            updateFilters={handleUpdateFilters}
            user={user}
          />
          <div className={styles['duplicate-url-link']}>
            <DuplicateIcon />
            <ButtonsCustom
              classType="link_primary"
              method={handleCopyToClipboard}
              text={t('commun_copy_url')}
              textAlign="right"
            />
          </div>
        </div>
        <TableOffers
          clickedRawUniqueKeyValue={editingOffer && editingOffer.offerHead.id.toString()}
          isCampaignStateTerminated={campaignProduct.campaignState === campaignStates.TERMINATED}
          isLoading={initialLoading || loading}
          isUsingFilters={!!Object.keys(filters)?.length}
          handleFlagClick={handleFlagClick}
          handleNextPage={() => setPage(page + 1)}
          handlePrevPage={() => setPage(page - 1)}
          handleSort={handleSort}
          offers={campaignProduct.offers}
          orderBy={order.sort}
          page={page}
          sortBy={order.type}
          total={campaignProduct.offersCount}
        />
      </section>
      <ContentDialog
        bigTitle
        className={styles['offer-edition-dialog']}
        isOpen={openOfferEditionDialog}
        handleClose={handleCloseOfferEditionDialog}
        maxWidth="md"
        title={
          <div className={styles['header']}>
            <p>{t('commun_edit_offer')}</p>
            {offerCreation.step3.title.value && (
              <p className={styles['offer-title']}>{`"${offerCreation.step3.title.value}"`}</p>
            )}
          </div>
        }
      >
        <div className={styles['some-space-bottom']}>
          {editingOffer && editingOffer.offerHead.superEditable && (
            <div className={styles['superEditableWarning-frame']}>
              <FlagActiveIcon />
              <div>
                <h3>{t('campaign_offers_details_superEditable_frame_title')}</h3>
                <p>{t('campaign_offers_details_superEditable_frame_desc')}</p>
              </div>
            </div>
          )}
          <AddOfferForm
            isSuperEditable={editingOffer && editingOffer.offerHead.superEditable}
            onOfferCreationChange={handleOfferCreationChange}
            onOfferSaved={handleOfferSaved}
          />
        </div>
      </ContentDialog>
    </>
  );
};

export default CampaignOffers;

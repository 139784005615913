import React, { useState, memo, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { scrollToElementById } from 'utils/global';
import {
  getUntargetableOffers,
  getUntargetableProductCampaignOffers,
  updateUntargetableProductCampaignOffers
} from 'api';

import DefaultOffersContainer from './DefaultOffersContainer';
import FormManager from 'components/FormManager';

const ProductStep3 = forwardRef(
  ({ campaignProduct, disabled, dispatch, isEditMode, isLoading, settingProductLevelLabelThree }, ref) => {
    const { t } = useTranslation();
    const thisRef = useRef(null);

    const [errorMessageMaxOffersField, setErrorMessageMaxOffersField] = useState(null);
    const [errorMessageMinOffersField, setErrorMessageMinOffersField] = useState(null);
    const [isInitLoading, setIsInitLoading] = useState(false);
    const [hasUntargetableOffersAvailable, setHasUntargetableOffersAvailable] = useState(null);
    const [offersMinimumNumberError, setOffersMinimumNumberError] = useState(false);

    useImperativeHandle(ref, () => ({
      ref: thisRef,
      step3CheckHasError() {
        return checkStep3HasError();
      }
    }));

    useEffect(() => {
      const init = async () => {
        setIsInitLoading(true);
        const untargetableOffers = await getUntargetableOffers({
          campaignProduct
        });
        setHasUntargetableOffersAvailable(!!untargetableOffers?.length);

        if (isEditMode) {
          await getUntargetableProductCampaignOffers({
            campaignProduct,
            page: 0
          });
        }
        setIsInitLoading(false);
      };
      if (!disabled && !isInitLoading) init();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabled]);

    useEffect(() => {
      const updateUntargetableOffers = async () => {
        const untargetableOffers = await getUntargetableOffers({
          campaignProduct
        });
        setHasUntargetableOffersAvailable(!!untargetableOffers?.length);
      };
      if (!disabled && !isInitLoading) updateUntargetableOffers();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignProduct.startDate, campaignProduct.endDate]);

    useEffect(() => {
      if (!disabled && !isInitLoading && !campaignProduct.offersUntargetablePreviewCount) {
        handleChange('offersMinimumNumber', 0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignProduct.offersUntargetablePreviewCount]);

    useEffect(() => {
      checkStep3HasError();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignProduct.untargetableProductCampaignOffers.total]);

    const checkInputOffersMaximumNumberError = ({ maxValue, minValue }) => {
      let errorMsg = null;
      if (maxValue === '') {
        errorMsg = t('commun_field_required');
      } else {
        if (minValue && maxValue < minValue) {
          errorMsg = t('campaign_product_step3_error_maxOffer_value_pattern');
        }
        if (maxValue < 1) {
          errorMsg = t('campaign_product_step3_error_value_pattern');
        }
      }
      setErrorMessageMaxOffersField(errorMsg);
      return !!errorMsg;
    };

    const checkInputOffersMinimumNumberError = ({ maxValue, minValue }) => {
      let errorMsg = null;

      if (minValue === '') {
        errorMsg = t('commun_field_required');
      } else if (!isInitLoading && minValue > campaignProduct.offersUntargetablePreviewCount) {
        errorMsg = t('campaign_product_step3_error_minOffer_value_pattern_2', {
          count: campaignProduct.offersUntargetablePreviewCount || 0
        });
      } else if (maxValue && maxValue < minValue) {
        errorMsg = t('campaign_product_step3_error_minOffer_value_pattern');
      }

      setErrorMessageMinOffersField(errorMsg);
      return !!errorMsg;
    };

    const checkStep3HasError = () => {
      let errorFieldIdList = [];

      if (
        campaignProduct.parameters.offersMinimumNumber > 0 &&
        campaignProduct.untargetableProductCampaignOffers.total < campaignProduct.parameters.offersMinimumNumber
      ) {
        setOffersMinimumNumberError(true);
        errorFieldIdList.push('field-offersMinimumNumber');
      } else {
        setOffersMinimumNumberError(false);
      }

      if (
        checkInputOffersMaximumNumberError({
          maxValue: campaignProduct.parameters.offersMaximumNumber,
          minValue: campaignProduct.parameters.offersMinimumNumber
        }) ||
        checkInputOffersMinimumNumberError({
          maxValue: campaignProduct.parameters.offersMaximumNumber,
          minValue: campaignProduct.parameters.offersMinimumNumber
        })
      ) {
        errorFieldIdList.push('field-offersMaximumNumber');
      }

      if (campaignProduct.parameters.offersNumberByCategory < 1) {
        errorFieldIdList.push('field-offersNumberByCategory');
      }

      if (errorFieldIdList.length) {
        setTimeout(() => {
          scrollToElementById(errorFieldIdList[0]);
        }, 150);
      }

      return !!errorFieldIdList.length;
    };

    const handleChange = async (id, value) => {
      if (disabled) return null;

      // delete all untargetableProductCampaignOffers if user fills offersMinimumNumber with a 0
      if (id === 'offersMinimumNumber') {
        checkInputOffersMaximumNumberError({
          maxValue: campaignProduct.parameters.offersMaximumNumber,
          minValue: value
        });
        checkInputOffersMinimumNumberError({
          maxValue: campaignProduct.parameters.offersMaximumNumber,
          minValue: value
        });

        if (value === 0) {
          setOffersMinimumNumberError(false);
          if (campaignProduct.untargetableProductCampaignOffers.listAll.length) {
            await updateUntargetableProductCampaignOffers({
              newIdList: [],
              oldIdList: campaignProduct.untargetableProductCampaignOffers.listAll.map((i) => i.id),
              campaignProduct
            });

            await getUntargetableProductCampaignOffers({ campaignProduct });
          }
        }

        setOffersMinimumNumberError(
          campaignProduct.untargetableProductCampaignOffers.listAll.length
            ? campaignProduct.untargetableProductCampaignOffers.listAll.length < value
            : false
        );
      }

      if (id === 'offersMaximumNumber') {
        checkInputOffersMaximumNumberError({
          maxValue: value,
          minValue: campaignProduct.parameters.offersMinimumNumber
        });
        checkInputOffersMinimumNumberError({
          maxValue: value,
          minValue: campaignProduct.parameters.offersMinimumNumber
        });
      }

      dispatch({
        type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE',
        payload: { parameters: { ...campaignProduct.parameters, [id]: value } }
      });
    };

    return (
      <div ref={thisRef}>
        <FormManager
          data={{
            title: t('campaign_configure_min_offers_block_title'),
            fieldsets: [
              {
                id: 'add-campaign-product-offers-params-block-1',
                outerContainerClass: ['full-width'],
                classnames: ['display-flex', 'no-padding'],
                fields: [
                  {
                    outerContainerClass: ['without-margin-top', 'without-margin-bottom'],
                    label: t('commun_total_long'),
                    classnames: ['large-input'],
                    placeholder: t('campaign_product_step3_minPerClient_label'),
                    type: 'NumberField',
                    id: 'offersMinimumNumber',
                    allowZero: true,
                    defaultValue: campaignProduct.parameters.offersMinimumNumber,
                    disabled: disabled || isLoading || isInitLoading || !hasUntargetableOffersAvailable,
                    error: errorMessageMinOffersField,
                    onFieldChange: (value) => handleChange('offersMinimumNumber', value),
                    fieldProps: {
                      InputProps: {
                        min: 0
                      }
                    }
                  }
                ]
              },
              {
                id: 'add-campaign-product-default-offers-container',
                classnames: ['no-padding'],
                display: !disabled,
                fields: [
                  {
                    outerContainerClass: ['full-width'],
                    id: 'default-offers-container',
                    type: 'CustomContent',
                    component: (
                      <DefaultOffersContainer
                        campaignProduct={campaignProduct}
                        hasError={offersMinimumNumberError}
                        hasUntargetableOffersAvailable={hasUntargetableOffersAvailable}
                        isLoading={isLoading || isInitLoading}
                      />
                    )
                  }
                ]
              }
            ]
          }}
        />
        <FormManager
          data={{
            title: t('campaign_configure_max_offers_block_title'),
            fieldsets: [
              {
                id: 'add-campaign-product-offers-params-block-2',
                outerContainerClass: ['full-width'],
                classnames: ['display-flex', 'no-padding'],
                fields: [
                  {
                    outerContainerClass: ['without-margin-top'],
                    label: t('campaign_product_step3_offersNumberByCategory_bis', {
                      text: settingProductLevelLabelThree
                    }),
                    classnames: ['large-input'],
                    placeholder: t('campaign_product_step3_maxPerClient_label'),
                    type: 'NumberField',
                    id: 'offersNumberByCategory',
                    allowZero: true,
                    defaultValue: campaignProduct.parameters.offersNumberByCategory,
                    onFieldChange: (value) => handleChange('offersNumberByCategory', value),
                    disabled: disabled,
                    fieldProps: {
                      InputProps: {
                        min: 1
                      }
                    },
                    validations: [
                      {
                        func: (value) => value !== '',
                        message: t('commun_field_required')
                      },
                      {
                        func: (value) => value > 0,
                        message: t('campaign_product_step3_error_value_pattern')
                      }
                    ]
                  },
                  {
                    outerContainerClass: ['without-margin-top'],
                    label: t('commun_total_long'),
                    classnames: ['large-input'],
                    placeholder: t('campaign_product_step3_maxPerClient_label'),
                    type: 'NumberField',
                    id: 'offersMaximumNumber',
                    allowZero: true,
                    defaultValue: campaignProduct.parameters.offersMaximumNumber,
                    disabled: disabled,
                    error: errorMessageMaxOffersField,
                    onFieldChange: (value) => handleChange('offersMaximumNumber', value),
                    fieldProps: {
                      InputProps: {
                        min: 1
                      }
                    }
                  }
                ]
              }
            ]
          }}
        />
      </div>
    );
  }
);

ProductStep3.propTypes = {
  campaignProduct: PropTypes.object,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  isEditMode: PropTypes.bool,
  isLoading: PropTypes.bool,
  settingProductLevelLabelThree: PropTypes.string.isRequired
};

export default memo(ProductStep3);

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { isOutOfRangePast18Month, isRecencyDatePickerOutsideRangePast18Month } from '../utils';
import FormManager from 'components/FormManager';
import HeaderBlock from './_HeaderBlock';

import styles from './ConditionsForm.module.scss';

const RecencyBlock = ({ config, groupIndex, onDeleteBlock, segment, updateData }) => {
  const { t } = useTranslation();
  const [dateFocusedInputState, setDateFocusedInputState] = useState(null);

  const isOutsideRange = (date) => {
    const { period } = segment;

    return isRecencyDatePickerOutsideRangePast18Month({
      date,
      dateFocusedInputState,
      end_date: period.value.end_date,
      start_date: period.value.start_date
    });
  };

  return (
    <div className={`${styles['mainContainer']} `}>
      <HeaderBlock onDelete={onDeleteBlock} segment={segment} />
      <FormManager
        data={{
          classnames: [styles['customForm']],
          fieldsets: [
            {
              id: 'segment-manager-recency-line-1',
              classnames: [styles['one-line']],
              fields:
                segment.period.type === 'over'
                  ? [
                      {
                        type: 'Select',
                        classnames: [styles['input'], styles['input-small']],
                        defaultValue: segment.period.type || config.lists.commun.period[0],
                        fieldProps: {
                          listData: config.lists.commun.period.map((el) => ({
                            id: el,
                            label: t(`_dyn_commun_select_period_${el}`)
                          })),
                          shrinkPlaceholder: false
                        },
                        id: `segment-${segment.type}-period-type-${groupIndex}`,
                        onFieldChange: (value) => {
                          const newSegment = JSON.parse(JSON.stringify(segment));
                          newSegment.period.type = value;
                          newSegment.period.value = { ...config.types.recency.period.value }; // reset value when period changed
                          updateData(newSegment);
                        }
                      },
                      {
                        type: 'Select',
                        classnames: [styles['input'], styles['input-small']],
                        defaultValue: segment.period.value.sign || config.lists.recency.sign[0],
                        fieldProps: {
                          listData: config.lists.recency.sign.map((el) => ({
                            id: el,
                            label: t(`_dyn_commun_select_sign_${el}`)
                          })),
                          shrinkPlaceholder: false
                        },
                        id: `segment-${segment.type}-period-value-sign-${groupIndex}`,
                        onFieldChange: (value) => {
                          const newSegment = JSON.parse(JSON.stringify(segment));
                          newSegment.period.value.sign = value;
                          updateData(newSegment);
                        }
                      },
                      {
                        type: 'NumberField',
                        allowZero: false,
                        classnames: [styles['input'], styles['input-small']],
                        defaultValue: segment.period.value.count || '',
                        fieldProps: {
                          InputProps: {
                            min: 0
                          },
                          shrinkPlaceholder: false
                        },
                        id: `segment-${segment.type}-period-value-count-${groupIndex}`,
                        onFieldChange: (value) => {
                          const newSegment = JSON.parse(JSON.stringify(segment));
                          newSegment.period.value.count = value;

                          // reset interval Select if count is out of range
                          if (isOutOfRangePast18Month({ count: value, interval: segment.period.value.interval })) {
                            newSegment.period.value.interval = null;
                          }

                          updateData(newSegment);
                        },
                        placeholder: t('commun_input_number_placeholder')
                      },
                      {
                        type: 'Select',
                        classnames: [styles['input'], styles['input-last']],
                        outerContainerClass: [styles['input-outerContainer']],
                        defaultValue: segment.period.value.interval,
                        fieldProps: {
                          listData: config.lists.commun.interval.map((el) => ({
                            id: el,
                            label: t(`_dyn_commun_select_interval_${el}`, { count: segment.period.value.count || 0 }),
                            disabled: isOutOfRangePast18Month({ count: segment.period.value.count, interval: el })
                          })),
                          placeHolderText: t('_dyn_commun_select_interval_placeholder'),
                          shrinkPlaceholder: false
                        },
                        id: `segment-${segment.type}-period-interval-${groupIndex}`,
                        onFieldChange: (value) => {
                          const newSegment = JSON.parse(JSON.stringify(segment));
                          newSegment.period.value.interval = value;
                          updateData(newSegment);
                        }
                      }
                    ]
                  : [
                      {
                        type: 'Select',
                        classnames: [styles['input'], styles['input-small']],
                        defaultValue: segment.period.type || config.lists.commun.period[0],
                        fieldProps: {
                          listData: config.lists.commun.period.map((el) => ({
                            id: el,
                            label: t(`_dyn_commun_select_period_${el}`)
                          })),
                          shrinkPlaceholder: false
                        },
                        id: `segment-${segment.type}-period-type-${groupIndex}`,
                        onFieldChange: (value) => {
                          const newSegment = JSON.parse(JSON.stringify(segment));
                          newSegment.period.type = value;
                          newSegment.period.value = { ...config.types.recency.period.value }; // reset value when period changed
                          updateData(newSegment);
                        }
                      },
                      {
                        type: 'DateRangePicker',
                        outerContainerClass: [styles['input-outerContainer']],
                        defaultValue: {
                          startDate: segment.period.value.start_date ? moment(segment.period.value.start_date) : null,
                          endDate: segment.period.value.end_date ? moment(segment.period.value.end_date) : null
                        },
                        fieldProps: {
                          fullWidth: true,
                          isOutsideRange: isOutsideRange,
                          getFocusInput: setDateFocusedInputState
                        },
                        id: `segment-${segment.type}-period-value-dates-${groupIndex}`,
                        onFieldChange: (dates) => {
                          const newSegment = JSON.parse(JSON.stringify(segment));
                          newSegment.period.value = {
                            end_date: dates?.endDate?.format?.('YYYY-MM-DD'),
                            start_date: dates?.startDate?.format?.('YYYY-MM-DD')
                          };
                          updateData(newSegment);
                        }
                      }
                    ]
            }
          ]
        }}
      />
    </div>
  );
};

RecencyBlock.propTypes = {
  config: PropTypes.object.isRequired,
  groupIndex: PropTypes.string.isRequired,
  onDeleteBlock: PropTypes.func.isRequired,
  segment: PropTypes.object.isRequired,
  updateData: PropTypes.func.isRequired
};

export default memo(RecencyBlock);

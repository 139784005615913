import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import useDynId from 'customHooks/useDynId';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import ModalContent from './ModalContent';
import Tooltip from 'components/Tooltip';

import { ReactComponent as SearchIcon } from 'assets/20px_recherche.svg';
import { ReactComponent as CrossIcon } from 'assets/cross.svg';

import styles from './AddElements.module.scss';

const AddElements = ({ onSubmit, segment }) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const {
    state: { user }
  } = globalState;

  const addedElementIdList = segment.condition?.on || segment.on;
  const isLevelStore = segment.condition?.level ? segment.condition.level === 'store' : segment.level === 'store';
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [openDeleteAllListDialog, setOpenDeleteAllListDialog] = useState(false);

  const stopEventBehavior = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleCloseSearchDialog = () => {
    setOpenSearchDialog(false);
  };
  const handleOpenSearchDialog = (e) => {
    stopEventBehavior(e);
    setOpenSearchDialog(true);
  };

  const handleCloseDeleteAllListDialog = () => {
    setOpenDeleteAllListDialog(false);
  };
  const handleOpenDeleteAllListDialog = (e) => {
    stopEventBehavior(e);
    setOpenDeleteAllListDialog(true);
  };

  const handleClearList = () => {
    onSubmit([]);
    handleCloseDeleteAllListDialog();
  };

  const getInputTitle = () => {
    const productLevel = segment?.condition?.level || segment?.level;
    const segmentType = segment.type === 'MultiPurchases' ? 'purchases' : segment.type.toLowerCase();
    switch (productLevel) {
      case 'level0':
        return user.settingProductLevelLabelOne;
      case 'level1':
        return user.settingProductLevelLabelTwo;
      case 'level2':
        return user.settingProductLevelLabelThree;
      case 'level3':
        return user.settingProductLevelLabelFour;
      default:
        const type = productLevel ? `${segmentType}_${productLevel}` : segmentType;
        return t(`segmentManager_vector_type_${type}_label`);
    }
  };

  return (
    <>
      <div className={styles['root']}>
        <div className={styles['fake-input']} onClick={handleOpenSearchDialog} role="button">
          <div className={styles['label']}>
            {addedElementIdList?.length ? (
              <p className={styles['selected']}>
                {isLevelStore
                  ? t('commun_selected_store_count', {
                      count: addedElementIdList.length
                    })
                  : t('commun_selected_element_count', {
                      count: addedElementIdList.length
                    })}
              </p>
            ) : (
              <p className={styles['empty']}>{getInputTitle()}</p>
            )}
          </div>
          <div className={styles['icons-container']}>
            <Tooltip title={t('commun_search_product_tooltip')}>
              <SearchIcon id={'search-products'} onClick={handleOpenSearchDialog} title="" />
            </Tooltip>

            {!!addedElementIdList?.length && (
              <Tooltip title={t('commun_search_product_delete_all_tooltip')}>
                <CrossIcon id={'delete-all-products'} onClick={handleOpenDeleteAllListDialog} title="" />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      <ContentDialog
        centerText
        disableClickOutside
        handleClose={handleCloseSearchDialog}
        isOpen={openSearchDialog}
        spaceless
        maxWidth="lg"
      >
        <ModalContent onChange={onSubmit} onClose={handleCloseSearchDialog} segment={segment} />
      </ContentDialog>
      <ContentDialog
        centerText
        handleClose={handleCloseDeleteAllListDialog}
        isOpen={openDeleteAllListDialog}
        maxWidth="xs"
        title={t('segmentManager_clear_all_products_list_modal_title')}
      >
        <ButtonsCustom
          classType="canceled"
          id={useDynId('cancelClearProductListPopup')}
          method={handleCloseDeleteAllListDialog}
          text={t('commun_cancel')}
        />
        <ButtonsCustom
          classType="action_primary_big"
          id={useDynId('confirmClearProductListPopup')}
          method={handleClearList}
          text={t('commun_button_clear_list')}
        />
      </ContentDialog>
    </>
  );
};

AddElements.propTypes = {
  onSubmit: PropTypes.func,
  segment: PropTypes.object
};

export default AddElements;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CheckboxCustom from 'components/CheckboxCustom';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

import styles from './CheckboxList.module.scss';

const CheckboxList = (props) => {
  const {
    className,
    data: { id, defaultValue = [], fieldProps },
    onChange,
    error
  } = props;

  const [value, setValue] = useState([]);

  useEffect(() => {
    if (defaultValue?.length) {
      setValue(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) onChange(id, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (val, checked) => {
    const values = checked ? [...value, val] : value.filter((el) => el !== val);

    setValue(values);
  };

  return (
    <>
      <div className={className}>
        <FormGroup row={!fieldProps.displayOnePerRow}>
          {fieldProps.list.map((item) => {
            const checked = value.includes(item.value);
            return (
              <FormControlLabel
                key={item.value}
                control={
                  <CheckboxCustom
                    idText={item.value}
                    checked={checked}
                    onChange={() => handleChange(item.value, !checked)}
                  />
                }
                label={item.label}
              />
            );
          })}
        </FormGroup>
      </div>

      {error && typeof error === 'string' && <div className={styles['error']}>{error}</div>}
    </>
  );
};

CheckboxList.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func
};

export default CheckboxList;

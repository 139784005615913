import React, { memo, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { MONTH_LABELS } from 'utils/constants';

import InputAdornment from 'components/InputAdornment';
import SelectCustom from 'components/SelectCustom';
import TextFieldCustom from 'components/TextFieldCustom';

import ModuloIcon from 'assets/modulo.svg';
import styles from './ValuePerMonthInput.module.scss';

const ValuePerMonthInput = (props) => {
  const {
    data: { id, defaultValue, disabled, rangeMonths },
    onChange
  } = props;

  const MONTHS_LIST = useMemo(() => {
    const [start, end] = rangeMonths;
    return [
      { id: '', label: t('commun_cancel') },
      { id: MONTH_LABELS[0], label: t('commun_month_full_01'), disabled: start > 0 || end < 1 },
      { id: MONTH_LABELS[1], label: t('commun_month_full_02'), disabled: start > 1 || end < 2 },
      { id: MONTH_LABELS[2], label: t('commun_month_full_03'), disabled: start > 2 || end < 3 },
      { id: MONTH_LABELS[3], label: t('commun_month_full_04'), disabled: start > 3 || end < 4 },
      { id: MONTH_LABELS[4], label: t('commun_month_full_05'), disabled: start > 4 || end < 5 },
      { id: MONTH_LABELS[5], label: t('commun_month_full_06'), disabled: start > 5 || end < 6 },
      { id: MONTH_LABELS[6], label: t('commun_month_full_07'), disabled: start > 6 || end < 7 },
      { id: MONTH_LABELS[7], label: t('commun_month_full_08'), disabled: start > 7 || end < 8 },
      { id: MONTH_LABELS[8], label: t('commun_month_full_09'), disabled: start > 8 || end < 9 },
      { id: MONTH_LABELS[9], label: t('commun_month_full_10'), disabled: start > 9 || end < 10 },
      { id: MONTH_LABELS[10], label: t('commun_month_full_11'), disabled: start > 10 || end < 11 },
      { id: MONTH_LABELS[11], label: t('commun_month_full_12'), disabled: start > 11 || end < 12 }
    ];
  }, [rangeMonths]);

  const [state, setState] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue) {
      setState(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) onChange(id, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value, state.month]);

  const handleValueInputChange = ({ target: { value } }) => {
    const payload = { ...state, value: value ? parseFloat(value.replace(',', '.')) : '' };
    setState(payload);
  };

  const handleValueInputBlur = ({ target: { value } }) => {
    const payload = { ...state, value: value ? parseFloat(value.replace(',', '.')) : '' };
    setState(payload);
  };

  const handleMonthInputChange = ({ target: { value } }) => {
    const payload = { ...state, month: value };
    setState(payload);
  };

  return (
    <>
      <div className={styles['root']}>
        <TextFieldCustom
          type="text"
          onChange={handleValueInputChange}
          onBlur={handleValueInputBlur}
          value={state.value}
          name={id}
          id={`${id}-value`}
          disabled={disabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img alt="percentage icon" src={ModuloIcon} />
              </InputAdornment>
            )
          }}
        />
        <SelectCustom
          selectClassName={styles['customized-select-selector']}
          className={styles['customized-select']}
          id={`${id}-month`}
          methodOnChange={handleMonthInputChange}
          value={state.month || ''}
          disabled={disabled}
          placeHolderText={t('commun_month_placeholder')}
          shrinkPlaceholder={false}
          listData={MONTHS_LIST}
        />
      </div>
    </>
  );
};

ValuePerMonthInput.propTypes = {
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func
};

export default memo(ValuePerMonthInput);

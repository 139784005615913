import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { store } from 'store';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getOffers, getTargetingStrategies } from 'api';
import { offerTypes } from 'utils/constants';
import TableProductOffers from 'components/TableProductOffers';
import FiltersContainer, { getInitialFilters } from 'components/Filters';
import OffersDetails from 'pages/OffersDetails';
import Drawer from 'components/Drawer';
import ButtonsCustom from 'components/ButtonsCustom';
import useDynId from 'customHooks/useDynId';
import OfferTopTab from 'components/OfferTopTab';

import styles from '../OfferListSupplier/OfferList.module.scss';

const OfferListRetailer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalState = useContext(store);
  const { id: offerHeadId } = useParams();
  const { state, dispatch } = globalState;
  const { retailers, suppliers, user, offers, queryFilters, targetingStrategiesWithUntargetable } = state;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({ type: 'id', sort: 'desc' });
  const oppositeType = user.userType === 'retailer' ? 'supplier' : 'retailer';
  const tableSize = 30;

  const initFilters = useMemo(
    () => getInitialFilters({ history, queryFilters, targetingStrategies: targetingStrategiesWithUntargetable }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [filters, setFilters] = useState(initFilters);
  const [isLoading, setIsLoading] = useState(true);

  const refreshOffersList = useCallback(async () => {
    setIsLoading(true);
    await getOffers({
      dispatch,
      user: user,
      sortBy: order.type,
      orderBy: order.sort,
      page,
      size: tableSize,
      offerType: offerTypes.RETAILER_PRODUCT_OFFER,
      filters
    });
    setIsLoading(false);
  }, [dispatch, user, page, order, filters]);

  const filtersListToDisplay = [
    'id',
    'opdStatus',
    'title',
    'descriptionTag',
    'targetingMarketingStrategy',
    'privateLabel',
    'budgetSpent',
    'budgetTarget',
    'validityDates'
  ];

  useEffect(() => {
    return () => {
      dispatch({ type: 'OFFERS_RESET' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!targetingStrategiesWithUntargetable.length) {
      getTargetingStrategies();
    }
  }, [targetingStrategiesWithUntargetable.length, retailers]);

  useEffect(() => {
    refreshOffersList();
  }, [refreshOffersList]);

  const handleSort = (type) => {
    setPage(0); // reset the page when changing the sorting
    setOrder({ type, sort: type === order.type && order.sort === 'desc' ? 'asc' : 'desc' });
  };

  const handleUpdateFilters = useCallback((newFilters) => {
    setPage(0); // reset the page when changing the filters
    setFilters({ ...newFilters });
  }, []);

  const handleTableRowClick = ({ id }) => {
    history.push({ pathname: `/offers/offersProductsRetailer/${id[0].value}`, search: history.location.search });
  };

  const onCloseFinished = () => {
    dispatch({ type: 'OFFERS_DETAILS_RESET' });
  };

  const handleClose = () => {
    // reset offer
    history.push({
      pathname: `/offers/offersProductsRetailer`,
      search: history.location.search
    });
  };

  const handleDeletedOffer = () => {
    handleClose();
    setPage(offers.list.length > 1 ? page : Math.max(0, page - 1));
    refreshOffersList();
  };

  return (
    <section className={styles['root']}>
      {user.userType === 'retailer' && <OfferTopTab index={1} />}
      <div className={styles['top-wrapper']}>
        <p className={styles['count-result']} id={useDynId('Xoffer')}>
          {t('commun_offer_product_offer_count', { count: offers.total })}
        </p>
        <Link to={'/offers/offersProductsRetailerImport'} role="link">
          <ButtonsCustom
            classType="action_primary_big"
            id={useDynId('importoffer')}
            text={t('offer_import_header_title')}
          />
        </Link>
      </div>

      <FiltersContainer
        dispatch={dispatch}
        filters={filters}
        filtersListToDisplay={filtersListToDisplay}
        retailersList={retailers}
        suppliersList={suppliers}
        targetingStrategiesList={targetingStrategiesWithUntargetable}
        updateFilters={handleUpdateFilters}
        user={user}
      />
      <TableProductOffers
        isLoading={isLoading}
        handleNextPage={() => setPage(page + 1)}
        handlePrevPage={() => setPage(page - 1)}
        handleSort={handleSort}
        offers={offers}
        orderBy={order.sort}
        offerType="retailer"
        oppositeType={oppositeType}
        page={page}
        size={tableSize}
        sortBy={order.type}
        columnsToHide={['offerType', 'offerHeadType', 'mdd', 'actionButton', 'supplierBudgetType', 'userManager']}
        handleRowClick={handleTableRowClick}
        isUsingFilters={!!Object.keys(filters)?.length}
        clickedRawUniqueKeyValue={offerHeadId?.toString()}
      />
      <Drawer isOpen={!!offerHeadId} onClose={handleClose} onCloseFinished={onCloseFinished}>
        <OffersDetails
          details={offers.details}
          offerHeadId={offerHeadId}
          onUpdated={refreshOffersList}
          onDeleted={handleDeletedOffer}
          user={user}
        />
      </Drawer>
    </section>
  );
};

export default OfferListRetailer;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { offerTypes } from 'utils/constants';
import { withFractionDigits } from 'utils/global';
import ScrollToTop from 'components/ScrollToTop';
import TableCustom from 'components/TableCustom';
import Tags from 'components/Tags';

import { ReactComponent as ArrowDownIcon } from 'assets/arrow_red_DOWN.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/arrow_green_UP.svg';
import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';

const TableProductOffers = ({
  clickedRawUniqueKeyValue,
  isLoading,
  offers,
  handleSort,
  page,
  size,
  handlePrevPage,
  handleNextPage,
  orderBy,
  sortBy,
  oppositeType,
  offerType,
  handleRowClick,
  columnsToHide = [],
  isUsingFilters
}) => {
  const { t } = useTranslation();
  const isSupplierList = offerType === 'supplier';

  const columns = [
    { id: 1, field: 'offerHeadId', headerName: 'ID', type: 'text', sortable: true, sortPath: 'offerHead.id' },
    { id: 2, field: 'status', headerName: t('commun_status_capital'), type: 'component', sortable: true },
    {
      id: 3,
      field: 'offerHeadType',
      headerName: t('_dyn_commun_type'),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.type'
    },
    {
      id: 4,
      field: 'discountValue',
      headerName: t('commun_generosity_short'),
      type: 'text',
      sortable: true
    },
    {
      id: 5,
      field: 'title',
      headerName: t('commun_title_capital'),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.title',
      hasTooltip: true
    },
    {
      id: 6,
      field: 'supplierBudgetType',
      headerName: t('commun_budget'),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.budget.type'
    },
    {
      id: 7,
      field: 'targetMarketing',
      headerName: t('commun_target_marketing'),
      type: 'text',
      sortable: true,
      sortPath: 'targetingStrategy.targetMarketing.title'
    },
    {
      id: 8,
      field: isSupplierList ? oppositeType : 'type',
      headerName: isSupplierList ? t(`_dyn_commun_${oppositeType}`) : t(`_dyn_commun_type`),
      type: 'text',
      hasTooltip: true,
      sortable: isSupplierList,
      sortPath: 'offerHead.budgetSupplierName'
    },
    {
      id: 9,
      field: 'roas',
      headerName: t('commun_tableHead_roas'),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.returnOnAdSpent'
    },
    {
      id: 10,
      field: 'budgetSpent',
      hasTooltip: true,
      headerName: t('commun_budget_spent_capital'),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.budgetSpent'
    },
    {
      id: 11,
      field: 'budgetTarget',
      headerName: t('commun_budget_target_capital'),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.budgetTarget'
    },
    {
      id: 12,
      field: 'validityStartDate',
      headerName: t('commun_validation_dates_capital'),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.validityStartDate'
    },
    {
      id: 13,
      field: 'offerType',
      headerName: t('campaign_details_list_offer_type'),
      type: 'text',
      sortable: true,
      sortPath: 'type'
    },
    {
      id: 14,
      field: 'mdd',
      headerName: t('campaign_details_list_private_label'),
      type: 'text',
      sortable: true,
      sortPath: 'privateLabel'
    },
    {
      id: 15,
      field: 'userManager',
      headerName: t('supplier_userManager'),
      type: 'text'
    },
    { id: 16, field: 'actionButton', type: 'component' }
  ].filter((el) => !columnsToHide.includes(el.field));

  const rows = useMemo(() => {
    return offers.list.map((i) => {
      const targetMarketingTitle = t(`commun_marketingStrategy_${i.targetingStrategy.targetMarketing.code}_title`);
      const isBudgetSpentGeaterThanBudgetTargetOnOPF =
        isSupplierList && i.offerHead?.budgetSpent > i.offerHead?.budgetTarget;

      return {
        id: [{ value: i.offerHead.id }],
        offerHeadId: [{ value: i.offerHead.id }],
        status: <Tags status={i.status} />,
        offerHeadType: [{ value: t(`offer_head_type_${i.offerHead.type}`), color: 'disabled' }],
        discountValue: [
          {
            value:
              i.discountUnit === 'CURRENCY'
                ? t('commun_price', {
                    value: i.discountValue,
                    currency: i.retailer?.currency?.code,
                    maximumFractionDigits: 2
                  })
                : `${i.discountValue} %`
          }
        ],
        title: [
          { value: i.offerHead?.title, color: 'disabled' },
          { value: i.offerHead?.descriptionTag, color: 'proposal' }
        ],
        supplierBudgetType: [
          { value: i.offerHead?.budget?.type ? t(`commun_budget_type_${i.offerHead?.budget?.type}`) : '' }
        ],
        targetMarketing: [{ value: targetMarketingTitle }],
        [oppositeType]: [{ value: i?.offerHead?.budget?.[oppositeType].name, color: 'disabled' }],
        type: [{ value: i.privateLabel ? t('commun_retailer_brand') : t('commun_national_brand'), color: 'disabled' }],
        roas: [
          {
            value: t('commun_number', {
              value: i.offerHead?.transactionalKpis?.returnOnAdSpent ?? '-',
              maximumFractionDigits: withFractionDigits(i.offerHead?.transactionalKpis?.returnOnAdSpent)
            })
          }
        ],
        budgetSpent: [
          {
            rawValue: t('commun_price', {
              currency: i.retailer?.currency?.code,
              maximumFractionDigits: withFractionDigits(i.offerHead?.budgetSpent || 0),
              value: i.offerHead?.budgetSpent ?? '-'
            }),
            value: (
              <span>
                {/* keep this <span> for alignement */}
                {isBudgetSpentGeaterThanBudgetTargetOnOPF && <WarningIcon />}
                {t('commun_price', { value: i.offerHead?.budgetSpent ?? '-', currency: i.retailer?.currency?.code })}
              </span>
            ),
            color: isBudgetSpentGeaterThanBudgetTargetOnOPF && 'alert'
          }
        ],
        budgetTarget: [
          {
            value:
              i.offerHead?.budgetTarget === -1 ? (
                t('commun_unlimited')
              ) : (
                <span>
                  {/* keep this <span> for alignement */}
                  {i.recommendation?.underPerformed ? (
                    <ArrowDownIcon />
                  ) : i.recommendation?.overPerformed ? (
                    <ArrowUpIcon />
                  ) : null}
                  {t('commun_price', { value: i.offerHead?.budgetTarget ?? '-', currency: i.retailer?.currency?.code })}
                </span>
              )
          }
        ],
        validityStartDate: [
          {
            value: (
              <span>
                {t('commun_from_to_dates', {
                  valueFrom: i.offerHead?.validityStartDate,
                  valueTo: i.offerHead?.validityEndDate,
                  useShortYear: true
                })}
                {i.periodsCount > 1 && <em>{`+${i.periodsCount - 1}`}</em>}
              </span>
            ),
            color: 'disabled'
          }
        ],
        offerType: [
          {
            value:
              i.type === offerTypes.SUPPLIER_PRODUCT_OFFER
                ? t('campaign_details_list_offers_supplier')
                : t('campaign_details_list_offers_retailer')
          }
        ],
        mdd: [{ value: i.privateLabel ? t('commun_yes') : t('commun_no') }],
        userManager: [
          {
            value: i.offerHead?.budget?.supplier?.userManager?.id
              ? i.offerHead?.budget?.supplier?.userManager?.firstName ||
                i.offerHead?.budget?.supplier?.userManager?.lastName
                ? `${i.offerHead?.budget?.supplier?.userManager?.firstName || ''} ${
                    i.offerHead?.budget?.supplier?.userManager?.lastName || ''
                  }`
                : i.offerHead?.budget?.supplier?.userManager?.email
              : ''
          }
        ],
        actionButton: null
      };
    });
  }, [offers.list, oppositeType, t, isSupplierList]);

  return (
    <>
      <TableCustom
        isLoading={isLoading}
        rows={rows}
        columns={columns}
        total={offers.total}
        handleSort={handleSort}
        page={page}
        size={size}
        prev={handlePrevPage}
        next={handleNextPage}
        order={orderBy}
        sort={sortBy}
        onRowClick={handleRowClick}
        type="big"
        isUsingFilters={isUsingFilters}
        clickedRawUniqueKeyValue={clickedRawUniqueKeyValue}
      />
      <ScrollToTop />
    </>
  );
};

TableProductOffers.propTypes = {
  columnsToHide: PropTypes.array,
  clickedRawUniqueKeyValue: PropTypes.string,
  handleNextPage: PropTypes.func,
  handlePrevPage: PropTypes.func,
  handleRowClick: PropTypes.func,
  handleSort: PropTypes.func,
  isLoading: PropTypes.bool,
  isUsingFilters: PropTypes.bool,
  offerType: PropTypes.string,
  offers: PropTypes.object,
  oppositeType: PropTypes.string,
  orderBy: PropTypes.string,
  page: PropTypes.number,
  size: PropTypes.number,
  sortBy: PropTypes.string
};

export default TableProductOffers;

import React, { useEffect, useState, useContext, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { store } from 'store';
import {
  getTimezones,
  getCurrencies,
  postRetailer,
  getDams,
  getNotificationTemplates,
  getLocales,
  getCountries,
  getRetailerDetails
} from 'api';
import { NON_POSITIONED_BUDGET_THRESHOLD_PERIODS } from 'utils/constants';
import useDynId from 'customHooks/useDynId';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import FooterButtonsContainer from 'components/LayoutWrappers/Form/FooterButtonsContainer';
import FormLayoutWrapper from 'components/LayoutWrappers/Form';
import GeneralSettings from './GeneralSettings';
import getGuidelinesToEdit from './helpers';
import Guidelines from './Guidelines';
import MarketingStrategies from './MarketingStrategies';
import NonPositionedBudgetObjectivesThresholds from './NonPositionedBudgetObjectivesThresholds';
import OffersSettings from './OffersSettings';
import ProductsRepository from './ProductsRepository';
import SuppliersNotifications from './SuppliersNotifications';
import TechnicalFees from './TechnicalFees';
import RetailersNotifications from './RetailersNotifications';

import './AddRetailerForm.scss';

const currentYear = new Date().getFullYear();

const initialState = {
  allowedExtensions: ['PNG', 'JPG'],
  budgetEstimatedEnabled: false,
  code: '',
  countryCode: '',
  currency: {},
  damName: 'NONE',
  guidelines: {
    EN: {},
    FR: {}
  },
  logo: null,
  name: '',
  notifyRetailersEnabled: false,
  notifySuppliersEnabled: false,
  offerSegmentEnabled: true,
  positionedTargetsLevels: [
    { month: null, period: NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.START_OF_YEAR, value: null },
    { month: null, period: NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.INTERMEDIATE, value: null },
    { month: null, period: NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.END_OF_YEAR, value: null }
  ],
  settingCashCouponVllCodeFormat: false,
  settingOfferAdditionalInformationSize: '',
  settingOfferImageHorizontalSize: '',
  settingOfferImageVerticalSize: '',
  settingOfferTitleSize: '',
  settingProductLevelLabelOne: '',
  settingProductLevelLabelTwo: '',
  settingProductLevelLabelThree: '',
  settingProductLevelLabelFour: '',
  targetingEnabled: true,
  technicalsFees: [
    { value: '', year: currentYear + 1 },
    { value: '', year: currentYear }
  ],
  timezone: '',
  type: 'CLIENT'
};

const AddRetailer = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { id: retailerId } = useParams();
  const {
    state: { retailer }
  } = useContext(store);
  const isCreation = !retailerId;

  const generalSettingsBlockRef = useRef(null);
  const technicalFeesBlockRef = useRef(null);
  const nonPositionedBudgetObjectivesThresholdsBlockRef = useRef(null);
  const offersSettingsBlockRef = useRef(null);
  const productsRepositoryBlockRef = useRef(null);
  const suppliersNotificationsBlockRef = useRef(null);
  const retailersNotificationsBlockRef = useRef(null);

  const [dialogOpened, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [retailerState, setRetailerState] = useState(JSON.parse(JSON.stringify(initialState)));
  const [initState, setInitState] = useState(JSON.parse(JSON.stringify(initialState)));

  useEffect(() => {
    const loadMandatoryData = async () => {
      await Promise.all([
        getTimezones(),
        getCurrencies(),
        getCountries(i18n?.language),
        getNotificationTemplates(),
        getDams(),
        getLocales()
      ]);
    };
    const loadOffer = async () => {
      await getRetailerDetails(retailerId);
    };

    loadMandatoryData();

    if (retailerId) {
      loadOffer();
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (retailer.id) {
      const positionedTargetsLevelsStart = retailer.positionedTargetsLevels?.find(
        (level) => level.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.START_OF_YEAR
      );
      const positionedTargetsLevelsHalf = retailer.positionedTargetsLevels?.find(
        (level) => level.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.INTERMEDIATE
      );
      const positionedTargetsLevelsEnd = retailer.positionedTargetsLevels?.find(
        (level) => level.period === NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.END_OF_YEAR
      );

      const payload = {
        ...retailer,
        currency: retailer.currency.id,
        localeNotifyRetailer: retailer.notificationInformationRetailer?.locale,
        localeNotifySupplier: retailer.notificationInformationSupplier?.locale,
        positionedTargetsLevels: [
          {
            month: positionedTargetsLevelsStart?.month ?? '',
            period: NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.START_OF_YEAR,
            value: positionedTargetsLevelsStart?.value ?? ''
          },
          {
            month: positionedTargetsLevelsHalf?.month ?? '',
            period: NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.INTERMEDIATE,
            value: positionedTargetsLevelsHalf?.value ?? ''
          },
          {
            month: positionedTargetsLevelsEnd?.month ?? '',
            period: NON_POSITIONED_BUDGET_THRESHOLD_PERIODS.END_OF_YEAR,
            value: positionedTargetsLevelsEnd?.value ?? ''
          }
        ],
        retailerHiddenCopyReceivers:
          retailer.notificationInformationRetailer?.hiddenCopyReceivers?.map((el) => ({
            email: el.mail
          })) || [],
        retailerReceivers:
          retailer.notificationInformationRetailer?.receivers?.map((el) => ({
            email: el.mail
          })) || [],
        supplierHiddenCopyReceivers:
          retailer.notificationInformationSupplier?.hiddenCopyReceivers?.map((el) => ({
            email: el.mail
          })) || [],
        technicalsFees: [
          {
            value: retailer.technicalsFees?.find((fee) => fee.year === currentYear + 1)?.value ?? '',
            year: currentYear + 1
          },
          { value: retailer.technicalsFees?.find((fee) => fee.year === currentYear)?.value ?? '', year: currentYear },
          {
            value: retailer.technicalsFees?.find((fee) => fee.year === currentYear - 1)?.value ?? '',
            year: currentYear - 1
          },
          {
            value: retailer.technicalsFees?.find((fee) => fee.year === currentYear - 2)?.value ?? '',
            year: currentYear - 2
          }
        ],
        templateIdNotifyRetailer: retailer.notificationInformationRetailer?.templateId,
        templateIdNotifySupplier: retailer.notificationInformationSupplier?.templateId,
        timezone: retailer.timezone.id
      };
      setRetailerState(JSON.parse(JSON.stringify(payload)));
      setInitState(JSON.parse(JSON.stringify(payload)));
    }
  }, [retailer]);

  const handleCancel = () => {
    if (JSON.stringify(retailerState) !== JSON.stringify(initState)) {
      setDialogOpen(true);
    } else {
      handleDialogConfirm();
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleDialogConfirm = () => {
    history.goBack();
  };

  const handleSubmit = async () => {
    setLoading(true);

    // error checks order matters
    const retailersNotificationsErrors = retailersNotificationsBlockRef.current.checkHasError();
    const suppliersNotificationsErrors = suppliersNotificationsBlockRef.current.checkHasError();
    const productsRepositoryErrors = productsRepositoryBlockRef.current.checkHasError();
    const offersSettingsErrors = offersSettingsBlockRef.current.checkHasError();
    const nonPositionedBudgetObjectivesThresholdsErrors =
      nonPositionedBudgetObjectivesThresholdsBlockRef.current.checkHasError();
    const technicalsFeesErrors = technicalFeesBlockRef.current.checkHasError();
    const generalSettingsErrors = await generalSettingsBlockRef.current.checkHasError();

    if (
      generalSettingsErrors ||
      offersSettingsErrors ||
      productsRepositoryErrors ||
      suppliersNotificationsErrors ||
      retailersNotificationsErrors ||
      technicalsFeesErrors ||
      nonPositionedBudgetObjectivesThresholdsErrors
    ) {
      setLoading(false);
      return;
    }

    // return to the detail view if nothing has changed
    if (JSON.stringify(retailerState) === JSON.stringify(initState)) {
      handleDialogConfirm();
      return;
    }

    const payload = {
      allowedExtensions: retailerState.allowedExtensions,
      budgetEstimatedEnabled: retailerState.budgetEstimatedEnabled,
      code: retailerState.code,
      countryCode: retailerState.countryCode,
      currency: { id: retailerState.currency },
      damName: retailerState.damName === 'NONE' ? null : retailerState.damName,
      logo: retailerState.logo,
      name: retailerState.name,
      notifyRetailersEnabled: retailerState.notifyRetailersEnabled,
      notifySuppliersEnabled: retailerState.notifySuppliersEnabled,
      offerSegmentEnabled: retailerState.offerSegmentEnabled,
      settingCashCouponVllCodeFormat: retailerState.settingCashCouponVllCodeFormat,
      settingOfferAdditionalInformationSize: retailerState.settingOfferAdditionalInformationSize,
      settingOfferImageHorizontalSize: retailerState.settingOfferImageHorizontalSize,
      settingOfferImageVerticalSize: retailerState.settingOfferImageVerticalSize,
      settingOfferTitleSize: retailerState.settingOfferTitleSize,
      settingProductLevelLabelOne: retailerState.settingProductLevelLabelOne,
      settingProductLevelLabelTwo: retailerState.settingProductLevelLabelTwo,
      settingProductLevelLabelThree: retailerState.settingProductLevelLabelThree,
      settingProductLevelLabelFour: retailerState.settingProductLevelLabelFour,
      targetingEnabled: retailerState.targetingEnabled,
      timezone: { id: retailerState.timezone },
      type: retailerState.type
    };

    let notificationPayload =
      retailerState.notifySuppliersEnabled || retailerState.notifyRetailersEnabled ? [] : undefined;

    if (retailerState.notifySuppliersEnabled) {
      notificationPayload.push({
        hiddenCopyReceivers: retailerState.supplierHiddenCopyReceivers?.map((el) => ({ mail: el.email, name: '' })),
        id: retailerState.notificationInformationSupplier?.id,
        locale: retailerState.localeNotifySupplier,
        receivers: [],
        templateId: parseInt(retailerState.templateIdNotifySupplier, 10),
        type: 'SUPPLIER'
      });
    }

    if (retailerState.notifyRetailersEnabled) {
      notificationPayload.push({
        hiddenCopyReceivers: retailerState.retailerHiddenCopyReceivers?.map((el) => ({ mail: el.email, name: '' })),
        id: retailerState.notificationInformationRetailer?.id,
        locale: retailerState.localeNotifyRetailer,
        receivers: retailerState.retailerReceivers?.map((el) => ({ mail: el.email, name: '' })),
        templateId: parseInt(retailerState.templateIdNotifyRetailer, 10),
        type: 'RETAILER'
      });
    }

    // check if technical fees have changed and should be patched
    // about technical fees : only current year and year +1 can be filled and changed (year -1 and year -2 should be read only)
    // so keep only current year and year +1 in the payload and keep only the ones who have changed
    const newTechnicalsFees = retailerState.technicalsFees
      .filter((fee) => fee.year === currentYear || fee.year === currentYear + 1)
      .filter((fee) => {
        const initialValue = initState.technicalsFees.find((i) => i.year === fee.year)?.value;
        return fee.value !== initialValue;
      })
      .map((fee) => ({ value: fee.value, year: fee.year }));

    // check if positionedTargetsLevels have changed and should be patched
    // keep only the ones who have changed in the payload
    // change empty fields '' to null
    const newPositionedTargetsLevels = retailerState.positionedTargetsLevels
      .filter((level) => {
        const initialValues = initState.positionedTargetsLevels.find((i) => i.period === level.period);
        return level.month !== initialValues.month || level.value !== initialValues.value;
      })
      .map((level) => ({ month: level.month || null, period: level.period, value: level.value ?? null }));

    const success = await postRetailer({
      isCreation,
      data: payload,
      notificationPayload,
      retailer: retailer,
      guidelines: getGuidelinesToEdit(initState, retailerState),
      technicalsFees: !!newTechnicalsFees.length ? newTechnicalsFees : null,
      positionedTargetsLevels: !!newPositionedTargetsLevels.length ? newPositionedTargetsLevels : null
    });

    if (success) {
      handleDialogConfirm();
    } else {
      setLoading(false);
    }
  };

  const handleChangeState = (data = {}) => {
    setRetailerState((state) => ({ ...state, ...data }));
  };

  return (
    <>
      <FormLayoutWrapper
        headerTitle={isCreation ? t('retailers_creation_title') : t('retailers_modification_title')}
        headerSubTitle={t('retailers_creation_desc')}
        onClose={handleCancel}
      >
        <div className="add-retailer-form">
          <GeneralSettings
            isCreation={isCreation}
            onChangeState={handleChangeState}
            retailerState={retailerState}
            ref={generalSettingsBlockRef}
          />
          <TechnicalFees
            data={retailerState.technicalsFees}
            onChangeState={handleChangeState}
            ref={technicalFeesBlockRef}
          />
          <NonPositionedBudgetObjectivesThresholds
            data={retailerState.positionedTargetsLevels}
            onChangeState={handleChangeState}
            ref={nonPositionedBudgetObjectivesThresholdsBlockRef}
          />
          <OffersSettings
            onChangeState={handleChangeState}
            retailerState={retailerState}
            ref={offersSettingsBlockRef}
          />
          <Guidelines onChangeState={handleChangeState} guidelinesState={retailerState.guidelines} />
          <ProductsRepository
            onChangeState={handleChangeState}
            retailerState={retailerState}
            ref={productsRepositoryBlockRef}
          />
          <MarketingStrategies checked={retailerState.offerSegmentEnabled} onChangeState={handleChangeState} />
          <SuppliersNotifications
            onChangeState={handleChangeState}
            retailerState={retailerState}
            ref={suppliersNotificationsBlockRef}
          />
          <RetailersNotifications
            onChangeState={handleChangeState}
            retailerState={retailerState}
            ref={retailersNotificationsBlockRef}
          />
          <FooterButtonsContainer
            confirmLabel={t('commun_button_save')}
            disabled={loading}
            isLoading={loading}
            onConfirm={handleSubmit}
          />
        </div>
      </FormLayoutWrapper>

      <ContentDialog
        centerText
        isOpen={dialogOpened}
        handleClose={handleCloseDialog}
        maxWidth="xs"
        title={
          <>
            {isCreation
              ? t('retailers_creation_confirmation_message')
              : t('retailers_modification_confirmation_message')}
            <br />
            <br />
            {t('retailers_creation_confirmation_message_not_saved')}
          </>
        }
      >
        <div>
          <ButtonsCustom
            id={useDynId('cancelDialogButton')}
            classType="canceled"
            text={t('retailers_creation_confirmation_no')}
            method={handleCloseDialog}
          />
          <ButtonsCustom
            id={useDynId('confirmDialogButton')}
            classType="action_primary_big"
            text={t('retailers_creation_confirmation_yes')}
            method={handleDialogConfirm}
          />
        </div>
      </ContentDialog>
    </>
  );
};

export default AddRetailer;

import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { scrollToElementById } from 'utils/global';

import FormManager from 'components/FormManager';

const RetailersNotifications = forwardRef(({ onChangeState, retailerState }, ref) => {
  const { t } = useTranslation();
  const {
    state: { locales, notificationTemplates },
  } = useContext(store);

  const [errorMsgTemplateIdNotifyRetailer, setErrorMsgTemplateIdNotifyRetailer] = useState(null);
  const [errorMsgLocaleNotifyRetailer, setErrorMsgLocaleNotifyRetailer] = useState(null);
  const [errorMsgRetailerReceivers, setErrorMsgRetailerReceivers] = useState(null);
  const [errorMsgRetailerHiddenCopyReceivers, setErrorMsgRetailerHiddenCopyReceivers] = useState(null);

  useImperativeHandle(ref, () => ({
    checkHasError() {
      return handleCheckError();
    },
  }));

  const handleCheckError = () => {
    let errorFieldIdList = [];

    if (retailerState.notifyRetailersEnabled && !retailerState.templateIdNotifyRetailer) {
      setErrorMsgTemplateIdNotifyRetailer(t('retailers_creation_retailer_notification_template_error_required'));
      errorFieldIdList.push('field-notifyRetailersEnabled');
    } else {
      setErrorMsgTemplateIdNotifyRetailer();
    }

    if (retailerState.notifyRetailersEnabled && !retailerState.localeNotifyRetailer) {
      setErrorMsgLocaleNotifyRetailer(t('retailers_creation_retailer_notification_region_error_required'));
      errorFieldIdList.push('field-templateIdNotifyRetailer');
    } else {
      setErrorMsgLocaleNotifyRetailer();
    }

    if (retailerState.notifyRetailersEnabled && !retailerState.retailerReceivers?.length) {
      setErrorMsgRetailerReceivers(t('retailers_creation_retailer_receivers_error_required'));
      errorFieldIdList.push('field-localeNotifyRetailer');
    } else {
      setErrorMsgRetailerReceivers();
    }

    if (retailerState.notifyRetailersEnabled && !retailerState.retailerHiddenCopyReceivers?.length) {
      setErrorMsgRetailerHiddenCopyReceivers(t('retailers_creation_retailer_hiddenCopyReceivers_error_required'));
      errorFieldIdList.push('field-retailerReceivers');
    } else {
      setErrorMsgRetailerHiddenCopyReceivers();
    }

    if (errorFieldIdList.length) {
      setTimeout(() => {
        scrollToElementById(errorFieldIdList[0]);
      }, 150);
    }

    return !!errorFieldIdList.length;
  };

  return (
    <FormManager
      data={{
        title: t('retailers_creation_retailers_notifications'),
        subTitle: t('retailers_creation_retailers_notifications_description'),
        fieldsets: [
          {
            id: 'creation-form-notify-retailers-fieldset',
            classnames: ['no-padding-bottom'],
            fields: [
              {
                outerContainerClass: ['without-margin-top'],
                label: t('retailers_creation_retailers_notifications'),
                defaultValue: retailerState?.notifyRetailersEnabled || false,
                type: 'Switch',
                id: 'notifyRetailersEnabled',
                switchLabels: {
                  off: t('retailers_creation_notifications_off'),
                  on: t('retailers_creation_notifications_on'),
                },
                fieldProps: { size: 'small' },
                onFieldChange: (value) => {
                  onChangeState({ notifyRetailersEnabled: value });
                },
              },
            ],
          },
          {
            id: 'creation-form-retailers-notification-fieldset',
            requirements: [{ field: 'notifyRetailersEnabled', eq: [true] }],
            fields: [
              {
                outerContainerClass: ['without-margin-top'],
                label: t('retailers_creation_notifications_template'),
                placeholder: t('retailers_creation_notifications_template_placeholder'),
                defaultValue: retailerState?.templateIdNotifyRetailer || '',
                type: 'Select',
                fieldProps: { listData: notificationTemplates, labelAttribute: 'name' },
                id: 'templateIdNotifyRetailer',
                error: errorMsgTemplateIdNotifyRetailer,
                onFieldChange: (value) => {
                  setErrorMsgTemplateIdNotifyRetailer();
                  onChangeState({ templateIdNotifyRetailer: value });
                },
              },
              {
                label: t('retailers_creation_notifications_region'),
                placeholder: t('retailers_creation_notifications_region_placeholder'),
                defaultValue: retailerState?.localeNotifyRetailer || '',
                type: 'Select',
                fieldProps: { listData: locales, labelAttribute: 'locale' },
                id: 'localeNotifyRetailer',
                error: errorMsgLocaleNotifyRetailer
                  ? t('retailers_creation_retailer_notification_region_error_required')
                  : null,
                onFieldChange: (value) => {
                  setErrorMsgLocaleNotifyRetailer();
                  onChangeState({ localeNotifyRetailer: value });
                },
              },
              {
                label: t('retailers_creation_notifications_recipients'),
                subLabel: t('retailers_creation_notifications_recipients_description'),
                type: 'UsersList',
                defaultValue: retailerState.retailerReceivers,
                requiredErrorMsg: errorMsgRetailerReceivers,
                id: 'retailerReceivers',
                onFieldChange: (list) => {
                  setErrorMsgRetailerReceivers();
                  onChangeState({ retailerReceivers: list });
                },
              },
              {
                label: t('retailers_creation_notifications_bcc'),
                subLabel: t('retailers_creation_notifications_bcc_description'),
                type: 'UsersList',
                defaultValue: retailerState.retailerHiddenCopyReceivers,
                id: 'retailerHiddenCopyReceivers',
                requiredErrorMsg: errorMsgRetailerHiddenCopyReceivers,
                onFieldChange: (list) => {
                  setErrorMsgRetailerHiddenCopyReceivers();
                  onChangeState({ retailerHiddenCopyReceivers: list });
                },
              },
            ],
          },
        ],
      }}
    />
  );
});

RetailersNotifications.propTypes = {
  onChangeState: PropTypes.func,
  retailerState: PropTypes.object,
};

export default RetailersNotifications;

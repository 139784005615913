import React, { useState, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { store } from 'store';
import {
  getAllSuppliers,
  getAllReferentialUniverses,
  getSupplier,
  getUserSuppliers,
  postSupplier,
  updateSupplier
} from 'api';
import { SUPPLIER_NOTE_MAX_LENGTH } from 'utils/constants';
import useDynId from 'customHooks/useDynId';

import FormManager from 'components/FormManager';
import FormStepTitle from 'components/FormManager/FormStepTitle';
import FormLayoutWrapper from 'components/LayoutWrappers/Form';
import ProductRefList from './ProductRefList';
import BudgetsFields from './BudgetsFields';
import UserManagerField from './UserManagerField';

const AddSupplierForm = ({
  disabled,
  inPanel,
  onFinish = () => {},
  onCancel = () => {},
  supplier = {},
  title,
  triggerChangeKeyForm
}) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state } = globalState;
  const { user } = state;
  const [keyForm, setKeyForm] = useState(0);
  const [loading, setLoading] = useState(false);
  const [universesList, setUniversesList] = useState([]);
  const [universeSelected, setUniverseSelected] = useState(null);
  const [userSupplierList, setUserSupplierList] = useState([]);
  const [userManagerEmail, setUserManagerEmail] = useState(null);
  const [prevUserSupplierList, setPrevInitialSupplierList] = useState();
  const [hasTwiceSameBudgetType, setHasTwiceSameBudgetType] = useState(false);
  const [prevBudgets, setPrevBudgets] = useState();
  const initPrevBudgetsDone = useRef(false);

  useEffect(() => {
    const fetchUniversesList = async () => {
      const list = await getAllReferentialUniverses();
      if (!!list.length) {
        setUniversesList(list);
      }
    };
    fetchUniversesList();
  }, []);

  useEffect(() => {
    const fetchUserSuppliers = async () => {
      const userSupplierListData = await getUserSuppliers(supplier.id);
      if (userSupplierListData) {
        setUserSupplierList(userSupplierListData);
        setPrevInitialSupplierList(userSupplierListData);
      }
    };

    if (supplier.id) {
      fetchUserSuppliers();
      setUserManagerEmail(supplier.userManager?.email);
      setUniverseSelected(supplier.universe);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplier.id]);

  useEffect(() => {
    if (supplier.budgets?.length && !initPrevBudgetsDone.current) {
      initPrevBudgetsDone.current = true;
      setPrevBudgets(JSON.parse(JSON.stringify(supplier.budgets)));
    }
  }, [supplier.budgets]);

  useEffect(() => {
    if (!inPanel && triggerChangeKeyForm) {
      setKeyForm(keyForm + 1);
    }
    // eslint-disable-next-line
  }, [triggerChangeKeyForm]);

  const handleCancel = () => {
    initPrevBudgetsDone.current = false;
    setPrevBudgets();
    setUserSupplierList([]);
    setPrevInitialSupplierList();
    setKeyForm(keyForm + 1);
    onCancel();
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    let result;
    if (supplier.id) {
      result = await updateSupplier({
        data,
        initialBudgets: prevBudgets,
        initialUserSupplierList: prevUserSupplierList,
        supplier,
        user
      });
    } else {
      result = await postSupplier({ data, user });
    }
    setLoading(false);

    if (result) {
      await getAllSuppliers(user.id);
      initPrevBudgetsDone.current = false;
      setPrevBudgets();
      setUserSupplierList([]);
      setPrevInitialSupplierList();
      onFinish();
    } else {
      // refresh data if something went wrong
      if (supplier.id) {
        await getSupplier({ retailerId: user.id, supplierId: supplier.id });
      }
    }
  };

  return (
    <section>
      <FormLayoutWrapper asPanel headerTitle={title}>
        <FormManager
          key={keyForm}
          loading={loading}
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          data={{
            raiseErrorOnSubmit: true,
            fieldsets: [
              {
                id: 'add-supllier',
                fields: [
                  {
                    type: 'CustomContent',
                    id: 'creation-form-title-1',
                    component: <FormStepTitle title={t('supplier_name')} />
                  },
                  {
                    placeholder: t('supplier_name'),
                    type: 'TextField',
                    defaultValue: supplier.name,
                    id: 'supplier_name',
                    fieldProps: { autoFocus: !supplier.id },
                    validations: [
                      {
                        func: (value) => !!value,
                        message: t('commun_field_required')
                      }
                    ],
                    disabled: disabled
                  },
                  {
                    type: 'CustomContent',
                    id: 'creation-form-products-title',
                    classnames: ['with-margin-top'],
                    component: (
                      <FormStepTitle
                        title={t('supplier_add_product')}
                        subtitle={t('supplier_select_product')}
                        marginTop="medium"
                      />
                    )
                  },
                  {
                    placeholder: t('supplier_choose_product'),
                    disabled: disabled,
                    type: <ProductRefList idField="product_ref_list" externalSupplierId={supplier.id} />,
                    id: 'product_ref_list',
                    validations: [
                      {
                        func: (value) => !!value?.linked?.length,
                        message: t('commun_field_required')
                      }
                    ]
                  },
                  {
                    type: 'CustomContent',
                    id: 'creation-form-universes-title',
                    component: (
                      <FormStepTitle
                        title={t('supplier_add_universe')}
                        subtitle={t('supplier_create_universe')}
                        marginTop="medium"
                        isOptional
                      />
                    )
                  },
                  {
                    type: 'Select',
                    id: 'universe',
                    placeholder: t('supplier_select_universe'),
                    defaultValue: universeSelected || '',
                    fieldProps: {
                      listData: [
                        { id: '', label: t('commun_none_bis') },
                        ...universesList
                          .map((universe) => ({
                            id: universe,
                            label: t(`universe_${universe}`)
                          }))
                          .sort((a, b) => {
                            if (a.label < b.label) {
                              return -1;
                            }
                            if (a.label > b.label) {
                              return 1;
                            }
                            return 0;
                          })
                      ],
                      labelAttribute: 'label'
                    },
                    onFieldChange: setUniverseSelected,
                    disabled: disabled
                  },
                  {
                    type: 'CustomContent',
                    id: 'creation-form-budgets-title',
                    component: (
                      <FormStepTitle
                        title={t('supplier_add_budget')}
                        subtitle={t('supplier_create_budget')}
                        marginTop="medium"
                      />
                    )
                  },
                  {
                    type: (
                      <BudgetsFields
                        budgets={supplier.budgets?.length ? supplier.budgets : undefined}
                        currency={user.currency.symbol}
                        hasError={hasTwiceSameBudgetType}
                        idField="budgets_fields"
                        supplierId={supplier.id}
                      />
                    ),
                    disabled: disabled,
                    id: 'budgets_fields',
                    onFieldChange: (value) => {
                      const hasError = value && value[0]?.type && value[1]?.type && value[0]?.type === value[1]?.type;
                      setHasTwiceSameBudgetType(hasError);
                    }
                  },
                  {
                    type: 'CustomContent',
                    id: 'creation-form-budgets-userManager',
                    component: (
                      <FormStepTitle
                        title={t('supplier_link_userManager')}
                        subtitle={t('supplier_select_userManager')}
                        marginTop="medium"
                        isOptional
                      />
                    )
                  },
                  {
                    type: <UserManagerField />,
                    defaultValue: userManagerEmail,
                    id: 'userManager_email',
                    disabled: disabled,
                    onFieldChange: setUserManagerEmail
                  },
                  {
                    type: 'CustomContent',
                    id: 'creation-form-budgets-users',
                    component: (
                      <FormStepTitle
                        title={t('supplier_link_emails')}
                        subtitle={t('supplier_select_user')}
                        marginTop="medium"
                        isOptional
                      />
                    )
                  },
                  {
                    type: 'UsersList',
                    defaultValue: userSupplierList,
                    fieldProps: { showTitle: true },
                    id: 'users_list',
                    disabled: disabled,
                    openEditForm: supplier.id,
                    onFieldChange: setUserSupplierList
                  },
                  {
                    type: 'CustomContent',
                    id: 'creation-form-budgets-note',
                    component: (
                      <FormStepTitle title={t('supplier_add_note_dialog_title')} marginTop="medium" isOptional />
                    )
                  },
                  {
                    type: 'TextField',
                    id: 'supplier_note',
                    defaultValue: supplier?.note?.replaceAll('</br>', '\n') || '',
                    fieldProps: {
                      InputProps: {
                        inputProps: { maxLength: SUPPLIER_NOTE_MAX_LENGTH },
                        min: 3
                      },
                      multiline: true
                    },
                    counter: SUPPLIER_NOTE_MAX_LENGTH
                  }
                ]
              }
            ],
            cancel: {
              disabled: disabled || loading,
              id: useDynId('cancel'),
              label: supplier.id ? t('commun_cancel') : t('commun_button_erase')
            },
            submit: {
              disabled: disabled || hasTwiceSameBudgetType,
              id: useDynId('save'),
              label: supplier.id ? t('commun_supplier_edit') : t('commun_add'),
              tooltip: disabled ? t('supplier_warning_limit') : ''
            }
          }}
        />
      </FormLayoutWrapper>
    </section>
  );
};

AddSupplierForm.propTypes = {
  disabled: PropTypes.bool,
  inPanel: PropTypes.bool,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  supplier: PropTypes.object,
  title: PropTypes.string,
  triggerChangeKeyForm: PropTypes.bool
};

export default AddSupplierForm;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getUntargetableProductCampaignOffers, updateUntargetableProductCampaignOffers } from 'api';
import ButtonsCustom from 'components/ButtonsCustom';
import Tooltip from 'components/Tooltip';
import OfferList from './OfferList';
import ContentDialog from 'components/ContentDialog';
import DialogSearchDefaultOffers from './DialogSearchDefaultOffers';

import { ReactComponent as ArrowIcon } from 'assets/14px_arrow.svg';
import InfoIcon from 'assets/24px_alert_information.svg';
import styles from './DefaultOffersContainer.module.scss';

const DefaultOffersContainer = ({ campaignProduct, hasError, hasUntargetableOffersAvailable, isLoading }) => {
  const { t } = useTranslation();
  const [isAddingOffers, setIsAddingOffers] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleSubmit = async (payload) => {
    setIsAddingOffers(true);
    await updateUntargetableProductCampaignOffers(payload);
    await getUntargetableProductCampaignOffers({ campaignProduct });
    setIsAddingOffers(false);
    setOpenModal(false);
  };

  if (isLoading && !isAddingOffers) {
    return (
      <div className={styles['defaultOffersContainer']}>
        <div className={styles['frame']}>{t('commun_loading')}</div>
      </div>
    );
  }
  if (!isLoading && !hasUntargetableOffersAvailable) {
    return (
      <div className={styles['defaultOffersContainer']}>
        <div className={styles['frame']}>{t('campaign_product_step3_defaultOffers_none_available')}</div>
      </div>
    );
  }
  if (campaignProduct.parameters.offersMinimumNumber < 1) {
    return null;
  }

  return (
    <>
      <div className={styles['defaultOffersContainer']}>
        <div className={`${styles['frame']} ${hasError ? styles['error'] : ''}`}>
          <Tooltip placement="right" title={t('campaign_product_step3_defaultOffers_frame_add_offers_tooltip')}>
            <img alt="info icon" src={InfoIcon} />
          </Tooltip>
          <div>
            <p>{`${t('campaign_product_step3_defaultOffers_frame_add_offers_1', {
              count: campaignProduct.parameters.offersMinimumNumber,
            })} ${t('campaign_product_step3_defaultOffers_frame_add_offers_2', {
              count: campaignProduct.untargetableProductCampaignOffers.total || 0,
            })}`}</p>
            <ButtonsCustom
              classType="link_primary"
              disabled={isLoading || isAddingOffers}
              method={handleOpenModal}
              startIconCustom={<ArrowIcon />}
              text={t('campaign_product_step3_defaultOffers_frame_add_offers_button_label')}
            />
          </div>
        </div>
        {!!campaignProduct.untargetableProductCampaignOffers.total && (
          <OfferList campaignProduct={campaignProduct} isAddingOrDeletingOffers={isLoading || isAddingOffers} />
        )}
      </div>
      <ContentDialog centerText isOpen={openModal} isLoading={isAddingOffers} handleClose={handleCloseModal} spaceless>
        <DialogSearchDefaultOffers
          campaignProduct={campaignProduct}
          isAddingOffers={isAddingOffers}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
        />
      </ContentDialog>
    </>
  );
};

DefaultOffersContainer.propTypes = {
  campaignProduct: PropTypes.object.isRequired,
  hasError: PropTypes.bool,
  hasUntargetableOffersAvailable: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default DefaultOffersContainer;

import React from 'react';
import PropTypes from 'prop-types';

import styles from './Operator.module.scss';

const Operator = ({ onClick, text, type = 'primary' }) => {
  return (
    <div
      className={`${styles['root']} ${onClick ? styles['asButton'] : ''} ${styles[type]}`}
      onClick={onClick || null}
      role={onClick ? 'button' : ''}
    >
      {text}
    </div>
  );
};

Operator.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'secondary']),
};

export default Operator;

import React, { memo } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import GeneralInfoBox from 'components/GeneralInfoBox';

import styles from './ConfirmationStep.module.scss';

const ConfirmationStep = ({ campaignCashCoupon, cashCouponsListAll, segmentsListAll }) => {
  const { t } = useTranslation();

  const segmentIdArray = segmentsListAll.map((segment) => segment.id);
  const couponIdArray = cashCouponsListAll.map((coupon) => coupon.id);

  return (
    <div className={styles['root']}>
      <div className={styles['campaign-title']}>{campaignCashCoupon.title}</div>
      <div className={styles['settings-container']}>
        <GeneralInfoBox label={t('commun_validation_dates')}>
          <span>
            {t('commun_from_to_dates', {
              valueFrom: campaignCashCoupon.date.startDate,
              valueTo: campaignCashCoupon.date.endDate,
            })}
          </span>
        </GeneralInfoBox>
      </div>
      <div className={styles['divider']}></div>

      <div className={styles['counter']}>{t('commun_coupon_count', { count: campaignCashCoupon.offers.length })}</div>

      <div className={styles['table']}>
        {campaignCashCoupon.offers.map((item, index) => (
          <div className={`${styles['table-content']} ${index % 2 === 0 ? styles['odd'] : ''}`} key={index} id={index}>
            <p className={styles['segment']}>
              {`${
                segmentIdArray.indexOf(item.retailerSegment.id) !== -1 &&
                segmentsListAll[segmentIdArray.indexOf(item.retailerSegment.id)].name
              } - ${
                segmentIdArray.indexOf(item.retailerSegment.id) !== -1 &&
                t('commun_customer_count', {
                  count: segmentsListAll[segmentIdArray.indexOf(item.retailerSegment.id)].customerCount,
                })
              }`}
            </p>
            <div className={styles['d-flex']}>
              <div className={styles['title']}>
                {couponIdArray.indexOf(item.cashCoupon.id) !== -1 &&
                  `${cashCouponsListAll[couponIdArray.indexOf(item.cashCoupon.id)].title} - ${item.cashCoupon.id}`}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
ConfirmationStep.propTypes = {
  campaignCashCoupon: PropTypes.object,
  cashCouponsListAll: PropTypes.array,
  segmentsListAll: PropTypes.array,
};
export default memo(ConfirmationStep);

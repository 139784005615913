export const apiUrl = window.REACT_APP_API_URL;
export const damUrl = window.REACT_APP_DAM_CONNECTOR;
export const apiUrlCouponingEngine = window.REACT_APP_API_URL_COUPONING_ENGINE;
export const apiUrlSegmentManager = window.REACT_APP_API_URL_SEGMENT_MANAGER;
export const apiUrlSegmentManagerEstimateCustomerCount =
  window.REACT_APP_API_URL_SEGMENT_MANAGER_ESTIMATE_CUSTOMER_COUNT;

export const exitModalTypes = {
  CAMPAIGN_CASH_COUPON: 'CAMPAIGN_CASH_COUPON',
  CAMPAIGN_EDITION: 'CAMPAIGN_EDITION',
  CAMPAIGN_PRODUCT: 'CAMPAIGN_PRODUCT',
  OFFER_CREATION: 'OFFER_CREATION',
  OFFER_EDITION: 'OFFER_EDITION',
  SEGMENT: 'SEGMENT'
};

export const campaignDeletionStatuses = {
  FAIL_DELETE: 'FAIL_DELETE',
  FAIL_DELETE_DATA: 'FAIL_DELETE_DATA',
  IN_PROCESS_TO_DELETE: 'IN_PROCESS_TO_DELETE'
};

export const campaignStates = {
  TO_COME: 'TO_COME', // à venir
  IN_PROGRESS: 'IN_PROGRESS', // en cours
  TERMINATED: 'TERMINATED' // passé,
};

export const campaignStatuses = {
  VALIDATED: 'VALIDATED',
  DRAFT: 'DRAFT'
};

export const campaignTargetingStatuses = {
  NOT_REQUESTED: 'NOT_REQUESTED',
  REQUESTED: 'REQUESTED',
  IN_PROCESS: 'IN_PROCESS',
  FAIL: 'FAIL',
  FAIL_DATA: 'FAIL_DATA',
  SUCCESS: 'SUCCESS'
};

export const discountTypes = [
  { id: 1, label: 'loyalty_discount', denomination: 'LOYALTY' },
  { id: 2, label: 'immediate_discount', denomination: 'IMMEDIATE' }
];

// do not use it directly -> use instead state.user.discountUnits
export const discountUnits = [
  { id: 1, label: '%', denomination: 'PERCENT' },
  { id: 2, label: '', denomination: 'CURRENCY' }
];

export const currencySymbol = {
  EUR: '€',
  USD: '$',
  GBP: '£'
};

export const campaignTypes = {
  CASH_COUPON: 'CASH_COUPON',
  MIXED: 'MIXED',
  PRODUCT: 'PRODUCT'
};

export const offerTypes = {
  CASH_OFFER: 'CASH_OFFER',
  RETAILER_PRODUCT_OFFER: 'RETAILER_PRODUCT_OFFER',
  SUPPLIER_PRODUCT_OFFER: 'SUPPLIER_PRODUCT_OFFER'
};

export const offerStatuses = {
  DISABLED: 'DISABLED',
  DRAFT: 'DRAFT',
  EXPIRED: 'EXPIRED',
  PROPOSAL: 'PROPOSAL',
  TO_VALIDATE: 'TO_VALIDATE',
  VALIDATED: 'VALIDATED'
};

export const offerImportStatuses = {
  FAIL: 'FAIL',
  FAIL_TO_PROCESS: 'FAIL_TO_PROCESS',
  IN_PROCESS: 'IN_PROCESS',
  REQUESTED: 'REQUESTED',
  SUCCESS: 'SUCCESS'
};

export const userTypes = {
  RETAILER: 'retailer',
  SUPPLIER: 'supplier'
};

export const filterSigns = [
  { id: 'contains', label: '=' },
  { id: 'doesNotContain', label: '≠' },
  { id: 'equals', label: '=' },
  { id: 'lessThan', label: '<' },
  { id: 'lessThanOrEqual', label: '<=' },
  { id: 'greaterThan', label: '>' },
  { id: 'greaterThanOrEqual', label: '>=' }
];

export const snackbarTypes = {
  ERROR: 'ERROR',
  DISABLE: 'DISABLE',
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING'
};

export const visualFileStatuses = {
  TO_GENERATE: 'TO_GENERATE',
  MODIFY_TO_REGENERATE: 'MODIFY_TO_REGENERATE',
  IN_PROCESS: 'IN_PROCESS',
  GENERATED: 'GENERATED'
};

export const supplierStatuses = {
  DRAFT: 'DRAFT',
  VALIDATED: 'VALIDATED'
};

export const supplierBudgetTypes = {
  DIGITAL: 'DIGITAL',
  PAPER: 'PAPER'
};

export const notificationStates = {
  SENT: 'SENT',
  ERROR: 'ERROR',
  NOT_SENT: 'NOT_SENT'
};

export const retailerTypes = {
  CLIENT: 'CLIENT',
  PROSPECT: 'PROSPECT',
  TEST: 'TEST'
};

export const segmentTypes = {
  DEMO: 'DEMO',
  IMPORT: 'IMPORT',
  PRE_STUDIO: 'PRE_STUDIO',
  STUDIO: 'STUDIO'
};

export const segmentUpdatingStatuses = {
  FAIL: 'FAIL',
  IN_PROCESS: 'IN_PROCESS',
  SUCCESS: 'SUCCESS'
};

export const segmentScopes = {
  CAMPAIGN: 'CAMPAIGN',
  OFFER: 'OFFER'
};

export const marketingStrategiesCodes = {
  DEF: 'DEF', // default - untargetable
  FID: 'FID', // loyalty
  GENERIC: 'GENERIC', // for non smart algo
  REAC: 'REAC', // reactivation
  REC: 'REC', // recrutment
  SMART: 'SMART' // for smart matching algo
};

export const segmentedMarketingStrategyCodes = [
  marketingStrategiesCodes.FID,
  marketingStrategiesCodes.REAC,
  marketingStrategiesCodes.REC
];

export const mailStatuses = {
  ACTIVATED: 'ACTIVATED',
  CLICKED: 'CLICKED',
  DELIVERED: 'DELIVERED',
  NOT_DELIVERED: 'NOT_DELIVERED',
  OPENED: 'OPENED',
  SPAM: 'SPAM'
};

export const offerContentTypes = [
  { id: 1, label: 'brand', denomination: 'BRAND' },
  { id: 2, label: 'product', denomination: 'PRODUCT' }
];

export const offerHeadTypes = {
  BRAND_OFFER: 'BRAND_OFFER',
  PRODUCT_OFFER: 'PRODUCT_OFFER'
};

export const MAX_OFFER_RECO_API_CALL = 10;

export const CONSTRAINTS_TYPES = {
  budget: 'budget',
  delay: 'creationToStartGap',
  duration: 'duration',
  discountType: 'discountType',
  discountUnit: 'discountUnit',
  endDay: 'endDay',
  startDay: 'startDay'
};

export const DAYS_UNIT = 'DAYS';

export const TARGETING_STRATEGIES_CODES = {
  REC: 'REC',
  INNO: 'INNO',
  REAC: 'REAC',
  AUT: 'AUT',
  FID: 'FID'
};

export const TARGETING_STRATEGIES_LABEL_FROM_CODE = {
  REC: 'Recruitment',
  INNO: 'Innovation',
  REAC: 'Reactivation',
  AUT: 'Classic',
  FID: 'Loyalty'
};

export const NON_POSITIONED_BUDGET_THRESHOLD_PERIODS = {
  START_OF_YEAR: 'START_OF_YEAR',
  INTERMEDIATE: 'INTERMEDIATE',
  END_OF_YEAR: 'END_OF_YEAR'
};

export const MONTH_LABELS = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER'
];

export const MONTH_i18n_MAPPING = {
  [MONTH_LABELS[0]]: 'commun_month_full_01',
  [MONTH_LABELS[1]]: 'commun_month_full_02',
  [MONTH_LABELS[2]]: 'commun_month_full_03',
  [MONTH_LABELS[3]]: 'commun_month_full_04',
  [MONTH_LABELS[4]]: 'commun_month_full_05',
  [MONTH_LABELS[5]]: 'commun_month_full_06',
  [MONTH_LABELS[6]]: 'commun_month_full_07',
  [MONTH_LABELS[7]]: 'commun_month_full_08',
  [MONTH_LABELS[8]]: 'commun_month_full_09',
  [MONTH_LABELS[9]]: 'commun_month_full_10',
  [MONTH_LABELS[10]]: 'commun_month_full_11',
  [MONTH_LABELS[11]]: 'commun_month_full_12'
};

// used in campaign product creation
export const initialCampaignProductCreationParams = {
  newOffersUseRate: 0.01,
  offersMaximumNumber: 20,
  offersMinimumNumber: 0,
  offersNumberByCategory: 1,
  privateLabelOffersMaximumNumber: 0
};
export const initialCampaignProductCreationParamsQuotesWithGenericTargetingOffers = {
  genericOffersQuote: 0.1,
  loyaltyOffersQuote: 0.45,
  recruitmentOffersQuote: 0.45
};
export const initialCampaignProductCreationParamsQuotesWithoutGenericTargetingOffers = {
  genericOffersQuote: 0,
  loyaltyOffersQuote: 0.5,
  recruitmentOffersQuote: 0.5
};

export const SEGMENT_MANAGER_RECENCY_PAST_DATE_MONTH_LIMIT = 36;

export const SUPPLIER_NOTE_MAX_LENGTH = 500;

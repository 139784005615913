import React from 'react';
import { useTranslation } from 'react-i18next';

import TopNavLinkTabs from 'components/TopNavLinkTabs';

type Props = {
  disabled?: boolean;
  index: number;
};

const OfferTopTab = ({ disabled, index }: Props): JSX.Element => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 'offersProductsSupplier',
      label: t('offers_list_supplier'),
      path: `/offers/offersProductsSupplier${
        localStorage.getItem('offersProductsSupplierFilter') ||
        `?validityStartDate=greaterThan,${new Date().getFullYear() - 1}-12-31`
      }`
    },
    {
      id: 'offersProductsRetailer',
      label: t('offers_list_retailer'),
      path: '/offers/offersProductsRetailer?status=VALIDATED'
    },
    {
      id: 'offersRetailerCashCoupon',
      label: t('commun_cash_coupon'),
      path: '/offers/offersRetailerCashCoupon'
    }
  ];

  return <TopNavLinkTabs disabled={disabled} selectedTabIndex={index} tabs={tabs} />;
};

export default OfferTopTab;

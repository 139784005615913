import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { crossProductPercentage } from 'utils/global';

import Tooltip from 'components/Tooltip';

import styles from './BudgetsProgressBar.module.scss';

const ProgressBar = ({ currency, data, small, total }) => {
  const { t } = useTranslation();
  const [hidePreload, setHidePreload] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHidePreload(true);
    });
  }, []);

  // unlimited budget
  if (total === -1) {
    return <div className={`${styles['progressBar']} ${styles['bar-unlimited']}`}></div>;
  }

  const widthPercents = data.map((el) => {
    if (el.value === null) {
      return null;
    }

    let val = crossProductPercentage({ value: el.value, total });
    if (val > 0) {
      // enhance visuals for enabling cursor hover
      val = Math.max(val, 2);
    }
    return val;
  });

  return (
    <div className={styles['progressBar']}>
      {data.map(({ key, value }, index) => {
        if (value === null || (value === 0 && index === 0)) return null;

        const tooltipMsg = t(`budget_progressBar_tooltip_${key}`, { value, currency });
        return (
          <Tooltip title={tooltipMsg} key={index.toString()}>
            <div
              className={`${styles['bar']} ${styles[`bar--${index}`]} ${small ? styles['small'] : ''} ${
                !hidePreload ? styles['preload'] : ''
              }`}
              style={{ width: `${Math.min(100, widthPercents[index])}%` }}
            ></div>
          </Tooltip>
        );
      })}
    </div>
  );
};

ProgressBar.propTypes = {
  currency: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, value: PropTypes.number })),
  small: PropTypes.bool,
  total: PropTypes.number.isRequired,
};
export default memo(ProgressBar);

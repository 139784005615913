import React from 'react';
import PropTypes from 'prop-types';
import { segmentScopes } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import styles from './Informations.module.scss';

const Informations = ({ scope }) => {
  const { t } = useTranslation();
  const creationSteps =
    scope === segmentScopes.CAMPAIGN
      ? [
          'segmentManager_sidePanel_informations_text_1',
          'segmentManager_sidePanel_informations_text_2',
          'segmentManager_sidePanel_informations_text_3',
          'segmentManager_sidePanel_informations_text_4'
        ]
      : [
          'segmentManager_sidePanel_informations_text_1',
          'segmentManager_sidePanel_informations_text_2_scope_offer',
          'segmentManager_sidePanel_informations_text_3_scope_offer',
          'segmentManager_sidePanel_informations_text_2',
          'segmentManager_sidePanel_informations_text_3',
          'segmentManager_sidePanel_informations_text_4'
        ];

  return (
    <div className={styles['informationsPanel']}>
      <p className={styles['title']}>{t('segmentManager_sidePanel_informations_title_A')}</p>
      <p className={styles['text']}>
        {scope === segmentScopes.CAMPAIGN
          ? t('segmentManager_sidePanel_informations_text_A')
          : t('segmentManager_sidePanel_informations_text_A_scope_offer')}
      </p>
      <p className={styles['title']}>{t('segmentManager_sidePanel_informations_title_B')}</p>
      <ol>
        {creationSteps.map((key, index) => (
          <li className={styles['text']} key={index.toString()}>
            {t(key)}
          </li>
        ))}
      </ol>
    </div>
  );
};

Informations.propTypes = {
  scope: PropTypes.oneOf(Object.keys(segmentScopes))
};

export default Informations;

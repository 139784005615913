import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useDynId from 'customHooks/useDynId';
import GoBackLink from 'components/GoBackLink';

import segmentOfferCreationIllustation from 'assets/illu_campaign_br.svg';
import segmentCampaignCreationIllustation from 'assets/illu_campaign_products.svg';
import { ReactComponent as ArrowIcon } from 'assets/14px_arrow_right_red.svg';

import styles from '../AddCampaign/AddCampaign.module.scss';

const AddSegment = (): JSX.Element => {
  const { t } = useTranslation();

  const config = [
    {
      linkTo: '/segmentation/segment-manager-offer',
      id: useDynId('createSegmentOffer'),
      iconSrc: segmentOfferCreationIllustation,
      altIcon: 'segment-offer-creation-icon',
      title: 'commun_button_create_segment_offer_title',
      desc: 'commun_button_create_segment_offer_desc'
    },
    {
      linkTo: '/segmentation/segment-manager-campaign',
      id: useDynId('createSegmentCampaign'),
      iconSrc: segmentCampaignCreationIllustation,
      altIcon: 'segment-campaign-creation-icon',
      title: 'commun_button_create_segment_campaign_title',
      desc: 'commun_button_create_segment_campaign_desc'
    }
  ];
  return (
    <section className={styles['root']}>
      <GoBackLink pathname={`/segmentation`} />
      <div className={styles['add-campaign']}>
        {config.map((el) => (
          <Link to={el.linkTo} id={el.id} key={el.linkTo}>
            <div className={styles['add-campaign-link']}>
              <div className={styles['add-campaign-link-icon']}>
                <img src={el.iconSrc} alt={el.altIcon} />
              </div>
              <div className={styles['flex-space-between']}>
                <div>
                  <div className={styles['add-campaign-link-title']}>{t(el.title)}</div>
                  <div className={styles['add-campaign-link-desc']}>{t(el.desc)}</div>
                </div>
                <ArrowIcon className={styles['arrow-icon']} />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default AddSegment;

import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { store } from 'store';
import { offerImportStatuses, snackbarTypes } from 'utils/constants';
import { getLastOffersImport, postOffersCsv } from 'api';
import useDynId from 'customHooks/useDynId';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import DragDropFile from 'components/DragDropFile';
import GoBackLink from 'components/GoBackLink';
import HelpGuide from 'components/HelpGuide';
import ImportedFileFrame from 'components/ImportedFileFrame';
import Loader from 'components/Loaders/Dots';

import { ReactComponent as UploadIcon } from 'assets/30px_import.svg';

import helpJSON from './help.json';
import styles from './OfferRetailerImport.module.scss';

const OfferRetailerImport = () => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const importList = state.offersImports.list;

  const [openImportedDialog, setOpenImportedDialog] = useState(false);
  const [openHelpDialog, setOpenHelpDialog] = useState(false);
  const [fileName, setFileName] = useState('');
  const [loadingList, setLoadingList] = useState(false);

  useEffect(() => {
    loadFilesList();

    // important car les données offersImports.details peuvent être mal reset depuis la page /offersProductsRetailerImport
    // à cause du fonctionnement de l'action UPDATE en array.push
    dispatch({ type: 'OFFERS_IMPORT_DETAILS_RESET' });

    // eslint-disable-next-line
  }, []);

  const loadFilesList = async () => {
    setLoadingList(true);
    await getLastOffersImport(state.user);
    setLoadingList(false);
  };

  const handleFileChange = (file) => {
    if (['text/csv', 'application/vnd.ms-excel'].includes(file.type)) {
      setFileName(file.name);
      setOpenImportedDialog(true);
      postOffersCsv({ file: file, retailerCode: state.user.code });
      // delay API call to allow time for the back to process the file
      setTimeout(loadFilesList, 2000);
    } else {
      dispatch({
        type: 'UI_SNACKBAR',
        payload: {
          type: snackbarTypes.ERROR,
          title: { key: 'import_file_bad_format', params: { text: '.CSV' } }
        }
      });
    }
  };

  const importhelplinkId = useDynId('importhelplink');
  const importhelpguideId = useDynId('importhelpguide');

  return (
    <>
      <div className={styles['root']}>
        <main className={styles['main']}>
          <div className={styles['back-button-container']}>
            <GoBackLink pathname={'/offers/offersProductsRetailer'} />
          </div>
          <div className={styles['header']}>
            <div className={styles['header-title']}>{t('offer_import_header_title')}</div>
            <div className={styles['header-desc']}>{t('offer_import_header_desc')}</div>
          </div>
          <DragDropFile acceptedFileType=".csv" onDropFile={handleFileChange}>
            <div className={styles['drag-drop-zone']}>
              <UploadIcon />
              <div>{Trans({ i18nKey: 'offer_import_file_drag_drop_field_text' })}</div>
            </div>
          </DragDropFile>
          <div className={styles['help-guide']}>
            <p>{t('offer_import_help_text')}</p>
            <Link
              id={importhelplinkId}
              className={styles['import-offer-help-link']}
              to={'/template_import_OPD.csv'}
              download
              target="_blank"
            >
              {t('offer_import_help_download')}
            </Link>
            <div>
              <ButtonsCustom
                id={importhelpguideId}
                classType="link_primary"
                method={() => {
                  setOpenHelpDialog(true);
                }}
                text={t('offer_import_help_guide')}
              />
            </div>
          </div>
          <div className={styles['divider']}></div>

          {loadingList ? (
            <div className={styles['list-loading']}>
              <p className={styles['list-loading-message']}>{t('import_loading_message')}</p>
              <Loader />
            </div>
          ) : (
            <>
              <p className={styles['list-title']}>{t('commun_last_import', { count: importList.length })}</p>
              {!importList.length && <p className={styles['list-result-empty']}>{t('offer_import_empty')}</p>}
            </>
          )}
          {importList.map((el) => (
            <ImportedFileFrame
              data={el}
              key={el.id.toString()}
              id={el.id.toString()}
              linkTo={
                el.status === offerImportStatuses.SUCCESS ? `/offers/offersProductsRetailerImport/${el.id}` : null
              }
              simpleDisplay={el.status !== offerImportStatuses.FAIL}
            />
          ))}
        </main>
      </div>
      <ContentDialog
        centerText
        isOpen={openImportedDialog}
        handleClose={() => {
          setOpenImportedDialog(false);
        }}
        title={t('import_file_processed', { text: fileName })}
        desc={t('import_dialog_subtitle')}
        maxWidth="xs"
      >
        <div className="confirm-dialog">
          <ButtonsCustom
            classType="action_primary_big"
            text={'OK'}
            method={() => {
              setOpenImportedDialog(false);
            }}
          />
        </div>
      </ContentDialog>
      <ContentDialog
        isOpen={openHelpDialog}
        handleClose={() => {
          setOpenHelpDialog(false);
        }}
      >
        <HelpGuide content={helpJSON} />
      </ContentDialog>
    </>
  );
};

export default OfferRetailerImport;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './RetailerSegmentSelectionResult.module.scss';

const RetailerSegmentSelectionResult = ({ retailerSegment }) => {
  const { t } = useTranslation();
  return (
    <div className={styles['retailerSegment']}>
      <p className={styles['title']}>{t('campaign_product_creation_retailerSegment_title')}</p>
      <div className={styles['frame']}>
        <div>
          <p className={styles['tag']}>{retailerSegment.id}</p>
          <p className={styles['text']}>{retailerSegment.name}</p>
        </div>
        <p className={styles['text-nb-client']}>
          {t('commun_customer_count', { count: retailerSegment.customerCount })}
        </p>
      </div>
    </div>
  );
};

RetailerSegmentSelectionResult.propTypes = {
  retailerSegment: PropTypes.object,
};

export default RetailerSegmentSelectionResult;

import React from 'react';
import PropTypes from 'prop-types';
import { segmentUpdatingStatuses } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import ToolTip from 'components/Tooltip';
import { ReactComponent as FailureIcon } from 'assets/30px_status_fail.svg';
import { ReactComponent as PendingIcon } from 'assets/30px_status_pending.svg';
import { ReactComponent as SuccessIcon } from 'assets/30px_status_success.svg';

const SegmentUpdatingStatusIcon = ({ status }) => {
  const { t } = useTranslation();

  if (!status || status === segmentUpdatingStatuses.IN_PROCESS)
    return (
      <ToolTip title={t('segment_updatingStatus_in_process')}>
        <PendingIcon />
      </ToolTip>
    );
  else if (status === segmentUpdatingStatuses.SUCCESS)
    return (
      <ToolTip title={t('segment_updatingStatus_success')}>
        <SuccessIcon />
      </ToolTip>
    );
  return (
    <ToolTip title={t('segment_updatingStatus_fail')}>
      <FailureIcon />
    </ToolTip>
  );
};

SegmentUpdatingStatusIcon.propTypes = {
  status: PropTypes.oneOf(Object.values(segmentUpdatingStatuses)),
};

export default SegmentUpdatingStatusIcon;

import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { formatFloat, withFractionDigits } from 'utils/global';

import styles from './GenerosityRecap.module.scss';
import clsx from 'clsx';

const GenerosityRecap = ({ basketThreshold, currency, discountType, discountUnits, generosity, recapType }) => {
  const { t } = useTranslation();

  if (!basketThreshold || !generosity?.unit || !generosity?.value || !discountType) {
    return null;
  }

  let basketThresholdValue;
  switch (recapType) {
    case 'cash_coupon_creation':
      basketThresholdValue = t('commun_price', {
        currency: currency?.code,
        value: basketThreshold || 0,
      });
      break;
    case 'offers_creation':
      basketThresholdValue = t('offers_creation_count_generosity_count', { count: basketThreshold || 0 });
      break;
    default:
      break;
  }

  const value = parseFloat(formatFloat(generosity.value));
  const maximumFractionDigits = withFractionDigits(value);

  const generosityValue =
    generosity.unit === 1
      ? `${t('commun_number', {
          value,
          maximumFractionDigits,
        })} ${discountUnits.find((i) => i.id === generosity.unit)?.label}`
      : t('commun_price', {
          currency: currency?.code,
          maximumFractionDigits,
          value,
        });

  return (
    <div className={clsx(styles['generosityRecap-root'], typeof recapType !== 'undefined' && styles['top-margin'])}>
      <div className={styles['title']}>
        <p>{t(`${recapType}_summary_title`)}</p>
      </div>

      <div className={styles['resume']}>
        <Trans
          i18nKey={`${recapType}_summary_type_${discountType === 1 ? 'loyalty' : 'immediate'}`}
          values={{ basketThresholdValue, generosityValue }}
        />
      </div>
    </div>
  );
};

GenerosityRecap.propTypes = {
  basketThreshold: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currency: PropTypes.object,
  discountType: PropTypes.number,
  discountUnits: PropTypes.array,
  generosity: PropTypes.object,
  recapType: PropTypes.oneOf(['cash_coupon_creation', 'offers_creation']).isRequired,
};

export default GenerosityRecap;

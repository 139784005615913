import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Tooltip from 'components/Tooltip';

import { ReactComponent as CrossIcon } from 'assets/22px_croix.svg';
import styles from './EanSelectedList.module.scss';

const EanSelectedList = ({ disabled, inModal, onRemoveItem, list = [], tagAttribute = 'ean', displayTag = true }) => {
  const { t } = useTranslation();

  return (
    <div className={`${styles['result-list']} ${inModal ? styles['inModal'] : ''}`}>
      <div className={styles['result-list-title']}>
        {t('offers_creation_product_selected', {
          count: list.length
        })}
      </div>
      <div className={`${styles['result-list-items']} ${inModal ? styles['inModal'] : ''}`}>
        {list.map((item, index) => (
          <div
            className={`${styles['result-list-item']} ${index % 2 === 0 && styles['odd']}`}
            key={`ean-result-list-${item.id}`}
          >
            <div className={styles['d-flex']}>
              {displayTag && (
                <div className={styles['ean-container']}>
                  <span className={styles['ean']}>{item[tagAttribute]}</span>
                </div>
              )}
              <Tooltip title={item.label}>
                <span className={styles['label']}>{item.label}</span>
              </Tooltip>
            </div>
            {!disabled && (
              <button onClick={() => onRemoveItem(item)} className={styles['close-button']}>
                <CrossIcon />
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

EanSelectedList.propTypes = {
  disabled: PropTypes.bool,
  displayTag: PropTypes.bool,
  inModal: PropTypes.bool,
  list: PropTypes.array,
  onRemoveItem: PropTypes.func,
  tagAttribute: PropTypes.string
};

export default EanSelectedList;

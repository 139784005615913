import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { segmentScopes } from 'utils/constants';
import { getStoresCount } from 'api';

import DrawerHeader from 'components/DrawerHeader';
import ButtonsCustom from 'components/ButtonsCustom';

import styles from './AddConditionsPanel.module.scss';

const AddConditionsPanel = ({ groupIndex, onClose, onSubmit, scope }) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const {
    state: { user }
  } = globalState;

  const [list, setList] = useState([]);
  const [hasStores, setHasStores] = useState(false);

  const conditionsList =
    scope === segmentScopes.CAMPAIGN
      ? [
          'recency',
          'frequency',
          'value',
          'multipurchases_sku',
          'multipurchases_level3',
          'multipurchases_level2',
          'multipurchases_level1',
          'multipurchases_level0',
          'multipurchases_brand',
          'multipurchases_store'
        ]
      : [
          'multipurchases_sku',
          'multipurchases_level3',
          'multipurchases_level2',
          'multipurchases_level1',
          'multipurchases_level0',
          'multipurchases_brand',
          'multipurchases_store'
        ];

  useEffect(() => {
    const fetchStoresCount = async () => {
      const storesCount = await getStoresCount();
      setHasStores(!!storesCount);
    };
    fetchStoresCount();
  }, []);

  const displayConditionLabel = (conditionType) => {
    switch (conditionType) {
      case 'multipurchases_level0':
        return user.settingProductLevelLabelOne;
      case 'multipurchases_level1':
        return user.settingProductLevelLabelTwo;
      case 'multipurchases_level2':
        return user.settingProductLevelLabelThree;
      case 'multipurchases_level3':
        return user.settingProductLevelLabelFour;
      default:
        return t(`segmentManager_vector_type_${conditionType}_label`);
    }
  };

  const handleClick = (value) => {
    let newList = [...list];
    const index = newList.findIndex((el) => el === value);
    if (index !== -1) {
      newList = [...newList.slice(0, index), ...newList.slice(index + 1)];
    } else {
      newList.push(value);
    }

    setList(newList);
  };

  const handleOnSubmit = () => {
    onSubmit({ groupIndex, newConditionsList: list });
    onClose();
  };

  return (
    <div className={styles['root']}>
      <DrawerHeader className={styles['title']} title={t('segmentManager_add_condition_panel_title')} />
      <p className={styles['desc']}>{t('segmentManager_add_condition_panel_desc')}</p>
      <div className={styles['buttons-list']}>
        {conditionsList.map((conditionType) => {
          // exclude optionnal level0 condition if user did not filled it
          if (!displayConditionLabel(conditionType)) return false;
          const isLevelStoreWithEmptyList = conditionType === 'multipurchases_store' && !hasStores;
          return (
            <ButtonsCustom
              key={conditionType}
              classType={list.includes(conditionType) ? 'action_primary' : 'action_reverse'}
              disabled={isLevelStoreWithEmptyList}
              method={() => {
                handleClick(conditionType);
              }}
              text={displayConditionLabel(conditionType)}
              tooltip={isLevelStoreWithEmptyList ? t('segmentManager_table_no_stores') : ''}
            />
          );
        })}
      </div>
      <div className={styles['confirmation-buttons-container-sticky']}>
        <ButtonsCustom classType="canceled" text={t('commun_button_cancel')} method={onClose} />
        <ButtonsCustom classType="action_primary_big" text={t('commun_add')} method={handleOnSubmit} />
      </div>
    </div>
  );
};

AddConditionsPanel.propTypes = {
  groupIndex: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  scope: PropTypes.oneOf(Object.keys(segmentScopes))
};

export default AddConditionsPanel;

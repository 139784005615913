import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TableCustom from 'components/TableCustom';

import { ReactComponent as CrossIcon } from 'assets/22px_croix.svg';
import styles from './TableProducts.module.scss';

const TableProducts = ({ list = [], onRemoveItem, tableSize = 5 }) => {
  const [pageIndex, setPageIndex] = useState(0);

  const tableColumns = [
    { id: 1, headerName: '', field: 'code', type: 'ean' },
    { id: 2, headerName: '', field: 'description', hasTooltip: true, wide: true },
    { id: 3, headerName: '', field: 'delete', type: 'component' }
  ];

  // delete icon action must be removed if the action is unavailable
  if (!onRemoveItem) {
    tableColumns.pop();
  }

  const tableRows = list.slice(pageIndex * tableSize, pageIndex * tableSize + tableSize).map((product) => ({
    id: [{ value: product.id }],
    code: [{ value: product.code, color: 'ean', textStyle: 'tag' }],
    description: [{ value: product.description, color: 'default' }],
    delete: onRemoveItem && (
      <div
        className={styles['close-button']}
        onClick={() => {
          onRemoveItem(product.code);
        }}
        role="button"
      >
        <CrossIcon />
      </div>
    )
  }));

  // refresh tableRows after deleting the last item on a page
  if (pageIndex && !tableRows.length) {
    setPageIndex(Math.max(0, pageIndex - 1));
  }

  return (
    <TableCustom
      columns={tableColumns}
      rows={tableRows}
      next={() => {
        setPageIndex((state) => state + 1);
      }}
      prev={() => {
        setPageIndex((state) => state - 1);
      }}
      page={pageIndex}
      showHeader={false}
      size={tableSize}
      total={list.length || 0}
      type="tiny"
    />
  );
};

TableProducts.propTypes = {
  list: PropTypes.array,
  onRemoveItem: PropTypes.func
};

export default TableProducts;

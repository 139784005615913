import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import ButtonsCustom from 'components/ButtonsCustom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popover from '@mui/material/Popover';
import Spinner from 'components/Loaders/Spinner';

import { ReactComponent as DownloadIcon } from 'assets/30px_download.svg';
import { ReactComponent as CsvIcon } from 'assets/csv-file.svg';
import { ReactComponent as XlsxIcon } from 'assets/xlsx-file.svg';

import styles from './ExportFileButton.module.scss';

enum EXTENSIONS {
  'CSV' = 'CSV',
  'EXCEL' = 'EXCEL'
}

type Props = {
  customCsvButtonKeyLabel?: string;
  customExcelButtonKeyLabel?: string;
  disabled: boolean;
  handleApiCall: (extension: EXTENSIONS) => Promise<boolean>;
};

const ExportFile = ({
  customCsvButtonKeyLabel,
  customExcelButtonKeyLabel,
  disabled,
  handleApiCall
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [isOnProgress, setIsOnProgress] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const buttonRef = useRef(null);

  const downloadFile = async (extensionType: EXTENSIONS) => {
    setIsOnProgress(true);
    setIsMenuOpen(false);

    await handleApiCall(extensionType);

    setTimeout(() => {
      setIsOnProgress(false);
    }, 500);
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };
  const handleOpenMenu = () => {
    if (!isOnProgress) {
      setIsMenuOpen(true);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <div className={clsx(styles['root'], isOnProgress && styles['isOnProgress'])}>
        <ButtonsCustom
          classType="icon_primary"
          disabled={disabled}
          method={handleOpenMenu}
          ref={buttonRef}
          startIconCustom={isOnProgress ? <Spinner /> : <DownloadIcon />}
          tooltip={isOnProgress ? t('commun_export_table_on_progress') : t('commun_export_table')}
        />

        <Popover
          classes={{ paper: styles['popper'] }}
          id={'open-export-offer-menu'}
          className={styles['popover']}
          disablePortal
          anchorEl={buttonRef.current}
          elevation={0}
          open={isMenuOpen}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <div
            onClick={() => {
              downloadFile(EXTENSIONS.EXCEL);
            }}
            role="button"
          >
            <XlsxIcon />
            {t(customExcelButtonKeyLabel || 'commun_file_export_xlsx')}
          </div>
          <div
            onClick={() => {
              downloadFile(EXTENSIONS.CSV);
            }}
            role="button"
          >
            <CsvIcon />
            {t(customCsvButtonKeyLabel || 'commun_file_export_csv')}
          </div>
        </Popover>
      </div>
    </ClickAwayListener>
  );
};

export default ExportFile;

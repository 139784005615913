import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { store } from 'store';
import { discountUnits, MAX_OFFER_RECO_API_CALL, offerStatuses, supplierBudgetTypes } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { checkNumberNotNull, formatFloat, getFirstValidityStartDate } from 'utils/global';
import { postOfferRecommendation } from 'api';

import BudgetAndTypeInput from './BudgetAndTypeInput';
import BudgetSummary from './BudgetSummary';
import FormManager from 'components/FormManager';
import HelperText from 'components/HelpText';
import NoBudget from './NoBudget';
import Recommendation from './Recommendation';
import RecommendationFailure from './RecommendationFailure';

const BudgetBlock = ({
  currency,
  currentRetailer,
  errors,
  freeBudgetData,
  helperText,
  isEditMode,
  offerConstraints,
  offerCreation,
  offerCreationHasChanged,
  previousBudgetTarget = 0,
  supplierBudgets,
  updateOfferCreation,
  newDatesYearDiffersFromPreviousSupplierBudgetYear
}) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state } = globalState;

  const [isFetchingReco, setIsFetchingReco] = useState(false);
  const [isRecoError, setIsRecoError] = useState(false);

  const { budget, budgetType, isLoadingSupplierBudgets, recoCounter, recommendation, validityDates } =
    offerCreation.step4;

  const existingBudgetConstraint = offerConstraints.find((el) => el.target === 'budget');
  const startDate = getFirstValidityStartDate(validityDates);

  const budgetEstimatedEnabled = currentRetailer.budgetEstimatedEnabled;

  //rule MODIF_OFFRE_PRODUITF_PROP_2
  // new EXPIRED status -> disable all inputs but dates
  const isOfferExpired = state.offers.details.offer?.status === offerStatuses.EXPIRED;
  const freeBudget =
    isEditMode && !isOfferExpired && !newDatesYearDiffersFromPreviousSupplierBudgetYear
      ? freeBudgetData + previousBudgetTarget
      : freeBudgetData;
  const budgetLeftAfterCreation = freeBudget - budget;

  // recoCounter flow values = 0,1,2...MAX_OFFER_RECO_API_CALL
  const hasReachedMaximumApiCalls = recoCounter > MAX_OFFER_RECO_API_CALL;

  // do not calculate reco when offer is expired or in progress
  const isOfferInProgress = isEditMode && moment().isAfter(startDate, 'day');

  const displayNoSupplierBudgetAvailable =
    !isLoadingSupplierBudgets &&
    !!offerCreation.step1.id &&
    !supplierBudgets.length &&
    !!validityDates.find((el) => el.startDate || el.endDate);

  const getPayload = () => {
    return {
      retailer: currentRetailer.code,
      targetingStrategy: state.targetingStrategies.find(
        (el) => el.id.toString() === offerCreation.step2.targetingStrategy.id
      )?.code,
      products: offerCreation.step3.resultListEan.map((product) => ({
        sku: product.ean,
        level3: product.productLevelFourthCode
      })),
      discount: {
        value: formatFloat(offerCreation.step3.generosity.value),
        unit: discountUnits.find((el) => el.id === offerCreation.step3.generosity.unit)?.denomination,
        minQuantityOfProducts: offerCreation.step3.quantity?.value
      },
      periods: validityDates.map((date) => ({
        end: date.endDate.utc().format(),
        start: date.startDate.utc().format()
      }))
    };
  };

  const fetchReco = async () => {
    setIsFetchingReco(true);
    const recoResponse = await postOfferRecommendation(getPayload());
    updateOfferCreation({
      step4: { recommendation: recoResponse || null, isLoadingRecommendation: false }
    });
    setIsFetchingReco(false);
    setIsRecoError(!recoResponse);
  };

  useEffect(() => {
    if (!budgetEstimatedEnabled || !freeBudgetData || isOfferExpired || isOfferInProgress) return;

    /***
     *  recoCounter flow values = 0,1,2...(MAX_OFFER_RECO_API_CALL -1)
     ***/
    let loadingRecommendationSetTimeoutId;

    if (recoCounter < MAX_OFFER_RECO_API_CALL && (offerCreationHasChanged || !recommendation)) {
      updateOfferCreation({
        step4: { isLoadingRecommendation: true, recoCounter: recoCounter + 1, recommendation: null }
      });
      fetchReco();

      // enable the submit button if reco api is taking too long
      // submit button is in AddOfferStepValidation.js file
      loadingRecommendationSetTimeoutId = setTimeout(() => {
        updateOfferCreation({ step4: { isLoadingRecommendation: false } });
      }, 10000);
    }

    if (offerCreationHasChanged && recoCounter + 1 > MAX_OFFER_RECO_API_CALL) {
      updateOfferCreation({ step4: { recoCounter: recoCounter + 1, recommendation: null } });
    }

    // clear the setTimeout
    return () => {
      updateOfferCreation({ step4: { isLoadingRecommendation: false } });
      if (loadingRecommendationSetTimeoutId) clearTimeout(loadingRecommendationSetTimeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetType]);

  const handleBudget = ({ value, budgetType }) => {
    const valueParsed = value ? parseInt(value, 10) : '';
    return (
      (checkNumberNotNull(value) || valueParsed === '') &&
      updateOfferCreation({
        step4: { budget: valueParsed, budgetType }
      })
    );
  };

  return (
    <FormManager
      data={{
        title: t('offers_creation_block_budget_title'),
        fieldsets: [
          {
            id: 'add-offer-budget-and-type',
            fields: [
              {
                classnames: ['half-width'],
                display: !!supplierBudgets.length,
                id: 'budget',
                type: <BudgetAndTypeInput />,
                onFieldChange: handleBudget,
                defaultValue: {
                  value: budget || '',
                  budgetType: budgetType || supplierBudgets?.[0]?.type || ''
                },

                listData: isEditMode
                  ? [supplierBudgetTypes.DIGITAL, supplierBudgetTypes.PAPER].map((el) => ({
                      id: el,
                      label: t(`commun_budget_type_${el}`)
                    }))
                  : supplierBudgets.map((el) => ({
                      id: el.type,
                      label: t(`commun_budget_type_${el.type}`)
                    })),

                isEditMode: isEditMode,
                currency: currency,
                error:
                  errors.budgetError && existingBudgetConstraint
                    ? t('offers_creation_budget_info', {
                        value: existingBudgetConstraint.threshold,
                        currency: currency.code
                      })
                    : undefined
              },
              {
                display: !!helperText,
                classnames: ['without-margin-top'],
                outerContainerClass: ['with-margin-top-medium'],
                id: 'offerRecommendedMinimumBudgetHelperText',
                type: <HelperText content={helperText} />
              },
              {
                display:
                  budgetEstimatedEnabled &&
                  !hasReachedMaximumApiCalls &&
                  !isRecoError &&
                  !!freeBudgetData &&
                  !isOfferExpired &&
                  !isOfferInProgress,
                type: 'CustomContent',
                id: 'reco',
                component: (
                  <Recommendation
                    value={recommendation}
                    isLoading={isFetchingReco}
                    currency={currentRetailer.currency.code}
                  />
                )
              },
              {
                // reached max api calls
                display: budgetEstimatedEnabled && hasReachedMaximumApiCalls,
                type: 'CustomContent',
                id: 'reco-reached-max-api-calls',
                component: (
                  <RecommendationFailure
                    desc={t('offers_creation_block_budget_reco_error_reached_max_api_calls_desc', {
                      count: MAX_OFFER_RECO_API_CALL
                    })}
                    title={t('offers_creation_block_budget_reco_error_reached_max_api_calls_title')}
                  />
                )
              },
              {
                // case : api error
                display: budgetEstimatedEnabled && isRecoError && !hasReachedMaximumApiCalls,
                type: 'CustomContent',
                id: 'reco-failure',
                component: (
                  <RecommendationFailure
                    desc={t('offers_creation_block_budget_reco_error_generic_desc')}
                    title={t('offers_creation_block_budget_reco_error_generic_title')}
                  />
                )
              },
              {
                display: !!supplierBudgets.length,
                outerContainerClass: ['full-width'],
                id: 'budget-summary',
                type: 'CustomContent',
                component: (
                  <BudgetSummary
                    budgetLeftAfterCreation={budgetLeftAfterCreation}
                    currencyCode={currency.code}
                    freeBudget={freeBudget}
                  />
                )
              },
              {
                display: displayNoSupplierBudgetAvailable,
                outerContainerClass: ['full-width'],
                id: 'no-budget-available',
                type: 'CustomContent',
                component: <NoBudget year={validityDates[0].startDate?.year() || validityDates[0].endDate?.year()} />
              }
            ]
          }
        ]
      }}
    />
  );
};

BudgetBlock.propTypes = {
  currency: PropTypes.object,
  currentRetailer: PropTypes.object,
  errors: PropTypes.object,
  freeBudgetData: PropTypes.number,
  helperText: PropTypes.string,
  isEditMode: PropTypes.bool,
  offerConstraints: PropTypes.array,
  offerCreation: PropTypes.object,
  offerCreationHasChanged: PropTypes.bool,
  previousBudgetTarget: PropTypes.number,
  supplierBudgets: PropTypes.array,
  updateOfferCreation: PropTypes.func,
  newDatesYearDiffersFromPreviousSupplierBudgetYear: PropTypes.bool
};

export default memo(BudgetBlock);

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ResponsivePie } from '@nivo/pie';
import clsx from 'clsx';

import styles from './Donut.module.scss';

const DEFAULT_COLORS = ['#EFEFF3', '#C8DFFF', '#5D80E8'];
const EMPTY_COLOR = ['#E8E8EC'];

/* index in data is used to link an id to always the same colors in cleanColors function*/

const Donut = ({
  colors = DEFAULT_COLORS,
  customConfig = {},
  data = [],
  insideInfo,
  oneLineDisplay,
  withLegends,
  withTooltips
}) => {
  const { t } = useTranslation();

  let cleanData = data.filter((el) => !!el.value);
  const cleanDataRemainingIds = cleanData.map((el) => el.index);

  if (!cleanData.length) {
    cleanData = [{ id: null, index: null, label: '', value: 100 }];
  }
  const cleanColors = cleanDataRemainingIds.length
    ? colors.filter((c, index) => cleanDataRemainingIds.includes(index))
    : EMPTY_COLOR;

  const renderInsideLabel = () => {
    return (
      <div className={styles['insideInfo']}>
        <div className={styles['title']}>{insideInfo.title}</div>
        <div className={styles['desc']}>{insideInfo.desc}</div>
      </div>
    );
  };

  const renderLegend = () => {
    return (
      <div className={styles['legend-container']}>
        {data.map((entry, index) => {
          return (
            <div className={styles['legend-item']} key={`${entry.id}-${index}`}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="6" fill={colors[index % colors.length]} />
              </svg>
              {t(entry.label)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderTooltip = ({ datum }) => {
    const { currency, value } = datum.data;
    return (
      <div className={styles['custom-tooltip']}>
        <span></span>
        {currency ? t('commun_price', { value, currency }) : t('commun_number', { value })}
      </div>
    );
  };

  return (
    <div
      className={clsx(styles['root'], withLegends && styles['withLegends'], oneLineDisplay && styles['oneLineDisplay'])}
    >
      <div>
        <div className={styles['donut']}>
          {insideInfo && renderInsideLabel()}
          <ResponsivePie
            data={cleanData}
            colors={cleanColors}
            margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
            startAngle={0}
            sortByValue={true}
            innerRadius={0.85}
            padAngle={0}
            cornerRadius={8}
            activeInnerRadiusOffset={2}
            activeOuterRadiusOffset={2}
            borderWidth={0}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            tooltip={(d) => withTooltips && renderTooltip(d)}
            {...customConfig}
          />
        </div>
      </div>
      {withLegends && renderLegend()}
    </div>
  );
};

Donut.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
  customConfig: PropTypes.object,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.string,
      id: PropTypes.string.isRequired,
      index: PropTypes.number.isRequired,
      label: PropTypes.string,
      value: PropTypes.number.isRequired
    })
  ),
  insideInfo: PropTypes.shape({ desc: PropTypes.any, title: PropTypes.any }),
  oneLineDisplay: PropTypes.bool,
  withLegends: PropTypes.bool,
  withTooltips: PropTypes.bool
};

export default Donut;

import React from 'react';
import reportWebVitals from './reportWebVitals';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './pages/App.js';
import { StateProvider } from './store/index.js';

import './i18n/index.js';
import './scss/index.scss';

// import moment locales
import 'moment/locale/fr';
import 'moment/locale/en-gb';

// initialize react-datepicker locales
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import fr from 'date-fns/locale/fr';
registerLocale('es', es);
registerLocale('fr', fr);
const userLocale = navigator.language.split('-')[0];
setDefaultLocale(userLocale);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <StateProvider>
      <Router>
        <App />
      </Router>
    </StateProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

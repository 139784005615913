import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import ButtonsCustom from 'components/ButtonsCustom';
import Popover from '@mui/material/Popover';
import { useTranslation } from 'react-i18next';
import useDynId from 'customHooks/useDynId';

import { ReactComponent as ArrowIcon } from 'assets/16px_arrow_2.svg';
import { ReactComponent as CloseIcon } from 'assets/22px_croix.svg';
import './TableFiltersCustom.scss';

const TableFiltersCustom = forwardRef(
  (
    { buttonLabel, popperId, popperContent, popperContentResume, isActive, handleValidation, disabled, buttonId },
    ref,
  ) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [activeButton, setActiveButton] = useState(null);

    useImperativeHandle(ref, () => ({
      activeButton,
      handleClose,
    }));

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      setActiveButton(true);
    };

    const handleClose = () => {
      setAnchorEl(null);
      setActiveButton(false);
      handleValidation && handleValidation();
    };

    const classType = activeButton || isActive ? 'filter_active' : 'filter';

    return (
      <div className="table-filter-custom">
        <ButtonsCustom
          classType={classType}
          text={buttonLabel}
          aria-controls={popperId}
          aria-haspopup="true"
          method={handleClick}
          endIconCustom={<ArrowIcon />}
          disabled={disabled}
          id={buttonId}
        />

        <Popover
          id={popperId}
          className="popper"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {popperContent}
          {popperContentResume}
          <div className="popper-validation">
            <ButtonsCustom
              classType="action_secondary"
              id={useDynId('apply')}
              text={t('commun_apply')}
              method={handleClose}
            />
          </div>
          <span className="popper-arrow"></span>
          <span className="popper-close-icon">
            <CloseIcon onClick={handleClose} />
          </span>
        </Popover>
      </div>
    );
  },
);

TableFiltersCustom.propTypes = {
  buttonId: PropTypes.string,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
  handleValidation: PropTypes.func,
  isActive: PropTypes.bool,
  popperContent: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  popperContentResume: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  popperId: PropTypes.string.isRequired,
};

export default TableFiltersCustom;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RadioGroupCustom from 'components/RadioGroup';

const RadioGroup = (props) => {
  const {
    data: { id, defaultValue, disabled, errorMsg, fullWidth, fieldProps, withBorder },
    onChange,
    error
  } = props;

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) onChange(id, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (ev) => {
    const {
      target: { value: newValue }
    } = ev;
    setValue(newValue);
  };

  return (
    <RadioGroupCustom
      {...fieldProps}
      disabled={disabled}
      error={!!error}
      errorMsg={errorMsg}
      fullWidth={fullWidth}
      id={id}
      onChange={handleChange}
      selectedValue={typeof value === 'number' ? value.toString() : value}
      withBorder={withBorder}
    />
  );
};

RadioGroup.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    fieldProps: PropTypes.object,
    fullWidth: PropTypes.bool,
    withBorder: PropTypes.bool
  }),
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func
};

export default RadioGroup;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDynId from 'customHooks/useDynId';

import TableCustom from 'components/TableCustom';
import ButtonsCustom from 'components/ButtonsCustom';
import SelectCustom from 'components/SelectCustom';

import styles from './DialogSearchProduct.module.scss';

const DialogSearchProduct = ({
  data,
  handleListUpdate,
  list,
  onChange,
  onClose,
  onOpen,
  settingProductLevelLabelFour,
  updateOfferCreation,
}) => {
  const { t } = useTranslation();
  const [tempItems, setTempItems] = useState(list);
  const brandId = useDynId('brand');
  const categoryId = useDynId('category');

  const {
    fieldProps: { offerCreation, products },
  } = data;
  const { filterCategory, filterCategoryList, filterBrand, filterBrandList } = offerCreation.step3;

  useEffect(() => {
    if (onOpen) onOpen();
    // eslint-disable-next-line
  }, []);

  const rows = products.list.map((item) => ({
    id: [{ value: item.id, color: 'default' }],
    checked: !!tempItems.filter((i) => i.id === item.id).length,
    code: [{ value: item.ean, color: 'ean', textStyle: 'tag' }],
    description: [{ value: item.label, color: 'default', textStyle: 'p3-m' }],
    customProductId: [{ value: item.customProductId, color: 'default', textStyle: 'p3-m' }],
    productLevelFourthCode: [{ value: item.productLevelFourthCode, color: 'default', textStyle: 'p3-m' }],
    averagePrice: [{ value: item.averagePrice }],
  }));

  const columns = [
    { id: 1, field: 'code', headerName: 'EAN', type: 'text', sortable: true },
    { id: 2, field: 'description', headerName: 'LIBELLE', type: 'text', sortable: true },
  ];

  const columnsTranslated = columns.map((i) => {
    i.headerName = t(`_dyn_commun_${i.field}`);
    return i;
  });

  const handleSort = (sort) => {
    const data = { sort: sort, order: products.order !== 'desc' ? 'desc' : 'asc' };
    handleListUpdate(data);
  };
  const handleNext = () => {
    handleListUpdate({ page: products.page + 1 });
  };
  const handlePrev = () => {
    handleListUpdate({ page: products.page - 1 });
  };

  const handleCheck = (elem) => {
    let newItems;
    if (elem.id === 'all') {
      // Set uniqueness works with primitive values (number, string)
      newItems = new Set(tempItems.map((t) => JSON.stringify(t)));
      if (elem.checked) {
        rows.forEach((item) => {
          newItems.add(
            JSON.stringify({
              averagePrice: item.averagePrice[0].value,
              customProductId: item.customProductId[0].value,
              ean: item.code[0].value,
              id: item.id[0].value,
              label: item.description[0].value,
              productLevelFourthCode: item.productLevelFourthCode[0].value,
            })
          );
        });
      } else {
        rows.forEach((item) => {
          newItems.delete(
            JSON.stringify({
              averagePrice: item.averagePrice[0].value,
              customProductId: item.customProductId[0].value,
              ean: item.code[0].value,
              id: item.id[0].value,
              label: item.description[0].value,
              productLevelFourthCode: item.productLevelFourthCode[0].value,
            })
          );
        });
      }
      // back to an Array of Objects
      newItems = [...newItems].map((str) => JSON.parse(str));
    } else {
      newItems = elem.checked
        ? [
            ...tempItems,
            {
              averagePrice: elem.item.averagePrice[0].value,
              customProductId: elem.item.customProductId[0].value,
              ean: elem.item.code[0].value,
              id: elem.item.id[0].value,
              label: elem.item.description[0].value,
              productLevelFourthCode: elem.item.productLevelFourthCode[0]?.value,
            },
          ]
        : tempItems.filter((i) => i.ean !== elem.item.code[0].value);
    }
    setTempItems(newItems);
  };

  return (
    <div className={styles['dialog-search-product']}>
      <h2>{t('offers_creation_title_search')}</h2>
      <div className={styles['filters-container']}>
        {filterBrandList.length > 0 && (
          <SelectCustom
            id={brandId}
            placeHolderText={t('offers_creation_brand')}
            methodOnChange={(event) => {
              // reset pagination and sorting
              handleListUpdate({ page: 0, sort: 'code', order: 'desc' });

              updateOfferCreation({
                step3: { filterBrand: { value: event.target.value } },
              });
            }}
            listData={[{ id: undefined, name: t('offers_creation_all_search') }, ...filterBrandList]}
            labelAttribute="name"
            value={filterBrand.value}
          />
        )}
        {filterCategoryList.length > 0 && (
          <SelectCustom
            id={categoryId}
            placeHolderText={settingProductLevelLabelFour}
            methodOnChange={(event) => {
              // reset pagination and sorting
              handleListUpdate({ page: 0, sort: 'code', order: 'desc' });

              updateOfferCreation({
                step3: { filterCategory: { value: event.target.value } },
              });
            }}
            listData={[{ id: undefined, name: t('offers_creation_all_search') }, ...filterCategoryList]}
            labelAttribute="name"
            value={filterCategory.value}
          />
        )}
      </div>
      <h3>
        {t('offers_creation_product_list')}{' '}
        {products.total ? (
          <span>
            {t('commun_result', {
              count: products.total,
            })}
          </span>
        ) : (
          <span>{t('commun_no_result')}</span>
        )}
      </h3>
      <TableCustom
        isLoading={products.loading}
        sort={products.sort}
        order={products.order}
        rows={rows}
        columns={columnsTranslated}
        total={products.total}
        handleSort={handleSort}
        page={products.page}
        size={products.limit}
        prev={handlePrev}
        next={handleNext}
        check={handleCheck}
        sticky
        inModal
      />
      <div className={styles['confirm-dialog']}>
        <ButtonsCustom classType="canceled" text={t('commun_cancel')} method={onClose} />
        <ButtonsCustom
          classType="action_primary_big"
          text={t('commun_save')}
          method={() => {
            onChange(tempItems);
            onClose();
          }}
        />
      </div>
    </div>
  );
};
DialogSearchProduct.propTypes = {
  data: PropTypes.object,
  handleListUpdate: PropTypes.func,
  list: PropTypes.array,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  settingProductLevelLabelFour: PropTypes.string,
  updateOfferCreation: PropTypes.func,
};
export default DialogSearchProduct;

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getUntargetableOffers } from 'api';
import ButtonsCustom from 'components/ButtonsCustom';
import Filters from 'components/Filters/Filters';
import TableCustom from 'components/TableCustom';

import styles from './DialogSearchDefaultOffers.module.scss';

const DialogSearchProduct = ({ campaignProduct, isAddingOffers, onClose, onSubmit }) => {
  const { t } = useTranslation();
  const [tempIdList, setTempIdList] = useState(
    campaignProduct.untargetableProductCampaignOffers.listAll.map((i) => i.id)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [tableOrderBy, setTableOrderBy] = useState('desc');
  const [tableSortBy, setTableSortBy] = useState('id');

  const columns = [
    {
      id: 1,
      field: 'id',
      headerName: t(`_dyn_commun_${'id'}`).toUpperCase(),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.id'
    },
    {
      id: 2,
      field: 'title',
      headerName: t(`_dyn_commun_${'title'}`).toUpperCase(),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.title'
    },
    {
      id: 3,
      field: 'descriptionTag',
      headerName: t(`_dyn_commun_${'tag'}`).toUpperCase(),
      type: 'text',
      sortable: true,
      sortPath: 'offerHead.descriptionTag'
    }
  ];
  const rows = campaignProduct.offersUntargetablePreviewList.map((item) => ({
    checked: tempIdList.includes(item.id),
    id: [{ value: item.offerHead.id, color: 'ean', textStyle: 'tag' }],
    descriptionTag: [{ value: item.offerHead.descriptionTag, color: 'default' }],
    title: [{ value: item.offerHead.title, color: 'default' }],
    offerId: item.id
  }));

  const filtersListToDisplay = useMemo(() => {
    return ['id', 'title', 'tag'];
  }, []);

  const handleSort = (sort) => {
    setTableOrderBy(tableOrderBy === 'asc' ? 'desc' : 'asc');
    setTableSortBy(sort);
  };
  const handleNext = () => {
    setPage((state) => state + 1);
  };
  const handlePrev = () => {
    setPage((state) => state - 1);
  };

  useEffect(() => {
    const handleListUpdate = async () => {
      setIsLoading(true);
      await getUntargetableOffers({ campaignProduct, page, sortBy: tableSortBy, orderBy: tableOrderBy, filters });
      setIsLoading(false);
    };
    handleListUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, tableOrderBy, tableSortBy, filters]);

  const handleSubmit = () => {
    onSubmit({
      newIdList: tempIdList,
      oldIdList: campaignProduct.untargetableProductCampaignOffers.listAll.map((i) => i.id),
      campaignProduct
    });
  };

  const handleCheck = (elem) => {
    let newItems = [...tempIdList];
    if (elem.id === 'all') {
      if (elem.checked) {
        rows.forEach((item) => {
          newItems.push(item.offerId);
        });
      } else {
        rows.forEach((item) => {
          newItems = newItems.filter((n) => n !== item.offerId);
        });
      }
    } else {
      if (elem.checked) newItems.push(elem.item.offerId);
      else newItems = newItems.filter((n) => n !== elem.item.offerId);
    }
    // remove duplicates
    newItems = [...new Set(newItems)];
    setTempIdList(newItems);
  };

  return (
    <div className={styles['dialog-search-product']}>
      <h2>{t('campaign_product_step3_defaultOffers_modal_title')}</h2>
      <div className={styles['filters-container']}>
        <Filters
          filters={filters}
          filtersListToDisplay={filtersListToDisplay}
          updateFilters={(newFilters) => {
            if (JSON.stringify(newFilters) !== JSON.stringify(filters)) {
              setPage(0);
              setFilters(newFilters);
            }
          }}
        />
      </div>
      <h3>
        {t('campaign_product_step3_defaultOffers_modal_desc')}
        {campaignProduct.offersUntargetablePreviewCount ? (
          <span>
            {t('commun_result', {
              count: campaignProduct.offersUntargetablePreviewCount
            })}
          </span>
        ) : (
          <span>{t('commun_no_result')}</span>
        )}
      </h3>
      <TableCustom
        isLoading={isLoading}
        sort={tableSortBy}
        order={tableOrderBy}
        rows={rows}
        columns={columns}
        total={campaignProduct.offersUntargetablePreviewCount}
        handleSort={handleSort}
        page={page}
        size={30}
        prev={handlePrev}
        next={handleNext}
        check={handleCheck}
        sticky
        inModal
      />
      <div className={styles['confirm-dialog']}>
        <ButtonsCustom
          classType="canceled"
          disabled={isLoading || isAddingOffers}
          method={onClose}
          text={t('commun_cancel')}
        />
        <ButtonsCustom
          classType="action_primary_big"
          disabled={isLoading}
          loading={isAddingOffers}
          method={handleSubmit}
          text={t('commun_save')}
        />
      </div>
    </div>
  );
};
DialogSearchProduct.propTypes = {
  campaignProduct: PropTypes.object.isRequired,
  isAddingOffers: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
export default DialogSearchProduct;

// @ts-nocheck
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, ScatterController, Tooltip } from 'chart.js';
import { useTranslation } from 'react-i18next';
import { withFractionDigits } from 'utils/global';

import styles from './ScatterPlotGraph.module.scss';
import clsx from 'clsx';

ChartJS.register(CategoryScale, LinearScale, PointElement, ScatterController, Tooltip);

export type DatumType = { rawData: any; x: number; y: number };

export type DataPropType = {
  data: DatumType[];
};

type LayoutPaddingType = { top: number; right: number; bottom: number; left: number };

type Props = {
  colors?: string[];
  currencyCode?: string;
  data: DataPropType[];
  handleClick?: (datum: DatumType) => void;
  layoutPadding?: LayoutPaddingType;
  renderTooltip?: (tooltipData: any) => JSX.Element;
  xAxisFormatType?: 'currency' | 'number' | 'percentage';
  xLabel?: string;
  xScaleMax?: number;
  xTickValues?: number | number[];
  yAxisFormatType?: 'currency' | 'number' | 'percentage';
  yLabel?: string;
  yScaleMax?: number;
  yTickValues?: number | number[];
};

const DEFAULT_COLORS = ['#5D80E8', '#C8DFFF', '#69788C'];
const DEFAULT_LAYOUT_PADDING = { top: 15, right: 2, bottom: 3, left: 2 };

const ScatterPlotGraph = ({
  colors = DEFAULT_COLORS,
  currencyCode,
  data,
  handleClick,
  layoutPadding,
  renderTooltip,
  xAxisFormatType = 'number',
  yAxisFormatType = 'number'
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const chartRef = useRef<HTMLCanvasElement>();
  const [tooltipData, setTooltipData] = useState<PointElement | undefined>();

  const displayFormattedAxisValue = useCallback(
    (value: number, type?: 'currency' | 'number' | 'percentage'): string => {
      const acceptedTypes = ['currency', 'number', 'percentage'];
      if (!type || !acceptedTypes.includes(type)) return value.toString();
      if (type === 'currency') {
        return t('commun_price', {
          currency: currencyCode,
          maximumFractionDigits: withFractionDigits(value),
          value
        });
      } else if (type === 'number') {
        return t('commun_number', { maximumFractionDigits: withFractionDigits(value), value });
      } else {
        return t('commun_percentage_number', { maximumFractionDigits: withFractionDigits(value), value });
      }
    },
    [currencyCode, t]
  );

  const config = useMemo(
    () => ({
      type: 'scatter',
      data: {
        datasets: [
          { data: data[2]?.data, backgroundColor: colors[2] },
          { data: data[1]?.data, backgroundColor: colors[1] },
          { data: data[0]?.data, backgroundColor: colors[0] }
        ]
      },
      options: {
        animation: false,
        aspectRatio: 2,
        elements: { point: { radius: 3, hoverRadius: 5 } },
        interaction: {
          mode: 'point' // hover on 'nearest' (default) or 'point' for precise hit
        },
        layout: {
          padding: layoutPadding || DEFAULT_LAYOUT_PADDING
        },
        onClick: (event) => {
          const rawData = event.chart.tooltip?.dataPoints?.[0]?.raw;
          if (handleClick && rawData) {
            handleClick(rawData);
          }
          setTooltipData();
        },
        plugins: {
          customCanvasBackgroundColor: {
            color: '#F9F9FB'
          },
          customMouveOutEventCatcher: {
            hideTooltip: () => {
              setTooltipData();
            }
          },
          legend: {
            display: false
          },
          tooltip: {
            enabled: false,
            external: ({ tooltip }) => {
              if (tooltip.opacity === 0) {
                setTooltipData();
                return;
              }

              const newTooltipData = tooltip.dataPoints[0];
              if (
                !tooltipData ||
                tooltipData.element.x !== newTooltipData.element.x ||
                tooltipData.element.y !== newTooltipData.element.y
              ) {
                setTooltipData(newTooltipData);
              }
            }
          }
        },
        scales: {
          x: {
            beginAtZero: true,
            grid: { display: false },
            suggestedMax: 50,
            ticks: {
              callback: (value) => displayFormattedAxisValue(value, xAxisFormatType)
            }
          },
          y: {
            beginAtZero: true,
            grid: { display: false },
            ticks: {
              callback: (value) => displayFormattedAxisValue(value, yAxisFormatType)
            }
          }
        }
      },
      plugins: [customCanvasBackgroundColorPlugin, customMouseOutPlugin]
    }),
    [colors, data, displayFormattedAxisValue, handleClick, layoutPadding, tooltipData, xAxisFormatType, yAxisFormatType]
  );

  useEffect(() => {
    if (chartRef.current !== null) {
      ChartJS.getChart(chartRef.current)?.destroy();
    }
    const chart = new ChartJS(chartRef.current, config);

    return () => {
      ChartJS.getChart(chart)?.destroy();
    };
  }, [config]);

  return (
    <div className={clsx(styles['root'])}>
      <canvas ref={chartRef} />
      {renderTooltip && tooltipData && renderTooltip(tooltipData)}
    </div>
  );
};

export default memo(ScatterPlotGraph);

type ScatterPlotGraphLegendProps = {
  colors?: string[];
  labels: string[];
};

export const ScatterPlotGraphLegend = ({
  colors = DEFAULT_COLORS,
  labels
}: ScatterPlotGraphLegendProps): JSX.Element => {
  return (
    <div className={styles['scatterPlotGraphLegend-root']}>
      {labels.map((label, index) => (
        <div className={styles['container']} key={index.toString()}>
          <div className={styles['square']} style={{ backgroundColor: colors[index] }}></div>
          <div>{label}</div>
        </div>
      ))}
    </div>
  );
};

const customCanvasBackgroundColorPlugin = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || '#99ffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  }
};

const customMouseOutPlugin = {
  id: 'customMouveOutEventCatcher',
  beforeEvent(chart, args, options) {
    const event = args.event;
    if (event.type === 'mouseout') {
      options.hideTooltip();
    }
  }
};

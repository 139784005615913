import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TextFieldCustom from 'components/TextFieldCustom';
import RadioGroup from 'components/RadioGroup';

import styles from './Duration.module.scss';

const Duration = (props) => {
  const {
    className,
    data: { id, defaultValue, placeholder },
    onChange = () => {},
    error,
  } = props;
  const { t } = useTranslation();
  const [state, setState] = useState();

  useEffect(() => {
    if (defaultValue && (defaultValue?.value !== state?.value || defaultValue?.unit !== state?.unit)) {
      setState(defaultValue);
      onChange(id, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleDurationValueChange = (e) => {
    const newValue = { ...state, value: e.target.value ? parseInt(e.target.value, 10) : '' };
    setState(newValue);
    onChange(id, newValue);
  };

  const handleDurationUnitChange = (e) => {
    const newValue = { ...state, unit: e.target.value };
    setState(newValue);
    onChange(id, newValue);
  };

  return (
    <div className={className}>
      <div className={styles['duration-container']}>
        <TextFieldCustom
          className={styles['duration-input']}
          label={placeholder}
          type="number"
          onChange={handleDurationValueChange}
          value={state?.value || ''}
          error={!!error}
        />
        <RadioGroup
          listData={[
            { label: t('product_offers_settings_duration_days'), value: 'DAYS' },
            { label: t('product_offers_settings_duration_weeks'), value: 'WEEKS' },
          ]}
          id="duration-selection"
          onChange={handleDurationUnitChange}
          selectedValue={state?.unit || 'WEEKS'}
        />
      </div>

      {error && typeof error === 'string' && <div className={styles['error']}>{error}</div>}
    </div>
  );
};

Duration.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
};

export default Duration;

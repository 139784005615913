import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useDynId from 'customHooks/useDynId';
import { ReactComponent as BackIcon } from 'assets/back.svg';
import './GoBackLink.scss';

const GoBackLink = ({ id, className, customOnClick, flushStore, label, pathname }) => {
  const { t } = useTranslation();
  const defaultId = useDynId('backToList');
  return (
    <Link
      id={id || defaultId}
      className={`goback-link ${className || ''}`}
      to={pathname}
      onClick={() => {
        if (customOnClick) customOnClick();
        if (flushStore) flushStore();
      }}
    >
      <BackIcon />
      {label || t('navigation_back')}
    </Link>
  );
};

GoBackLink.propTypes = {
  className: PropTypes.string,
  customOnClick: PropTypes.func,
  flushStore: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

export default memo(GoBackLink);

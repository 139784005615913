import { currencySymbol } from 'utils/constants';
import Qs from 'query-string';
import moment from 'moment-timezone';

export const debounce = (callback, wait) => {
  let timeout = null;
  return (...args) => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
};

export const delay = (value = 200) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, value);
  });
};

export const throttle = (fn, delay) => {
  let timeout = false;
  return (...rest) => {
    if (!timeout) {
      timeout = true;
      fn.apply(this, rest);
      setTimeout(() => {
        timeout = false;
      }, delay);
    }
  };
};

export const checkCampaignTitle = (value) => {
  return /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s\-_()[\]]+$/.test(value);
};
export const checkNumber = (value) => /^\d+((\.|,)\d{0,2})?$/.test(value);
export const checkInteger = (value) => /^\d+?$/.test(value);
export const checkNumberNotNull = (value) => checkNumber(value) && parseInt(value, 10) > 0;
export const formatFloat = (value) => {
  if (!value) return null;

  let val = value;
  if (typeof val === 'number') val = value.toString();

  return Number(parseFloat(val.replace(',', '.')).toFixed(2));
};

export const checkYearFormat = (year) => {
  return /^\d{4}$/.test(year);
};

export const dateFormatRegexp = /^(\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
export const checkDateFormat = (date) => {
  return dateFormatRegexp.test(date);
};

// remove key when empty values
export const cleanObject = (obj) => {
  const newObj = { ...obj };
  Object.keys(obj).forEach((key) => {
    if (!obj[key]) delete newObj[key];
  });
  return newObj;
};

export const checkCurrency = (currencyCode) => currencySymbol[currencyCode] || currencyCode;

export const QsParse = (urlSearch) => {
  return Qs.parse(urlSearch, {
    arrayFormat: 'comma',
    parseNumbers: true,
    parseBooleans: true
  });
};
export const QsStringify = (obj) => {
  return Qs.stringify(obj, { arrayFormat: 'comma', skipNull: true, skipEmptyString: true });
};

export const getLang = () => (window.Cypress ? 'fr' : window.navigator.language);

export const validateEmail = (email) =>
  /^(([a-zA-Z0-9_\-+.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))+$/.test(
    email
  );

export const withFractionDigits = (value) => {
  if (!value) return 0;
  else return Number.isSafeInteger(value) ? 0 : 2;
};

export const checkFileExtension = (filename = '', allowedExtensions) => {
  const fileExtension = filename.split('.').pop();
  const isAllowedExtension = allowedExtensions.includes(fileExtension);

  return isAllowedExtension;
};

export const isFileOfTypeImage = (file) => {
  return /image/.test(file.type);
};

export const hasEmptyField = (obj) => {
  let emptyFieldList = [];
  // nested param is for avoiding first check when obj is an array of objects
  const r = (o, nested) => {
    Object.values(o).forEach((value) => {
      if (nested && Array.isArray(value) && !value.filter((el) => el || el === 0).length) {
        emptyFieldList.push(value);
      } else if (value && typeof value === 'object' && !moment.isMoment(value)) {
        return r(value, true);
      } else if (!value && value !== 0) {
        emptyFieldList.push(value);
      }
    });
  };
  r(obj);
  return !!emptyFieldList?.length;
};

export const checkCampaign = (campaign, campaignDetails) => {
  campaign.map((details) => details.id === campaignDetails.id && details);
};

export const scrollToElementById = (id) => {
  document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
};

export const objectOrderedKeys = (object) => {
  return Object.keys(object)
    .sort()
    .reduce((obj, key) => {
      obj[key] = object[key];
      return obj;
    }, {});
};

export const arrayEquals = (a, b) => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
};

export const crossProductPercentage = ({ value, total }) => {
  return total ? (100 * value) / total : 0;
};

export const floatify = (value) => {
  return parseFloat(value.toFixed(10));
};

export const getFirstValidityStartDate = (validityDates, key = 'startDate') => {
  if (validityDates) {
    const startDates = validityDates.reduce((acc, d) => {
      if (d[key]) {
        acc.push(d[key]);
      }
      return acc;
    }, []);
    return !!startDates.length && moment.min(startDates);
  }
  return null;
};
export const getLastValidityEndDate = (validityDates, key = 'endDate') => {
  if (validityDates) {
    const endDates = validityDates.reduce((acc, d) => {
      if (d[key]) {
        acc.push(d[key]);
      }
      return acc;
    }, []);
    return !!endDates.length && moment.max(endDates.map((d) => moment(d)));
  }
  return null;
};

export function getAveragePriceFromProducts(productList = []) {
  const filterProductsWithAveragePriceList = productList.filter((product) => !!product.averagePrice);

  if (filterProductsWithAveragePriceList.length) {
    const averagePriceFromProducts = filterProductsWithAveragePriceList.reduce((acc, product) => {
      return acc + product.averagePrice;
    }, 0);
    return averagePriceFromProducts / filterProductsWithAveragePriceList.length;
  }
  return 0;
}

export const getLastDayOfYear = (year = moment().year()) => {
  return moment(`${year}-12-31`).endOf('day').utc();
};

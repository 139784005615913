import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CrossIcon } from 'assets/22px_croix.svg';
import { useTranslation } from 'react-i18next';
import useDynId from 'customHooks/useDynId';

import { getExternalSupplierSuppliers, getExternalSupplier } from 'api';

import AutocompleteCustom from 'components/AutocompleteCustom';
import ProductRefListDetails from 'components/ProductRefListDetails';
import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';

import styles from './ProductRefList.module.scss';

const ProductRefList = ({ externalSupplierId, onChange, idField, error, disabled }) => {
  const { t } = useTranslation();
  const [productRef, setProductRef] = useState({ linked: [], removed: [], alreadyLinked: [] });
  const [productRefSearch, setProductRefSearch] = useState([]);
  const [dialogOpened, setDialogOpened] = useState(false);

  useEffect(() => {
    const fetchApi = async (id) => {
      const items = { linked: [], removed: [], alreadyLinked: [] };

      if (id) {
        const products = await getExternalSupplierSuppliers(id);
        items.alreadyLinked = products;
        items.linked = products;
      }

      setProductRef(items);
      onChange(idField, items);
    };

    fetchApi(externalSupplierId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalSupplierId]);

  const handleRemoveProductRef = (id) => {
    const newProductRef = { ...productRef };
    newProductRef.linked = productRef.linked.filter((i) => i.id !== id);
    newProductRef.removed.push(id);
    onChange(idField, newProductRef);
    setProductRef(newProductRef);
  };

  const handleProductRefAutocompleteChange = async (event) => {
    if (event && event.target.value?.length > 1) {
      const value = event.target.value.replace(/[|]/g, ' ');
      const productRefList = await getExternalSupplier(value);
      if (productRefList?.length) {
        setProductRefSearch(productRefList?.map((i) => ({ ...i, label: `${i.name} - ${i.code}` })));
      }
    }
  };

  const handleAddProductRef = (item) => {
    if (!productRef.linked.filter((itemToFilter) => itemToFilter.code === item.code).length) {
      const newProductRef = { ...productRef };
      newProductRef.removed = productRef.removed.filter((i) => i !== item.id);
      newProductRef.linked = [...newProductRef.linked, item];
      onChange(idField, newProductRef);
      setProductRef(newProductRef);
    }
  };

  const tags = productRef.linked.map((i) => ({ ...i, label: `${i.name} | #${i.code}` }));

  return (
    <>
      <AutocompleteCustom
        disabled={disabled}
        error={error}
        id={useDynId('add_products_autocomplete_input')}
        listData={productRefSearch}
        onInputChange={handleProductRefAutocompleteChange}
        onSelection={handleAddProductRef}
        placeholderText={t('supplier_choose_product')}
      />
      <div className={styles['tags-container']}>
        {tags.length ? (
          <section className={styles['product-ref-list']}>
            {tags.map((i) => {
              return (
                <div className={styles['tag']} key={i.id}>
                  {i.label}
                  <CrossIcon onClick={() => handleRemoveProductRef(i.id)} title="" />
                </div>
              );
            })}
          </section>
        ) : (
          ''
        )}
        <ButtonsCustom
          classType="link_primary"
          disabled={!tags.length}
          id={useDynId('show_products_button')}
          method={() => setDialogOpened(true)}
          text={t('supplier_show_product_list')}
        />
        <ContentDialog centerText spaceless isOpen={dialogOpened} handleClose={() => setDialogOpened(false)}>
          <ProductRefListDetails productRefs={productRef.linked} />
        </ContentDialog>
      </div>
    </>
  );
};

ProductRefList.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  externalSupplierId: PropTypes.number,
  idField: PropTypes.string,
  onChange: PropTypes.func,
};

export default ProductRefList;

export const ReadFile = (file) => {
  if (!file) return null;
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

export const blobToFile = (file, fileName, fileType) => {
  const typeSplit = fileType.split('/');
  const typeExt = typeSplit[1];
  const nameSplit = fileName.split('.');
  const fileExt = nameSplit[1];
  const nameExt = fileExt ? fileName : `${fileName}.${typeExt}`;

  return new File([file], nameExt, { type: fileType });
};

export const getBlobByUrl = (url) => fetch(url).then((res) => res.blob());

import React from 'react';
import PropTypes from 'prop-types';

import ConditionalWrapper from 'components/ConditionalWrapper';

import styles from './DragDropFile.module.scss';

const DragDropFile = ({ acceptedFileType, children, disabled, hasForm = true, onDropFile }) => {
  // drag state
  const [dragActive, setDragActive] = React.useState(false);
  // ref
  const inputRef = React.useRef(null);

  // handle drag events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (!disabled && e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    inputRef.current.click();
  };

  const handleFile = (file) => {
    onDropFile?.(file);
  };

  return (
    <div className={styles['root']}>
      <ConditionalWrapper
        condition={hasForm}
        defaultWrapper={(children) => (
          <div className={styles['form-file-upload']} onDragEnter={handleDrag}>
            {children}
          </div>
        )}
        wrapper={(children) => (
          <form
            className={styles['form-file-upload']}
            id="form-file-upload"
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
          >
            {children}
          </form>
        )}
      >
        <>
          <input
            accept={acceptedFileType}
            className={styles['input-file-upload']}
            disabled={disabled}
            id="input-file-upload"
            multiple={false}
            onChange={handleChange}
            onClick={(e) => (e.target.value = null)}
            ref={inputRef}
            type="file"
          />
          <label htmlFor="input-file-upload" id="label-file-upload">
            {React.cloneElement(children, { onClick: onButtonClick })}
          </label>
          {dragActive && (
            <div
              className={styles['drag-file-element']}
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </>
      </ConditionalWrapper>
    </div>
  );
};

DragDropFile.propTypes = {
  acceptedFileType: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  hasForm: PropTypes.bool,
  onDropFile: PropTypes.func.isRequired,
};

export default DragDropFile;

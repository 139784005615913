import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@mui/material/Autocomplete';
import TextFieldCustom from 'components/TextFieldCustom';

import { ReactComponent as DeleteIcon } from 'assets/cross_campaign.svg';
import { ReactComponent as ClearIcon } from 'assets/22px_croix.svg';
import { ReactComponent as DropdownArrowIcon } from 'assets/dropdown_arrow.svg';
import './AutocompleteCustom.scss';

const AutocompleteTag = ({
  className,
  disabled,
  error = false,
  id,
  inputType = 'text',
  listData,
  noOptionsText,
  multipleValue,
  onSelection,
  placeholderText,
  value = [],
  valueToLabel
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const maxValueReached = !multipleValue && value?.length > 0;

  const getOnlyPositiveNumber = (value) => {
    return value.match(/^\d*$/) ? value : value.slice(0, -1);
  };

  return (
    <Autocomplete
      classes={{
        root: `autocomplete-custom autocompleteTag ${className}`,
        paper: 'autocomplete-custom-paper paper-option-fullWidth',
        clearIndicator: 'clearIcon',
        clearIndicatorDirty: 'clearIconDirty',
        popupIndicator: 'popupIcon',
        endAdornment: 'autocomplete-custom-endAdornment',
        noOptions: 'autocomplete-custom-noOptions',
        listbox: 'autocomplete-custom-listBox',
        option: 'autocomplete-custom-option'
      }}
      clearOnBlur={true}
      clearText=""
      clearIcon={
        <div className="clearIcon" role="button">
          <ClearIcon />
        </div>
      }
      closeText=""
      disabled={disabled || maxValueReached}
      filterSelectedOptions
      forcePopupIcon
      freeSolo={!listData?.length}
      id={`multi-tags-autocomplete-${id}`}
      inputValue={inputValue}
      limitTags={2}
      multiple
      noOptionsText={noOptionsText || t('commun_no_result')}
      onChange={(event, option) => {
        onSelection(option);
      }}
      onInputChange={(event, newInputValue) => {
        let formattedValue = newInputValue;
        if (formattedValue !== '' && inputType === 'number') {
          formattedValue = getOnlyPositiveNumber(formattedValue);
        }
        setInputValue(formattedValue);
      }}
      openText=""
      options={listData || []}
      popupIcon={listData?.length ? <DropdownArrowIcon /> : null}
      renderInput={(params) => {
        return (
          <TextFieldCustom
            {...params}
            error={!!error}
            errorMsg={error}
            placeholder={maxValueReached ? '' : placeholderText}
            shrinkPlaceholder={false}
            onBlur={(e) => {
              if (!listData?.length && e.target.value) {
                let formattedValue = e.target.value;
                if (formattedValue !== '' && inputType === 'number') {
                  formattedValue = getOnlyPositiveNumber(formattedValue);
                }
                const newValues = [...value, formattedValue];
                onSelection(newValues);
              }
            }}
          />
        );
      }}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => {
          const onDelete = getTagProps({ index }).onDelete;
          return (
            <div className="value-tag" key={`${option}-${index}`}>
              {valueToLabel(option)}
              <span className="closeIcon" onClick={onDelete} role="button">
                {<DeleteIcon />}
              </span>
            </div>
          );
        })
      }
      value={value}
    />
  );
};

AutocompleteTag.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  listData: PropTypes.array,
  multipleValue: PropTypes.bool,
  noOptionsText: PropTypes.string,
  onSelection: PropTypes.func.isRequired,
  placeholderText: PropTypes.string,
  value: PropTypes.array,
  valueToLabel: PropTypes.func
};

export default AutocompleteTag;

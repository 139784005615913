import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TableCustom from 'components/TableCustom';
import ButtonsCustom from 'components/ButtonsCustom';

import Card from './Card';
import SearchBox from './SearchBox';

import styles from './PictureGallery.module.scss';

const PictureGallery = ({ retailerCode, products, size = 6, onCancel, onValidate, initialSelectedProduct }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(initialSelectedProduct);
  const [keywords, setKeywords] = useState([]);
  const [file, setFile] = useState();

  const filteredProducts = products.filter(
    (el) =>
      el.customProductId &&
      (keywords.length === 0 ||
        keywords.some((keyword) => el.label.toLowerCase().includes(keyword.toLowerCase())) ||
        keywords.some((keyword) => el.ean.toLowerCase().includes(keyword.toLowerCase())))
  );
  const paginatedProducts = filteredProducts.slice(size * page, size * (page + 1));

  const rows = paginatedProducts.map((item) => ({
    id: [{ value: item.ean }],
    card: (
      <Card
        {...item}
        retailerCode={retailerCode}
        selected={item.ean === selectedProduct?.ean}
        onClick={(fl) => {
          setFile(fl);
          setSelectedProduct(item);
        }}
      />
    )
  }));

  const columns = [{ id: 1, field: 'card', headerName: '', type: 'component' }];

  const handleValidate = () => {
    onValidate(file);
  };

  const handleSearchChange = (data) => {
    setKeywords(data);
    setPage(0);
  };

  return (
    <div className={styles['dialog-picture-gallery']}>
      <h2 className={styles['dialog-title']}>{t('offers_creation_image_choice_gallery')}</h2>

      <SearchBox onChange={handleSearchChange} />

      <TableCustom
        columns={columns}
        rows={rows}
        total={filteredProducts.length || 0}
        page={page}
        size={size}
        prev={() => setPage(page - 1)}
        next={() => setPage(page + 1)}
        showHeader={false}
        type="cards"
      />

      <div className={styles['dialog-picture-gallery-buttons-container']}>
        <ButtonsCustom classType="canceled" text={t('commun_cancel')} method={onCancel} />
        <ButtonsCustom classType="action_primary_big" text={t('commun_save')} method={handleValidate} />
      </div>
    </div>
  );
};

PictureGallery.propTypes = {
  onCancel: PropTypes.func,
  onValidate: PropTypes.func,
  products: PropTypes.array,
  retailerCode: PropTypes.string,
  selectedProduct: PropTypes.object,
  size: PropTypes.number
};

export default PictureGallery;

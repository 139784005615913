import React, { ReactNode, forwardRef, memo } from 'react';
import clsx from 'clsx';

import { Button } from '@mui/material';
import Spinner from 'components/Loaders/Spinner';
import Tooltip from 'components/Tooltip';

import styles from './ButtonsCustom.module.scss';

type Props = {
  classType:
    | 'action'
    | 'actionLight'
    | 'activeGreen'
    | 'activeRed'
    | 'addNewLine'
    | 'canceled'
    | 'canceled_small'
    | 'confirmedLighter'
    | 'dashed'
    | 'resetFilters'
    | 'resetFilters_active'
    | 'action_primary'
    | 'action_primary_big'
    | 'action_primary_home'
    | 'action_primary_small'
    | 'action_reverse'
    | 'action_reverse_lighter'
    | 'action_secondary'
    | 'action_secondary_big'
    | 'action_tertiary'
    | 'filter'
    | 'filter_active'
    | 'icon_primary'
    | 'icon_primary_small'
    | 'icon_primary_tiny'
    | 'icon_secondary_small'
    | 'icon_reverse_tiny'
    | 'link_primary'
    | 'link_primary_big'
    | 'link_primary_small'
    | 'link_black';
  disabled?: boolean;
  endIconCustom?: ReactNode;
  error?: boolean;
  errorType?: string;
  fullWidth?: boolean;
  id?: string;
  loading?: boolean;
  method?: (e: React.MouseEvent<HTMLElement>) => void;
  size?: 'small' | 'large' | 'medium' | 'thin';
  startIconCustom?: ReactNode;
  text?: ReactNode;
  textAlign?: 'left' | 'right';
  tooltip?: string;
};

const ButtonsCustom = forwardRef<HTMLButtonElement, Props>(
  (
    {
      classType,
      disabled,
      endIconCustom,
      error,
      errorType = 'shake',
      fullWidth,
      id,
      loading,
      method,
      size = 'large',
      startIconCustom,
      text,
      textAlign,
      tooltip
    },
    ref
  ) => {
    const buttonComponent = (
      <Button
        className={clsx(
          styles['button-custom'],
          styles[classType],
          styles[size],
          error && styles[`error-${errorType}`],
          fullWidth && styles['full-width'],
          loading && styles['loading'],
          textAlign === 'left' && styles['text-align-left'],
          textAlign === 'right' && styles['text-align-right'],
          styles['label']
        )}
        disableRipple
        disableTouchRipple
        id={id}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation();
          if (!loading && method) {
            method(e);
          }
        }}
        startIcon={startIconCustom}
        endIcon={endIconCustom}
        disabled={disabled}
        ref={ref}
        classes={{
          startIcon: styles['start-icon'],
          disabled: styles['disabled']
        }}
      >
        <>
          {loading && <Spinner className={styles['button-spinner']} />}
          {text}
        </>
      </Button>
    );
    const tooltipWrapper = (child: ReactNode) => (
      <Tooltip title={tooltip || ''}>
        <div>{child}</div>
      </Tooltip>
    );
    return tooltip ? tooltipWrapper(buttonComponent) : buttonComponent;
  }
);

export default memo(ButtonsCustom);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import TextFieldCustom from 'components/TextFieldCustom';

import styles from './TextField.module.scss';

const TextField = (props) => {
  const {
    className,
    data: { classNameContainer, counter, customMessage, defaultValue = '', disabled, id, placeholder, fieldProps },
    onChange,
    error,
  } = props;

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) onChange(id, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={`${styles['text-field']} ${classNameContainer}`}>
      <TextFieldCustom
        className={className}
        label={placeholder}
        type="text"
        onChange={(ev) => setValue(ev.target.value)}
        value={value}
        name={id}
        id={id}
        error={!!error}
        errorMsg={error}
        disabled={disabled}
        {...fieldProps}
      />
      {counter && (
        <div className={styles['help-counter']}>
          {customMessage ? <span>{customMessage}</span> : <span></span>}
          <span>
            {value?.length || 0}/{counter}
          </span>
        </div>
      )}
      {!counter && customMessage && (
        <div className={styles['help-counter']}>
          <span>{customMessage}</span>
        </div>
      )}
    </div>
  );
};

TextField.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default TextField;

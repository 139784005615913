import React from 'react';
import PropTypes from 'prop-types';
import { campaignTargetingStatuses } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import ToolTip from 'components/Tooltip';
import { ReactComponent as FailureIcon } from 'assets/30px_status_fail.svg';
import { ReactComponent as PendingIcon } from 'assets/30px_status_pending.svg';
import { ReactComponent as SuccessIcon } from 'assets/30px_status_success.svg';

const CampaignTargetingStatusTag = ({ status }) => {
  const { t } = useTranslation();

  if (status === campaignTargetingStatuses.NOT_REQUESTED) return null;
  else if ([campaignTargetingStatuses.REQUESTED, campaignTargetingStatuses.IN_PROCESS].includes(status))
    return (
      <div className="d-flex">
        <ToolTip title={t('campaign_details_allocation_in_progress')}>
          <PendingIcon />
        </ToolTip>
      </div>
    );
  else if (status === campaignTargetingStatuses.SUCCESS)
    return (
      <div className="d-flex">
        <ToolTip title={t('campaign_details_allocation_success_title')}>
          <SuccessIcon />
        </ToolTip>
      </div>
    );
  return (
    <div className="d-flex">
      <ToolTip title={t('campaign_details_allocation_failed_title')}>
        <FailureIcon />
      </ToolTip>
    </div>
  );
};

CampaignTargetingStatusTag.propTypes = {
  status: PropTypes.oneOf(Object.values(campaignTargetingStatuses)),
};

export default CampaignTargetingStatusTag;

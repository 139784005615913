import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Radio } from '@mui/material';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    backgroundColor: '#fff',
    border: '1px solid #E8E8EC',
    borderRadius: '50%',
    boxShadow: 'none',
    height: 14,
    width: 14,
  },
  checkedIcon: {
    '&:before': {
      backgroundColor: '#FF8378',
      borderRadius: '50%',
      content: '""',
      display: 'block',
      height: 6,
      position: 'relative',
      left: 3,
      top: 3,
      width: 6,
    },
  },
  disabledIcon: {
    '&:before': {
      backgroundColor: '#B1C0D3',
      borderRadius: '50%',
      content: '""',
      display: 'block',
      height: 6,
      position: 'relative',
      left: 3,
      top: 3,
      width: 6,
    },
  },
});

const StyledRadio = (props) => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={`${classes.icon} ${classes.checkedIcon} ${props.disabled ? classes.disabledIcon : ''}`} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};
StyledRadio.propTypes = {
  disabled: PropTypes.bool,
};

export default StyledRadio;

import React, { memo, forwardRef, useState, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import useDynId from 'customHooks/useDynId';
import { checkCampaignTitle } from 'utils/global';
import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import FormManager from 'components/FormManager';

const CashCouponStep1 = forwardRef(({ campaignCashCoupon, isEditMode, updateCashCoupon }, ref) => {
  const { t } = useTranslation();
  const thisRef = useRef(null);

  const [errorMessageTitleField, setErrorMessageTitleField] = useState(null);
  const [errorMessageDateField, setErrorMessageDateField] = useState(null);
  const [showAlertDialog, setShowAlertDialog] = useState(isEditMode);
  const [showAlertDialogAlreadyShown, setShowAlertDialogAlreadyShown] = useState(false);

  useImperativeHandle(ref, () => ({
    ref: thisRef,
    step1CheckHasError() {
      const titleHasError = checkHasTitleError();
      const dateHasError = checkHasDateError();
      return titleHasError || dateHasError;
    }
  }));

  const checkHasTitleError = () => {
    const { title } = campaignCashCoupon;
    let hasError = false;
    if (!title) {
      hasError = true;
      setErrorMessageTitleField(t('commun_field_required'));
    } else if (!checkCampaignTitle(title)) {
      hasError = true;
      setErrorMessageTitleField(t('campaign_creation_title_error'));
    }

    if (!hasError) {
      setErrorMessageTitleField();
    }
    return hasError;
  };

  const checkHasDateError = () => {
    const { date } = campaignCashCoupon;
    let hasError = false;

    if (!date.endDate || !date.startDate) {
      hasError = true;
      setErrorMessageDateField(t('commun_field_required'));
    }

    if (!hasError) {
      setErrorMessageDateField();
    }
    return hasError;
  };

  const handleChangeTitle = (title) => {
    updateCashCoupon({ title });
    //reset title errors
    if (title) setErrorMessageTitleField();
  };

  const handleChangeDates = (date) => {
    // reset step2 offers if dates are changed
    if (
      !date.startDate?.isSame(campaignCashCoupon.date.startDate, 'day') ||
      !date.endDate?.isSame(campaignCashCoupon.date.endDate, 'day')
    ) {
      updateCashCoupon({
        date,
        offers: [
          {
            retailerSegment: { id: '' },
            cashCoupon: { id: '' }
          }
        ]
      });
    } else {
      updateCashCoupon({ date });
    }
    // reset dates errors
    if (date.startDate && date.endDate) setErrorMessageDateField();
  };

  const handleDatePickerFocusInput = (focusInput) => {
    if (
      focusInput &&
      !showAlertDialogAlreadyShown &&
      campaignCashCoupon.offers.some((el) => el.cashCoupon.id && el.retailerSegment.id)
    ) {
      setShowAlertDialog(true);
    }
  };

  const handleCloseDatesAlertDialog = () => {
    setShowAlertDialog(false);
    setShowAlertDialogAlreadyShown(true);
  };

  return (
    <>
      <div ref={thisRef}>
        <FormManager
          data={{
            title: t('campaign_creation_step_parameters_title'),
            subTitle: t('campaign_creation_step_parameters_desc'),
            fieldsets: [
              {
                id: 'add-campaign-cash-coupon-step-01',
                fields: [
                  {
                    outerContainerClass: ['without-margin-top'],
                    placeholder: t('campaign_cash_coupon_step1_input_title'),
                    type: 'TextField',
                    id: 'title',
                    defaultValue: campaignCashCoupon.title,
                    error: errorMessageTitleField,
                    onFieldChange: handleChangeTitle
                  },
                  {
                    type: 'DateRangePicker',
                    id: 'date',
                    defaultValue: campaignCashCoupon.date,
                    error: errorMessageDateField,
                    fieldProps: { getFocusInput: handleDatePickerFocusInput, minDate: moment() },
                    onFieldChange: handleChangeDates
                  }
                ]
              }
            ]
          }}
        />
      </div>
      <ContentDialog
        centerText
        isOpen={showAlertDialog}
        handleClose={handleCloseDatesAlertDialog}
        title={t('campaign_product_step2_modal_alert_message')}
        maxWidth="xs"
      >
        <div className="confirm-dialog">
          <ButtonsCustom
            classType="action_primary_big"
            text={t('commun_button_got_it')}
            method={handleCloseDatesAlertDialog}
            id={useDynId('step1Valid2Modal')}
          />
        </div>
      </ContentDialog>
    </>
  );
});

CashCouponStep1.propTypes = {
  campaignCashCoupon: PropTypes.object,
  isEditMode: PropTypes.bool,
  updateCashCoupon: PropTypes.func
};

export default memo(CashCouponStep1);

import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';

import styles from './LearnMore.module.scss';

const LearnMore = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <ButtonsCustom classType="link_primary_small" method={handleOpen} text={t('commun_learn_more')} />
      <ContentDialog
        isOpen={isOpen}
        handleClose={handleClose}
        title={t('offers_creation_block_budget_reco_learn_more_popup_title')}
        maxWidth="xs"
      >
        <div className={styles['root']}>
          <Trans i18nKey="offers_creation_block_budget_reco_learn_more_popup_desc" />
        </div>
      </ContentDialog>
    </>
  );
};

export default LearnMore;

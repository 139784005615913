import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { getAllSuppliers, getSegments, updateSegments } from 'api';
import { segmentTypes, segmentUpdatingStatuses } from 'utils/constants';
import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import Drawer from 'components/Drawer';
import TableCustom from 'components/TableCustom';
import ScrollToTop from 'components/ScrollToTop';
import SegmentDetails from 'pages/SegmentDetails';
import SegmentUpdatingStatusIcon from 'components/SegmentUpdatingStatusIcon';
import FiltersContainer, { getInitialFilters } from 'components/Filters';
import useDynId from 'customHooks/useDynId';

import { ReactComponent as UploadIcon } from 'assets/30px_import.svg';
import styles from './Segmentation.module.scss';

const Segmentation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const { id: segmentDetailsId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({ type: 'title', sort: 'asc' });
  const [checkList, setCheckList] = useState([]); // [retailerSegment]
  const [actionType, setActionType] = useState(null); // null, archive or restore
  const oppositeSort = order.sort === 'asc' ? 'desc' : 'asc';
  const tableSize = 30;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initFilters = useMemo(
    () => getInitialFilters({ history, queryFilters: state.queryFilters, suppliers: state.suppliers }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const [filters, setFilters] = useState(initFilters);
  const filtersListToDisplay = useMemo(() => {
    return ['title', 'segmentType', 'segmentUpdatingStatus', 'archived', 'retailerUser'];
  }, []);

  const archiveButtonDisabled = !checkList.length || checkList.some((item) => item.archived);
  const restoreButtonDisabled = !checkList.length || checkList.some((item) => !item.archived);

  const fetchSegments = async () => {
    setIsLoading(true);
    await getSegments({
      retailerCode: state.user.code,
      page,
      size: tableSize,
      filters,
      supplierCodes: filters.supplierId?.length
        ? filters.supplierId.map((id) => state.suppliers.find((sup) => sup.id === id)?.code)
        : undefined
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (!state.suppliers.length) {
      getAllSuppliers();
    }

    // on unmount
    return () => {
      dispatch({ type: 'SEGMENTS_RESET' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!filters.supplierId?.length || state.suppliers.length) {
      fetchSegments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.user.code, page, order.sort, order.type, filters, state.suppliers]);

  const columns = [
    // { id: 1, field: 'id', headerName: t('segment_table_id'), type: 'text', sortable: false },
    { id: 2, field: 'title', headerName: t('segment_table_title'), type: 'text', sortable: false },
    { id: 3, field: 'nbClient', headerName: t('segment_table_number_of_customers'), type: 'number', sortable: false },
    { id: 4, field: 'segmentType', headerName: t('_dyn_commun_type'), type: 'text', sortable: false },
    { id: 5, field: 'majDate', headerName: t('segment_table_lastUpdate'), type: 'text', sortable: false },
    { id: 6, field: 'supplier', headerName: t('commun_supplier'), type: 'text', sortable: false },
    {
      id: 7,
      field: 'segmentUpdatingStatus',
      headerName: t('segment_table_calcul'),
      type: 'component',
      sortable: false
    },
    {
      id: 8,
      field: 'update',
      headerName: t('commun_update'),
      type: 'text',
      sortable: false
    },
    {
      id: 9,
      field: 'status',
      headerName: t('commun_status'),
      type: 'tag',
      sortable: false
    }
  ];

  const rows = state.segments.list.map((retailerSegment) => {
    return {
      checked:
        retailerSegment.lastUpdate && retailerSegment.updatingStatus !== segmentUpdatingStatuses.FAIL
          ? !!checkList.find((retSegment) => retSegment.id === retailerSegment.id)
          : null,
      id: [{ value: retailerSegment.id }],
      title: [{ value: retailerSegment.name }],
      nbClient: [
        {
          value: t('commun_number', { value: retailerSegment.customerCount ?? '-' })
        }
      ],
      segmentType: [{ value: retailerSegment.type ? t(`segment_type_${retailerSegment.type}`) : '-' }],
      majDate: [{ value: retailerSegment.lastUpdate ? t('commun_date', { value: retailerSegment.lastUpdate }) : '-' }],
      supplier: [
        {
          value: retailerSegment.supplierId
            ? state.suppliers.find((sup) => sup.id.toString() === retailerSegment.supplierId)?.name || '-'
            : '-'
        }
      ],
      segmentUpdatingStatus: (
        <div className="d-flex">
          <SegmentUpdatingStatusIcon status={retailerSegment.updatingStatus} />
          {retailerSegment.type === segmentTypes.IMPORT &&
            retailerSegment.updatingStatus === segmentUpdatingStatuses.FAIL && (
              <span className={styles['import-error-msg']}>{t('commun_import_error')}</span>
            )}
        </div>
      ),
      update: [{ value: retailerSegment.archived ? '-' : t('commun_weekly') }],
      status: [
        {
          value: retailerSegment.archived ? t('segment_table_archived') : t('segment_table_active'),
          color: retailerSegment.archived ? 'disabled' : 'valid',
          textStyle: 'tag'
        }
      ]
    };
  });

  const handleTableRowClick = ({ id }) => {
    history.push({ pathname: `/segmentation/${id[0].value}`, search: history.location.search });
  };

  const handleClose = () => {
    history.push({ pathname: '/segmentation', search: history.location.search });
  };

  const onDetailsPanelCloseFinished = () => {
    dispatch({ type: 'SEGMENTS_DETAILS_RESET' });
  };

  const handleCheck = (elem) => {
    let newItems;
    if (elem.id === 'all') {
      // Set uniqueness works with primitive values (number, string)
      newItems = new Set(checkList.map((t) => JSON.stringify(t)));

      rows
        .filter((item) => item.checked !== null)
        .forEach((item) => {
          const id = item.id[0].value;
          const currentSelection = JSON.stringify(
            state.segments.list.find((retailerSegment) => retailerSegment.id === id)
          );

          if (elem.checked) {
            newItems.add(currentSelection);
          } else {
            newItems.delete(currentSelection);
          }
        });
      // back to an Array of Objects
      newItems = [...newItems].map((str) => JSON.parse(str));
    } else {
      const id = elem.item.id[0].value;
      newItems = elem.checked
        ? [...checkList, state.segments.list.find((retailerSegment) => retailerSegment.id === id)]
        : checkList.filter((retailerSegment) => retailerSegment.id !== id);
    }
    setCheckList(newItems);
  };

  const handleAction = async () => {
    setIsLoading(true);
    await updateSegments(
      checkList.map((seg) => ({
        ...seg,
        archived: actionType === 'archive'
      }))
    );

    await fetchSegments();
    setCheckList([]);
    setIsLoading(false);
    setActionType(null);
  };

  const handleUpdateFilters = useCallback((newFilters) => {
    setPage(0);
    setFilters({ ...newFilters });
  }, []);

  const handleGoToImportState = () => {
    history.push({
      pathname: '/segmentation/segment-manager-campaign',
      search: `${history.location.search ? history.location.search + '&' : '?'}import-file=true`
    });
  };

  return (
    <>
      <section className={styles['root']}>
        <div className={styles['top-wrapper']}>
          <div className={styles['header']}>
            <div className={styles['header-title']}>{t('segment_title')}</div>
            <div className={styles['header-desc']}>{t('segment_desc')}</div>
          </div>
        </div>
        <div className={styles['top-wrapper']}>
          <p className={styles['count-result']} id={useDynId('Xsegment')}>
            {t('commun_segment_count', { count: state.segments.total })}
          </p>
          <div className={styles['right-buttons']}>
            <ButtonsCustom
              classType="link_primary_big"
              disabled={restoreButtonDisabled}
              method={() => {
                setActionType('restore');
              }}
              text={t('commun_restore')}
              tooltip={restoreButtonDisabled ? t('segment_restore_tooltip') : ''}
            />

            <ButtonsCustom
              classType="link_primary_big"
              disabled={archiveButtonDisabled}
              method={() => {
                setActionType('archive');
              }}
              text={t('commun_archive')}
              tooltip={archiveButtonDisabled ? t('segment_archive_tooltip') : ''}
            />
            <ButtonsCustom
              classType="icon_primary"
              disabled={isLoading}
              method={isLoading ? null : handleGoToImportState}
              startIconCustom={<UploadIcon />}
              tooltip={t('commun_segment_import')}
            />
            <Link to={`/segmentation/add-segment${history.location.search}`} role="link">
              <ButtonsCustom classType="action_primary_big" text={t('commun_button_create_segment')} />
            </Link>
          </div>
        </div>
        <FiltersContainer
          dispatch={dispatch}
          filters={filters}
          filtersListToDisplay={filtersListToDisplay}
          suppliersList={state.suppliers}
          updateFilters={handleUpdateFilters}
          user={state.user}
        />
        <TableCustom
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          total={state.segments.total}
          handleSort={(type) => type && setOrder({ type: type, sort: type === order.type ? oppositeSort : 'desc' })}
          page={page}
          size={tableSize}
          prev={() => setPage(page - 1)}
          next={() => setPage(page + 1)}
          order={order.sort}
          sort={order.type}
          type="big"
          onRowClick={handleTableRowClick}
          check={handleCheck}
          isUsingFilters={!!Object.keys(filters)?.length}
          clickedRawUniqueKeyValue={segmentDetailsId?.toString()}
        />
        <Drawer isOpen={!!segmentDetailsId} onClose={handleClose} onCloseFinished={onDetailsPanelCloseFinished}>
          <SegmentDetails segmentId={segmentDetailsId} />
        </Drawer>
        <ScrollToTop />
      </section>
      <ContentDialog
        centerText
        isLoading={isLoading}
        isOpen={!!actionType}
        handleClose={() => {
          setActionType(null);
        }}
        title={t(`segment_${actionType}_confirm`, { count: checkList.length })}
        maxWidth="xs"
      >
        <div className="confirm-dialog">
          <ButtonsCustom
            classType="canceled"
            text={t('commun_button_no')}
            method={() => setActionType(null)}
            disabled={isLoading}
          />
          <ButtonsCustom
            classType="action_primary_big"
            text={t('commun_button_yes_continue')}
            method={handleAction}
            loading={isLoading}
          />
        </div>
      </ContentDialog>
    </>
  );
};

export default Segmentation;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { getSegments } from 'api';
import { segmentScopes } from 'utils/constants';

import AutocompleteSelect from 'components/AutocompleteCustom/AutocompleteSelect';
import FormManager from 'components/FormManager';

import styles from './RetailerSegmentSelection.module.scss';

const RetailerSegmentSelection = ({ disabled = true }) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const {
    campaignCreation: {
      product: { retailerSegment }
    },
    user,
    segments
  } = state;
  const [isLoading, setIsLoading] = useState(false);

  const allSegments = useMemo(() => {
    return segments.listAll.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  }, [segments.listAll]);

  useEffect(() => {
    const fetchSegments = async () => {
      setIsLoading(true);
      await getSegments({ retailerCode: user.code, scope: segmentScopes.CAMPAIGN });
      setIsLoading(false);
    };
    fetchSegments();
  }, [user.code]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'SEGMENTS_RESET' });
    };
  }, [dispatch]);

  const handleDelete = () => {
    dispatch({ type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE', payload: { retailerSegment: null } });
  };

  const handleValidation = (selectedSegment) => {
    if (selectedSegment && selectedSegment.id !== retailerSegment?.id) {
      dispatch({ type: 'CAMPAIGN_CREATION_PRODUCT_UPDATE', payload: { retailerSegment: selectedSegment } });
    }
  };

  if (!segments.total) {
    return null;
  }
  return (
    <FormManager
      data={{
        fieldsets: [
          {
            id: 'add-campaign-product-segment-selection-container',
            fields: [
              {
                outerContainerClass: ['full-width'],
                id: 'retailer-segment-selection-form-container',
                type: 'CustomContent',
                component: (
                  <>
                    <div className={styles['root']}>
                      <div>
                        <span className={styles['title']}>{t('campaign_product_creation_retailerSegment_title')}</span>
                        <span className={styles['subTitle']}>{`(${t('commun_optional')})`}</span>
                      </div>
                      <p className={styles['desc']}>{t('campaign_product_creation_retailerSegment_desc')}</p>
                      <p className={styles['input-label']}>
                        {t('campaign_cash_coupon_step2_form_segment_place_holder')}
                      </p>

                      <AutocompleteSelect
                        disabled={disabled || isLoading}
                        disablePortal
                        id="select-segment"
                        isLoading={isLoading}
                        labelAttribute="name"
                        listData={allSegments}
                        onSelection={(segment) => {
                          if (segment) {
                            handleValidation(segment);
                          } else {
                            handleDelete();
                          }
                        }}
                        placeholderText={t('campaign_cash_coupon_step2_form_segment_place_holder')}
                        useCustomSegmentOption
                        value={allSegments.find((seg) => seg.id === retailerSegment?.id)}
                      />
                    </div>
                  </>
                )
              }
            ]
          }
        ]
      }}
    />
  );
};

RetailerSegmentSelection.propTypes = {
  disabled: PropTypes.bool
};

export default RetailerSegmentSelection;

import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { store } from 'store';
import { disableOffer, notifyRetailers } from 'api';
import { snackbarTypes } from 'utils/constants';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import Tags from 'components/Tags';

import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';
import styles from './OfferDisableDialog.module.scss';

import { offerDisableStatusType } from 'types/offerDisableStatus';

type Props = {
  dialogDisableOpen: boolean;
  disableStatus?: offerDisableStatusType;
  notifyRetailersEnabled: boolean;
  offerHeadId?: number;
  onCloseDialog: () => void;
  updateData: () => void;
};

const OfferDisableDialog = ({
  dialogDisableOpen,
  disableStatus,
  notifyRetailersEnabled,
  offerHeadId,
  onCloseDialog,
  updateData
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { dispatch } = globalState as any;

  const [isDisableLoading, setIsDisableLoading] = useState<boolean>(false);

  const handleDisableOffer = async () => {
    setIsDisableLoading(true);
    const disabled = await disableOffer(offerHeadId);

    if (disabled) {
      if (notifyRetailersEnabled) {
        await notifyRetailers(offerHeadId);
      }
      onCloseDialog();
      updateData();

      dispatch({
        type: 'UI_SNACKBAR',
        payload: { type: snackbarTypes.DISABLE, title: { key: 'commun_offer_disabled' } }
      });
    }

    setIsDisableLoading(false);
  };

  const displayLinkedCampaignsWarning = () => {
    let display = [];
    const allocatedLinkedCampaigns = disableStatus?.infos.find((info) => info.code === 'CAMP004');
    const notAllocatedLinkedCampaigns = disableStatus?.infos.find((info) => info.code === 'CAMP003');

    if (allocatedLinkedCampaigns)
      display.push(
        <div className={styles['warning-list-title']} key={allocatedLinkedCampaigns.code}>
          {t(`offer_details_disable_status_code_${allocatedLinkedCampaigns.code}`, {
            count: allocatedLinkedCampaigns.lstCampaignId.length
          })}
          <ul>
            {allocatedLinkedCampaigns.campaignsList.map((el) => (
              <li key={el.id}>
                <div className={styles['li-container']}>
                  <div className="d-flex">
                    <Tags status={el.campaignState} />
                    <p>{el.title}</p>
                  </div>
                  <div>{el.id}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    if (notAllocatedLinkedCampaigns)
      display.push(
        <div className={styles['warning-list-title']} key={notAllocatedLinkedCampaigns.code}>
          {t(`offer_details_disable_status_code_${notAllocatedLinkedCampaigns.code}`, {
            count: notAllocatedLinkedCampaigns.lstCampaignId.length
          })}
          <ul>
            {notAllocatedLinkedCampaigns.campaignsList.map((el) => (
              <li key={el.id}>
                <div className={styles['li-container']}>
                  <div className="d-flex">
                    <Tags status={el.campaignState} />
                    <p>{el.title}</p>
                  </div>
                  <div>{el.id}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      );
    return display;
  };

  return (
    <ContentDialog
      centerText
      isLoading={isDisableLoading}
      isOpen={dialogDisableOpen}
      handleClose={onCloseDialog}
      title={t('offer_details_dialog_disable_title')}
      desc={t('offer_details_dialog_disable_subtitle')}
      maxWidth="xs"
    >
      <div className={styles['root']}>
        <div className={styles['confirm-dialog']}>
          <ButtonsCustom
            classType="canceled"
            disabled={isDisableLoading}
            text={t('commun_cancel')}
            method={onCloseDialog}
          />
          <ButtonsCustom
            classType="action_primary_big"
            loading={isDisableLoading}
            text={t('commun_button_yes_continue')}
            method={handleDisableOffer}
          />
        </div>

        {!!disableStatus?.infos?.length && (
          <div className={styles['warning']}>
            <div className={styles['error-list-title']}>
              <WarningIcon />
              <span>{t('offer_details_dialog_disable_warning')}</span>
            </div>
            {displayLinkedCampaignsWarning()}
          </div>
        )}
      </div>
    </ContentDialog>
  );
};

export default OfferDisableDialog;

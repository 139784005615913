import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { store } from 'store';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteCampaign,
  getCampaign,
  getCashCouponsCampaigns,
  getDuplicateCampaignStatus,
  getUntargetableOffersCampaigns,
  notifyProductCampaignSuppliers
} from 'api';
import {
  campaignDeletionStatuses,
  campaignTargetingStatuses,
  campaignTypes,
  notificationStates
} from 'utils/constants';
import useDynId from 'customHooks/useDynId';
import { formatFloat, withFractionDigits } from 'utils/global';
import { getFarthestDuplicableInitialEndDate, getFarthestDublicableOfferEndDate } from './utils';
import ABTestingResults from 'components/CampaignKpiResults/ABTesting';
import Accordion from 'components/Accordion';
import AllocationPanel from 'components/AllocationPanel';
import ButtonsCustom from 'components/ButtonsCustom';
import CampaignKeypointsPanel from 'components/CampaignKeypointsPanel';
import CampaignKpiResults from 'components/CampaignKpiResults';
import CampaignParametersTable from 'components/CampaignParametersTable';
import ContentDialog from 'components/ContentDialog';
import Divider from 'components/Divider';
import DuplicateCampaignDialog from 'components/DuplicateCampaignDialog';
import GoBackLink from 'components/GoBackLink';
import NoCampaignData from 'components/NoCampaignData';
import Roi from 'components/RoiFrame';
import SegmentUpdatingStatusIcon from 'components/SegmentUpdatingStatusIcon';
import Tags from 'components/Tags';

import { ReactComponent as DuplicateIcon } from 'assets/duplicate.svg';
import { ReactComponent as EditIcon } from 'assets/16px_edit.svg';
import { ReactComponent as EmailIcon } from 'assets/30px_email.svg';
import { ReactComponent as FileIcon } from 'assets/30px_file.svg';
import { ReactComponent as TrashIcon } from 'assets/16px_trash.svg';
import { ReactComponent as TrashLoadingIcon } from 'assets/trash_loading_red.svg';

import styles from './CampaignDetails.module.scss';

const CampaignsDetails = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const {
    campaigns: { details: campaign },
    user
  } = state;
  const { id } = useParams();
  const [hasData, setHasData] = useState(false);
  const [hasDuplicableOffers, setHasDuplicableOffers] = useState(null);
  const [duplicableOfferDatesLimits, setduplicableOfferDatesLimits] = useState(null);
  const [duplicableInitialDates, setDuplicableInitialDates] = useState({});
  const [isInitLoading, setIsInitLoading] = useState(true);
  const [isNotificationSending, setIsNotificationSending] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);

  const backId = useDynId('backToList');
  const deleteId = useDynId('delete');
  const listOfferId = useDynId('listOffer');

  const currentCampaignType = campaign.campaignType;
  const isAllocationSuccess = campaign.targetingStatus === campaignTargetingStatuses.SUCCESS;

  const transactionalKpis =
    currentCampaignType === campaignTypes.CASH_COUPON
      ? campaign.transactionalKpisCashCouponCampaign
      : currentCampaignType === campaignTypes.PRODUCT
      ? campaign.transactionalKpisOfferCampaign
      : null;

  const showTransactionalKpis =
    isAllocationSuccess && transactionalKpis && moment().isSameOrAfter(campaign.validityStartDate, 'day');
  const hasBudgetSpent =
    currentCampaignType === campaignTypes.CASH_COUPON
      ? !!campaign.transactionalKpisCashCouponCampaign?.budget
      : !!campaign.transactionalKpisOfferCampaign?.discountGranted;

  const isCampaignExpired = moment().isAfter(campaign.validityEndDate, 'day');
  const isCampaignStarted = moment().isAfter(campaign.validityStartDate, 'day');

  const isEditable = campaignTargetingStatuses.NOT_REQUESTED === campaign.targetingStatus && !isCampaignStarted;
  const isInProcessToDelete = campaign.deletionStatus === campaignDeletionStatuses.IN_PROCESS_TO_DELETE;

  const isNotificationsInactive =
    campaign.notificationState === notificationStates.SENT ||
    isCampaignExpired ||
    isNotificationSending ||
    isCampaignStarted;

  useEffect(() => {
    // --> first Fetch on mount
    const init = async () => {
      setIsInitLoading(true);
      const getCampaignData = await getCampaign(id);
      setHasData(!!getCampaignData);
      setIsInitLoading(false);
    };
    init();

    return () => dispatch({ type: 'CAMPAIGNS_DETAILS_RESET' });
  }, [dispatch, id]);

  useEffect(() => {
    const fetchFarthestEndDateOffer = async () => {
      let getDuplicableLastOffer =
        campaign.campaignType === campaignTypes.PRODUCT
          ? await getUntargetableOffersCampaigns({
              campaignId: campaign.id,
              page: 0,
              size: 1,
              sortBy: 'offerOfferHeadValidityEndDate'
            })
          : await getCashCouponsCampaigns({
              campaignId: campaign.id,
              page: 0,
              size: 1,
              sortBy: 'cashCouponValidityEndDate'
            });
      if (getDuplicableLastOffer?.length) {
        getDuplicableLastOffer =
          getDuplicableLastOffer[0][campaign.campaignType === campaignTypes.PRODUCT ? 'offer' : 'cashCoupon'];

        const initialStartDate = moment().isAfter(moment(campaign.validityStartDate), 'day')
          ? moment()
          : moment(campaign.validityStartDate);

        const offerEndDate =
          campaign.campaignType === campaignTypes.PRODUCT
            ? moment(getDuplicableLastOffer.offerHead.validityEndDate)
            : moment(getDuplicableLastOffer.validityEndDate);

        setDuplicableInitialDates({
          endDate: getFarthestDuplicableInitialEndDate({
            campaign: campaign,
            offerEndDate,
            initialStartDate
          }),
          startDate: initialStartDate
        });
        setduplicableOfferDatesLimits({
          endDate: getFarthestDublicableOfferEndDate(offerEndDate),
          startDate: moment()
        });
      }
    };
    if (campaign?.id) fetchFarthestEndDateOffer();
  }, [campaign]);

  useEffect(() => {
    const fetchData = async () => {
      const duplicateStatusData = await getDuplicateCampaignStatus({
        campaignId: campaign?.id,
        validityEndDate: duplicableInitialDates.endDate,
        validityStartDate: duplicableInitialDates.startDate
      });
      if (duplicateStatusData) {
        setHasDuplicableOffers(duplicateStatusData.duplicable);
      }
    };
    if (
      hasDuplicableOffers === null &&
      campaign?.id &&
      duplicableInitialDates.startDate &&
      duplicableInitialDates.endDate
    ) {
      fetchData();
    }
  }, [campaign, duplicableInitialDates, hasDuplicableOffers]);

  const flushStore = useCallback(() => {
    dispatch({ type: 'CAMPAIGNS_DETAILS_RESET' });
  }, [dispatch]);

  const handleCloseDuplicateDialog = useCallback(() => {
    setIsDuplicateDialogOpen(false);
  }, []);

  const handleDelete = async () => {
    setIsDeleteLoading(true);
    if (
      campaign.targetingStatus === campaignTargetingStatuses.NOT_REQUESTED ||
      campaign.targetingStatus === campaignTargetingStatuses.FAIL_DATA
    ) {
      const isDeleteSuccess = await deleteCampaign({ campaign });
      if (isDeleteSuccess) {
        flushStore();
        history.push('/campaigns');
      }
    } else {
      const isDeleteSuccess = await deleteCampaign({ campaign, displaySnackbar: false });
      if (isDeleteSuccess) {
        await getCampaign(id);
        setIsDeleteDialogOpen(false);
        setIsDeleteLoading(false);
      }
    }
  };

  const handleSendNotification = async () => {
    if (!isNotificationsInactive) {
      setIsNotificationSending(true);
      await notifyProductCampaignSuppliers({ campaign });
      await getCampaign(campaign.id);
      setIsNotificationSending(false);
    }
  };

  const displayCashcouponsOffersSummary = ({ clientNumber, clientPercent, item, isInAccordion }) => {
    return (
      <div className={`${styles['main']} ${styles['summary']}`}>
        <div className={styles['left']}>
          <p>
            {`${item.externalSegment.name} - ${t('commun_customer_count', {
              count: clientNumber || 0
            })}`}
            <span className={styles['separator-grey']}>|</span>
            <span className={styles['summary-info']}>
              <Trans
                i18nKey="campaign_details_kpi_cashcoupon_targeted_client_count"
                values={{
                  count: item.kpis.sampleATargetedClients ?? '-',
                  percentValue: clientPercent ?? '-'
                }}
              />
            </span>
          </p>
          <div className="d-flex">
            <div className={styles['summary-desc']}>
              {item.cashCoupon.title} - {item.cashCoupon.id}
            </div>
          </div>
          <div className="d-flex">
            <div className={styles['summary-desc']}>{displayCashcouponGenerosityRecap(item.cashCoupon)}</div>
          </div>
        </div>
        <div className={`${styles['right']} ${isInAccordion ? styles['isInAccordion'] : ''}`}>
          {typeof item.kpis.abTested === 'boolean' && (
            <div>{t(`campaign_details_kpi_cashcoupon_segment_abtesting_${item.kpis.abTested}`)}</div>
          )}
          <SegmentUpdatingStatusIcon status={item.externalSegment.updatingStatus} />
        </div>
      </div>
    );
  };

  const displayCashcouponGenerosityRecap = ({ discountBasketThreshold, discountType, discountUnit, discountValue }) => {
    const basketThresholdValue = t('commun_price', {
      currency: user.currency.code,
      value: discountBasketThreshold || 0
    });

    const value = parseFloat(formatFloat(discountValue));
    const maximumFractionDigits = Number.isSafeInteger(value) ? 0 : 2;

    const generosityValue =
      discountUnit === 'PERCENT'
        ? `${t('commun_number', {
            value,
            maximumFractionDigits
          })} ${user.discountUnits.find((i) => i.denomination === discountUnit)?.label}`
        : t('commun_price', {
            currency: user.currency.code,
            maximumFractionDigits,
            value
          });

    return (
      <Trans
        i18nKey={`cash_coupon_creation_summary_type_${discountType === 'LOYALTY' ? 'loyalty' : 'immediate'}`}
        values={{
          basketThresholdValue,
          generosityValue
        }}
      />
    );
  };

  if (!hasData) {
    return (
      <section className={styles['root']}>
        <GoBackLink id={backId} pathname="/campaigns" flushStore={flushStore} />
        <NoCampaignData isLoading={isInitLoading} />
      </section>
    );
  }

  return (
    <>
      <section className={styles['root']}>
        <GoBackLink id={backId} pathname="/campaigns" flushStore={flushStore} />

        <div className={styles['layout-col']}>
          <div className={styles['main-col']}>
            {campaign.deletionStatus && (
              <div className={styles['delete-inProcess-frame']}>
                <TrashLoadingIcon />
                <div>
                  <p className={styles['title']}>
                    {t(
                      campaign.deletionStatus === campaignDeletionStatuses.FAIL_DELETE_DATA
                        ? 'campaign_details_deleting_frame_title_failed'
                        : 'campaign_details_deleting_frame_title'
                    )}
                  </p>
                  <p className={styles['desc']}>
                    {t(
                      campaign.deletionStatus === campaignDeletionStatuses.FAIL_DELETE_DATA
                        ? 'campaign_details_deleting_frame_desc_failed'
                        : 'campaign_details_deleting_frame_desc'
                    )}
                  </p>
                </div>
              </div>
            )}
            <div className={styles['frame']}>
              <div className={styles['header']}>
                <div className={styles['campaign-title']}>
                  <div>{campaign.title}</div>
                  <Tags status={campaign.campaignState} />
                </div>
                <span className={styles['campaign-id']}>ID : {id}</span>
                {campaign.creator && campaign.createdAt && (
                  <span className={styles['campaign-createdBy']}>{` - ${t('campaign_details_created_by_at', {
                    value: campaign.createdAt,
                    text: campaign.creator,
                    datetime: campaign.createdAt
                  })}`}</span>
                )}
              </div>
              <div className={styles['info-section']}>
                <div className={styles['row']}>
                  <div className={styles['box']}>
                    <p className={styles['label']}>{t('commun_validation_dates')}</p>
                    <p className={styles['content']}>
                      {t('commun_from_to_dates', {
                        valueFrom: campaign.validityStartDate,
                        valueTo: campaign.validityEndDate
                      })}
                    </p>
                  </div>
                </div>
              </div>
              {(showTransactionalKpis ||
                campaign.externalSegment?.id ||
                campaignTypes.CASH_COUPON === currentCampaignType ||
                campaign.personalized) && <Divider />}

              {showTransactionalKpis && currentCampaignType === campaignTypes.CASH_COUPON && (
                <section className={styles['campaignResults-section']}>
                  <div className={styles['title']}>{t('campaign_details_keypoints_results')}</div>
                  {hasBudgetSpent && (
                    <>
                      <CampaignKpiResults
                        campaignType={campaign.campaignType}
                        currency={user.currency.code}
                        transactionalKpis={transactionalKpis}
                      />
                      <Roi roi={transactionalKpis.returnOnInvestment} />
                    </>
                  )}
                  <ABTestingResults campaign={campaign} currency={user.currency.code} hasBudgetSpent={hasBudgetSpent} />
                </section>
              )}
              {showTransactionalKpis && currentCampaignType === campaignTypes.PRODUCT && (
                <section className={styles['campaignResults-section']}>
                  <div className={styles['title']}>{t('campaign_details_keypoints_results')}</div>
                  <CampaignKpiResults
                    campaignType={campaign.campaignType}
                    currency={user.currency.code}
                    transactionalKpis={transactionalKpis}
                  />
                </section>
              )}

              {campaign.externalSegment?.id && (
                <div className={styles['retailerSegment']}>
                  <div className={styles['retailerSegment-container']}>
                    <div>
                      <p className={styles['title']}>{t('campaign_product_creation_retailerSegment_title')}</p>
                      <p className={styles['content']}>
                        {campaign.externalSegment?.name} -{' '}
                        {campaign.targetingDate && campaign.targetedSegments?.length ? (
                          <i>
                            {t('campaign_details_retailerSegment_after_allocation_info', {
                              count: campaign.targetedSegments?.[0]?.clientNumber,
                              value: campaign.targetingDate
                            })}
                          </i>
                        ) : (
                          <i>{`${t('commun_customer_count', {
                            count: campaign.externalSegment?.customerCount || 0
                          })}`}</i>
                        )}
                      </p>
                    </div>
                    <SegmentUpdatingStatusIcon status={campaign.externalSegment?.updatingStatus} />
                  </div>
                  <p className={styles['info']}>
                    {campaign.personalized
                      ? t('campaign_details_targeting_date_info')
                      : t('campaign_details_targeting_date_info_non_personalized')}
                  </p>
                </div>
              )}

              {campaignTypes.PRODUCT === currentCampaignType && campaign.personalized && (
                <section className={styles['campaignResults-section']}>
                  <div className={styles['parameters']}>
                    <CampaignParametersTable
                      parameters={campaign.paramsOfferCampaign}
                      settingProductLevelLabelThree={user.settingProductLevelLabelThree}
                    />
                  </div>
                </section>
              )}
              {campaignTypes.CASH_COUPON === currentCampaignType && (
                <section className={styles['campaignResults-section']}>
                  <div className={styles['title']}>
                    {t('commun_cash_coupon_count', { count: campaign.cashCoupons.length })}
                  </div>
                  <div className={styles['desc']}>
                    <p>{t('campaign_details_targeting_date_info')}</p>
                    {isAllocationSuccess && campaign.targetingDate && (
                      <p>{t('campaign_details_targeting_date', { value: campaign.targetingDate })}</p>
                    )}
                  </div>

                  {campaign.cashCoupons.length > 0 &&
                    campaign.cashCoupons.map((item, index) => {
                      if (showTransactionalKpis && campaign.targetingKpis?.sampleATargetedClients && item.kpis) {
                        const clientNumber =
                          campaign.targetedSegments?.find((t) => {
                            const segmentId = t.externalSegmentId || t.segmentId;
                            return segmentId === item.externalSegment.id;
                          })?.clientNumber || item.externalSegment.customerCount;
                        const clientPercent =
                          clientNumber && Math.round((item.kpis.sampleATargetedClients / clientNumber) * 100);

                        const kpis = {
                          returnOnInvestment: t('commun_number', {
                            value: item.kpis.returnOnInvestment ?? '-',
                            maximumFractionDigits: withFractionDigits(item.kpis.returnOnInvestment)
                          }),
                          // display only if emittedCoupons exists
                          emittedCoupons:
                            typeof item.kpis.emittedCoupons !== 'number'
                              ? undefined
                              : t('commun_number', { value: item.kpis.emittedCoupons ?? '-' }),
                          usedCoupons: t('commun_number', { value: item.kpis.usedCoupons ?? '-' }),
                          distinctUsedClient: t('commun_number', { value: item.kpis.distinctUsedClient ?? '-' }),
                          sampleATurnover: t('commun_price', {
                            value: item.kpis.sampleATurnover ?? '-',
                            currency: user.currency.code
                          }),
                          incrementalTurnover: t('commun_price', {
                            value: item.kpis.incrementalTurnover ?? '-',
                            currency: user.currency.code
                          }),
                          budget: t('commun_price', {
                            value: item.kpis.budget ?? '-',
                            currency: user.currency.code
                          })
                        };
                        if (!item.kpis.abTested) {
                          delete kpis.returnOnInvestment;
                          delete kpis.incrementalTurnover;
                        }

                        return (
                          <div
                            className={`${styles['cashCoupon-results']} ${index % 2 === 0 ? styles['odd'] : ''}`}
                            key={index}
                            id={index}
                          >
                            {!hasBudgetSpent ? (
                              <Accordion
                                iconColor={hasBudgetSpent ? 'primary' : 'default'}
                                summary={displayCashcouponsOffersSummary({
                                  clientNumber,
                                  clientPercent,
                                  item,
                                  isInAccordion: true
                                })}
                              >
                                <ul className={`${styles['kpis-list']} ${index % 2 === 0 ? styles['odd'] : ''}`}>
                                  {Object.keys(kpis).map((key) => {
                                    if (!kpis[key]) return false;
                                    return (
                                      <li key={key}>
                                        <span>{t(`campaign_details_kpi_cashcoupon_segment_${key}`)}</span>
                                        <span>{kpis[key]}</span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Accordion>
                            ) : (
                              displayCashcouponsOffersSummary({ clientNumber, clientPercent, item })
                            )}
                          </div>
                        );
                      } else {
                        return (
                          <div
                            className={`${styles['cashCoupon-results']} ${index % 2 === 0 ? styles['odd'] : ''}`}
                            key={index}
                            id={index}
                          >
                            <div className={styles['main']}>
                              <div className={styles['left']}>
                                <p>
                                  {`${item.externalSegment.name} - ${t('commun_customer_count', {
                                    count:
                                      campaign.targetedSegments?.find((t) => {
                                        const segmentId = t.externalSegmentId || t.segmentId;
                                        return segmentId === item.externalSegment.id;
                                      })?.clientNumber ||
                                      item.externalSegment.customerCount ||
                                      0
                                  })}`}
                                </p>
                                <div className={styles['info']}>
                                  {item.cashCoupon.title} - {item.cashCoupon.id}
                                </div>
                                <div className={styles['info']}>
                                  {displayCashcouponGenerosityRecap(item.cashCoupon)}
                                </div>
                              </div>
                              <div className={styles['right']}>
                                {!!item?.kpis?.discountGranted &&
                                  t(`commun_price`, {
                                    value: item.kpis.discountGranted ?? '-',
                                    currency: user.currency.code
                                  })}
                                <SegmentUpdatingStatusIcon status={item.externalSegment.updatingStatus} />
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })}
                </section>
              )}
            </div>
            <div className={`${styles['frame']} ${styles['footer-buttons']}`}>
              <div className="d-flex">
                {campaign.retailer?.notifySuppliersEnabled &&
                campaign.targetingKpisOfferCampaign?.numberOfSupplierProductOffers > 0 ? (
                  <div
                    className={`${styles['notification']} ${
                      isNotificationsInactive ? styles['inactive-notification'] : ''
                    }`}
                    onClick={handleSendNotification}
                    role="button"
                  >
                    <EmailIcon />
                    <div className={styles['notification-link']}>
                      <span>{t('campaign_details_notification_send')}</span>
                      <span className={styles['notification-link-precision']}>
                        {isNotificationSending && t('campaign_details_notification_sending')}
                        {!isNotificationSending &&
                          campaign.notificationState === notificationStates.SENT &&
                          campaign.notificationSendingDate &&
                          t('campaign_details_notification_date', { value: campaign.notificationSendingDate })}
                        {!isNotificationSending &&
                          campaign.notificationState === notificationStates.ERROR &&
                          campaign.notificationSendingDate &&
                          t('campaign_details_notification_error', { value: campaign.notificationSendingDate })}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                {campaignTypes.PRODUCT === currentCampaignType && (
                  <div className={styles['offers-list-link']}>
                    <ButtonsCustom
                      classType="link_primary"
                      method={() => history.push(`/campaigns/${campaign.id}/offers`)}
                      text={t('campaign_details_list_offers')}
                      id={listOfferId}
                      startIconCustom={<FileIcon />}
                    />
                  </div>
                )}
              </div>
              <div className={styles['right-side']}>
                <ButtonsCustom
                  id={deleteId}
                  classType="icon_primary"
                  method={() => {
                    setIsDuplicateDialogOpen(true);
                  }}
                  disabled={isInitLoading || isInProcessToDelete || !hasDuplicableOffers}
                  startIconCustom={<DuplicateIcon />}
                  tooltip={t(
                    !isInProcessToDelete && hasDuplicableOffers
                      ? 'campaign_details_duplicate_button_tooltip'
                      : !hasDuplicableOffers
                      ? 'campaign_details_duplicate_no_offer_tooltip'
                      : 'campaign_details_duplicate_deletionStatus_inProcess_tooltip'
                  )}
                />

                <ButtonsCustom
                  id={deleteId}
                  classType="icon_primary"
                  method={() => {
                    history.push(
                      `/campaigns/add-campaign/${
                        campaign.campaignType === campaignTypes.CASH_COUPON
                          ? 'cash-coupons'
                          : campaign.personalized
                          ? 'products'
                          : 'products-non-personalized'
                      }/${campaign.id}`
                    );
                  }}
                  disabled={isInitLoading || !isEditable}
                  startIconCustom={<EditIcon />}
                  tooltip={
                    !isEditable
                      ? t(
                          campaign.personalized
                            ? 'campaign_details_update_tooltip'
                            : 'campaign_details_update_non_personalized_tooltip'
                        )
                      : ''
                  }
                />

                <ButtonsCustom
                  id={deleteId}
                  classType="icon_primary"
                  method={() => {
                    setIsDeleteDialogOpen(true);
                  }}
                  disabled={isInitLoading || !campaign.isDeletable?.deletable}
                  startIconCustom={<TrashIcon />}
                  tooltip={
                    !campaign.isDeletable?.deletable
                      ? t(`campaign_details_delete_tooltip_case_${campaign.isDeletable?.code}`)
                      : ''
                  }
                />
              </div>
            </div>
          </div>
          <aside>
            <AllocationPanel campaign={campaign} targetingEnabled={user.targetingEnabled} />
            <CampaignKeypointsPanel campaign={campaign} />
          </aside>
        </div>
      </section>
      <ContentDialog
        centerText
        isLoading={isDeleteLoading}
        isOpen={isDeleteDialogOpen}
        handleClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        title={t('campaign_details_delete_confirmation_message')}
        maxWidth="xs"
      >
        <ButtonsCustom
          classType="canceled"
          text={t('commun_button_cancel')}
          method={() => setIsDeleteDialogOpen(false)}
          disabled={isDeleteLoading}
        />
        <ButtonsCustom
          classType="action_primary_big"
          text={t('commun_button_delete_confirm')}
          method={handleDelete}
          loading={isDeleteLoading}
        />
      </ContentDialog>
      {hasDuplicableOffers && (
        <DuplicateCampaignDialog
          campaign={campaign}
          duplicableInitialDates={duplicableInitialDates}
          duplicableOfferDatesLimits={duplicableOfferDatesLimits}
          isOpen={isDuplicateDialogOpen}
          onClose={handleCloseDuplicateDialog}
        />
      )}
    </>
  );
};

export default CampaignsDetails;

import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { store } from 'store';
import { segmentScopes } from 'utils/constants';

import AppNavigation from 'components/AppNavigation';
import AddOffer from 'pages/AddOffer';
import AddCampaign from 'pages/AddCampaign';
import AddCashCoupon from 'pages/AddCashCoupon';
import AddRetailer from 'pages/AddRetailer';
import AddSegment from 'pages/AddSegment';
import AddSupplier from 'pages/AddSupplier';
import ApiDocs from 'pages/Apidocs';
import CampaignOffers from 'pages/CampaignOffers';
import Campaigns from 'pages/Campaigns';
import CampaignsDetails from 'pages/CampaignsDetails';
import CashCouponSteps from 'pages/AddCampaign/CashCouponSteps';
import DashboardRetailerTab from 'pages/DashboardRetailer/RetailerTab';
import DashboardSupplier from 'pages/DashboardSupplier';
import DashboardSupplierTab from 'pages/DashboardRetailer/SupplierTab';
import DashboardOfferTab from 'pages/DashboardRetailer/OfferTab';
import Legals from 'pages/Legals';
import OfferListCashCoupon from 'pages/OfferListCashCoupon';
import OfferListRetailer from 'pages/OfferListRetailer';
import OfferListSupplier from 'pages/OfferListSupplier';
import OfferRetailerImport from 'pages/OfferRetailerImport';
import OfferRetailerImportDetails from 'pages/OfferRetailerImportDetails';
import PilotageRetailer from 'pages/PilotageRetailer';
import ProductSteps from 'pages/AddCampaign/ProductSteps';
import Retailers from 'pages/Retailers';
import PrivateRoute from 'components/RouteCustom';
import Segmentation from 'pages/Segmentation';
import SegmentManager from 'pages/SegmentManager';
import Settings from 'pages/Settings';
import Suppliers from 'pages/Suppliers';
import UseCaseImportFiles from 'pages/UseCaseImportFiles';

const REDIRECTION_CONFIG = {
  retailer: '/dashboard',
  retailerService: '/api-docs',
  superAdmin: '/retailers',
  supplier: '/home'
};

const defaultRedirection = '/dashboard';

const Main = (props) => {
  const globalState = useContext(store);
  const {
    state: { user }
  } = globalState;

  return (
    <Switch>
      <Route exact path="/legal-mentions" component={Legals} />
      <Route exact path="/privacy-policy" component={Legals} />

      <PrivateRoute exact path="/">
        <>
          <AppNavigation />
          <Redirect to={REDIRECTION_CONFIG[user.userType] || defaultRedirection} />
        </>
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/retailers"
        component={Retailers}
        typeAllowed={['superAdmin']}
        roleAllowed={['SUPER_ADMIN']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/retailers/add-retailer/:id?"
        component={AddRetailer}
        typeAllowed={['superAdmin']}
        roleAllowed={['SUPER_ADMIN']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/retailers/:id?"
        component={Retailers}
        typeAllowed={['superAdmin']}
        roleAllowed={['SUPER_ADMIN']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/api-docs"
        component={ApiDocs}
        typeAllowed={['superAdmin', 'retailerService']}
        roleAllowed={['SUPER_ADMIN', 'RETAILER_SERVICE']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/use-case-import-files"
        component={UseCaseImportFiles}
        typeAllowed={['superAdmin']}
        roleAllowed={['SUPER_ADMIN']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/suppliers/add-supplier"
        component={AddSupplier}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />

      <PrivateRoute
        exact
        path="/suppliers/:id?"
        component={Suppliers}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/home"
        component={DashboardSupplier}
        typeAllowed={['supplier']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />

      <PrivateRoute exact path="/dashboard" typeAllowed={['retailer']} roleAllowed={['ADMIN', 'CREW']}>
        <>
          <AppNavigation />
          <Redirect to={`/dashboard/retailer${localStorage.getItem('dashboardRetailer_retailerTabFilter') || ''}`} />
        </>
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard/retailer"
        component={DashboardRetailerTab}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/dashboard/supplier"
        component={DashboardSupplierTab}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/dashboard/offer"
        component={DashboardOfferTab}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute exact path={'/offers'} typeAllowed={['retailer', 'supplier']} roleAllowed={['ADMIN', 'CREW']}>
        <>
          <AppNavigation />
          <Redirect
            to={
              '/offers/offersProductsSupplier' +
              (localStorage.getItem('offersProductsSupplierFilter') ||
                `?validityStartDate=greaterThan,${new Date().getFullYear() - 1}-12-31`) +
              (user.userManagerId ? `&userManagerId=${user.userManagerId}` : '')
            }
          />
        </>
      </PrivateRoute>
      <PrivateRoute
        exact
        path={'/offers/offersProductsSupplier/:id?'}
        component={OfferListSupplier}
        typeAllowed={['retailer', 'supplier']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/offers/offersProductsRetailer/:id?"
        component={OfferListRetailer}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/offers/offersProductsRetailerImport"
        component={OfferRetailerImport}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/offers/offersProductsRetailerImport/:id"
        component={OfferRetailerImportDetails}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/offers/offersRetailerCashCoupon/:id?"
        component={OfferListCashCoupon}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path={'/offers/add-cash-coupon/:id?'}
        component={AddCashCoupon}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/offers/add-offer/:offerId?"
        component={AddOffer}
        typeAllowed={['retailer', 'supplier']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/campaigns"
        component={Campaigns}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/campaigns/add-campaign"
        component={AddCampaign}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/campaigns/add-campaign/cash-coupons/:id?"
        component={CashCouponSteps}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/campaigns/add-campaign/products/:id?"
        component={ProductSteps}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/campaigns/add-campaign/products-non-personalized/:id?"
        render={() => <ProductSteps personalized={false} />}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/campaigns/:id"
        component={CampaignsDetails}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/campaigns/:id/offers/:offerId?"
        component={CampaignOffers}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/segmentation/add-segment"
        component={AddSegment}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/segmentation/segment-manager-campaign/:id?"
        render={() => <SegmentManager scope={segmentScopes.CAMPAIGN} />}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/segmentation/segment-manager-offer/:id?"
        render={() => <SegmentManager scope={segmentScopes.OFFER} />}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/segmentation/:id?"
        component={Segmentation}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />
      <PrivateRoute exact path="/settings" typeAllowed={['retailer']} roleAllowed={['ADMIN']}>
        <>
          <AppNavigation />
          <Redirect to="/settings/offersProductsSupplier" />
        </>
      </PrivateRoute>
      <PrivateRoute
        exact
        path="/settings/:type"
        component={Settings}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN']}
        {...props}
      />
      <PrivateRoute
        exact
        path="/monitoring"
        component={PilotageRetailer}
        typeAllowed={['retailer']}
        roleAllowed={['ADMIN', 'CREW']}
        {...props}
      />

      <Redirect to="/" />
    </Switch>
  );
};

export default Main;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { campaignTypes } from 'utils/constants';
import { withFractionDigits } from 'utils/global';
import FramedIcon from 'components/FramedIcon';
import Tooltip from 'components/Tooltip';

import { ReactComponent as LightningIcon } from 'assets/24px_éclair.svg';
import { ReactComponent as MoneyIcon } from 'assets/24px_monnaie.svg';
import { ReactComponent as ReturnRateIcon } from 'assets/24px_return.svg';
import { ReactComponent as TurnoverIcon } from 'assets/24px_generosité.svg';

import './CampaignKpiResults.scss';

const CampaignKpiResults = ({ campaignType, currency, transactionalKpis }) => {
  const { t } = useTranslation();

  let kpiResults = null;
  if (campaignType === campaignTypes.CASH_COUPON) {
    kpiResults = [
      {
        color: 'yellow',
        icon: <TurnoverIcon />,
        label: t('_dyn_campaign_details_keypoints_results_turnover_total'),
        tooltip: t('_dyn_campaign_details_keypoints_results_turnover_total_tooltip'),
        value: t('commun_price', {
          value: transactionalKpis.sampleATurnover ?? '-',
          currency
        })
      },
      {
        color: 'green',
        icon: <MoneyIcon />,
        label: t('_dyn_campaign_details_keypoints_results_incremental_turnover'),
        tooltip: t('_dyn_campaign_details_keypoints_results_incremental_turnover_tooltip'),
        value: t('commun_price', {
          value: transactionalKpis.incrementalTurnover ?? '-',
          currency
        })
      },
      {
        color: 'purple',
        icon: <LightningIcon />,
        label: t('_dyn_campaign_details_keypoints_results_total_budget_spent'),
        tooltip: t('_dyn_campaign_details_keypoints_results_cashcoupon_total_budget_spent_tooltip'),
        value: t('commun_price', {
          value: transactionalKpis.budget ?? '-',
          currency
        })
      }
    ];
  }
  if (campaignType === campaignTypes.PRODUCT) {
    kpiResults = [
      {
        color: 'yellow',
        icon: <MoneyIcon />,
        label: t('_dyn_campaign_details_keypoints_results_product_turnover_after_discount'),
        tooltip: t('_dyn_campaign_details_keypoints_results_product_turnover_after_discount_tooltip'),
        value: t('commun_price', {
          value: transactionalKpis.sampleATurnover ?? '-',
          currency
        })
      },
      {
        color: 'purple',
        icon: <LightningIcon />,
        label: t('_dyn_campaign_details_keypoints_results_total_budget_spent'),
        tooltip: t('_dyn_campaign_details_keypoints_results_product_total_budget_spent_tooltip'),
        value: t('commun_price', {
          value: transactionalKpis.discountGranted ?? '-',
          currency
        })
      },
      {
        color: 'blue',
        icon: <ReturnRateIcon />,
        label: t('_dyn_campaign_details_keypoints_results_product_user_rate'),
        tooltip: t('_dyn_campaign_details_keypoints_results_product_user_rate_tooltip'),
        value: `${t('commun_number', {
          value: transactionalKpis.usedRate !== null ? transactionalKpis.usedRate * 100 : '-',
          maximumFractionDigits: withFractionDigits(transactionalKpis.usedRate * 100)
        })} %`
      }
    ];
  }

  return (
    <div>
      <div className="kpi-section">
        {kpiResults.map((k) => (
          <Tooltip placement="bottom" title={k.tooltip} key={k.label}>
            <div className={`kpi-container ${k.color}`}>
              <FramedIcon color={k.color} icon={k.icon} size="big" />
              <div className="data-container">
                <p>{k.value}</p>
                <p>{k.label}</p>
              </div>
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

CampaignKpiResults.propTypes = {
  campaignType: PropTypes.oneOf(Object.values(campaignTypes)),
  currency: PropTypes.string,
  transactionalKpis: PropTypes.object
};

export default memo(CampaignKpiResults);

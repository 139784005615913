import React from 'react';
import { Oval as Loader } from 'react-loader-spinner';
import { ReactComponent as LogoRelevanc } from 'assets/logo_relevanc.svg';
import styles from './Logos.module.scss';

const Logo = () => <LogoRelevanc />;

export const BrandLoader = () => (
  <div className={styles['loader-container']}>
    <div className={styles.logo}>
      <LogoRelevanc />
    </div>
    <div className={styles.loader}>
      <Loader color="#fe4b4b" secondaryColor="#ffdbdb" height={200} width={200} />
    </div>
  </div>
);

export default Logo;

import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useDynId from 'customHooks/useDynId';
import { useHistory } from 'react-router-dom';
import { postCashCouponCampaign, updateCashCouponCampaign } from 'api';
import ButtonsCustom from 'components/ButtonsCustom';
import ConfirmationStep from './ConfirmationStep';
import ContentDialog from 'components/ContentDialog';
import FormManager from 'components/FormManager';

const CashCouponStep3 = ({ campaignCashCoupon, cashCouponsListAll, handlePreviousStep, segmentsListAll, user }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [newBrCampaignId, setNewBrCampaignId] = useState(null);
  const [tooltipErrorMsg, setTooltipErrorMsg] = useState(null);

  const isEditMode = !!campaignCashCoupon.id;

  const saveCampaign = async () => {
    setIsLoading(true);
    const newCampaignResponse = isEditMode
      ? await updateCashCouponCampaign({ campaign: campaignCashCoupon, retailerId: user.id })
      : await postCashCouponCampaign({ campaign: campaignCashCoupon, retailerId: user.id });
    setIsLoading(false);

    if (newCampaignResponse.id) {
      if (isEditMode) {
        history.push(`/campaigns/${campaignCashCoupon.id}`);
      } else {
        setDialogIsOpen(true);
        setNewBrCampaignId(newCampaignResponse.id);
      }
    } else {
      setTooltipErrorMsg(newCampaignResponse.msg);
    }
  };

  const handleDialogClose = () => {
    history.push(`/campaigns/${newBrCampaignId}`);
  };

  return (
    <>
      <FormManager
        onSubmit={saveCampaign}
        data={{
          fieldsets: [
            {
              id: 'add-campaign-cash-coupon-step-03',
              fields: [
                {
                  id: 'campaign-creation-step-03-confirmation',
                  outerContainerClass: ['full-width'],
                  type: 'CustomContent',
                  component: (
                    <ConfirmationStep
                      campaignCashCoupon={campaignCashCoupon}
                      cashCouponsListAll={cashCouponsListAll}
                      segmentsListAll={segmentsListAll}
                    />
                  )
                }
              ]
            }
          ],
          cancel: {
            label: t('commun_button_previous_step'),
            disabled: isLoading || !!tooltipErrorMsg,
            onClick: handlePreviousStep,
            id: useDynId('step3Cancel')
          },
          submit: {
            label: t('commun_button_save_campaign'),
            disabled: !!tooltipErrorMsg,
            id: useDynId('step3Save'),
            loading: isLoading,
            tooltip: tooltipErrorMsg ? t(tooltipErrorMsg) : ''
          }
        }}
      />
      <ContentDialog
        centerText
        isOpen={dialogIsOpen}
        handleClose={handleDialogClose}
        title={t('campaign_modal_validation_title')}
        desc={t('campaign_modal_validation_desc')}
        maxWidth="sm"
      >
        <div className="confirm-dialog">
          <ButtonsCustom
            id={useDynId('step3ConfirmModal')}
            classType="action_primary_big"
            text={t('commun_button_got_it')}
            method={handleDialogClose}
          />
        </div>
      </ContentDialog>
    </>
  );
};

CashCouponStep3.propTypes = {
  campaignCashCoupon: PropTypes.object.isRequired,
  cashCouponsListAll: PropTypes.array.isRequired,
  handlePreviousStep: PropTypes.func.isRequired,
  segmentsListAll: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};
export default memo(CashCouponStep3);

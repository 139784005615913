import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonsCustom from 'components/ButtonsCustom';

import { ReactComponent as AddIcon } from 'assets/30px_plus.svg';
import styles from './AddMultiPurchasesInnerConditions.module.scss';

// list of inner conditions -> in reference to ConditionsForm list
type InnerConditionType =
  | 'currency' // -> value
  | 'last_visit' // -> recency
  | 'quantity' // -> purchases
  | 'visit' // -> frequency
  | 'visit_each_day' // new recurrence
  | 'visit_each_week' // new recurrence
  | 'visit_each_month' // new recurrence
  | 'visit_each_year'; // new recurrence

type Props = {
  list: InnerConditionType[];
  addNewInnerCondition: (list: InnerConditionType) => void;
};

const displayList: InnerConditionType[] = ['visit_each_day', 'quantity', 'visit', 'currency', 'last_visit'];
const notYetAvailable: InnerConditionType[] = ['visit_each_day'];

const AddMultiPurchasesInnerConditions = ({ list = [], addNewInnerCondition }: Props): JSX.Element => {
  const { t } = useTranslation();
  const formattedList: InnerConditionType[] = list.map((conditionName) => {
    if (conditionName.includes('visit_each')) {
      return 'visit_each_day';
    } else return conditionName;
  });

  const handleClick = (conditionName: InnerConditionType) => {
    addNewInnerCondition(conditionName);
  };

  const getDisabledTooltip = (conditionName: InnerConditionType): string | undefined => {
    if (notYetAvailable.includes(conditionName)) {
      return t('commun_coming_soon');
    }
    if (formattedList.includes('last_visit')) {
      if (conditionName === 'last_visit') {
        return t('segmentManager_vector_multipurchases_add_inner_type_already_added_disabled_tooltip');
      } else return t('segmentManager_vector_multipurchases_add_inner_type_last_visit_already_added_disabled_tooltip');
    } else if (conditionName === 'last_visit' && !!formattedList.length) {
      return t('segmentManager_vector_multipurchases_add_inner_type_last_visit_disabled_tooltip');
    }
    return t('segmentManager_vector_multipurchases_add_inner_type_already_added_disabled_tooltip');
  };

  return (
    <div className={styles['root']}>
      {displayList.map((conditionName) => {
        /* rules for isDisabled */
        // is disabled when last_visit is included in formattedList
        // last_visit is disabled when any other innerCondition is included in formattedList
        // is disabled when already included in formattedList because can not be added twice
        const isDisabled =
          notYetAvailable.includes(conditionName) ||
          formattedList.includes('last_visit') ||
          (conditionName === 'last_visit' && !!formattedList.length) ||
          formattedList.includes(conditionName);

        return (
          <ButtonsCustom
            key={conditionName}
            classType={'action_reverse_lighter'}
            disabled={isDisabled}
            method={() => {
              handleClick(conditionName);
            }}
            text={t(`segmentManager_vector_multipurchases_inner_type_${conditionName}_label`)}
            startIconCustom={<AddIcon />}
            tooltip={isDisabled ? getDisabledTooltip(conditionName) : undefined}
          />
        );
      })}
    </div>
  );
};

export default memo(AddMultiPurchasesInnerConditions);

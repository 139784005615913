import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import SelectCustom from 'components/SelectCustom';

const Select = (props) => {
  const {
    className,
    data: { id, defaultValue, disabled, placeholder, fieldProps },
    onChange,
    error,
  } = props;

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) onChange(id, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = (ev) => {
    const {
      target: { value },
    } = ev;
    setValue(value);
  };

  const foundValue = fieldProps.listData.find((el) => el.id === value);

  return (
    <SelectCustom
      className={className}
      id={id}
      placeHolderText={placeholder}
      methodOnChange={handleChange}
      value={foundValue ? value : ''}
      error={!!error}
      errorMsg={error}
      disabled={disabled}
      {...fieldProps}
    />
  );
};

Select.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
};

export default Select;

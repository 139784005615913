import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getUntargetableProductCampaignOffers, deleteProductCampaignOffer } from 'api';
import TableCustom from 'components/TableCustom';

import { ReactComponent as CrossIcon } from 'assets/22px_croix.svg';
import styles from './OfferList.module.scss';

const OfferList = ({ campaignProduct, isAddingOrDeletingOffers }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);

  const tableSize = 30;
  const columns = [
    { id: 1, headerName: '', field: 'id' },
    { id: 2, headerName: '', field: 'title', hasTooltip: true },
    { id: 3, headerName: '', field: 'delete', type: 'component', align: 'right' },
  ];
  const offersParsed = campaignProduct.untargetableProductCampaignOffers.list.map((i) => ({
    id: [{ value: i.offerHead.id, color: 'ean', textStyle: 'tag' }],
    title: [
      { value: i.offerHead.title, color: 'default' },
      { value: i.offerHead.descriptionTag, color: 'proposal' },
    ],
    delete: (
      <div className={styles['cross-icon']} onClick={() => handleDeleteOffer(i.offerCampaignId)} role="button">
        <CrossIcon />
      </div>
    ),
  }));

  if (!offersParsed.length && !isAddingOrDeletingOffers && !isLoading) return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getUntargetableProductCampaignOffers({
        campaignProduct,
        page,
        size: tableSize,
      });
      setIsLoading(false);
    };
    if (!isAddingOrDeletingOffers && !isLoading) fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleDeleteOffer = async (offerCampaignId) => {
    await deleteProductCampaignOffer(offerCampaignId);
    // was last item in list ?
    const mustChangePage = campaignProduct.untargetableProductCampaignOffers.list.length === 1;
    if (page !== 0 && mustChangePage) {
      setPage((state) => state - 1);
    } else {
      await getUntargetableProductCampaignOffers({
        campaignProduct,
        page,
        size: tableSize,
      });
    }
  };

  const handleNextPage = async () => {
    setPage((state) => state + 1);
  };

  const handlePreviousPage = async () => {
    setPage((state) => state - 1);
  };

  return (
    <div className={styles['offer-list']}>
      <TableCustom
        columns={columns}
        rows={offersParsed}
        total={campaignProduct.untargetableProductCampaignOffers.total || 0}
        page={page}
        size={tableSize}
        prev={handlePreviousPage}
        next={handleNextPage}
        showHeader={false}
        isLoading={isAddingOrDeletingOffers}
        type="medium"
      />
    </div>
  );
};

OfferList.propTypes = {
  campaignProduct: PropTypes.object.isRequired,
  isAddingOrDeletingOffers: PropTypes.bool.isRequired,
};

export default memo(OfferList);

import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { Trans, useTranslation } from 'react-i18next';
import {
  getConditionTitle,
  getSettingProductLevelXLabel,
  hasAtLeastOneProductTypeConditionFilled
} from 'components/SegmentManager/utils';

import DotsLoader from 'components/Loaders/Dots';
import Operator from '../Operator';

import styles from './Review.module.scss';

const Review = ({ hasAccessToEstimateCustomerCount, isLoadingEstimate, isVectorFilled, segment }) => {
  const { t } = useTranslation();
  const {
    state: {
      user,
      segments: { estimateCustomerCount }
    }
  } = useContext(store);

  const displayConditionInfo = (segment) => {
    switch (segment.type) {
      case 'Frequency': {
        const value =
          segment.condition.value !== null
            ? typeof segment.condition.value === 'number'
              ? t('commun_number', { value: segment.condition.value })
              : segment.condition.value
                  .map((v) => t('commun_number', { value: v }))
                  .sort()
                  .join(` ${t('_dyn_commun_select_operator_$or').toLowerCase()} `)
            : ' ... ';

        return (
          <>
            <div className={styles['info']}>
              {t('segmentManager_sidePanel_review_info_frequency', {
                text1: segment.condition.sign ? t(`_dyn_commun_select_sign_text_${segment.condition.sign}`) : ' ... ',
                text2: value
              })}
            </div>
            <div className={styles['info']}>
              {segment.period.value.interval && typeof segment.period.value.count === 'number'
                ? t(`segmentManager_sidePanel_review_period_${segment.period.value.interval}`, {
                    count: segment.period.value.count
                  })
                : ' ... '}
            </div>
          </>
        );
      }

      case 'MultiPurchases': {
        const productLevel = segment.level;
        const isLevelStore = productLevel === 'store';

        const listText = () => {
          switch (productLevel) {
            case 'level0':
            case 'level1':
            case 'level2':
            case 'level3':
              return t('_dyn_product_levelX_selected_count', {
                count: segment.on.length,
                text: getSettingProductLevelXLabel({ productLevel, user })
              });
            default:
              return t(`commun_selected_${productLevel}_count`, { count: segment.on.length }).toLowerCase();
          }
        };
        const innerConditionLines = segment.conditions.map((innerCondition) => {
          // like Value
          if (innerCondition.unit === 'currency') {
            const value =
              innerCondition.value !== null
                ? typeof innerCondition.value === 'number'
                  ? t('commun_price', { value: innerCondition.value, currency: user.currency.code })
                  : innerCondition.value
                      .map((v) => t('commun_price', { value: v, currency: user.currency.code }))
                      .sort()
                      .join(` ${t('_dyn_commun_select_operator_$or').toLowerCase()} `)
                : ' ... ';
            return t('segmentManager_sidePanel_review_info_multipurchases_innerCondition_currency', {
              sign: innerCondition.sign
                ? `${t(`_dyn_commun_select_sign_text_${innerCondition.sign}`).toLowerCase()} `
                : ' ... ',
              value
            });
          }
          // like recency
          else if (innerCondition.unit === 'last_visit') {
            const text =
              segment.period.type === 'over'
                ? segment.period.value.interval &&
                  segment.period.value.sign &&
                  typeof segment.period.value.count === 'number'
                  ? t(
                      `segmentManager_sidePanel_review_info_recency_over_${segment.period.value.sign}_${segment.period.value.interval}`,
                      {
                        count: segment.period.value.count
                      }
                    )
                  : ' ... '
                : segment.period.value.start_date && segment.period.value.end_date
                ? t('commun_between_from_to_dates', {
                    valueFrom: segment.period.value.start_date,
                    valueTo: segment.period.value.end_date
                  })
                : ' ... ';
            return text;
          }
          // like purchases
          else if (innerCondition.unit === 'quantity') {
            const value =
              innerCondition.value !== null
                ? typeof innerCondition.value === 'number'
                  ? t('commun_number', { value: innerCondition.value })
                  : innerCondition.value
                      .map((v) => t('commun_number', { value: v }))
                      .sort()
                      .join(` ${t('_dyn_commun_select_operator_$or').toLowerCase()} `)
                : ' ... ';

            const text = t('segmentManager_sidePanel_review_info_multipurchases_quantity_product', {
              count:
                typeof innerCondition.value === 'number'
                  ? innerCondition.value
                  : innerCondition.value?.length === 1
                  ? innerCondition.value[0]
                  : innerCondition.value?.length,
              sign: innerCondition.sign
                ? `${t(`_dyn_commun_select_sign_text_bis_${innerCondition.sign}`).toLowerCase()} `
                : ' ... ',
              value
            });
            return text;
          }
          // like frequency
          else if (innerCondition.unit === 'visit') {
            const value =
              innerCondition.value !== null
                ? typeof innerCondition.value === 'number'
                  ? t('commun_number', { value: innerCondition.value })
                  : innerCondition.value
                      .map((v) => t('commun_number', { value: v }))
                      .sort()
                      .join(` ${t('_dyn_commun_select_operator_$or').toLowerCase()} `)
                : ' ... ';
            return t('segmentManager_sidePanel_review_info_frequency', {
              text1: innerCondition.sign ? t(`_dyn_commun_select_sign_text_${innerCondition.sign}`) : ' ... ',
              text2: value
            });
          }
          // new rhythme / pace
          else if (
            innerCondition.unit === 'visit_each_day' ||
            innerCondition.unit === 'visit_each_week' ||
            innerCondition.unit === 'visit_each_month' ||
            innerCondition.unit === 'visit_each_year'
          ) {
            const value =
              innerCondition.value !== null
                ? typeof innerCondition.value === 'number'
                  ? t('commun_number', { value: innerCondition.value })
                  : innerCondition.value
                      .map((v) => t('commun_number', { value: v }))
                      .sort()
                      .join(` ${t('_dyn_commun_select_operator_$or').toLowerCase()} `)
                : ' ... ';

            return t('segmentManager_sidePanel_review_info_multipurchases_innerCondition_visit_each', {
              sign: innerCondition.sign
                ? t(`_dyn_commun_select_sign_text_${innerCondition.sign}`).toLowerCase()
                : ' ... ',
              text: t(`_dyn_commun_select_pace_${innerCondition.unit}`).toLowerCase(),
              value
            });
          }
          // else
          else return ' ... ';
        });

        return (
          <>
            <div className={styles['info']}>
              {isLevelStore
                ? t('segmentManager_sidePanel_review_info_multipurchases_level_store', {
                    count: segment.on.length,
                    list: segment.on.length ? (segment.on.length > 1 ? segment.on.length : segment.on[0]) : ' ... '
                  })
                : t('segmentManager_sidePanel_review_info_multipurchases', {
                    list: segment.on.length ? listText() : ' ... ',
                    count: segment.on.length
                  })}
            </div>
            {innerConditionLines.map((text, index) => (
              <div className={styles['info']} key={index.toString()}>
                {text}
              </div>
            ))}
            {segment.conditions[0]?.unit !== 'last_visit' && (
              <div className={styles['info']}>
                {segment.period.value.interval && typeof segment.period.value.count === 'number'
                  ? t(`segmentManager_sidePanel_review_period_${segment.period.value.interval}`, {
                      count: segment.period.value.count
                    })
                  : ' ... '}
              </div>
            )}
          </>
        );
      }

      case 'Purchases': {
        const productLevel = segment.condition.level;
        const isLevelStore = productLevel === 'store';
        const listText = () => {
          switch (productLevel) {
            case 'level0':
            case 'level1':
            case 'level2':
            case 'level3':
              return t('_dyn_product_levelX_selected_count', {
                count: segment.condition.on.length,
                text: getSettingProductLevelXLabel({ productLevel, user })
              });
            default:
              return t(`commun_selected_${productLevel}_count`, { count: segment.condition.on.length }).toLowerCase();
          }
        };
        const value =
          segment.condition.value !== null
            ? typeof segment.condition.value === 'number'
              ? isLevelStore
                ? t('commun_product_count', { count: segment.condition.value })
                : t('commun_number', { value: segment.condition.value })
              : segment.condition.value
                  .map((v) => t('commun_number', { value: v }))
                  .sort()
                  .join(` ${t('_dyn_commun_select_operator_$or').toLowerCase()} `)
            : ' ... ';

        return (
          <>
            <div className={styles['info']}>
              {isLevelStore
                ? t('segmentManager_sidePanel_review_info_purchases_level_store', {
                    count: segment.condition.on.length,
                    list: segment.condition.on.length
                      ? segment.condition.on.length > 1
                        ? segment.condition.on.length
                        : segment.condition.on[0]
                      : ' ... ',
                    sign: segment.condition.sign
                      ? `${t(`_dyn_commun_select_sign_text_bis_${segment.condition.sign}`).toLowerCase()} `
                      : ' ... ',
                    value
                  })
                : t('segmentManager_sidePanel_review_info_purchases', {
                    list: segment.condition.on.length ? listText() : ' ... ',
                    sign: segment.condition.sign
                      ? `${t(`_dyn_commun_select_sign_text_bis_${segment.condition.sign}`).toLowerCase()} `
                      : ' ... ',
                    value
                  })}
            </div>
            <div className={styles['info']}>
              {segment.period.value.interval && typeof segment.period.value.count === 'number'
                ? t(`segmentManager_sidePanel_review_period_${segment.period.value.interval}`, {
                    count: segment.period.value.count
                  })
                : ' ... '}
            </div>
          </>
        );
      }

      case 'Recency': {
        return (
          <div className={styles['info']}>
            {segment.period.type === 'over'
              ? segment.period.value.interval &&
                segment.period.value.sign &&
                typeof segment.period.value.count === 'number'
                ? t(
                    `segmentManager_sidePanel_review_info_recency_over_${segment.period.value.sign}_${segment.period.value.interval}`,
                    {
                      count: segment.period.value.count
                    }
                  )
                : ' ... '
              : segment.period.value.start_date && segment.period.value.end_date
              ? t('commun_between_from_to_dates', {
                  valueFrom: segment.period.value.start_date,
                  valueTo: segment.period.value.end_date
                })
              : ' ... '}
          </div>
        );
      }

      case 'Value': {
        const value =
          segment.condition.value !== null
            ? typeof segment.condition.value === 'number'
              ? t('commun_price', { value: segment.condition.value, currency: user.currency.code })
              : segment.condition.value
                  .map((v) => t('commun_price', { value: v, currency: user.currency.code }))
                  .sort()
                  .join(` ${t('_dyn_commun_select_operator_$or').toLowerCase()} `)
            : ' ... ';

        return (
          <>
            <div className={styles['info']}>
              {t('segmentManager_sidePanel_review_info_value', {
                text1: segment.condition.sign ? t(`_dyn_commun_select_sign_text_${segment.condition.sign}`) : ' ... ',
                text2: value
              })}
            </div>
            <div className={styles['info']}>
              {segment.period.value.interval && typeof segment.period.value.count === 'number'
                ? t(`segmentManager_sidePanel_review_period_${segment.period.value.interval}`, {
                    count: segment.period.value.count
                  })
                : ' ... '}
            </div>
          </>
        );
      }

      default:
        return false;
    }
  };

  const groupFactory = (list, idx) => {
    let classnames;
    if (idx === 0 && list.segments?.length > 1) {
      classnames = ['firstLevelSubGroup'];
    } else if (idx === 1) {
      classnames = ['block', 'secondLevelSubGroup'];
    } else if (idx === 2) {
      classnames = ['block', 'thirdLevelSubGroup'];
    } else {
      classnames = '';
    }

    return (
      <div className={`${classnames ? classnames.map((c) => styles[c]).join(' ') : ''}`}>
        {list.segments?.map((el, index) => {
          return (
            <div className={styles['relative']} key={`${idx}-${index}`}>
              {index !== 0 && (
                <span className={styles['button-operator']}>
                  <Operator text={t(`_dyn_commun_select_operator_${list.operator}`)} type="secondary" />
                </span>
              )}
              {!el ? (
                <div className={`${styles['block']} ${styles['flex-content']}`} key={`${idx}-${index}`}>
                  ...
                </div>
              ) : el.type ? (
                <div className={`${styles['block']} ${styles['flex-content']}`} key={`${idx}-${index}`}>
                  <div>{getConditionTitle({ segment: el, t, user })}</div>
                  {displayConditionInfo(el)}
                </div>
              ) : idx < 3 ? (
                <Fragment key={`${idx}-${index}`}>{groupFactory(el, idx + 1)}</Fragment>
              ) : (
                false
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const displayEstimate = () => {
    if (isLoadingEstimate) return <DotsLoader />;
    if (estimateCustomerCount === null || !isVectorFilled) return t('commun_loading_users');
    return (
      <Trans i18nKey={'segmentManager_sidePanel_review_estimation_result'} values={{ count: estimateCustomerCount }} />
    );
  };

  // hard segment
  if (!segment.vector) {
    return (
      <div className={styles['reviewPanel']}>
        <div
          className={`${styles['block']} ${styles['flex-content']} ${styles['segment-name']} ${styles['hardSegment-placeholder']}`}
        >
          ...
        </div>
      </div>
    );
  }

  // display placeholders
  if (!segment.vector.segments?.length) {
    return (
      <div className={styles['reviewPanel']}>
        <div className={`${styles['block-placeholder']} ${styles['estimation-placeholder']}`} />
        {segment.name ? (
          <div className={`${styles['block']} ${styles['flex-content']} ${styles['segment-name']}`}>{segment.name}</div>
        ) : (
          <div className={`${styles['block-placeholder']} ${styles['segment-name-placeholder']}`} />
        )}
        <div className={styles['firstLevelSubGroup-placeholder']}>
          <div className={styles['block-placeholder']} />
          <div className={`${styles['block-placeholder']} ${styles['big-placeholder']}`}>
            <div className={styles['button-operator-placeholder']} />
          </div>
          <div className={styles['block-placeholder']}>
            <div className={styles['button-operator-placeholder']} />
          </div>
        </div>
      </div>
    );
  }

  const isUnavailable = hasAtLeastOneProductTypeConditionFilled(segment.vector);
  return (
    <>
      {hasAccessToEstimateCustomerCount && (
        <div className={styles['estimation']}>
          {!isVectorFilled && !isUnavailable && (
            <div className={styles['notFilled']}>{t('segmentManager_sidePanel_review_estimation_not_filled')}</div>
          )}
          <div>
            {t('segmentManager_sidePanel_review_estimation')}
            <span className={styles['estimation-value']}>
              {isUnavailable ? <i>{t('commun_unavailable').toLowerCase()}</i> : displayEstimate()}
            </span>
          </div>
          {isUnavailable && (
            <div className={styles['estimation-unavailable-desc']}>
              {t('segmentManager_sidePanel_review_estimation_unavailable')}
            </div>
          )}
        </div>
      )}
      <div className={styles['reviewPanel']}>
        <div className={`${styles['block']} ${styles['flex-content']} ${styles['segment-name']}`}>
          {segment.name || '...'}
        </div>
        {groupFactory(segment.vector, 0)}
      </div>
    </>
  );
};

Review.propTypes = {
  hasAccessToEstimateCustomerCount: PropTypes.bool,
  isLoadingEstimate: PropTypes.bool,
  isVectorFilled: PropTypes.bool,
  segment: PropTypes.object.isRequired
};

export default Review;

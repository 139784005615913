import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from 'assets/24px_alert_information.svg';

import './OfferDetailsAlert.scss';

const OfferDetailsAlert = ({ className = '', title, description, withInfoIcon = true }) => {
  const { t } = useTranslation();

  return (
    <div className={`${className} offer-details-alert`}>
      {withInfoIcon && (
        <div className="offer-details-alert-icon">
          <InfoIcon />
        </div>
      )}
      <div className="offer-details-alert-content">
        <div className="offer-details-alert-title">{t(title)}</div>
        {description ? <div className="offer-details-alert-desc">{t(description)}</div> : ''}
      </div>
    </div>
  );
};

OfferDetailsAlert.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  withInfoIcon: PropTypes.bool,
};

export default OfferDetailsAlert;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ButtonsCustom from 'components/ButtonsCustom';
import Logo from 'components/Logos';
import LegalMentionsFR from './LegalMentions-fr';
import PrivacyPolicyFR from './PrivacyPolicy-fr';
import ScrollToTop from 'components/ScrollToTop';

import { ReactComponent as BackIcon } from 'assets/14px_arrow_left.svg';
import styles from './Legals.module.scss';

const legalPageNameList = ['/legal-mentions', '/privacy-policy'];

const Legals = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const pageName = legalPageNameList.find((el) => el === window.location.pathname);
  const files = {
    [legalPageNameList[0]]: <LegalMentionsFR />,
    [legalPageNameList[1]]: <PrivacyPolicyFR />,
  };

  return (
    <div className={styles['root']}>
      <div className={styles['menu']}>
        <Logo />
        <ButtonsCustom
          classType="action_tertiary"
          id="go-back"
          method={() => {
            history.goBack();
          }}
          startIconCustom={<BackIcon />}
          text={t('commun_back')}
        />
      </div>
      <div className={styles['content']}>{files[pageName]}</div>
      <ScrollToTop />
    </div>
  );
};

export default Legals;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { store } from 'store';

import AppNavigation from 'components/AppNavigation';
import { BrandLoader } from 'components/Logos';

const RouteCustom = ({ children: LoginForm, roleAllowed = [], tokenIsSet, typeAllowed = [], ...props }) => {
  const globalState = useContext(store);
  const { state } = globalState;

  // display LoginForm page from children props
  if (!tokenIsSet) {
    return LoginForm;
  }

  // display loader
  if (state.initIsLoading) {
    return <BrandLoader />;
  }

  // redirect if user is not allowed to access this page
  if (
    state?.user?.roles?.[0] &&
    (!typeAllowed.includes(state.user.userType) || !roleAllowed.some((role) => state.user.roles.includes(role)))
  ) {
    return <Redirect to={{ pathname: '/' }} />;
  }

  return (
    <>
      <AppNavigation />
      <Route {...props} />
    </>
  );
};

RouteCustom.propTypes = {
  children: PropTypes.node,
  roleAllowed: PropTypes.array,
  tokenIsSet: PropTypes.bool,
  typeAllowed: PropTypes.array,
};

export default RouteCustom;

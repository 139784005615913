import React, { memo, useState, useRef, useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  campaignStates,
  campaignTargetingStatuses,
  campaignTypes,
  discountUnits,
  filterSigns,
  offerHeadTypes,
  offerStatuses,
  offerTypes,
  retailerTypes,
  segmentTypes,
  segmentUpdatingStatuses,
  supplierBudgetTypes
} from 'utils/constants';
import { checkNumber } from 'utils/global';

import TableFiltersCustom from 'components/TableFiltersCustom';
import TextFieldCustom from 'components/TextFieldCustom';
import InputAdornment from 'components/InputAdornment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AutocompleteCustom from 'components/AutocompleteCustom';
import ButtonsCustom from 'components/ButtonsCustom';
import CheckboxCustom from 'components/CheckboxCustom';
import SelectCustom from 'components/SelectCustom';
import DatePicker from 'components/DatePicker';
import useDynId from 'customHooks/useDynId';

import { ReactComponent as ArrowIcon } from 'assets/14px_arrow.svg';
import { ReactComponent as DeleteCross } from 'assets/cross_campaign.svg';
import { ReactComponent as FlagActiveIcon } from 'assets/flag_active.svg';
import { ReactComponent as FlagInactiveIcon } from 'assets/flag_inactive.svg';
import { ReactComponent as ResetIcon } from 'assets/resetIcon.svg';

import './Filters.scss';

const Filters = forwardRef(
  (
    {
      containerWithNoMargin = false,
      currency,
      disabled,
      filters = {},
      filtersListToDisplay = [],
      retailersList = [],
      suppliersList = [],
      targetingStrategiesList = [],
      universesList = [],
      updateFilters,
      userManagersList = [],
      useResetButton = true
    },
    ref
  ) => {
    const { t } = useTranslation();
    const resetButtonId = useDynId('resetFilters');

    useImperativeHandle(ref, () => ({
      resetAllFilters
    }));

    const tagTableFiltersCustomRef = useRef(null);
    const titleTableFiltersCustomRef = useRef(null);
    const budgetSpentTableFiltersCustomRef = useRef(null);
    const budgetSpentWithOverrunTableFiltersCustomRef = useRef(null);
    const budgetTargetTableFiltersCustomRef = useRef(null);
    const currentYearBudgetTableFiltersCustomRef = useRef(null);
    const nextYearBudgetTableFiltersCustomRef = useRef(null);
    const discountMinQuantityTableFiltersCustomRef = useRef(null);
    const averagePriceTableFiltersCustomRef = useRef(null);
    const generosityTableFiltersCustomRef = useRef(null);
    const generosityPercentageTableFiltersCustomRef = useRef(null);

    const [tagContainsInputValue, setTagContainsInputValue] = useState('');
    const [tagNotContainsInputValue, setTagNotContainsInputValue] = useState('');
    const [tagContains, setTagContains] = useState(filters.tagContains || []);
    const [tagNotContains, setTagNotContains] = useState(filters.tagNotContains || []);
    const hasFilterIds = !!filters.id?.length;
    const [title, setTitle] = useState(filters.title);
    const [idInput, setIdInput] = useState('');
    const [idList, setIdList] = useState(filters.id || []);
    const [statusList, setStatusList] = useState(filters.status || []);
    const [typeList, setTypeList] = useState(filters.type || []);
    const [stateList, setStateList] = useState(filters.state || []);
    const [targetingStatusList, setTargetingStatusList] = useState(filters.targetingStatus || []);
    const [targetingStrategyIdList, setTargetingStrategyIdList] = useState(filters.targetingStrategyId || []);
    const [targetingMarketingCodeList, setTargetingMarketingCodeList] = useState(filters.targetingMarketingCode || []);
    const [privateLabelList, setPrivateLabelList] = useState(filters.privateLabel || []);
    const [discountTypeList, setDiscountTypeList] = useState(filters.discountType || []);
    const [supplierIdList, setSupplierIdList] = useState(filters.supplierId || []);
    const [retailerIdList, setRetailerIdList] = useState(filters.retailerId || []);
    const [budgetSpent, setBudgetSpent] = useState(filters.budgetSpent || ['lessThan', null]);
    const [onlyOverrunBudget, setOnlyOverrunBudget] = useState(filters.onlyOverrunBudget || null);
    const [budgetTarget, setBudgetTarget] = useState(filters.budgetTarget || ['equals', null]);
    const [currentYearBudget, setCurrentYearBudget] = useState(filters.currentYearBudget || ['equals', null]);
    const [nextYearBudget, setNextYearBudget] = useState(filters.nextYearBudget || ['equals', null]);
    const [validityStartDate, setValidityStartDate] = useState(filters.validityStartDate || ['equals', null]);
    const [validityEndDate, setValidityEndDate] = useState(filters.validityEndDate || ['equals', null]);
    const [creationDate, setCreationDate] = useState(filters.creationDate || ['equals', null]);
    const [archivedList, setArchivedList] = useState(filters.archived || []);
    const [offerHeadTypeList, setOfferHeadTypeList] = useState(filters.offerHeadType || []);
    const [offerTypeList, setOfferTypeList] = useState(filters.offerType || []);
    const [retailerTypeList, setRetailerTypeList] = useState(filters.retailerType || []);
    const [segmentTypeList, setSegmentTypeList] = useState(filters.segmentType || []);
    const [segmentUpdatingStatusList, setUpdatingStatusList] = useState(filters.segmentUpdatingStatus || []);
    const [supplierBudgetTypeList, setSupplierBudgetTypeList] = useState(filters.supplierBudgetType || []);
    const [discountMinQuantity, setDiscountMinQuantity] = useState(filters.discountMinQuantity || ['equals', null]);
    const [averagePrice, setAveragePrice] = useState(filters.averagePrice || ['lessThan', null]);
    const [generosity, setGenerosity] = useState(filters.generosity || ['lessThan', null, 2]); // [sign, value, id from discountUnits]
    const [generosityPercentage, setGenerosityPercentage] = useState(
      filters.generosityPercentage || ['lessThan', null]
    );
    const [superEditable, setSuperEditable] = useState(filters.superEditable || null);
    const [userManagerIdList, setUserManagerIdList] = useState(filters.userManagerId || []);
    const [universeSelectedList, setUniverseSelectedList] = useState(filters.universe || []);

    useEffect(() => {
      if (targetingStrategiesList.length && filters.targetingStrategyId)
        setTargetingStrategyIdList(filters.targetingStrategyId);
    }, [filters.targetingStrategyId, targetingStrategiesList]);

    useEffect(() => {
      if (targetingStrategiesList.length && filters.targetingMarketingCode)
        setTargetingMarketingCodeList(filters.targetingMarketingCode);
    }, [filters.targetingMarketingCode, targetingStrategiesList]);

    useEffect(() => {
      if (universesList.length && filters.universe) setUniverseSelectedList(filters.universe);
    }, [filters.universe, universesList]);

    const truncate = (inputData = [], inputLabel) => {
      let data = !Array.isArray(inputData) ? [inputData] : [...inputData];
      data = data.filter((el) => el !== undefined);
      const trucatedString = data[0]?.length > 14 ? `${data[0].substring(0, 14)}...` : data[0];

      if (trucatedString) {
        return (
          <span>
            {trucatedString}
            {data?.length > 1 && (
              <>
                , <span className={clsx('popper-badge', hasFilterIds && 'disabled')}>+ {data.length - 1}</span>
              </>
            )}
          </span>
        );
      }
      return t(inputLabel);
    };

    const handleKeyUp = (event, key) => {
      if (event.key === 'Enter') {
        if (key === 'tagContains') {
          // avoid empty and duplicates
          const value = event.target.value;
          if (value && !tagContains.includes(value)) {
            setTagContains([...tagContains, value]);
          }
          setTagContainsInputValue('');
        }
        if (key === 'tagNotContains') {
          // avoid empty and duplicates
          const value = event.target.value;
          if (value && !tagNotContains.includes(value)) {
            setTagNotContains([...tagNotContains, value]);
          }
          setTagNotContainsInputValue('');
        }
        if (key === 'title') {
          handleUpdateFilters({ title: event.target.value });
          titleTableFiltersCustomRef.current.handleClose();
        }
        if (key === 'budgetSpent') {
          handleUpdateFilters({
            budgetSpent: budgetSpent[1] ? budgetSpent : undefined
          });
          budgetSpentTableFiltersCustomRef.current.handleClose();
        }
        if (key === 'budgetSpentWithOverrun') {
          handleUpdateFilters({
            budgetSpent: budgetSpent[1] ? budgetSpent : undefined,
            onlyOverrunBudget
          });
          budgetSpentWithOverrunTableFiltersCustomRef.current.handleClose();
        }
        if (key === 'budgetTarget') {
          handleUpdateFilters({
            budgetTarget: budgetTarget[1] ? budgetTarget : undefined
          });
          budgetTargetTableFiltersCustomRef.current.handleClose();
        }
        if (key === 'currentYearBudget') {
          handleUpdateFilters({
            currentYearBudget: currentYearBudget[1] ? currentYearBudget : undefined
          });
          currentYearBudgetTableFiltersCustomRef.current.handleClose();
        }
        if (key === 'nextYearBudget') {
          handleUpdateFilters({
            nextYearBudget: nextYearBudget[1] ? nextYearBudget : undefined
          });
          nextYearBudgetTableFiltersCustomRef.current.handleClose();
        }
        if (key === 'discountMinQuantity') {
          handleUpdateFilters({
            discountMinQuantity: discountMinQuantity[1] ? discountMinQuantity : undefined
          });
          discountMinQuantityTableFiltersCustomRef.current.handleClose();
        }
        if (key === 'averagePrice') {
          handleUpdateFilters({
            averagePrice: averagePrice[1] ? averagePrice : undefined
          });
          averagePriceTableFiltersCustomRef.current.handleClose();
        }
        if (key === 'generosity') {
          handleUpdateFilters({
            generosity: generosity[1] ? generosity : undefined
          });
          generosityTableFiltersCustomRef.current.handleClose();
        }
        if (key === 'generosityPercentage') {
          handleUpdateFilters({
            generosityPercentage: generosityPercentage[1] ? generosityPercentage : undefined
          });
          generosityPercentageTableFiltersCustomRef.current.handleClose();
        }

        if (key === 'id') {
          // avoid empty and duplicates
          const value = parseInt(event.target.value, 10);
          if (event.target.value && !idList.includes(value)) {
            setIdList([...idList, value]);
          }
          setIdInput('');
        }
      }
    };

    const resetAllFilters = (exclusionListObj) => {
      const exclusionList = exclusionListObj?.exclusionList;

      if (!exclusionList?.includes('descriptionTag')) setTagContainsInputValue('');
      if (!exclusionList?.includes('descriptionTag')) setTagNotContainsInputValue('');
      if (!exclusionList?.includes('descriptionTag')) setTagContains([]);
      if (!exclusionList?.includes('descriptionTag')) setTagNotContains([]);
      if (!exclusionList?.includes('title')) setTitle(null);
      if (!exclusionList?.includes('id')) setIdInput('');
      if (!exclusionList?.includes('id')) setIdList([]);
      if (!exclusionList || !['dashboardOfferStatus', 'opdStatus', 'status'].some((el) => exclusionList?.includes(el)))
        setStatusList([]);
      if (!exclusionList?.includes('state')) setStateList([]);
      if (!exclusionList?.includes('targetingStatus')) setTargetingStatusList([]);
      if (!exclusionList?.includes('targetingStrategy')) setTargetingStrategyIdList([]);
      if (!exclusionList?.includes('targetingMarketingStrategy')) setTargetingMarketingCodeList([]);
      if (!exclusionList?.includes('privateLabel')) setPrivateLabelList([]);
      if (!exclusionList?.includes('discountType')) setDiscountTypeList([]);
      if (!exclusionList?.includes('type')) setTypeList([]);
      if (!exclusionList?.includes('retailerUser')) setSupplierIdList([]);
      if (!exclusionList?.includes('supplierUser')) setRetailerIdList([]);
      if (!exclusionList?.includes('budgetSpent')) setBudgetSpent(['lessThan', null]);
      if (!exclusionList?.includes('budgetSpentWithOverrun')) setOnlyOverrunBudget(null);
      if (!exclusionList?.includes('budgetTarget')) setBudgetTarget(['equals', null]);
      if (!exclusionList?.includes('currentYearBudget')) setCurrentYearBudget(['equals', null]);
      if (!exclusionList?.includes('nextYearBudget')) setNextYearBudget(['equals', null]);
      if (!exclusionList?.includes('validityDates')) setValidityStartDate(['equals', null]);
      if (!exclusionList?.includes('validityDates')) setValidityEndDate(['equals', null]);
      if (!exclusionList?.includes('creationDate')) setCreationDate(['equals', null]);
      if (!exclusionList?.includes('archived')) setArchivedList([]);
      if (!exclusionList?.includes('offerHeadType')) setOfferHeadTypeList([]);
      if (!exclusionList?.includes('privateLabel')) setOfferTypeList([]);
      if (!exclusionList?.includes('retailerType')) setRetailerTypeList([]);
      if (!exclusionList?.includes('segmentType')) setSegmentTypeList([]);
      if (!exclusionList?.includes('segmentUpdatingStatus')) setUpdatingStatusList([]);
      if (!exclusionList?.includes('supplierBudgetType')) setSupplierBudgetTypeList([]);
      if (!exclusionList?.includes('discountMinQuantity')) setDiscountMinQuantity(['equals', null]);
      if (!exclusionList?.includes('averagePrice')) setAveragePrice(['lessThan', null]);
      if (!exclusionList?.includes('generosity')) setGenerosity(['lessThan', null, 2]);
      if (!exclusionList?.includes('generosityPercentage')) setGenerosityPercentage(['lessThan', null]);
      if (!exclusionList?.includes('generosityPercentage')) setSuperEditable(null);
      if (!exclusionList?.includes('userManager')) setUserManagerIdList([]);
      if (!exclusionList?.includes('universe')) setUniverseSelectedList([]);

      updateFilters({});
    };

    const handleUpdateFilters = (filter) => {
      const newFilters = { ...filters, ...filter };
      const arrFilter = Object.entries(filter);
      arrFilter.forEach((arr) => {
        if (!arr[1] || (typeof arr[1] !== 'boolean' && !arr[1].length)) {
          delete newFilters[arr[0]];
        }
      });
      updateFilters(newFilters);
    };

    const filtersConfig = {
      descriptionTag: (
        <TableFiltersCustom
          buttonLabel={truncate(
            tagContains?.length
              ? [
                  `${filterSigns.find((sign) => sign.id === 'contains').label} ${tagContains[0]}`,
                  ...tagContains.slice(1),
                  ...tagNotContains
                ]
              : tagNotContains?.length
              ? [
                  `${filterSigns.find((sign) => sign.id === 'doesNotContain').label} ${tagNotContains[0]}`,
                  ...tagNotContains.slice(1)
                ]
              : undefined,
            '_dyn_campaign_product_step2_form_tagInput'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('tagFilter')}
          isActive={!hasFilterIds && (!!tagContains?.length || !!tagNotContains?.length)}
          handleValidation={() => {
            const newTagContainsList =
              tagContainsInputValue && !tagContains.includes(tagContainsInputValue)
                ? [...tagContains, tagContainsInputValue]
                : [...tagContains];
            const newTagNotContainsList =
              tagNotContainsInputValue && !tagNotContains.includes(tagNotContainsInputValue)
                ? [...tagNotContains, tagNotContainsInputValue]
                : [...tagNotContains];

            handleUpdateFilters({
              tagContains: newTagContainsList,
              tagNotContains: newTagNotContainsList
            });
            setTagContains(newTagContainsList);
            setTagNotContains(newTagNotContainsList);
            setTagContainsInputValue('');
            setTagNotContainsInputValue('');
          }}
          key="descriptionTag"
          popperId="descriptionTag_filter"
          popperContent={
            <>
              {/* tagContains input */}
              <div className="inputContainer-with-left-select-sign first">
                <div className="sign-info-block" id="select-tag-line1-sign">
                  {filterSigns.find((sign) => sign.id === 'contains').label}
                </div>
                <TextFieldCustom
                  autoFocus
                  id={useDynId('tag-contains-input')}
                  InputProps={{
                    endAdornment: (
                      <ButtonsCustom
                        classType="icon_secondary_small"
                        disabled={!tagContainsInputValue.length}
                        id="add_tag_contains_button"
                        method={() => {
                          if (tagContainsInputValue && !tagContains.includes(tagContainsInputValue)) {
                            setTagContains([...tagContains, tagContainsInputValue]);
                          }
                          setTagContainsInputValue('');
                        }}
                        startIconCustom={<ArrowIcon />}
                      />
                    )
                  }}
                  label={t('commun_tag_include')}
                  onChange={(event) => {
                    const value = event.target.value.replace(',', '');
                    setTagContainsInputValue(value);
                  }}
                  onKeyUp={(event) => {
                    handleKeyUp(event, 'tagContains');
                  }}
                  value={tagContainsInputValue}
                />
              </div>
              {/* tagContains list */}
              <div className="tags-container">
                {tagContains.map((tag) => {
                  return (
                    <div className="popper-resume" key={tag}>
                      {tag}
                      <button
                        className="popper-resume-delete-button"
                        onClick={() => {
                          setTagContains(tagContains.filter((el) => el !== tag));
                        }}
                      >
                        {<DeleteCross />}
                      </button>
                    </div>
                  );
                })}
              </div>

              {/* tagNotContains input */}
              <div className="inputContainer-with-left-select-sign">
                <div className="sign-info-block" id="select-tag-line2-sign">
                  {filterSigns.find((sign) => sign.id === 'doesNotContain').label}
                </div>
                <TextFieldCustom
                  id={useDynId('tag-notContains-input')}
                  InputProps={{
                    endAdornment: (
                      <ButtonsCustom
                        classType="icon_secondary_small"
                        disabled={!tagNotContainsInputValue.length}
                        id="add_tag_notContains_button"
                        method={() => {
                          if (tagNotContainsInputValue && !tagNotContains.includes(tagNotContainsInputValue)) {
                            setTagNotContains([...tagNotContains, tagNotContainsInputValue]);
                          }
                          setTagNotContainsInputValue('');
                        }}
                        startIconCustom={<ArrowIcon />}
                      />
                    )
                  }}
                  label={t('commun_tag_exclude')}
                  onChange={(event) => {
                    const value = event.target.value.replace(',', '');
                    setTagNotContainsInputValue(value);
                  }}
                  onKeyUp={(event) => {
                    handleKeyUp(event, 'tagNotContains');
                  }}
                  value={tagNotContainsInputValue}
                />
              </div>
              {/* tagNotContains list */}
              <div className="tags-container">
                {tagNotContains.map((tag) => {
                  return (
                    <div className="popper-resume" key={tag}>
                      {tag}
                      <button
                        className="popper-resume-delete-button"
                        onClick={() => {
                          setTagNotContains(tagNotContains.filter((el) => el !== tag));
                        }}
                      >
                        {<DeleteCross />}
                      </button>
                    </div>
                  );
                })}
              </div>
            </>
          }
          ref={tagTableFiltersCustomRef}
        />
      ),
      title: (
        <TableFiltersCustom
          buttonLabel={truncate(filters.title, '_dyn_campaign_product_step2_form_titleInput')}
          isActive={!hasFilterIds && !!title?.length}
          buttonId={useDynId('titleFilter')}
          handleValidation={() => {
            handleUpdateFilters({ title });
          }}
          disabled={disabled || hasFilterIds}
          key="title"
          popperId="title_filter"
          popperContent={
            <TextFieldCustom
              type="text"
              label={t('_dyn_campaign_product_step2_form_titleInput')}
              defaultValue={filters.title}
              onKeyUp={(event) => {
                handleKeyUp(event, 'title');
              }}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
              autoComplete="off"
              autoFocus
              id={useDynId('inputTitle')}
            />
          }
          ref={titleTableFiltersCustomRef}
        />
      ),
      id: (
        <TableFiltersCustom
          buttonLabel={truncate(filters.id, '_dyn_campaign_product_step2_form_idInput')}
          isActive={hasFilterIds}
          buttonId={useDynId('idFilter')}
          handleValidation={() => {
            const newIds = [...idList];
            const value = parseInt(idInput, 10);
            if (idInput && !idList.includes(value)) {
              newIds.push(value);
            }
            handleUpdateFilters({ id: newIds });
            setIdList(newIds);
            setIdInput('');
          }}
          disabled={disabled}
          key="id"
          popperId="id_filter"
          popperContent={
            <TextFieldCustom
              type="number"
              label={t('_dyn_campaign_product_step2_form_idInput')}
              onKeyUp={(event) => {
                handleKeyUp(event, 'id');
              }}
              onChange={(event) => {
                setIdInput(event.target.value);
              }}
              value={idInput}
              autoComplete="off"
              autoFocus
              id={useDynId('inputId')}
              InputProps={{
                endAdornment: (
                  <ButtonsCustom
                    classType="icon_secondary_small"
                    disabled={!idInput.length}
                    id="add_id_button"
                    method={() => {
                      // avoid empty and duplicates
                      const value = parseInt(idInput, 10);
                      if (value && !idList.includes(value)) {
                        setIdList([...idList, value]);
                      }
                      setIdInput('');
                    }}
                    startIconCustom={<ArrowIcon />}
                  />
                )
              }}
            />
          }
          popperContentResume={idList.map((id) => (
            <div className="popper-resume" key={id}>
              {id.toString()}
              <button
                className="popper-resume-delete-button"
                onClick={() => {
                  setIdList(idList.filter((i) => i !== id));
                }}
              >
                {<DeleteCross />}
              </button>
            </div>
          ))}
        />
      ),
      dashboardOfferStatus: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.status ? filters.status.map((s) => t(`_dyn_commun_${s}`)) : undefined,
            'commun_status'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('statusFilter')}
          isActive={!hasFilterIds && !!statusList.length}
          handleValidation={() => {
            handleUpdateFilters({ status: statusList });
          }}
          key="dashboardOfferStatus"
          popperId="dashboard_offer_status_filter"
          popperContent={
            <FormGroup>
              {[offerStatuses.VALIDATED, offerStatuses.EXPIRED, offerStatuses.DISABLED].map((status) => (
                <FormControlLabel
                  key={status}
                  control={
                    <CheckboxCustom
                      idText={status}
                      checked={statusList.includes(status)}
                      onChange={(e) => {
                        //    add or remove status in the array
                        const newStatusList = statusList.includes(status)
                          ? statusList.filter((s) => s !== status)
                          : [...statusList, status];

                        setStatusList(newStatusList);
                      }}
                    />
                  }
                  label={t(`_dyn_commun_${status}`)}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      opdStatus: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.status ? filters.status.map((s) => t(`_dyn_commun_${s}`)) : undefined,
            'commun_status'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('statusFilter')}
          isActive={!hasFilterIds && !!statusList.length}
          handleValidation={() => {
            handleUpdateFilters({ status: statusList });
          }}
          key="opdStatus"
          popperId="opd_status_filter"
          popperContent={
            <FormGroup>
              {[offerStatuses.DRAFT, offerStatuses.VALIDATED, offerStatuses.EXPIRED, offerStatuses.DISABLED].map(
                (status) => (
                  <FormControlLabel
                    key={status}
                    control={
                      <CheckboxCustom
                        idText={status}
                        checked={statusList.includes(status)}
                        onChange={(e) => {
                          //    add or remove status in the array
                          const newStatusList = statusList.includes(status)
                            ? statusList.filter((s) => s !== status)
                            : [...statusList, status];

                          setStatusList(newStatusList);
                        }}
                      />
                    }
                    label={t(`_dyn_commun_${status}`)}
                  />
                )
              )}
            </FormGroup>
          }
        />
      ),
      status: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.status ? filters.status.map((s) => t(`_dyn_commun_${s}`)) : undefined,
            'commun_status'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('statusFilter')}
          isActive={!hasFilterIds && !!statusList.length}
          handleValidation={() => {
            handleUpdateFilters({ status: statusList });
          }}
          key="status"
          popperId="status_filter"
          popperContent={
            <FormGroup>
              {[
                offerStatuses.PROPOSAL,
                offerStatuses.TO_VALIDATE,
                offerStatuses.VALIDATED,
                offerStatuses.EXPIRED,
                offerStatuses.DISABLED
              ].map((status) => (
                <FormControlLabel
                  key={status}
                  control={
                    <CheckboxCustom
                      idText={status}
                      checked={statusList.includes(status)}
                      onChange={(e) => {
                        //    add or remove status in the array
                        const newStatusList = statusList.includes(status)
                          ? statusList.filter((s) => s !== status)
                          : [...statusList, status];

                        setStatusList(newStatusList);
                      }}
                    />
                  }
                  label={t(`_dyn_commun_${status}`)}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      type: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.type ? filters.type.map((s) => t(`_dyn_commun_${s}`)) : undefined,
            '_dyn_commun_type'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('typeFilter')}
          isActive={!hasFilterIds && !!typeList.length}
          handleValidation={() => {
            handleUpdateFilters({ type: typeList });
          }}
          key="type"
          popperId="type_filter"
          popperContent={
            <FormGroup>
              {Object.keys(campaignTypes)
                .filter((i) => i !== campaignTypes.MIXED)
                .map((type) => (
                  <FormControlLabel
                    key={type}
                    control={
                      <CheckboxCustom
                        idText={type}
                        checked={typeList.includes(type)}
                        onChange={(e) => {
                          //    add or remove status in the array
                          const newTypeList = typeList.includes(type)
                            ? typeList.filter((s) => s !== type)
                            : [...typeList, type];

                          setTypeList(newTypeList);
                        }}
                      />
                    }
                    label={t(`_dyn_commun_${type}`)}
                  />
                ))}
            </FormGroup>
          }
        />
      ),
      state: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.state ? filters.state.map((s) => t(`_dyn_commun_${s}`)) : undefined,
            'commun_state'
          )}
          disabled={disabled || hasFilterIds}
          isActive={!hasFilterIds && !!stateList.length}
          handleValidation={() => {
            handleUpdateFilters({ state: stateList });
          }}
          key="state"
          buttonId={useDynId('stateFilter')}
          popperId="state_filter"
          popperContent={
            <FormGroup>
              {Object.keys(campaignStates).map((state) => (
                <FormControlLabel
                  key={state}
                  control={
                    <CheckboxCustom
                      idText={state}
                      checked={stateList.includes(state)}
                      onChange={(e) => {
                        //    add or remove status in the array
                        const newStateList = stateList.includes(state)
                          ? stateList.filter((s) => s !== state)
                          : [...stateList, state];

                        setStateList(newStateList);
                      }}
                    />
                  }
                  label={t(`_dyn_commun_${state}`)}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      archived: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.archived && filters.archived.length
              ? filters.archived.map((s) => t(`segment_table_${s ? 'archived' : 'active'}`))
              : undefined,
            'commun_status'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('archivedFilter')}
          isActive={!hasFilterIds && !!archivedList.length}
          handleValidation={() => {
            handleUpdateFilters({ archived: archivedList });
          }}
          key="archived"
          popperId="archived_filter"
          popperContent={
            <FormGroup>
              {[false, true].map((status) => (
                <FormControlLabel
                  key={status}
                  control={
                    <CheckboxCustom
                      idText={status}
                      checked={archivedList.includes(status)}
                      onChange={(e) => {
                        //    add or remove archived status in the array
                        const newArchivedList = archivedList.includes(status)
                          ? archivedList.filter((s) => s !== status)
                          : [...archivedList, status];

                        setArchivedList(newArchivedList);
                      }}
                    />
                  }
                  label={t(`segment_table_${status ? 'archived' : 'active'}`)}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      segmentUpdatingStatus: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.segmentUpdatingStatus?.length
              ? filters.segmentUpdatingStatus.map((s) => t(`segment_updatingStatus_${s.toLowerCase()}`))
              : undefined,
            'segment_table_calcul'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('segmentUpdatingStatusFilter')}
          isActive={!hasFilterIds && !!segmentUpdatingStatusList.length}
          handleValidation={() => {
            handleUpdateFilters({ segmentUpdatingStatus: segmentUpdatingStatusList });
          }}
          key="segmentUpdatingStatus"
          popperId="calcul_filter"
          popperContent={
            <FormGroup>
              {Object.keys(segmentUpdatingStatuses).map((status) => (
                <FormControlLabel
                  key={status}
                  control={
                    <CheckboxCustom
                      idText={status}
                      checked={segmentUpdatingStatusList.includes(status)}
                      onChange={(e) => {
                        //    add or remove segmentUpdatingStatus status in the array
                        const newList = segmentUpdatingStatusList.includes(status)
                          ? segmentUpdatingStatusList.filter((s) => s !== status)
                          : [...segmentUpdatingStatusList, status];

                        setUpdatingStatusList(newList);
                      }}
                    />
                  }
                  label={t(`segment_updatingStatus_${status.toLowerCase()}`)}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      targetingStatus: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.targetingStatus
              ? filters.targetingStatus.map((s) => t(`campaign_targetingStatus_${s}`))
              : undefined,
            'campaign_details_targeting_status'
          )}
          disabled={disabled || hasFilterIds}
          isActive={!hasFilterIds && !!targetingStatusList.length}
          handleValidation={() => {
            handleUpdateFilters({ targetingStatus: targetingStatusList });
          }}
          key="targetingStatus"
          buttonId={useDynId('targetingStatusFilter')}
          popperId="targetingStatus_filter"
          popperContent={
            <FormGroup>
              {Object.keys(campaignTargetingStatuses)
                .filter(
                  (status) =>
                    status !== campaignTargetingStatuses.REQUESTED && status !== campaignTargetingStatuses.FAIL_DATA
                )
                .map((status) => {
                  return (
                    <FormControlLabel
                      key={status}
                      control={
                        <CheckboxCustom
                          idText={status}
                          checked={targetingStatusList.includes(status)}
                          onChange={(e) => {
                            //    add or remove status in the array
                            const newStatusList = targetingStatusList.includes(status)
                              ? targetingStatusList.filter((s) => s !== status)
                              : [...targetingStatusList, status];

                            setTargetingStatusList(newStatusList);
                          }}
                        />
                      }
                      label={t(`campaign_targetingStatus_${status}`)}
                    />
                  );
                })}
            </FormGroup>
          }
        />
      ),
      targetingStrategy: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.targetingStrategyId?.map((t) => targetingStrategiesList?.find((s) => t === s.id)?.title),
            'commun_strategy'
          )}
          disabled={disabled || hasFilterIds || !targetingStrategiesList.length}
          isActive={!hasFilterIds && !!targetingStrategyIdList.length}
          buttonId={useDynId('targetingStrategyIdFilter')}
          handleValidation={() => {
            handleUpdateFilters({ targetingStrategyId: targetingStrategyIdList });
          }}
          key="targetingStrategy"
          popperId="targetingStrategyId_filter"
          popperContent={
            <FormGroup>
              {targetingStrategiesList.map((strategyDataItem) => (
                <FormControlLabel
                  key={strategyDataItem.id}
                  control={
                    <CheckboxCustom
                      idText={strategyDataItem.code}
                      checked={targetingStrategyIdList.includes(strategyDataItem.id)}
                      onChange={(e) => {
                        //    add or remove targetingStrategyId in the array
                        const newList = targetingStrategyIdList.includes(strategyDataItem.id)
                          ? targetingStrategyIdList.filter((s) => s !== strategyDataItem.id)
                          : [...targetingStrategyIdList, strategyDataItem.id];

                        setTargetingStrategyIdList(newList);
                      }}
                    />
                  }
                  label={strategyDataItem.title}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      targetingMarketingStrategy: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.targetingMarketingCode?.map((code) =>
              t(
                `commun_marketingStrategy_${
                  targetingStrategiesList?.find((s) => code === s.targetMarketing.code)?.targetMarketing.code
                }_title`
              )
            ),
            'commun_target_marketing'
          )}
          disabled={disabled || hasFilterIds || !targetingStrategiesList.length}
          isActive={!hasFilterIds && !!targetingMarketingCodeList.length}
          buttonId={useDynId('targetingMarketingCodeFilter')}
          handleValidation={() => {
            handleUpdateFilters({ targetingMarketingCode: targetingMarketingCodeList });
          }}
          key="targetingMarketingStrategy"
          popperId="targetingMarketingCode_filter"
          popperContent={
            <FormGroup>
              {targetingStrategiesList
                .reduce((acc, value) => {
                  const id = value.targetMarketing.id;
                  if (!acc.find((el) => el.targetMarketing.id === id)) {
                    acc.push(value);
                  }
                  return acc;
                }, [])
                .map((strategyDataItem) => (
                  <FormControlLabel
                    key={strategyDataItem.id}
                    control={
                      <CheckboxCustom
                        idText={strategyDataItem.code}
                        checked={targetingMarketingCodeList.includes(strategyDataItem.targetMarketing.code)}
                        onChange={(e) => {
                          //    add or remove targetingStrategyId in the array
                          const newList = targetingMarketingCodeList.includes(strategyDataItem.targetMarketing.code)
                            ? targetingMarketingCodeList.filter((s) => s !== strategyDataItem.targetMarketing.code)
                            : [...targetingMarketingCodeList, strategyDataItem.targetMarketing.code];

                          setTargetingMarketingCodeList(newList);
                        }}
                      />
                    }
                    label={t(`commun_marketingStrategy_${strategyDataItem.targetMarketing.code}_title`)}
                  />
                ))}
            </FormGroup>
          }
        />
      ),
      privateLabel: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.privateLabel?.map((p) => t(p === 'mdd' ? 'commun_retailer_brand' : 'commun_national_brand')),
            '_dyn_campaign_product_step2_form_mddInput'
          )}
          disabled={
            disabled ||
            hasFilterIds ||
            (filtersListToDisplay.includes('offerType') &&
              offerTypeList.length === 1 &&
              offerTypeList[0] === offerTypes.SUPPLIER_PRODUCT_OFFER)
          }
          buttonId={useDynId('privateLabelFilter')}
          isActive={!hasFilterIds && !!privateLabelList.length}
          handleValidation={() => {
            handleUpdateFilters({ privateLabel: privateLabelList });
          }}
          key="privateLabel"
          popperId="privateLabel_filter"
          popperContent={
            <FormGroup>
              {['mdd', 'national'].map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <CheckboxCustom
                      idText={type}
                      checked={privateLabelList.includes(type)}
                      onChange={() => {
                        //    add or remove privateLabel in the array
                        const newList = privateLabelList.includes(type)
                          ? privateLabelList.filter((p) => p !== type)
                          : [...privateLabelList, type];

                        setPrivateLabelList(newList);
                      }}
                      name={type}
                    />
                  }
                  label={t(type === 'mdd' ? 'commun_retailer_brand' : 'commun_national_brand')}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      retailerUser: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.supplierId?.map((sup) => suppliersList?.find((s) => sup === s.id)?.name),
            'commun_supplier'
          )}
          isActive={!hasFilterIds && !!supplierIdList.length}
          disabled={disabled || hasFilterIds || !suppliersList.length}
          handleValidation={() => {
            handleUpdateFilters({ supplierId: supplierIdList });
          }}
          key="retailerUser"
          buttonId={useDynId('supplierFilter')}
          popperId="supplier_filter"
          popperContent={
            <AutocompleteCustom
              autoFocus={true}
              labelAttribute="name"
              listData={
                !!userManagerIdList?.length
                  ? suppliersList.filter((sup) => userManagerIdList.includes(sup.userManager?.id))
                  : suppliersList
              }
              onSelection={(item) => {
                const newList = [...supplierIdList];
                if (!newList.includes(item.id)) {
                  newList.push(item.id);
                }
                setSupplierIdList(newList);
              }}
              placeholderText={t('commun_supplier')}
            />
          }
          popperContentResume={supplierIdList.map((id) => (
            <div className="popper-resume" key={id}>
              {suppliersList.find((s) => s.id === id)?.name}
              <button
                className="popper-resume-delete-button"
                onClick={() => {
                  setSupplierIdList(supplierIdList.filter((el) => el !== id));
                }}
              >
                {<DeleteCross />}
              </button>
            </div>
          ))}
        />
      ),
      supplierUser: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.retailerId?.map((id) => retailersList?.find((r) => id === r.id)?.name),
            'commun_retailer'
          )}
          disabled={disabled || hasFilterIds || !retailersList.length}
          isActive={!hasFilterIds && !!retailerIdList.length}
          handleValidation={() => {
            handleUpdateFilters({ retailerId: retailerIdList });
          }}
          buttonId={useDynId('retailerFilter')}
          key="supplierUser"
          popperId="retailer_filter"
          popperContent={
            <FormGroup>
              {retailersList?.map((retailerItem) => (
                <FormControlLabel
                  key={retailerItem.id}
                  control={
                    <CheckboxCustom
                      idText={retailerItem.name}
                      checked={retailerIdList.includes(retailerItem.id)}
                      onChange={(e) => {
                        //    add or remove retailerId in the array
                        const newList = retailerIdList.includes(retailerItem.id)
                          ? retailerIdList.filter((s) => s !== retailerItem.id)
                          : [...retailerIdList, retailerItem.id];

                        setRetailerIdList(newList);
                      }}
                    />
                  }
                  label={retailerItem.name}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      budgetSpent: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.budgetSpent?.[1]
              ? `${filterSigns.find((sign) => sign.id === filters.budgetSpent[0]).label} ${t('commun_price', {
                  value: filters.budgetSpent[1],
                  currency: currency?.code
                })}`
              : undefined,
            'commun_budget_spent'
          )}
          disabled={disabled || hasFilterIds || !!supplierIdList.length}
          buttonId={useDynId('budgetSpentFilter')}
          isActive={!hasFilterIds && !!budgetSpent?.[1]}
          handleValidation={() => {
            handleUpdateFilters({
              budgetSpent: budgetSpent[1] ? budgetSpent : undefined
            });
          }}
          key="budgetSpent"
          popperId="budgetSpent_filter"
          popperContent={
            <div className="inputContainer-with-left-select-sign">
              <SelectCustom
                id="select-budgetSpent-line1-sign"
                methodOnChange={(event) => {
                  const newArray = [...budgetSpent];
                  newArray[0] = event.target.value;
                  setBudgetSpent(newArray);
                }}
                listData={filterSigns.filter((sign) => ['lessThan', 'greaterThan'].includes(sign.id))}
                labelAttribute="label"
                value={filterSigns.find((sign) => sign.id === budgetSpent[0]).id}
              />
              <TextFieldCustom
                autoFocus
                InputProps={{
                  endAdornment: <InputAdornment position="end">{currency?.symbol} </InputAdornment>
                }}
                label={t('commun_budget_spent')}
                onChange={(event) => {
                  const value = event.target.value;
                  if (checkNumber(value) || value === '') {
                    const newArray = [...budgetSpent];
                    newArray[1] = value;
                    setBudgetSpent(newArray);
                  }
                }}
                onKeyUp={(event) => {
                  handleKeyUp(event, 'budgetSpent');
                }}
                type="number"
                value={budgetSpent[1] || ''}
              />
            </div>
          }
          ref={budgetSpentTableFiltersCustomRef}
        />
      ),
      budgetSpentWithOverrun: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.budgetSpent?.[1] || filters.onlyOverrunBudget
              ? [
                  filters.onlyOverrunBudget ? t('commun_budget_spent_overrun') : undefined,
                  filters.budgetSpent?.[1]
                    ? `${filterSigns.find((sign) => sign.id === filters.budgetSpent[0]).label} ${t('commun_price', {
                        value: filters.budgetSpent[1],
                        currency: currency?.code
                      })}`
                    : undefined
                ]
              : undefined,
            'commun_budget_spent'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('budgetSpentWithOverrunFilter')}
          isActive={!hasFilterIds && (!!budgetSpent?.[1] || onlyOverrunBudget)}
          handleValidation={() => {
            handleUpdateFilters({
              budgetSpent: budgetSpent[1] ? budgetSpent : undefined,
              onlyOverrunBudget
            });
          }}
          key="budgetSpentWithOverrun"
          popperId="budgetSpentWithOverrun_filter"
          popperContent={
            <>
              <div className="inputContainer-with-left-select-sign">
                <SelectCustom
                  id="select-budgetSpentWithOverrun-line1-sign"
                  methodOnChange={(event) => {
                    const newArray = [...budgetSpent];
                    newArray[0] = event.target.value;
                    setBudgetSpent(newArray);
                  }}
                  listData={filterSigns.filter((sign) => ['lessThan', 'greaterThan'].includes(sign.id))}
                  labelAttribute="label"
                  value={filterSigns.find((sign) => sign.id === budgetSpent[0]).id}
                />
                <TextFieldCustom
                  autoFocus
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{currency?.symbol} </InputAdornment>
                  }}
                  label={t('commun_budget_spent')}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (checkNumber(value) || value === '') {
                      const newArray = [...budgetSpent];
                      newArray[1] = value;
                      setBudgetSpent(newArray);
                    }
                  }}
                  onKeyUp={(event) => {
                    handleKeyUp(event, 'budgetSpentWithOverrun');
                  }}
                  type="number"
                  value={budgetSpent[1] || ''}
                />
              </div>
              <FormControlLabel
                key={'onlyOverrunBudget'}
                control={
                  <CheckboxCustom
                    idText={'onlyOverrunBudget'}
                    checked={onlyOverrunBudget || false}
                    onChange={() => {
                      setOnlyOverrunBudget(!onlyOverrunBudget);
                    }}
                  />
                }
                label={t('commun_budget_spent_overrun_only')}
              />
            </>
          }
          ref={budgetSpentWithOverrunTableFiltersCustomRef}
        />
      ),
      budgetTarget: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.budgetTarget?.[1]
              ? `${filterSigns.find((sign) => sign.id === filters.budgetTarget[0]).label} ${t('commun_price', {
                  value: filters.budgetTarget[1],
                  currency: currency?.code
                })}`
              : undefined,
            'commun_budget_target'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('budgetTargetFilter')}
          isActive={!hasFilterIds && !!budgetTarget?.[1]}
          handleValidation={() => {
            handleUpdateFilters({
              budgetTarget: budgetTarget[1] ? budgetTarget : undefined
            });
          }}
          key="budgetTarget"
          popperId="budgetTarget_filter"
          popperContent={
            <div className="inputContainer-with-left-select-sign">
              <SelectCustom
                id="select-budgetTarget-line1-sign"
                methodOnChange={(event) => {
                  const newArray = [...budgetTarget];
                  newArray[0] = event.target.value;
                  setBudgetTarget(newArray);
                }}
                listData={filterSigns.filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))}
                labelAttribute="label"
                value={filterSigns.find((sign) => sign.id === budgetTarget[0]).id}
              />
              <TextFieldCustom
                autoFocus
                InputProps={{
                  endAdornment: <InputAdornment position="end">{currency?.symbol} </InputAdornment>
                }}
                label={t('commun_budget_target')}
                type="number"
                onChange={(event) => {
                  const value = event.target.value;
                  if (checkNumber(value) || value === '') {
                    const newArray = [...budgetTarget];
                    newArray[1] = value;
                    setBudgetTarget(newArray);
                  }
                }}
                onKeyUp={(event) => {
                  handleKeyUp(event, 'budgetTarget');
                }}
                value={budgetTarget[1] || ''}
              />
            </div>
          }
          ref={budgetTargetTableFiltersCustomRef}
        />
      ),
      currentYearBudget: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.currentYearBudget?.[1]
              ? `${filterSigns.find((sign) => sign.id === filters.currentYearBudget[0]).label} ${t('commun_price', {
                  value: filters.currentYearBudget[1],
                  currency: currency?.code
                })}`
              : undefined,
            t('supplier_budget_N', { year: new Date().getFullYear() })
          )}
          disabled={disabled || hasFilterIds || !!supplierIdList.length}
          buttonId={useDynId('currentYearBudget')}
          isActive={!hasFilterIds && !!currentYearBudget?.[1]}
          handleValidation={() => {
            handleUpdateFilters({
              currentYearBudget: currentYearBudget[1] ? currentYearBudget : undefined
            });
          }}
          key="currentYearBudget"
          popperId="currentYearBudget_filter"
          popperContent={
            <div className="inputContainer-with-left-select-sign">
              <SelectCustom
                id="select-currentYearBudget-line1-sign"
                methodOnChange={(event) => {
                  const newArray = [...currentYearBudget];
                  newArray[0] = event.target.value;
                  setCurrentYearBudget(newArray);
                }}
                listData={filterSigns.filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))}
                labelAttribute="label"
                value={filterSigns.find((sign) => sign.id === currentYearBudget[0]).id}
              />
              <TextFieldCustom
                autoFocus
                InputProps={{
                  endAdornment: <InputAdornment position="end">{currency?.symbol} </InputAdornment>
                }}
                label={t('supplier_budget_N', { year: new Date().getFullYear() })}
                onChange={(event) => {
                  const value = event.target.value;
                  if (checkNumber(value) || value === '') {
                    const newArray = [...currentYearBudget];
                    newArray[1] = event.target.value;
                    setCurrentYearBudget(newArray);
                  }
                }}
                onKeyUp={(event) => {
                  handleKeyUp(event, 'currentYearBudget');
                }}
                type="number"
                value={currentYearBudget[1] || ''}
              />
            </div>
          }
          ref={currentYearBudgetTableFiltersCustomRef}
        />
      ),
      nextYearBudget: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.nextYearBudget?.[1]
              ? `${filterSigns.find((sign) => sign.id === filters.nextYearBudget[0]).label} ${t('commun_price', {
                  value: filters.nextYearBudget[1],
                  currency: currency?.code
                })}`
              : undefined,
            t('supplier_budget_N1', { year: new Date().getFullYear() + 1 })
          )}
          disabled={disabled || hasFilterIds || !!supplierIdList.length}
          buttonId={useDynId('nextYearBudget')}
          isActive={!hasFilterIds && !!nextYearBudget?.[1]}
          handleValidation={() => {
            handleUpdateFilters({
              nextYearBudget: nextYearBudget[1] ? nextYearBudget : undefined
            });
          }}
          key="nextYearBudget"
          popperId="nextYearBudget_filter"
          popperContent={
            <div className="inputContainer-with-left-select-sign">
              <SelectCustom
                id="select-nextYearBudget-line1-sign"
                methodOnChange={(event) => {
                  const newArray = [...nextYearBudget];
                  newArray[0] = event.target.value;
                  setNextYearBudget(newArray);
                }}
                listData={filterSigns.filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))}
                labelAttribute="label"
                value={filterSigns.find((sign) => sign.id === nextYearBudget[0]).id}
              />
              <TextFieldCustom
                autoFocus
                InputProps={{
                  endAdornment: <InputAdornment position="end">{currency?.symbol} </InputAdornment>
                }}
                label={t('supplier_budget_N1', { year: new Date().getFullYear() + 1 })}
                onChange={(event) => {
                  const value = event.target.value;
                  if (checkNumber(value) || value === '') {
                    const newArray = [...nextYearBudget];
                    newArray[1] = event.target.value;
                    setNextYearBudget(newArray);
                  }
                }}
                onKeyUp={(event) => {
                  handleKeyUp(event, 'nextYearBudget');
                }}
                type="number"
                value={nextYearBudget[1] || ''}
              />
            </div>
          }
          ref={nextYearBudgetTableFiltersCustomRef}
        />
      ),
      validityDates: (
        <TableFiltersCustom
          buttonLabel={truncate([], 'commun_validation_dates')}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('validityDatesFilter')}
          isActive={(!hasFilterIds && !!validityStartDate?.[1]) || (!hasFilterIds && !!validityEndDate?.[1])}
          handleValidation={() => {
            handleUpdateFilters({
              validityStartDate: validityStartDate?.[1] ? validityStartDate : undefined,
              validityEndDate: validityEndDate?.[1] ? validityEndDate : undefined
            });
          }}
          key="validityDates"
          popperId="validityDates_filter"
          popperContent={
            <>
              {/* startDate */}
              <div className="inputContainer-with-left-select-sign">
                <SelectCustom
                  id="select-validityStartDate-sign"
                  methodOnChange={(event) => {
                    const newArray = [...validityStartDate];
                    newArray[0] = event.target.value;
                    setValidityStartDate(newArray);
                  }}
                  listData={filterSigns.filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))}
                  labelAttribute="label"
                  value={filterSigns.find((sign) => sign.id === validityStartDate[0]).id}
                />
                <DatePicker
                  date={validityStartDate?.[1] ? moment(validityStartDate[1]) : null}
                  id="validityStartDate"
                  placeholder={t('commun_start_date')}
                  onDateChange={(date) => {
                    const newArray = [...validityStartDate];
                    newArray[1] = date ? date.startOf('day') : null;
                    setValidityStartDate(newArray);
                  }}
                />
              </div>
              {/* endDate */}
              <div className="inputContainer-with-left-select-sign">
                <SelectCustom
                  id="select-validityEndDate-sign"
                  methodOnChange={(event) => {
                    const newArray = [...validityEndDate];
                    newArray[0] = event.target.value;
                    setValidityEndDate(newArray);
                  }}
                  listData={filterSigns.filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))}
                  labelAttribute="label"
                  value={filterSigns.find((sign) => sign.id === validityEndDate[0]).id}
                />
                <DatePicker
                  date={validityEndDate?.[1] ? moment(validityEndDate[1]) : null}
                  id="validityEndDate"
                  placeholder={t('commun_end_date')}
                  onDateChange={(date) => {
                    const newArray = [...validityEndDate];
                    newArray[1] = date ? date.endOf('day') : null;
                    setValidityEndDate(newArray);
                  }}
                />
              </div>
            </>
          }
        />
      ),
      creationDate: (
        <TableFiltersCustom
          buttonLabel={truncate([], 'commun_creation_date')}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('creationDateFilter')}
          isActive={(!hasFilterIds && !!creationDate?.[1]) || (!hasFilterIds && !!creationDate?.[1])}
          handleValidation={() => {
            handleUpdateFilters({
              creationDate: creationDate?.[1] ? creationDate : undefined
            });
          }}
          key="creationDate"
          popperId="creationDate_filter"
          popperContent={
            <>
              {/* startDate */}
              <div className="inputContainer-with-left-select-sign">
                <SelectCustom
                  id="select-creationDate-sign"
                  methodOnChange={(event) => {
                    const newArray = [...creationDate];
                    newArray[0] = event.target.value;
                    setCreationDate(newArray);
                  }}
                  listData={filterSigns.filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))}
                  labelAttribute="label"
                  value={filterSigns.find((sign) => sign.id === creationDate[0]).id}
                />
                <DatePicker
                  date={creationDate?.[1] ? moment(creationDate[1]) : null}
                  id="creationDate"
                  placeholder={t('commun_creation_date')}
                  onDateChange={(date) => {
                    const newArray = [...creationDate];
                    newArray[1] = date ? date.startOf('day') : null;
                    setCreationDate(newArray);
                  }}
                />
              </div>
            </>
          }
        />
      ),
      discountType: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.discountType?.length
              ? filters.discountType.map((d) => t(`_dyn_commun_${d.toLowerCase()}_discount`))
              : undefined,
            'commun_discount_type'
          )}
          buttonId={useDynId('discountFilter')}
          disabled={disabled || hasFilterIds}
          isActive={!hasFilterIds && !!discountTypeList.length}
          handleValidation={() => {
            handleUpdateFilters({ discountType: discountTypeList });
          }}
          key="discountType"
          popperId="discountType_filter"
          popperContent={
            <FormGroup>
              {['IMMEDIATE', 'LOYALTY'].map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <CheckboxCustom
                      idText={type}
                      checked={discountTypeList.includes(type)}
                      onChange={(e) => {
                        //    add or remove targetingStrategyId in the array
                        const newList = discountTypeList.includes(type)
                          ? discountTypeList.filter((s) => s !== type)
                          : [...discountTypeList, type];

                        setDiscountTypeList(newList);
                      }}
                    />
                  }
                  label={t(`_dyn_commun_${type.toLowerCase()}_discount`)}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      offerHeadType: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.offerHeadType?.length ? filters.offerHeadType.map((o) => t(`offer_head_type_${o}`)) : undefined,
            '_dyn_commun_type'
          )}
          buttonId={useDynId('offerHeadTypeFilter')}
          isActive={!hasFilterIds && !!offerHeadTypeList.length}
          disabled={disabled || hasFilterIds}
          key="offerHeadType"
          popperId="offerHeadType_filter"
          handleValidation={() => {
            handleUpdateFilters({ offerHeadType: offerHeadTypeList });
          }}
          popperContent={
            <FormGroup>
              {Object.keys(offerHeadTypes).map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <CheckboxCustom
                      idText={type}
                      checked={offerHeadTypeList.includes(type)}
                      onChange={(e) => {
                        //    add or remove offerHeadType in the array
                        const newList = offerHeadTypeList.includes(type)
                          ? offerHeadTypeList.filter((s) => s !== type)
                          : [...offerHeadTypeList, type];

                        setOfferHeadTypeList(newList);
                      }}
                    />
                  }
                  label={t(`offer_head_type_${type}`)}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      offerType: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.offerType?.length
              ? filters.offerType.map((o) => t(`_dyn_campaign_product_step2_form_${o}`))
              : undefined,
            '_dyn_campaign_product_step2_form_typeInput'
          )}
          buttonId={useDynId('offerTypeFilter')}
          isActive={!hasFilterIds && !!offerTypeList.length}
          disabled={disabled || hasFilterIds}
          key="offerType"
          popperId="offerType_filter"
          handleValidation={() => {
            handleUpdateFilters({ offerType: offerTypeList });
          }}
          popperContent={
            <FormGroup>
              {[offerTypes.RETAILER_PRODUCT_OFFER, offerTypes.SUPPLIER_PRODUCT_OFFER].map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <CheckboxCustom
                      idText={type}
                      checked={offerTypeList.includes(type)}
                      onChange={(e) => {
                        //    add or remove offerType in the array
                        const newList = offerTypeList.includes(type)
                          ? offerTypeList.filter((s) => s !== type)
                          : [...offerTypeList, type];

                        setOfferTypeList(newList);
                      }}
                    />
                  }
                  label={t(`_dyn_campaign_product_step2_form_${type}`)}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      retailerType: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.retailerType?.length ? filters.retailerType.map((r) => t(`retailer_type_${r}`)) : undefined,
            'retailers_list_type'
          )}
          buttonId={useDynId('retailerTypeFilter')}
          isActive={!hasFilterIds && !!retailerTypeList.length}
          disabled={hasFilterIds}
          key="retailerType"
          popperId="retailerType_filter"
          handleValidation={() => {
            handleUpdateFilters({ retailerType: retailerTypeList });
          }}
          popperContent={
            <FormGroup>
              {Object.keys(retailerTypes).map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <CheckboxCustom
                      idText={type}
                      checked={retailerTypeList.includes(type)}
                      onChange={(e) => {
                        //    add or remove retailerType in the array
                        const newList = retailerTypeList.includes(type)
                          ? retailerTypeList.filter((s) => s !== type)
                          : [...retailerTypeList, type];

                        setRetailerTypeList(newList);
                      }}
                    />
                  }
                  label={t(`retailer_type_${type}`)}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      segmentType: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.segmentType?.length ? filters.segmentType.map((r) => t(`segment_type_${r}`)) : undefined,
            '_dyn_commun_type'
          )}
          buttonId={useDynId('segmentTypeFilter')}
          isActive={!hasFilterIds && !!segmentTypeList.length}
          disabled={hasFilterIds}
          key="segmentType"
          popperId="segmentType_filter"
          handleValidation={() => {
            handleUpdateFilters({ segmentType: segmentTypeList });
          }}
          popperContent={
            <FormGroup>
              {Object.keys(segmentTypes).map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <CheckboxCustom
                      idText={type}
                      checked={segmentTypeList.includes(type)}
                      onChange={(e) => {
                        //    add or remove segmentType in the array
                        const newList = segmentTypeList.includes(type)
                          ? segmentTypeList.filter((s) => s !== type)
                          : [...segmentTypeList, type];

                        setSegmentTypeList(newList);
                      }}
                    />
                  }
                  label={t(`segment_type_${type}`)}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      supplierBudgetType: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.supplierBudgetType?.length
              ? filters.supplierBudgetType.map((type) => t(`commun_budget_type_${type}`))
              : undefined,
            'suppliers_list_budget_type'
          )}
          buttonId={useDynId('supplierBudgetTypeFilter')}
          isActive={!hasFilterIds && !!supplierBudgetTypeList.length}
          disabled={disabled || hasFilterIds}
          key="supplierBudgetType"
          popperId="supplierBudgetType_filter"
          handleValidation={() => {
            handleUpdateFilters({ supplierBudgetType: supplierBudgetTypeList });
          }}
          popperContent={
            <FormGroup>
              {Object.keys(supplierBudgetTypes).map((type) => (
                <FormControlLabel
                  key={type}
                  control={
                    <CheckboxCustom
                      idText={type}
                      checked={supplierBudgetTypeList.includes(type)}
                      onChange={(e) => {
                        //    add or remove supplierBudgetType in the array
                        const newList = supplierBudgetTypeList.includes(type)
                          ? supplierBudgetTypeList.filter((s) => s !== type)
                          : [...supplierBudgetTypeList, type];

                        setSupplierBudgetTypeList(newList);
                      }}
                    />
                  }
                  label={t(`commun_budget_type_${type}`)}
                />
              ))}
            </FormGroup>
          }
        />
      ),
      discountMinQuantity: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.discountMinQuantity?.[1]
              ? `${filterSigns.find((sign) => sign.id === filters.discountMinQuantity[0]).label} ${
                  filters.discountMinQuantity[1]
                }`
              : undefined,
            'offers_creation_quantity_to_add_bis'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('discountMinQuantityFilter')}
          isActive={!hasFilterIds && !!discountMinQuantity?.[1]}
          handleValidation={() => {
            handleUpdateFilters({
              discountMinQuantity: discountMinQuantity[1] ? discountMinQuantity : undefined
            });
          }}
          key="discountMinQuantity"
          popperId="discountMinQuantity_filter"
          popperContent={
            <div className="inputContainer-with-left-select-sign">
              <SelectCustom
                id="select-discountMinQuantity-sign"
                methodOnChange={(event) => {
                  const newArray = [...discountMinQuantity];
                  newArray[0] = event.target.value;
                  setDiscountMinQuantity(newArray);
                }}
                listData={filterSigns.filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))}
                labelAttribute="label"
                value={filterSigns.find((sign) => sign.id === discountMinQuantity[0]).id}
              />
              <TextFieldCustom
                autoFocus
                label={t('offers_creation_quantity_to_add_bis')}
                type="number"
                onChange={(event) => {
                  const value = event.target.value;
                  if (checkNumber(value) || value === '') {
                    const newArray = [...discountMinQuantity];
                    newArray[1] = value;
                    setDiscountMinQuantity(newArray);
                  }
                }}
                onKeyUp={(event) => {
                  handleKeyUp(event, 'discountMinQuantity');
                }}
                value={discountMinQuantity[1] || ''}
              />
            </div>
          }
          ref={discountMinQuantityTableFiltersCustomRef}
        />
      ),
      averagePrice: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.averagePrice?.[1]
              ? `${filterSigns.find((sign) => sign.id === filters.averagePrice[0]).label} ${filters.averagePrice[1]}`
              : undefined,
            'offers_creation_average_price_short'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('averagePriceFilter')}
          isActive={!hasFilterIds && !!averagePrice?.[1]}
          handleValidation={() => {
            handleUpdateFilters({
              averagePrice: averagePrice[1] ? averagePrice : undefined
            });
          }}
          key="averagePrice"
          popperId="averagePrice_filter"
          popperContent={
            <div className="inputContainer-with-left-select-sign">
              <SelectCustom
                id="select-averagePrice-sign"
                methodOnChange={(event) => {
                  const newArray = [...averagePrice];
                  newArray[0] = event.target.value;
                  setAveragePrice(newArray);
                }}
                listData={filterSigns.filter((sign) => ['lessThan', 'greaterThan'].includes(sign.id))}
                labelAttribute="label"
                value={filterSigns.find((sign) => sign.id === averagePrice[0]).id}
              />
              <TextFieldCustom
                autoFocus
                InputProps={{
                  endAdornment: <InputAdornment position="end">{currency?.symbol} </InputAdornment>
                }}
                label={t('offers_creation_average_price_short')}
                type="number"
                onChange={(event) => {
                  const value = event.target.value;
                  if (checkNumber(value) || value === '') {
                    const newArray = [...averagePrice];
                    newArray[1] = value;
                    setAveragePrice(newArray);
                  }
                }}
                onKeyUp={(event) => {
                  handleKeyUp(event, 'averagePrice');
                }}
                value={averagePrice[1] || ''}
              />
            </div>
          }
          ref={averagePriceTableFiltersCustomRef}
        />
      ),
      generosity: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.generosity?.[1]
              ? `${filterSigns.find((sign) => sign.id === filters.generosity[0]).label} ${filters.generosity[1]} ${
                  filters.generosity[2] === 1 ? '%' : currency?.symbol
                }`
              : undefined,
            'commun_generosity'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('generosityFilter')}
          isActive={!hasFilterIds && !!generosity?.[1]}
          handleValidation={() => {
            handleUpdateFilters({
              generosity: generosity[1] ? generosity : undefined
            });
          }}
          key="generosity"
          popperId="generosity_filter"
          popperContent={
            <div className="inputContainer-with-left-select-sign">
              <SelectCustom
                id="select-generosity-sign"
                methodOnChange={(event) => {
                  const newArray = [...generosity];
                  newArray[0] = event.target.value;
                  setGenerosity(newArray);
                }}
                listData={filterSigns.filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))}
                labelAttribute="label"
                value={filterSigns.find((sign) => sign.id === generosity[0]).id}
              />
              <TextFieldCustom
                autoFocus
                label={t('commun_generosity')}
                type="number"
                onChange={(event) => {
                  const value = event.target.value;
                  if (checkNumber(value) || value === '') {
                    const newArray = [...generosity];
                    newArray[1] = value;
                    setGenerosity(newArray);
                  }
                }}
                onKeyUp={(event) => {
                  handleKeyUp(event, 'generosity');
                }}
                value={generosity[1] || ''}
              />
              <SelectCustom
                id="select-generosity-unit"
                methodOnChange={(event) => {
                  const newArray = [...generosity];
                  newArray[2] = event.target.value;
                  setGenerosity(newArray);
                }}
                listData={discountUnits.map((el) => {
                  if (el.denomination === 'CURRENCY') {
                    return { ...el, label: currency?.symbol };
                  } else return el;
                })}
                labelAttribute="label"
                value={generosity[2]}
              />
            </div>
          }
          ref={generosityTableFiltersCustomRef}
        />
      ),
      generosityPercentage: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.generosityPercentage?.[1]
              ? `${filterSigns.find((sign) => sign.id === filters.generosityPercentage[0]).label} ${
                  filters.generosityPercentage[1]
                } %`
              : undefined,
            'commun_generosity'
          )}
          disabled={disabled || hasFilterIds}
          buttonId={useDynId('generosityPercentageFilter')}
          isActive={!hasFilterIds && !!generosityPercentage?.[1]}
          handleValidation={() => {
            handleUpdateFilters({
              generosityPercentage: generosityPercentage[1] ? generosityPercentage : undefined
            });
          }}
          key="generosityPercentage"
          popperId="generosityPercentage_filter"
          popperContent={
            <div className="inputContainer-with-left-select-sign">
              <SelectCustom
                id="select-generosityPercentage-sign"
                methodOnChange={(event) => {
                  const newArray = [...generosityPercentage];
                  newArray[0] = event.target.value;
                  setGenerosityPercentage(newArray);
                }}
                listData={filterSigns.filter((sign) => ['equals', 'lessThan', 'greaterThan'].includes(sign.id))}
                labelAttribute="label"
                value={filterSigns.find((sign) => sign.id === generosityPercentage[0]).id}
              />
              <TextFieldCustom
                autoFocus
                label={t('commun_generosity')}
                type="number"
                onChange={(event) => {
                  const value = event.target.value;
                  if (checkNumber(value) || value === '') {
                    const newArray = [...generosityPercentage];
                    newArray[1] = value;
                    setGenerosityPercentage(newArray);
                  }
                }}
                onKeyUp={(event) => {
                  handleKeyUp(event, 'generosityPercentage');
                }}
                value={generosityPercentage[1] || ''}
              />
              <div className="end-text-extra-info">%</div>
            </div>
          }
          ref={generosityPercentageTableFiltersCustomRef}
        />
      ),
      superEditable: (
        <ButtonsCustom
          id={useDynId('superEditableFilter')}
          classType={superEditable ? 'resetFilters_active' : 'resetFilters'}
          disabled={disabled || hasFilterIds}
          key="superEditable"
          startIconCustom={superEditable ? <FlagActiveIcon /> : <FlagInactiveIcon />}
          method={() => {
            const newValue = !superEditable === true ? true : null;
            setSuperEditable(newValue);
            handleUpdateFilters({ superEditable: newValue });
          }}
        />
      ),
      userManager: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.userManagerId?.map((id) => {
              const elementFound = userManagersList?.find((u) => id === u.id);
              if (elementFound?.firstName || elementFound?.lastName) {
                return `${elementFound?.firstName} ${elementFound?.lastName}`;
              }
              return elementFound?.email;
            }),
            'supplier_userManager'
          )}
          isActive={!hasFilterIds && !!userManagerIdList.length}
          disabled={disabled || hasFilterIds || !userManagersList.length || !!supplierIdList.length}
          handleValidation={() => {
            handleUpdateFilters({ userManagerId: userManagerIdList });
          }}
          key="userManagerId"
          buttonId={useDynId('userManagerFilter')}
          popperId="userManager_filter"
          popperContent={
            <FormGroup>
              {userManagersList.map((userManager) => (
                <FormControlLabel
                  key={userManager.id}
                  control={
                    <CheckboxCustom
                      idText={userManager.id.toString()}
                      checked={userManagerIdList.includes(userManager.id)}
                      onChange={(e) => {
                        //    add or remove userManager id in the array
                        const newList = userManagerIdList.includes(userManager.id)
                          ? userManagerIdList.filter((id) => id !== userManager.id)
                          : [...userManagerIdList, userManager.id];

                        setUserManagerIdList(newList);
                      }}
                    />
                  }
                  label={
                    userManager.firstName || userManager.lastName
                      ? `${userManager.firstName} ${userManager.lastName}`
                      : userManager.email
                  }
                />
              ))}
            </FormGroup>
          }
        />
      ),
      universe: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.universe?.map((universe) => t(`universe_${universe}`)),
            'commun_universe'
          )}
          isActive={!hasFilterIds && !!universeSelectedList.length}
          disabled={disabled || hasFilterIds || !universesList.length}
          handleValidation={() => {
            // update suppliers list filter when universe changes
            setSupplierIdList((state) => {
              const newState = universeSelectedList.length
                ? state.filter((id) => {
                    const supplier = suppliersList.find((sup) => sup.id === id);
                    return !!universeSelectedList.includes(supplier.universe);
                  })
                : state;
              return newState;
            });
            handleUpdateFilters({ universe: universeSelectedList });
          }}
          key="universe"
          buttonId={useDynId('universeFilter')}
          popperId="universe_filter"
          popperContent={
            <FormGroup>
              {universesList
                .map((universe) => ({ id: universe, label: t(`universe_${universe}`) }))
                .sort((a, b) => {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                })
                .map((universeObj) => (
                  <FormControlLabel
                    key={universeObj.id}
                    control={
                      <CheckboxCustom
                        idText={universeObj.id}
                        checked={universeSelectedList.includes(universeObj.id)}
                        onChange={(e) => {
                          //    add or remove universe in the array
                          const newList = universeSelectedList.includes(universeObj.id)
                            ? universeSelectedList.filter((id) => id !== universeObj.id)
                            : [...universeSelectedList, universeObj.id];

                          setUniverseSelectedList(newList);
                        }}
                      />
                    }
                    label={universeObj.label}
                  />
                ))}
            </FormGroup>
          }
        />
      ),
      universeLinkedToSupplierList: (
        <TableFiltersCustom
          buttonLabel={truncate(
            filters.universe?.map((universe) => t(`universe_${universe}`)),
            'commun_universe'
          )}
          isActive={!hasFilterIds && !!universeSelectedList.length}
          disabled={disabled || hasFilterIds || !universesList.length || !!supplierIdList.length}
          handleValidation={() => {
            handleUpdateFilters({ universe: universeSelectedList });
          }}
          key="universe"
          buttonId={useDynId('universeFilter')}
          popperId="universe_filter"
          popperContent={
            <FormGroup>
              {universesList
                .map((universe) => ({ id: universe, label: t(`universe_${universe}`) }))
                .sort((a, b) => {
                  if (a.label < b.label) {
                    return -1;
                  }
                  if (a.label > b.label) {
                    return 1;
                  }
                  return 0;
                })
                .map((universeObj) => (
                  <FormControlLabel
                    key={universeObj.id}
                    control={
                      <CheckboxCustom
                        idText={universeObj.id}
                        checked={universeSelectedList.includes(universeObj.id)}
                        onChange={(e) => {
                          //    add or remove universe in the array
                          const newList = universeSelectedList.includes(universeObj.id)
                            ? universeSelectedList.filter((id) => id !== universeObj.id)
                            : [...universeSelectedList, universeObj.id];

                          setUniverseSelectedList(newList);
                        }}
                      />
                    }
                    label={universeObj.label}
                  />
                ))}
            </FormGroup>
          }
        />
      )
    };

    const displayResetFiltersButton = () => {
      return (
        <ButtonsCustom
          id={resetButtonId}
          classType="resetFilters"
          disabled={disabled}
          startIconCustom={<ResetIcon />}
          method={resetAllFilters}
        />
      );
    };

    // is multiline filters ?
    if (Array.isArray(filtersListToDisplay[0])) {
      return (
        <div>
          {filtersListToDisplay.map((arr, index) => {
            return (
              <div
                className={`container-multiline ${index === 0 ? 'first-line' : ''} ${
                  index + 1 === filtersListToDisplay.length ? 'last-line' : ''
                }`}
                key={index}
              >
                {arr.map((key) => filtersConfig[key] || null)}
                {/* RESET BUTTON on last line */}
                {index + 1 === filtersListToDisplay.length && displayResetFiltersButton()}
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className={clsx('container', containerWithNoMargin && 'no-margin')}>
        {/* FILTERS multi line or single linge */}
        {filtersListToDisplay.map((key) => filtersConfig[key] || null)}
        {/* RESET BUTTON */}
        {useResetButton && displayResetFiltersButton()}
      </div>
    );
  }
);

Filters.propTypes = {
  containerWithNoMargin: PropTypes.bool,
  currency: PropTypes.object,
  disabled: PropTypes.bool,
  filters: PropTypes.object,
  filtersListToDisplay: PropTypes.array,
  retailersList: PropTypes.array,
  suppliersList: PropTypes.array,
  targetingStrategiesList: PropTypes.array,
  universesList: PropTypes.array,
  updateFilters: PropTypes.func.isRequired,
  userManagersList: PropTypes.array
};

export default memo(Filters);

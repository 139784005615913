import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { getBudgetsAreaSupplierOffer } from 'api';

import CampaignsToAllocate from './CampaignsToAllocateBlock';
import Donut from 'components/Donut';
import Tooltip from 'components/Tooltip';

import { ReactComponent as EmptyDonutIcon } from 'assets/empty_donut.svg';
import { ReactComponent as PeopleIcon } from 'assets/24px_people.svg';
import { ReactComponent as RocketIcon } from 'assets/24px_fusée.svg';
import { ReactComponent as TargetIcon } from 'assets/30px_cible.svg';

import styles from '../../Dashboard.module.scss';

const BudgetKpisBlock = ({ filterYear, loadingAvailableYears }) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state } = globalState;
  const { user } = state;

  const [budgetKpis, setBudgetKpis] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const budgetKpisList = useMemo(() => {
    return [
      {
        icon: <RocketIcon />,
        label: 'commun_budget_target',
        tooltip: t('dashboard_budgets_kpis_targeted_tooltip'),
        value: budgetKpis?.keyFigure?.totalBudget
          ? t('commun_price', { value: budgetKpis.keyFigure.totalBudget, currency: user.currency.code })
          : '-'
      },
      {
        icon: <PeopleIcon />,
        label: 'commun_total_of_use',
        tooltip: t('dashboard_budgets_kpis_total_of_use_tooltip'),
        value: budgetKpis?.keyFigure?.totalNumberOfUsedCoupons
          ? t('commun_number', { value: budgetKpis.keyFigure.totalNumberOfUsedCoupons })
          : '-'
      },
      {
        icon: <TargetIcon />,
        label: 'commun_targeted_customers',
        tooltip: t('dashboard_budgets_kpis_single_target_customer_tooltip'),
        value: budgetKpis?.keyFigure?.singleTargetCustomer
          ? t('commun_number', { value: budgetKpis.keyFigure.singleTargetCustomer })
          : '-'
      }
    ];
  }, [budgetKpis, t, user.currency.code]);

  useEffect(() => {
    const getBudgetKpis = async () => {
      setIsLoading(true);
      const data = await getBudgetsAreaSupplierOffer({ budgetYear: filterYear, retailerId: user.id });
      setBudgetKpis(data);
      setIsLoading(false);
    };
    getBudgetKpis();
  }, [filterYear, user.id]);

  return (
    <div className={styles['block-container']}>
      <div className={styles['block']}>
        <div className={`${styles['frame']} ${styles['graph-container']}`}>
          <div>
            <div className={styles['block-title']}>{t('dashboard_budgets_kpis_title')}</div>
          </div>
          <div className={styles['donut']}>
            {(loadingAvailableYears || isLoading || !budgetKpis?.budgetTracking) && (
              <div className={styles['empty-icon']}>
                <EmptyDonutIcon />
              </div>
            )}
            {!loadingAvailableYears && !isLoading && !budgetKpis?.budgetTracking && (
              <div className={styles['empty-text']}>{t('commun_no_budget')}</div>
            )}
            {!loadingAvailableYears && !isLoading && !!budgetKpis?.budgetTracking && (
              <>
                <Donut
                  data={[
                    {
                      currency: user.currency.code,
                      id: 'dashboard_budget_remainingBudgetToPositioned',
                      index: 0,
                      label: 'dashboard_budget_remainingBudgetToPositioned',
                      value: budgetKpis?.budgetTracking?.remainingBudgetToPositioned
                    },
                    {
                      currency: user.currency.code,
                      id: 'dashboard_budget_positionedBudget',
                      index: 1,
                      label: 'dashboard_budget_positionedBudget',
                      value: budgetKpis?.budgetTracking?.positionedBudget
                    },
                    {
                      currency: user.currency.code,
                      id: 'dashboard_budget_expiredBudget',
                      index: 2,
                      label: 'dashboard_budget_expiredBudget',
                      value: budgetKpis?.budgetTracking?.expiredBudget
                    }
                  ]}
                  insideInfo={{
                    desc: t('dashboard_budget_remainingBudgetToPositioned'),
                    title: `${Math.max(
                      0,
                      Math.round(
                        (budgetKpis.budgetTracking.remainingBudgetToPositioned * 100) / budgetKpis.keyFigure.totalBudget
                      )
                    )} %`
                  }}
                  oneLineDisplay
                  withLegends
                  withTooltips
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className={styles['block']}>
        {budgetKpisList.map((el) => (
          <div className={`${styles['frame']} ${styles['kpi-container']}`} key={el.label}>
            <Tooltip title={el.tooltip}>
              <div className="d-flex">
                <div className={styles['icon-container']}>{el.icon}</div>
                <div className={styles['data-container']}>
                  <div>{t(el.label)}</div>
                  <div>{el.value}</div>
                </div>
              </div>
            </Tooltip>
          </div>
        ))}
        <CampaignsToAllocate />
      </div>
    </div>
  );
};

BudgetKpisBlock.propTypes = {
  filterYear: PropTypes.number,
  loadingAvailableYears: PropTypes.bool
};

export default BudgetKpisBlock;

import React, { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import currenciesList from 'utils/currencies.json';
import { getDashboardRetailerBudgetInfo, getDashboardSupplierBudgetInfo } from 'api';
import { useTranslation, Trans } from 'react-i18next';
import { withFractionDigits } from 'utils/global';

import FramedIcon from 'components/FramedIcon';
import Tooltip from 'components/Tooltip';

import { ReactComponent as GearIcon } from 'assets/20px_reglages.svg';
import { ReactComponent as MoneyBagIcon } from 'assets/20px_bourse.svg';
import { ReactComponent as MoneyStackIcon } from 'assets/24px_monnaie.svg';
import { ReactComponent as RocketIcon } from 'assets/24px_fusée.svg';
import { ReactComponent as PercentIcon } from 'assets/modulo.svg';
import { ReactComponent as TooltipIcon } from 'assets/30px_info_round.svg';

import styles from './DashboardSupplier.module.scss';

import { supplierBudgetInfoType } from 'types/supplierBudgetInfo';

interface IConfigData {
  color: string; // blue | green | yellow
  icon: ReactNode;
  label: string;
  labelTooltip: ReactNode; // string or <Trans /> JSX Element
  value: string;
}

type Props = {
  budgetIds?: number[];
  currency: string;
  retailerId?: number;
  year: number;
};

const BudgetsBlock = ({ budgetIds, currency, retailerId, year }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [data, setData] = useState<supplierBudgetInfoType>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const currencies: any = currenciesList;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response: supplierBudgetInfoType = retailerId
        ? await getDashboardRetailerBudgetInfo({
            budgetIds,
            retailerId,
            year
          })
        : await getDashboardSupplierBudgetInfo({ budgetIds, year });
      setData(response);
      setIsLoading(false);
    };

    if (!retailerId || !!budgetIds?.length) {
      fetchData();
    }
  }, [budgetIds, retailerId, year]);

  // 2 types : frame-square or frame-pilled
  const config = [
    {
      type: 'frame-square',
      blocks: [
        {
          color: 'yellow',
          icon: <MoneyBagIcon />,
          label: t('dashboard_budgets_kpis_negotiated_total'),
          labelTooltip: <Trans i18nKey="dashboard_budgets_kpis_negotiated_total_tooltip" />,
          value:
            !isLoading && currency && data?.negotiatedTotalBudget
              ? t('commun_price', { value: data?.negotiatedTotalBudget, currency })
              : '-'
        }
      ]
    },
    {
      type: 'frame-pilled',
      blocks: [
        {
          color: 'yellow',
          icon: <MoneyBagIcon />,
          label: t('dashboard_budgets_kpis_promotional_total'),
          labelTooltip: <Trans i18nKey="dashboard_budgets_kpis_promotional_total_tooltip" />,
          value:
            !isLoading && currency && data?.promotionalTotalBudget
              ? t('commun_price', { value: data?.promotionalTotalBudget, currency })
              : '-'
        },
        {
          color: 'yellow',
          icon: <GearIcon />,
          label: t('dashboard_budgets_kpis_technical_fees'),
          labelTooltip: <Trans i18nKey="dashboard_budgets_kpis_technical_fees_tooltip" />,
          value:
            !isLoading && currency && data?.technicalFees
              ? t('commun_price', { value: data?.technicalFees, currency })
              : '-'
        }
      ]
    },
    {
      type: 'frame-square',
      blocks: [
        {
          color: 'green',
          icon: <MoneyStackIcon />,
          label: t('dashboard_budgets_kpis_totalEstimatedTurnover'),
          labelTooltip: <Trans i18nKey="dashboard_budgets_kpis_totalEstimatedTurnover_tooltip" />,
          value:
            !isLoading && currency && data?.totalEstimatedTurnover
              ? t('commun_price', { value: data?.totalEstimatedTurnover, currency })
              : '-'
        }
      ]
    },
    {
      type: 'frame-pilled',
      blocks: [
        {
          color: 'green',
          icon: <RocketIcon />,
          label: t('dashboard_budgets_kpis_averageReturnOnAdSpend'),
          labelTooltip: <Trans i18nKey="dashboard_budgets_kpis_averageReturnOnAdSpend_tooltip" />,
          value:
            !isLoading && data?.totalReturnOnAdSpend
              ? t('commun_number', {
                  value: data.totalReturnOnAdSpend,
                  maximumFractionDigits: withFractionDigits(data.totalReturnOnAdSpend)
                })
              : '-'
        },
        {
          color: 'green',
          icon: <RocketIcon />,
          label: t('dashboard_budgets_kpis_returnOnInvestment'),
          labelTooltip: <Trans i18nKey="dashboard_budgets_kpis_returnOnInvestment_tooltip" />,
          value:
            !isLoading && data?.returnOnInvestment
              ? t('commun_number', {
                  value: data.returnOnInvestment,
                  maximumFractionDigits: withFractionDigits(data.returnOnInvestment)
                })
              : '-'
        }
      ]
    },
    {
      type: 'frame-pilled',
      blocks: [
        {
          color: 'blue',
          icon: <div style={{ color: 'white', fontSize: '17px' }}>{currencies[currency]}</div>,
          label: t('dashboard_budgets_kpis_averageDiscount'),
          labelTooltip: (
            <Trans
              i18nKey="dashboard_budgets_kpis_averageDiscount_tooltip"
              values={{
                text: currencies[currency]
              }}
            />
          ),
          value:
            !isLoading && currency && data?.averageDiscount
              ? t('commun_price', {
                  value: data.averageDiscount,
                  maximumFractionDigits: withFractionDigits(data.averageDiscount),
                  currency
                })
              : '-'
        },
        {
          color: 'blue',
          icon: <PercentIcon />,
          label: t('dashboard_budgets_kpis_averageDiscountPercentage'),
          labelTooltip: <Trans i18nKey="dashboard_budgets_kpis_averageDiscountPercentage_tooltip" />,
          value:
            !isLoading && data?.averageDiscountPercentage
              ? t('commun_percentage_number', {
                  value: data.averageDiscountPercentage,
                  maximumFractionDigits: withFractionDigits(data.averageDiscountPercentage)
                })
              : '-'
        }
      ]
    }
  ];

  const renderFrameSquare = (data: IConfigData, index: number): JSX.Element => (
    <div
      className={clsx(styles['frame-square'], styles['card'], styles[`with-decoration-on-top-color-${data.color}`])}
      key={`frame-square-${index}`}
    >
      <div className={styles['icon-top']}>
        <FramedIcon color={data.color} icon={data.icon} size="big" />
      </div>
      <div className={styles['value']}>{data.value}</div>
      <div className={styles['sub-label-with-info-bubble']}>
        <span>{data.label}</span>
        <Tooltip title={data.labelTooltip}>
          <TooltipIcon />
        </Tooltip>
      </div>
    </div>
  );

  const renderFramePilled = (data: IConfigData[], index: number): JSX.Element => (
    <div className={styles['frame-pilled']} key={`frame-pilled-${index}`}>
      {data.map((d, index) => renderFrameSmall(d, index))}
    </div>
  );

  const renderFrameSmall = (data: IConfigData, index: number): JSX.Element => (
    <div
      className={clsx(styles['frame-small'], styles['card'], styles[`with-decoration-on-top-color-${data.color}`])}
      key={`frame-small-${index}`}
    >
      <FramedIcon color={data.color} icon={data.icon} size="big" />
      <div>
        <div className={styles['small-value']}>{data.value}</div>
        <div className={styles['sub-label-with-info-bubble']}>
          <span>{data.label}</span>
          <Tooltip title={data.labelTooltip}>
            <TooltipIcon />
          </Tooltip>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div>
        {renderFrameSquare(config[0].blocks[0], 0)}
        {renderFramePilled(config[1].blocks, 1)}
      </div>
      <div>
        <div>
          {renderFrameSquare(config[2].blocks[0], 2)}
          {renderFramePilled(config[3].blocks, 3)}
        </div>
        {renderFramePilled(config[4].blocks, 4)}
      </div>
    </>
  );
};

export default BudgetsBlock;

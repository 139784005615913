import React from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './HelpText.module.scss';

import { ReactComponent as InfoIcon } from 'assets/24px_alert_information.svg';

const HelperText = ({ className, content = '', isPreview, title }) => (
  <div className={clsx(styles['helper-text'], isPreview && styles['helper-text-preview'], className)}>
    <InfoIcon className={styles['helper-text-icon']} />
    <div className={styles['helper-text-content']}>
      {title && <h2>{title}</h2>}
      <div
        className={styles['helper-text-html-content']}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
      />
    </div>
  </div>
);

HelperText.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string,
  isPreview: PropTypes.bool,
  title: PropTypes.string,
};

export default HelperText;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getDamImage } from 'api';
import { ReadFile } from 'utils/readFile';

import styles from './DamImage.module.scss';

const DamImage = ({ className, productCode, retailerCode, onClick }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      const img = await getDamImage(productCode, retailerCode, 'M1_T1', 'jpg');
      if (img) {
        const content = await ReadFile(img);
        mounted && setImage(content);
      }
    };

    if (retailerCode) fetch();
    return () => (mounted = false);
  }, [productCode, retailerCode]);

  const handleClick = async () => {
    if (onClick && image) {
      const img = await getDamImage(productCode, retailerCode, 'M1_S1', 'jpg');
      const fl = new File([img], `file_${productCode}_${Date.now()}.jpg`, { type: 'image/jpeg' });

      if (fl) {
        onClick(fl);
      }
    }
  };

  return (
    <div className={className} onClick={handleClick} role="button">
      {image ? (
        <img className={styles['image']} src={image} alt={productCode} />
      ) : (
        <div className={styles['loading']} />
      )}
    </div>
  );
};

DamImage.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  productCode: PropTypes.string,
  retailerCode: PropTypes.string,
};

export default DamImage;

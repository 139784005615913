import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { withFractionDigits } from 'utils/global';
import { getMonitoredOffersToValidate, updateOffer } from 'api';
import { offerStatuses, snackbarTypes } from 'utils/constants';

import Loader from 'components/Loaders/Dots';
import Tags from 'components/Tags';

import { ReactComponent as LightningIcon } from 'assets/24px_éclair.svg';
import { ReactComponent as TimelineArrowIcon } from 'assets/timeline_arrow.svg';
import { ReactComponent as TimelineArrowLeftIcon } from 'assets/timeline_arrow_left.svg';
import { ReactComponent as ValidateIcon } from 'assets/30px_valide.svg';

import EmptyImage from 'assets/illu_petit_robot.png';
import noImageIcon from 'assets/no_image.svg';
import styles from './PilotageRetailer.module.scss';

import { offerType } from 'types/offer';
import { stateOffersType } from 'types/storeState';
import ButtonsCustom from 'components/ButtonsCustom';

type Props = {
  count: number | null;
  filters: { supplierId?: string[]; userManagerId?: string[] };
  onUpdated: () => void;
};

const tableSize = 12;

const MonitoredOffersToValidate = ({ count, filters, onUpdated }: Props): JSX.Element => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state, dispatch } = globalState as any;
  const {
    user,
    monitoringRetailer: { offersToValidate }
  } = state;
  const currency = user.currency.code;

  // use the store for not refetching data when mounting after changing tab index
  const { list, orderBy, page, sortBy, total } = offersToValidate as stateOffersType;

  const [isLoading, setIsLoading] = useState(true);
  const [isValidating, setIsValidating] = useState(false);
  const [pageIndex, setPageIndex] = useState(page);

  const fetchData = async ({ page }: { page?: number }) => {
    setIsLoading(true);
    await getMonitoredOffersToValidate({
      retailerId: user.id,
      sortBy,
      orderBy,
      page,
      size: tableSize,
      filters
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (!list?.length && count && count !== total) {
      fetchData({});
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, filters, list]);

  useEffect(() => {
    dispatch({
      type: 'MONITORING_RETAILER_OFFERS_TO_VALIDATE_LIST',
      payload: { page: pageIndex }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  const handleNextPage = () => {
    const newPage = page + 1;
    setPageIndex(newPage);
    fetchData({ page: newPage });
  };

  const handlePrevPage = () => {
    const newPage = page - 1;
    setPageIndex((page) => page - 1);
    fetchData({ page: newPage });
  };

  const validateOffer = async (offer: offerType) => {
    setIsValidating(true);
    const updatedOffer = await updateOffer({
      offer: {
        ...offer,
        status: offerStatuses.VALIDATED
      }
    } as any);

    setIsValidating(false);

    if (updatedOffer) {
      await fetchData({});
      onUpdated();
      dispatch({
        type: 'UI_SNACKBAR',
        payload: { type: snackbarTypes.SUCCESS, title: { key: 'commun_validate_offer_success' } }
      });
    }
  };

  return (
    <div className={styles['monitoredOffersToValidate-container']}>
      <h4>{t('pilotage_monitored_offers_to_validate_title')}</h4>

      <div className={styles['cards-container']}>
        {/* display loader */}
        {isLoading && (
          <div className={clsx(styles['card'], styles['empty-card'])}>
            <p>{t('commun_loading')}</p>
            <Loader />
          </div>
        )}

        {/* display no results */}
        {!isLoading && total === 0 && (
          <div className={clsx(styles['card'], styles['empty-card'])}>
            <img src={EmptyImage} alt="no offers to disable" />
            <p className={styles['title']}>{t('commun_no_offers_to_validate')}</p>
            <p className={styles['desc']}>{t('pilotage_monitored_offers_to_validate_no_results')}</p>
          </div>
        )}

        {/* display cards list */}
        {!isLoading &&
          list.map((offer) => (
            <div className={styles['card']} key={offer.id}>
              <div className={styles['inner-container']}>
                <div className={styles['header']}>
                  <div>
                    <div className={styles['status-button']}>
                      <Tags status={offer.status} />
                      <ButtonsCustom
                        classType="action_reverse_lighter"
                        disabled={isValidating}
                        method={() => {
                          validateOffer(offer);
                          dispatch({
                            type: 'UI_SNACKBAR',
                            payload: { type: snackbarTypes.DISABLE, title: { key: 'commun_validate_offer_in_process' } }
                          });
                        }}
                        size="thin"
                        startIconCustom={<ValidateIcon />}
                        text={t('pilotage_monitored_offers_to_validate_button_label')}
                      />
                    </div>
                    <div className={styles['generosity']}>
                      {offer.discountUnit === 'CURRENCY'
                        ? t('commun_price', {
                            value: offer.discountValue ?? '-',
                            currency,
                            maximumFractionDigits: 2
                          })
                        : `${offer.discountValue ?? '-'} %`}
                    </div>
                  </div>
                  <div className={styles['image-container']}>
                    <img
                      className={styles['image']}
                      src={
                        offer?.offerHead?.imageMediumFileDescriptor?.url
                          ? offer?.offerHead?.imageMediumFileDescriptor?.url
                          : offer?.offerHead?.fileDescriptor?.url
                          ? offer?.offerHead?.fileDescriptor?.url
                          : noImageIcon
                      }
                      alt={offer.offerHead.title}
                    />
                  </div>
                </div>
                <div className={styles['content']}>
                  <div>
                    <div className={styles['title']}>
                      {offer.offerHead.title}
                      {/* {offer.offerHead.title.length > 95
                        ? offer.offerHead.title.slice(0, 95) + '...'
                        : offer.offerHead.title} */}
                    </div>
                    <div className={styles['tag']}>{offer.offerHead.descriptionTag}</div>
                    <div className={styles['offer-id']}>{`#${offer.offerHead.id}`}</div>
                    <div className={styles['period']}>
                      {t('commun_from_to_dates', {
                        valueFrom: offer.offerHead?.validityStartDate,
                        valueTo: offer.offerHead?.validityEndDate,
                        useShortYear: true
                      })}
                      {offer.periodsCount > 1 && <em>{`+${offer.periodsCount - 1}`}</em>}
                    </div>
                  </div>
                  <div>
                    {[
                      { label: t('commun_supplier'), value: offer.offerHead.budget?.supplier?.name },
                      {
                        label: t('commun_offer_type'),
                        value: t(`offer_head_type_${offer.offerHead.type}`)
                      },
                      {
                        label: t('commun_average_price'),
                        value: t('commun_price', {
                          currency,
                          maximumFractionDigits: withFractionDigits(offer.averagePrice),
                          value: offer.averagePrice ?? '-'
                        })
                      },
                      {
                        label: t('commun_budget_target'),
                        value:
                          offer.offerHead.budgetTarget === -1
                            ? t('commun_unlimited')
                            : t('commun_price', {
                                currency,
                                value: offer.offerHead?.budgetTarget ?? '-'
                              }),
                        extraInfo:
                          offer.offerHead.budgetEstimated &&
                          t('commun_estimated_budget_price', {
                            currency,
                            value: offer.offerHead.budgetEstimated
                          })
                      }
                    ].map((item) => {
                      return (
                        <div className={styles['info-container']} key={item.label}>
                          <div>
                            <p>{item.label}</p>
                            <p>{item.value}</p>
                          </div>
                          {item.extraInfo && (
                            <div className={styles['extra-info']}>
                              <LightningIcon />
                              {item.extraInfo}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className={styles['footer']}>
                {t('commun_offer_created_at_by', { text: offer.creator, value: offer.createdAt })}
              </div>
            </div>
          ))}

        {/* display pagination if usefull */}
        {!isLoading && !!list.length && total > list.length && (
          <div className={styles['pagination']}>
            <span className={styles['page-number']}>
              {page * tableSize + 1} - {Math.min(total, page * tableSize + tableSize)}
            </span>
            <span className={styles['total']}> / {total}</span>
            <TimelineArrowLeftIcon
              onClick={() => {
                if (page > 0) {
                  handlePrevPage();
                }
              }}
            />
            <TimelineArrowIcon
              onClick={() => {
                if (page < Math.floor(total / tableSize) && page * tableSize + tableSize !== total) {
                  handleNextPage();
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MonitoredOffersToValidate;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import DateRangePicker from 'components/DateRangePicker';

const FormDateRangePicker = (props) => {
  const {
    className,
    data: { id, defaultValue, placeholder, disabled, fieldProps },
    onChange,
    error
  } = props;

  const [dates, setDates] = useState(defaultValue);

  useEffect(() => {
    if (
      (defaultValue && !defaultValue.startDate && !defaultValue.endDate) ||
      (defaultValue?.startDate && !moment(defaultValue.startDate).isSame(dates.startDate, 'day')) ||
      (defaultValue?.endDate && !moment(defaultValue.endDate).isSame(dates.endDate, 'day'))
    ) {
      handleChange(defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue?.startDate, defaultValue?.endDate]);

  const handleChange = (newDates) => {
    setDates(newDates);
    if (onChange) onChange(id, newDates);
  };

  return (
    <DateRangePicker
      className={className}
      label={placeholder}
      type="text"
      onDatesChange={handleChange}
      startDate={dates?.startDate}
      endDate={dates?.endDate}
      name={id}
      id={id}
      error={!!error}
      errorMsg={error}
      disabled={disabled}
      {...fieldProps}
    />
  );
};

FormDateRangePicker.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func
};

export default FormDateRangePicker;

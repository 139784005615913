import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ReactComponent as NoResult } from 'assets/no_result.svg';

import ProductsList from './ProductsList';

import styles from './ProductRefListDetails.module.scss';

const ProductRefListDetails = ({ productRefs, responsive }) => {
  const { t } = useTranslation();

  return (
    <div className={styles[`container${responsive ? '-fluid' : ''}`]}>
      {productRefs.length === 0 ? (
        <div className={styles['no-result']}>
          <NoResult />
          <span>{t('supplier_no_product_list')}</span>
        </div>
      ) : (
        <>
          <h2>{t('commun_product_list')}</h2>

          <div className={styles['tags']}>
            {productRefs.map((el) => (
              <span className={styles['tag']} key={`tag-${el.id}`}>
                {el.name} | #{el.code}
              </span>
            ))}
          </div>

          {productRefs.map((el) => (
            <ProductsList reference={el} key={`list-${el.id}`} />
          ))}
        </>
      )}
    </div>
  );
};

ProductRefListDetails.propTypes = {
  productRefs: PropTypes.arrayOf(PropTypes.object),
  responsive: PropTypes.bool,
};

export default ProductRefListDetails;

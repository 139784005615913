import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { ReactComponent as FailureIcon } from 'assets/30px_croix.svg';
import { ReactComponent as InfoIcon } from 'assets/24px_alert_information.svg';

import styles from './RecommendationFailure.module.scss';

const RecommendationFailure = ({ desc, title, type = 'fail' }) => {
  return (
    <div className={clsx(styles['root'], type === 'info' && styles['type-info'])}>
      {type === 'fail' ? <FailureIcon /> : <InfoIcon />}
      <div>
        <p className={styles['title']}>{title}</p>
        <p className={styles['desc']}>{desc}</p>
      </div>
    </div>
  );
};

RecommendationFailure.propTypes = {
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(['fail', 'info']),
};

export default RecommendationFailure;

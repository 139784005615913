import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getProducts } from 'api';

import TableCustom from 'components/TableCustom';

const TableProducts = ({ brand, offerCreation, retailerId, supplierId, updateProductsCountList }) => {
  const [productList, setProductList] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [total, setTotal] = useState();
  const tableSize = 5;

  const tableColumns = [
    { id: 1, headerName: '', field: 'code', type: 'ean' },
    { id: 2, headerName: '', field: 'description', hasTooltip: true, wide: true },
  ];

  const tableRows = productList.map((product) => ({
    id: [{ value: product.id }],
    code: [{ value: product.ean, color: 'ean', textStyle: 'tag' }],
    description: [{ value: product.label, color: 'default' }],
  }));

  // refresh tableRows after deleting the last item on a page
  if (pageIndex && !tableRows.length) {
    setPageIndex(Math.max(0, pageIndex - 1));
  }

  useEffect(() => {
    const fetchLinkedProducts = async () => {
      const response = await getProducts({
        brandIds: brand.id,
        retailerId,
        supplierId,
        sort: 'code',
        order: 'desc',
        page: pageIndex,
        size: tableSize,
      });

      if (response) {
        setProductList(response.list);
        setTotal(response.total);

        updateProductsCountList({ brandId: brand.id, value: response.total });
      }
    };

    fetchLinkedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  return (
    <TableCustom
      columns={tableColumns}
      rows={tableRows}
      next={() => {
        setPageIndex((state) => state + 1);
      }}
      prev={() => {
        setPageIndex((state) => state - 1);
      }}
      page={pageIndex}
      showHeader={false}
      size={tableSize}
      total={total || 0}
      type="tiny"
    />
  );
};

TableProducts.propTypes = {
  brand: PropTypes.object,
  offerCreation: PropTypes.object,
  retailerId: PropTypes.number,
  supplierId: PropTypes.number,
  updateProductsCountList: PropTypes.func,
};

export default TableProducts;

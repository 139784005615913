import React, { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ContentDialog from 'components/ContentDialog';
import ButtonsCustom from 'components/ButtonsCustom';
import FormManager from 'components/FormManager';

const SelectSupplierBlock = ({
  currentRetailer,
  disabled,
  error,
  listData = [],
  offerCreationStep1Id,
  updateOfferCreation
}) => {
  const { t } = useTranslation();
  const [dialogOpened, setDialogOpen] = useState(false);
  const [itemSelectedId, setItemSelectedId] = useState(offerCreationStep1Id);
  // This temp value let us keep the old one after cancelling a change modal
  const [tempOldItemId, setTempOldItemId] = useState();

  const selectedSupplier =
    listData.length === 1 ? listData[0] : listData.find((supplier) => supplier.id === itemSelectedId);

  const updateOffer = (id) => {
    updateOfferCreation({
      step1: {
        code: currentRetailer.code,
        damName: currentRetailer.damName,
        id
      }
    });
  };

  useEffect(() => {
    setItemSelectedId(offerCreationStep1Id);

    // reset products when step1.id changes
    if (!offerCreationStep1Id) {
      updateOfferCreation({
        step3: {
          brands: [],
          image: false,
          resultListEan: []
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerCreationStep1Id]);

  const handleSelection = (item) => {
    const id = item?.id;

    if (itemSelectedId && id !== itemSelectedId) {
      setDialogOpen(true);
      setTempOldItemId(itemSelectedId);
    } else if (id) {
      updateOffer(id);
    }

    setItemSelectedId(id);
  };

  const closeDialogAndKeepOldValue = () => {
    setDialogOpen(false);
    setItemSelectedId(tempOldItemId);
  };

  const submitChangeAndResetOfferCreation = () => {
    updateOfferCreation({
      step1: {
        code: currentRetailer.code,
        damName: currentRetailer.damName,
        id: itemSelectedId
      },
      step3: {
        brands: [],
        image: false,
        resultListEan: []
      }
    });
    setDialogOpen(false);
  };

  return (
    <>
      <FormManager
        data={{
          title: t(`offers_creation_block_retailer_title`),
          fieldsets: [
            {
              id: 'add-offer-retailer-supplier-step',
              fields: [
                {
                  placeholder: t('commun_choose'),
                  type: 'Autocomplete',
                  defaultValue: selectedSupplier,
                  fieldProps: { listData, labelAttribute: 'name', noOptionsText: t('commun_no_supplier_found') },
                  onFieldChange: handleSelection,
                  id: 'name',
                  disabled: disabled || listData.length === 1,
                  error
                }
              ]
            }
          ]
        }}
      />

      <ContentDialog
        centerText
        isOpen={dialogOpened}
        handleClose={closeDialogAndKeepOldValue}
        title={t('offers_creation_confirm_change_step1')}
        maxWidth="xs"
      >
        <div className="confirm-dialog">
          <ButtonsCustom classType="canceled" text={t('commun_button_cancel')} method={closeDialogAndKeepOldValue} />
          <ButtonsCustom
            classType="action_primary_big"
            text={t('commun_button_continue')}
            method={submitChangeAndResetOfferCreation}
          />
        </div>
      </ContentDialog>
    </>
  );
};

SelectSupplierBlock.propTypes = {
  currentRetailer: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  listData: PropTypes.array,
  offerCreationStep1Id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  updateOfferCreation: PropTypes.func
};

export default memo(SelectSupplierBlock);

import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { store } from 'store';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCampaignsToAllocateCount } from 'api';

import Tooltip from 'components/Tooltip';

import { ReactComponent as ArrowRightIcon } from 'assets/14px_arrow.svg';
import { ReactComponent as CampaignIcon } from 'assets/30px_campagne.svg';

import styles from '../../Dashboard.module.scss';

const CampaignsToAllocate = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const globalState = useContext(store);
  const { state } = globalState;
  const { user } = state;

  const [campaignsCountToAllocateSinceStartYear, setCampaignsCountToAllocateSinceStartYear] = useState(null);
  const [campaignsCountToAllocateSinceSevenDays, setCampaignsCountToAllocateSinceSevenDays] = useState(null);

  useEffect(() => {
    const getCampaignsCount = async () => {
      const countSinceStartYear = await getCampaignsToAllocateCount({
        retailerId: user.id
      });
      if (!isNaN(countSinceStartYear)) {
        setCampaignsCountToAllocateSinceStartYear(countSinceStartYear);
      }
      if (countSinceStartYear) {
        const countSinceSevenDays = await getCampaignsToAllocateCount({
          retailerId: user.id,
          createdAt: moment().subtract(7, 'days').startOf('day')
        });

        if (countSinceSevenDays) {
          setCampaignsCountToAllocateSinceSevenDays(countSinceSevenDays);
        }
      }
    };

    getCampaignsCount();
  }, [user.id]);

  const handleGoToCampaignsListToAllocate = () => {
    if (campaignsCountToAllocateSinceStartYear) {
      history.push(
        `/campaigns?state=TO_COME,IN_PROGRESS&targetingStatus=NOT_REQUESTED&validityStartDate=greaterThan,${moment()
          .subtract(1, 'day')
          .startOf('day')
          .format('YYYY-MM-DD')}`
      );
    }
  };

  return (
    <div
      className={`${styles['frame']} ${styles['kpi-container-clickable']} ${
        !campaignsCountToAllocateSinceStartYear ? styles['no-results'] : ''
      }`}
      onClick={handleGoToCampaignsListToAllocate}
      role="button"
    >
      <div className="d-flex">
        <div className={styles['icon-container']}>
          <CampaignIcon />
        </div>
        <div className={styles['data-container']}>
          <div className="d-flex">
            {campaignsCountToAllocateSinceStartYear ?? '-'}
            {campaignsCountToAllocateSinceSevenDays && (
              <Tooltip
                title={t('dashboard_campaigns_to_allocate_plus_tooltip', {
                  count: campaignsCountToAllocateSinceStartYear,
                  since: moment().subtract(7, 'days')
                })}
              >
                <em className={styles['plus-counter']}>{`+${campaignsCountToAllocateSinceSevenDays}`}</em>
              </Tooltip>
            )}
          </div>
          <div>{t('dashboard_campaigns_to_allocate', { count: campaignsCountToAllocateSinceStartYear })}</div>
        </div>
      </div>
      {!!campaignsCountToAllocateSinceStartYear && <ArrowRightIcon />}
    </div>
  );
};

export default CampaignsToAllocate;

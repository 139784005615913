import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { offerHeadTypes } from 'utils/constants';

import FormManager from 'components/FormManager';
import EanAutocomplete from './EanAutocomplete';
import BrandAutocomplete from './BrandAutocomplete';
import HelperText from 'components/HelpText';

const ProductsBlock = ({
  currentRetailer,
  disabled,
  helperText,
  isEditMode,
  offerCreation,
  onOpenDialogSearchProduct,
  products,
  supplierId,
  updateOfferCreation
}) => {
  const { t } = useTranslation();
  const { dispatch } = useContext(store);

  const helperTextBlock =
    typeof helperText !== 'undefined'
      ? [
          {
            id: 'offerProductHelperText',
            type: <HelperText content={helperText} />
          }
        ]
      : undefined;

  const updateOfferFormState = (data) => {
    updateOfferCreation({
      step3: data
    });
  };

  const handleOptionClick = (data) => {
    updateOfferFormState({ resultListEan: data });
  };

  const handleOfferTypeClick = (value) => {
    if (!isEditMode) {
      // TODO: rework image gallery to avoid using/simulating getAllProducts
      dispatch({ type: 'PRODUCTS_RESET' });

      // reset lists when switching offer type
      updateOfferFormState({
        offerType: value,
        brands: value === offerHeadTypes.BRAND_OFFER ? offerCreation.step3.brands : [],
        resultListEan: [],
        image: false
      });
    }
  };

  return (
    <FormManager
      data={{
        title: t('offers_creation_block_products_title'),
        fieldsets: [
          {
            id: 'add-offer-product-step',
            fields: [
              ...(helperTextBlock || []),
              {
                type: 'RadioGroup',
                label: t('commun_selection_mode'),
                defaultValue: offerCreation.step3.offerType,
                fieldProps: {
                  listData: Object.keys(offerHeadTypes)
                    .reverse()
                    .map((el) => ({
                      label: t(`commun_by_${el}`),
                      value: el
                    }))
                },
                onFieldChange: handleOfferTypeClick,
                disabled: disabled || isEditMode,
                id: 'offerType',
                withBorder: true
              },
              {
                type: <BrandAutocomplete />,
                placeholder: t('offer_add_brand'),
                disabled: disabled,
                fieldProps: {
                  offerCreation,
                  retailerId: currentRetailer?.id,
                  supplierId,
                  updateOfferCreation
                },
                id: 'brands',
                error: offerCreation.errors.resultListBrandError,
                display: offerCreation.step3.offerType === offerHeadTypes.BRAND_OFFER
              },
              {
                type: <EanAutocomplete />,
                placeholder: t(`offers_creation_products_ean_input_placeholder`),
                onFieldChange: handleOptionClick,
                id: 'ean',
                defaultValue: offerCreation.step3.resultListEan,
                fieldProps: {
                  offerCreation,
                  onOpenDialogSearchProduct,
                  products,
                  retailerId: currentRetailer?.id,
                  settingProductLevelLabelFour: currentRetailer?.settingProductLevelLabelFour,
                  supplierId,
                  updateOfferCreation
                },
                disabled: disabled,
                error: offerCreation.errors.resultListEanError,
                display: offerCreation.step3.offerType === offerHeadTypes.PRODUCT_OFFER
              }
            ]
          }
        ]
      }}
    />
  );
};

ProductsBlock.propTypes = {
  currentRetailer: PropTypes.object,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  isEditMode: PropTypes.bool,
  offerCreation: PropTypes.object,
  onOpenDialogSearchProduct: PropTypes.func,
  products: PropTypes.object,
  supplierId: PropTypes.number,
  updateOfferCreation: PropTypes.func
};

export default memo(ProductsBlock);

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { store } from 'store';
import {
  checkRetailerHasAccessToSegmentEstimateCustomerCount,
  getAllSuppliers,
  getSegmentDetails,
  getSegmentEstimateCustomerCount,
  postSegment,
  updateOneSegment
} from 'api';
import {
  exitModalTypes,
  retailerTypes,
  segmentTypes,
  segmentUpdatingStatuses,
  segmentScopes,
  snackbarTypes
} from 'utils/constants';
import { hasAtLeastOneConditionFilled, hasAtLeastOneProductTypeConditionFilled } from 'components/SegmentManager/utils';
import { hasEmptyField, QsParse } from 'utils/global';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import DragDropFile from 'components/DragDropFile';
import FormManager from 'components/FormManager';
import GoBackLink from 'components/GoBackLink';
import ImportedFileFrame from 'components/ImportedFileFrame';
import Loader from 'components/Loaders/Dots';
import MainBlock from 'components/SegmentManager';
import SidePanel from 'components/SegmentManager/SidePanel';

import { ReactComponent as UploadIcon } from 'assets/30px_import.svg';
import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';

import styles from './SegmentManager.module.scss';

let timeoutId; // used for debouncing fetchEstimateClientCount

const fakeEstimateCustomerCount = () => {
  const min = 100000;
  const max = 500000;
  return Math.random() * (max - min) + min;
};

const initSegmentCampaign = {
  scope: segmentScopes.CAMPAIGN,
  name: '',
  description: '',
  vector: {
    operator: '$and',
    segments: []
  }
};

const initSegmentOffer = {
  scope: segmentScopes.OFFER,
  name: '',
  description: '',
  supplierId: '',
  supplierCode: '',
  archived: true,
  vector: {
    operator: '$and',
    segments: []
  }
};

/* 2 scopes : campaign or offer */
const SegmentManager = ({ scope }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const {
    dispatch,
    state: { exitModalType, suppliers, user }
  } = useContext(store);

  const initSegment = scope === segmentScopes.CAMPAIGN ? initSegmentCampaign : initSegmentOffer;

  const [details, setDetails] = useState(initSegment);
  const [hasAccessToEstimateCustomerCount, setHasAccessToEstimateCount] = useState(false);

  const segmentId = id && !isNaN(parseInt(id)) && parseInt(id);
  const isImportFileState = details?.type === segmentTypes.IMPORT || QsParse(history.location.search)?.['import-file'];

  const [initState, setInitState] = useState(
    segmentId && details?.id && details?.id === segmentId
      ? JSON.parse(JSON.stringify(details))
      : JSON.parse(JSON.stringify(isImportFileState ? { ...initSegment, vector: null } : initSegment))
  );
  const [state, setState] = useState(
    segmentId && details?.id && details?.id === segmentId
      ? JSON.parse(JSON.stringify(details))
      : JSON.parse(JSON.stringify(isImportFileState ? { ...initSegment, vector: null } : initSegment))
  );
  const [openExitPopup, setOpenExitPopup] = useState(false);
  const [openSavePopup, setOpenSavePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEstimate, setIsLoadingEstimate] = useState(false);
  const [importFileReady, setImportFileReady] = useState(null);
  const [forceReviewTab, setForceReviewTab] = useState(false);
  const [forceDisplayDragDropZone, setForceDisplayDragDropZone] = useState(false);

  const isVectorFilled = !!state.vector
    ? JSON.stringify(state.vector) !== JSON.stringify(initSegment.vector) && !hasEmptyField(state.vector)
    : true;

  const isClientType = user.type === retailerTypes.CLIENT;
  const disableSubmitButton =
    !isClientType ||
    isLoading ||
    !state.name ||
    (scope === segmentScopes.OFFER && !state.supplierCode) ||
    (!isImportFileState && !isVectorFilled) ||
    (isImportFileState && !importFileReady && !segmentId);

  const selectedSupplier =
    scope === segmentScopes.OFFER && !!suppliers.length
      ? suppliers.find(
          (supplier) => supplier.id.toString() === state.supplierId || supplier.code === state.supplierCode
        )
      : null;

  const fetchEstimateClientCount = () => {
    // debounce the api call
    timeoutId = setTimeout(async () => {
      setIsLoadingEstimate(true);
      await getSegmentEstimateCustomerCount({ ...state, retailerCode: user.code });
      setIsLoadingEstimate(false);
      setIsLoading(false);
    }, 250);
  };

  const triggerEstimateCustomerCount = (newState) => {
    // cancel previous trigger
    clearTimeout(timeoutId);
    if (
      hasAccessToEstimateCustomerCount &&
      !isLoading &&
      !isLoadingEstimate &&
      newState.vector?.segments?.length &&
      !hasEmptyField(newState.vector) &&
      !hasAtLeastOneProductTypeConditionFilled(newState.vector)
    ) {
      if (isClientType) {
        fetchEstimateClientCount();
      } else {
        dispatch({
          type: 'SEGMENTS_UPDATE',
          payload: { estimateCustomerCount: fakeEstimateCustomerCount() }
        });
      }
    } else {
      setIsLoading(false);
      setIsLoadingEstimate(false);
    }
  };

  useEffect(() => {
    if (segmentId && !isClientType) {
      historyGoBack();
    }

    return () => {
      clearTimeout(timeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchHasAccessToEstimateCustomerCount = async () => {
      const hasAccess = await checkRetailerHasAccessToSegmentEstimateCustomerCount(user.code);
      if (hasAccess) {
        setHasAccessToEstimateCount(true);
      }
    };
    // enable estimate customer count fetching data and displaying block
    // only for scope campaign for now because this data's api is not available for purchases and multipurchases conditions
    // and scope OFFER has only multipurchases conditions
    if (scope === segmentScopes.CAMPAIGN) {
      fetchHasAccessToEstimateCustomerCount();
    }
  }, [user.code, scope]);

  useEffect(() => {
    getAllSuppliers();
  }, []);

  useEffect(() => {
    const fetchSegment = async (id) => {
      setIsLoading(true);
      const segmentDetails = await getSegmentDetails(id);
      if (segmentDetails?.id) {
        setDetails(segmentDetails);
      }
    };

    if (segmentId) {
      fetchSegment(segmentId);
    } else {
      setIsLoading(false);
    }
  }, [segmentId]);

  /* launch estimateClientCount once if is in edition mode */
  useEffect(() => {
    if (segmentId && details?.id === segmentId) {
      let data = JSON.parse(JSON.stringify(details));

      // rattrapage --> fill supplierId and supplierCode fields
      if (scope === segmentScopes.OFFER && !data.supplierCode) {
        data.supplierCode = suppliers.find((sup) => sup.id.toString() === data.supplierId)?.code;
      }
      setInitState(data);
      setState(data);
      triggerEstimateCustomerCount(details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, segmentId]);

  useEffect(() => {
    dispatch({
      type: 'EXIT_MODAL_TYPE_UPDATE',
      payload: JSON.stringify(state) !== JSON.stringify(initState) ? exitModalTypes.SEGMENT : null
    });

    return () => {
      dispatch({
        type: 'EXIT_MODAL_TYPE_UPDATE',
        payload: null
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleChange = ({ key, value }) => {
    const newState = { ...state, [key]: value };

    // rattrapage --> fill supplierId and supplierCode fields
    if (key === 'supplierCode') {
      newState.supplierId = suppliers.find((supp) => supp.code === value)?.id?.toString();
    }

    setState(newState);

    if (key !== 'name' && key !== 'description' && hasAtLeastOneConditionFilled(newState.vector)) {
      setForceReviewTab(true);
    }

    // launch client estimation count
    if (key !== 'name' && key !== 'description') {
      triggerEstimateCustomerCount(newState);
    }
  };

  const handleSubmit = async () => {
    const data = { ...state, retailerCode: user.code };
    setIsLoading(true);
    const isSuccess = segmentId
      ? await updateOneSegment({ segment: data, file: importFileReady })
      : await postSegment({ segment: data, file: importFileReady });
    setIsLoading(false);

    if (isSuccess) {
      historyGoBack();
    }
  };

  const handleGoBackLink = () => {
    if (exitModalType === exitModalTypes.SEGMENT) {
      setOpenExitPopup(true);
    } else {
      historyGoBack();
    }
  };

  const handleUploadFile = async (file) => {
    if ('text/csv' === file.type) {
      setImportFileReady(file);
      setForceDisplayDragDropZone(false);
    } else {
      setImportFileReady(null);
      dispatch({
        type: 'UI_SNACKBAR',
        payload: {
          type: snackbarTypes.ERROR,
          title: { key: 'import_file_bad_format', params: { text: '.CSV' } }
        }
      });
    }
  };

  const historyGoBack = () => {
    history.push('/segmentation');
  };

  const displayDragDropZone = () => (
    <DragDropFile acceptedFileType=".csv" onDropFile={handleUploadFile}>
      <div className={styles['drag-drop-zone']}>
        <UploadIcon />
        <div>{Trans({ i18nKey: 'segmentManager_drag_drop_field_text' })}</div>
      </div>
    </DragDropFile>
  );

  const displayFileReady = () => {
    return (
      <>
        {forceDisplayDragDropZone && displayDragDropZone()}
        <div className={styles['last-file-container']}>
          <div>
            <div>{t('commun_last_import', { count: 1 })}</div>
            <ButtonsCustom
              classType="link_primary_big"
              method={() => {
                setForceDisplayDragDropZone(true);
              }}
              text={t('commun_select_new_file')}
            />
          </div>
          <ImportedFileFrame
            data={{
              errors: !importFileReady && segmentId ? details.importInfo?.errors : [],
              hasErrorFileUrl: details?.importInfo?.errorFileUrl,
              filename: importFileReady
                ? importFileReady.name
                : segmentId
                ? details.importInfo?.originalFileName || 'Segment'
                : undefined,
              requestDate: !importFileReady && segmentId ? details.importInfo?.importedAt : undefined,
              segmentId: segmentId,
              status: importFileReady ? 'SUCCESS' : details.updatingStatus
            }}
            simpleDisplay={!!importFileReady || details.updatingStatus !== segmentUpdatingStatuses.FAIL}
          />
          {importFileReady && <div className={styles['import-desc']}>{t('segmentManager_file_save_info')}</div>}
        </div>
      </>
    );
  };

  const displayRadioButtonLabel = (title, description) => (
    <div>
      <div>{title}</div>
      <small>
        <i>{description}</i>
      </small>
    </div>
  );

  const displayMainContent = () => {
    if (isLoading) return <Loader />;
    if (isImportFileState) {
      return importFileReady || segmentId ? displayFileReady() : displayDragDropZone();
    }
    if (state.vector) {
      return (
        <MainBlock
          data={state.vector}
          scope={scope}
          updateData={(value) => {
            handleChange({ key: 'vector', value });
          }}
        />
      );
    } else {
      return (
        <div className={styles['hardVector-info']}>
          <div>
            <WarningIcon />
          </div>
          <div>{t('segmentManager-hard-segment-main-block-info')}</div>
        </div>
      );
    }
  };

  const disableSubmitButtonTooltip = () => {
    if (disableSubmitButton) {
      if (isImportFileState && !importFileReady) {
        return t('segmentManager_submit_button_tooltip_import');
      } else if (!isClientType) {
        return t('segmentManager_submit_button_tooltip_not_client_type_tooltip');
      } else if (!isImportFileState && state.vector) {
        if (JSON.stringify(state.vector) === JSON.stringify(initSegment.vector) || !state.name) {
          return t('segmentManager_submit_button_tooltip');
        } else if (scope === segmentScopes.OFFER && !state.supplierCode) {
          return t('segmentManager_submit_button_tooltip_scope_offer_supplierId_missing');
        } else {
          return t('segmentManager_submit_button_tooltip_not_filled');
        }
      } else if (!state.name) {
        t('segmentManager_submit_button_tooltip_hard_segment');
      }
    } else {
      return '';
    }
  };

  return (
    <div className={styles['segmentManager']}>
      <div className={styles['main-frame']}>
        <GoBackLink customOnClick={handleGoBackLink} pathname={'#'} />
        <div className={styles['page-title']}>
          {t(
            `segmentManager_page_title_${segmentId ? 'edition' : 'creation'}${
              scope === segmentScopes.OFFER ? '_scope_offer' : ''
            }`
          )}
        </div>

        <FormManager
          data={{
            fieldsets: [
              {
                id: 'segment-manager-title-and-desc',
                fields: [
                  {
                    classNameContainer: ['custom-input-container-width-large'],
                    counter: 50,
                    fieldProps: {
                      inputProps: {
                        minLength: 1,
                        maxLength: 50
                      }
                    },
                    id: 'name',
                    defaultValue: state.name,
                    onFieldChange: (value) => {
                      handleChange({ key: 'name', value });
                    },
                    label: t('segmentManager_input_title_placeholder'),
                    type: 'TextField'
                  },
                  {
                    outerContainerClass: ['with-margin-bottom'],
                    classNameContainer: ['custom-input-container-width-huge'],
                    counter: 120,
                    fieldProps: {
                      inputProps: {
                        minLength: 0,
                        maxLength: 120
                      },
                      multiline: true
                    },
                    id: 'description',
                    defaultValue: state.description,
                    onFieldChange: (value) => {
                      handleChange({ key: 'description', value });
                    },
                    label: t('segmentManager_input_desc_placeholder'),
                    type: 'TextField'
                  }
                ]
              },
              {
                id: 'segment-manager-offer-extra-fields',
                display: scope === segmentScopes.OFFER,
                fields: [
                  {
                    outerContainerClass: ['with-margin-bottom'],
                    classnames: ['huge-input'],
                    type: 'Autocomplete',
                    id: 'supplierCode',
                    defaultValue: selectedSupplier,
                    disabled: !!segmentId, // disable in edit mode
                    fieldProps: {
                      listData: suppliers,
                      labelAttribute: 'name',
                      noOptionsText: t('commun_no_supplier_found')
                    },
                    onFieldChange: (value) => {
                      handleChange({ key: 'supplierCode', value: value?.code || null });
                    },
                    label: t('commun_supplier'),
                    placeholder: t('commun_choose')
                  },
                  {
                    label: t('segmentManager_radio_button_update_group_label'),
                    type: 'RadioGroup',
                    defaultValue: state.archived?.toString(),
                    fieldProps: {
                      listData: [
                        {
                          value: 'true',
                          label: displayRadioButtonLabel(
                            t('segmentManager_radio_button_update_segment_false_title'),
                            t('segmentManager_radio_button_update_segment_false_desc')
                          )
                        },
                        {
                          value: 'false',
                          label: displayRadioButtonLabel(
                            t('segmentManager_radio_button_update_segment_true_title'),
                            t('segmentManager_radio_button_update_segment_true_desc')
                          )
                        }
                      ]
                    },
                    onFieldChange: (value) => {
                      handleChange({ key: 'archived', value: value === 'true' });
                    },
                    id: 'radio-group-archived',
                    withBorder: true,
                    fullWidth: true
                  }
                ]
              }
            ]
          }}
        />

        {displayMainContent()}

        <div className={styles['save-button-block']}>
          <ButtonsCustom
            classType="canceled"
            text={t('commun_button_cancel')}
            method={handleGoBackLink}
            disabled={isLoading}
          />

          <ButtonsCustom
            classType="action_primary_big"
            disabled={disableSubmitButton}
            method={() => {
              setOpenSavePopup(true);
            }}
            text={t('commun_button_save')}
            tooltip={disableSubmitButtonTooltip()}
          />
        </div>
      </div>

      <SidePanel
        forceReviewTab={forceReviewTab}
        hasAccessToEstimateCustomerCount={hasAccessToEstimateCustomerCount}
        isLoadingEstimate={isLoadingEstimate}
        isVectorFilled={isVectorFilled}
        segment={state}
      />

      <ContentDialog
        centerText
        isLoading={isLoading}
        isOpen={!!openSavePopup}
        handleClose={() => {
          setOpenSavePopup(false);
        }}
        title={t(segmentId ? 'segment_update_confirm_title' : 'segment_save_confirm_title')}
        maxWidth="xs"
      >
        <ButtonsCustom
          classType="canceled"
          text={t('commun_button_no')}
          method={() => setOpenSavePopup(false)}
          disabled={isLoading}
        />
        <ButtonsCustom
          classType="action_primary_big"
          text={t('commun_button_yes_continue')}
          method={handleSubmit}
          loading={isLoading}
        />
      </ContentDialog>
      <ContentDialog
        centerText
        isLoading={isLoading}
        isOpen={!!openExitPopup}
        handleClose={() => {
          setOpenExitPopup(false);
        }}
        title={t('segmentManager_leave_modal_message')}
        maxWidth="xs"
      >
        <ButtonsCustom
          classType="canceled"
          text={t('commun_button_cancel')}
          method={() => {
            setOpenExitPopup(false);
          }}
          disabled={isLoading}
        />
        <ButtonsCustom
          classType="action_primary_big"
          text={t('commun_button_continue')}
          method={historyGoBack}
          disabled={isLoading}
        />
      </ContentDialog>
    </div>
  );
};

SegmentManager.propTypes = {
  scope: PropTypes.oneOf(Object.keys(segmentScopes))
};

export default SegmentManager;

import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { getExternalSupplierProducts } from 'api';

import CustomAccordion from 'components/Accordion';
import TableCustom from 'components/TableCustom';

import styles from './ProductsList.module.scss';

const ProductsList = ({ reference }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState({ list: [], total: 0 });
  const [page, setPage] = useState(0);
  const tableSize = 10;

  const getParams = useMemo(
    () => ({
      page,
      size: tableSize
    }),
    [page]
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await getExternalSupplierProducts({
        ...getParams,
        externalSupplierId: reference.id,
        retailer: reference.retailer?.id
      });

      setProducts(result);
      setIsLoading(false);
    };
    fetchData();
  }, [reference.id, reference.retailer?.id, getParams]);

  const columns = [
    { id: 1, field: 'ean' },
    { id: 2, field: 'label', type: 'text' }
  ];

  const rows = products.list.map((element) => ({
    id: [{ value: element.id }],
    ean: [{ value: element.ean, color: 'ean', textStyle: 'tag' }],
    label: [{ value: element.label }]
  }));

  return (
    <div className={styles['container']}>
      <CustomAccordion
        summary={
          <div className={styles['container-title']}>
            {reference.name} <strong>#{reference.code}</strong>
          </div>
        }
      >
        <TableCustom
          type="multiLists"
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          total={products.total}
          page={page}
          size={tableSize}
          prev={() => setPage(page - 1)}
          next={() => setPage(page + 1)}
          showHeader={false}
        />
      </CustomAccordion>
    </div>
  );
};

ProductsList.propTypes = {
  reference: PropTypes.object
};

export default ProductsList;

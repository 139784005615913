import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from 'components/SwitchCustom';

const SwitchField = (props) => {
  const {
    data: { id, switchLabels = {}, defaultValue = false, fieldProps, disabled },
    onChange
  } = props;

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) onChange(id, value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleChange = ({ target: { checked } }) => {
    setValue(checked);
  };

  return (
    <div>
      <FormControlLabel
        control={<Switch checked={value} disabled={disabled} name={id} onChange={handleChange} {...fieldProps} />}
        label={value ? switchLabels.on : switchLabels.off}
      />
    </div>
  );
};

SwitchField.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func
};

export default SwitchField;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import SelectCustom from 'components/SelectCustom';
import TextFieldCustom from 'components/TextFieldCustom';

import { checkNumber } from 'utils/global';

import styles from './Generosity.module.scss';

const Generosity = (props) => {
  const {
    className,
    data: { id, defaultValue, disabled, placeholder, fieldProps },
    onChange,
    error,
  } = props;

  const [state, setState] = useState(
    defaultValue || {
      value: '',
      unit: 1,
    }
  );

  useEffect(() => {
    if (defaultValue) {
      setState(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) onChange(id, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value, state.unit]);

  const handleGenerosityChange = ({ target: { value } }) => {
    if (checkNumber(value) || value === '') {
      const payload = { ...state, value };
      setState(payload);
    }
  };

  const handleGenerosityBlur = ({ target: { value } }) => {
    const payload = { ...state, value: value === '0' ? '' : value.replace(',', '.') };
    setState(payload);
  };

  const handleUnitChange = ({ target: { value } }) => {
    const payload = { ...state, unit: value };
    setState(payload);
  };

  return (
    <>
      <div className={`${styles['field']} ${className}`}>
        <TextFieldCustom
          label={placeholder}
          type="text"
          onChange={handleGenerosityChange}
          onBlur={handleGenerosityBlur}
          value={state.value}
          name={id}
          id={`${id}-value`}
          error={!!error}
          disabled={disabled}
        />
        <SelectCustom
          selectClassName={styles['customized-select-selector']}
          className={styles['customized-select']}
          id={`${id}-unit`}
          methodOnChange={handleUnitChange}
          value={state.unit}
          error={!!error}
          disabled={disabled}
          shrinkPlaceholder={false}
          {...fieldProps}
        />
      </div>
      {error && typeof error === 'string' && <div className={styles['error']}>{error}</div>}
    </>
  );
};

Generosity.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func,
};

export default Generosity;

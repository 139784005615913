import React from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { withFractionDigits } from 'utils/global';

import styles from './CampaignParametersTable.module.scss';

const CampaignParametersTable = ({ parameters, settingProductLevelLabelThree }) => {
  const { t } = useTranslation();

  const config = [
    {
      id: 'offersMinimumNumber',
      data: parameters.offersMinimumNumber,
      label: t('campaign_product_step4_parameters_min_offers')
    },
    {
      id: 'offersMaximumNumber',
      data: parameters.offersMaximumNumber,
      label: t('campaign_product_step4_parameters_max_offers')
    },
    {
      id: 'offersNumberByCategory',
      data: parameters.offersNumberByCategory,
      label: t('campaign_product_step4_parameters_max_category', {
        text: settingProductLevelLabelThree
      })
    }
  ];
  return (
    <div className={styles['parameters']}>
      <div className={styles['parameters-title']}>{t('campaign_product_step4_parameters_title')}</div>
      {config.map((el, index) => (
        <div
          key={el.id}
          className={`${styles['parameters-info']} ${index % 2 === 1 ? styles['parameters-info-odd'] : ''}`}
        >
          <div className={styles['parameters-info-label']}>{el.label}</div>
          <div className={styles['parameters-info-data']}>
            {el.isPercent
              ? `${(parameters?.[el.id] * 100).toFixed(
                  withFractionDigits(parameters?.[el.id] * 100) ? el.maximumFractionDigits : 0
                )} %`
              : parameters?.[el.id]}
          </div>
        </div>
      ))}
    </div>
  );
};

CampaignParametersTable.propTypes = {
  parameters: PropTypes.object,
  settingProductLevelLabelThree: PropTypes.string
};

export default CampaignParametersTable;

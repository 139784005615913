import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ArrowErrorIcon from 'assets/triangle_error.svg';
import ArrowIcon from 'assets/triangle.svg';
import styles from './BudgetSummary.module.scss';

const BudgetSummary = ({ budgetLeftAfterCreation, currencyCode, freeBudget }) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles['root']} ${(budgetLeftAfterCreation < 0 || !freeBudget) && styles['error']}`}>
      <div className={styles['left']}>
        <label>{t('offers_creation_budget_before')}</label>
        <div className={styles['amount']}>
          {t('commun_price', { value: Math.max(0, freeBudget), currency: currencyCode })}
        </div>
      </div>
      <div className={styles['arrow']}>
        <img alt="" src={budgetLeftAfterCreation < 0 || !freeBudget ? ArrowErrorIcon : ArrowIcon} />
      </div>
      <div className={styles['right']}>
        <label>
          {budgetLeftAfterCreation >= 0 && !!freeBudget
            ? t('offers_creation_budget_after')
            : t('offers_creation_budget_insufficient')}
        </label>
        {budgetLeftAfterCreation >= 0 && !!freeBudget && (
          <div className={styles['amount']}>
            {t('commun_price', { value: budgetLeftAfterCreation, currency: currencyCode })}
          </div>
        )}
      </div>
    </div>
  );
};
BudgetSummary.propTypes = {
  budgetLeftAfterCreation: PropTypes.number,
  currencyCode: PropTypes.string,
  freeBudget: PropTypes.number,
};
export default memo(BudgetSummary);

import React, { useContext, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { store } from 'store';
import { getAllSupplierBudgets, postOffer, updateOffer } from 'api';
import { checkAddOfferFields } from 'pages/AddOffer/checkers';
import { formatFloat, getFirstValidityStartDate, getLastValidityEndDate } from 'utils/global';
import { discountTypes, offerHeadTypes, offerStatuses } from 'utils/constants';
import moment from 'moment-timezone';
import { oldSuffix } from '../TargetingStrategiesBlock';

import { initialObj } from 'pages/AddOffer/formHelper';
import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';

import { ReactComponent as WarningIcon } from 'assets/24px_alert_round_yellow.svg';

import styles from './AddOfferStepValidation.module.scss';

const AddOfferStepValidation = ({
  budgetData,
  currentRetailer,
  goBackToParametersStep,
  goToBudgetStep,
  isBudgetStep,
  isEditMode,
  isSuperEditable,
  initialOfferCreationToDuplicate,
  offerConstraints,
  offerCreation,
  onOfferSaved,
  resultListBrandRaw,
  resultListEanRaw,
  updateOfferCreation,
  validityDatesListRaw
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalState = useContext(store);
  const {
    state: { offers, queryFilters, supplierBudgetsAll, user, targetingStrategies }
  } = globalState;
  const { discountUnits } = user;
  const {
    details: { offer: offerDetails, offerCampaigns }
  } = offers;
  const hasLinkedCampaigns = !!offerCampaigns.length;

  const [dialogOpened, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogAllInputsAreSameOpened, setDialogAllInputsAreSameOpened] = useState(false);
  const [dialogAllInputsButPeriodsAreSameOpened, setDialogAllInputsButPeriodsAreSameOpened] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());

  const { step1, step2, step3, step4 } = offerCreation;
  const targetingStrategyId = step2.targetingStrategy.id;
  const externalSegmentId = step2.externalSegment.id;

  const {
    additionalInformation,
    quantity,
    numberMax,
    tag,
    title,
    generosity,
    resultListEan,
    offerType,
    discountType,
    brands
  } = step3;
  const { supplierBill, budget, budgetType, isLoadingRecommendation, recommendation, validityDates } = step4;

  const isSupplier = user?.userType === 'supplier';
  const retailerId = currentRetailer?.id;
  const supplierId = isSupplier ? step1.multiSupplierId || user.id : step1.id;
  const addOfferItemsChecked = checkAddOfferFields(
    isBudgetStep,
    {
      offerCreation,
      freeBudget: budgetData?.free || 0,
      offerDetails,
      discountUnits,
      discountTypes,
      offerConstraints,
      validityDatesListRaw,
      targetingStrategies
    },
    t
  );

  const isDisabledBecauseOfBudgetOrRecommendation = isBudgetStep ? isLoadingRecommendation || !budget : false;

  // fetch budget when inputs change
  useEffect(() => {
    if (!retailerId || !supplierId) return;

    const fetchSupplierBudgets = async () => {
      const supplierBudgetsAll = await getAllSupplierBudgets({
        retailerId: retailerId,
        supplierIds: supplierId,
        year
      });

      updateOfferCreation({
        step4: {
          budgetType: supplierBudgetsAll
            ? offerDetails?.offerHead?.budget?.type
              ? offerDetails?.offerHead?.budget?.type
              : supplierBudgetsAll?.length === 1
              ? supplierBudgetsAll?.[0]?.type
              : ''
            : offerCreation.step4.budgetType,
          isLoadingSupplierBudgets: false
        }
      });
    };

    updateOfferCreation({
      step4: {
        isLoadingSupplierBudgets: true
      }
    });

    fetchSupplierBudgets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailerId, supplierId, year]);

  useEffect(() => {
    const startDate = getFirstValidityStartDate(validityDates);
    startDate && setYear(startDate.year());
  }, [validityDates]);

  // used before submiting for checking if algo data specific inputs have changed
  const stringifyAllNeededAlgoDataPropertiesForComparison = (offer) => {
    return JSON.stringify({
      discount: offer.step3.generosity,
      periods: offer.step4.validityDates,
      products: offer.step3.resultListEan,
      quantity: offer.step3.quantity,
      retailer: currentRetailer.code,
      targetingStrategyId: offer.step2.targetingStrategy.id.toString(),
      externalSegmentId: offer.step2.externalSegment.id
    });
  };

  // used before submiting for checking if algo data specific inputs have changed
  const stringifyAllNeededButPeriodsAlgoDataPropertiesForComparison = (offer) => {
    return JSON.stringify({
      discount: offer.step3.generosity,
      products: offer.step3.resultListEan,
      quantity: offer.step3.quantity,
      retailer: currentRetailer.code,
      targetingStrategyId: offer.step2.targetingStrategy.id.toString(),
      externalSegmentId: offer.step2.externalSegment.id
    });
  };

  const addErrorInputState = () => {
    const arr = {};
    addOfferItemsChecked.forEach((i) => (arr[i.errorName] = !i.value));

    updateOfferCreation({
      errors: {
        ...initialObj?.errors,
        ...arr
      }
    });
  };

  /* apply rules from https://docs.google.com/spreadsheets/d/13rwLh0QaAK3t54axGWo4BCTBG1hc2uRhwzhSYlbNmzo/edit?pli=1#gid=0 */
  const getNewOfferStatus = () => {
    let status = offerDetails.status;

    const products = resultListEan.map((i) => i.id).sort();
    const productsRaw = resultListEanRaw.map((i) => i.id).sort();

    const brandList = brands.map((i) => i.id).sort();
    const brandListRaw = resultListBrandRaw.map((i) => i.id).sort();

    const productsHasChanged =
      step3.offerType === offerHeadTypes.BRAND_OFFER
        ? brandListRaw.length !== brandList.length || !brandListRaw.every((v, i) => v === brandList[i])
        : productsRaw.length !== products.length || !productsRaw.every((v, i) => v === products[i]);

    const discountUnitOfferDetails = offerDetails.discountUnit === 'PERCENT' ? 1 : 2;
    const discountTypeOfferDetails = offerDetails.discountType === 'LOYALTY' ? 1 : 2;
    const startDateOfferDetails = moment(offerDetails.offerHead.validityStartDate).toISOString();
    const endDateOfferDetails = moment(offerDetails.offerHead.validityEndDate).toISOString();

    if (
      targetingStrategyId !== offerDetails.targetingStrategy.id.toString() ||
      externalSegmentId !== offerDetails.offerHead.externalSegment?.id ||
      offerType !== offerDetails.offerHead.type ||
      generosity.value !== offerDetails.discountValue ||
      generosity.unit !== discountUnitOfferDetails ||
      discountType.id !== discountTypeOfferDetails ||
      quantity.value !== offerDetails.discountMinQuantityOfProducts ||
      numberMax.value !== offerDetails.discountMaximumUses ||
      getFirstValidityStartDate(validityDates).toISOString() !== startDateOfferDetails ||
      getLastValidityEndDate(validityDates).toISOString() !== endDateOfferDetails ||
      productsHasChanged
    ) {
      // suppliers rules
      if (isSupplier) {
        if (offerDetails.status === offerStatuses.VALIDATED && !hasLinkedCampaigns) {
          status = offerStatuses.TO_VALIDATE;
        }
      } else {
        // retailers rules
        if (
          offerDetails.status === offerStatuses.TO_VALIDATE ||
          (offerDetails.status === offerStatuses.VALIDATED && !hasLinkedCampaigns)
        ) {
          status = offerStatuses.PROPOSAL;
        }
      }
    }
    return status;
  };

  const checkErrorAndSubmit = async () => {
    if (isLoading) return false;

    setIsLoading(true);
    let currentBudgetData = null;

    if (step1.id) {
      currentBudgetData = await getAllSupplierBudgets({
        retailerId: retailerId,
        supplierIds: supplierId,
        year
      });
      if (!currentBudgetData?.[0]) {
        setIsLoading(false);
        return;
      }
    }

    // check input errors and put them into addOfferItemsChecked
    addErrorInputState();

    if ((!isEditMode && !currentBudgetData.length) || addOfferItemsChecked.length) {
      setDialogOpen(true);
      setIsLoading(false);
      return;
    } else if (isBudgetStep) {
      // check has errors
      if (
        (!isEditMode && currentBudgetData && currentBudgetData.find((b) => b.type === budgetType)?.free < budget) ||
        addOfferItemsChecked.length
      ) {
        setDialogOpen(true);
        setIsLoading(false);
        return;
      }

      // check if is duplicating and new offer is same as initial offer
      if (!!Object.keys(initialOfferCreationToDuplicate).length) {
        // all properties are the same
        if (
          stringifyAllNeededAlgoDataPropertiesForComparison(initialOfferCreationToDuplicate) ===
          stringifyAllNeededAlgoDataPropertiesForComparison(offerCreation)
        ) {
          setDialogAllInputsAreSameOpened(true);
          setIsLoading(false);
          return;
        }

        // all properties BUT periods are the same
        if (
          stringifyAllNeededButPeriodsAlgoDataPropertiesForComparison(initialOfferCreationToDuplicate) ===
          stringifyAllNeededButPeriodsAlgoDataPropertiesForComparison(offerCreation)
        ) {
          setDialogAllInputsButPeriodsAreSameOpened(true);
          setIsLoading(false);
          return;
        }
      }

      // everything is OK
      handleSubmit();
    } else {
      // form goes from parameters step to budget step (step 1 -> step 2)
      goToBudgetStep();
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    const validityDatesList = validityDates?.filter((dates) => !!dates.startDate && !!dates.endDate);
    const offer = {
      ...offerDetails,
      billedToSupplier: isSupplier || supplierBill,
      discountCurrency: null,
      discountMaximumUses: numberMax.value,
      discountMinQuantityOfProducts: quantity.value,
      discountType: discountTypes.find((i) => i.id === discountType.id)?.denomination,
      discountUnit: discountUnits.find((i) => i.id === generosity.unit)?.denomination,
      discountValue: formatFloat(generosity.value),
      offerHead: {
        ...offerDetails.offerHead,
        additionalInformation: additionalInformation.value,
        budget: {
          id: budgetData.id
        },
        budgetEstimated: recommendation,
        budgetReserved: 0,
        budgetSpent: 0,
        budgetTarget: budget,
        descriptionTag: tag.value || '',
        externalSegment: externalSegmentId ? { id: externalSegmentId } : undefined,
        fileDescriptor: step3.image.id ? step3.image : { id: '' },
        imageMediumFileDescriptor: offerDetails?.offerHead?.imageMediumFileDescriptor || { id: '' },
        imageRetailerFileDescriptor: offerDetails?.offerHead?.imageRetailerFileDescriptor || { id: '' },
        title: title.value,
        type: step3.offerType,
        validityEndDate: getLastValidityEndDate(validityDatesList).toISOString(),
        validityStartDate: getFirstValidityStartDate(validityDatesList).toISOString()
      },
      retailer: { id: retailerId },
      status: isSupplier ? offerStatuses.TO_VALIDATE : offerStatuses.PROPOSAL,
      targetingStrategy: { id: parseInt(step2.targetingStrategy.id?.toString()?.replace(oldSuffix, '')) } // remove "_old" suffix added in TargetingStrategiesBlock component
    };
    delete offer.croppedImageMetadata;

    const cropData = step3.cropData || null;
    const products = resultListEan.map((i) => i.id);
    const brandList = brands.map((i) => i.id);

    let newOffer;
    if (isEditMode) {
      offer.id = offerDetails.id;
      offer.status = getNewOfferStatus();
      offer.retailer = { id: retailerId, code: offerDetails.retailer.code };

      newOffer = await updateOffer({
        cropData,
        brandList,
        file: offerCreation.step3.image,
        isSuperEditable: isSuperEditable,
        offer,
        products,
        resultListBrandRaw,
        resultListEanRaw,
        validityDatesList,
        validityDatesListRaw
      });
    } else {
      newOffer = await postOffer({
        file: offerCreation.step3.image,
        cropData,
        offer,
        products,
        brandList,
        retailerCode: currentRetailer.code,
        validityDatesList
      });
    }
    setIsLoading(false);

    if (newOffer) {
      onOfferSaved?.(newOffer.offerHead.id);
    }
  };

  return (
    <>
      <div className={clsx(styles['bottom-buttons-container'], isBudgetStep && styles['flex-space-between'])}>
        {isBudgetStep && goBackToParametersStep && (
          <ButtonsCustom
            classType="action_tertiary"
            text={t('commun_edit_parameters')}
            method={goBackToParametersStep}
            disabled={isLoading}
          />
        )}
        <ButtonsCustom
          classType="action_primary_big"
          disabled={
            !step1.id ||
            supplierBudgetsAll.length === 0 ||
            isDisabledBecauseOfBudgetOrRecommendation ||
            (isBudgetStep && !budgetData?.id)
          }
          text={isBudgetStep ? t('offers_creation_save_offer') : t('commun_save')}
          method={checkErrorAndSubmit}
          loading={isLoading}
          tooltip={
            step1.id && (!budgetData?.id || supplierBudgetsAll.length === 0)
              ? t('offers_creation_no_supplier_budget_frame', { year })
              : ''
          }
        />
      </div>

      <ContentDialog
        centerText
        isOpen={dialogOpened}
        handleClose={() => setDialogOpen(false)}
        title={t('offers_creation_incomplete_form')}
        desc={
          <div>
            {addOfferItemsChecked.map((i) => (
              <p key={i.name}>{i.name}</p>
            ))}
          </div>
        }
      >
        <div>
          <ButtonsCustom
            classType="action_primary_big"
            text={t('commun_button_continue')}
            method={() => setDialogOpen(false)}
          />
        </div>
      </ContentDialog>
      <ContentDialog
        centerText
        maxWidth="xs"
        isOpen={dialogAllInputsAreSameOpened}
        handleClose={() => {
          if (!isLoading) setDialogAllInputsAreSameOpened(false);
        }}
        title={
          <>
            <WarningIcon />
            <br />
            <br />
            <div>{t('offers_creation_duplication_dialog_same_offer_title')}</div>
          </>
        }
        desc={
          <>
            <div>{t('offers_creation_duplication_dialog_same_offer_desc_1')}</div>
            <br />
            <div>{t('_dyn_campaign_product_step2_form_strategyInput')}</div>
            <div>{t('commun_product_list')}</div>
            <div>{t('commun_generosity')}</div>
            <div>{t('offers_creation_quantity_to_add')}</div>
            <div>{t('offers_creation_discount_mechanism_type')}</div>
            <div>{t('offers_creation_max_number_use')}</div>
            <div>{t('commun_validation_dates')}</div>
            <br />
            <div>{t('offers_creation_duplication_dialog_same_offer_desc_2')}</div>
          </>
        }
      >
        <div className={styles['dialog-duplication-footer']}>
          <ButtonsCustom
            classType="canceled_small"
            disabled={isLoading}
            text={<Trans i18nKey={'offers_creation_duplication_confirm_button_label'} />}
            method={handleSubmit}
          />
          <ButtonsCustom
            classType="action_primary_big"
            disabled={isLoading}
            text={t('commun_edit_offer')}
            method={() => {
              setDialogAllInputsAreSameOpened(false);
              goBackToParametersStep();
            }}
          />
        </div>
      </ContentDialog>
      <ContentDialog
        centerText
        isOpen={dialogAllInputsButPeriodsAreSameOpened}
        handleClose={() => {
          if (!isLoading) setDialogAllInputsButPeriodsAreSameOpened(false);
        }}
        title={
          <>
            <WarningIcon />
            <br />
            <br />
            <div>{t('offers_creation_duplication_dialog_same_offer_but_dates_title')}</div>
          </>
        }
        desc={t('offers_creation_duplication_dialog_same_offer_but_dates_desc')}
      >
        <div className={styles['dialog-duplication-footer']}>
          <ButtonsCustom
            classType="canceled_small"
            disabled={isLoading}
            text={t('commun_creation_resume')}
            method={handleSubmit}
          />
          <ButtonsCustom
            classType="action_primary_big"
            disabled={isLoading}
            text={t('commun_edit_offer_initial')}
            method={() => {
              history.replace({
                pathname: `/offers/add-offer/${history.location.offerIdToClone}`,
                search: queryFilters['/offers/offersProductsSupplier']
              });
              document.location.reload();
            }}
          />
        </div>
      </ContentDialog>
    </>
  );
};

AddOfferStepValidation.propTypes = {
  budgetData: PropTypes.object,
  currentRetailer: PropTypes.object,
  goBackToParametersStep: PropTypes.func,
  goToBudgetStep: PropTypes.func,
  isBudgetStep: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isSuperEditable: PropTypes.bool,
  offerConstraints: PropTypes.array,
  offerCreation: PropTypes.object,
  onOfferSaved: PropTypes.func,
  initialOfferCreationToDuplicate: PropTypes.object,
  resultListBrandRaw: PropTypes.array,
  resultListEanRaw: PropTypes.array,
  updateOfferCreation: PropTypes.func,
  validityDatesListRaw: PropTypes.array
};

export default memo(AddOfferStepValidation);

import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { store } from 'store';
import { snackbarTypes } from 'utils/constants';
import { getAllSuppliers, getDraftSuppliers, validSuppliers, deleteSupplier } from 'api';

import AddSupplierForm from 'components/AddSupplierForm';
import Backdrop from '@mui/material/Backdrop';
import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import GoBackLink from 'components/GoBackLink';
import UserIconList from 'components/UserIconList';

import { ReactComponent as DeleteIcon } from 'assets/16px_trash.svg';
import { ReactComponent as Pencil } from 'assets/16px_edit.svg';

import styles from './AddSupplier.module.scss';

const AddSupplier = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { user } = state;
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [draftList, setDraftList] = useState([]);
  const [draftListLoading, setDraftListLoading] = useState([]);
  const [disableDel, setDisableDel] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDialogConfim = useCallback(() => {
    history.push('/suppliers');
  }, [history]);

  const [draftToEdit, setDraftToEdit] = useState();
  const draftMaxLimit = 20;

  const fetchSuppliers = async () => {
    setDraftListLoading(true);
    const draftSupplier = await getDraftSuppliers({ retailerId: user.id });

    setDraftList(draftSupplier);
    setDraftListLoading(false);
  };

  useEffect(() => {
    fetchSuppliers();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (draftList.length >= draftMaxLimit) {
      dispatch({
        type: 'UI_SNACKBAR',
        payload: {
          type: snackbarTypes.WARNING,
          title: { key: 'supplier_warning_limit_snackbar_title' },
          desc: { key: 'supplier_warning_limit_snackbar_desc' },
        },
      });
    }
    //eslint-disable-next-line
  }, [draftList.length]);

  const handleFinishEdit = async () => {
    // order matters
    await fetchSuppliers();
    setDraftToEdit();
  };

  const handleCancel = () => {
    setDraftToEdit();
  };

  const handleEditButton = (drf) => {
    setDraftToEdit(drf);
  };

  const handleDeleteButton = async () => {
    setLoadingDelete(true);
    const response = await deleteSupplier(disableDel);
    if (response) fetchSuppliers();
    setDisableDel(false);
    setOpenDialogDelete(false);
    setLoadingDelete(false);
  };

  const handleSubmitSuppliersBulk = async () => {
    setIsSubmitting(true);
    const bulkValidationOK = await validSuppliers({ supplierIds: draftList.map((i) => i.id) });
    await getAllSuppliers(user.id);
    if (bulkValidationOK) {
      history.push('/suppliers');
    } else {
      setIsSubmitting(false);
    }
  };

  const displaySkeletonDraftList = [...Array(3).keys()].map((i) => (
    <div className={`${styles['draft-skeleton']} ${draftListLoading ? styles['loading'] : ''}`} key={i}>
      <div className={styles['draft-skeleton-left-side']}>
        <span />
        <span />
        <span />
        <span />
      </div>
      <div className={styles['draft-skeleton-right-side']}>
        <div className={styles['draft-skeleton-right-side-top']}>
          <span />
          <span />
        </div>
        <div className={styles['draft-skeleton-right-side-bottom']}>
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  ));

  const displayDraftList = (
    <>
      <div className={styles['draft-counter']}>
        <span>{draftList.length} </span>
        {`/ ${draftMaxLimit}`}
      </div>
      {draftList.map((i) => {
        return (
          <div className={`${styles['draft']} ${draftToEdit?.id === i.id && styles['selected']}`} key={i.id}>
            <div className={styles['draft-left-side']}>
              <div className={styles['draft-title']}>{i.name}</div>
              <div className={styles['draft-info']}>
                {i.productsCount} {t('supplier_products', { count: i.productsCount })}
              </div>

              <div className={styles['draft-external-supplier-container']}>
                {i.externalSuppliers.map((ext) => (
                  <div className={styles['draft-budget-list']} key={ext.id}>
                    {ext.name}
                  </div>
                ))}
              </div>

              <div className="d-flex">
                {i.budgets.map((budget) => {
                  let b = null;
                  if (budget.budgetN.total && budget.budgetN.year && budget.budgetN1.total && budget.budgetN1.year) {
                    b = (
                      <div>{`${budget.budgetN.year} : ${t('commun_price', {
                        value: budget.budgetN.total,
                        currency: user.currency.code,
                      })} | ${budget.budgetN1.year} : ${t('commun_price', {
                        value: budget.budgetN1.total,
                        currency: user.currency.code,
                      })}`}</div>
                    );
                  } else if (budget.budgetN.total && budget.budgetN.year) {
                    b = (
                      <div>{`${budget.budgetN.year} : ${t('commun_price', {
                        value: budget.budgetN.total,
                        currency: user.currency.code,
                      })}`}</div>
                    );
                  } else if (budget.budgetN1.total && budget.budgetN1.year) {
                    b = (
                      <div>{`${budget.budgetN1.year} : ${t('commun_price', {
                        value: budget.budgetN1.total,
                        currency: user.currency.code,
                      })}`}</div>
                    );
                  }

                  if (b) {
                    return (
                      <div className={styles['draft-budget-list']} key={budget.type}>
                        <div className={styles['type']}>{t(`commun_budget_type_${budget.type}_long`)}</div>
                        {b}
                      </div>
                    );
                  } else return false;
                })}
              </div>
            </div>
            <div className={styles['draft-right-side']}>
              <div className={styles['draft-action-buttons']}>
                <ButtonsCustom
                  key="draft-edit-button"
                  classType="action"
                  disabled={draftToEdit?.id === i.id}
                  id={`draft_edit_button_${i.id}`}
                  method={() => handleEditButton(i)}
                  startIconCustom={<Pencil />}
                />
                <ButtonsCustom
                  key="draft-delete-button"
                  classType="action"
                  disabled={disableDel === i.id || draftToEdit?.id === i.id}
                  id={`draft_delete_button_${i.id}`}
                  method={() => {
                    setDisableDel(i.id);
                    setOpenDialogDelete(true);
                  }}
                  startIconCustom={<DeleteIcon />}
                />
              </div>
              <UserIconList userCount={i.userSuppliersCount} />
            </div>
          </div>
        );
      })}
      <div className={styles['draft-button-container']}>
        <ButtonsCustom
          classType="canceled"
          disabled={isSubmitting}
          id={'cancel_button'}
          text={t('commun_button_cancel')}
          method={() => setOpenDialog(true)}
        />
        <ButtonsCustom
          classType="action_primary_big"
          disabled={isSubmitting}
          id={'confirm_button'}
          text={t('suppliers_validation')}
          method={handleSubmitSuppliersBulk}
        />
      </div>
      <ContentDialog
        centerText
        isOpen={openDialog}
        handleClose={() => setOpenDialog(false)}
        title={t('suppliers_list_leave_form')}
        maxWidth="xs"
      >
        <div className="confirm-dialog">
          <ButtonsCustom
            classType="canceled"
            id={'dialog_cancel_button'}
            text={t('commun_button_cancel')}
            method={() => setOpenDialog(false)}
          />
          <ButtonsCustom
            classType="action_primary_big"
            id={'dialog_confirm_button'}
            text={t('commun_button_continue')}
            method={handleDialogConfim}
          />
        </div>
      </ContentDialog>
      <ContentDialog
        centerText
        isOpen={openDialogDelete}
        handleClose={() => {
          setDisableDel();
          setOpenDialogDelete(false);
        }}
        title={t('suppliers_list_delete_dialog_title')}
        maxWidth="xs"
      >
        <div className="confirm-dialog">
          <ButtonsCustom
            classType="canceled"
            disabled={loadingDelete}
            id={'dialog_cancel_button'}
            text={t('commun_button_cancel')}
            method={() => {
              setDisableDel();
              setOpenDialogDelete(false);
            }}
          />
          <ButtonsCustom
            classType="action_primary_big"
            loading={loadingDelete}
            id={'dialog_confirm_button'}
            text={t('commun_button_continue')}
            method={handleDeleteButton}
          />
        </div>
      </ContentDialog>
    </>
  );

  return (
    <section className={styles['root']}>
      <div className={styles['left-side']}>
        <GoBackLink pathname={`/suppliers`} id="add_supplierList-goBack-button" />
        <div className={styles['top-wrapper']}>
          <div className={styles['header-group']}>
            <div className={styles['header-title']}>{t('supplier_add')}</div>
            <div className={styles['header-desc-small']}>
              {t('supplier_desc1')} {t('supplier_desc2')}
            </div>
          </div>
        </div>
        <div className={styles['draft-list']}>{draftList.length ? displayDraftList : displaySkeletonDraftList}</div>
      </div>
      <Backdrop className={styles['overlay']} open={!!draftToEdit}></Backdrop>
      <div className={styles['right-side']}>
        <AddSupplierForm
          disabled={draftList.length >= draftMaxLimit && !draftToEdit}
          onCancel={handleCancel}
          onFinish={handleFinishEdit}
          supplier={draftToEdit ? JSON.parse(JSON.stringify(draftToEdit)) : undefined}
          title={draftToEdit ? t(`supplier_edit`) : t(`supplier_create`)}
          triggerChangeKeyForm={!draftListLoading}
        />
      </div>
    </section>
  );
};

export default AddSupplier;

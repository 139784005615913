import React, { useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { checkNumberNotNull, getAveragePriceFromProducts, withFractionDigits } from 'utils/global';
import { discountTypes } from 'utils/constants';

import FormManager from 'components/FormManager';
import InputAdornment from 'components/InputAdornment';
import HelperText from 'components/HelpText';
import SummaryText from './SummaryText';

import './PromotionBlock.scss';

const PromotionBlock = ({
  currency,
  currentRetailer,
  disabled,
  helperText,
  isEditMode,
  offerCreation,
  offerConstraints,
  updateOfferCreation
}) => {
  const { t } = useTranslation();

  const combinedGuidelineText = useMemo(() => {
    let averagePriceText;
    const averagePriceFromProducts = getAveragePriceFromProducts(offerCreation.step3.resultListEan);
    if (averagePriceFromProducts) {
      averagePriceText = t('offers_creation_average_price', {
        value: averagePriceFromProducts ?? '-',
        maximumFractionDigits: withFractionDigits(averagePriceFromProducts),
        currency: currency.code
      });
    } else {
      averagePriceText = t('offers_creation_average_price_none');
    }

    return `<div>${averagePriceText}</div>${helperText ?? ''}`;
  }, [currency, helperText, offerCreation.step3.resultListEan, t]);

  const discountUnits = useMemo(() => {
    return [
      { id: 1, label: '%', denomination: 'PERCENT' },
      { id: 2, label: currency.symbol, denomination: 'CURRENCY' }
    ];
  }, [currency.symbol]);

  const existingDiscountTypeConstraint = offerConstraints.find((el) => el.target === 'discountType') || {
    selectedValues: ['LOYALTY', 'IMMEDIATE']
  };

  const existingDiscountUnitConstraint = offerConstraints.find((el) => el.target === 'discountUnit') || {
    selectedValues: ['PERCENT', 'CURRENCY']
  };

  const defaultDiscountType = isEditMode
    ? offerCreation.step3.discountType?.id
    : existingDiscountTypeConstraint?.selectedValues?.length === 1
    ? existingDiscountTypeConstraint?.selectedValues[0]
    : 'LOYALTY';

  const defaultDiscountTypeId = discountTypes.find((i) => i.denomination === defaultDiscountType)?.id;

  const defaultDiscountUnit = isEditMode
    ? offerCreation.step3.generosity?.unit
    : existingDiscountUnitConstraint?.selectedValues?.length === 1
    ? existingDiscountUnitConstraint?.selectedValues[0]
    : 'PERCENT';

  const defaultDiscountUnitId = discountUnits.find((i) => i.denomination === defaultDiscountUnit)?.id;

  useEffect(() => {
    if (defaultDiscountTypeId || defaultDiscountUnitId) {
      updateOfferCreation({
        step3: {
          discountType: { id: defaultDiscountTypeId },
          generosity: { ...offerCreation.step3.generosity, unit: defaultDiscountUnitId }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRetailer?.id, defaultDiscountTypeId, defaultDiscountUnitId]);

  const castDiscountTypeValue = (discountTypeValue, typeToCast) => {
    const type = typeof discountTypeValue;

    if (type !== 'undefined' && typeToCast === 'string') {
      return discountTypeValue.toString();
    }

    if (type !== 'undefined' && typeToCast === 'number') {
      return parseInt(discountTypeValue, 10);
    }

    return discountTypeValue;
  };

  const getDiscountRadioGroup = () =>
    discountTypes.map(({ id, label, denomination }) => ({
      value: id.toString(),
      label: renderRadioButtonLabel(t(`_dyn_commun_${label}`), t(`_dyn_commun_description_${label}`)),
      disabled: !existingDiscountTypeConstraint?.selectedValues?.includes(denomination)
    }));

  const handleGenerosityChange = (value) => {
    updateOfferCreation({
      step3: { generosity: value }
    });
  };

  const handleDiscountTypeChange = (value) => {
    if (value) {
      updateOfferCreation({
        step3: { discountType: { id: castDiscountTypeValue(value, 'number') } }
      });
    }
  };

  const handleQuantityChange = (value) => {
    const valueParsed = value ? parseInt(value, 10) : '';

    if (checkNumberNotNull(value) || valueParsed === '') {
      updateOfferCreation({
        step3: { quantity: { value: valueParsed } }
      });
    }
  };

  const handleNumMaxChange = (value) => {
    const valueParsed = value ? parseInt(value, 10) : '';

    if (checkNumberNotNull(value) || valueParsed === '') {
      updateOfferCreation({
        step3: { numberMax: { value: valueParsed } }
      });
    }
  };

  const renderRadioButtonLabel = (title, description) => (
    <div>
      <div>{title}</div>
      <small>
        <i>{description}</i>
      </small>
    </div>
  );

  return (
    <div className="promotion-block">
      <FormManager
        data={{
          title: t('offers_creation_block_discount_mechanism_title'),
          fieldsets: [
            {
              classnames: [typeof helperText === 'undefined' ? 'grid-promotion' : 'grid-promotion-with-helper-text'],
              id: 'add-offer-promotion-block-line-1',
              fields: [
                {
                  outerContainerClass: ['Generosity'],
                  label: t('commun_generosity'),
                  type: 'Generosity',
                  defaultValue: {
                    value: offerCreation.step3.generosity?.value,
                    unit: offerCreation.step3.generosity?.unit
                  },
                  fieldProps: {
                    listData: discountUnits.map((i) => ({
                      ...i,
                      disabled: !existingDiscountUnitConstraint?.selectedValues?.includes(i.denomination)
                    }))
                  },
                  onFieldChange: handleGenerosityChange,
                  id: 'select-generosity',
                  disabled: disabled,
                  error: offerCreation?.errors.generosityError
                },
                {
                  outerContainerClass: ['QuantityToAddToCart'],
                  classnames: ['without-margin-top'],
                  label: t('offers_creation_quantity_to_add'),
                  defaultValue: offerCreation.step3.quantity?.value,
                  fieldProps: {
                    InputProps: {
                      endAdornment: <InputAdornment position="end">{t('commun_products').toLowerCase()}</InputAdornment>
                    }
                  },
                  onFieldChange: handleQuantityChange,
                  type: 'NumberField',
                  id: 'quantity',
                  disabled: disabled,
                  error: offerCreation?.errors.quantityError
                }
              ]
            },
            {
              id: 'add-offer-promotion-block-line2',
              fields: [
                {
                  outerContainerClass: ['with-margin-top-medium'],
                  id: 'offerPromotionHelperText',
                  type: <HelperText content={combinedGuidelineText} />
                },
                {
                  label: t('offers_creation_discount_mechanism_type'),
                  type: 'RadioGroup',
                  defaultValue: castDiscountTypeValue(offerCreation.step3.discountType?.id, 'string') || '',
                  fieldProps: {
                    listData: getDiscountRadioGroup()
                  },
                  onFieldChange: handleDiscountTypeChange,
                  id: 'radio-group-discount-type',
                  disabled: disabled,
                  withBorder: true,
                  fullWidth: true,
                  error: offerCreation?.errors.discountTypeError
                },
                {
                  classnames: ['without-margin-top', 'medium-input'],
                  label: t('offers_creation_max_number_use'),
                  defaultValue: offerCreation.step3.numberMax?.value,
                  fieldProps: {
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          {t('offers_creation_max_number_use_adornment').toLowerCase()}
                        </InputAdornment>
                      )
                    }
                  },
                  onFieldChange: handleNumMaxChange,
                  type: 'NumberField',
                  id: 'maxNumberOfUse',
                  disabled: disabled,
                  error: offerCreation?.errors.numberMaxError
                },
                {
                  display:
                    offerCreation.step3.discountType?.id &&
                    offerCreation.step3.quantity?.value &&
                    offerCreation.step3.generosity?.value,
                  type: 'CustomContent',
                  id: 'generosity-help',
                  component: (
                    <SummaryText currency={currency.code} discountUnits={discountUnits} step3={offerCreation.step3} />
                  )
                }
              ]
            }
          ]
        }}
      />
    </div>
  );
};

PromotionBlock.propTypes = {
  currency: PropTypes.object,
  currentRetailer: PropTypes.object,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  isEditMode: PropTypes.bool,
  offerConstraints: PropTypes.array,
  offerCreation: PropTypes.object,
  updateOfferCreation: PropTypes.func
};

export default memo(PromotionBlock);

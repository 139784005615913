import React from 'react';
import Slider from '@mui/material/Slider';
import styles from './SliderCustom.module.scss';

const SliderCustom = (props) => (
  <Slider
    {...props}
    classes={{
      root: styles['root'],
      thumb: styles['thumb'],
      track: styles['track'],
      rail: styles['rail'],
    }}
  />
);
export default SliderCustom;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CONSTRAINTS_TYPES, DAYS_UNIT, offerTypes, snackbarTypes } from 'utils/constants';
import { store } from 'store';
import { getOfferConstraints, addOfferConstraint, updateOfferConstraint, removeOfferConstraint } from 'api';

import FormLayoutWrapper from 'components/LayoutWrappers/Form';
import FormManager from 'components/FormManager';
import InputAdornment from 'components/InputAdornment';
import TitleBlock from './TitleBlock';
import WarningContainer from './WarningContainer';

const WEEK_DAYS_LIST = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

const Settings = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const { user, offerConstraints } = state;
  const [values, setValues] = useState({
    budget: '',
    delay: { unit: DAYS_UNIT, value: '' },
    discountType: ['IMMEDIATE', 'LOYALTY'],
    discountUnit: ['PERCENT', 'CURRENCY'],
    duration: { unit: DAYS_UNIT, value: '' },
    endDay: '',
    startDay: ''
  });
  const offerType =
    type === 'offersProductsRetailer' ? offerTypes.RETAILER_PRODUCT_OFFER : offerTypes.SUPPLIER_PRODUCT_OFFER;

  const daysList = useMemo(
    () => [
      { label: t('commun_all'), value: '' },
      ...WEEK_DAYS_LIST.map((day) => ({ label: t(`commun_day_${day}`), value: day }))
    ],
    [t]
  );

  const existingConstraints = useMemo(() => {
    const existingBudgetConstraint = offerConstraints.find(
      (el) => el.target === CONSTRAINTS_TYPES.budget && el.type === offerType
    );
    const existingDelayConstraint = offerConstraints.find(
      (el) => el.target === CONSTRAINTS_TYPES.delay && el.type === offerType
    );
    const existingDiscountTypeConstraint = offerConstraints.find(
      (el) => el.target === CONSTRAINTS_TYPES.discountType && el.type === offerType
    );
    const existingDiscountUnitConstraint = offerConstraints.find(
      (el) => el.target === CONSTRAINTS_TYPES.discountUnit && el.type === offerType
    );
    const existingDurationConstraint = offerConstraints.find(
      (el) => el.target === CONSTRAINTS_TYPES.duration && el.type === offerType
    );
    const existingEndDayConstraint = offerConstraints.find(
      (el) => el.target === CONSTRAINTS_TYPES.endDay && el.type === offerType
    );
    const existingStartDayConstraint = offerConstraints.find(
      (el) => el.target === CONSTRAINTS_TYPES.startDay && el.type === offerType
    );

    return {
      budget: existingBudgetConstraint,
      delay: existingDelayConstraint,
      discountType: existingDiscountTypeConstraint,
      discountUnit: existingDiscountUnitConstraint,
      duration: existingDurationConstraint,
      endDay: existingEndDayConstraint,
      startDay: existingStartDayConstraint
    };
  }, [offerConstraints, offerType]);

  useEffect(() => {
    const loadConstraints = async () => {
      await getOfferConstraints(user.id);
    };

    loadConstraints();
  }, [user.id]);

  useEffect(() => {
    if (offerConstraints.length) {
      const defaultValues = {
        budget: existingConstraints.budget?.threshold || '',
        delay: existingConstraints.delay
          ? { value: existingConstraints.delay.threshold, unit: existingConstraints.delay.unit }
          : { value: '', unit: DAYS_UNIT },
        discountType: existingConstraints?.discountType?.selectedValues?.length
          ? existingConstraints.discountType.selectedValues
          : ['IMMEDIATE', 'LOYALTY'],
        discountUnit: existingConstraints?.discountUnit?.selectedValues?.length
          ? existingConstraints.discountUnit.selectedValues
          : ['PERCENT', 'CURRENCY'],
        duration: existingConstraints.duration
          ? { value: existingConstraints.duration.threshold, unit: existingConstraints.duration.unit }
          : { value: '', unit: DAYS_UNIT },
        endDay: existingConstraints.endDay?.selectedValues?.[0] || '',
        startDay: existingConstraints.startDay?.selectedValues?.[0] || ''
      };
      setValues(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerConstraints, offerConstraints.length, offerType]);

  const handleChange = ({ id, data }) => {
    setValues((state) => ({ ...state, [id]: data }));
  };

  const handleSubmit = async () => {
    const { budget, delay, discountType, discountUnit, duration, endDay, startDay } = values;
    const responses = await Promise.all([
      handleSubmitBudget(budget),
      handleSubmitDelay(delay),
      handleSubmitDiscountType(discountType),
      handleSubmitDiscountUnit(discountUnit),
      handleSubmitDuration(duration),
      handleSubmitEndDay(endDay),
      handleSubmitStartDay(startDay)
    ]);
    await getOfferConstraints(user.id);
    if (responses.every((res) => res === true)) {
      dispatch({
        type: 'UI_SNACKBAR',
        payload: { type: snackbarTypes.SUCCESS, title: { key: 'offer_constraints_updated' } }
      });
    }
  };

  const handleApiCalls = async ({ constraint, payload }) => {
    let isOK = true;
    if (payload) {
      if (constraint) {
        isOK = await updateOfferConstraint(payload);
      } else {
        isOK = await addOfferConstraint(payload);
      }
    } else if (constraint) {
      isOK = await removeOfferConstraint(constraint.id);
    }
    return isOK;
  };

  const handleSubmitBudget = async (budget) => {
    let payload;
    if (budget) {
      if (budget === existingConstraints.budget?.threshold) {
        return true;
      }
      payload = {
        id: existingConstraints.budget?.id,
        target: CONSTRAINTS_TYPES.budget,
        operator: 'greaterThanOrEqual',
        threshold: budget,
        type: offerType,
        retailer: {
          id: user.id
        }
      };
    }
    return await handleApiCalls({ constraint: existingConstraints.budget, payload });
  };

  const handleSubmitDelay = async (delay) => {
    let payload;
    if (delay?.value) {
      if (delay.value === existingConstraints.delay?.threshold && delay.unit === existingConstraints.delay?.unit) {
        return true;
      }
      payload = {
        id: existingConstraints.delay?.id,
        target: CONSTRAINTS_TYPES.delay,
        operator: 'greaterThanOrEqual',
        threshold: delay.value,
        unit: delay.unit,
        type: offerType,
        retailer: {
          id: user.id
        }
      };
    }
    return await handleApiCalls({ constraint: existingConstraints.delay, payload });
  };

  const handleSubmitDiscountType = async (typeList) => {
    let payload;
    if (typeList?.length) {
      const sortedTypes = typeList.sort();
      const sortedSelectedValues = existingConstraints.discountType?.selectedValues?.sort();
      if (JSON.stringify(sortedTypes) === JSON.stringify(sortedSelectedValues)) {
        return true;
      }

      payload = {
        id: existingConstraints.discountType?.id,
        target: CONSTRAINTS_TYPES.discountType,
        operator: 'in',
        selectedValues: typeList,
        type: offerType,
        retailer: {
          id: user.id
        }
      };
    }
    return await handleApiCalls({ constraint: existingConstraints.discountType, payload });
  };

  const handleSubmitDiscountUnit = async (unitList) => {
    let payload;
    if (unitList?.length) {
      const sortedTypes = unitList.sort();
      const sortedSelectedValues = existingConstraints.discountUnit?.selectedValues?.sort();
      if (JSON.stringify(sortedTypes) === JSON.stringify(sortedSelectedValues)) {
        return true;
      }
      payload = {
        id: existingConstraints.discountUnit?.id,
        target: CONSTRAINTS_TYPES.discountUnit,
        operator: 'in',
        selectedValues: unitList,
        type: offerType,
        retailer: {
          id: user.id
        }
      };
    }
    return await handleApiCalls({ constraint: existingConstraints.discountUnit, payload });
  };

  const handleSubmitDuration = async (duration) => {
    let payload;
    if (duration?.value) {
      if (
        duration.value === existingConstraints.duration?.threshold &&
        duration.unit === existingConstraints.duration?.unit
      ) {
        return true;
      }
      payload = {
        id: existingConstraints.duration?.id,
        target: CONSTRAINTS_TYPES.duration,
        operator: 'greaterThanOrEqual',
        threshold: duration.value,
        unit: duration.unit,
        type: offerType,
        retailer: {
          id: user.id
        }
      };
    }
    return await handleApiCalls({ constraint: existingConstraints.duration, payload });
  };

  const handleSubmitEndDay = async (endDay) => {
    let payload;
    if (endDay) {
      if (existingConstraints.endDay?.selectedValues?.includes(endDay)) {
        return true;
      }
      payload = {
        id: existingConstraints.endDay?.id,
        target: CONSTRAINTS_TYPES.endDay,
        operator: 'in',
        selectedValues: [endDay],
        type: offerType,
        retailer: {
          id: user.id
        }
      };
    }

    return await handleApiCalls({ constraint: existingConstraints.endDay, payload });
  };

  const handleSubmitStartDay = async (startDay) => {
    let payload;
    if (startDay) {
      if (existingConstraints.startDay?.selectedValues?.includes(startDay)) {
        return true;
      }
      payload = {
        id: existingConstraints.startDay?.id,
        target: CONSTRAINTS_TYPES.startDay,
        operator: 'in',
        selectedValues: [startDay],
        type: offerType,
        retailer: {
          id: user.id
        }
      };
    }

    return await handleApiCalls({ constraint: existingConstraints.startDay, payload });
  };

  return (
    <FormLayoutWrapper
      headerTitle={t('product_offers_settings_title')}
      headerSubTitle={t('product_offers_settings_desc')}
    >
      <FormManager
        onSubmit={handleSubmit}
        data={{
          raiseErrorOnSubmit: true,
          fieldsets: [
            {
              id: 'admin-retailer-settings',
              fields: [
                {
                  outerContainerClass: ['full-width'],
                  id: 'info-block',
                  type: 'CustomContent',
                  component: <WarningContainer />
                },
                {
                  id: 'promotion-block-title',
                  type: 'CustomContent',
                  component: <TitleBlock title={t('offers_creation_block_discount_mechanism_title')} />
                },
                {
                  label: t('product_offers_settings_discount_type'),
                  type: 'CheckboxList',
                  id: 'discountType',
                  defaultValue: values.discountType,
                  fieldProps: {
                    list: [
                      { value: 'IMMEDIATE', label: t('product_offers_settings_discount_type_immediate') },
                      { value: 'LOYALTY', label: t('product_offers_settings_discount_type_loyalty') }
                    ]
                  },
                  onFieldChange: (data) => {
                    handleChange({ id: 'discountType', data });
                  },
                  validations: [
                    {
                      func: (val) => val?.length,
                      message: t('product_offers_settings_discount_type_error')
                    }
                  ]
                },
                {
                  label: t('product_offers_settings_discount_unit'),
                  type: 'CheckboxList',
                  id: 'discountUnit',
                  defaultValue: values.discountUnit,
                  fieldProps: {
                    list: [
                      { value: 'PERCENT', label: t('product_offers_settings_discount_unit_percent') },
                      {
                        value: 'CURRENCY',
                        label: t('product_offers_settings_discount_unit_currency', {
                          currency: user.currency?.symbol
                        })
                      }
                    ]
                  },
                  onFieldChange: (data) => {
                    handleChange({ id: 'discountUnit', data });
                  },
                  validations: [
                    {
                      func: (val) => val?.length,
                      message: t('product_offers_settings_discount_unit_error')
                    }
                  ]
                },
                {
                  id: 'budget-block-title',
                  type: 'CustomContent',
                  component: <TitleBlock title={t('commun_budget')} />
                },
                {
                  label: t('product_offers_settings_minimal_budget'),
                  type: 'NumberField',
                  id: 'budget',
                  defaultValue: values.budget,
                  fieldProps: {
                    InputProps: {
                      endAdornment: <InputAdornment position="end">{user.currency.symbol} </InputAdornment>
                    }
                  },
                  onFieldChange: (data) => {
                    handleChange({ id: 'budget', data });
                  },
                  validations: [
                    {
                      func: (value) => !value || value > 0,
                      message: t('product_offers_settings_minimal_budget_error')
                    }
                  ]
                },
                {
                  id: 'dates-block-title',
                  type: 'CustomContent',
                  component: (
                    <TitleBlock
                      title={t('offers_creation_block_duration_and_validity_dates_title')}
                      desc={t('offers_creation_block_duration_and_validity_dates_desc')}
                    />
                  )
                },
                {
                  label: t('product_offers_settings_minimal_duration'),
                  type: 'Duration',
                  id: 'duration',
                  defaultValue: values.duration,
                  onFieldChange: (data) => {
                    handleChange({ id: 'duration', data });
                  },
                  validations: [
                    {
                      func: (valueObj) => !valueObj?.value || valueObj?.value > 0,
                      message: t('product_offers_settings_minimal_duration_error')
                    }
                  ]
                },
                {
                  label: t('product_offers_settings_minimal_delay'),
                  type: 'Duration',
                  id: 'delay',
                  defaultValue: values.delay,
                  onFieldChange: (data) => {
                    handleChange({ id: 'delay', data });
                  },
                  validations: [
                    {
                      func: (valueObj) => !valueObj?.value || valueObj?.value > 0,
                      message: t('product_offers_settings_minimal_delay_error')
                    },
                    {
                      func: (valueObj) =>
                        valueObj.unit !== DAYS_UNIT || (valueObj.unit === DAYS_UNIT && valueObj?.value < 366),
                      message: t('product_offers_settings_minimal_delay_max_days')
                    },
                    {
                      func: (valueObj) =>
                        valueObj.unit === DAYS_UNIT || (valueObj.unit !== DAYS_UNIT && valueObj?.value < 53),
                      message: t('product_offers_settings_minimal_delay_max_weeks')
                    }
                  ]
                },
                {
                  label: t('product_offers_settings_day_start'),
                  type: 'RadioGroup',
                  id: 'startDay',
                  defaultValue: values.startDay,
                  fieldProps: {
                    listData: daysList
                  },
                  onFieldChange: (data) => {
                    handleChange({ id: 'startDay', data });
                  }
                },
                {
                  label: t('product_offers_settings_day_end'),
                  type: 'RadioGroup',
                  id: 'endDay',
                  defaultValue: values.endDay,
                  fieldProps: {
                    listData: daysList
                  },
                  onFieldChange: (data) => {
                    handleChange({ id: 'endDay', data });
                  }
                }
              ]
            }
          ],
          submit: {
            label: t('commun_button_save')
          }
        }}
      />
    </FormLayoutWrapper>
  );
};

export default Settings;

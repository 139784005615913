import React from 'react';
import PropTypes from 'prop-types';

import styles from './DrawerHeader.module.scss';

const DrawerHeader = ({ buttons, className, disabledMessage, title }) => (
  <>
    <div className={`${styles['drawer-header']} ${className}`}>
      <div className="d-flex">
        <div className={styles['drawer-header-title']}>{title}</div>
        {buttons}
      </div>
      {disabledMessage && <div className={styles['disabled-msg']}> {disabledMessage}</div>}
    </div>
  </>
);

DrawerHeader.propTypes = {
  buttons: PropTypes.array,
  className: PropTypes.string,
  disabledMessage: PropTypes.string,
  title: PropTypes.string,
};

export default DrawerHeader;

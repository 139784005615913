import React, { useState, useEffect, memo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import BudgetsProgressBar from 'components/BudgetsProgressBar';
import BudgetsProgressLegend from 'components/BudgetsProgressBar/Legend';
import OfferDetailsAlert from 'components/OfferDetailsAlert';
import OfferBudgetRecommendation from 'components/OfferBudgetRecommendation';
import { getBudgetRecommendation } from 'api';

import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';

import styles from './OfferBudget.module.scss';

const OfferBudget = ({
  budgetSpent,
  budgetTarget,
  budgetType,
  currency,
  isExpiredOrDisabled,
  isSupplierList,
  onUpdated
}) => {
  const { t } = useTranslation();
  const isBudgetUnlimited = budgetTarget === -1;
  const isBudgetSpentGeaterThanBudgetTargetOnOPF = isSupplierList && budgetSpent > budgetTarget;

  const [budgetRecommendation, setBudgetRecommendation] = useState(null);

  const { id } = useParams();
  const offerId = !isNaN(id) ? parseInt(id, 10) : undefined;

  useEffect(() => {
    const getRecommandation = async () => {
      const recommendation = await getBudgetRecommendation(offerId);
      setBudgetRecommendation(recommendation);
    };

    if (offerId) getRecommandation();
  }, [budgetTarget, offerId]);

  return (
    <div className={styles['root']}>
      <div className={styles['info-container']}>
        <div className={styles['title']}>
          {budgetType ? t(`commun_budget_type_${budgetType}_long`) : t('commun_budget')}
        </div>
        <div className={`${styles['recap']} ${isBudgetSpentGeaterThanBudgetTargetOnOPF ? styles['alert'] : ''}`}>
          {isBudgetSpentGeaterThanBudgetTargetOnOPF && <WarningIcon />}
          {t('commun_price', { value: budgetSpent, currency })}
          <span className={styles['total']}>{` / ${
            isBudgetUnlimited
              ? t('commun_unlimited')
              : t('commun_price', {
                  value: budgetTarget,
                  currency
                })
          }`}</span>
        </div>
      </div>
      <BudgetsProgressBar
        currency={currency}
        data={[
          { key: 'spent', value: budgetSpent },
          {
            key: 'reinjected',
            value: isExpiredOrDisabled ? Math.max(0, budgetTarget - budgetSpent) : null
          },
          { key: 'left', value: isExpiredOrDisabled ? null : Math.max(0, budgetTarget - budgetSpent) }
        ]}
        total={budgetTarget}
      />
      {!isBudgetUnlimited && (
        <BudgetsProgressLegend
          list={[
            'budget_progressBar_spent',
            isExpiredOrDisabled ? 'budget_progressBar_reinjected' : null,
            isExpiredOrDisabled ? null : 'budget_progressBar_left'
          ]}
        />
      )}
      {isBudgetSpentGeaterThanBudgetTargetOnOPF && (
        <OfferDetailsAlert className="alert-red" title={t('offer_details_budget_alert')} withInfoIcon={false} />
      )}

      {budgetRecommendation && (
        <OfferBudgetRecommendation
          budgetRecommendation={budgetRecommendation}
          budgetTarget={budgetTarget}
          currency={currency}
          onUpdated={onUpdated}
        />
      )}
    </div>
  );
};

OfferBudget.propTypes = {
  budgetSpent: PropTypes.number.isRequired,
  budgetTarget: PropTypes.number.isRequired,
  budgetType: PropTypes.string,
  currency: PropTypes.string.isRequired,
  isExpiredOrDisabled: PropTypes.bool.isRequired,
  isSupplierList: PropTypes.bool.isRequired,
  onUpdated: PropTypes.func.isRequired
};

export default memo(OfferBudget);

import React, { useEffect, useState, useContext, memo } from 'react';
import { store } from 'store';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormManager from 'components/FormManager';
import PictureGallery from 'components/PictureGallery';

const ImageBlock = ({ currentRetailer, disabled, offerCreation, updateOfferCreation }) => {
  const { t } = useTranslation();
  const {
    state: { offers }
  } = useContext(store);

  const defaultCropData = {
    cropSize: {
      width: currentRetailer?.settingOfferImageHorizontalSize,
      height: currentRetailer?.settingOfferImageVerticalSize
    }
  };

  const { step1, step3 } = offerCreation;
  const damName = step1.damName;
  const { cropData, image, resultListEan } = step3;

  const [pictureCropData, setPictureCropData] = useState(cropData || defaultCropData);

  useEffect(() => {
    let newCropData = defaultCropData;
    if (cropData) {
      newCropData = {
        crop: cropData.crop,
        cropSize: { ...defaultCropData.cropSize },
        croppedAreaPixels: cropData.croppedAreaPixels,
        zoom: cropData.zoom
      };
    }
    setPictureCropData(newCropData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerCreation.step1.id, cropData]);

  const handleImageChange = (imageData) => {
    if (imageData.file) {
      setPictureCropData(imageData?.cropData || defaultCropData);
      updateOfferCreation({
        step3: {
          image: imageData.file || imageData,
          cropData: imageData.cropData
        }
      });
    }
  };

  const getInitialSelectedProduct = () => {
    const offerCreationImageName = image?.name;
    if (offerCreationImageName && !!resultListEan.length) {
      let ean;
      if (!!image.id) {
        const separator = 'file';
        const eanLength = resultListEan[0].ean.length;
        ean = offerCreationImageName.substr(offerCreationImageName.indexOf(separator) + separator.length, eanLength);
      } else {
        ean = offerCreationImageName.split('_')?.[1];
      }
      return resultListEan.find((p) => p.ean === ean);
    } else return undefined;
  };

  return (
    <FormManager
      data={{
        title: t('offers_creation_block_image_choice_title'),
        subTitle: t('offers_creation_block_image_choice_subtitle'),
        fieldsets: [
          {
            id: 'add-offer-image-block',
            fields: [
              {
                type: 'ImageChoice',
                defaultValue: image,
                originalFile: offers.details.offer.offerHead?.fileDescriptor,
                mediumFile: offers.details.offer.offerHead?.imageMediumFileDescriptor,
                defaultCropData: pictureCropData,
                onFieldChange: handleImageChange,
                fieldProps: {
                  gallery: <PictureGallery />,
                  productsGalleryList: resultListEan,
                  productsPreviewList: damName ? resultListEan : [],
                  retailer: currentRetailer,
                  initialSelectedProduct: getInitialSelectedProduct()
                },
                id: 'image',
                disabled,
                error: offerCreation.errors?.imageError
              }
            ]
          }
        ]
      }}
    />
  );
};

ImageBlock.propTypes = {
  currentRetailer: PropTypes.object,
  disabled: PropTypes.bool,
  offerCreation: PropTypes.object,
  updateOfferCreation: PropTypes.func
};

export default memo(ImageBlock);

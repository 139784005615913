import React, { memo, ReactNode, useState } from 'react';
import moment from 'moment-timezone';
import Popper from '@mui/material/Popper';

import { ReactComponent as CloseIcon } from 'assets/22px_croix.svg';
import styles from './NewFeaturePopup.module.scss';

const today = moment();

type Props = {
  anchorRef?: Element;
  desc?: ReactNode;
  expiresAt: string;
  id: string;
  title: ReactNode;
  visible?: boolean;
};

/*** Displays a popper until the user closes it or the date is expired ***/
/*** from then on the popper won't show up again ***/

const NewFeaturePopup = ({ anchorRef, desc, expiresAt, id, title, visible = true }: Props): JSX.Element | null => {
  const storageKey = `new-feature-${id}`;
  const storageKeyExists = localStorage.getItem(storageKey);
  const maxDateExpired = today.isAfter(moment(expiresAt), 'day');
  const [isOpen, setIsOpen] = useState<boolean>(!storageKeyExists && !maxDateExpired);

  const handleOnClose = (): void => {
    // save storageKey in localStorage
    localStorage.setItem(storageKey, 'seen');
    // then close the popper
    setIsOpen(false);
  };

  if (!anchorRef || !visible) return null;
  return (
    <Popper
      className={styles['root']}
      anchorEl={anchorRef}
      id={`${id}-id`}
      open={isOpen}
      placement="bottom-end"
      modifiers={[
        {
          name: 'flip',
          options: {
            allowedAutoPlacements: ['bottom'],
            fallbackPlacements: ['bottom'],
            flipVariations: false
          }
        }
      ]}
    >
      <div className={styles['header-line']}>
        <div className={styles['title']}>{title}</div>
        <div className={styles['close-icon']} id={`${id}-close-button-id`} onClick={handleOnClose} role="button">
          <CloseIcon />
        </div>
      </div>
      {desc && <div className={styles['desc']}>{desc}</div>}
      <span className={styles['popper-arrow']}></span>
    </Popper>
  );
};

export default memo(NewFeaturePopup);

import React from 'react';

const LegalMentions = () => (
  <main>
    <h2>Mentions légales et Conditions Générales d’Utilisation de Plateforme</h2>

    <h3>Identification</h3>
    <p>
      Le présent site accessible à l’URL{' '}
      <a href="https://rcdmp.relevanc.io" rel="noopener noreferrer" target="_blank">
        https://rcdmp.relevanc.io
      </a>{' '}
      (le « Site ») est la propriété RELEVANC, Société par actions simplifiée au capital de 252 631,00 euros,
      immatriculée au Registre du Commerce et des Sociétés de Saint-Etienne sous le numéro 824155824, dont le siège
      social est situé : 1 crs Antoine Guichard - 42000 Saint-Etienne, assujettie pour la France à la TVA sous le numéro
      FR49824155824 (e-mail : <a href="mailto:contact@relevanc.com">contact@relevanc.com</a>).
    </p>
    <p>
      Le directeur de la publication du Site est Monsieur Olivier Morin, en qualité de Directeur général exécutif de
      RelevanC.
    </p>
    <p>
      Le Site est hébergé par Google Ireland Limited, société étrangère non immatriculée au RCS, dont le siège social
      est situé à Gordon House, Barrow Street, Dublin 4, Ireland.
    </p>
    <p>
      L’utilisateur reconnaît avoir pris connaissance de la présente politique de confidentialité et s’engage à la
      respecter.
    </p>
    <p>
      L’utilisateur reconnaît être parfaitement informé du fait que son accord concernant le contenu des présentes
      Conditions Générales ne nécessite pas la signature manuscrite de ce document, mais résulte de sa seule
      consultation du Site.
    </p>

    <h3>Conditions Générales d’Utilisation</h3>
    <h4>Obligations / Responsabilité de l’utilisateur</h4>
    <p>L’utilisateur s’interdit notamment de :</p>
    <ul>
      <li>communiquer des informations :</li>
      <ul>
        <li>fausses, imprécises, mensongères</li>
        <li>diffamatoires, médisantes ou calomnieuses</li>
        <li>
          qui portent atteinte aux droits d’auteurs, aux droits des brevets, aux droits des marques, aux secrets de
          fabrication, aux autres droits de propriété intellectuelle, au droit de divulgation ou à la vie privée des
          tiers
        </li>
        <li>qui enfreignent la loi française et/ou applicable</li>
        <li>
          qui contiennent des virus, chevaux de Troie, « worms », bombe à retardement, « cancelbots » ou tout autre
          programme informatique visant à endommager ou à intercepter clandestinement tout système informatique, données
          ou informations nominatives
        </li>
      </ul>
      <li>
        tenter d’induire en erreur d’autres utilisateurs en usurpant l’identité ou une dénomination sociale ou en
        portant atteinte à l’image ou à la réputation d’autres personnes et/ou en se faisant passer pour un tiers ou
        pour un employé, un service habilité ou un affilié de RELEVANC
      </li>
      <li>perturber, ralentir, bloquer ou altérer le flux normal des données échangées dans le cadre du Site</li>
      <li>
        accéder frauduleusement, se maintenir, entraver ou perturber les systèmes d’information de RELEVANC et notamment
        les serveurs, les réseaux connectés au Site, ou refuser de se conformer aux conditions requises, aux procédures,
        aux règles générales ou aux dispositions réglementaires applicables aux réseaux
      </li>
    </ul>
    <p>
      L’utilisateur s’engage à respecter les dispositions légales en la matière, applicables aux informations circulant
      sur Internet et/ou le Site et demeurera en tout état de cause seul responsable des atteintes aux droits de tiers
      qui pourraient résulter de son utilisation du Site. L’utilisateur est seul juge de sa faculté, légale,
      contractuelle et/ou judiciaire à accéder au Site et/ou utiliser le Site. A ce titre, en utilisant le Site,
      l’utilisateur reconnaît qu’il ne viole aucune disposition légale, contractuelle ou statutaire. L’utilisateur
      s’engage à utiliser le Site et les services à des fins conformes à l’ordre public, la sécurité publique et aux
      bonnes mœurs et demeurera en tout état de cause seul responsable des éventuelles atteintes à l’ordre public, à la
      sécurité publique et aux bonnes mœurs qui pourraient résulter de son utilisation du Site.
    </p>
    <p>
      De manière générale, l’utilisateur garantit RELEVANC contre toute réclamation, action et/ou revendication dont
      elle pourrait faire l’objet à ce titre et s’engage, à ce titre, à prendre à sa charge l’ensemble des sommes,
      dommages et intérêts, frais, honoraires d’avocat et dépens auxquels pourrait être condamné RELEVANC.
    </p>
    <p>
      L’utilisateur du Site reconnaît enfin avoir pris connaissance de la présente notice légale, en accepter les termes
      et s’engage à respecter les obligations mises à sa charge et à consulter, chaque nouvelle version mise à jour de
      cette notice, dès lors qu’il y aura été invité par tous moyens.
    </p>
    <p>
      En tout état de cause, l’utilisateur est seul responsable de la perte, du vol, de l’oubli, de son identifiant et
      de son mot de passe, qui lui sont personnels.
    </p>

    <h4>Droits d’auteur / Copyright</h4>
    <p>
      La structure générale, ainsi que les logiciels, textes, images animées ou non, sons, savoir-faire... et tous les
      autres éléments composant le Site sont la propriété exclusive de RELEVANC ou de ses partenaires.
    </p>
    <p>
      Toute représentation totale ou partielle de ce Site, sans l’autorisation expresse de l’exploitant du Site, est
      interdite et constituerait une contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la
      propriété intellectuelle.
    </p>
    <p>
      Les marques de RELEVANC et de ses partenaires, ainsi que les logos figurant sur le site sont des marques
      semi-figuratives ou non et sont déposées. Toute reproduction totale ou partielle de ces marques ou de ces logos,
      effectuée à partir des éléments du site sans l’autorisation expresse de l’exploitant du site web est donc
      prohibée, au sens de l’article L.713-2 du Code de la propriété intellectuelle.
    </p>
    <p>
      Les présentes Conditions Générales n’emportent aucune cession d’aucune sorte de droit de propriété intellectuelle
      sur les éléments appartenant à RELEVANC, ou ayants droit tels que les sons, vidéos, photographies, images, textes
      littéraires, travaux artistiques, logiciels, marques, chartes graphiques, logos) au bénéfice de l’utilisateur.
      Toute autre utilisation, non expressément autorisée par écrit et au préalable par RELEVANC est prohibée et
      constitutive de contrefaçon.
    </p>

    <h4>Responsabilité</h4>
    <p>
      Le Site ainsi que son contenu sont fournis « en l’état » et sans aucune garantie, notamment de performance future.
      RELEVANC exclut, dans les limites permises par la loi, toutes garanties. A cet égard et notamment, RELEVANC ne
      garantit nullement que le Site est accessible à tout moment ou sans erreur et/ou exempt de vices. Dans ce cadre,
      il est rappelé que l’accès au Site peut être temporairement suspendu, en raison (i) de la maintenance matérielle
      et/ou logicielle du serveur du Site, et/ou (ii) de la maintenance matérielle, logicielle et/ou éditoriale du Site,
      et/ou (iii) de la survenance d’un cas de force majeure. L’éventualité d’une indisponibilité temporaire ou
      définitive du Site ne peut en aucun cas donner lieu à une demande de dommages et intérêts ou toute autre forme de
      réparation.
    </p>
    <p>
      Il est rappelé que (i) la transmission des données sur Internet ne bénéficie que d’une fiabilité relative,
      celles-ci circulant sur des réseaux hétérogènes, aux caractéristiques et capacités diverses, qui sont parfois
      saturés à certaines périodes de la journée et de nature à impacter les délais de téléchargement ou l’accessibilité
      aux données, (ii) Internet est un réseau ouvert et, qu’en conséquence, les informations qu’il véhicule ne sont pas
      protégées contre les risques de détournement, d’intrusion dans votre système, de piratage des données, programmes
      et fichiers de son système, de contamination par des virus informatiques, et (iii) il appartient à l’utilisateur
      de prendre toutes les mesures appropriées de façon à protéger son système et son contenu contre la contamination
      par des virus comme des tentatives d’intrusion. En conséquence, RELEVANC ne saurait être responsable pour tout
      dommage résultant de l’accès, l’utilisation et/ou l’indisponibilité du Site, et notamment pour toute perte de
      données/programmes, toute contamination par virus, tout préjudice financier et/ou commercial et/ou toute perte
      d’image. RELEVANC se réserve le droit d’effectuer des opérations de maintenance et de mises à jour durant
      lesquelles les produits et services ne seront pas disponibles. RELEVANC se réserve notamment le droit d’ajouter ou
      de supprimer des fonctionnalités, ou de modifier la mise en page de l’outil.
    </p>

    <h4>Loi applicable</h4>
    <p>Les présentes Conditions Générales sont régies par la loi française.</p>
  </main>
);

export default LegalMentions;

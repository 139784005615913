import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, Select, InputLabel, MenuItem } from '@mui/material';
import { ReactComponent as dropdownArrow } from 'assets/dropdown_arrow.svg';
import './SelectCustom.scss';

const SelectCustom = (props) => {
  const {
    className,
    disabled,
    id,
    placeHolderText,
    listData,
    methodOnChange,
    value = '',
    labelAttribute = 'label',
    error = false,
    errorMsg,
    selectClassName = 'root',
    disablePortal,
    shrinkPlaceholder = false,
  } = props;
  const [shrink, setShrink] = useState(false);

  return (
    <>
      <FormControl
        id={id}
        variant="filled"
        classes={{
          root: `customized-select ${className} ${error ? 'error' : ''} ${
            disablePortal ? 'whenPortalIsDisabled' : ''
          } ${shrinkPlaceholder ? '' : 'no-shrinkPlaceholder'}`,
        }}
        error={error}
      >
        {shrinkPlaceholder ? (
          <InputLabel shrink={!!value || shrink} htmlFor={id}>
            {placeHolderText}
          </InputLabel>
        ) : (
          <InputLabel shrink={false} htmlFor={id}>
            {!value && placeHolderText}
          </InputLabel>
        )}

        <Select
          disabled={disabled}
          value={value}
          onChange={methodOnChange}
          onClose={() => setShrink(false)}
          onOpen={() => setShrink(true)}
          IconComponent={dropdownArrow}
          classes={{ root: selectClassName }}
          MenuProps={{
            classes: { paper: `customized-select-menu ${shrinkPlaceholder ? '' : 'no-shrinkPlaceholder'}` },
            anchorOrigin: {
              vertical: 35,
              horizontal: 'center',
            },
            disablePortal: disablePortal,
          }}
        >
          {listData.map((option) => (
            <MenuItem key={option.id || ''} value={option.id} id={option.idCustom} disabled={option.disabled}>
              {option[labelAttribute]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {error && typeof errorMsg === 'string' && <p className="error">{errorMsg}</p>}
    </>
  );
};

SelectCustom.propTypes = {
  className: PropTypes.string,
  disablePortal: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string.isRequired,
  labelAttribute: PropTypes.string,
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
  methodOnChange: PropTypes.func,
  placeHolderText: PropTypes.string,
  selectClassName: PropTypes.string,
  shrinkPlaceholder: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SelectCustom;

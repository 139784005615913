import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AsidePanel from 'components/AsidePanel';
import Loader from 'components/Loaders/Dots';
import UnknownId from 'components/Drawer/UnknownId';
import styles from './NoCampaignData.module.scss';

const NoCampaignData = ({ isLoading }) => {
  const { t } = useTranslation();
  return (
    <div className={styles['root']}>
      <div className={styles['main-col']}>
        <div className={`${styles['frame']} ${styles['big-container']}`}>
          {isLoading ? (
            <div className={styles['big-container']}>
              <p>{t('commun_loading')}</p>
              <Loader />
            </div>
          ) : (
            <UnknownId />
          )}
        </div>
        <div className={`${styles['frame']} ${styles['align-right']}`}>
          <div className={styles['placeholder-button']}></div>
        </div>
      </div>
      <aside className={styles['aside-col']}>
        <AsidePanel className={styles['allocation-placeholder']}>
          <div className={styles['placeholder-group-text']}>
            <div className={`${styles['placeholder-text']} ${styles['medium']}`}></div>
            <div className={styles['placeholder-text']}></div>
            <div className={`${styles['placeholder-text']} ${styles['small']}`}></div>
          </div>
          <div className={styles['placeholder-button']}></div>
        </AsidePanel>
        {[1, 2, 3].map((key) => (
          <AsidePanel className={styles['kpi-placeholder']} key={key}>
            <div className={styles['placeholder-square']}></div>
            <div className={styles['placeholder-group-text']}>
              <div className={`${styles['placeholder-text']} ${styles['small']}`}></div>
              <div className={`${styles['placeholder-text']} ${styles['medium']}`}></div>
            </div>
          </AsidePanel>
        ))}
      </aside>
    </div>
  );
};

NoCampaignData.propTypes = {
  isLoading: PropTypes.bool,
};

export default memo(NoCampaignData);

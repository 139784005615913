import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { withFractionDigits } from 'utils/global';
import { getDashboardRetailerOfferInfo, getDashboardSupplierOfferInfo } from 'api';

import Donut from 'components/Donut';
import styles from './DashboardSupplier.module.scss';

import { offerInfoPerTargetingStrategiesType } from 'types/offerInfoPerTargetingStrategies';
import { targetMarketingCodesType } from 'types/targetMarketing';

const COLORS = ['#5D80E8', '#C8DFFF', '#FE4B4B', '#FFDBDB', '#E8E8EC'];

type Props = {
  budgetIds?: number[];
  currency: string;
  retailerId?: number;
  year: number;
};

const MarketingStrategiesBlock = ({ budgetIds, currency, retailerId, year }: Props) => {
  const { t } = useTranslation();
  const [data, setData] = useState<offerInfoPerTargetingStrategiesType[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const config = [
    {
      blockTitle_i18n: 'dashboard_offer_info_per_targeting_strategies_budget_spent_block_title',
      key: 'BudgetSpent'
    },
    {
      blockTitle_i18n: 'dashboard_offer_info_per_targeting_strategies_estimated_turnover_block_title',
      key: 'EstimatedTurnover'
    },
    {
      blockTitle_i18n: 'dashboard_offer_info_per_targeting_strategies_used_coupons_block_title',
      key: 'UsedCoupons'
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const response: offerInfoPerTargetingStrategiesType[] = retailerId
        ? await getDashboardRetailerOfferInfo({
            budgetIds,
            retailerId,
            year
          })
        : await getDashboardSupplierOfferInfo({ budgetIds, year });
      setData(response);
      setIsLoading(false);
    };

    if (!retailerId || !!budgetIds?.length) {
      fetchData();
    }
  }, [budgetIds, retailerId, year]);

  const displayLegendColor = (index: number): JSX.Element => (
    <div className={styles['legend-color']} style={{ backgroundColor: COLORS[index] }} />
  );

  const displayPercent = (blockKey: string, index: number): string => {
    const percentValue = getPercentValue(blockKey, index);

    return t('commun_percentage_number', {
      value: percentValue,
      maximumFractionDigits: withFractionDigits(percentValue)
    });
  };

  const displayTitle = (code: targetMarketingCodesType): string => {
    return t(`commun_marketingStrategy_${code}_title`);
  };

  const displayValue = (blockKey: string, index: number): string => {
    const valueKey = `value${blockKey}` as keyof offerInfoPerTargetingStrategiesType;
    const value = isLoading ? 0 : data?.[index]?.[valueKey] || 0;

    return currency
      ? t('commun_price', {
          value,
          currency
        })
      : '0%';
  };

  const getPercentValue = (blockKey: string, index: number): number => {
    const percentKey = `percentage${blockKey}` as keyof offerInfoPerTargetingStrategiesType;
    const percentValue = isLoading ? 0 : data?.[index]?.[percentKey] || 0;
    return percentValue as number;
  };

  return (
    <>
      {config.map((block) => (
        <div className={clsx(styles['card'], styles['offer-info-per-targeting-strategy-card'])} key={block.key}>
          <div>{t(block.blockTitle_i18n)}</div>
          <Donut
            customConfig={{
              animate: false,
              isInteractive: false,
              cornerRadius: 0,
              innerRadius: 0.5,
              padAngle: 4,
              startAngle: -10,
              endAngle: 360
            }}
            colors={COLORS}
            data={data?.map((d, index) => ({
              id: d.code,
              index,
              label: d.code,
              value: getPercentValue(block.key, index)
            }))}
          />
          <table>
            <tbody>
              {data?.map((d, index) => (
                <tr key={d.code}>
                  <td>{displayLegendColor(index)}</td>
                  <td>{displayPercent(block.key, index)}</td>
                  <td>{displayTitle(d.code)}</td>
                  <td>{displayValue(block.key, index)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </>
  );
};

export default MarketingStrategiesBlock;

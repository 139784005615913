import React, { useContext, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router-dom';
import { getLang } from 'utils/global';
import { useTranslation } from 'react-i18next';
import { store } from 'store';
import {
  getAllSuppliers,
  getAllProductlevels,
  getGuidelinesByRetailerId,
  getOfferConstraints,
  getOfferForDuplication,
  getOfferForEdit,
  getSegments,
  getTargetingStrategies
} from 'api';

import currencies from 'utils/currencies.json';
import { exitModalTypes, offerStatuses, offerTypes, segmentScopes } from 'utils/constants';
import {
  AddOfferStepValidation,
  BudgetBlock,
  ImageBlock,
  MultiSupplierSelectionBlock,
  ParametersBlock,
  ProductsBlock,
  PromotionBlock,
  SelectRetailerBlock,
  SelectSupplierBlock,
  TargetingStrategiesBlock,
  ValidityDatesBlock
} from 'components/AddOfferSteps';

import {
  checkAddOfferStep1,
  checkAddOfferStep2,
  checkAddOfferStep3,
  checkAddOfferStep4,
  mapOfferToForm
} from './formHelper';

const AddOfferForm = ({ isSuperEditable, onBudgetStepChange, onOfferCreationChange, onOfferSaved }) => {
  const { t } = useTranslation();
  const history = useHistory();
  // for clone mode
  const offerIdToClone = history.location.offerIdToClone;
  // for edit mode
  const { offerId: id } = useParams();
  const offerId = !isNaN(id) && parseInt(id, 10);
  const globalState = useContext(store);
  const { dispatch, state } = globalState;
  const [isBudgetStep, setIsBudgetStep] = useState(false);
  const [initialOfferCreationToDuplicate, setInitialOfferCreationToDuplicate] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [offerCreation, setOfferCreation] = useState(mapOfferToForm({}, state.user));
  const [oldOfferCreationStringified, setOldOfferCreationStringified] = useState('');
  const [resultListBrandRaw, setResultListBrandRaw] = useState([]);
  const [resultListEanRaw, setResultListEanRaw] = useState([]);
  const [validityDatesListRaw, setValidityDatesListRaw] = useState([]);
  const [guidelines, setGuidelines] = useState({ FR: {}, EN: {} });
  const language = getLang().split('-')[0] === 'fr' ? getLang().split('-')[0].toUpperCase() : 'EN';

  const { step1, step4, errors } = offerCreation;
  const isRetailer = state.user.userType === 'retailer';
  const isSupplier = state.user.userType === 'supplier';
  const stepOneError = errors.stepOneError;
  const currentSupplierBudget = state.supplierBudgetsAll.find((s) => s.type === step4.budgetType);

  const suppliersWithBudget = useMemo(
    () => state.suppliers.filter((s) => s.currentYearBudgetTotal || s.nextYearBudgetTotal),
    [state.suppliers]
  );

  const isMultiSupplierUser = state.user.isMultiSupplierAccount;
  const supplierId = isRetailer ? step1.id : isMultiSupplierUser ? step1.multiSupplierId : state.user.id;

  const currentRetailer = isRetailer
    ? state.user
    : isMultiSupplierUser
    ? state.suppliers.find((supplier) => supplier.id === supplierId)?.retailer
    : state.user.retailer;

  const currency = currentRetailer?.currency
    ? { id: 1, code: currentRetailer.currency.code, symbol: currencies[currentRetailer.currency.code] }
    : state.user.currency;

  const offerConstraints = state.offerConstraints.filter(
    (el) => el.type === offerTypes.SUPPLIER_PRODUCT_OFFER && el.retailer.id === currentRetailer?.id
  );

  // keep offerId condition to differentiate edition and duplication
  const hasLinkedCampaigns = offerId && !!state.offers.details.offerCampaigns.length;

  // new EXPIRED status -> disable all inputs but dates
  const isExpiredStatus = state.offers.details.offer?.status === offerStatuses.EXPIRED;

  useEffect(() => {
    const fetchAllSuppliers = async () => {
      await getAllSuppliers(isRetailer ? state.user.id : undefined);
      setIsLoading(false);
    };

    fetchAllSuppliers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getOffer = async (id, isDuplicating) => {
      setIsLoading(true);
      const offer = isDuplicating
        ? await getOfferForDuplication({ currentRetailer, offerHeadId: id, suppliersWithBudget, t })
        : await getOfferForEdit(id);

      setResultListEanRaw(offer.resultListEan);
      setResultListBrandRaw(offer.offerBrands);
      setValidityDatesListRaw(offer.validityDatesList);

      const mappedOffer = mapOfferToForm(offer, state.user);
      setOfferCreation(mappedOffer);
      setOldOfferCreationStringified(getPropertiesForComparison(mappedOffer));
      if (isDuplicating) {
        setInitialOfferCreationToDuplicate(mappedOffer);
      }
      setIsLoading(false);
    };

    const id = offerId || offerIdToClone;
    if (id) {
      getOffer(id, !!offerIdToClone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId, offerIdToClone]);

  useEffect(() => {
    const fetchGuidelines = async (retailerId) => {
      const response = await getGuidelinesByRetailerId(retailerId);
      setGuidelines(response);
    };

    if (!!currentRetailer?.id) {
      fetchGuidelines(currentRetailer.id);
    }
  }, [currentRetailer?.id]);

  useEffect(() => {
    // check if form is not empty
    if (step1.id) {
      dispatch({
        type: 'EXIT_MODAL_TYPE_UPDATE',
        payload: offerId ? exitModalTypes.OFFER_EDITION : exitModalTypes.OFFER_CREATION
      });
    }

    return () => {
      dispatch({
        type: 'EXIT_MODAL_TYPE_UPDATE',
        payload: null
      });
    };
  }, [dispatch, offerId, step1.id]);

  useEffect(() => {
    return () => {
      dispatch({ type: 'SEGMENTS_RESET' });
    };
  }, [dispatch]);

  useEffect(() => {
    const getConstraints = async () => {
      await getOfferConstraints(currentRetailer.id);
    };

    if (!!currentRetailer?.id && !offerIdToClone) {
      getConstraints();
    }
    // eslint-disable-next-line
  }, [currentRetailer?.id]);

  useEffect(() => {
    const fetchProductlevels = async () => {
      const filterBrandList = await getAllProductlevels({
        productLevel: 'brand',
        retailerId: currentRetailer.id,
        supplierId
      });
      const filterCategoryList = await getAllProductlevels({
        productLevel: 'lvl4',
        retailerId: currentRetailer.id,
        supplierId
      });

      if (filterBrandList && filterCategoryList) {
        setOfferCreation((prevState) => ({
          ...prevState,
          step3: {
            ...prevState.step3,
            filterBrandList,
            filterCategoryList
          }
        }));
      }
    };

    if (currentRetailer?.id && supplierId) {
      fetchProductlevels();
    }
  }, [currentRetailer?.id, supplierId, step1.multiSupplierId]);

  useEffect(() => {
    if (currentRetailer?.id) {
      getTargetingStrategies();
    }
  }, [currentRetailer?.id]);

  useEffect(() => {
    if (currentRetailer?.code && currentRetailer?.offerSegmentEnabled && supplierId && !!state.suppliers.length) {
      const supplierCode = state.suppliers.find((sup) => sup.id === supplierId)?.code;
      getSegments({
        retailerCode: currentRetailer.code,
        scope: segmentScopes.OFFER,
        supplierCodes: [supplierCode]
      });
    }
  }, [currentRetailer, state.suppliers, supplierId]);

  useEffect(() => {
    onBudgetStepChange?.(isBudgetStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBudgetStep]);

  useEffect(() => {
    onOfferCreationChange?.(offerCreation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerCreation]);

  // used in budgetBlock for fetching reco only if specific inputs have changed
  const getPropertiesForComparison = (offer) => {
    return JSON.stringify({
      discount: offer.step3.generosity,
      periods: offer.step4.validityDates,
      products: offer.step3.resultListEan,
      quantity: offer.step3.quantity,
      retailer: currentRetailer?.code,
      targetingStrategyId: offer.step2.targetingStrategy.id.toString(),
      externalSegmentId: offer.step2.externalSegment.id
    });
  };

  const handleOfferCreationUpdate = (data = {}) => {
    setOfferCreation((prevState) => {
      const payload = {
        step1: { ...prevState.step1, ...data.step1 },
        step2: { ...prevState.step2, ...data.step2 },
        step3: { ...prevState.step3, ...data.step3 },
        step4: { ...prevState.step4, ...data.step4 },
        errors: { ...prevState.errors, ...data.errors }
      };

      payload.step1.completed = checkAddOfferStep1(payload.step1);
      payload.step2.completed = checkAddOfferStep2(payload.step2);
      payload.step3.completed = checkAddOfferStep3(payload.step3);
      payload.step4.completed = checkAddOfferStep4(payload.step4, currentSupplierBudget?.free);

      return payload;
    });
  };

  return (
    <main>
      {isBudgetStep && (
        <BudgetBlock
          currency={currency}
          currentRetailer={currentRetailer}
          errors={errors}
          freeBudgetData={currentSupplierBudget?.free || 0}
          helperText={guidelines?.[language]?.offerRecommendedMinimumBudget?.content}
          isEditMode={!!offerId}
          offerConstraints={offerConstraints}
          offerCreation={offerCreation}
          offerCreationHasChanged={oldOfferCreationStringified !== getPropertiesForComparison(offerCreation)}
          previousBudgetTarget={state.offers.details.offer?.offerHead?.budgetTarget || undefined}
          supplierBudgets={state.supplierBudgetsAll}
          updateOfferCreation={handleOfferCreationUpdate}
          newDatesYearDiffersFromPreviousSupplierBudgetYear={
            validityDatesListRaw?.[0]?.startDate?.year() !==
            state.supplierBudgetsAll?.find((b) => b.type === offerCreation.step4.budgetType)?.year
          }
        />
      )}

      <div className={clsx(isBudgetStep && 'd-hidden')}>
        {isMultiSupplierUser && (
          <MultiSupplierSelectionBlock
            disabled={!!offerId || isLoading || isExpiredStatus}
            error={stepOneError}
            listData={!!offerId ? state.suppliers : suppliersWithBudget}
            multiSupplierId={offerCreation.step1?.multiSupplierId}
            updateOfferCreation={handleOfferCreationUpdate}
          />
        )}
        {isRetailer && (
          <SelectSupplierBlock
            currentRetailer={currentRetailer}
            disabled={!suppliersWithBudget.length || !!offerId || isLoading || isExpiredStatus}
            error={!isLoading && !suppliersWithBudget.length ? t('commun_no_budget') : stepOneError}
            listData={!!offerId ? state.suppliers : suppliersWithBudget}
            offerCreationStep1Id={offerCreation.step1?.id}
            updateOfferCreation={handleOfferCreationUpdate}
          />
        )}
        {isSupplier && (
          <SelectRetailerBlock
            error={!suppliersWithBudget.length ? t('commun_no_budget') : stepOneError}
            listData={currentRetailer ? [currentRetailer] : []}
            multiSupplierId={offerCreation.step1?.multiSupplierId}
            updateOfferCreation={handleOfferCreationUpdate}
          />
        )}
        <TargetingStrategiesBlock
          currentRetailer={currentRetailer}
          currentSegmentId={offerCreation.step2.externalSegment.id}
          currentStrategyId={offerCreation.step2.targetingStrategy.id.toString()}
          disabled={!step1.id || isExpiredStatus || (hasLinkedCampaigns && !isSuperEditable)}
          errors={errors}
          isDuplicating={!!offerIdToClone}
          isEditMode={!!offerId}
          targetingStrategies={state.targetingStrategies}
          segments={state.segments.listAll}
          updateOfferCreation={handleOfferCreationUpdate}
        />
        <ProductsBlock
          currentRetailer={currentRetailer}
          disabled={!step1.id || isExpiredStatus || (hasLinkedCampaigns && !isSuperEditable)}
          helperText={guidelines?.[language]?.offerProduct?.content}
          isEditMode={!!offerId}
          offerCreation={offerCreation}
          products={state.products}
          supplierId={supplierId || null}
          updateOfferCreation={handleOfferCreationUpdate}
        />
        <ImageBlock
          currentRetailer={currentRetailer}
          disabled={!step1.id || isExpiredStatus}
          offerCreation={offerCreation}
          updateOfferCreation={handleOfferCreationUpdate}
        />
        <PromotionBlock
          currency={currency}
          currentRetailer={currentRetailer}
          disabled={!step1.id || isExpiredStatus || (hasLinkedCampaigns && !isSuperEditable)}
          helperText={guidelines?.[language]?.offerPromotion?.content}
          isEditMode={!!offerId}
          offerConstraints={offerConstraints}
          offerCreation={offerCreation}
          updateOfferCreation={handleOfferCreationUpdate}
        />
        <ParametersBlock
          disabled={!step1.id || isExpiredStatus}
          additionalInformationHelperText={guidelines?.[language]?.offerAdditionalInformation?.content}
          offerAdditionalInformationSize={currentRetailer?.settingOfferAdditionalInformationSize || 500}
          offerCreation={offerCreation}
          offerTitleSize={currentRetailer?.settingOfferTitleSize || 80}
          titleHelperText={guidelines?.[language]?.offerTitle?.content}
          updateOfferCreation={handleOfferCreationUpdate}
        />

        <ValidityDatesBlock
          disabled={!step1.id}
          errors={errors}
          helperText={guidelines?.[language]?.offerDate?.content}
          linkedOfferCampaigns={state.offers.details.offerCampaigns}
          offerConstraints={offerConstraints}
          offerCreation={offerCreation}
          supplierBudgets={state.supplierBudgetsAll}
          updateOfferCreation={handleOfferCreationUpdate}
        />
      </div>

      <AddOfferStepValidation
        budgetData={currentSupplierBudget}
        currentRetailer={currentRetailer}
        isEditMode={!!offerId}
        isSuperEditable={isSuperEditable}
        offerCreation={offerCreation}
        resultListBrandRaw={resultListBrandRaw}
        resultListEanRaw={resultListEanRaw}
        validityDatesListRaw={validityDatesListRaw}
        updateOfferCreation={handleOfferCreationUpdate}
        offerConstraints={offerConstraints}
        isBudgetStep={isBudgetStep}
        initialOfferCreationToDuplicate={initialOfferCreationToDuplicate}
        goToBudgetStep={() => {
          setIsBudgetStep(true);
        }}
        goBackToParametersStep={() => {
          setIsBudgetStep(false);
          setOldOfferCreationStringified(getPropertiesForComparison(offerCreation));
        }}
        onOfferSaved={onOfferSaved}
      />
    </main>
  );
};

export default AddOfferForm;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { config } from '../index';
import FormManager from 'components/FormManager';

import styles from '../ConditionsForm/ConditionsForm.module.scss';

const QuantityBlock = ({ data, groupIndex, listIndex, updateData }) => {
  const { t } = useTranslation();

  return (
    <FormManager
      data={{
        fieldsets: [
          {
            id: `segment-manager-multipurchases-inner-condition-${data.unit}-${groupIndex}-${listIndex}`,
            classnames: [styles['one-line']],
            fields: [
              {
                type: 'TextField',
                classnames: [styles['input'], styles['input-wide']],
                defaultValue: t('segmentManager_static_having_bought_quantity'),
                fieldProps: {
                  readOnly: true,
                  shrinkPlaceholder: false
                },
                id: `inner-condition-wording-static-${data.unit}-${groupIndex}-${listIndex}`
              },
              {
                type: 'Select',
                classnames: [styles['input'], styles['input-small']],
                defaultValue: data.sign || config.lists.purchases.sign[0],
                fieldProps: {
                  listData: config.lists.purchases.sign.map((el) => ({
                    id: el,
                    label: t(`_dyn_commun_select_sign_${el}`)
                  })),
                  shrinkPlaceholder: false
                },
                id: `inner-condition-sign-${data.unit}-${groupIndex}-${listIndex}`,
                onFieldChange: (value) => {
                  const newData = JSON.parse(JSON.stringify(data));
                  newData.sign = value;

                  // update value when sign changed from '$in' to other signs
                  if (newData.value !== null) {
                    if (newData.value?.length && data.sign === '$in' && value !== '$in') {
                      if (newData.value.length > 1) {
                        // reset value when there were multiple values with '$in' sign
                        newData.value = null;
                      } else {
                        // insert unique '$in' value into other signs value
                        newData.value = newData.value[0];
                      }
                    } else if (data.sign !== '$in' && value === '$in') {
                      // update value when sign changed from other signs to '$in'
                      // insert other signs value into '$in' value
                      newData.value = [newData.value];
                    }
                  }

                  updateData(newData);
                }
              },
              {
                type: 'AutocompleteTag',
                classnames: [styles['input'], styles['input-last']],
                outerContainerClass: [styles['input-outerContainer']],
                defaultValue:
                  typeof data.value === 'number' || data.value ? (data.sign === '$in' ? data.value : [data.value]) : [],
                fieldProps: {
                  freeSolo: true,
                  inputType: 'number',
                  multipleValue: data.sign === '$in',
                  valueToLabel: (value) => t('commun_product_count', { count: parseInt(value) || 0 })
                },
                id: `inner-condition-value-${data.unit}-${groupIndex}-${listIndex}`,
                onFieldChange: (value) => {
                  const newData = JSON.parse(JSON.stringify(data));
                  newData.value = value.length
                    ? data.sign === '$in'
                      ? [...new Set(value.map((n) => parseInt(n)))] // restore uniqueness because last input value from AutocompleteTag is a String
                      : parseInt(value[0])
                    : null;
                  updateData(newData);
                },
                placeholder: t('commun_x_products_input_placeholder')
              }
            ]
          }
        ]
      }}
    />
  );
};

QuantityBlock.propTypes = {
  data: PropTypes.object.isRequired,
  groupIndex: PropTypes.string.isRequired,
  listIndex: PropTypes.number.isRequired,
  updateData: PropTypes.func.isRequired
};

export default QuantityBlock;

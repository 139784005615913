import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import AsidePanel from 'components/AsidePanel';

import styles from './NoAllocationPanel.module.scss';

const NoAllocationPanel = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['root']}>
      <AsidePanel>
        <h2 className={styles['title']}>{t('campaign_details_allocation_disabled_frame_title')}</h2>
        <p className={styles['desc']}>{t('campaign_details_allocation_disabled_frame_desc')}</p>
      </AsidePanel>
    </div>
  );
};

export default memo(NoAllocationPanel);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './Dots.scss';

const DotsLoader = ({ className }) => {
  const [step, setStep] = useState(0);
  const steps = [
    ['strong', 'light', 'medium'],
    ['medium', 'strong', 'light'],
    ['light', 'medium', 'strong'],
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setStep((step) => (step < 2 ? step + 1 : 0));
    }, 800);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={`dotsLoader ${className}`}>
      <div className={`dot dot-${steps[step][0]}`} />
      <div className={`dot dot-${steps[step][1]}`} />
      <div className={`dot dot-${steps[step][2]}`} />
    </div>
  );
};

DotsLoader.propTypes = {
  className: PropTypes.string,
};

export default DotsLoader;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { checkInteger } from 'utils/global';

import InputAdornment from 'components/InputAdornment';
import SelectCustom from 'components/SelectCustom';
import TextFieldCustom from 'components/TextFieldCustom';

import styles from './BudgetAndTypeInput.module.scss';

const BudgetAndTypeInput = (props) => {
  const {
    className,
    data: { id, currency, defaultValue, disabled, isEditMode, listData },
    onChange,
    error
  } = props;

  const [state, setState] = useState({
    value: defaultValue.value || '',
    budgetType: defaultValue.budgetType || ''
  });

  useEffect(() => {
    if (defaultValue.value || defaultValue.budgetType) {
      setState(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) onChange(id, state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value, state.budgetType]);

  const handleValueChange = ({ target: { value } }) => {
    if (checkInteger(value) || value === '') {
      const payload = { ...state, value };
      setState(payload);
    }
  };

  const handleTypeChange = ({ target: { value } }) => {
    const payload = { ...state, budgetType: value };
    setState(payload);
  };

  return (
    <>
      <div className={`${styles['root']} ${className}`}>
        <TextFieldCustom
          type="text"
          onChange={handleValueChange}
          name={id}
          id={'value'}
          error={!!error}
          disabled={disabled}
          value={state.value}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div>{currency.symbol}</div>
              </InputAdornment>
            )
          }}
        />
        <SelectCustom
          className={styles['customized-select']}
          id={'budgetType'}
          methodOnChange={handleTypeChange}
          error={!!error}
          disabled={disabled || isEditMode || listData.length < 2}
          value={state.budgetType}
          listData={listData}
        />
      </div>
      {error && typeof error === 'string' && <div className={styles['errorMsg']}>{error}</div>}
    </>
  );
};

BudgetAndTypeInput.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onChange: PropTypes.func
};

export default BudgetAndTypeInput;

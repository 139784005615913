import React from 'react';
import { useTranslation } from 'react-i18next';

import FormManager from 'components/FormManager';

type Props = {
  checked: boolean;
  onChangeState: ({ offerSegmentEnabled }: { offerSegmentEnabled: boolean }) => void;
};

const MarketingStrategies = ({ checked, onChangeState }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <FormManager
      id="marketing-strategies-form-id"
      data={{
        title: t('retailers_creation_enable_offer_segment_params'),
        subTitle: t('retailers_creation_enable_offer_segment_params_desc'),
        fieldsets: [
          {
            id: 'creation-form-marketing-strategy',
            fields: [
              {
                type: 'Switch',
                defaultValue: checked,
                switchLabels: { off: t('commun_switch_OFF_plural'), on: t('commun_switch_ON_plural') },
                fieldProps: { size: 'small' },
                id: 'offerSegmentEnabled',
                onFieldChange: (value: boolean) => {
                  onChangeState({ offerSegmentEnabled: value });
                }
              }
            ]
          }
        ]
      }}
    />
  );
};

export default MarketingStrategies;

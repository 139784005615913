import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { checkIsPositioningLate, getMonthIndexByLevels } from 'utils/budget';
import { withFractionDigits } from 'utils/global';
import { MONTH_i18n_MAPPING } from 'utils/constants';

import Tags from 'components/Tags';
import styles from './DashboardSupplier.module.scss';

import { positionedTargetsLevelType } from 'types/positionedTargetsLevels';

enum TAG_STATUS {
  'LATE' = 'LATE',
  'ON_TRACK' = 'ON_TRACK'
}

type Props = {
  nonPositionedPercentage?: number;
  positionedTargetsLevels?: positionedTargetsLevelType[];
};

const NonPositionedBudgetThresholds = ({ nonPositionedPercentage, positionedTargetsLevels }: Props): JSX.Element => {
  const { t } = useTranslation();

  // data needed to manage isPositioningLate
  const monthIndexByLevels = useMemo(() => getMonthIndexByLevels(positionedTargetsLevels), [positionedTargetsLevels]);

  if (!positionedTargetsLevels) {
    return (
      <div className={styles['targeting-threshold-container']}>
        <div className={styles['targeting-threshold-error']}>
          <p>{t('commun_goals')}</p>
          <p>{t('dashboard_nonPositionedBudgetLevels_error')}</p>
        </div>
      </div>
    );
  }

  const data = [
    monthIndexByLevels?.positionedTargetsLevels_start,
    monthIndexByLevels?.positionedTargetsLevels_half,
    monthIndexByLevels?.positionedTargetsLevels_end
  ].filter((el) => !!el);

  /* TAG_STATUS depends on nonPositionedPercentage and CURRENT_MONTH_INDEX */
  /* returns ON_TRACK if nonPositionedPercentage value is greater than positionedTargetsLevels value linked to CURRENT_MONTH_INDEX  */
  const getTagStatus = () => {
    return checkIsPositioningLate(monthIndexByLevels, nonPositionedPercentage) ? TAG_STATUS.LATE : TAG_STATUS.ON_TRACK;
  };

  return (
    <div className={styles['targeting-threshold-container']}>
      {!!data.length && (
        <>
          <div className={styles['tag']}>
            <Tags status={getTagStatus()} />
          </div>
          <table>
            <thead>
              <tr>
                <th>{t('commun_goals')}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((level) => (
                <tr key={level?.period}>
                  <td>{level?.month ? t(MONTH_i18n_MAPPING[level.month]) : '-'}</td>
                  <td>
                    {level?.value || level?.value === 0
                      ? t('commun_percentage_number', {
                          value: level.value,
                          maximumFractionDigits: withFractionDigits(level.value)
                        })
                      : '-'}
                  </td>
                  <td>{t('commun_budget_nonPositioned')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default NonPositionedBudgetThresholds;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import FormManager from 'components/FormManager';
import HelperText from 'components/HelpText';

const ParametersBlock = ({
  additionalInformationHelperText,
  disabled,
  offerCreation,
  offerTitleSize,
  offerAdditionalInformationSize,
  titleHelperText,
  updateOfferCreation,
}) => {
  const { t } = useTranslation();

  const titleHelperTextBlock =
    typeof titleHelperText !== 'undefined'
      ? [
          {
            id: 'titleHelperText',
            type: (
              <HelperText
                content={titleHelperText}
                title={t('retailers_creation_retailer_guidelines_offer_title_box_title')}
              />
            ),
          },
        ]
      : undefined;

  const additionalInformationHelperTextBlock =
    typeof additionalInformationHelperText !== 'undefined'
      ? [
          {
            id: 'additionalInformationHelperText',
            type: (
              <HelperText
                content={additionalInformationHelperText}
                title={t('retailers_creation_retailer_guidelines_offer_additional_information_box_title')}
              />
            ),
          },
        ]
      : undefined;

  const updateOfferFormState = (data) => {
    updateOfferCreation({
      step3: data,
    });
  };

  const handleTitleChange = (value) => {
    if (value.length <= offerTitleSize) {
      updateOfferFormState({ title: { value } });
    }
  };

  const handleAdditionalInfoChange = (value) => {
    if (value.length <= offerAdditionalInformationSize) {
      updateOfferFormState({ additionalInformation: { value } });
    }
  };

  const handleTagChange = (value) => {
    updateOfferCreation({
      step3: { tag: { value } },
    });
  };

  return (
    <FormManager
      data={{
        title: t('offers_creation_block_parameters_title'),
        fieldsets: [
          {
            id: 'add-offer-name-step',
            fields: [
              {
                label: t('offers_creation_offer_title'),
                onFieldChange: handleTitleChange,
                defaultValue: offerCreation.step3.title.value,
                error: offerCreation.errors.titleError,
                fieldProps: {
                  inputProps: { maxLength: offerTitleSize },
                  multiline: true,
                },

                type: 'TextField',
                id: 'title',
                disabled,
                counter: offerTitleSize,
              },
              ...(titleHelperTextBlock || []),
              {
                classnames: ['without-margin-top'],
                label: t('offers_creation_offer_additionalInformation'),
                onFieldChange: handleAdditionalInfoChange,
                defaultValue: offerCreation.step3.additionalInformation.value,
                fieldProps: {
                  inputProps: { maxLength: offerAdditionalInformationSize },
                  multiline: true,
                },
                type: 'TextField',
                id: 'additionalInformation',
                disabled,
                counter: offerAdditionalInformationSize,
                customMessage: t('commun_optional'),
              },
              ...(additionalInformationHelperTextBlock || []),
              {
                classnames: ['without-margin-top'],
                label: t('commun_tag'),
                onFieldChange: handleTagChange,
                defaultValue: offerCreation.step3.tag.value,
                type: 'TextField',
                id: 'tag',
                disabled,
                info: t('commun_optional'),
              },
            ],
          },
        ],
      }}
    />
  );
};

ParametersBlock.propTypes = {
  additionalInformationHelperText: PropTypes.string,
  disabled: PropTypes.bool,
  offerAdditionalInformationSize: PropTypes.number,
  offerCreation: PropTypes.object,
  offerTitleSize: PropTypes.number,
  titleHelperText: PropTypes.string,
  updateOfferCreation: PropTypes.func,
};

export default memo(ParametersBlock);

import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import { store } from 'store';
import { getRetailers, getCountries } from 'api';
import useDynId from 'customHooks/useDynId';

import ButtonsCustom from 'components/ButtonsCustom';
import Drawer from 'components/Drawer';
import FiltersContainer, { getInitialFilters } from 'components/Filters';
import RetailerDetails from 'pages/RetailerDetails';
import TableCustom from 'components/TableCustom';

import { ReactComponent as NewIcon } from 'assets/24px_new.svg';

import styles from './Retailers.module.scss';

const Retailers = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { id: retailerId } = useParams();
  const {
    dispatch,
    state: { retailersList, countries, queryFilters }
  } = useContext(store);

  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({ type: 'updatedAt', sort: 'desc' });
  const oppositeSort = order.sort === 'asc' ? 'desc' : 'asc';
  const tableSize = 30;
  const today = moment();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initFilters = useMemo(() => getInitialFilters({ history, queryFilters }), []);
  const [filters, setFilters] = useState(initFilters);
  const filtersListToDisplay = ['retailerType'];

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getRetailers({
      filters,
      page,
      orderBy: order.sort,
      sortBy: order.type,
      size: tableSize
    });
    setIsLoading(false);
  }, [filters, order.sort, order.type, page]);

  useEffect(() => {
    getCountries(i18n?.language);
    fetchData();

    dispatch({ type: 'RESET_RETAILER_DETAILS' });

    return () => {
      dispatch({ type: 'RESET_RETAILERS' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const handleCloseDetails = () => {
    history.push({ pathname: '/retailers', search: history.location.search });
    dispatch({ type: 'RESET_RETAILER_DETAILS' });
  };

  const handleTableRowClick = ({ id }) => {
    history.push({ pathname: `/retailers/${id[0].value}`, search: history.location.search });
  };

  const handleUpdateFilters = useCallback((newFilters) => {
    setFilters({ ...newFilters });
  }, []);

  const columns = [
    { id: 1, field: 'new', type: 'component' },
    { id: 2, field: 'code', headerName: t('retailers_list_code'), type: 'text', sortable: true },
    { id: 3, field: 'name', headerName: t('retailers_list_name'), type: 'text', sortable: true },
    { id: 4, field: 'countryCode', headerName: t('retailers_list_country'), type: 'text', sortable: true },
    { id: 5, field: 'type', headerName: t('retailers_list_type'), sortable: true },
    { id: 6, field: 'updatedAt', headerName: t('retailers_list_last_update'), type: 'text', sortable: true }
  ];

  const rows = retailersList.list.map((retailer) => {
    return {
      id: [{ value: retailer.id, color: 'disabled' }],
      new: today.diff(retailer.createdAt, 'days') <= 30 ? <NewIcon className={styles['new-tag']} /> : null,
      code: [{ value: retailer.code, color: 'disabled' }],
      name: [{ value: retailer.name }],
      countryCode: [{ value: countries.find((el) => el.code === retailer.countryCode)?.name || retailer.countryCode }],
      type: [{ value: t(`retailer_type_${retailer.type}`) }],
      updatedAt: [{ value: t('commun_date', { value: retailer.updatedAt }), color: 'disabled' }]
    };
  });

  return (
    <>
      <section className={styles['root']}>
        <div className={styles['top-wrapper']}>
          <div className={styles['header']}>
            <div className={styles['header-title']}>{t('retailers_list_title')}</div>
            <div className={styles['header-desc']}>{t('retailers_list_desc')}</div>
          </div>
        </div>
        <div className={styles['top-wrapper']}>
          <p className={styles['count-result']} id={useDynId('Xretailer')}>
            {t('commun_retailer_count', { count: retailersList.total })}
          </p>
          <Link to={'/retailers/add-retailer'} role="link">
            <ButtonsCustom
              id={useDynId('createRetailerButton')}
              classType="action_primary_big"
              size="medium"
              text={t('commun_button_create_a_retailer')}
            />
          </Link>
        </div>

        <FiltersContainer
          dispatch={dispatch}
          filters={filters}
          filtersListToDisplay={filtersListToDisplay}
          updateFilters={handleUpdateFilters}
        />
        <TableCustom
          isLoading={isLoading}
          rows={rows}
          columns={columns}
          total={retailersList.total}
          handleSort={(type) => type && setOrder({ type: type, sort: type === order.type ? oppositeSort : 'desc' })}
          page={page}
          size={tableSize}
          prev={() => setPage(page - 1)}
          next={() => setPage(page + 1)}
          order={order.sort}
          sort={order.type}
          onRowClick={handleTableRowClick}
          type="firstTag"
          uniqueKey="code"
          isUsingFilters={!!Object.keys(filters)?.length}
          clickedRawUniqueKeyValue={retailerId?.toString()}
        />

        <Drawer isOpen={!!retailerId} onClose={handleCloseDetails}>
          <RetailerDetails />
        </Drawer>
      </section>
    </>
  );
};

export default Retailers;

import React, { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { store } from 'store';
import { deleteCampaign } from 'api';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { exitModalTypes, userTypes } from 'utils/constants';
import { removeAllTokens } from 'utils/token';

import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import Logo from 'components/Logos';

import { ReactComponent as AdminIcon } from 'assets/30px_admin.svg';
import { ReactComponent as CampagneIcon } from 'assets/30px_campagne.svg';
import { ReactComponent as DistributeurIcon } from 'assets/30px_distributeur.svg';
import { ReactComponent as FileIcon } from 'assets/30px_file.svg';
import { ReactComponent as FournisseurIcon } from 'assets/30px_fournisseur.svg';
import { ReactComponent as HomeIcon } from 'assets/30px_home.svg';
import { ReactComponent as ImportIcon } from 'assets/30px_import.svg';
import { ReactComponent as LogoutIcon } from 'assets/30px_logout.svg';
import { ReactComponent as OffreIcon } from 'assets/30px_offre.svg';
import { ReactComponent as PilotageIcon } from 'assets/24px_éclair.svg';
import { ReactComponent as SegmentationIcon } from 'assets/30px_cible.svg';
import { ReactComponent as SettingsIcon } from 'assets/20px_reglages.svg';
import styles from './AppNavigation.module.scss';

const dataNavigation = (user) => [
  {
    id: 'home',
    label: 'Home',
    link: '/home',
    roles: ['ADMIN', 'CREW'],
    types: ['supplier']
  },
  {
    id: 'dashboard',
    label: 'Home',
    link: '/dashboard',
    roles: ['ADMIN', 'CREW'],
    types: ['retailer']
  },
  {
    id: 'pilotageRetailer',
    label: 'Pilotage',
    link: user.userManagerId ? `/monitoring?userManagerId=${user.userManagerId}` : '/monitoring',
    roles: ['ADMIN', 'CREW'],
    types: ['retailer']
  },
  {
    id: 'campain',
    label: 'Campagnes',
    link: '/campaigns',
    roles: ['ADMIN', 'CREW'],
    types: ['retailer']
  },
  {
    id: 'offers',
    label: 'Offres',
    link: '/offers',
    roles: ['ADMIN', 'CREW'],
    types: ['retailer', 'supplier']
  },
  {
    id: 'segmentation',
    label: 'Segmentation',
    link: '/segmentation',
    roles: ['ADMIN', 'CREW'],
    types: ['retailer']
  },
  {
    id: 'retailers',
    label: 'Distributeurs',
    link: '/retailers',
    roles: ['SUPER_ADMIN'],
    types: ['superAdmin']
  },
  {
    id: 'apidocs',
    label: 'ApiDocs',
    link: '/api-docs',
    roles: ['SUPER_ADMIN', 'RETAILER_SERVICE'],
    types: ['superAdmin', 'retailerService']
  },
  {
    id: 'suppliers',
    label: 'Fournisseurs',
    link: user.userManagerId ? `/suppliers?userManagerId=${user.userManagerId}` : '/suppliers',
    roles: ['ADMIN', 'CREW'],
    types: ['retailer']
  },
  {
    id: 'settings',
    label: 'Settings',
    link: '/settings',
    roles: ['ADMIN'],
    types: ['retailer']
  },
  {
    id: 'UseCaseImportFiles',
    label: 'UseCaseImportFiles',
    link: '/use-case-import-files',
    roles: ['SUPER_ADMIN'],
    types: ['superAdmin']
  }
];

const AppNavigation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const globalState = useContext(store);
  const { state, dispatch } = globalState;
  const {
    campaignCreation: { product: campaignCreationProduct },
    exitModalType,
    user
  } = state;

  const [dialogOpened, setDialogOpen] = useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [navLink, setnavLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const iconObj = {
    Admin: <AdminIcon />,
    ApiDocs: <FileIcon />,
    Campagnes: <CampagneIcon />,
    Distributeurs: <DistributeurIcon />,
    Fournisseurs: <FournisseurIcon />,
    Home: <HomeIcon />,
    Logout: <LogoutIcon />,
    Offres: <OffreIcon />,
    Pilotage: <PilotageIcon />,
    Segmentation: <SegmentationIcon />,
    Settings: <SettingsIcon />,
    UseCaseImportFiles: <ImportIcon />
  };

  const pushNav = (event, link) => {
    if (exitModalType) {
      event.preventDefault();
      setDialogOpen(true);
    }

    setnavLink(link);
    dispatch({ type: 'QUERY_FILTERS_RESET' });
  };

  const handleDialogConfirm = async () => {
    let noApiError = true;
    if (exitModalType === exitModalTypes.CAMPAIGN_PRODUCT || exitModalType === exitModalTypes.CAMPAIGN_EDITION) {
      // delete productCampaign if exists in Draft status
      if (campaignCreationProduct.id) {
        setIsLoading(true);
        noApiError = await deleteCampaign({ campaign: campaignCreationProduct });
        setIsLoading(false);
      }
    }
    if (noApiError) {
      setDialogOpen(false);
      dispatch({ type: 'CAMPAIGN_CREATION_RESET' });
      dispatch({ type: 'CAMPAIGNS_DETAILS_RESET' });
      dispatch({ type: 'OFFERS_DETAILS_RESET' });
      dispatch({ type: 'PRODUCTS_RESET' }); // offer creation
      history.push(navLink);

      /* fix a bug when a supplier user clicks on "create offer" while on offer creation / edition page */
      /* as the url / router does not change, the component is not properly unmounted and many useSates keep and display the state of the old offer */
      if (
        (exitModalType === exitModalTypes.OFFER_CREATION || exitModalType === exitModalTypes.OFFER_EDITION) &&
        navLink === '/offers/add-offer'
      ) {
        window.location.reload();
      }
    }
  };

  const handleDialogCancel = () => {
    setDialogOpen(false);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    setLogoutDialogOpen(true);
  };

  const handleLogoutCancel = () => {
    setLogoutDialogOpen(false);
  };
  const handleLogoutConfirm = async () => {
    // delete Draft productCampaign if exists before logout
    if (exitModalType === exitModalTypes.CAMPAIGN_PRODUCT || exitModalType === exitModalTypes.CAMPAIGN_EDITION) {
      if (campaignCreationProduct.id) {
        setIsLoading(true);
        await deleteCampaign({ campaign: campaignCreationProduct });
        setIsLoading(true);
      }
    }
    removeAllTokens();
    document.location.reload();
  };

  const displayContentDialogTitle = () => {
    switch (exitModalType) {
      case exitModalTypes.CAMPAIGN_EDITION:
        return t('campaign_edition_leave_modal_message');
      case exitModalTypes.CAMPAIGN_CASH_COUPON:
      case exitModalTypes.CAMPAIGN_PRODUCT:
        return t('campaign_creation_leave_modal_message');
      case exitModalTypes.OFFER_CREATION:
        return (
          <>
            {t('offers_creation_confirmation_message')}
            <br />
            <br />
            {t('offers_creation_confirmation_message_not_saved')}
          </>
        );
      case exitModalTypes.OFFER_EDITION:
        return t('offers_edition_leave_popup_confirmation_message');
      case exitModalTypes.SEGMENT:
        return t('segmentManager_leave_modal_message');
      default:
        return '';
    }
  };

  const pathCreate = user.userType !== userTypes.RETAILER ? '/offers/add-offer' : '/campaigns/add-campaign';

  return (
    <section className={styles['app-navigation']}>
      <div className={styles['app-navigation-logo']}>
        <Link
          to={'/'}
          onClick={(event) => {
            pushNav(event, '/');
          }}
          role="link"
        >
          {user.userType === userTypes.RETAILER && user?.logo?.url ? (
            <img src={user.logo.url} alt={user.logo.name} />
          ) : (
            <Logo />
          )}
        </Link>
      </div>
      <div className={styles['app-navigation-bottom']}>
        <div>
          <div className={styles['home-button']}>
            {user.userType !== 'superAdmin' && user.userType !== 'retailerService' && (
              <Link
                to={pathCreate}
                onClick={(event) => {
                  pushNav(event, pathCreate);
                }}
                role="link"
              >
                <ButtonsCustom
                  classType="action_primary_home"
                  text={
                    user.userType !== 'retailer' ? t('commun_button_create_offer') : t('commun_button_create_campaign')
                  }
                />
              </Link>
            )}
          </div>
          <ul
            className={`${styles['app-navigation-sections']} ${
              user.userType === 'superAdmin' ? styles['extra-margin'] : ''
            }`}
          >
            {dataNavigation(user).map((item) => {
              const isGrantedType = item.types.includes(user.userType);
              const showItemMenu = item.roles.some((role) => user.roles?.includes(role));
              const itemMenu = (
                <li key={item.id}>
                  <NavLink
                    to={item.link}
                    activeClassName={styles['link-active']}
                    onClick={(event) => {
                      pushNav(event, item.link);
                    }}
                    role="link"
                    id={`menu_${user.userType}_${item.id}`}
                  >
                    <span className={styles['app-navigation-sections-icons']}>{iconObj[item.label]}</span>
                    {t(`_dyn_navigation_${item.label}`)}
                  </NavLink>
                </li>
              );

              return showItemMenu && isGrantedType && itemMenu;
            })}
          </ul>
        </div>
        <div>
          <ul
            className={`${styles['app-navigation-sections']} ${
              user.userType === 'superAdmin' ? styles['extra-margin'] : ''
            }`}
          >
            <li>
              <NavLink to={'/'} onClick={handleLogout} role="link">
                <span className={styles['app-navigation-sections-icons']}>{iconObj.Logout}</span>
                {t('navigation_logout')}
              </NavLink>
            </li>
          </ul>

          <div className={styles['legals-container']}>
            <NavLink
              to={'/legal-mentions'}
              onClick={(event) => {
                pushNav(event, '/legal-mentions');
              }}
              role="link"
            >
              {t('legals_legal_mentions')}
            </NavLink>
            <NavLink
              to={'/privacy-policy'}
              onClick={(event) => {
                pushNav(event, '/privacy-policy');
              }}
              role="link"
            >
              {t('legals_privacy_policy')}
            </NavLink>
          </div>
        </div>
      </div>
      <ContentDialog
        centerText
        isLoading={isLoading}
        isOpen={dialogOpened}
        handleClose={handleDialogCancel}
        title={displayContentDialogTitle()}
        maxWidth="xs"
      >
        <div className={styles['confirm-dialog']}>
          <ButtonsCustom
            classType="canceled"
            text={t('commun_button_cancel')}
            method={handleDialogCancel}
            disabled={isLoading}
          />
          <ButtonsCustom
            classType="action_primary_big"
            text={t('commun_button_continue')}
            method={handleDialogConfirm}
            loading={isLoading}
          />
        </div>
      </ContentDialog>
      <ContentDialog
        centerText
        isLoading={isLoading}
        isOpen={logoutDialogOpen}
        handleClose={handleLogoutCancel}
        title={t('navigation_logout_popup_title')}
        maxWidth="xs"
      >
        <div className={styles['confirm-dialog']}>
          <ButtonsCustom
            classType="canceled"
            text={t('commun_button_cancel')}
            method={handleLogoutCancel}
            disabled={isLoading}
          />
          <ButtonsCustom
            classType="action_primary_big"
            text={t('commun_button_continue')}
            method={handleLogoutConfirm}
            disabled={isLoading}
          />
        </div>
      </ContentDialog>
    </section>
  );
};

export default AppNavigation;

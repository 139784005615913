import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import DateRangePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { ClickAwayListener } from '@mui/material';
import Tooltip from 'components/Tooltip';

import { ReactComponent as DatePickerCalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as CalendarArrowLeftIcon } from 'assets/calendar_arrow_left.svg';
import { ReactComponent as CalendarArrowRightIcon } from 'assets/calendar_arrow_right.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './DateRangePicker.scss';

const userLocale = navigator.language.split('-')[0];
const popperModifiers = [
  {
    name: 'offset',
    options: {
      offset: [10, 0]
    }
  },
  {
    name: 'preventOverflow',
    options: {
      rootBoundary: 'fixed',
      tether: true,
      altAxis: false
    }
  }
];

const CustomDateRangePicker = ({
  className,
  disabled,
  disableYearSelection,
  endDate,
  error,
  errorMsg,
  fullWidth,
  getFocusInput,
  highlightDates,
  id,
  isOutsideRange,
  minDate,
  onDatesChange,
  startDate
}) => {
  const { t } = useTranslation();
  const [isEndDatePickerOpen, setIsEndDatePickerOpen] = useState(false);
  const [isStartDatePickerOpen, setIsStartDatePickerOpen] = useState(false);
  const [inputFocusState, setInputFocusState] = useState(null); // -> startDate, endDate, null when datepicker is closed

  const periodUsedInACampaignTooltip = t('offer_details_period_in_campaign_tooltip', { count: 1 });
  const simpleFormatedDateInHighlightDates = highlightDates?.map((d) => moment(d).format());

  // when an offer-period is in a campaign, its start date can not be changed
  const disableStartDate = disabled === 'startDate';

  const monthsList = [
    t('commun_month_full_01'),
    t('commun_month_full_02'),
    t('commun_month_full_03'),
    t('commun_month_full_04'),
    t('commun_month_full_05'),
    t('commun_month_full_06'),
    t('commun_month_full_07'),
    t('commun_month_full_08'),
    t('commun_month_full_09'),
    t('commun_month_full_10'),
    t('commun_month_full_11'),
    t('commun_month_full_12')
  ];

  useEffect(() => {
    getFocusInput?.(inputFocusState);
  }, [getFocusInput, inputFocusState]);

  useEffect(() => {
    const value = isEndDatePickerOpen ? 'endDate' : isStartDatePickerOpen ? 'startDate' : null;
    setInputFocusState(value);
  }, [isEndDatePickerOpen, isStartDatePickerOpen]);

  const yearsList = useMemo(() => {
    const currYear = moment().year();
    const pastYear = currYear - 9;
    const futurYear = currYear + 20;
    const arr = [];
    let counter = 0;
    do {
      arr.push(pastYear + counter);
      counter += 1;
    } while (counter + pastYear < futurYear);
    return arr;
  }, []);

  const CustomHeader = ({
    changeMonth,
    changeYear,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    monthDate: date
  }) => {
    return (
      <div className="custom-header">
        {customHeaderCount === 0 && (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              decreaseMonth(e);
            }}
          >
            <CalendarArrowLeftIcon />
          </button>
        )}

        <select
          value={monthsList[date.getMonth()]}
          onChange={({ target: { value } }) => {
            changeMonth(monthsList.indexOf(value));
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {monthsList.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        {!disableYearSelection && (
          <select
            value={date.getFullYear()}
            onChange={({ target: { value } }) => {
              changeYear(value);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {yearsList.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}

        {customHeaderCount === 1 && (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              increaseMonth(e);
            }}
          >
            <CalendarArrowRightIcon />
          </button>
        )}
      </div>
    );
  };

  const CustomDayContents = (day, date) => {
    const isInACampaign = simpleFormatedDateInHighlightDates?.includes(moment(date).format());
    const tooltipText = isInACampaign ? periodUsedInACampaignTooltip : '';
    return (
      <Tooltip title={tooltipText} placement="top">
        <span>{day}</span>
      </Tooltip>
    );
  };

  const handleFocusInputOnCalendarClose = () => {
    setInputFocusState(null);
    setIsEndDatePickerOpen(false);
    setIsStartDatePickerOpen(false);
  };

  const handleIsOutsideRange = (date) => {
    return !isOutsideRange?.(moment(date));
  };

  const handleOnChange = (date) => {
    const startD = inputFocusState === 'startDate' ? date : startDate;
    let endD = inputFocusState === 'endDate' ? date : endDate;
    if (startD && endD && moment(startD).isAfter(endD)) {
      endD = null;
    }
    onDatesChange({
      startDate: startD ? moment(startD).startOf('day') : null,
      endDate: endD ? moment(endD).endOf('day') : null
    });
    if (inputFocusState === 'startDate') {
      setIsEndDatePickerOpen(true);
      setIsStartDatePickerOpen(false);
      setInputFocusState('endDate');
    }
    if (inputFocusState === 'endDate') {
      handleFocusInputOnCalendarClose();
    }
  };

  return (
    <div
      className={clsx(
        'custom-date-range-picker-container',
        disabled && typeof disabled === 'boolean' && 'totalytDisabled',
        inputFocusState === 'endDate' && 'enddate-arrow-position',
        fullWidth && 'fullWidth',
        className
      )}
      id={id}
    >
      <div
        className={clsx('datepicker-fake-input', error && 'error', fullWidth && 'fullWidth')}
        id={`${id}-datepicker-fake-input`}
      >
        <div className="fake-input">
          <div
            className={clsx(
              disableStartDate && 'fake-input-disabled',
              inputFocusState === 'startDate' && isStartDatePickerOpen && 'focus'
            )}
            onClick={(e) => {
              e.preventDefault();
              setIsEndDatePickerOpen(false);
              setIsStartDatePickerOpen(true);
            }}
            role="button"
          >
            <span>{startDate ? t('commun_date', { value: startDate.format() }) : t('commun_start_date')}</span>
          </div>
          <span>&#8594;</span>
          <div
            className={clsx(inputFocusState === 'endDate' && isEndDatePickerOpen && 'focus')}
            onClick={(e) => {
              e.preventDefault();
              setIsEndDatePickerOpen(true);
              setIsStartDatePickerOpen(false);
            }}
            role="button"
          >
            <span>{endDate ? t('commun_date', { value: endDate.format() }) : t('commun_end_date')}</span>
          </div>
        </div>
        <DatePickerCalendarIcon />
      </div>

      {isStartDatePickerOpen && (
        <ClickAwayListener onClickAway={handleFocusInputOnCalendarClose}>
          <DateRangePicker
            dateFormat={userLocale === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
            disabledKeyboardNavigation
            endDate={endDate && moment(endDate).toDate()}
            filterDate={handleIsOutsideRange}
            highlightDates={highlightDates}
            id={`${id}-daterangepicker-start`}
            inline
            minDate={minDate && moment(minDate).toDate()}
            monthsShown={2}
            onChange={handleOnChange}
            popperModifiers={popperModifiers}
            popperPlacement="bottom-start"
            popperProps={{ strategy: 'fixed' }}
            renderCustomHeader={CustomHeader}
            renderDayContents={CustomDayContents}
            selected={startDate && moment(startDate).toDate()}
            selectsDisabledDaysInRange={!!endDate}
            selectStart
            startDate={startDate && moment(startDate).toDate()}
            showPopperArrow={false}
          />
        </ClickAwayListener>
      )}
      {isEndDatePickerOpen && (
        <ClickAwayListener onClickAway={handleFocusInputOnCalendarClose}>
          <DateRangePicker
            className="enddate-arrow-position"
            dateFormat={userLocale === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
            disabledKeyboardNavigation
            endDate={endDate && moment(endDate).toDate()}
            filterDate={handleIsOutsideRange}
            highlightDates={highlightDates}
            id={`${id}-daterangepicker-end`}
            inline
            minDate={startDate ? moment(startDate).toDate() : minDate && moment(minDate).toDate()}
            monthsShown={2}
            onChange={handleOnChange}
            popperModifiers={popperModifiers}
            popperPlacement="bottom-start"
            popperProps={{ strategy: 'fixed' }}
            renderCustomHeader={CustomHeader}
            renderDayContents={CustomDayContents}
            selected={endDate && moment(endDate).toDate()}
            selectsDisabledDaysInRange
            selectsEnd
            startDate={startDate && moment(startDate).toDate()}
            showPopperArrow={false}
          />
        </ClickAwayListener>
      )}
      {error && typeof errorMsg === 'string' && <p className="error">{errorMsg}</p>}
    </div>
  );
};

CustomDateRangePicker.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disableYearSelection: PropTypes.bool,
  endDate: PropTypes.instanceOf(moment),
  error: PropTypes.bool,
  errorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fullWidth: PropTypes.bool,
  getFocusInput: PropTypes.func,
  highlightDates: PropTypes.array,
  id: PropTypes.string.isRequired,
  isOutsideRange: PropTypes.func,
  minDate: PropTypes.instanceOf(moment),
  onDatesChange: PropTypes.func.isRequired,
  startDate: PropTypes.instanceOf(moment)
};

export default CustomDateRangePicker;

import React, { Fragment, memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { duplicateCampaign, getDuplicateCampaignStatus } from 'api';
import useDynId from 'customHooks/useDynId';
import ButtonsCustom from 'components/ButtonsCustom';
import ContentDialog from 'components/ContentDialog';
import FormManager from 'components/FormManager';
import Loader from 'components/Loaders/Dots';

import { ReactComponent as WarningIcon } from 'assets/warning_icon.svg';
import styles from './DuplicateDialog.module.scss';
import { campaignTypes } from 'utils/constants';

const duplicableTypeInfos = {
  OFFERS: 'OFFERS',
  SEGMENTS: 'SEGMENTS',
  CASH_COUPONS: 'CASH_COUPONS',
  ADDITIONAL_OFFERS: 'ADDITIONAL_OFFERS',
};

const DuplicateDialogContent = ({ campaign, duplicableInitialDates, duplicableOfferDatesLimits, isOpen, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const today = moment();
  const duplicateDatepickerId = useDynId('duplicate_datepicker');
  const isProductCampaign = campaign.campaignType === campaignTypes.PRODUCT;

  const [dates, setDates] = useState();
  const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
  const [duplicableStatus, setDuplicableStatus] = useState({});
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(null);

  useEffect(() => {
    if (duplicableInitialDates) {
      setDates(duplicableInitialDates);
    }
  }, [duplicableInitialDates]);

  useEffect(() => {
    const fetchDuplicateStatus = async () => {
      setIsDuplicateLoading(true);
      const duplicateStatusData = await getDuplicateCampaignStatus({
        campaignId: campaign?.id,
        validityEndDate: dates.endDate,
        validityStartDate: dates.startDate,
      });
      setIsDuplicateLoading(false);
      if (duplicateStatusData) {
        /* COUP-3227 : group "Offers X Segments" errors */
        const groupedErrors = {
          ...duplicateStatusData,
          infos: duplicateStatusData.infos.reduce((acc, value) => {
            const foundIndex = acc.findIndex((el) => el.id === value.id);
            if (foundIndex !== -1) {
              acc[foundIndex].code2 = value.code;
              acc[foundIndex].message2 = value.message;
            } else {
              acc.push(value);
            }
            return acc;
          }, []),
        };
        setDuplicableStatus(groupedErrors);
      }
    };
    if (isOpen && campaign?.id && dates?.startDate && dates?.endDate) fetchDuplicateStatus();
  }, [campaign, dates, isOpen]);

  const handleClose = () => {
    if (!isDuplicateLoading) onClose?.();
  };
  const handleDuplicate = async () => {
    const newCampaign = await duplicateCampaign({
      campaignId: campaign?.id,
      validityEndDate: dates.endDate,
      validityStartDate: dates.startDate,
    });
    if (newCampaign?.id) {
      history.push(`/campaigns/${newCampaign.id}`);
      handleClose();
    }
  };

  const handleDates = ({ startDate, endDate }) => {
    setDates({ startDate, endDate });
  };

  const isOutsideRange = (date) => {
    if (duplicableOfferDatesLimits?.endDate) {
      const maxDate = moment.max(today, moment(duplicableOfferDatesLimits.endDate));
      return date.isAfter(maxDate, 'day') || date.isBefore(today, 'day');
    }
    return date.isBefore(today, 'day');
  };

  const displayCashcouponErrors = () => {
    return (
      <div className={styles['error-list-group']}>
        <div>{t('campaign_duplicate_offers_x_segments')}</div>
        <ul>
          {duplicableStatus.infos.map((el) => {
            const codeErrorLabelBr =
              el.code && 'CASH001' === el.code ? '- ' + t(`campaign_duplicate_status_error_code_${el.code}`) : '';
            const codeErrorLabelSegment = el.code2
              ? '- ' + t(`campaign_duplicate_status_error_code_${el.code2}`)
              : el.code && 'CASH001' !== el.code
              ? '- ' + t(`campaign_duplicate_status_error_code_${el.code}`)
              : '';
            return (
              <Fragment key={el.id}>
                <li>{`${el.id} - ${el.title} ${codeErrorLabelBr}`}</li>
                <li>{`${el.idCashCouponSegment} - ${el.titleCashCouponSegment} ${codeErrorLabelSegment}`}</li>
                <br />
              </Fragment>
            );
          })}
        </ul>
      </div>
    );
  };

  const displayProductErrors = () => {
    const offersErrorList = duplicableStatus.infos
      .filter((el) => el.type === duplicableTypeInfos.OFFERS)
      .sort((a, b) => (a.id > b.id ? -1 : 1));
    const additionalOffersError = duplicableStatus.infos.find(
      (el) => el.type === duplicableTypeInfos.ADDITIONAL_OFFERS
    );
    const segmentError = duplicableStatus.infos.find((el) => el.type === duplicableTypeInfos.SEGMENTS);
    return (
      <div>
        {!!offersErrorList.length && (
          <div className={styles['error-list-group']}>
            <div>{t(`campaign_duplicate_status_error_type_${duplicableTypeInfos.OFFERS}`)}</div>
            <ul>
              {offersErrorList.map((el) => {
                const codeErrorLabel = el.code ? '- ' + t(`campaign_duplicate_status_error_code_${el.code}`) : '';
                return <li key={`${el.id}-${el.code}`}>{`${el.id} - ${el.title} ${codeErrorLabel}`}</li>;
              })}
            </ul>
          </div>
        )}
        {!!additionalOffersError && (
          <div className={styles['error-list-group']}>
            <div>{t(`campaign_duplicate_status_error_type_${duplicableTypeInfos.ADDITIONAL_OFFERS}`)}</div>
            <div>
              {t('campaign_duplicate_status_error_code_OFF003_desc1', {
                text1: campaign.paramsOfferCampaign.offersMinimumNumber,
                text2:
                  campaign.paramsOfferCampaign.offersMinimumNumber - additionalOffersError.numberOfDefaultProductOffers,
              })}
            </div>
            <div>{t('campaign_duplicate_status_error_code_OFF003_desc2')}</div>
          </div>
        )}
        {!!segmentError && (
          <div className={styles['error-list-group']}>
            <div>{t(`campaign_duplicate_status_error_type_${duplicableTypeInfos.SEGMENTS}`)}</div>
            <ul>
              {[segmentError].map((el) => {
                const codeErrorLabel = el.code ? '- ' + t(`campaign_duplicate_status_error_code_${el.code}`) : '';
                return <li key={el.id}>{`${el.id} - ${el.title} ${codeErrorLabel}`}</li>;
              })}
            </ul>
          </div>
        )}
      </div>
    );
  };

  return (
    <ContentDialog
      isLoading={isDuplicateLoading}
      isOpen={isOpen}
      handleClose={handleClose}
      className={styles['custom-width-dialog']}
      maxWidth="md"
      spaceless
    >
      <div className={`${styles['root']} ${isDatePickerOpen ? styles['datepicker-open'] : ''}`}>
        <h2 className={styles['title']}>{t('campaign_duplicate_dialog_title')}</h2>
        <div className={styles['desc']}>{t('campaign_duplicate_dialog_desc')}</div>
        <FormManager
          data={{
            fieldsets: [
              {
                id: 'add-campaign-cash-coupon-step',
                fields: [
                  {
                    classnames: ['mini-input-field', 'dateRangePicker', 'fullWidth'],
                    type: 'DateRangePicker',
                    id: duplicateDatepickerId,
                    defaultValue: dates,
                    onFieldChange: handleDates,
                    fieldProps: {
                      isOutsideRange,
                      getFocusInput: (focus) => {
                        setIsDatePickerOpen(!!focus);
                      },
                    },
                    validations: [
                      {
                        func: (value) => value?.startDate && value?.endDate,
                        message: t('commun_field_required'),
                      },
                    ],
                  },
                ],
              },
            ],
          }}
        />
        {!isDuplicateLoading && !duplicableStatus?.duplicable && (
          <div className={styles['error']}>{t('campaign_duplicate_no_compatible_offers')}</div>
        )}

        <div className={styles['validationButtons']}>
          <ButtonsCustom
            classType="canceled"
            disabled={isDuplicateLoading}
            id={useDynId('step3Cancel')}
            method={onClose}
            text={t('commun_cancel')}
          />
          <ButtonsCustom
            classType="action_primary_big"
            disabled={isDuplicateLoading || !duplicableStatus?.duplicable} // no compatible offers
            id={useDynId('duplicate')}
            loading={isDuplicateLoading}
            method={handleDuplicate}
            text={t('campaign_duplicate_dialog_button_confirm')}
          />
        </div>

        {isDuplicateLoading && (
          <div className={styles['loading']}>
            <p>{t('commun_loading')}</p>
            <Loader />
          </div>
        )}

        {!isDuplicateLoading && !!duplicableStatus?.infos?.length && (
          <div className={styles['error']}>
            <div className={styles['error-list-title']}>
              <WarningIcon />
              {t('campaign_duplicate_some_incompatible_offers')}
            </div>
            {isProductCampaign ? displayProductErrors() : displayCashcouponErrors()}
          </div>
        )}
      </div>
    </ContentDialog>
  );
};

DuplicateDialogContent.propTypes = {
  campaign: PropTypes.object,
  duplicableInitialDates: PropTypes.shape({
    endDate: PropTypes.instanceOf(moment),
    startDate: PropTypes.instanceOf(moment),
  }),
  duplicableOfferDatesLimits: PropTypes.shape({
    endDate: PropTypes.instanceOf(moment),
    startDate: PropTypes.instanceOf(moment),
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default memo(DuplicateDialogContent);

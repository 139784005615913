import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { useTranslation } from 'react-i18next';
import { campaignTargetingStatuses, campaignTypes } from 'utils/constants';
import { withFractionDigits } from 'utils/global';
import AsidePanel from 'components/AsidePanel';
import FramedIcon from 'components/FramedIcon';
import Tooltip from 'components/Tooltip';

import { ReactComponent as BudgetSpendableIcon } from 'assets/bourse_empty.svg';
import { ReactComponent as BudgetSpentIcon } from 'assets/20px_bourse.svg';
import { ReactComponent as InfoIcon } from 'assets/30px_info_round.svg';
import { ReactComponent as MassBRIcon } from 'assets/24px_mass_BR.svg';
import { ReactComponent as PeopleIcon } from 'assets/24px_people.svg';
import { ReactComponent as RecrutementIcon } from 'assets/24px_recrutement.svg';
import { ReactComponent as RetailerIcon } from 'assets/24px_fidélisation.svg';
import { ReactComponent as ReturnRateIcon } from 'assets/24px_return.svg';
import { ReactComponent as TargetIcon } from 'assets/30px_cible.svg';
import { ReactComponent as SupplierIcon } from 'assets/30px_fournisseur.svg';

import './CampaignKeypointsPanel.scss';

const CampaignKeypointsPanel = ({ campaign }) => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { state } = globalState;
  const currencyCode = state.user.currency?.code;

  const {
    campaignType,
    targetingKpis,
    targetingKpisOfferCampaign,
    targetingStatus,
    transactionalKpisCashCouponCampaign,
    transactionalKpisOfferCampaign
  } = campaign;

  const isAllocationSuccess = targetingStatus === campaignTargetingStatuses.SUCCESS;

  const { printedCouponsPerClient, sampleATargetedClients } = targetingKpis || {};
  const { numberOfSupplierProductOffers, totalOfSpendableBudget } = targetingKpisOfferCampaign || {};
  const { discountGranted, rateUsedClientsVsTargetedClients, sampleATargetedShoppers } =
    transactionalKpisOfferCampaign || {};
  const { distinctUsedClient, emittedCoupons, returnRateVsEmitted, returnRateVsTargeted, usedCoupons } =
    transactionalKpisCashCouponCampaign || {};

  const cashCouponKpis =
    isAllocationSuccess && [campaignTypes.CASH_COUPON, campaignTypes.MIXED].includes(campaignType) && targetingKpis
      ? [
          [
            {
              id: 'sampleATargetedClients_cashcoupon',
              icon: <TargetIcon />,
              hasTooltip: true,
              rawValue: sampleATargetedClients || 0,
              value: t('commun_number', { value: sampleATargetedClients ?? '-' })
            },
            {
              id: 'distinctUsedClient',
              icon: <RetailerIcon />,
              hasTooltip: true,
              rawValue: distinctUsedClient || 0,
              value: t('commun_number', { value: distinctUsedClient ?? '-' })
            },
            {
              id: 'emittedCoupons', // display only if rawValue exists
              icon: <MassBRIcon />,
              rawValue: emittedCoupons || 0,
              value: t('commun_number', { value: emittedCoupons ?? '-' })
            },
            {
              id: 'averageCouponsPerClient',
              icon: <RecrutementIcon />,
              hasTooltip: true,
              rawValue: printedCouponsPerClient || 0,
              value: t('commun_number', {
                value: printedCouponsPerClient ?? '-',
                maximumFractionDigits: withFractionDigits(printedCouponsPerClient)
              })
            },
            {
              id: 'usedCoupons',
              icon: <PeopleIcon />,
              hasTooltip: true,
              rawValue: usedCoupons || 0,
              value: t('commun_number', { value: usedCoupons ?? '-' })
            }
          ],
          [
            {
              id: 'returnRateVsEmitted', // display only if rawValue exists
              icon: <ReturnRateIcon />,
              hasTooltip: true,
              rawValue: returnRateVsEmitted || 0,
              value: `${t('commun_number', {
                value: returnRateVsEmitted ?? '-',
                maximumFractionDigits: withFractionDigits(returnRateVsEmitted)
              })} %`
            },
            {
              id: 'returnRateVsTargeted',
              icon: <ReturnRateIcon />,
              hasTooltip: true,
              rawValue: returnRateVsTargeted || 0,
              value: `${t('commun_number', {
                value: returnRateVsTargeted ?? '-',
                maximumFractionDigits: withFractionDigits(returnRateVsTargeted)
              })} %`
            }
          ]
        ]
      : null;

  const productKpis =
    campaignTypes.PRODUCT === campaignType && targetingKpisOfferCampaign
      ? [
          [
            {
              id: 'numberOfSupplierProductOffers',
              icon: <SupplierIcon />,
              rawValue: numberOfSupplierProductOffers || 0,
              value: t('commun_number', { value: numberOfSupplierProductOffers ?? '-' })
            },
            {
              id: 'totalOfSpendableBudget',
              icon: <BudgetSpendableIcon />,
              hasTooltip: true,
              rawValue: totalOfSpendableBudget || 0,
              value: currencyCode
                ? t('commun_price', {
                    currency: currencyCode,
                    maximumFractionDigits: withFractionDigits(totalOfSpendableBudget),
                    value: totalOfSpendableBudget ?? '-'
                  })
                : ''
            },
            {
              id: 'discountGranted',
              icon: <BudgetSpentIcon />,
              rawValue: discountGranted || 0,
              hasTooltip: true,
              value: currencyCode
                ? t('commun_price', {
                    currency: currencyCode,
                    maximumFractionDigits: withFractionDigits(discountGranted),
                    value: discountGranted ?? '-'
                  })
                : '-'
            },
            {
              id: 'sampleATargetedShoppers',
              icon: <TargetIcon />,
              hasTooltip: true,
              rawValue: sampleATargetedShoppers || 0,
              value: t('commun_number', { value: sampleATargetedShoppers ?? '-' })
            },
            {
              id: 'rateUsedClientsVsTargetedClients',
              icon: <PeopleIcon />,
              hasTooltip: true,
              rawValue: rateUsedClientsVsTargetedClients || 0,
              value: t('commun_number', {
                value: rateUsedClientsVsTargetedClients ?? '-',
                maximumFractionDigits: withFractionDigits(rateUsedClientsVsTargetedClients)
              })
            }
          ]
        ]
      : null;

  const displayKeypoint = ({ hasTooltip, icon, id, isPrimaryKeypoint, rawValue, value }) => {
    return (
      <div className="keypoint-container">
        <div>
          <div className={`keypoint-block ${isPrimaryKeypoint ? 'primaryKeypoint' : ''}`}>
            <FramedIcon icon={icon} size="big" />
            <div className="data-container">
              <p>{value}</p>
              <p>
                {t(`_dyn_campaign_details_keypoints_${id}`, { count: rawValue })}
                {hasTooltip && (
                  <Tooltip
                    placement="left"
                    title={hasTooltip ? t(`_dyn_campaign_details_keypoints_${id}_tooltip`) : ''}
                  >
                    <span className="tooltip-icon">
                      <InfoIcon />
                    </span>
                  </Tooltip>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const kpis = [cashCouponKpis, productKpis];

  return kpis.map((kpi) => {
    if (!kpi) return null;
    return kpi.map((group, kpisIndex) => {
      return (
        <AsidePanel className="campaignKeypointsPanel" thinContainer key={kpisIndex.toString()}>
          {kpisIndex === 0 && <h2 className="title">{t('campaign_details_keypoints_title')}</h2>}
          {group.map((keypoint, groupIndex) => {
            if (typeof keypoint.rawValue !== 'number') return false;
            return (
              <React.Fragment key={keypoint.id}>
                {displayKeypoint(keypoint)}
                {!keypoint.isPrimaryKeypoint && groupIndex < group.length - 1 && <div className="divider" />}
              </React.Fragment>
            );
          })}
        </AsidePanel>
      );
    });
  });
};

CampaignKeypointsPanel.propTypes = {
  title: PropTypes.string
};

export default memo(CampaignKeypointsPanel);

import React, { useState, memo, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getCashCoupons } from 'api';
import { scrollToElementById } from 'utils/global';
import useDynId from 'customHooks/useDynId';
import ButtonsCustom from 'components/ButtonsCustom';
import BrSelection from './BrSelection';
import CashCouponForm from 'components/CashCouponForm';
import FormManager from 'components/FormManager';

const CashCouponStep2 = forwardRef(
  ({ campaignCashCoupon, cashCouponsListAll, disabled, segmentsListAll, updateCashCoupon, user }, ref) => {
    const { t } = useTranslation();
    const thisRef = useRef();

    const [fieldError, setFieldError] = useState([]);
    const [errorMessage, setErrorMessage] = useState([]);
    const [openCashCouponDialog, setOpenCashCouponDialog] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      if (campaignCashCoupon.date.startDate && campaignCashCoupon.date.endDate) {
        fetchCashCoupons();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignCashCoupon.date.startDate, campaignCashCoupon.date.endDate]);

    const fetchCashCoupons = async () => {
      setIsLoading(true);
      await getCashCoupons({
        retailerId: user.id,
        filters: {
          validityStartDate: ['lessThanOrEqual', campaignCashCoupon.date.startDate],
          validityEndDate: ['greaterThanOrEqual', campaignCashCoupon.date.endDate],
        },
      });
      setIsLoading(false);
    };

    useImperativeHandle(ref, () => ({
      ref: thisRef,
      step2CheckHasError() {
        return checkHasError();
      },
    }));

    const checkHasError = () => {
      const stateOffersArray = campaignCashCoupon.offers;
      const hash = {};
      const errorArray = [];
      const errorMessageArray = [];

      stateOffersArray.forEach((items, index) => {
        let key = JSON.stringify(items);
        hash[key] = (hash[key] || 0) + 1;

        if (items.cashCoupon.id === '' || items.retailerSegment.id === '') {
          errorArray.push(index);
          errorMessageArray.push({ id: index, message: t('campaign_cash_coupon_step2_form_empty_error_message') });
        } else if (hash[key] >= 2) {
          errorArray.push(index);
          errorMessageArray.push({ id: index, message: t('campaign_cash_coupon_step2_form_doublon_error_message') });
        }

        setFieldError(errorArray);
        setErrorMessage(errorMessageArray);
      });

      return errorArray.length !== 0;
    };

    const handleDelete = (index) => {
      const cashCouponArray = [...campaignCashCoupon.offers];
      cashCouponArray.splice(index, 1);
      updateCashCoupon({ offers: cashCouponArray });
      handleDeleteError(index);
    };

    const handleDeleteError = (itemIndex) => {
      const errorArray = [...fieldError];
      const messageArray = [...errorMessage];
      const errorArrayIndex = errorArray.indexOf(itemIndex);

      errorArrayIndex !== -1 && errorArray.splice(errorArrayIndex, 1);
      setFieldError(errorArray);

      messageArray.map((message, messageIndex) => message.id === itemIndex && messageArray.splice(messageIndex, 1));
      setErrorMessage(messageArray);
    };

    const handleUpdateCashcoupon = ({ id = '', index, key }) => {
      const cashCouponArray = [...campaignCashCoupon.offers];
      const cashCouponArrayItem = { [key]: { id } };
      cashCouponArray[index] = { ...cashCouponArray[index], ...cashCouponArrayItem };

      updateCashCoupon({ offers: cashCouponArray });
      handleDeleteError(index);
    };

    const toggleDisplayCashCouponCreation = () => {
      setOpenCashCouponDialog((state) => !state);
      scrollToElementById('root');
    };

    return (
      <>
        <div ref={thisRef}>
          <FormManager
            data={{
              title: t('campaign_creation_step_BR_title'),
              subTitle: t('campaign_creation_step_BR_desc'),
              fieldsets: [
                {
                  id: 'add-campaign-cash-coupon-step-02',
                  fields: [
                    {
                      id: 'br-creation',
                      type: 'CustomContent',
                      component: (
                        <ButtonsCustom
                          id={useDynId('step2CreateCashCoupon')}
                          classType="link_primary"
                          method={toggleDisplayCashCouponCreation}
                          text={t('commun_button_create_cash_coupon')}
                        />
                      ),
                    },
                    {
                      id: 'br-selection',
                      outerContainerClass: ['full-width', 'with-big-margin-top'],
                      type: 'CustomContent',
                      component: (
                        <>
                          <BrSelection
                            cashCouponsListAll={cashCouponsListAll}
                            disabled={disabled}
                            errorMessage={errorMessage}
                            fieldError={fieldError}
                            isLoading={isLoading}
                            offers={campaignCashCoupon.offers}
                            onDelete={handleDelete}
                            segmentsListAll={segmentsListAll}
                            updateList={handleUpdateCashcoupon}
                          />
                          <ButtonsCustom
                            id={useDynId('step2AddCashCoupon')}
                            classType="addNewLine"
                            text={t('campaign_cash_coupon_step2_form_add_cash_coupon')}
                            method={() => {
                              const cashCouponArray = [
                                ...campaignCashCoupon.offers,
                                { retailerSegment: { id: '' }, cashCoupon: { id: '' } },
                              ];
                              updateCashCoupon({ offers: cashCouponArray });
                            }}
                          />
                        </>
                      ),
                    },
                  ],
                },
              ],
            }}
          />
        </div>
        {openCashCouponDialog && (
          <CashCouponForm
            onCanceled={toggleDisplayCashCouponCreation}
            onSubmited={() => {
              fetchCashCoupons();
              toggleDisplayCashCouponCreation();
            }}
            wrapperProps={{
              asDialog: true,
              headerTitle: t('commun_button_create_cash_coupon'),
              headerSubTitle: t('cash_coupon_creation_desc'),
            }}
          />
        )}
      </>
    );
  }
);

CashCouponStep2.propTypes = {
  campaignCashCoupon: PropTypes.object.isRequired,
  cashCouponsListAll: PropTypes.array,
  disabled: PropTypes.bool,
  segmentsListAll: PropTypes.array,
  updateCashCoupon: PropTypes.func,
  user: PropTypes.object,
};

export default memo(CashCouponStep2);

/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { ReactComponent as ExpandIcon } from 'assets/16px_arrow_2.svg';
import './Accordion.scss';

const CustomAccordion = ({
  children,
  classname = '',
  customIcon,
  disabled = false,
  expandedByDefault = false,
  handleExpandedChange,
  hideExpandIcon = false,
  iconColor = 'default',
  id,
  preventClickOnSummary = false,
  summary,
  summaryLabel = '',
  ...props
}) => (
  <div
    className={`style-none ${preventClickOnSummary ? 'preventClickOnSummary' : 'forceCursorPointer'} ${
      customIcon ? 'hasCustomIcon' : ''
    } ${classname}`}
  >
    <Accordion
      disabled={disabled}
      defaultExpanded={expandedByDefault}
      onChange={(ev, expanded) => handleExpandedChange?.(expanded)}
      {...props}
    >
      <AccordionSummary
        aria-controls={`accordion-content-${id}`}
        id={id}
        expandIcon={hideExpandIcon ? null : customIcon || <ExpandIcon className={`icon-color-${iconColor}`} />}
      >
        <div
          className="accordion-summary"
          onClick={(event) => {
            if (preventClickOnSummary) event.stopPropagation();
          }}
          onFocus={(event) => {
            if (preventClickOnSummary) event.stopPropagation();
          }}
        >
          {summary ? summary : <div>{summaryLabel}</div>}
        </div>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  </div>
);

CustomAccordion.propTypes = {
  children: PropTypes.node,
  classname: PropTypes.string,
  customIcon: PropTypes.node,
  disabled: PropTypes.bool,
  expandedByDefault: PropTypes.bool,
  handleExpandedChange: PropTypes.func,
  hideExpandIcon: PropTypes.bool,
  iconColor: PropTypes.string,
  id: PropTypes.string,
  preventClickOnSummary: PropTypes.bool,
  summary: PropTypes.node
};

export default CustomAccordion;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WarningIcon } from 'assets/30px_attention.svg';
import styles from './WarningContainer.module.scss';

const WarningContainer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles['root']}>
      <div className={styles['icon']}>
        <WarningIcon />
      </div>
      <div>{t('product_offers_settings_help_desc')}</div>
    </div>
  );
};

export default WarningContainer;

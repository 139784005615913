import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { store } from 'store';
import { useTranslation } from 'react-i18next';

import { marketingStrategiesCodes, offerTypes, userTypes } from 'utils/constants';
import {
  getAllRetailers,
  getAllSuppliers,
  getAllUserManager,
  getOffers,
  getOffersExportFile,
  getTargetingStrategies
} from 'api';
import useDynId from 'customHooks/useDynId';

import Drawer from 'components/Drawer';
import ExportFileButton from 'components/ExportFileButton';
import TableProductOffers from 'components/TableProductOffers';
import FiltersContainer, { getInitialFilters } from 'components/Filters';
import OffersDetails from 'pages/OffersDetails';
import ButtonsCustom from 'components/ButtonsCustom';
import OfferTopTab from 'components/OfferTopTab';

import styles from './OfferList.module.scss';

const OfferListSupplier = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id: offerHeadId } = useParams();
  const globalState = useContext(store);
  const tableSize = 30;
  const { state, dispatch } = globalState;
  const { queryFilters, retailers, suppliers, user, offers, targetingStrategies, userManagers } = state;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState({ type: 'id', sort: 'desc' });
  const [isLoading, setIsLoading] = useState(true);
  const oppositeType = user.userType === 'retailer' ? 'supplier' : 'retailer';

  // if user.userType === 'supplier' there can be more than one retailer
  // so if one of the retailers has offerSegmentEnabled === true
  // display all targetingStrategies
  // if not, display only Smart and Generic
  const usefullTargetingStrategies =
    user.userType === 'retailer'
      ? targetingStrategies
      : retailers.length
      ? !!retailers.find((retailer) => retailer.offerSegmentEnabled === true)
        ? targetingStrategies
        : [
            targetingStrategies.find((s) => s.targetMarketing.code === marketingStrategiesCodes.SMART),
            targetingStrategies.find((s) => s.targetMarketing.code === marketingStrategiesCodes.GENERIC)
          ]
      : targetingStrategies;

  const initFilters = useMemo(
    () =>
      getInitialFilters({
        history,
        queryFilters,
        retailers,
        suppliers,
        targetingStrategies: usefullTargetingStrategies,
        userManagers
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [usefullTargetingStrategies]
  );

  const [filters, setFilters] = useState(initFilters);

  useEffect(() => {
    if (user.userType === userTypes.RETAILER) {
      getAllSuppliers();
      getAllUserManager();
    } else {
      getAllRetailers();
    }
  }, [user.userType]);

  const refreshOffersList = useCallback(async () => {
    setIsLoading(true);
    await getOffers({
      dispatch,
      user: user,
      sortBy: order.type,
      orderBy: order.sort,
      page,
      size: tableSize,
      offerType: offerTypes.SUPPLIER_PRODUCT_OFFER,
      filters,
      fetchMultiPeriodCount: true
    });
    setIsLoading(false);
    localStorage.setItem('offersProductsSupplierFilter', window.location.search);
  }, [dispatch, user, page, order, filters]);

  const filtersListToDisplay = [
    'id',
    'status',
    'offerHeadType',
    'title',
    'descriptionTag',
    'targetingMarketingStrategy',
    user.userType === userTypes.RETAILER && 'retailerUser',
    user.userType === userTypes.SUPPLIER && 'supplierUser',
    'budgetSpentWithOverrun',
    'budgetTarget',
    'validityDates',
    user.userType === userTypes.RETAILER && 'userManager'
  ];

  // on unmount
  useEffect(() => {
    return () => {
      dispatch({ type: 'OFFERS_RESET' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch targetingStrategies for marketingStrategies filters
  useEffect(() => {
    if (!targetingStrategies.length) {
      getTargetingStrategies();
    }
  }, [targetingStrategies.length]);

  useEffect(() => {
    refreshOffersList();
  }, [refreshOffersList]);

  const handleSort = (type) => {
    setPage(0); // reset the page when changing the sorting
    setOrder({ type, sort: type === order.type && order.sort === 'desc' ? 'asc' : 'desc' });
  };

  const handleUpdateFilters = useCallback((newFilters) => {
    setPage(0); // reset the page when changing the filters
    setFilters({ ...newFilters });
  }, []);

  const handleTableRowClick = ({ id }) => {
    history.push({ pathname: `/offers/offersProductsSupplier/${id[0].value}`, search: history.location.search });
  };

  const handleClose = () => {
    // reset offer
    history.push({ pathname: '/offers/offersProductsSupplier', search: history.location.search });
  };

  const handleDownloadFile = (extension) => {
    return getOffersExportFile({
      extension,
      filters,
      orderBy: order.sort,
      sortBy: order.type,
      userId: user.id
    });
  };

  const onCloseFinished = () => {
    dispatch({ type: 'OFFERS_DETAILS_RESET' });
  };

  const handleDeletedOffer = () => {
    handleClose();
    setPage(offers.list?.length > 1 ? page : Math.max(0, page - 1));
    refreshOffersList();
  };

  return (
    <section className={styles['root']}>
      {user.userType === 'retailer' && <OfferTopTab index={0} />}
      <div className={styles['top-wrapper']}>
        <p className={styles['count-result']} id={useDynId('Xoffer')}>
          {t('commun_offer_product_offer_count', { count: offers.total })}
        </p>
        <div className={styles['right-buttons']}>
          <ExportFileButton disabled={!offers.total || isLoading} handleApiCall={handleDownloadFile} />
          <Link to={'/offers/add-offer'} role="link">
            <ButtonsCustom
              classType="action_primary_big"
              id={useDynId('createOffer')}
              text={t('commun_button_create_offer')}
            />
          </Link>
        </div>
      </div>

      <FiltersContainer
        dispatch={dispatch}
        filters={filters}
        filtersListToDisplay={filtersListToDisplay}
        retailersList={retailers}
        suppliersList={suppliers}
        targetingStrategiesList={usefullTargetingStrategies}
        updateFilters={handleUpdateFilters}
        user={user}
        userManagersList={userManagers}
      />
      <TableProductOffers
        isLoading={isLoading}
        handleNextPage={() => setPage(page + 1)}
        handlePrevPage={() => setPage(page - 1)}
        handleSort={handleSort}
        offers={offers}
        orderBy={order.sort}
        offerType="supplier"
        oppositeType={oppositeType}
        page={page}
        size={tableSize}
        sortBy={order.type}
        handleRowClick={handleTableRowClick}
        columnsToHide={['offerType', 'mdd', 'actionButton']}
        isUsingFilters={!!Object.keys(filters)?.length}
        clickedRawUniqueKeyValue={offerHeadId?.toString()}
      />
      <Drawer isOpen={!!offerHeadId} onClose={handleClose} onCloseFinished={onCloseFinished}>
        <OffersDetails
          details={offers.details}
          offerHeadId={offerHeadId}
          onUpdated={refreshOffersList}
          onDeleted={handleDeletedOffer}
          user={user}
        />
      </Drawer>
    </section>
  );
};

export default OfferListSupplier;

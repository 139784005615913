import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { store } from 'store';
import { getImportUseCase, postImportUseCase } from 'api';
import { snackbarTypes } from 'utils/constants';

import DragDropFile from 'components/DragDropFile';
import ImportedFileFrame from 'components/ImportedFileFrame';
import Loader from 'components/Loaders/Spinner_red';
import LoadingDots from 'components/Loaders/Dots';
import ScrollToTop from 'components/ScrollToTop';

import { ReactComponent as UploadIcon } from 'assets/30px_import.svg';
import styles from './UseCaseImportFiles.module.scss';

const UseCaseImportFiles = () => {
  const { t } = useTranslation();
  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [firstLoading, setFirstLoading] = useState(true);
  const [isDropLoading, setIsDropLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [importedFileList, setImportedFileList] = useState([]);
  const [totalCount, setTotalCount] = useState(null);

  const size = 10;
  const hasMoreFiles = totalCount !== null && totalCount - importedFileList.length > 0;
  const isLoading = useRef(true);

  useEffect(() => {
    const initialFetch = async () => {
      await fetchImportedFileList(0);
      setFirstLoading(false);
    };
    initialFetch();
  }, []);

  const fetchImportedFileList = async (currentPage) => {
    isLoading.current = true;
    const data = await getImportUseCase({ page: currentPage, size });
    if (data) {
      setImportedFileList((state) => [...state, ...data.list]);
      setTotalCount(data.totalCount);
    }
    isLoading.current = false;
  };

  const handleInfiniteScrollPagination = () => {
    if (isLoading.current || !hasMoreFiles) return;

    if ((window.scrollY + document.body.offsetHeight) / document.body.scrollHeight > 0.8 && hasMoreFiles) {
      isLoading.current = true;
      setPage((state) => state + 1);
      fetchImportedFileList(page + 1);
    }
  };

  const handleUploadFile = async (file) => {
    if ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' === file.type) {
      isLoading.current = true;
      setIsDropLoading(true);
      const uploadSuccess = await postImportUseCase(file);
      if (uploadSuccess) {
        const newListData = await getImportUseCase({ page: 0, size });
        if (newListData) {
          setImportedFileList(newListData.list);
          setTotalCount(newListData.totalCount);
          setPage(0);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
      setIsDropLoading(false);
      isLoading.current = false;
    } else {
      dispatch({
        type: 'UI_SNACKBAR',
        payload: {
          type: snackbarTypes.ERROR,
          title: { key: 'import_file_bad_format', params: { text: '.xlsx' } }
        }
      });
    }
  };

  const displayDragDropZone = () => {
    if (isDropLoading) {
      return (
        <div className={`${styles['drag-drop-zone']} ${styles['loading']}`}>
          <Loader />
          <div>{Trans({ i18nKey: 'use_case_import_file_loading' })}</div>
        </div>
      );
    }
    return (
      <>
        <DragDropFile acceptedFileType=".xlsx" onDropFile={handleUploadFile}>
          <div className={styles['drag-drop-zone']}>
            <UploadIcon />
            <div>{Trans({ i18nKey: 'use_case_import_file_drag_drop_field_text' })}</div>
          </div>
        </DragDropFile>
        {firstLoading && (
          <div className={styles['initial-loading']}>
            <p className={styles['initial-loading-message']}>{t('import_loading_message')}</p>
            <LoadingDots />
          </div>
        )}
      </>
    );
  };

  if (!importedFileList.length) {
    return <div className={`${styles['useCaseImportFiles-page']} ${styles['emptyList']}`}>{displayDragDropZone()}</div>;
  }
  return (
    <div className={styles['useCaseImportFiles-page']}>
      {displayDragDropZone()}
      <div className={styles['divider']}></div>
      <p className={styles['list-title']}>{t('commun_last_import', { count: importedFileList.length })}</p>

      {importedFileList.map((el, index) => {
        return <ImportedFileFrame data={el} key={`${el.id}-${index}`} />;
      })}
      {hasMoreFiles && <LoadingDots />}

      <ScrollToTop handleScroll={handleInfiniteScrollPagination} />
    </div>
  );
};

export default UseCaseImportFiles;

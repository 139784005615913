import React from 'react';

import BudgetKpisBlock from './BudgetKpisBlock';
import BudgetSpentByPeriod from './BudgetSpentByPeriod';

import styles from '../../Dashboard.module.scss';
import { t } from 'i18next';

type Props = {
  filterYear: number;
  loadingAvailableYears: boolean;
  handleUpdateUrl: (obj: { budgetYear: number }) => void;
  yearList: number[];
};

const Dashboard = ({ filterYear, loadingAvailableYears, handleUpdateUrl, yearList }: Props): JSX.Element => {
  return (
    <>
      <div className={styles['block-title']}>{t('commun_offer_tab_supplier')}</div>

      {/* block line 1 */}
      <BudgetKpisBlock filterYear={filterYear} loadingAvailableYears={loadingAvailableYears} />

      {/* block line 2 */}
      <BudgetSpentByPeriod
        filterYear={filterYear}
        furthestPastYearInYearList={yearList?.[0]}
        loadingAvailableYears={loadingAvailableYears}
        updateUrl={handleUpdateUrl}
      />
    </>
  );
};

export default Dashboard;

import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import GoBackLink from 'components/GoBackLink';

import { ReactComponent as CrossIcon } from 'assets/22px_croix.svg';
import styles from './FormLayout.module.scss';

const FormLayout = ({ asDialog, asPanel, children, headerSubTitle, headerTitle, onClose, withGoBackLink }) => {
  const history = useHistory();
  const wrapperRef = useRef(null);

  const handleCloseClick = () => {
    if (onClose) onClose();
    else goBack();
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <div
      className={`${styles['root']} ${asDialog ? styles['asDialog'] : ''} ${asPanel ? styles['asPanel'] : ''}`}
      ref={wrapperRef}
    >
      {withGoBackLink && (
        <div className={styles['goBackLink']}>
          <GoBackLink customOnClick={goBack} pathname={'#'} />
        </div>
      )}
      <div className={styles['main-container']}>
        <div className={styles['main-block']}>
          {headerTitle && (
            <div className={styles['header']} id="form-layout-wrapper-header-container">
              <div className={styles['title']}>
                <h2 className={!onClose ? styles['fullWidth'] : ''}>{headerTitle}</h2>
                {onClose && (
                  <div className={styles['cross-icon']} onClick={handleCloseClick} role="button">
                    <CrossIcon />
                  </div>
                )}
              </div>
              {headerSubTitle && <p>{headerSubTitle}</p>}
            </div>
          )}
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
};

FormLayout.propTypes = {
  asDialog: PropTypes.bool,
  asPanel: PropTypes.bool,
  children: PropTypes.node.isRequired,
  headerSubTitle: PropTypes.string,
  headerTitle: PropTypes.string,
  onClose: PropTypes.func,
  withGoBackLink: PropTypes.bool,
};
export default memo(FormLayout);

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './HelpGuide.module.scss';

const Item = ({ element, index }) => {
  const { t } = useTranslation();

  return (
    <div className={styles[element.type]}>
      {Array.isArray(element.content) ? (
        <ul>
          {element.content.map((item, itemIndex) => {
            if (typeof item === 'object') return <Item element={item} key={`list-${index}-${itemIndex}`} />;

            return <li key={`list-${index}-${itemIndex}`}>{t(item) || item}</li>;
          })}
        </ul>
      ) : (
        t(element.content) || element.content
      )}
    </div>
  );
};

Item.propTypes = {
  element: PropTypes.object,
  index: PropTypes.number,
};

export default Item;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './TabSwitch.module.scss';

const TabSwitch = ({ isLoading, tabs }) => {
  return (
    <div className={styles['root']}>
      {tabs.map((tab, index) => (
        <button
          className={`${tab.isActive ? styles['active'] : ''} items-${styles[tabs.length]}`}
          disabled={isLoading}
          key={`tab-${tab.label}-${index}`}
          onClick={tab.onClick}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

TabSwitch.propTypes = {
  isLoading: PropTypes.bool,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string.isRequired, isActive: PropTypes.bool, onClick: PropTypes.func })
  )
};

export default TabSwitch;

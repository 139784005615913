import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { validateEmail } from 'utils/global';
import ButtonsCustom from 'components/ButtonsCustom';
import EmailsList from 'components/EmailsList';
import TextFieldCustom from 'components/TextFieldCustom';

import { ReactComponent as ArrowIcon } from 'assets/14px_arrow.svg';
import styles from './UserManagerField.module.scss';

const UserManagerField = (props) => {
  const {
    disabled,
    data: { id, defaultValue },
    onChange
  } = props;
  const { t } = useTranslation();
  const [emailInput, setEmailInput] = useState('');
  const [email, setEmail] = useState(defaultValue);
  const [errorEmailFormat, setErrorEmailFormat] = useState(false);
  const [errorEmailNotAdded, setErrorEmailNotAdded] = useState(false);

  useEffect(() => {
    if (JSON.stringify(defaultValue) !== JSON.stringify(email)) {
      setEmail(defaultValue);
      onChange(id, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const checkErrorReminder = () => {
    if (emailInput) {
      setErrorEmailNotAdded(true);
    }
  };

  const displayErrorMsg = () => {
    if (errorEmailFormat) return t('supplier_invalid_email');
    else if (errorEmailNotAdded) return t('supplier_input_validation_reminder');
    else return '';
  };

  const handleRemove = () => {
    onChange(id, null);
  };

  const handleAddEmail = () => {
    if (disabled) return;
    if (emailInput && validateEmail(emailInput)) {
      onChange(id, emailInput);
      setEmailInput('');
      setEmail(emailInput);
      setErrorEmailFormat(false);
      setErrorEmailNotAdded(false);
    } else {
      setErrorEmailFormat(true);
    }
  };

  const handleEmailInput = (e) => {
    setEmailInput(e.target.value.trim());
    if (!e.target.value.trim()) {
      setErrorEmailFormat(false);
      setErrorEmailNotAdded(false);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      handleAddEmail();
    }
  };

  if (email) {
    return (
      <div className={styles['email-container']}>
        <EmailsList emails={[{ email }]} handleRemove={handleRemove} />
      </div>
    );
  }

  return (
    <TextFieldCustom
      id="userManager-email-input"
      type="text"
      label={t('supplier_email')}
      onBlur={checkErrorReminder}
      onChange={handleEmailInput}
      value={emailInput}
      error={errorEmailFormat || errorEmailNotAdded}
      errorMsg={displayErrorMsg()}
      onKeyUp={handleKeyUp}
      InputProps={{
        disabled: disabled,
        endAdornment: (
          <ButtonsCustom
            classType="icon_secondary_small"
            id="add_userManager_email"
            method={handleAddEmail}
            startIconCustom={<ArrowIcon />}
          />
        )
      }}
    />
  );
};

UserManagerField.propTypes = {
  data: PropTypes.object,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default UserManagerField;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import styles from './DurationSummary.module.scss';

const DurationSummary = ({ existingDurationConstraint, validityDates }) => {
  const cleanValidityDates = validityDates?.filter((d) => !!d.startDate && !!d.endDate);
  const diffList = cleanValidityDates?.map((d) => {
    return Math.floor(d.endDate.diff(d.startDate, 'days') + 1);
  });
  const diff = diffList?.reduce((acc, value) => {
    return acc + value;
  }, 0);

  const minDays = existingDurationConstraint
    ? existingDurationConstraint.unit === 'DAYS'
      ? existingDurationConstraint.threshold
      : existingDurationConstraint.threshold * 7
    : 0;

  if (!diff || diff < minDays) {
    return false;
  }
  return (
    <div className={styles['root']}>
      <Trans
        i18nKey={`offers_creation_total_duration_${diff < 7 ? 'in_days' : 'in_weeks'}`}
        values={{ count: diff < 7 ? diff : Math.floor(diff / 7) }}
      />
    </div>
  );
};

DurationSummary.propTypes = {
  existingDurationConstraint: PropTypes.object,
  validityDates: PropTypes.array,
};

export default memo(DurationSummary);

import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { store } from 'store';
import { Trans, useTranslation } from 'react-i18next';
import { scrollToElementById } from 'utils/global';
import { checkRetailerExistsByCode } from 'api';

import FormManager from 'components/FormManager';
import RetailerTypeChoice from './RetailerTypeChoice';

const nameRegex = /^[\s\w\u00C0-\u00FF-&()]*$/;
const codeRegex = /^[a-z][a-z0-9]*$/;

const GeneralSettings = forwardRef(({ isCreation, retailerState, onChangeState }, ref) => {
  const { t } = useTranslation();
  const {
    state: { countries, currencies, dams, timezones },
  } = useContext(store);

  const [errorMsgName, setErrorMsgName] = useState(null);
  const [errorMsgCode, setErrorMsgCode] = useState(null);
  const [errorMsgCountryCode, setErrorMsgCountryCode] = useState(null);
  const [errorMsgTimezone, setErrorMsgTimezone] = useState(null);
  const [errorMsgCurrency, setErrorMsgCurrency] = useState(null);

  useImperativeHandle(ref, () => ({
    checkHasError() {
      return handleCheckError();
    },
  }));

  const handleCheckError = async () => {
    let errorFieldIdList = [];

    if (!retailerState.name) {
      setErrorMsgName(t('retailers_creation_retailer_name_error_required'));
      errorFieldIdList.push('field-retailer-creation-form-general-parameters-title');
    } else if (retailerState.name.length > 250 || !nameRegex.test(retailerState.name)) {
      setErrorMsgName(t('retailers_creation_retailer_name_error_format'));
      errorFieldIdList.push('field-retailer-creation-form-general-parameters-title');
    } else {
      setErrorMsgName();
    }

    if (!retailerState.code) {
      setErrorMsgCode(t('retailers_creation_retailer_code_error_required'));
      errorFieldIdList.push('field-logo');
    } else if (retailerState.code.length < 2 || retailerState.code.length > 10 || !codeRegex.test(retailerState.code)) {
      setErrorMsgCode(t('retailers_creation_retailer_code_error_format'));
      errorFieldIdList.push('field-logo');
    } else if (isCreation && (await checkRetailerExistsByCode(retailerState.code))) {
      setErrorMsgCode(t('retailers_creation_retailer_code_error_exists'));
      errorFieldIdList.push('field-logo');
    } else {
      setErrorMsgCode();
    }

    if (!retailerState.countryCode) {
      setErrorMsgCountryCode(t('retailers_creation_retailer_country_error_required'));
      errorFieldIdList.push('field-code');
    } else {
      setErrorMsgCountryCode();
    }

    if (!retailerState.timezone) {
      setErrorMsgTimezone(t('retailers_creation_retailer_timezone_error_required'));
      errorFieldIdList.push('field-countryCode');
    } else {
      setErrorMsgTimezone();
    }

    if (!retailerState.currency) {
      setErrorMsgCurrency(t('retailers_creation_retailer_currency_error_required'));
      errorFieldIdList.push('field-timezone');
    } else {
      setErrorMsgCurrency();
    }

    if (errorFieldIdList.length) {
      setTimeout(() => {
        scrollToElementById(errorFieldIdList[0]);
      }, 150);
    }

    return !!errorFieldIdList.length;
  };

  return (
    <FormManager
      data={{
        title: t('retailers_creation_general_params'),
        fieldsets: [
          {
            id: 'creation-form-general',
            fields: [
              {
                type: <RetailerTypeChoice />,
                disabled: !isCreation,
                defaultValue: retailerState?.type,
                id: 'type',
                onFieldChange: (value) => {
                  onChangeState({ type: value });
                },
              },
              {
                label: t('retailers_creation_retailer_name'),
                placeholder: t('retailers_creation_retailer_name'),
                defaultValue: retailerState?.name || '',
                type: 'TextField',
                id: 'name',
                fieldProps: {
                  InputProps: {
                    min: 1,
                    max: 250,
                  },
                },
                error: errorMsgName,
                onFieldChange: (value) => {
                  setErrorMsgName();
                  onChangeState({ name: value });
                },
              },
              {
                id: 'logo',
                label: <Trans i18nKey="retailers_creation_retailer_logo" />,
                type: 'ImageChoice',
                defaultValue: retailerState?.logo,
                displayCustomReco: t('commun_picture_size_reco'),
                displayRemoveButton: true,
                importFeatureOnly: true,
                onFieldChange: (file) => {
                  onChangeState({ logo: file });
                },
              },
              {
                label: t('retailers_creation_retailer_code'),
                placeholder: t('retailers_creation_retailer_code'),
                defaultValue: retailerState?.code || '',
                type: 'TextField',
                id: 'code',
                fieldTooltip: t('retailers_creation_retailer_code_tooltip'),
                disabled: !isCreation,
                error: errorMsgCode,
                onFieldChange: (value) => {
                  setErrorMsgCode();
                  onChangeState({ code: value });
                },
              },
              {
                label: t('retailers_creation_retailer_location'),
                placeholder: t('retailers_creation_country'),
                defaultValue: retailerState?.countryCode || '',
                type: 'Select',
                fieldProps: { listData: countries, labelAttribute: 'name' },
                id: 'countryCode',
                error: errorMsgCountryCode,
                onFieldChange: (value) => {
                  setErrorMsgCountryCode();
                  onChangeState({ countryCode: value });
                },
              },
              {
                outerContainerClass: ['with-margin-top'],
                placeholder: t('retailers_creation_timezone'),
                defaultValue: retailerState?.timezone || '',
                type: 'Select',
                fieldProps: { listData: timezones, labelAttribute: 'name' },
                id: 'timezone',
                error: errorMsgTimezone,
                onFieldChange: (value) => {
                  setErrorMsgTimezone();
                  onChangeState({ timezone: value });
                },
              },
              {
                outerContainerClass: ['with-margin-top'],
                placeholder: t('retailers_creation_currency'),
                defaultValue: retailerState?.currency || '',
                type: 'Select',
                fieldProps: { listData: currencies, labelAttribute: 'name' },
                id: 'currency',
                error: errorMsgCurrency,
                onFieldChange: (value) => {
                  setErrorMsgCurrency();
                  onChangeState({ currency: value });
                },
              },
              {
                label: t('retailers_creation_dam'),
                subLabel: t('retailers_creation_dam_description'),
                placeholder: t('retailers_creation_dam'),
                defaultValue: retailerState?.damName || 'NONE',
                type: 'Select',
                fieldProps: {
                  listData: [{ id: 'NONE', name: t('retailers_creation_dam_none') }, ...dams],
                  labelAttribute: 'name',
                },
                id: 'damName',
                onFieldChange: (value) => {
                  onChangeState({ damName: value });
                },
              },
              {
                label: t('retailers_creation_use_allocation'),
                defaultValue: !isCreation ? retailerState?.targetingEnabled : true,
                type: 'Switch',
                id: 'targetingEnabled',
                switchLabels: { off: t('retailers_creation_switch_off'), on: t('retailers_creation_switch_on') },
                fieldProps: { size: 'small' },
                onFieldChange: (value) => {
                  onChangeState({ targetingEnabled: value });
                },
              },
              {
                label: t('retailers_creation_vll'),
                defaultValue: !isCreation ? retailerState?.settingCashCouponVllCodeFormat : false,
                type: 'Switch',
                id: 'settingCashCouponVllCodeFormat',
                switchLabels: { off: t('retailers_creation_switch_off'), on: t('retailers_creation_switch_on') },
                fieldProps: { size: 'small' },
                onFieldChange: (value) => {
                  onChangeState({ settingCashCouponVllCodeFormat: value });
                },
              },
              {
                label: t('retailers_creation_use_budgetEstimation'),
                defaultValue: !isCreation ? retailerState?.budgetEstimatedEnabled : false,
                type: 'Switch',
                id: 'budgetEstimatedEnabled',
                switchLabels: { off: t('retailers_creation_switch_off'), on: t('retailers_creation_switch_on') },
                fieldProps: { size: 'small' },
                onFieldChange: (value) => {
                  onChangeState({ budgetEstimatedEnabled: value });
                },
              },
            ],
          },
        ],
      }}
    />
  );
});

GeneralSettings.propTypes = {
  isCreation: PropTypes.bool,
  onChangeState: PropTypes.func,
  retailerState: PropTypes.object,
};

export default GeneralSettings;

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './Tags.module.scss';

const Tags = ({ status, textOnly, withFixedWidth }) => {
  const { t } = useTranslation();
  if (!status) return null;
  return (
    <div
      className={clsx(
        styles['tag'],
        styles[`tag-${status}`],
        textOnly && styles['textOnly'],
        withFixedWidth && styles['with-fixed-width']
      )}
    >
      {t(`_dyn_commun_${status}`)}
    </div>
  );
};

Tags.propTypes = {
  status: PropTypes.string,
  textOnly: PropTypes.bool,
  withFixedWidth: PropTypes.bool
};

export default memo(Tags);

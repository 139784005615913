import { hasEmptyField } from 'utils/global';
import moment from 'moment-timezone';
import { SEGMENT_MANAGER_RECENCY_PAST_DATE_MONTH_LIMIT } from 'utils/constants';

export const getSettingProductLevelXLabel = ({ productLevel, user }) => {
  switch (productLevel) {
    case 'level0':
      return user.settingProductLevelLabelOne;
    case 'level1':
      return user.settingProductLevelLabelTwo;
    case 'level2':
      return user.settingProductLevelLabelThree;
    case 'level3':
      return user.settingProductLevelLabelFour;
    default:
      return '';
  }
};

export const getConditionTitle = ({ segment, t, user }) => {
  const productLevel = segment?.condition?.level || segment?.level;
  const segmentType = segment.type.toLowerCase();
  switch (productLevel) {
    case 'level0':
      return user.settingProductLevelLabelOne;
    case 'level1':
      return user.settingProductLevelLabelTwo;
    case 'level2':
      return user.settingProductLevelLabelThree;
    case 'level3':
      return user.settingProductLevelLabelFour;
    default:
      const type = productLevel ? `${segmentType}_${productLevel}` : segmentType;
      return t(`segmentManager_vector_type_${type}_label`);
  }
};

export const hasAtLeastOneConditionFilled = (vector) => {
  let conditionFilledStateList = [];
  const r = (list) => {
    list.forEach((element) => {
      if (!element) {
        conditionFilledStateList.push(false);
      } else if (element.type) {
        conditionFilledStateList.push(!hasEmptyField(element));
      } else {
        r(element.segments);
      }
    });
  };

  r(vector.segments);
  return conditionFilledStateList.some((el) => el === true);
};

export const hasAtLeastOneProductTypeConditionFilled = (vector) => {
  let hasProductFamilyConditionFilled = false;
  const r = (list) => {
    list.forEach((element) => {
      if (element) {
        if (!element.type) {
          r(element.segments);
        } else {
          if ((element.type === 'Purchases' || element.type === 'MultiPurchases') && !hasEmptyField(element)) {
            hasProductFamilyConditionFilled = true;
          }
        }
      }
    });
  };

  r(vector.segments);
  return hasProductFamilyConditionFilled;
};

export const cleanVector = (vector = {}) => {
  let v = { ...vector };
  const r = (o) => {
    Object.keys(o).forEach((key) => {
      if (key === 'segments') {
        if (o[key][1] === null) {
          o[key].splice(1, 1);
        }
        o[key].filter((el) => {
          if (el.type) {
            return !hasEmptyField(el);
          } else {
            return r(o[key]);
          }
        });
      }
    });
  };
  r(v);
  return v;
};

export const isOutOfRange = ({ count, interval }) => {
  if (interval === 'YEAR') {
    return count > Math.floor(SEGMENT_MANAGER_RECENCY_PAST_DATE_MONTH_LIMIT / 12);
  } else if (interval === 'MONTH') {
    return count > SEGMENT_MANAGER_RECENCY_PAST_DATE_MONTH_LIMIT;
  } else if (interval === 'WEEK') {
    return count > 52;
  } else if (interval === 'DAY') {
    return count > 31;
  } else {
    return false;
  }
};

export const isOutOfRangePast18Month = ({ count, interval }) => {
  if (interval === 'YEAR') {
    return count > 1;
  } else if (interval === 'MONTH') {
    return count > 18;
  } else if (interval === 'WEEK') {
    return count > 52;
  } else if (interval === 'DAY') {
    return count > 31;
  } else {
    return false;
  }
};

export const isRecencyDatePickerOutsideRange = ({ date, dateFocusedInputState, end_date, start_date }) => {
  const today = moment();
  const pastDate = moment().subtract(SEGMENT_MANAGER_RECENCY_PAST_DATE_MONTH_LIMIT, 'month'); // recency past date limit
  const isOutOfRange = date.isBefore(pastDate, 'day') || date.isAfter(today, 'day');
  if (dateFocusedInputState === 'startDate' && end_date) {
    return isOutOfRange || date.isAfter(end_date, 'day');
  }
  if (dateFocusedInputState === 'endDate' && start_date) {
    return isOutOfRange || date.isBefore(start_date, 'day');
  }
  return isOutOfRange;
};

export const isRecencyDatePickerOutsideRangePast18Month = ({ date, dateFocusedInputState, end_date, start_date }) => {
  const today = moment();
  const pastDate = moment().subtract(18, 'month'); // recency past date limit
  const isOutOfRange = date.isBefore(pastDate, 'day') || date.isAfter(today, 'day');
  if (dateFocusedInputState === 'startDate' && end_date) {
    return isOutOfRange || date.isAfter(end_date, 'day');
  }
  if (dateFocusedInputState === 'endDate' && start_date) {
    return isOutOfRange || date.isBefore(start_date, 'day');
  }
  return isOutOfRange;
};

export const isProductLevelXType = (productLevel) => ['level0', 'level1', 'level2', 'level3'].includes(productLevel);

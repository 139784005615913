import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { initialState } from './initialState';
import currencies from 'utils/currencies.json';

const store = createContext(initialState);
const { Provider } = store;
const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user
        },
        initIsLoading: false
      };

    case 'INIT_SUPPLIERS_UPDATE': {
      return { ...state, suppliers: action.payload };
    }

    case 'EXIT_MODAL_TYPE_UPDATE':
      return { ...state, exitModalType: action.payload };

    case 'CAMPAIGN_CREATION_PRODUCT_OFFERS_RESET':
      return {
        ...state,
        campaignCreation: {
          ...state.campaignCreation,
          product: {
            ...state.campaignCreation.product,
            genericTargetingStrategyCampaignOffersCount: 0,
            offers: [],
            untargetableProductCampaignOffers: {
              list: [],
              listAll: [],
              total: null
            }
          }
        }
      };
    case 'CAMPAIGN_CREATION_PRODUCT_PREVIEW_OFFERS_RESET':
      return {
        ...state,
        campaignCreation: {
          ...state.campaignCreation,
          product: {
            ...state.campaignCreation.product,
            offersPreviewCount: null,
            offersUntargetablePreviewCount: null,
            offersUntargetablePreviewList: []
          }
        }
      };
    case 'CAMPAIGN_CREATION_PRODUCT_UPDATE':
      return {
        ...state,
        campaignCreation: {
          ...state.campaignCreation,
          product: { ...state.campaignCreation.product, ...action.payload }
        }
      };
    case 'CAMPAIGN_CREATION_RESET':
      return {
        ...state,
        campaignCreation: {
          ...initialState.campaignCreation
        },
        exitModalType: null
      };

    case 'CAMPAIGNS_DETAILS_RESET':
      return {
        ...state,
        campaigns: { ...state.campaigns, details: { ...initialState.campaigns.details } }
      };
    case 'CAMPAIGNS_DETAILS_UPDATE':
      return {
        ...state,
        campaigns: {
          ...state.campaigns,
          details: {
            ...state.campaigns.details,
            ...action.payload.campaign
          }
        }
      };
    case 'CAMPAIGNS_UPDATE':
      return {
        ...state,
        campaigns: { ...state.campaigns, ...action.payload }
      };
    case 'CAMPAIGNS_RESET':
      return {
        ...state,
        campaigns: { ...initialState.campaigns }
      };

    case 'CASH_COUPONS_RESET':
      return {
        ...state,
        cashCoupons: {
          ...initialState.cashCoupons
        }
      };

    case 'CASH_COUPONS_DETAILS_RESET':
      return {
        ...state,
        cashCoupons: {
          ...state.cashCoupons,
          details: { ...initialState.cashCoupons.details }
        }
      };

    case 'CASH_COUPONS_UPDATE':
      return {
        ...state,
        cashCoupons: {
          ...state.cashCoupons,
          ...action.payload
        }
      };

    case 'OFFERS_DETAILS_RESET':
      return {
        ...state,
        offers: {
          ...state.offers,
          details: { ...initialState.offers.details }
        }
      };
    case 'OFFERS_DETAILS_UPDATE':
      return {
        ...state,
        offers: {
          ...state.offers,
          details: { ...state.offers.details, ...action.payload }
        }
      };
    case 'OFFERS_RESET':
      return { ...state, offers: { ...initialState.offers } };
    case 'OFFERS_UPDATE':
      return {
        ...state,
        offers: {
          ...state.offers,
          ...action.payload
        }
      };

    case 'OFFERS_IMPORT_RESET':
      return {
        ...state,
        offersImports: {
          list: [],
          details: {
            listWithImage: [],
            listWithoutImage: []
          }
        }
      };
    case 'OFFERS_IMPORT_UPDATE':
      return {
        ...state,
        offersImports: {
          ...state.offersImports,
          ...action.payload
        }
      };
    case 'OFFERS_IMPORT_DETAILS_UPDATE':
      return {
        ...state,
        offersImports: {
          ...state.offersImports,
          details: {
            listWithoutImage: [...state.offersImports.details.listWithoutImage, ...action.payload.listWithoutImage],
            listWithImage: [...state.offersImports.details.listWithImage, ...action.payload.listWithImage]
          }
        }
      };
    case 'OFFERS_IMPORT_DETAILS_RESET':
      return {
        ...state,
        offersImports: {
          ...state.offersImports,
          details: {
            listWithoutImage: [],
            listWithImage: []
          }
        }
      };
    case 'PRODUCTS_RESET':
      return {
        ...state,
        products: { ...initialState.products }
      };
    case 'PRODUCTS_UPDATE':
      return {
        ...state,
        products: { ...state.products, ...action.payload }
      };
    case 'SEGMENTS_DETAILS_RESET':
      return {
        ...state,
        segments: {
          ...state.segments,
          details: { ...initialState.segments.details },
          estimateCustomerCount: null
        }
      };
    case 'SEGMENTS_RESET':
      return {
        ...state,
        segments: {
          ...initialState.segments
        }
      };
    case 'SEGMENTS_UPDATE':
      return {
        ...state,
        segments: {
          ...state.segments,
          ...action.payload
        }
      };

    case 'SUPPLIER_BUDGETS_ALL':
      return { ...state, supplierBudgetsAll: action.payload };

    case 'SUPPLIER_BUDGETS_ALL_RESET':
      return { ...state, supplierBudgetsAll: initialState.supplierBudgetsAll };

    case 'MONITORING_RETAILER_OFFERS_TO_DISABLE_LIST':
      return {
        ...state,
        monitoringRetailer: {
          ...state.monitoringRetailer,
          offersToDisable: { ...state.monitoringRetailer.offersToDisable, ...action.payload }
        }
      };

    case 'MONITORING_RETAILER_OFFERS_TO_DISABLE_LIST_RESET':
      return {
        ...state,
        monitoringRetailer: {
          ...state.monitoringRetailer,
          offersToDisable: { ...initialState.monitoringRetailer.offersToDisable }
        }
      };

    case 'MONITORING_RETAILER_OFFERS_TO_VALIDATE_LIST':
      return {
        ...state,
        monitoringRetailer: {
          ...state.monitoringRetailer,
          offersToValidate: { ...state.monitoringRetailer.offersToValidate, ...action.payload }
        }
      };

    case 'MONITORING_RETAILER_OFFERS_TO_VALIDATE_LIST_RESET':
      return {
        ...state,
        monitoringRetailer: {
          ...state.monitoringRetailer,
          offersToValidate: { ...initialState.monitoringRetailer.offersToValidate }
        }
      };

    case 'MONITORING_RETAILER_SUPPLIER_BUDGETS_LIST':
      return {
        ...state,
        monitoringRetailer: {
          ...state.monitoringRetailer,
          supplierBudgets: { ...state.monitoringRetailer.supplierBudgets, ...action.payload }
        }
      };

    case 'MONITORING_RETAILER_SUPPLIER_BUDGETS_LIST_RESET':
      return {
        ...state,
        monitoringRetailer: {
          ...state.monitoringRetailer,
          supplierBudgets: { ...initialState.monitoringRetailer.supplierBudgets }
        }
      };

    case 'MONITORING_RETAILER_RESET':
      return {
        ...state,
        monitoringRetailer: { ...initialState.monitoringRetailer }
      };

    case 'GET_ALL_RETAILERS':
      return { ...state, retailers: action.payload };

    case 'GET_RETAILER_DETAILS':
      return {
        ...state,
        retailer: { ...action.payload }
      };

    case 'GET_TARGETING_STRATEGIES':
      return {
        ...state,
        targetingStrategies: [...action.payload.targetingStrategies],
        targetingStrategiesWithUntargetable: [...action.payload.targetingStrategiesWithUntargetable]
      };

    case 'RETAILERS_UPDATE':
      return {
        ...state,
        retailersList: { ...state.retailersList, ...action.payload }
      };

    case 'RESET_RETAILER_DETAILS':
      return {
        ...state,
        retailer: {}
      };

    case 'RESET_RETAILERS':
      return {
        ...state,
        retailer: {},
        retailersList: {
          list: [],
          page: 0,
          total: 0
        },
        strategies: []
      };

    case 'SUPPLIERS_RESET':
      return {
        ...state,
        suppliersList: { ...initialState.suppliersList }
      };

    case 'SUPPLIERS_UPDATE':
      return {
        ...state,
        suppliersList: { ...state.suppliersList, ...action.payload }
      };

    case 'SUPPLIERS_DETAILS_RESET':
      return {
        ...state,
        suppliersList: {
          ...state.suppliersList,
          details: { ...initialState.suppliersList.details }
        }
      };

    case 'USER_MANAGERS_UPDATE':
      return { ...state, userManagers: action.payload };

    case 'USER_MANAGERS_RESET':
      return { ...state, userManagers: initialState.userManagers };

    case 'UNIVERSE_RETAILER_UPDATE':
      return { ...state, universesRetailerList: action.payload };
    case 'UNIVERSE_RETAILER_UPDATE_RESET':
      return { ...state, universesRetailerList: initialState.universesRetailerList };

    case 'TIMEZONES_UPDATE':
      return {
        ...state,
        timezones: action.payload.sort((a, b) => a.name.localeCompare(b.name))
      };

    case 'NOTIFICATION_TEMPLATES_UPDATE':
      return {
        ...state,
        notificationTemplates: action.payload
      };

    case 'CURRENCIES_UPDATE':
      return {
        ...state,
        currencies: action.payload
          .sort((a, b) => a.code.localeCompare(b.code))
          .map((el) => ({ ...el, name: `${el.code} - ${currencies[el.code] || ''}` }))
      };

    case 'COUNTRIES_UPDATE':
      return {
        ...state,
        countries: action.payload.map((el) => ({ ...el, id: el.code, name: `${el.name} - ${el.code}` }))
      };

    case 'DAMS_RESET':
      return { ...state, dams: [] };
    case 'DAMS_UPDATE':
      return {
        ...state,
        dams: [...action.payload]
      };

    case 'LOCALES_UPDATE':
      return {
        ...state,
        locales: action.payload.sort((a, b) => a.locale.localeCompare(b.locale)).map((el) => ({ ...el, id: el.locale }))
      };

    case 'UI_SNACKBAR':
      return { ...state, snackbar: { ...state.snackbar, ...action.payload } };

    case 'QUERY_FILTERS_UPDATE': {
      return { ...state, queryFilters: { ...state.queryFilters, ...action.payload } };
    }
    case 'QUERY_FILTERS_RESET': {
      return { ...state, queryFilters: {} };
    }

    case 'API_DOCS':
      return { ...state, apiDocs: action.payload };

    case 'GET_OFFER_CONSTRAINTS':
      return {
        ...state,
        offerConstraints: action.payload
      };

    default:
      throw new Error();
  }
};

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};
StateProvider.propTypes = { children: PropTypes.node };

export { store, StateProvider };

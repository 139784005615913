import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { retailerTypes } from 'utils/constants';
import RadioGroup from 'components/RadioGroup';

import useDynId from 'customHooks/useDynId';

import styles from './RetailerTypeChoice.module.scss';

const RetailerTypeChoice = ({ data: { id, defaultValue = retailerTypes.CLIENT }, disabled, onChange }) => {
  const { t } = useTranslation();
  const [state, setState] = useState(defaultValue);

  useEffect(() => {
    setState(defaultValue);
  }, [defaultValue]);

  const handleChange = (e) => {
    e.stopPropagation();
    setState(e.target.value);
    if (onChange) onChange(id, e.target.value);
  };

  return (
    <div className={styles['root']}>
      <RadioGroup
        listData={Object.keys(retailerTypes).map((type) => ({ label: t(`retailer_type_${type}`), value: type }))}
        disabled={disabled}
        id={useDynId('type-choice')}
        onChange={handleChange}
        selectedValue={state}
      />
    </div>
  );
};

RetailerTypeChoice.propTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default memo(RetailerTypeChoice);

import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { offerStatuses, offerTypes } from 'utils/constants';
import { getOffersCount } from 'api';

import Divider from 'components/Divider';
import Tags from 'components/Tags';

import styles from './DashboardSupplier.module.scss';

type Props = {
  budgetIds?: number[];
  retailerId?: number;
  year: number;
};

const OffersBlock = ({ budgetIds, retailerId, year }: Props): JSX.Element => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [proposalCount, setProposalCount] = useState<number | null>(null);
  const [toValidateCount, setToValidateCount] = useState<number | null>(null);
  const [validatedCount, setValidatedCount] = useState<number | null>(null);
  const totalCount: number | null = useMemo(() => {
    if (
      typeof proposalCount !== 'number' ||
      typeof toValidateCount !== 'number' ||
      typeof validatedCount !== 'number'
    ) {
      return null;
    }
    return proposalCount + toValidateCount + validatedCount;
  }, [proposalCount, toValidateCount, validatedCount]);

  useEffect(() => {
    const fetchOffersCount = async () => {
      setIsLoading(true);
      const promises = [
        getOffersCount({
          budgetIds,
          offerType: offerTypes.SUPPLIER_PRODUCT_OFFER,
          retailerId,
          statuses: [offerStatuses.PROPOSAL],
          year
        }),
        getOffersCount({
          budgetIds,
          offerType: offerTypes.SUPPLIER_PRODUCT_OFFER,
          retailerId,
          statuses: [offerStatuses.TO_VALIDATE],
          year
        }),
        getOffersCount({
          budgetIds,
          offerType: offerTypes.SUPPLIER_PRODUCT_OFFER,
          retailerId,
          statuses: [offerStatuses.DISABLED, offerStatuses.EXPIRED, offerStatuses.VALIDATED],
          year
        })
      ];
      const [proposalResponse, toValidateResponse, validatedResponse] = await Promise.all(promises);

      setIsLoading(false);
      setProposalCount(!proposalResponse && proposalResponse !== 0 ? null : proposalResponse);
      setToValidateCount(!toValidateResponse && toValidateResponse !== 0 ? null : toValidateResponse);
      setValidatedCount(!validatedResponse && validatedResponse !== 0 ? null : validatedResponse);
    };
    fetchOffersCount();
  }, [budgetIds, retailerId, year]);

  const displayLoadingOffersCount = () => {
    return `- ${t('commun_offer', { count: 2 })}`;
  };

  return (
    <div className={clsx(styles['card'], styles['offers-block'])}>
      <div>
        <div className={styles['big-value']}>{isLoading || totalCount === null ? '-' : totalCount}</div>
        <div className={styles['sub-label']}>
          {t('commun_offer', { count: isLoading || totalCount === null ? 2 : totalCount })}
        </div>
      </div>
      <Divider borderSize="thin" marginSize="large" />
      <div>
        <div>
          {isLoading || validatedCount === null
            ? displayLoadingOffersCount()
            : t('_dyn_offer_import_list_count', { count: validatedCount })}
          <Tags status={offerStatuses.VALIDATED} />
        </div>
        <Divider borderSize="thin" marginSize="thin" />
        <div>
          {isLoading || toValidateCount === null
            ? displayLoadingOffersCount()
            : t('_dyn_offer_import_list_count', { count: toValidateCount })}
          <Tags status={offerStatuses.TO_VALIDATE} />
        </div>
        <Divider borderSize="thin" marginSize="thin" />
        <div>
          {isLoading || proposalCount === null
            ? displayLoadingOffersCount()
            : t('_dyn_offer_import_list_count', { count: proposalCount })}
          <Tags status={offerStatuses.PROPOSAL} />
        </div>
      </div>
    </div>
  );
};

export default OffersBlock;

import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from './fields/Autocomplete';
import AutocompleteSelect from './fields/AutocompleteSelect';
import AutocompleteTag from './fields/AutocompleteTag';
import CheckboxList from './fields/CheckboxList';
import DateRangePicker from './fields/DateRangePicker';
import Duration from './fields/Duration';
import Generosity from './fields/Generosity';
import ImageChoice from './fields/ImageChoice';
import NumberField from './fields/NumberField';
import RadioGroup from './fields/RadioGroup';
import Select from './fields/Select';
import Switch from './fields/Switch';
import TextField from './fields/TextField';
import UsersList from './fields/UsersList';

import FormContext from './context';

const FIELDS = {
  Autocomplete,
  AutocompleteSelect,
  AutocompleteTag,
  CheckboxList,
  DateRangePicker,
  Duration,
  Generosity,
  ImageChoice,
  NumberField,
  RadioGroup,
  Select,
  Switch,
  TextField,
  UsersList,
};

const FieldManager = (props) => {
  const { errors, raiseErrorOnSubmit, shouldUpdateForm, shouldRaiseError, shouldFlagValid } = useContext(FormContext);
  const {
    className,
    data,
    data: {
      classnames = [],
      id,
      component,
      type,
      label,
      placeholder,
      disabled,
      defaultValue,
      onFieldChange,
      onFieldError,
      validations = [],
      display = true,
    },
  } = props;

  const [error, setError] = useState();

  useEffect(() => {
    // first render
    onChange(id, defaultValue, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (onFieldError) onFieldError(error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const validate = (value, hideError) => {
    validations.some(({ type, func, message, options }) => {
      const isValid = func ? func(value) : true;

      if (isValid || !display) {
        setError();
        shouldFlagValid(id);
      } else if (hideError || raiseErrorOnSubmit) {
        // PROPAGATE ERROR BUT DON'T SHOW ERROR (Validation at mount)
        shouldRaiseError(id, message);
        return true;
      } else {
        // PROPAGATE ERROR AND SHOW ERROR
        shouldRaiseError(id, message);
        setError(message);
        return true;
      }

      return false;
    });
  };

  const onChange = (id, value, hideError) => {
    if (onFieldChange) onFieldChange(value);

    shouldUpdateForm(id, value);
    validate(value, hideError);
  };

  if (!display) {
    return false;
  }

  // Custom Content
  if (type === 'CustomContent') {
    return !disabled
      ? React.cloneElement(component, {
          className: [...classnames].join(' '),
        })
      : false;
  }

  // Custom Component
  if (typeof type === 'object') {
    return React.cloneElement(type, {
      className: ['form-field', className, ...classnames].join(' '),
      label,
      placeholder,
      data,
      disabled,
      error: data.error || errors[id] || error,
      onChange,
      onError: onFieldError,
    });
  }

  // Default Component
  const FieldComponent = FIELDS[type];
  if (FieldComponent) {
    return (
      <FieldComponent
        className={['form-field', className, ...classnames].join(' ')}
        data={data}
        error={data.error || errors[id] || error}
        disabled={disabled}
        onChange={onChange}
        onError={onFieldError}
      />
    );
  }

  console.error(`[Form Manager] Component ${type} not found !`);
  return false;
};

FieldManager.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object,
};

export default FieldManager;

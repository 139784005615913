import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import FormManager from 'components/FormManager';

const SelectRetailerBlock = ({ error, listData, multiSupplierId, updateOfferCreation }) => {
  const { t } = useTranslation();
  const currentRetailer = listData[0];

  useEffect(() => {
    updateOffer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiSupplierId]);

  const updateOffer = () => {
    if (currentRetailer?.id) {
      updateOfferCreation({
        step1: {
          id: currentRetailer?.id,
          code: currentRetailer?.code,
          damName: currentRetailer?.damName
        }
      });
    }
  };

  return (
    <FormManager
      data={{
        title: t(`offers_creation_block_supplier_title`),
        fieldsets: [
          {
            id: 'add-offer-supplier-retailer-step',
            fields: [
              {
                placeholder: t('commun_choose'),
                type: 'Select',
                defaultValue: currentRetailer?.id,
                fieldProps: {
                  listData,
                  labelAttribute: 'name',
                  noOptionsText: t('commun_no_retailer_found')
                },
                id: 'name',
                disabled: true, // always disabled because there will be always one pre-selected option
                error
              }
            ]
          }
        ]
      }}
    />
  );
};

SelectRetailerBlock.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  listData: PropTypes.array,
  multiSupplierId: PropTypes.number,
  updateOfferCreation: PropTypes.func
};

export default memo(SelectRetailerBlock);

/*
const graphDataSchema = 
//   [  {  data: [{x: 33,y: 52}, ...]    }, ...  ];
*/

// const expectedSchemaFor3Graphs =
// [
//   [  {  data: [{x: 33,y: 52}, ...]    }, ...  ],
//   [  {  data: [{x: 33,y: 52}, ...]    }, ...  ],
//   [  {  data: [{x: 33,y: 52}, ...]    }, ...  ],
// ];

import { DataPropType } from 'components/ScatterPlotGraph';
import { DashboardRetailerOffersAreaChartsType } from 'types/dashboardRetailer';

export const convertDataForScatterPlotGraphs = (data: DashboardRetailerOffersAreaChartsType): DataPropType[][] => {
  if (!data?.length) {
    return [];
  }
  const groupByMaxValue = 3;
  const yProps = ['burnRate', 'estimatedTurnover', 'returnOnAdSpent'];

  const initialData: DataPropType[][] = [
    [{ data: [] }, { data: [] }, { data: [] }],
    [{ data: [] }, { data: [] }, { data: [] }],
    [{ data: [] }, { data: [] }, { data: [] }]
  ];

  const newData: DataPropType[][] = data?.reduce((acc, value, index) => {
    if (index === 0) {
      acc = initialData;
    }

    const xValue: number = value.generosityPercentage || 0;
    const yValues: number[] = yProps.map((p) => (value as any)[p] || 0);

    let groupIndexValue = value.quantityMinimum;
    if (typeof groupIndexValue === 'number' && groupByMaxValue && groupIndexValue >= groupByMaxValue) {
      groupIndexValue = groupByMaxValue;
    }
    groupIndexValue = groupIndexValue - 1; // -1 because array starts at 0

    yValues.forEach((yValue, index) => {
      // push values
      acc[index][groupIndexValue].data.push({
        rawData: value,
        x: xValue,
        y: yValue
      });
    });

    return acc;
  }, [] as DataPropType[][]);

  return newData;
};

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactComponent as CloseIcon } from 'assets/22px_croix.svg';
import styles from './ContentDialog.module.scss';

const ContentDialog = ({
  bigTitle,
  centerText,
  children,
  className = '',
  desc,
  disableClickOutside = false,
  handleClose,
  isLoading,
  isOpen,
  maxWidth,
  spaceless,
  subTitle,
  title
}) => {
  const handleCloseAction = (ev, reason) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (disableClickOutside && reason === 'backdropClick') return;
    if (!isLoading) handleClose();
  };

  return (
    <Dialog
      className={`
      ${styles['content-dialog']} ${className || ''}
      ${spaceless ? styles['spaceless'] : ''}
      ${centerText ? styles['center-text'] : ''}`}
      open={isOpen}
      scroll="body"
      onClose={handleCloseAction}
      maxWidth={maxWidth}
      BackdropProps={{ className: styles['backdrop'] }}
      PaperProps={{ className: styles['dialog-paper'] }}
    >
      {handleClose && (
        <div
          className={`${styles['close-icon']} ${isLoading ? styles['disabled'] : ''}`}
          id="close_dialog_button"
          onClick={handleCloseAction}
          role="button"
        >
          <CloseIcon />
        </div>
      )}
      <DialogTitle classes={{ root: clsx(styles['dialog-title'], bigTitle && styles['bigTitle']) }}>
        <div>{title}</div>
        {subTitle && <div className={styles['sub-title']}>{title}</div>}
      </DialogTitle>
      <DialogContent classes={{ root: styles['dialog-content'] }}>
        {desc && <div className={styles['dialog-content-text']}>{desc}</div>}
        <div className={styles['dialog-content-children-container']}>{children}</div>
      </DialogContent>
    </Dialog>
  );
};
ContentDialog.propTypes = {
  bigTitle: PropTypes.bool,
  centerText: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  desc: PropTypes.node,
  handleClose: PropTypes.func,
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  spaceless: PropTypes.bool,
  subTitle: PropTypes.node,
  title: PropTypes.node
};

export default ContentDialog;

import React, { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './GaugeBar.module.scss';

type Props = {
  color: 'primary' | 'secondary';
  label: ReactNode;
  percent: number;
  position: 'left' | 'right';
};

const minThresholdPercentBeforeDisplayingValue = 2;

const GaugeBar = ({ color = 'primary', label, percent, position = 'left' }: Props): JSX.Element => {
  // gaugeValue is the displayed percent value -> 0 or between 2 and 100
  // because under a value of 2, the display is ugly
  let gaugeValue: number = percent;
  if (gaugeValue) {
    if (gaugeValue < minThresholdPercentBeforeDisplayingValue) {
      gaugeValue = minThresholdPercentBeforeDisplayingValue;
    } else if (gaugeValue > 100) {
      gaugeValue = 100;
    }
  }

  return (
    <div className={styles['root']}>
      {position === 'left' && (
        <div
          className={clsx(
            styles['label-container'],
            styles['position-left'],
            gaugeValue < minThresholdPercentBeforeDisplayingValue && styles['hide-dash']
          )}
          style={{ bottom: `${gaugeValue}%` }}
        >
          <div className={styles['label-sub-container']}>
            <div className={clsx(styles['label-node'], !gaugeValue && styles['fix-label-position'])}>{label}</div>
            <div className={styles['dash-container']}>
              <div className={styles['dash']} />
            </div>
          </div>
        </div>
      )}
      <div className={styles['gauge-container']}>
        <div className={clsx(styles['gauge'], styles[`gauge-color-${color}`])} style={{ height: `${gaugeValue}%` }} />
      </div>
      {position === 'right' && (
        <div
          className={clsx(
            styles['label-container'],
            styles['position-right'],
            gaugeValue < minThresholdPercentBeforeDisplayingValue && styles['hide-dash']
          )}
          style={{ bottom: `${gaugeValue}%` }}
        >
          <div className={styles['label-sub-container']}>
            <div className={styles['dash-container']}>
              <div className={styles['dash']} />
            </div>
            <div className={clsx(styles['label-node'], !gaugeValue && styles['fix-label-position'])}>{label}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GaugeBar;
